import { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { checkPhoneNum } from "@/utils";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";
import type { PropertyStatus } from "@/types";

import {
  useUpdatePropertyDriverDefaultInfo,
  useCreatePropertyTruckLink,
  useDeletePropertyTruckLink,
} from "services";
import { CARRIER_VALID_MSG, CARRIER_TOAST_MSG } from "constants/index";
import type {
  DriverDefaultInfoForm,
  DriverSelectType,
  GetPropertyDriverDetailClientModel,
  PropertyDetailEditCardType,
} from "types";

const DRIVER_FORM_INIT_STATE = {
  profile: null,
  profileUrl: null,
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  status: "AVAILABLE" as PropertyStatus,
  created: "",
  linkedRegistNumber: "",
  truckId: "",
  memo: "",
  license: null,
};

const schema = yup.object({
  profile: yup.string().defined().nullable(),
  profileUrl: yup.string().defined().nullable(),
  lastName: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
  middleName: yup.string().defined().nullable(),
  firstName: yup.string().required(),
  phone: yup
    .string()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("phone", CARRIER_VALID_MSG.PHONE, (value) => checkPhoneNum(value)),
  status: yup.mixed<PropertyStatus>().defined().required(),
  created: yup.string().defined(),
  linkedRegistNumber: yup.string().defined(),
  truckId: yup.string().defined(),
  memo: yup
    .string()
    .optional()
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_MEMO_200,
      (value) => (value?.length ?? 0) <= 200,
    ),
  license: yup.mixed<FileList | string>().defined().nullable(),
});

const useDefaultInfo = (
  data: GetPropertyDriverDetailClientModel["defaultInfo"],
  driverId: string,
  handleEditState: (
    label: PropertyDetailEditCardType | null,
    type?: boolean,
  ) => () => void,
) => {
  const formMethod = useForm<DriverDefaultInfoForm>({
    defaultValues: DRIVER_FORM_INIT_STATE,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const {
    mutate: updatePropertyDriverDefaultInfoMutate,
    isSuccess: isDefaultUpdateDone,
    isLoading: isDefaultUpdateLoading,
  } = useUpdatePropertyDriverDefaultInfo();

  const {
    mutate: createPropertyTruckLinkMutate,
    isSuccess: isCreateLinkDone,
    isLoading: isCreateLinkLoading,
  } = useCreatePropertyTruckLink();

  const {
    mutate: deletePropertyTruckLinkMutate,
    isSuccess: isDeleteLinkDone,
    isLoading: isDeleteLinkLoading,
  } = useDeletePropertyTruckLink();

  const handleSubmitDefaultInfo = formMethod.handleSubmit(
    (data: DriverDefaultInfoForm) => {
      if (isCompareDefaultInfoData()) {
        handleEditState(null, true)();
        return;
      }

      if (!data.license) return;

      const body = {
        lastName: data.lastName,
        middleName: data.middleName,
        firstName: data.firstName,
        phone: data.phone,
        memo: data.memo ? data.memo.trim() : null,
        status: data.status,
        // TODO: 서버 로직 수정된 거 확인 후 주석 제거
        ...(typeof data.license !== "string" && {
          license: data.license ? data.license[0] : null,
        }),
      };

      const req = {
        driverId,
        body,
      };

      updatePropertyDriverDefaultInfoMutate(req, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_UPDATE_DONE);
          handleEditState(null, true)();
        },
        onError: (err) => {
          switch (err.response?.data.response) {
            case "DRIVING_DRIVER":
              addToast(CARRIER_TOAST_MSG.WARNING.PROCESS_FAIL);
              break;

            default:
              addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
          }
        },
      });
    },
  );

  const handleChangeDefaultInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.type === "file" ? e.target.files[0] : null;
    const name = e.target.name as keyof DriverDefaultInfoForm;
    const value = e.target.value;

    switch (name) {
      case "license":
        if (!file) return;

        if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
          addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
          formMethod.setError(name, {
            type: "capacityOver",
          });
          formMethod.setValue(name, null);
          return;
        }

        formMethod.clearErrors("license");

        return;

      case "linkedRegistNumber":
        formMethod.setValue("linkedRegistNumber", value);
        formMethod.setValue("truckId", "");
        formMethod.clearErrors("linkedRegistNumber");
        formMethod.clearErrors("truckId");

        return;
    }
  };

  const handleBlurlinkedRegistNumber = () => {
    if (
      !formMethod.watch("truckId") &&
      formMethod.watch("linkedRegistNumber")
    ) {
      formMethod.setError("linkedRegistNumber", {
        message: CARRIER_VALID_MSG.PLATE_NUMBER,
      });
    }
  };

  const handleDelFile = (name: string) => () => {
    formMethod.setValue(name as keyof DriverDefaultInfoForm, null);
    formMethod.setError(name as keyof DriverDefaultInfoForm, {
      message: CARRIER_VALID_MSG.REQUIRED,
    });
  };

  const handleSelectLink = useCallback(
    ({ registNumber, truckId }: DriverSelectType) => {
      formMethod.setValue("linkedRegistNumber", registNumber);
      formMethod.setValue("truckId", truckId);
      formMethod.clearErrors("linkedRegistNumber");
    },
    //} [form, formErr]  NOTE: dependency에 뭐가 들어가야 하나?
    [formMethod.setValue, formMethod.formState.errors],
  );

  const handleCancelDefaultInfo = () => {
    if (!data) return;

    formMethod.reset(makeOriginDriverInfoForm(data));
  };

  const handleUpdateLink = () => {
    if (!data) return;

    if (data.linkedRegistNumber === formMethod.watch("linkedRegistNumber")) {
      handleEditState(null, true)();
      return;
    }

    if (formMethod.watch("truckId")) {
      const req = {
        driverId,
        body: { truckId: formMethod.watch("truckId") },
      };

      createPropertyTruckLinkMutate(req, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_UPDATE_DONE);
          handleEditState(null, true)();
        },
        onError: () => addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      });
    }
  };

  const handleUnlinkTruck = () => {
    const req = {
      driverId,
    };

    deletePropertyTruckLinkMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_UPDATE_DONE);
        handleEditState(null, true)();
      },
      onError: () => addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
    });
  };

  const isCompareDefaultInfoData = () => {
    if (!data) return false;

    const originFormData = makeOriginDriverInfoForm(data);
    const currentFormData = formMethod.getValues();

    return isEqual(originFormData, currentFormData);
  };

  const isCompareLinkInfoData = () => false;

  const makeOriginDriverInfoForm = (
    originData: GetPropertyDriverDetailClientModel["defaultInfo"],
  ) => {
    return {
      profile: originData.profile ?? "",
      profileUrl: originData.profileUrl,
      lastName: originData.lastName,
      middleName: originData.middleName ?? "",
      firstName: originData.firstName,
      status: originData.status,
      phone: originData.phone,
      created: originData.created,
      linkedRegistNumber: originData.linkedRegistNumber,
      truckId: "",
      memo: originData.memo ?? "",
      license: originData.license ? originData.license : "",
    };
  };

  useEffect(() => {
    if (!data) return;

    formMethod.setValue("profile", data.profile);
    formMethod.setValue("lastName", data.lastName ?? "");
    formMethod.setValue("middleName", data.middleName ?? "");
    formMethod.setValue("firstName", data.firstName ?? "");
    formMethod.setValue("phone", data.phone ?? "");
    formMethod.setValue("memo", data.memo ?? "");
    formMethod.setValue("created", data.created ?? "");
    formMethod.setValue("linkedRegistNumber", data.linkedRegistNumber ?? "");
    formMethod.setValue("status", data.status);
    formMethod.setValue("license", data.license ? data.license : "");
    formMethod.setValue("truckId", "");
  }, [data]);

  return {
    formMethod,
    handleChangeDefaultInfo,
    handleBlurlinkedRegistNumber,
    handleSubmitDefaultInfo,
    handleCancelDefaultInfo,
    handleUpdateLink,
    handleUnlinkTruck,
    handleDelFile,
    handleSelectLink,
    isDefaultUpdateDone,
    isCreateLinkDone,
    isDeleteLinkDone,
    isDefaultUpdateLoading,
    isCreateLinkLoading,
    isDeleteLinkLoading,
    isCompareDefaultInfoData,
    isCompareLinkInfoData,
  };
};

export default useDefaultInfo;
