import { useRef, useState, useCallback, useEffect } from "react";
import { debounce, isEmpty } from "lodash-es";

import { useGetPropertyDriverTruck } from "services";
import { deleteObjectKeyWithEmptyValue } from "utils";
import type { GetTruckSearchServerItem } from "types";

const useTruckLinkAutoComplete = (
  searchValue: string,
  driverId: string,
  selectFn: (link: GetTruckSearchServerItem) => void,
  handleBlur?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
) => {
  const isSelected = useRef(false);
  const listRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [selectIdx, setSelectIdx] = useState(-1);
  const [driverTruckReq, setDriverTruckReq] =
    useState<GetTruckSearchServerItem>({});

  const { data: truckLinks } = useGetPropertyDriverTruck(driverTruckReq);

  const enterKeyFunc = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!truckLinks) return;

    const selectedtruckLink = truckLinks.filter(
      (link) => link.registNumber === searchValue,
    );
    if (!isEmpty(selectedtruckLink)) {
      selectFn(selectedtruckLink[0]);
    } else {
      handleBlur && handleBlur(e);
    }

    e.currentTarget.blur();
    setIsFocused(false);
    setDriverTruckReq({});
  };

  const closureArrowKeyFunc = () => {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (!truckLinks) return;

      const keyCode = e.key,
        firstValue = truckLinks[0],
        lastValue = truckLinks[truckLinks.length - 1];

      isSelected.current = true;

      switch (keyCode) {
        case "ArrowUp":
          {
            if (selectIdx <= 0) {
              setSelectIdx(truckLinks.length - 1);
              selectFn(lastValue);

              return;
            }
            selectFn(truckLinks[selectIdx - 1]);
            setSelectIdx((prev) => prev - 1);
          }
          break;

        case "ArrowDown": {
          if (selectIdx === truckLinks.length - 1) {
            setSelectIdx(0);
            selectFn(firstValue);

            return;
          }

          selectFn(truckLinks[selectIdx + 1]);
          setSelectIdx((prev) => prev + 1);
          break;
        }
      }
    };
  };

  const keyListenerMap: Map<
    string,
    (e: React.KeyboardEvent<HTMLInputElement>) => void
  > = new Map([
    ["ArrowUp", closureArrowKeyFunc()],
    ["ArrowDown", closureArrowKeyFunc()],
    ["Enter", enterKeyFunc],
  ]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const handleKeyListener = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const listener = keyListenerMap.get(e.key);
        typeof listener === "function" && listener(e);
      };
      handleKeyListener(e);
    },
    [truckLinks, selectIdx],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    const req = deleteObjectKeyWithEmptyValue({
      ...(driverId && { driverId }),
      registNumber: searchValue,
    });
    setSelectIdx(-1);
    setDriverTruckReq(req);
  }, [searchValue]);

  const handleUnsetValue = () => {
    setIsFocused(false);
  };

  const handleBlurLink = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!listRef.current) {
      setDriverTruckReq({});
      handleSelectWithoutClick(e);
    }
  };

  const handleSelectWithoutClick = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!truckLinks) return;

    const truckRegistNumList = truckLinks.map((truck) => truck.registNumber);

    if (truckRegistNumList.includes(searchValue)) {
      const { registNumber, truckId } = truckLinks.filter(
        (linkTruck) => linkTruck.registNumber === searchValue,
      )[0];

      const link = {
        registNumber,
        truckId,
      };

      typeof selectFn === "function" && selectFn(link);
    } else {
      handleBlur && handleBlur(e);
    }
  };

  const handleSelectOption = (link: GetTruckSearchServerItem) => () => {
    typeof selectFn === "function" && selectFn(link);
    setIsFocused(false);
    isSelected.current = true;
  };

  const loadLinkedList = useCallback(
    debounce((searchValue) => {
      setSelectIdx(-1);
      const req = {
        ...(driverId && { driverId }),
        ...(searchValue && { registNumber: searchValue }),
      };
      setDriverTruckReq(req);
    }, 300),
    [],
  );

  useEffect(() => {
    if (!isSelected.current) {
      loadLinkedList(searchValue);
    }
    isSelected.current = false;
  }, [searchValue]);

  return {
    listRef,
    truckLinks,
    isFocused,
    handleKeyDown,
    handleSelectOption,
    handleFocus,
    handleBlurLink,
    handleUnsetValue,
  };
};

export default useTruckLinkAutoComplete;
