import type { Languages } from "@/types";

export const COMMON_TOAST_MSG = {
  SUCCESS: {
    ACCOUNT_DEFAULT_UPDATE_DONE: {
      type: "success",
      content: "Account information has been updated.",
    },
    ORDER_CREATE_QUTATION_DONE: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    ORDER_CREATE_SELF_DONE: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    PASSWORD_CHANGE_DONE: {
      type: "success",
      content: "Your password has been changed.",
    },
    SETTLEMENT_PAYMENT_DONE: {
      type: "success",
      content: "This payment has been requested.",
    },
    SETTLEMENT_REQUEST_PAYMENT_REJECT: {
      type: "success",
      content: "This requested payment has been rejected.",
    },
    SETTLEMENT_REQUEST_PAYMENT_CONFIRM_DONE: {
      type: "success",
      content: "This requested payment has been confirmed.",
    },
    COMPANY_LICENSE_UPDATE_DONE: {
      type: "success",
      content: "Change request has been sent successfully.",
    },
    CANCEL_ORDER_DONE: {
      type: "success",
      content: "The order has been canceled successfully.",
    },
    REJECT_QUOTATION_DONE: {
      type: "success",
      content: "The quotation has been rejected.",
    },
    CARRIER_REQUEST_QUOTATION_REJECT_DONE: {
      type: "success",
      content: "The quotation has been rejected.",
    },
    RESEND_QUOTATION_DONE: {
      type: "success",
      content: "Your quotation has been requested.",
    },
    SEND_QUOTAIUON_DONE: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    APPROVE_QUOTATION_DONE: {
      type: "success",
      content: "Quotation approved successfully.",
    },
    SEND_QUOTATION_DONE: {
      type: "success",
      content: "The quotation has been sent successfully.",
    },
    ORDER_CANCEL_DONE: {
      type: "success",
      content: "The order has been canceled successfully.",
    },
    SMART_DISPATCH_DONE: {
      type: "success",
      content: "Smart dispatch has been completed successfully.",
    },
    COPY_PLATE_DONE: {
      type: "success",
      content: "Plate number was copied successfully.",
    },
  },
  WARNING: {
    LOGIN_FAIL: {
      type: "warning",
      content: "Incorrect email address or password.",
    },
    ACCOUNT_NOT_EXIST: {
      type: "warning",
      content: "COCOTRUCK account does not exist.",
    },
    CARGO_TYPE_DELETE_FAIL: {
      type: "warning",
      content: "You must enter at least one cargo type.",
    },
    TRUCK_DELETE_FAIL: {
      type: "warning",
      content: "You must enter at least one truck type.",
    },
    ALREADY_ACCEPTED_ALLOCATION_FAIL: {
      type: "warning",
      content: "Driver has already been allocated to this order.",
    },
    ALREADY_SHIPPING_ALLOCATION_FAIL: {
      type: "warning",
      content: "Unable to cancel allocation due to an order shipping.",
    },
    EXCEED_FILE_SIZE: {
      type: "warning",
      content: "You can't upload more than 5MB.",
    },
    INVALID_ETA_DATE: {
      type: "warning",
      content: "You cannot select a time later than the ETD.",
    },
    INVALID_ETD_DATE: {
      type: "warning",
      content: "You cannot choose a time earlier than the ETA.",
    },
    FAIL_TO_CALL_API: {
      type: "warning",
      content: "Request failed. Please try again.",
    },
    ENTER_ABLE_PRICE: {
      type: "warning",
      content: "You can't enter 0.",
    },
    INVALID_PRICE: {
      type: "warning",
      content: "The payment subtotal is incorrect.",
    },
    INVALID_MEMO: {
      type: "warning",
      content: "Memo cannot be longer than 200 characters.",
    },
    PHONE_EXISTS: {
      type: "warning",
      content: "This phone number is already registered.",
    },
    DUPLICATE_EMAIL: {
      type: "warning",
      content: "This Login ID is already taken.",
    },
    CANCEL_ORDER_FAIL: {
      type: "warning",
      content: "You cannot cancel the order after loading.",
    },
    WRONG_TRUCK_COUNT: {
      type: "warning",
      content:
        "It is incorrect number of trucks, please check your allocation information.",
    },
    DUPLICATE_TRUCK_OPTION: {
      type: "warning",
      content:
        "This truck is already registered, please check number of trucks.",
    },
    RESULT_NOT_FOUND: {
      type: "warning",
      content: "No results found.",
    },
    ROUTE_DISTANCE_EXCEED: {
      type: "warning",
      content: "The route cannot be displayed because it exceeds 2,000 km.",
    },
    CANNOT_FIND_ROUTABLE_POINT: {
      type: "warning",
      content:
        "A problem occurred while calculating the route, so the route cannot be displayed.",
    },
    CANNOT_FIND_GPS: {
      type: "warning",
      content: "GPS information doesn't exist.",
    },
    INVALID_ORDERNUMBER: {
      type: "warning",
      content: "Order number is not checked.",
    },
    GPS_NOT_CONNECTED: {
      type: "warning",
      content: "GPS is not registerd, Please contact your administrator.",
    },
  },
} as const;
