import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const SummaryWrapper = styled.ul`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 0;
  `}
`;

export const Place = styled.li`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 4px;
    color: ${theme.color.black};
  `}
`;

export const LocationTimePrice = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    & > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_70};
    }

    :last-of-type {
      margin-bottom: 0;
    }

    svg > g > path {
      fill: ${theme.color.gray_40};
    }
  `}
`;

export const pickUpPointIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.primary_40};
  }
`;

export const dropOffPointIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.red_20};
  }
`;

export const priceIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;
