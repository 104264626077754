import type {
  ChangePasswordQueryModel,
  ChangePasswordServerModel,
  CheckEmailAuthNumQueryModel,
  LoginQueryModel,
  LoginServerModel,
} from "@/types";

import { ax } from "apis/axios";

export const loginAPI = async (req: LoginQueryModel) => {
  const { data } = await ax.post<LoginServerModel>(`/auth/login`, req.body);

  return data;
};

export const logoutAPI = (refreshToken: string) =>
  ax.patch("/auth/logout", { refreshToken });

export const resetPassword = (email: string) => {
  return ax.post("/auth/password/reset", { email });
};

export const checkEmailAuthNum = async (req: CheckEmailAuthNumQueryModel) => {
  const { data } = await ax.put<ChangePasswordServerModel>(
    "/auth/password/reset",
    req.body,
  );

  return data;
};

export const changePassword = (req: ChangePasswordQueryModel) => {
  return ax.patch("/auth/password/reset", req.body);
};
