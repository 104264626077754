import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useCheckTableWithCondition = <
  T extends Record<K, string>,
  K extends keyof T,
>(
  tableDatas: T[] = [],
  uniqueId: K,
  condition?: (tableRowData: T) => boolean,
  isCheckableTable = false,
) => {
  const [searchParams] = useSearchParams();

  const [isCheckTable, setIsCheckTable] = useState(isCheckableTable);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const isChecked = (id: string) => checkedIds.includes(id);

  const checkableIds: string[] = useMemo(() => {
    const data = condition ? tableDatas.filter(condition) : tableDatas;
    return data.map((tableData) => tableData[uniqueId]);
  }, [tableDatas, condition]);

  const isCheckable = (id: string) => checkableIds.includes(id);

  const isAllChecked =
    !!checkableIds.length && checkableIds.length === checkedIds.length;

  const handleCheck = (id: string) => () =>
    checkedIds.includes(id)
      ? setCheckedIds(checkedIds.filter((checkId) => checkId !== id))
      : setCheckedIds([...checkedIds, id]);
  const handleInitCheck = () => setCheckedIds(checkableIds);

  const handleAllCheck = () => setCheckedIds(isAllChecked ? [] : checkableIds);
  const handleAllUnCheck = () => setCheckedIds([]);

  const handleTransformCheckTable = () => setIsCheckTable(true);
  const handleTransformTable = () => setIsCheckTable(false);

  const handleComparePreviousCheckedIds = (id: string) =>
    !checkedIds.includes(id) ? setCheckedIds([]) : setCheckedIds(checkableIds);

  useEffect(() => {
    handleAllUnCheck();
    handleTransformTable();
  }, [searchParams.get("page")]);

  return {
    isCheckTable,
    isCheckable,
    isChecked,
    isAllChecked,
    checkedIds,
    setCheckedIds,
    handleCheck,
    handleInitCheck,
    handleAllCheck,
    handleAllUnCheck,
    handleTransformCheckTable,
    handleTransformTable,
    handleComparePreviousCheckedIds,
  };
};

export default useCheckTableWithCondition;
