import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const detailTable = (theme: Theme) => css`
  & > span {
    ${theme.font.bold_18};
    display: block;
    margin-bottom: 20px;
  }

  & > div {
    width: 1294px;
    border: 1px solid ${theme.color.gray_30};
  }
`;

export const internalLink = css`
  margin: 33px 0 0 auto;
  width: fit-content;
`;

export const ContentBox = styled.div`
  img {
    max-width: 100%;
  }
`;
