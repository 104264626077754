/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import type { UseMutateFunction } from "@tanstack/react-query";

import { LoginErrMsg, ExternalLink, Button, Input } from "@/components";
import { UnvisibleIcon, VisibleIcon } from "@/assets";
import type { Languages, LoginQueryModel, LoginServerModel } from "@/types";

import useLoginForm from "./hooks/useLoginForm";
import * as S from "./LoginForm.styled";

interface LoginProps<T> {
  loginMutate: UseMutateFunction<T, unknown, LoginQueryModel, unknown>;
}

const LoginForm = <T extends LoginServerModel>({
  loginMutate,
}: LoginProps<T>) => {
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useLoginForm(loginMutate);
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const handlePasswordShow = () => setIsPasswordShow(!isPasswordShow);

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Title>{t("Sign in")}</S.Title>
      <S.InputWrapper>
        <S.EmailInputTitle>{t("Email address")}</S.EmailInputTitle>
        <Input css={S.input} placeholder="" register={register("email")} />
        <S.PasswordInputTitle>{t("Password")}</S.PasswordInputTitle>
        <S.InputRoot>
          <Input
            css={S.input}
            placeholder=""
            type={isPasswordShow ? "text" : "password"}
            register={register("password")}
          />
          <S.IconWrapper>
            <S.PasswordShowButton
              type="button"
              tabIndex={-1}
              isPasswordShow={isPasswordShow}
              onClick={handlePasswordShow}
              aria-label="show password"
            >
              {isPasswordShow ? <VisibleIcon /> : <UnvisibleIcon />}
            </S.PasswordShowButton>
          </S.IconWrapper>
        </S.InputRoot>
      </S.InputWrapper>
      {errors && (errors.email || errors.password) && (
        <LoginErrMsg
          css={S.loginErrMsg}
          id="login"
          text={
            (errors.email?.message || errors.password?.message) as Languages
          }
        />
      )}
      <Button
        css={S.loginBtn}
        label="Sign in"
        type="submit"
        variant="primaryLarge"
        handleClickBtn={handleSubmit}
      />
      <S.FlexWrapper>
        <ExternalLink
          css={S.externalLink}
          href="https://docs.google.com/forms/d/e/1FAIpQLSc3FHz64_MkCraNxstrwg0GG1N7xh3CmfIsyF-iwt65VN4EAQ/viewform"
          variant="ghostGray"
        >
          {t("Create account")}
        </ExternalLink>
        {/* <InternalLink
          css={S.internalLink}
          variant="ghostGray"
          to={"/resetPassword"}
        >
          {t("Reset password")}
        </InternalLink> */}
      </S.FlexWrapper>
    </S.Form>
  );
};

export default LoginForm;
