import { useState } from "react";

import { DRAG_STATUS } from "@/constants";

const useDragDrop = (
  callbackFn: (dragTargetId: string, dropTargetId: string) => void,
) => {
  const [draggabledId, setDraggabledId] = useState("");
  const [isMute, setIsMute] = useState(false);
  const [dragTargetId, setDragTargetId] = useState("");
  const [dropTargetId, setDropTargetId] = useState("");

  const handleSetDraggabledId = (id: string) => (): void => {
    setDraggabledId(id);
  };

  const handleDragStart = (id: string) => (): void => {
    notifyDragObservers(id, DRAG_STATUS.START);
  };

  const handleDragEnd = (id: string) => (): void => {
    notifyDragObservers(id, DRAG_STATUS.STOP);
  };

  const handleDragEnter = (id: string) => (): void => {
    notifyDragObservers(id, DRAG_STATUS.ENTER);
  };

  const handleDragLeave = (id: string) => (): void => {
    notifyDragObservers(id, DRAG_STATUS.LEAVE);
  };

  const handleDragOver = (e: React.DragEvent<HTMLUListElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLUListElement>) => {
    e.preventDefault();
    if (!dropTargetId) return;

    if (dragTargetId && dragTargetId !== dropTargetId) {
      typeof callbackFn === "function" &&
        callbackFn(dragTargetId, dropTargetId);
    }
    setDropTargetId("");
  };

  const notifyDragObservers = (targetId: string, state: string): void => {
    switch (state) {
      case DRAG_STATUS.START:
        setDragTargetId(targetId);
        setIsMute(true);
        break;

      case DRAG_STATUS.STOP:
        setDraggabledId("");
        setDragTargetId("");
        setIsMute(false);
        break;

      case DRAG_STATUS.ENTER:
        setDropTargetId(targetId);
        break;

      case DRAG_STATUS.LEAVE:
        if (dropTargetId === targetId) {
          setDropTargetId("");
        }
        break;

      default:
        new Error(`is not valid state ${state}`);
    }
  };

  return {
    isMute,
    draggabledId,
    dragTargetId,
    dropTargetId,
    handleSetDraggabledId,
    handleDragStart,
    handleDragEnd,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  };
};

export default useDragDrop;
