import React from "react";
import { Outlet } from "react-router-dom";

import * as S from "./PublicLayout.styled";

interface PublicLayoutProps {
  footer: React.ReactNode;
}

const PublicLayout = ({ footer }: PublicLayoutProps) => {
  return (
    <S.Root>
      <S.Wrapper>
        <S.ItemWrapper>
          <S.CustomLoginLogo />
        </S.ItemWrapper>
        <S.ItemWrapper>
          <Outlet />
        </S.ItemWrapper>
      </S.Wrapper>
      <S.CustomIfDesignIcon />
      {footer}
    </S.Root>
  );
};

export default PublicLayout;
