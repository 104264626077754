import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinBtn_ghost_b } from "@/styles";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    color: ${theme.color.black};
  `}
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
    background-color: ${theme.color.white};
  `}
`;

export const CustomPagination = css`
  border-top: 0;
`;
