export const getMeterSize = (
  length: string | number,
  depth: string | number,
  height: string | number,
) => {
  if (!depth) return "";

  return `${length}m x ${depth}m x ${height}m`;
};

export const getFileSize = (type: "KB" | "MB", size: number) => {
  switch (type) {
    case "KB":
      return +(size / 1024).toFixed(2);
    case "MB":
      return +(size / 1048576).toFixed(2);
  }
};
