import React, { useState, useEffect, useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useImmer } from "use-immer";

import {
  formatDate,
  getMonthYear,
  getNewMonth,
  onlyWithColonsInTime,
  transform24Hr,
} from "@/utils";
import { COMMON_VALID_MSG } from "@/constants";
import type { CalendarModalSelectDate } from "@/types";

const useCalendarModal = (
  selectDate: CalendarModalSelectDate,
  handleChangeSelectDate: (selectDate: CalendarModalSelectDate) => void,
) => {
  const initSelectedDate = selectDate.map(
    (date: CalendarModalSelectDate[number]) =>
      date ? date.set("hour", 0).set("minute", 0) : null,
  );

  const initMonthYear = getMonthYear(
    !selectDate[0] && !selectDate[1]
      ? dayjs()
      : dayjs(selectDate[0], "DD/MM/YYYY"),
  );

  const [currentDate, setCurrentDate] =
    useState<CalendarModalSelectDate>(initSelectedDate);
  const [time, setTime] = useImmer({
    startTime: selectDate[0] ? formatDate(selectDate[0], "HH:mm") : null,
    endTime: selectDate[1] ? formatDate(selectDate[1], "HH:mm") : null,
  });
  const [timeErr, setTimeErr] = useImmer({ startTime: "", endTime: "" });

  const [monthYear, setMonthYear] = useState(initMonthYear);
  const [nextMonthYear, setNextMonthYear] = useState(getNewMonth(monthYear, 1));
  const [isFocusStartDate, setIsFocusStartDate] = useState(false);
  const [isFocusEndDate, setIsFocusEndDate] = useState(false);

  const getTimeAppliedDate = (date: dayjs.Dayjs | string, time: string) => {
    const [hour, min] = time.split(":"),
      newDate = dayjs(date).set("hour", +hour).set("minute", +min);

    return newDate;
  };

  const isCheckEndTimeBeforeThanStartTime = (
    startDate: dayjs.Dayjs,
    startTime: string,
    endDate: dayjs.Dayjs,
    endTime: string,
  ) => {
    const startDateTime = getTimeAppliedDate(startDate, startTime);
    const endDateTime = getTimeAppliedDate(endDate, endTime);

    return endDateTime.isBefore(startDateTime, "m");
  };

  const checkTimeValid = (startTime: string, endTime: string) => {
    const formattedStartTime = transform24Hr(startTime);
    const formattedEndTime = transform24Hr(endTime);
    let hasError = false;

    if (!formattedStartTime || !formattedStartTime.valid) {
      hasError = true;
      setTimeErr((draft) => {
        draft.startTime = COMMON_VALID_MSG.TIME_VALID;
      });
    }

    if (!formattedEndTime || !formattedEndTime.valid) {
      hasError = true;
      setTimeErr((draft) => {
        draft.endTime = COMMON_VALID_MSG.TIME_VALID;
      });
    }

    return hasError;
  };

  const checkEndDateBeforeThanStartDate = (
    startDate: dayjs.Dayjs,
    startTime: string,
    endDate: dayjs.Dayjs,
    endTime: string,
  ) => {
    let hasError = false;

    const isEndTimeBeforeThanStartTime = isCheckEndTimeBeforeThanStartTime(
      startDate,
      startTime ?? "00:00",
      endDate,
      endTime ?? "00:00",
    );

    if (isEndTimeBeforeThanStartTime) {
      setTimeErr((draft) => {
        draft.startTime = COMMON_VALID_MSG.END_TIME_BEFORE_THAN_START_TIME;
        draft.endTime = COMMON_VALID_MSG.END_TIME_BEFORE_THAN_START_TIME;
      });
      hasError = true;
    } else {
      setTimeErr((draft) => {
        draft.startTime = isEndTimeBeforeThanStartTime ? timeErr.startTime : "";
        draft.endTime = isEndTimeBeforeThanStartTime ? timeErr.endTime : "";
      });
    }

    return hasError;
  };

  const checkTimeError = () => {
    if (!currentDate[0] || !currentDate[1]) return;

    const { startTime, endTime } = time;

    if (!(startTime && endTime)) return;

    const hasInvalidError = checkTimeValid(startTime, endTime);
    if (hasInvalidError) return true;

    const hasEndDateBeforeThanStartDateError = checkEndDateBeforeThanStartDate(
      currentDate[0],
      startTime,
      currentDate[1],
      endTime,
    );
    if (hasEndDateBeforeThanStartDateError) return true;
  };

  const handleSelectDateUpdate = () => {
    if (currentDate[0] && currentDate[1]) {
      const dateTime = [
        getTimeAppliedDate(currentDate[0], time.startTime || "00:00"),
        getTimeAppliedDate(currentDate[1], time.endTime || "00:00"),
      ];

      handleChangeSelectDate(dateTime);
    }
  };

  const applyTime = (name: "startTime" | "endTime", currentTime: string) => {
    const formattedTime = transform24Hr(currentTime);

    if (!formattedTime || !currentTime) return;

    if (!formattedTime.valid) {
      setTimeErr((draft) => {
        draft[name] = COMMON_VALID_MSG.TIME_VALID;
      });

      return true;
    }

    setTime((draft) => {
      draft[name] = formattedTime.value;
    });

    if (!currentDate[0] || !currentDate[1]) return;

    return false;
  };

  const handleTimeChange =
    (type: "startTime" | "endTime") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeErr((draft) => {
        draft[type] = "";
      });

      const value = e.target?.value ?? "00:00";
      if (value.length > 5) return;

      setTime((draft) => {
        draft[type] = onlyWithColonsInTime(value);
      });
    };

  const handleTimeBlur =
    (type: "startTime" | "endTime") =>
    (e: React.FocusEvent<HTMLInputElement>) => {
      applyTime(type, e.target.value);
    };

  const isPeriodOfSelectDate = (
    selectDay: Dayjs,
    baseDay: CalendarModalSelectDate,
  ) => {
    return (
      !dayjs(selectDay, "YYYY-MM-DD").isBefore(dayjs(baseDay[0]), "day") &&
      !dayjs(selectDay, "YYYY-MM-DD").isAfter(dayjs(baseDay[1]), "day")
    );
  };

  const isDisabledDate = (
    selectDay: Dayjs,
    currentDate: CalendarModalSelectDate,
  ) => {
    if (isFocusStartDate) {
      return dayjs(selectDay, "YYYY-MM-DD").isAfter(
        dayjs(currentDate[1]),
        "day",
      );
    }

    if (isFocusEndDate) {
      return dayjs(selectDay, "YYYY-MM-DD").isBefore(
        dayjs(currentDate[0]),
        "day",
      );
    }
  };

  const startEndOfTheWeek = useCallback((day: dayjs.Dayjs) => {
    const dayOfWeek = dayjs(day).day();

    if (dayOfWeek === 6) {
      return "end";
    } else if (dayOfWeek === 0) {
      return "start";
    } else {
      return "middle";
    }
  }, []);

  const handleFocusStartDate = useCallback(() => {
    setIsFocusStartDate(true);
    setIsFocusEndDate(false);
  }, [isFocusStartDate]);

  const handleFocusEndDate = useCallback(() => {
    setIsFocusStartDate(false);
    setIsFocusEndDate(true);
  }, [isFocusEndDate]);

  const handleChangePrevMonth = useCallback(() => {
    setMonthYear((prevDate) => getNewMonth(prevDate, -1));
    setNextMonthYear((prevDate) => getNewMonth(prevDate, -1));
  }, []);

  const handleChangeNextMonth = useCallback(() => {
    const newMonthYear = getNewMonth(monthYear, 1);
    setMonthYear(newMonthYear);
    setNextMonthYear(getNewMonth(monthYear, 2));
  }, [monthYear]);

  const handleClickCalendarDate = (date: dayjs.Dayjs) => () => {
    if (isFocusStartDate) {
      if (date.isSame(currentDate[0])) {
        if (currentDate.length === 1) {
          setCurrentDate([null, null]);
        }
        if (currentDate.length === 2) {
          setCurrentDate([null, currentDate[1]]);
        }
      } else {
        if (currentDate.length === 1) {
          setCurrentDate([date, null]);
        }
        if (currentDate.length === 2) {
          setCurrentDate([date, currentDate[1]]);
        }
      }
    }

    if (isFocusEndDate) {
      date.isSame(currentDate[1])
        ? setCurrentDate([currentDate[0], null])
        : setCurrentDate([currentDate[0], date]);
    }

    // NOTE: 종료[날짜+시간] > 시작[날짜+시간] 확인하여 에러 Reset
    if (currentDate[0] && currentDate[1]) {
      checkEndDateBeforeThanStartDate(
        isFocusStartDate ? date : currentDate[0],
        time.startTime ?? "00:00",
        isFocusEndDate ? date : currentDate[1],
        time.endTime ?? "00:00",
      );
    }
  };

  useEffect(() => {
    if (currentDate[0]) {
      setIsFocusStartDate(false);
      setIsFocusEndDate(true);
    }

    if (!currentDate[0]) {
      setIsFocusStartDate(true);
      setIsFocusEndDate(false);
    }
  }, [currentDate]);

  useEffect(() => {
    setCurrentDate(initSelectedDate);

    if (selectDate[0]) {
      setTime((draft) => {
        draft.startTime = selectDate[0]
          ? selectDate[0].format("HH:mm")
          : "00:00";
      });
    }
    if (selectDate[1]) {
      setTime((draft) => {
        draft.endTime = selectDate[1] ? selectDate[1].format("HH:mm") : "00:00";
      });
    }
  }, []);

  return {
    dateFocus: {
      isFocusStartDate,
      isFocusEndDate,
      handleFocusStartDate,
      handleFocusEndDate,
    },
    isDisabledDate,
    isPeriodOfSelectDate,
    startEndOfTheWeek,
    monthYear,
    nextMonthYear,
    currentDate,
    time,
    timeErr,
    checkTimeError,
    handleTimeChange,
    handleTimeBlur,
    handleChangePrevMonth,
    handleChangeNextMonth,
    handleClickCalendarDate,
    handleSelectDateUpdate,
  };
};

export default useCalendarModal;
