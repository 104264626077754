import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_table_pagination } from "@/styles";

export const Root = styled.section`
  display: grid;
  grid-template-columns: 1fr 396px;
  min-height: 880px;
`;

export const querySearchInput = (theme: Theme) => css`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.color.gray_30};
  border-bottom: 0;
  background-color: ${theme.color.white};

  & > input {
    ${theme.font.regular_15};
    border: 0px;

    ::placeholder {
      ${theme.font.regular_15};
    }
  }
`;

export const SearchCnt = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > span {
      ${theme.font.regular_13};
      color: ${theme.color.gray_70};
      white-space: nowrap;
    }

    &::after {
      content: "";
      width: 1px;
      height: 12px;
      margin: 0 8px 0 4px;
      background-color: ${theme.color.gray_40};
    }
  `}
`;
export const queryPagination = css`
  ${mixin_table_pagination}
`;
