import { useState, useEffect } from "react";
import { findIndex } from "lodash-es";

import { useAlignCenter, useLocationMarker, useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";

import { useOrsPolylines } from "hooks";
import { usePostAllocationOrs } from "services";
import { makeDeliveryToAllocation } from "utils";
import type { AllocationRoute, SelectedAllocationDelivery } from "types";

const useAllocation = (originAllocations?: AllocationRoute[]) => {
  const [allocations, setAllocations] = useState<AllocationRoute[]>([]);

  const { addToast } = useToast();
  const { displayLocationMarker } = useLocationMarker("Allocation");
  const { alignMapCenter } = useAlignCenter([]);
  const { displayOrsPolylines, hideOrsPolyline } = useOrsPolylines();

  const { mutate: getOrsRouteMutate } = usePostAllocationOrs();

  const updateOrsRoutes = (requestedAllocations: AllocationRoute[]) => {
    if (!requestedAllocations.length) return;

    getOrsRouteMutate(requestedAllocations, {
      onSuccess: (orsRoutes) => {
        displayOrsPolylines(orsRoutes);
      },
      onError: (err) => {
        switch (err.response.data.error.code) {
          // NOTE: 2,000km 이상 경로 탐색 불가
          case 2004:
            addToast(COMMON_TOAST_MSG.WARNING.ROUTE_DISTANCE_EXCEED);
            break;
          default:
            addToast(COMMON_TOAST_MSG.WARNING.CANNOT_FIND_ROUTABLE_POINT);
            break;
        }
      },
    });
  };

  const changeMemo = (id: string, memo: string): void => {
    const selectedIdx = findIndex(allocations, (item) => item.id === id);
    const copiedAllocations = allocations.map((val, idx: number) => {
      if (idx === selectedIdx) {
        const copyVal = { ...val };
        copyVal.memo = memo;
        return copyVal;
      }
      return val;
    });

    setAllocations(copiedAllocations);
  };

  const displayMarkersAndCenterMap = (allocations: AllocationRoute[]): void => {
    const allocationMarkers = allocations.map((delivery) => ({
      type: delivery.type,
      lat: +delivery.lat,
      lng: +delivery.lng,
    }));

    displayLocationMarker(allocationMarkers);
    alignMapCenter(allocationMarkers);
  };

  const initAllocation = (originAllocations: AllocationRoute[]): void => {
    const orderIdx: { [key: string]: number } = {};
    let cnt = 0;

    originAllocations.forEach((originAllocation) => {
      if (originAllocation.type === "PICK") {
        orderIdx[originAllocation.orderId] = cnt;
        cnt += 1;
      }
    });

    const allocations: AllocationRoute[] =
      originAllocations.map((originAllocation) => ({
        ...originAllocation,
        idx: orderIdx[originAllocation.orderId],
      })) || [];

    hideOrsPolyline();
    updateOrsRoutes(allocations);
    displayMarkersAndCenterMap(allocations);
    setAllocations(allocations);
  };

  const handleAllocationAdd =
    (selectedDelivery: SelectedAllocationDelivery) => (): void => {
      const copiedAllocations = [
        ...allocations,
        makeDeliveryToAllocation(selectedDelivery, "PICK"),
        makeDeliveryToAllocation(selectedDelivery, "DROP"),
      ];

      hideOrsPolyline();
      updateOrsRoutes(copiedAllocations);
      displayMarkersAndCenterMap(copiedAllocations);
      setAllocations(copiedAllocations);
    };

  const handleAllocationDelete =
    (selectedDelivery: SelectedAllocationDelivery) => (): void => {
      const copiedAllocations = [...allocations].filter(
        (arr) => arr.orderId !== selectedDelivery.orderId,
      );

      hideOrsPolyline();
      updateOrsRoutes(copiedAllocations);
      displayMarkersAndCenterMap(copiedAllocations);
      setAllocations(copiedAllocations);
    };

  const handleAllocationWithSmartDispatchAdd = (
    allocations: AllocationRoute[],
  ): void => {
    hideOrsPolyline();
    updateOrsRoutes(allocations);
    displayMarkersAndCenterMap(allocations);
    setAllocations(allocations);
  };

  const handleDragDrop = (dragTargetId: string, dropTargetId: string): void => {
    const dragTargetIdx = allocations.findIndex(
      (item) => item.id === dragTargetId,
    );
    const dropTargetIdx = allocations.findIndex(
      (item) => item.id === dropTargetId,
    );
    const copiedAllocations = [...allocations];
    const dragTarget = copiedAllocations.splice(dragTargetIdx, 1);

    if (dragTargetIdx > dropTargetIdx) {
      dropTargetIdx === 0
        ? copiedAllocations.unshift(dragTarget[0])
        : copiedAllocations.splice(dropTargetIdx, 0, dragTarget[0]);
    } else {
      copiedAllocations.splice(dropTargetIdx, 0, dragTarget[0]);
    }

    hideOrsPolyline();
    updateOrsRoutes(copiedAllocations);
    displayMarkersAndCenterMap(copiedAllocations);
    setAllocations(copiedAllocations);
  };

  useEffect(() => {
    if (!originAllocations) return;

    initAllocation(originAllocations);
  }, [originAllocations]);

  return {
    allocations,
    handleDragDrop,
    changeMemo,
    handleAllocationAdd,
    handleAllocationDelete,
    handleAllocationWithSmartDispatchAdd,
  };
};

export default useAllocation;
