/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useId,
} from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading, NoResult, Portal } from "@/components";
import { useDomReady } from "@/hooks";
import { WarningIcon64 } from "@/assets";
import type { Languages, TableColumn, TableColumns } from "@/types";

import * as S from "./Table.styled";

const TableContext = createContext({} as any);

const TABLE_ROW_ID_PREFIX = "table-row";
const TABLE_DETAIL_ROW_ID_PREFIX = "table-detail-row";

interface TableProps {
  className?: string;
  children: any;
  title: any;
  isStickyThead?: boolean;
  hasRowBtn?: any;
  isLoading?: boolean;
  openTriggerColumnIdx?: number;
  handleSelectRow?: any;
  rowCount: number;
}

const Table = ({
  className,
  children,
  title,
  isStickyThead = false,
  hasRowBtn = false,
  isLoading = false,
  openTriggerColumnIdx = 0,
  handleSelectRow,
  rowCount,
}: TableProps) => {
  const [openDetailInfoRowId, setOpenDetailInfoRowId] = useState("");

  return (
    <S.Root
      className={className}
      aria-rowcount={rowCount}
      isStickyThead={isStickyThead}
    >
      <TableContext.Provider
        value={{
          hasRowBtn,
          isLoading,
          openTriggerColumnIdx,
          openDetailInfoRowId,
          setOpenDetailInfoRowId,
          handleSelectRow,
        }}
      >
        <caption className="a11y">{title}</caption>
        {children}
      </TableContext.Provider>
    </S.Root>
  );
};

interface TableHeadProps {
  className?: string;
  columnList: TableColumns<string>;
}

Table.Head = function TableHeader({ className, columnList }: TableHeadProps) {
  const { t } = useTranslation();

  const hasDepth = columnList.length !== columnList.flat(2).length;

  return (
    <thead className={className}>
      <S.Trow>
        {columnList.map((columns, i: number) => {
          if (columns.length === 1) {
            const column = columns[0] as TableColumn<string>;
            return (
              <S.Th key={column.key} rowCnt={hasDepth ? 3 : 1} scope="col">
                {t(column.label as Languages)}
              </S.Th>
            );
          }

          return columns.map((column) => {
            if (Array.isArray(column)) {
              return column.map((col) => (
                <S.Th key={col.key} scope="col" hasMultiCol>
                  {t(col.label as Languages)}
                </S.Th>
              ));
            } else {
              return (
                <S.Th
                  key={column.key}
                  scope="col"
                  hasMultiCol
                  isLast={columnList.length - 1 === i}
                  rowCnt={1}
                  columnCnt={(columns as TableColumn<string>[][])[1].length}
                >
                  {t(column.label as Languages)}
                </S.Th>
              );
            }
          });
        })}
      </S.Trow>
    </thead>
  );
};

interface TableBodyProps {
  children: React.ReactNode;
  className?: string;
}

Table.Body = function TableBody({ children, className }: TableBodyProps) {
  const { isLoading } = useContext(TableContext);

  const loadingContent = (
    <S.LoadingRow>
      <td>
        <Loading />
      </td>
    </S.LoadingRow>
  );

  return (
    <S.Tbody className={className}>
      {isLoading ? loadingContent : children}
    </S.Tbody>
  );
};
interface TableRowProps {
  children: React.ReactNode;
  className?: string;
}

Table.Row = function TableRow({ children, className }: TableRowProps) {
  return <S.Trow className={className}>{children}</S.Trow>;
};

interface TableClickRowProps {
  children: React.ReactNode;
  id: any;
}

Table.ClickRow = function TableClickRow({ children, id }: TableClickRowProps) {
  const domReady = useDomReady();

  const rowId = `${TABLE_ROW_ID_PREFIX}-${id}`;
  const detailId = `${TABLE_DETAIL_ROW_ID_PREFIX}-${id}`;

  const {
    className,
    openTriggerColumnIdx,
    hasRowBtn,
    openDetailInfoRowId,
    setOpenDetailInfoRowId,
    handleSelectRow,
  } = useContext(TableContext);

  const handleClickRow = () => {
    typeof handleSelectRow === "function"
      ? handleSelectRow(id)
      : handleOpenRowDetailInfo(id);
  };

  const handleOpenRowDetailInfo = (id: string) => {
    const detailRowId = openDetailInfoRowId === id ? null : id;
    setOpenDetailInfoRowId(detailRowId);
  };

  return (
    <>
      <S.Trow
        id={rowId}
        className={className}
        aria-selected={openDetailInfoRowId === id}
      >
        {children}
      </S.Trow>
      {hasRowBtn &&
        domReady &&
        createPortal(
          <>
            <S.DetailOpenButton
              type="button"
              aria-controls={detailId}
              aria-expanded={openDetailInfoRowId === id}
              onClick={handleClickRow}
            >
              <span className="a11y">open detail row</span>
            </S.DetailOpenButton>
          </>,
          (document.querySelector as any)(
            `#${rowId} > td:nth-of-type(${openTriggerColumnIdx + 1})`,
          ),
        )}
    </>
  );
};

interface TableDetailRowProps {
  children: React.ReactNode;
  className?: string;
  id: string;
}

Table.DetailRow = function TableDetailRow({
  children,
  className,
  id,
}: TableDetailRowProps) {
  const rowRef = useRef(null) as any;

  const detailId = `${TABLE_DETAIL_ROW_ID_PREFIX}-${id}`;
  const { openDetailInfoRowId } = useContext(TableContext);

  useEffect(() => {
    if (id === openDetailInfoRowId) {
      setTimeout(() => rowRef.current.focus());
    }
  }, [openDetailInfoRowId]);

  return (
    <>
      {id === openDetailInfoRowId && (
        <S.DetailRow
          ref={rowRef}
          id={detailId}
          className={className}
          tabIndex={0}
        >
          <S.DetailInfoWrapper>{children}</S.DetailInfoWrapper>
        </S.DetailRow>
      )}
    </>
  );
};

interface RowProps {
  className?: string;
  children: React.ReactNode;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface SelectRowProps extends RowProps {
  isSelected: boolean;
  handleRowClick: () => void;
}

Table.SelectRow = function SelectRow({
  className,
  children,
  isSelected,
  handleRowClick,
}: SelectRowProps) {
  const domReady = useDomReady();

  const rowId = useId().replace(/[^a-zA-Z0-9-_]/g, "");

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <S.RowButton type="button" onClick={handleRowClick}>
            <span className="a11y">select row</span>
          </S.RowButton>
        </td>
      </Portal>
    </>
  );
};

interface SelectRowMovePageProps extends RowProps {
  path: string;
  isSelected?: boolean;
  isOpenNewTab?: boolean;
  state?: Record<string, string | null>;
}

Table.SelectRowMovePage = function SelectRowMovePage({
  className,
  children,
  isSelected = false,
  path,
  isOpenNewTab,
  state,
}: SelectRowMovePageProps) {
  const domReady = useDomReady();

  const rowId = useId().replace(/[^a-zA-Z0-9-_]/g, "");

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <Link
            css={S.rowlink}
            to={path}
            target={isOpenNewTab ? "_blank" : "_self"}
            state={state} // TODO: 상태값 추가 되면 삭제하기
          >
            <span className="a11y">select row</span>
          </Link>
        </td>
      </Portal>
    </>
  );
};

interface SelectRowOpenSidePanelProps extends RowProps {
  path: string;
  isSelected?: boolean;
  isOpenNewTab?: boolean;
}

Table.SelectRowOpenSidePanel = React.forwardRef<
  HTMLAnchorElement,
  SelectRowOpenSidePanelProps
>(function SelectRowOpenSidePanel(
  { className, children, isSelected = false, path, isOpenNewTab },
  ref,
) {
  const domReady = useDomReady();

  const rowId = useId().replace(/[^a-zA-Z0-9-_]/g, "");

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <Link
            css={S.rowlink}
            ref={ref}
            to={path}
            target={isOpenNewTab ? "_blank" : "_self"}
          >
            <span className="a11y">select row</span>
          </Link>
        </td>
      </Portal>
    </>
  );
});

interface TableCellProps {
  children: React.ReactNode;
  className?: string;
  onClick?: any;
}

Table.Cell = function TableData({
  children,
  className,
  onClick,
}: TableCellProps) {
  return (
    <S.Td className={className} onClick={onClick}>
      {children}
    </S.Td>
  );
};

interface NodataProps {
  className?: string;
  children?: React.ReactNode;
}

Table.NoData = function NoData({ className, children }: NodataProps) {
  return (
    <S.NoData className={className}>
      <td>
        <NoResult />
        {children}
      </td>
    </S.NoData>
  );
};

interface NotSelectRequiredDataProps {
  className?: string;
}

Table.NotSelectRequiredData = function NotSelectRequiredData({
  className,
}: NotSelectRequiredDataProps) {
  const { t } = useTranslation();

  return (
    <S.NotSelectRequiredData className={className}>
      <td>
        <WarningIcon64 />
        {t("Please select a period to check the settlement.")}
      </td>
    </S.NotSelectRequiredData>
  );
};

export default Table;
