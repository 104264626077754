import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReadField, UpdateField } from "./field";
import * as S from "./DefaultInfo.styled";

const DefaultInfo = () => {
  const { t } = useTranslation();

  const [isUpdateField, setIsUpdateField] = useState(false);

  const handleChangeReadField = () => setIsUpdateField(false);
  const handleChangeUpdateField = () => setIsUpdateField(true);

  const Field = isUpdateField ? (
    <UpdateField handleChangeReadField={handleChangeReadField} />
  ) : (
    <ReadField handleChangeUpdateField={handleChangeUpdateField} />
  );

  return (
    <article>
      <S.Title>{t("Default information")}</S.Title>
      {Field}
    </article>
  );
};

export default DefaultInfo;
