import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./LinkMenuDialog.styled";

interface LinkMenuDialogProps {
  handleUnlink: any;
  handleClickEdit: any;
}

const LinkMenuDialog = ({
  handleUnlink,
  handleClickEdit,
}: LinkMenuDialogProps) => {
  const { t } = useTranslation();

  return (
    <S.Root>
      <ul>
        <li>
          <S.Btn type="button" onClick={handleClickEdit}>
            {t("Edit")}
          </S.Btn>
        </li>
        <li>
          <S.Btn type="button" onClick={handleUnlink}>
            {t("Unlink all")}
          </S.Btn>
        </li>
      </ul>
    </S.Root>
  );
};

export default LinkMenuDialog;
