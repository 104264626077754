import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div<{ isClose: boolean }>`
  ${({ theme, isClose }) => css`
    display: ${isClose ? "none" : "flex"};
    flex-direction: column;
    width: ${theme.size.FOLDABLE_SHORT_LAYOUT_WIDTH};
    border-left: 1px solid ${theme.color.gray_20};
    background-color: ${theme.color.white};
    transition-duration: 0.5s;
  `}
`;

const mixinBtn = (theme: Theme) => css`
  position: absolute;
  top: 50px;
  right: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 0 5px 5px 0;
  background-color: ${theme.color.white};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  z-index: -1;
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    ${mixinBtn(theme)};

    & > svg path {
      width: 12px;
      height: 12px;
      fill: ${theme.color.gray_80};
    }
  `}
`;

export const FolderBtn = styled.button`
  ${({ theme }) => css`
    ${mixinBtn(theme)};

    & > svg path {
      width: 18px;
      height: 18px;
      fill: ${theme.color.gray_80};
    }
  `}
`;
