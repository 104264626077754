import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  width: max-content;
  padding: 4px;
`;

export const button = css`
  width: 100%;
  border: 0;
  justify-content: left;
`;
