import React from "react";

import * as S from "./TableLayout.styled";

interface TableLayoutProps {
  children: React.ReactNode;
  h2?: string;
}

const TableLayout = ({ children, h2 }: TableLayoutProps) => {
  return (
    <S.Root>
      {h2 && <h2 className="a11y">{h2}</h2>}
      {children}
    </S.Root>
  );
};

export default TableLayout;
