import React from "react";
import { useTranslation } from "react-i18next";

import { Button, InternalLink, TableOfContent } from "@/components";
import { useModal } from "@/hooks";
import { DelTruckIcon, PATH } from "@/assets";

import {
  Specification,
  TruckDrivingRecords,
  DelTruckModal,
  TruckDefaultInfo,
} from "components";
import * as S from "./TruckDetail.styled";

const TruckDetail = () => {
  const { t } = useTranslation();

  const { handleModalOpen, modalRef } = useModal();

  const contents = [
    {
      heading: { text: "Default information" },
      content: <TruckDefaultInfo />,
    },
    {
      heading: { text: "Specification" },
      content: <Specification />,
    },
    { heading: { text: "Driving records" }, content: <TruckDrivingRecords /> },
  ];

  return (
    <S.Layout>
      <TableOfContent
        css={S.toc}
        stickyTop={<S.TocHeading>{t("Truck details")}</S.TocHeading>}
        stickyBottom={
          <Button
            css={S.button}
            variant="ghostGray"
            label="Delete the truck"
            icon={<DelTruckIcon />}
            handleClickBtn={handleModalOpen(<DelTruckModal ref={modalRef} />)}
          />
        }
        contents={contents}
      />
      <InternalLink
        css={S.backLink}
        variant="outlineMedium"
        to={`${PATH.PROPERTY}?tab=truck`}
      >
        {t("Back to list")}
      </InternalLink>
    </S.Layout>
  );
};

export default TruckDetail;
