import React from "react";
import type { UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DetailModal } from "@/components";
import { calculateTotalAmount, formatOrderNum, formatPrice } from "@/utils";

import type { QuotationSendForm } from "types";
import * as S from "./SendQuotationCheckModal.styled";

interface SendQuotationCheckModalProps {
  truckingCompany: string;
  watch: UseFormWatch<QuotationSendForm>;
  handleSend: () => void;
}

const SendQuotationCheckModal = React.forwardRef(
  (
    { truckingCompany, watch, handleSend }: SendQuotationCheckModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <DetailModal
        ref={ref}
        title="Send quotation"
        posBtnLabel="Send"
        callbackFn={handleSend}
      >
        <S.Body>
          <S.Quotation>
            <S.Title>{t("Trucking company")}</S.Title>
            <S.ContentWrapper>
              {watch("isCheckSelfTransport") ? (
                <span> {truckingCompany}</span>
              ) : (
                watch("companyIds").map((company, i) => (
                  <span key={i}>{company.name}</span>
                ))
              )}
            </S.ContentWrapper>
          </S.Quotation>
          {watch("orders").map((order, i) => {
            const totalPrice = formatPrice(
              calculateTotalAmount(order.subtotal ?? "0", order.tax),
            );

            return (
              <S.Quotation key={i}>
                <S.Title>
                  {formatOrderNum(order.orderNumber).replace("#", "")}
                </S.Title>
                <S.ContentWrapper>
                  <span>{totalPrice}</span>
                  {order.memo && <S.Content>{order.memo}</S.Content>}
                </S.ContentWrapper>
              </S.Quotation>
            );
          })}
        </S.Body>
      </DetailModal>
    );
  },
);

SendQuotationCheckModal.displayName = "SendQuotationCheckModal";

export default SendQuotationCheckModal;
