import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetQuotationCompleted } from "services";
import type { CompanyType, GetQuotationCompletedQueryModel } from "types";
import QuotationCompletedSection from "./section/QuotationCompletedSection";
import * as S from "./QuotationCompleted.styled";

interface QuotationCompletedProps {
  clientType: CompanyType;
}

const QuotationCompleted = ({ clientType }: QuotationCompletedProps) => {
  const { filters } = useFilterParams<GetQuotationCompletedQueryModel["query"]>(
    ["page"],
  );

  const { data, dataUpdatedAt, refetch } = useGetQuotationCompleted<
    typeof clientType
  >({ query: filters });

  return (
    <>
      {data && (
        <>
          <QuotationCompletedSection
            data={data}
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default QuotationCompleted;
