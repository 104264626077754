import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import {
  CARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES,
  FORWARDER_FORWARDERCARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES,
  SHIPPER_QUOTATION_ONGOING_TABLE_CELL_SIZES,
} from "assets";
import type { CompanyType } from "types";

export const table = (clientType: CompanyType) => css`
  tr {
    grid-template-columns: ${clientType === "forwarder" ||
    clientType === "forwardercarrier"
      ? FORWARDER_FORWARDERCARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES
      : clientType === "shipper"
      ? SHIPPER_QUOTATION_ONGOING_TABLE_CELL_SIZES
      : CARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES};
  }
`;

export const linknoData = (theme: Theme) => css`
  ${theme.button.outlineMedium};
  margin: 16px 0 0;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 100%;
`;

export const Count = styled.div`
  ${({ theme }) => css`
    ${theme.font.semibold_12};
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 2px 4px;
    color: ${theme.color.gray_60};
  `}
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

export const cell = css`
  :nth-last-of-type(2) {
    display: inline-flex;
  }
`;

export const linkDetail = (theme: Theme) => css`
  ${theme.button.ghostGray};
  z-index: ${theme.zIndex.ROW_DETAIL_BUTTON};
`;

export const CompanyWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      ${theme.font.semibold_12};
      border: 1px solid ${theme.color.gray_35};
      border-radius: 2px;
      padding: 2px;
      line-height: calc(16 / 12);
      color: ${theme.color.gray_60};
    }
  `}
`;
