import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";

import { InternalLink, LabelContentTable } from "@/components";
import { comma, formatICTDateTime, formatOrderNum } from "@/utils";
import { PATH } from "@/assets";

import { useGetPropertyTruckDetail } from "services";
import * as S from "./TruckDrivingRecords.styled";

const TruckDrivingRecords = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetPropertyTruckDetail(id!);

  if (!data) return null;

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment time">
          {data.drivingRecords.totalShipmentTime
            ? `${comma(data.drivingRecords.totalShipmentTime)} hours`
            : "0 hours"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment distance">
          {!isNil(data.drivingRecords.totalShipmentDistance)
            ? `${comma(data.drivingRecords.totalShipmentDistance)} km`
            : "0 km"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Last shipping on">
          {data.drivingRecords.lastShippingOn
            ? formatICTDateTime(
                data.drivingRecords.lastShippingOn,
                "DD/MM/YYYY",
              )
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Recent allocation">
          {data.drivingRecords.recentAllocation ? (
            <S.AllocationContent>
              <span>
                {formatOrderNum(data.drivingRecords.recentAllocation)}
              </span>
              <InternalLink
                css={S.link}
                variant="outlineMedium"
                to={`${PATH.PROPERTY_TRUCK_ALLOCATION}/${id!}`}
              >
                {t("See all")}
              </InternalLink>
            </S.AllocationContent>
          ) : (
            "-"
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Completed orders">
          {data.drivingRecords.progressed
            ? comma(data.drivingRecords.progressed)
            : "0"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default TruckDrivingRecords;
