import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import type { S3FileUploadType } from "@/types";

import { ax } from "apis/axios";
import { createImgUrls, getS3PresignedUrlAPI } from "apis";
import {
  filterDuplicateImg,
  addPrefixToUrlIfNeeded,
  makeS3PresignedUrlFileUuids,
} from "utils";
import type {
  GetTruckQueryModel,
  CreateTruckQueryModel,
  UpdateTruckDefaultInfoQueryModel,
  UpdateTruckSpecificationInfoQueryModel,
  CreateDriverLinkQueryModel,
  GetDriverSearchQueryModel,
  GetTruckAllocationQueryModel,
  GetTruckServerModel,
  GetTruckGarageServerModel,
  GetDriverSearchServerModel,
  GetTruckDetailServerModel,
  UpdateTruckDefaultInfoServerModel,
  GetTruckAllocationServerModel,
  GetGpsTrucksServerModel,
} from "types";

export const getTrucksAPI = async (req: GetTruckQueryModel) => {
  const { data } = await ax.get<GetTruckServerModel>("/property/truck", {
    params: req.query,
  });

  const newTrucks = await Promise.all(
    data.trucks.map(async (truck) => {
      const profileS3Key = truck.profileS3Key
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(truck.profileS3Key, "profile"),
          )
        : truck.profileS3Key;

      return {
        ...truck,
        profileS3Key,
      };
    }),
  );

  return { ...data, trucks: newTrucks };
};

export const getPropertyTruck = (params: GetTruckQueryModel) => {
  return ax.get("/property/truck", { params: { ...params, pageSize: 20 } });
};

export const createTruckAPI = async (req: CreateTruckQueryModel) => {
  const files: S3FileUploadType = {
    registration: {
      file: req.registration,
      path: "license",
    },
    ...(req.truckPhoto && {
      truckPhoto: {
        file: req.truckPhoto,
        path: "profile",
      },
    }),
  };

  const fileUuids = await makeS3PresignedUrlFileUuids(files);

  const body = {
    ...req.body,
    ...fileUuids,
  };

  return await ax.post("/property/truck", body);
};

export const getTruckDetailAPI = async (truckId: string) => {
  const { data } = await ax.get<GetTruckDetailServerModel>(
    `/property/truck/${truckId}`,
  );
  // NOTE: 상세 조회 API 수정 후 작업 예정
  const linkedDrivers = await Promise.all(
    data.linked.map(async (link) => ({
      ...link,
      profileUrl: link.profileS3Key
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(link.profileS3Key, "profile"),
          )
        : null,
      selected: true,
    })),
  );
  return { ...data, linked: linkedDrivers };
};

export const updateTruckDefaultInfoAPI = async (
  req: UpdateTruckDefaultInfoQueryModel,
) => {
  const files: S3FileUploadType = {
    ...(req.body.truckPhoto instanceof File && {
      truckPhoto: {
        file: req.body.truckPhoto,
        path: "profile",
      },
    }),
    ...(req.body.registration instanceof File && {
      registration: {
        file: req.body.registration,
        path: "license",
      },
    }),
  };

  const fileUuids = await makeS3PresignedUrlFileUuids(files);

  await ax.put<UpdateTruckDefaultInfoServerModel>(
    `/property/truck/${req.truckId}/default`,
    { ...req.body, ...fileUuids },
  );
};

export const updateTruckSpecificationAPI = (
  req: UpdateTruckSpecificationInfoQueryModel,
) => ax.put(`/property/truck/${req.truckId}/spec`, req.body);

export const deleteTruckAPI = (truckId: string) =>
  ax.delete(`/property/truck/${truckId}`);

export const createDriverLinkAPI = (req: CreateDriverLinkQueryModel) =>
  ax.post(`/property/truck/${req.truckId}/driver`, req.body);

export const getTruckGaragesAPI = async () => {
  const { data } = await ax.get<GetTruckGarageServerModel[]>(
    "/property/truck/garage",
  );

  return data;
};

export const getLinkedDriverAPI = async (req: GetDriverSearchQueryModel) => {
  const { data } = await ax.get<GetDriverSearchServerModel>(
    "/property/truck/driver",
    { params: req.query },
  );

  return data;
};

export const getLinkManageDriverListAPI = async () => {
  const { data } = await ax.get<GetDriverSearchServerModel>(
    "/property/truck/driver",
  );

  const drivers = await Promise.all(
    data.map(async (driver) => ({
      ...driver,
      profile: driver.profile
        ? await getS3PresignedUrlAPI(
            addPrefixToUrlIfNeeded(driver.profile, "profile"),
          )
        : null,
    })),
  );

  return drivers;
};

export const getTruckAllocationsAPI = async (
  req: GetTruckAllocationQueryModel,
) => {
  const { data } = await ax.get<GetTruckAllocationServerModel>(
    `/property/truck/${req.truckId}/allocation`,
    { params: req.query },
  );

  const filteredProfiles = filterDuplicateImg(
    data.allocationList,
    "driverProfile",
  );
  const photoUrls = await createImgUrls(filteredProfiles);

  const newData = {
    ...data,
    allocationList: data.allocationList.map((allocation, i) => ({
      ...allocation,
      driverProfileUrl: photoUrls[i],
    })),
  };

  return newData;
};

export const getGpsTrucksAPI = async () => {
  const { data } = await ax.get<GetGpsTrucksServerModel>("/property/truck/gps");

  return data;
};
