import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";
import {
  Button,
  DownloadBtn,
  ErrMsg,
  Input,
  InternalLink,
  LabelContent,
  LabelContentTable,
} from "@/components";
import { onlyNumber, upperCaseToupperFirst } from "@/utils";
import { DownArrowIcon16, PATH, PHONE_MAX_LENGTH } from "@/assets";
import { MEMBER_GRADE } from "@/constants";
import type { Languages } from "@/types";

import { LicenseRegistrationLink } from "components";
import { useDownloadFile } from "services";
import useEditCompany from "./hooks/useEditCompany";
import { CSVN_CARRIER_COMPANY_ID } from "../readField/ReadField";
import * as S from "./UpdateField.styled";

interface UpdateFieldProps {
  handleChangeReadField: () => void;
}

const UpdateField = ({ handleChangeReadField }: UpdateFieldProps) => {
  const { t } = useTranslation();

  const { auth } = useAuth();

  const {
    mutate: downloadFrontBusinessLicenseFileMutate,
    isLoading: isFrontBusinessLicenseLoading,
  } = useDownloadFile();
  const {
    mutate: downloadBackBusinessLicenseFileMutate,
    isLoading: isBackBusinessLicenseLoading,
  } = useDownloadFile();
  const { mutate: downloadFrontERCFileMutate, isLoading: isFrontERCLoading } =
    useDownloadFile();
  const { mutate: downloadBackERCFileMutate, isLoading: isBackERCLoading } =
    useDownloadFile();
  const {
    isUpdateCompanyInfoLoading,
    data,
    errors,
    register,
    watch,
    handleSubmit,
  } = useEditCompany(handleChangeReadField);

  if (!data) return null;

  return (
    <form onSubmit={handleSubmit}>
      <LabelContentTable variant="whiteBg">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Company name">
            {data.name}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {auth.clientType !== "shipper" && (
          <LabelContentTable.Row>
            <LabelContentTable.Content labelWidth={180} label="Plan">
              <S.PlanContent>
                {t(upperCaseToupperFirst(data.plan) as Languages)}
                {auth.companyId === CSVN_CARRIER_COMPANY_ID &&
                  (auth.clientType === "carrier" ||
                    auth.clientType === "forwarder") && (
                    <InternalLink
                      css={S.detailLink}
                      variant="ghostPrimary"
                      to={PATH.SETTINGS_COMPANY_PLAN_EDIT}
                    >
                      {t("Edit")} <DownArrowIcon16 />
                    </InternalLink>
                  )}
              </S.PlanContent>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        {auth.clientType !== "shipper" && (
          <LabelContentTable.Row>
            <LabelContentTable.Content
              labelWidth={180}
              label={"Payment history"}
            >
              <S.PlanContent>
                <InternalLink
                  css={S.detailLink}
                  variant="ghostPrimary"
                  to={PATH.SETTINGS_COMPANY_PLAN}
                >
                  {t("Details")} <DownArrowIcon16 />
                </InternalLink>
              </S.PlanContent>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Address">
            {data.addr}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {auth.grade === MEMBER_GRADE.manager && (
          <LabelContentTable.Row>
            <LabelContentTable.Content labelWidth={180} label="Tax code">
              {data.taxCode}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Tel.">
            <LabelContent css={S.labelContent} label="" direction="vertical">
              <Input
                css={S.labelContentInput}
                maxLength={PHONE_MAX_LENGTH}
                placeholder="Only numbers"
                value={watch("phone")}
                register={register("phone", { setValueAs: onlyNumber })}
                hasError={!!errors.phone?.message}
              />
              {!!errors.phone?.message && (
                <ErrMsg css={S.errorMsg}>
                  {errors.phone?.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="E-mail">
            <LabelContent css={S.labelContent} label="" direction="vertical">
              <Input
                css={S.labelContentInput}
                placeholder="E-mail"
                value={watch("email")}
                register={register("email")}
                hasError={!!errors.email?.message}
              />
              {!!errors.email?.message && (
                <ErrMsg css={S.errorMsg}>
                  {errors.email?.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {auth.grade === MEMBER_GRADE.manager &&
          (auth.clientType === "carrier" ||
            auth.clientType === "forwardercarrier") && (
            <LabelContentTable.Row>
              <LabelContentTable.Content
                labelWidth={180}
                label="Business license"
              >
                <S.DownloadBtnWrapper>
                  {data.transportLicense1 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Front of Transportation business license"
                      fileName="Front of Transportation business license"
                      fileKey={data.transportLicense1}
                      isLoading={isFrontBusinessLicenseLoading}
                      downloadFileMutate={
                        downloadFrontBusinessLicenseFileMutate
                      }
                    />
                  )}
                  {data.transportLicense2 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Back of Transportation business license"
                      fileName="Back of Transportation business license"
                      fileKey={data.transportLicense2}
                      isLoading={isBackBusinessLicenseLoading}
                      downloadFileMutate={downloadBackBusinessLicenseFileMutate}
                    />
                  )}
                </S.DownloadBtnWrapper>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
          )}
        {auth.grade === MEMBER_GRADE.manager && (
          <>
            <LabelContentTable.Row>
              <LabelContentTable.Content labelWidth={180} label="ERC">
                <S.DownloadBtnWrapper>
                  {data.license1 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Front of ERC"
                      fileName="Front of ERC"
                      fileKey={data.license1}
                      isLoading={isFrontERCLoading}
                      downloadFileMutate={downloadFrontERCFileMutate}
                    />
                  )}
                  {data.license2 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Back of ERC"
                      fileName="Back of ERC"
                      fileKey={data.license2}
                      isLoading={isBackERCLoading}
                      downloadFileMutate={downloadBackERCFileMutate}
                    />
                  )}
                </S.DownloadBtnWrapper>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LicenseRegistrationLink
              licenseRequestState={data.licenseRequest}
            />
          </>
        )}
      </LabelContentTable>
      <S.ButtonWrapper>
        <Button
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleChangeReadField}
        />
        <Button
          isLoading={isUpdateCompanyInfoLoading}
          type="submit"
          variant="primaryMedium"
          label="Update"
          isDisabled={Object.keys(errors).length > 0}
        />
      </S.ButtonWrapper>
    </form>
  );
};

export default UpdateField;
