import { css } from "@emotion/react";

import { font } from "@/styles/themes/font";
import { color } from "@/styles/themes/color";

export const mixinInput = css`
  ${font.regular_14};
  width: 100%;
  height: 40px;
  border: 1px solid ${color.gray_35};
  border-radius: 2px;
  padding: 9px 11px;

  &::placeholder {
    ${font.regular_14};
    color: ${color.gray_50};
  }

  &:not(:disabled):not([aria-invalid="true"]):hover {
    border-color: ${color.primary_40};
  }

  &:focus {
    border: 1px solid ${color.primary_40};
  }

  &[aria-invalid="true"] {
    border-color: ${color.red_20};
  }
`;

export const input = {
  default: css`
    ${mixinInput}
    color: ${color.black};
  `,

  icon: css`
    ${mixinInput}
    padding: 9px 48px 9px 12px;

    & + button {
      position: absolute;
      top: 8px;
      right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  `,

  primary: css`
    ${font.regular_15};
    width: 100%;
    height: 52px;
    border: 1px solid ${color.gray_30};
    padding: 9px 12px;
    transition: 0.3s;

    &::placeholder {
      ${font.regular_14};
      color: ${color.gray_40};
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        border-color: ${color.primary_40};
      }
    }

    &:focus {
      border-color: ${color.primary_20};
    }

    &[aria-invalid="true"] {
      border-color: ${color.red_20};
    }

    &[readonly] {
      background-color: ${color.gray_40};
    }

    &:disabled {
      border-color: ${color.gray_20};
      color: ${color.gray_70};
      background-color: ${color.gray_10};
      cursor: not-allowed;
    }
  `,
} as const;

export type InputType = typeof input;
