import { RouterLocation, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useAuth } from "@/contexts";
import { PATH } from "@/assets";
import { COMMON_ERROR_CODE } from "@/constants";
import type {
  APIError,
  ChangePasswordQueryModel,
  ChangePasswordServerModel,
  CheckEmailAuthNumQueryModel,
  LoginQueryModel,
} from "@/types";

import {
  loginAPI,
  resetPassword,
  checkEmailAuthNum,
  changePassword,
  logoutAPI,
} from "apis";

export const usePostLogin = () => {
  const navigate = useNavigate();
  const { state }: RouterLocation = useLocation();

  const { auth } = useAuth();

  return useMutation({
    mutationFn: (req: LoginQueryModel) => loginAPI(req),
    onSuccess: (res) => {
      auth.save(res);

      state?.from ? navigate(state?.from) : navigate(PATH.ORDER_MANAGEMENT);
    },
  });
};

export const useLogout = () => {
  const navigate = useNavigate();

  const { auth } = useAuth();

  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>>({
    mutationFn: () => logoutAPI(auth.refreshToken),
    onSuccess: () => {
      auth.clear();

      navigate("/", { state: "" });
    },
  });
};

export const usePostResetPassword = () =>
  useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (email) => resetPassword(email),
  });

export const useCheckEmailAuthNum = () =>
  useMutation<
    ChangePasswordServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    CheckEmailAuthNumQueryModel
  >({ mutationFn: (req) => checkEmailAuthNum(req) });

export const usePatchChangePassword = () =>
  useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    ChangePasswordQueryModel
  >({
    mutationFn: (req) => changePassword(req),
  });
