/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { ForwardedRef } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { isEmpty, upperCase } from "lodash-es";

import { DetailModal, ErrMsg, Input } from "@/components";
import { useModal } from "@/hooks";
import { formatDate } from "@/utils";
import { ChevronLeftIcon, ChevronRightIcon, WEEKS } from "@/assets";
import type { CalendarModalSelectDate, Languages } from "@/types";

import CalendarDate from "../calendarDate/CalendarDate";
import useCalendarModal from "./hooks/useCalendarModal";
import * as S from "./CalendarModal.styled";

interface CalendarModalProps {
  className?: string;
  title: Languages;
  byStartDate?: string;
  selectDate: CalendarModalSelectDate;
  isStartDateDisabled?: boolean;
  handleChangeSelectDate: (selectDate: CalendarModalSelectDate) => void;
}

const CalendarModal = React.forwardRef(
  (
    {
      className,
      title,
      selectDate,
      handleChangeSelectDate,
    }: CalendarModalProps,
    ref: ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    const { handleModalClose } = useModal();

    const {
      dateFocus: {
        isFocusStartDate,
        isFocusEndDate,
        handleFocusStartDate,
        handleFocusEndDate,
      },
      isPeriodOfSelectDate,
      startEndOfTheWeek,
      monthYear,
      nextMonthYear,
      currentDate,
      time,
      timeErr,
      isDisabledDate,
      checkTimeError,
      handleTimeChange,
      handleTimeBlur,
      handleChangePrevMonth,
      handleChangeNextMonth,
      handleClickCalendarDate,
      handleSelectDateUpdate,
    } = useCalendarModal(selectDate, handleChangeSelectDate);

    const handleClickUpdate = () => {
      const error = checkTimeError();
      if (error) return;

      handleSelectDateUpdate();
      handleModalClose();
    };

    const timeErrMsgs = Object.values(timeErr).filter(Boolean);

    return (
      <DetailModal
        ref={ref}
        className={className}
        css={S.detailModal}
        title={title}
        posBtnLabel="Confirm"
        callbackFn={handleClickUpdate}
        isDisabled={!currentDate[0] || !currentDate[1] || !isEmpty(timeErrMsgs)}
      >
        <S.FlexWrapper>
          <S.DateTimeLabel>
            {t("Start date, time ~ End date, time")}
          </S.DateTimeLabel>
          <S.DateInputContent>
            <S.DateBtn
              type="button"
              isFocus={isFocusStartDate}
              hasError={!!timeErr.startTime}
              onClick={handleFocusStartDate}
            >
              <S.DateValueWrapper>
                {currentDate[0] ? (
                  <S.CalendarInputValue>
                    {formatDate(currentDate[0], "DD/MM/YYYY")}
                  </S.CalendarInputValue>
                ) : (
                  <S.CalendarInputPlaceholder>
                    {t("Start date")}
                  </S.CalendarInputPlaceholder>
                )}
              </S.DateValueWrapper>
              <S.Bar />
              <Input
                css={S.timeInput}
                // NOTE: 시간은 번역이 필요 없지만 타입으로 인해 as Languages 단언
                placeholder={"00:00" as Languages}
                maxLength={5}
                value={time.startTime ?? ""}
                handleChangeValue={handleTimeChange("startTime")}
                handleBlur={handleTimeBlur("startTime")}
              />
            </S.DateBtn>
            <span>~</span>
            <S.DateBtn
              type="button"
              isFocus={isFocusEndDate}
              hasError={!!timeErr.endTime}
              onClick={handleFocusEndDate}
            >
              <S.DateValueWrapper>
                {currentDate[1] ? (
                  <S.CalendarInputValue>
                    {formatDate(currentDate[1], "DD/MM/YYYY")}
                  </S.CalendarInputValue>
                ) : (
                  <S.CalendarInputPlaceholder>
                    {t("End date")}
                  </S.CalendarInputPlaceholder>
                )}
              </S.DateValueWrapper>
              <S.Bar />
              <Input
                css={S.timeInput}
                // NOTE: 시간은 번역이 필요 없지만 타입으로 인해 as Languages 단언
                placeholder={"00:00" as Languages}
                maxLength={5}
                value={time.endTime ?? ""}
                handleChangeValue={handleTimeChange("endTime")}
                handleBlur={handleTimeBlur("endTime")}
              />
            </S.DateBtn>
            {timeErrMsgs[0] && (
              <ErrMsg css={S.errMsg}>{timeErrMsgs[0] as Languages}</ErrMsg>
            )}
          </S.DateInputContent>
        </S.FlexWrapper>
        <S.CalendarFlexWrapper>
          <S.CanlendarWrapper>
            <S.Header>
              <S.MonthYear>
                <div>
                  {t(upperCase(monthYear.value.format("MMM")) as Languages)}
                </div>
                <div>{monthYear.year}</div>
              </S.MonthYear>
              <S.PrevMonthBtn
                type="button"
                aria-label="move previous month"
                onClick={handleChangePrevMonth}
              >
                <ChevronLeftIcon />
              </S.PrevMonthBtn>
            </S.Header>
            <>
              <S.WeekRow>
                {WEEKS.map((week, i) => (
                  <li key={i}>{week}</li>
                ))}
              </S.WeekRow>
              <S.DateRow className={className} data-status="date">
                {[...Array(monthYear.firstDOW)].map((_, i) => (
                  <div key={i} />
                ))}
                {[...Array(monthYear.lastDate)].map((_, i) => {
                  const date = monthYear.startDate.add(i, "d");

                  return (
                    <CalendarDate
                      key={i}
                      date={date}
                      isPrevEndDateDisabled={false}
                      isDefaultDisabled={isDisabledDate(date, currentDate)}
                      isSameStartEndDate={
                        dayjs(currentDate[0]).format("DD/MM/YYYY") ===
                        dayjs(currentDate[1]).format("DD/MM/YYYY")
                      }
                      isStartDate={date.isSame(date.startOf("month"), "day")}
                      isCurrentStartDate={date.isSame(currentDate[0])}
                      isCurrentEndDate={date.isSame(currentDate[1])}
                      isOriginDate={
                        (date.isSame(currentDate[0]) &&
                          date.isSame(selectDate[0])) ||
                        (date.isSame(currentDate[1]) &&
                          date.isSame(selectDate[1]))
                      }
                      isDoneCurrentDate={Boolean(
                        currentDate[0] && currentDate[1],
                      )}
                      isPeriodOfSelectDate={isPeriodOfSelectDate(
                        date,
                        currentDate,
                      )}
                      startEndOfTheWeek={startEndOfTheWeek(date)}
                      handleClickDate={handleClickCalendarDate(date)}
                    />
                  );
                })}
              </S.DateRow>
            </>
          </S.CanlendarWrapper>
          <S.Division />
          <S.CanlendarWrapper>
            <S.Header>
              <S.MonthYear>
                <div>
                  {t(upperCase(nextMonthYear.value.format("MMM")) as Languages)}
                </div>
                <div>{nextMonthYear.year}</div>
              </S.MonthYear>
              <S.NextMonthBtn
                type="button"
                aria-label="move next month"
                onClick={handleChangeNextMonth}
              >
                <ChevronRightIcon />
              </S.NextMonthBtn>
            </S.Header>
            <>
              <S.WeekRow>
                {WEEKS.map((week, i) => (
                  <li key={i}>{week}</li>
                ))}
              </S.WeekRow>
              <S.DateRow className={className} data-status="date">
                {[...Array(nextMonthYear.firstDOW)].map((_, i) => (
                  <div key={i} />
                ))}
                {[...Array(nextMonthYear.lastDate)].map((_, i) => {
                  const date = nextMonthYear.startDate.add(i, "d");

                  return (
                    <CalendarDate
                      key={i}
                      date={date}
                      isPrevEndDateDisabled={false}
                      isSameStartEndDate={
                        dayjs(currentDate[0]).format("DD/MM/YYYY") ===
                        dayjs(currentDate[1]).format("DD/MM/YYYY")
                      }
                      isStartDate={date.isSame(date.startOf("month"), "day")}
                      isCurrentStartDate={date.isSame(currentDate[0])}
                      isCurrentEndDate={date.isSame(currentDate[1])}
                      isOriginDate={
                        (date.isSame(currentDate[0]) &&
                          date.isSame(selectDate[0])) ||
                        (date.isSame(currentDate[1]) &&
                          date.isSame(selectDate[1]))
                      }
                      isDoneCurrentDate={Boolean(
                        currentDate[0] && currentDate[1],
                      )}
                      isPeriodOfSelectDate={isPeriodOfSelectDate(
                        date,
                        currentDate,
                      )}
                      startEndOfTheWeek={startEndOfTheWeek(date)}
                      handleClickDate={handleClickCalendarDate(date)}
                    />
                  );
                })}
              </S.DateRow>
            </>
          </S.CanlendarWrapper>
        </S.CalendarFlexWrapper>
      </DetailModal>
    );
  },
);

CalendarModal.displayName = "CalendarModal";

export default CalendarModal;
