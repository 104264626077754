import { css, Theme } from "@emotion/react";

import { mixin_horizontal_scrollbar } from "@/styles";

import { SETTLEMENT_TABLE_CELL_SIZES } from "assets";

export const table = (theme: Theme) => css`
  ${mixin_horizontal_scrollbar}
  display: block;
  min-width: ${`calc(${theme.size.TABLE_SECTION_MIN_WIDTH} - ${theme.size.LAYOUT_PADDING} * 2)`};
  max-width: ${`calc(${theme.size.TABLE_SECTION_MAX_WIDTH} - ${theme.size.LAYOUT_PADDING} * 2)`};

  tr {
    grid-template-columns: ${SETTLEMENT_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;
