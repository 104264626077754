import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const AddrAutoComplete = styled.div`
  position: relative;
  width: 100%;
`;

export const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const AutocompleteInput = styled.input<{ hasError: boolean }>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_14};
    ${theme.input.default};
    flex: 1;
    height: 40px;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_35};
    border-radius: 2px;
    padding: 0 39px 0 11px;
    color: ${theme.color.black};

    ::placeholder {
      color: ${theme.color.gray_50};
    }

    &:focus-within + button > svg path {
      fill: ${theme.color.primary_40};
    }
  `}
`;

export const SearchButton = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);
    svg > g > path {
      fill: ${theme.color.gray_50};
    }
  `}
`;

export const OpenMapButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.color.gray_35};
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: ${theme.color.gray_00};

    & > svg > g {
      width: 20px;
      height: 20px;

      & > path {
        fill: ${theme.color.gray_50};
      }
    }

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const location = css`
  position: absolute;
  right: 12px;
`;

export const DropDownWrapper = styled.ul<{ isActive: boolean }>`
  ${({ theme, isActive }) => css`
    ${mixin_scrollbar};
    position: absolute;
    top: 100%;
    display: ${isActive ? "block" : "none"};
    width: 100%;
    max-height: 200px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
    z-index: 10;
  `}
`;

export const DropDownItem = styled.li`
  ${({ theme }) => css`
    padding: 10px 12px;
    background-color: inherit;
    cursor: pointer;

    font-size: 1.3rem;
    font-weight: 400;
    color: ${theme.color.gray_70};

    :hover {
      color: ${theme.color.primary_40};
      font-weight: 600;
    }
  `}
`;
