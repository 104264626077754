import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  changeDriverPasswordAPI,
  createPropertyDriverAPI,
  createTruckLinkAPI,
  deletePropertyDriverAPI,
  deleteTruckLinkAPI,
  getPropertyDriverAPI,
  getPropertyDriverAllocationAPI,
  getPropertyDriverDetailAPI,
  getPropertyDriverTruckAPI,
  updatePropertyDriverDefaultInfoAPI,
  updatePropertyDriverAccountInfoAPI,
} from "apis";
import type {
  CreateDriverQueryModel,
  ChangeDriverPasswordQueryModel,
  CreateTruckLinkQueryModel,
  DeleteDriverQueryModel,
  GetDriverQueryModel,
  GetDriverAllocationQueryModel,
  GetTruckSearchQueryModel,
  DeleteTruckLinkQueryModel,
  UpdateDriverDefaultInfoQueryModel,
  UpdateDriverAccountInfoQueryModel,
} from "types";

const driverKeys = {
  all: ["driver"],
  lists: () => [...driverKeys.all, "list"] as const,
  list: (filters: GetDriverQueryModel) =>
    [...driverKeys.lists(), { filters }] as const,
  details: () => [...driverKeys.all, "detail"] as const,
  detail: (id: string) => [...driverKeys.details(), id] as const,
  allocations: () => [...driverKeys.all, "allocationList"] as const,
  allocation: (filters: GetDriverAllocationQueryModel) =>
    [...driverKeys.allocations(), { filters }] as const,
  driverTrucks: () => [...driverKeys.all, "driverTrucks"] as const,
  driverTruck: (filters: GetTruckSearchQueryModel) =>
    [...driverKeys.driverTrucks(), { filters }] as const,
};

export const useGetPropertyDrivers = (req: GetDriverQueryModel) => {
  return useQuery({
    queryKey: driverKeys.list(req),
    queryFn: () => getPropertyDriverAPI(req),
  });
};

export const useCreatePropertyDriver = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateDriverQueryModel
  >({
    mutationFn: (req) => createPropertyDriverAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.lists()),
  });
};

export const useGetPropertyDirverAllocation = (
  filters: GetDriverAllocationQueryModel,
) => {
  return useQuery({
    queryKey: driverKeys.allocation(filters),
    queryFn: () => getPropertyDriverAllocationAPI(filters),
  });
};

export const useGetPropertyDriverTruck = (
  filters: GetTruckSearchQueryModel,
) => {
  return useQuery({
    queryKey: driverKeys.driverTruck(filters),
    queryFn: () => getPropertyDriverTruckAPI(filters),
    keepPreviousData: true,
  });
};

export const useGetPropertyDriverDetail = (req: string) => {
  return useQuery({
    queryKey: driverKeys.detail(req),
    queryFn: () => getPropertyDriverDetailAPI({ driverId: req }),
    select: (res) => ({
      driverId: res.driverId,
      id: res.id,
      defaultInfo: {
        profile: res.profile,
        profileUrl: res.profileUrl,
        lastName: res.lastName,
        middleName: res.middleName,
        firstName: res.firstName,
        phone: res.phone,
        status: res.status,
        created: res.created,
        linkedRegistNumber: res.linkedRegistNumber,
        memo: res.memo,
        license: res.license,
      },
      accountInfo: {
        bankName: res.bankName,
        bankBranch: res.bankBranch,
        bankAccountNo: res.bankAccountNo,
        bankAccountHolder: res.bankAccountHolder,
      },
      drivingRecords: {
        totalTransitTime: res.totalTransitTime,
        totalTransitDistance: res.totalTransitDistance,
        lastShippingOn: res.lastShippingOn,
        recentAllocation: res.recentAllocation,
        progressed: res.progressed,
      },
    }),
  });
};

export const useCreatePropertyTruckLink = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateTruckLinkQueryModel
  >({
    mutationFn: (req) => createTruckLinkAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.details()),
  });
};

export const useDeletePropertyTruckLink = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    DeleteTruckLinkQueryModel
  >({
    mutationFn: (req) => deleteTruckLinkAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.details()),
  });
};

export const useUpdatePropertyDriverDefaultInfo = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateDriverDefaultInfoQueryModel
  >({
    mutationFn: (req) => updatePropertyDriverDefaultInfoAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.details()),
  });
};

export const useUpdatePropertyDriverAccountInfo = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateDriverAccountInfoQueryModel
  >({
    mutationFn: (req) => updatePropertyDriverAccountInfoAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.details()),
  });
};

export const useChangeDriverPassword = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    ChangeDriverPasswordQueryModel
  >({
    mutationFn: (req) => changeDriverPasswordAPI(req),
    onSuccess: () => queryClient.invalidateQueries(driverKeys.details()),
  });
};

export const useDeletePropertyDriver = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    DeleteDriverQueryModel
  >({
    mutationFn: (req) => deletePropertyDriverAPI(req),
  });
};
