import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Header = styled.header`
  ${({ theme }) => css`
    position: sticky;
    top: ${theme.size.HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: ${theme.color.gray_00};
    z-index: ${theme.zIndex.STICKY};

    & > div:first-of-type {
      padding: 12px 0;
    }
  `}
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & > h2 {
      ${theme.font.medium_15};
      margin-right: 8px;
      color: ${theme.color.black};
    }
  `}
`;

export const Refetch = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13}
    display: flex;
    align-items: center;
    height: 20px;

    & > span {
      color: ${theme.color.gray_60};
    }

    & > time {
      margin: 0 4px;
      color: ${theme.color.gray_80};
    }
  `}
`;

export const RefetchBtn = styled.button`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${theme.color.gray_20};
  `}
`;

export const ActiveWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const downloadIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_80};
  }
`;

export const resetIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_70};
  }
`;
