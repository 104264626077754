import React, { createContext, useContext, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash-es";

import { useTab } from "@/hooks";
import { ArrowRightIcon } from "@/assets";
import type { TabType } from "@/types";
import * as S from "./Tab.styled";

const TabContentContext = createContext<{
  tabs: readonly TabType[];
  selectedTab: string;
}>({
  tabs: [],
  selectedTab: "",
});
interface TabProps {
  className?: string;
  tabs: readonly TabType[];
  children: ReactNode;
}

const Tab = ({ className, tabs, children }: TabProps) => {
  const { selectedTab } = useTab(tabs);

  return (
    <TabContentContext.Provider value={{ tabs, selectedTab }}>
      <section className={className}>{children}</section>
    </TabContentContext.Provider>
  );
};

interface TabListProps {
  className?: string;
  tabCount?: { [key: string]: number };
  isOrder?: boolean;
}

Tab.TabList = function TablList({
  className,
  tabCount,
  isOrder,
}: TabListProps) {
  const { t } = useTranslation();

  const { tabs, selectedTab } = useContext(TabContentContext);

  return (
    <S.TabList role="tablist" className={className} isOrder={isOrder}>
      {tabs.map(({ key, label }, idx) => (
        <S.Tab key={key} role="tab">
          <Link
            id={key}
            role="tab"
            aria-controls={label}
            aria-selected={selectedTab === key}
            to={`?tab=${key}`}
            replace
          >
            <span>{t(label)}</span>
            {tabCount && (
              <span>
                {!isUndefined(tabCount[key]) ? `(${tabCount[key]})` : ""}
              </span>
            )}
          </Link>
          {isOrder && idx !== 3 && <ArrowRightIcon />}
        </S.Tab>
      ))}
    </S.TabList>
  );
};

interface TabPanelProps {
  render: { [key: string]: ReactNode };
  className?: string;
  handleClickOpen?: () => void;
}

Tab.TabPanel = function TabPanel({
  render,
  className,
  handleClickOpen,
}: TabPanelProps) {
  const { selectedTab } = useContext(TabContentContext);

  return (
    <div
      id={selectedTab}
      role="tabpanel"
      aria-labelledby={selectedTab}
      className={className}
      onClick={handleClickOpen}
    >
      {render[selectedTab]}
    </div>
  );
};

export default Tab;
