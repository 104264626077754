import React from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import {
  Button,
  Dropdown,
  ErrMsg,
  FormFileInput,
  FormRadioBtn,
  LabelContent,
  LabelContentTable,
  Profile,
  Input,
  Textarea,
} from "@/components";
import { useModal, useToast } from "@/hooks";
import { formatICTDateTime, formatPlateNumber } from "@/utils";
import { AVAILABLE_RADIOS, SuccessIcon } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";
import type { Languages } from "@/types";

import {
  useGetGpsTrucks,
  useGetPropertyTruckDetail,
  useGetTruckGarage,
} from "services";
import { PlateNumberModal } from "components";
import { getTruckGarageData, selectDropdownForm } from "utils";
import type { TruckDefaultInfoForm } from "types";
import * as S from "./TruckDefaultInfoFieldEdit.styled";

interface TruckDefaultInfoFieldEditProps {
  isLoading: boolean;
  updateDefaultInfo: (data: TruckDefaultInfoForm) => void;
  handleClickCancel?: () => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TruckDefaultInfoFieldEdit = ({
  isLoading,
  updateDefaultInfo,
  handleClickCancel,
  handleFileChange,
}: TruckDefaultInfoFieldEditProps) => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { data } = useGetPropertyTruckDetail(id!, { suspense: true });
  const { data: truckGarages } = useGetTruckGarage();
  const { data: gpsTrucks } = useGetGpsTrucks(!!auth.gpsType);
  const customTruckGarages =
    (truckGarages && [...getTruckGarageData(truckGarages)]) ?? [];

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    register,
    handleSubmit,
  } = useFormContext<TruckDefaultInfoForm>();

  const gpsAvailable = gpsTrucks?.plateList.find(
    (truck) => truck === watch("plateNumber"),
  );

  const handleFileDelete = (name: string) => (): void => {
    setValue(name as keyof TruckDefaultInfoForm, null); // TODO: FormFileInput 수정할 때 as 삭제 필요
  };

  const handlePlateListOpen = () => {
    auth.gpsType && gpsTrucks?.plateList.length
      ? handleModalOpen(
          <Controller
            name="plateNumber"
            control={control}
            render={({ field: { onChange } }) => {
              const handleCancelClick = (): void => {
                handleModalClose();
              };

              const handleRowClick = (plate: string) => (): void => {
                onChange(plate);
                handleModalClose();
              };

              return (
                <PlateNumberModal
                  ref={modalRef}
                  plateNumbers={gpsTrucks.plateList}
                  type="select"
                  handleCancelClick={handleCancelClick}
                  handleRowClick={handleRowClick}
                />
              );
            }}
          />,
        )()
      : addToast(COMMON_TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  return (
    <form onSubmit={handleSubmit(updateDefaultInfo)}>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Plate number"
            isRequired
          >
            <Input
              maxLength={11}
              placeholder="Search by plate number"
              value={watch("plateNumber")}
              hasError={!!errors.plateNumber?.message}
              register={register("plateNumber", {
                setValueAs: formatPlateNumber,
              })}
            />
            <S.PlateList>
              {`- ${t("Do you want to check plate number list?")}`}{" "}
              <Button
                css={S.viewDetailsButton}
                variant="text"
                label="View details"
                handleClickBtn={handlePlateListOpen}
              />
            </S.PlateList>
            {errors.plateNumber?.message && (
              <ErrMsg>{errors.plateNumber?.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label={"GPS" as Languages}>
            <SuccessIcon
              css={S.gpsIcon(
                !!data?.defaultInfo.gpsAvailable || !!gpsAvailable,
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            <FormRadioBtn
              radioList={AVAILABLE_RADIOS}
              register={register("category")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(watch("created"), "DD/MM/YYYY")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="Parked garage"
            isRequired
          >
            <Controller
              name="parkedGarage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  css={S.dropdown}
                  disabled={!customTruckGarages?.length}
                  hasError={!!errors.parkedGarage?.message}
                  placeholder="Select the garage"
                  options={customTruckGarages ?? []}
                  selectedOption={value}
                  handleSelect={selectDropdownForm(
                    customTruckGarages,
                    onChange,
                  )}
                />
              )}
            />
            {errors.parkedGarage?.message && (
              <ErrMsg>{errors.parkedGarage?.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Linking">
            <S.LinkedDriverWrapper>
              {data?.defaultInfo.linked[0]?.name ? (
                data?.defaultInfo.linked.map((field) => (
                  <S.Driver key={field.driverId}>
                    <Profile src={field.profileUrl} />
                    <span>{field.name}</span>
                  </S.Driver>
                ))
              ) : (
                <span>-</span>
              )}
            </S.LinkedDriverWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.content} label="Memo">
            <Textarea
              id="truckEditTextarea"
              placeholder="Write anything about the truck."
              maxLength={200}
              hasError={!!errors.memo?.message}
              value={watch("memo") || ""}
              register={register("memo")}
            />
            {errors.memo?.message && (
              <ErrMsg>{errors.memo?.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Attached file">
            <S.FileWrapper>
              <LabelContent
                css={S.fileContent}
                label="Vehicle registration certificate"
                direction="vertical"
                isRequired
              >
                <FormFileInput
                  placeholder="Upload vehicle registration certificate"
                  file={watch("registration")}
                  fileKey="registration"
                  hasErr={!!errors.registration?.message}
                  register={register}
                  handleDeleteFile={handleFileDelete}
                  handleInputChange={handleFileChange}
                />
              </LabelContent>
              <LabelContent
                css={S.fileContent}
                label="Truck photo"
                direction="vertical"
              >
                <FormFileInput
                  placeholder="Upload truck photo"
                  file={watch("truckPhoto")}
                  fileKey="truckPhoto"
                  hasErr={!!errors.truckPhoto?.message}
                  register={register}
                  handleDeleteFile={handleFileDelete}
                  handleInputChange={handleFileChange}
                />
              </LabelContent>
            </S.FileWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleClickCancel}
        />
        <Button
          type="submit"
          isLoading={isLoading}
          isDisabled={Object.values(errors).flat().filter(Boolean).length > 0}
          label="Update"
          variant="primaryMedium"
        />
      </S.BtnWrapper>
    </form>
  );
};

export default TruckDefaultInfoFieldEdit;
