import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import {
  checkIdValidation,
  checkPhoneNum,
  checkPasswordValidation,
  checkPasswordLength,
  makeCryptoFunction,
} from "@/utils";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";

import { useCreatePropertyDriver } from "services";
import { CARRIER_VALID_MSG, CARRIER_TOAST_MSG } from "constants/index";
import type { DriverSelectType } from "types";

interface FORM_STATE_TYPE {
  lastName: string;
  middleName: string;
  firstName: string;
  phone: string;
  linked: string;
  truckId: string | null;
  profile: FileList | null;
  license: FileList | null;
  loginId: string;
  password: string;
  confirmPassword: string;
  bankName: string;
  bankBranch: string;
  bankAccountNo: string;
  lengthCheck: string;
  agreement: string;
  isProvideInfo: boolean;
  isAgreeCollection: boolean;
}

const FORM_INIT_STATE: FORM_STATE_TYPE = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  linked: "",
  truckId: "",
  profile: null,
  license: null,
  loginId: "",
  password: "",
  confirmPassword: "",
  bankName: "",
  bankBranch: "",
  bankAccountNo: "",
  lengthCheck: "",
  agreement: "",
  isProvideInfo: false,
  isAgreeCollection: false,
};

const schema = yup
  .object({
    lastName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    middleName: yup.string().defined(),
    firstName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    phone: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("phone", CARRIER_VALID_MSG.PHONE, (value) => checkPhoneNum(value)),
    linked: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    truckId: yup.string().defined().nullable(),
    profile: yup.mixed<FileList>().defined().nullable(),
    license: yup
      .mixed<FileList>()
      .defined()
      .required(COMMON_VALID_MSG.REQUIRED)
      .nullable(),
    loginId: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("idStepOne", CARRIER_VALID_MSG.ID_STEP_ONE, (value) =>
        checkIdValidation(1, value),
      )
      .test("idStepTwo", CARRIER_VALID_MSG.ID_STEP_TWO, (value) =>
        checkIdValidation(2, value),
      )
      .test("idStepThree", CARRIER_VALID_MSG.ID_STEP_THREE, (value) =>
        checkIdValidation(3, value),
      ),
    password: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("passwordLength", CARRIER_VALID_MSG.PASSWORD_STEP_ONE, (value) =>
        checkPasswordLength(value),
      )
      .test("passwordValidate", CARRIER_VALID_MSG.PASSWORD_STEP_TWO, (value) =>
        checkPasswordValidation(value),
      ),
    confirmPassword: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test(
        "confirmPasswordLength",
        CARRIER_VALID_MSG.PASSWORD_STEP_ONE,
        (value) => checkPasswordLength(value),
      )
      .test(
        "confirmPasswordValidate",
        CARRIER_VALID_MSG.PASSWORD_STEP_TWO,
        (value) => checkPasswordValidation(value),
      )
      .test(
        "passwordConfirm",
        CARRIER_VALID_MSG.PASSWORD_CONFIRM,
        function (value) {
          return value === this.resolve(yup.ref("password")); // NOTE: this를 사용하려면 화살표 함수를 사용할 수 없음
        },
      ),
    bankName: yup.string().defined().nullable(),
    bankBranch: yup.string().defined().nullable(),
    bankAccountNo: yup
      .string()
      .defined()
      .when("lengthCheck", {
        is: true,
        then: () =>
          yup
            .string()
            .min(6, CARRIER_VALID_MSG.ACCOUNT_NUMBER)
            .max(15, CARRIER_VALID_MSG.ACCOUNT_NUMBER),
        otherwise: () => yup.string().notRequired(),
      }),
    lengthCheck: yup.string().defined(),
    isProvideInfo: yup.boolean().required(COMMON_VALID_MSG.REQUIRED),
    isAgreeCollection: yup.boolean().required(COMMON_VALID_MSG.REQUIRED),
    agreement: yup.string().defined(),
  })
  .required();

const useDriverCreateForm = () => {
  const navigate = useNavigate();

  const {
    register,
    watch,
    setValue,
    setError,
    setFocus,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: FORM_INIT_STATE,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const {
    mutate: createPropertyDriverMutate,
    isLoading: isCreateDriverLoading,
  } = useCreatePropertyDriver();

  const { addToast } = useToast();

  const handleDriverLicenseEmptyError = () => {
    if (!watch("isProvideInfo") || !watch("isAgreeCollection")) {
      setError("agreement", { message: CARRIER_VALID_MSG.REQUIRED });
    }

    if (!watch("license")) {
      setError("license", { message: COMMON_VALID_MSG.REQUIRED });
    }
  };

  const handleSubmitDriverCreate = handleSubmit((data) => {
    let error = false;

    if (!watch("license")) {
      setError("license", { message: CARRIER_VALID_MSG.REQUIRED });
      error = true;
    }

    if (!watch("isProvideInfo") || !watch("isAgreeCollection")) {
      setError("agreement", { message: CARRIER_VALID_MSG.REQUIRED });
      error = true;
    }

    if (error) return;

    const req = {
      lastName: data.lastName,
      middleName: data.middleName || null,
      firstName: data.firstName,
      phone: data.phone,
      truckId: data.truckId || null,
      ...(data.profile && { profile: data.profile[0] }),
      ...(data.license && { license: data.license[0] }),
      id: data.loginId,
      password: makeCryptoFunction(data.password),
      bankName: data.bankName ?? null,
      bankBranch: data.bankBranch ?? null,
      bankAccountNo: data.bankAccountNo ?? null,
    };

    createPropertyDriverMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_ADD_DONE);
        navigate(PATH.PROPERTY);
      },
      onError: (err) => {
        switch (err.response?.data.response) {
          case "INVALID_TRUCK_ID":
            setError("linked", { message: CARRIER_VALID_MSG.LINKEDTRUCK });
            setFocus("linked");
            break;
          case "ID_EXISTS":
            setError("loginId", { message: CARRIER_VALID_MSG.ID_EXISTS });
            break;
          case "PHONE_EXISTS":
            setError("phone", {
              message: CARRIER_VALID_MSG.PHONE_EXISTS,
            });
            setFocus("phone");
            break;
        }
      },
    });
  }, handleDriverLicenseEmptyError);

  const handleChangeAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const id = name.split(".").at(-1);
    const key = name as keyof typeof FORM_INIT_STATE;

    switch (id) {
      case "license":
      case "profile": {
        if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
          addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
          setError(key, {
            type: "capacityOver",
          });
          setValue(key, null);
        } else {
          clearErrors(key);
        }
        break;
      }
    }
  };

  const handleDelFile = (fileKey: string) => () => {
    if (fileKey === "license") {
      setError("license", { message: CARRIER_VALID_MSG.REQUIRED });
    }
    setValue(fileKey as keyof FORM_STATE_TYPE, null);
  };

  const handleSelectLink = useCallback(
    ({ registNumber, truckId }: DriverSelectType) => {
      setValue("linked", registNumber);
      setValue("truckId", truckId);
      clearErrors("linked");
    },
    [setValue, errors],
  );

  const handleCheckAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setValue(name as keyof FORM_STATE_TYPE, checked);
  };

  useEffect(() => {
    if (
      watch("isProvideInfo") &&
      watch("isAgreeCollection") &&
      errors.agreement?.message
    ) {
      clearErrors("agreement");
    }
  }, [
    watch("isProvideInfo"),
    watch("isAgreeCollection"),
    errors.agreement?.message,
  ]);

  return {
    errors,
    isCreateDriverLoading,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmitDriverCreate,
    handleDelFile,
    handleSelectLink,
    handleCheckAgree,
    handleChangeAttachments,
  };
};

export default useDriverCreateForm;
