import React from "react";
import { useRecoilValue } from "recoil";

import { beforePagePathState } from "@/stores";
import { button } from "@/styles";

import InternalLink from "../internalLink/InternalLink";

interface PreviousPageLinkProps {
  className?: string;
  isDisabled?: boolean;
  variant: keyof typeof button;
  state?: { [key: string]: any }; // NOTE: 타입 추정이 어려워 any 작성
  to: string;
  children: React.ReactNode;
}

const PreviousPageLink = ({
  className,
  children,
  variant,
  state,
  to: path,
}: PreviousPageLinkProps) => {
  const beforePagePath = useRecoilValue(beforePagePathState);

  return (
    <InternalLink
      className={className}
      variant={variant}
      to={beforePagePath ? beforePagePath : path}
      state={state}
    >
      {children}
    </InternalLink>
  );
};

export default PreviousPageLink;
