import React from "react";

import { SettingLayout } from "@/components";

import { DefaultInfo, CredentialInfo } from "components";
import { SETTING_MENU } from "assets";
import * as S from "./Account.styled";

const Account = () => {
  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <S.Account>
        <h3 className="a11y">account</h3>
        <DefaultInfo />
        <CredentialInfo />
      </S.Account>
    </SettingLayout>
  );
};

export default Account;
