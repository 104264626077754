import dayjs from "dayjs";

import { filterValidCoords, wrongFormatDateTimeConvertToUTC } from "@/utils";
import type { LatLng, PickDropType } from "@/types";

import { ax, getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded } from "utils";
import type {
  GetMonitoringOrderDetailServerModel,
  GetMonitoringTruckDetailServerModel,
  GetMonitoringTrucksQueryModel,
  GetMonitoringTrucksServerModel,
  GetShipperMonitoringOrderDetailServerModel,
} from "types";

export const getMonitoringTrucksAPI = async (
  req: GetMonitoringTrucksQueryModel,
) => {
  const { data } = await ax.get<GetMonitoringTrucksServerModel>(
    "/monitoring/truck",
    { params: req.query },
  );

  const coords = filterValidCoords(data.trucks);

  return { ...data, coords };
};

export const getMonitoringTruckDetailAPI = async (truckId: string) => {
  const { data } = await ax.get<GetMonitoringTruckDetailServerModel>(
    `/monitoring/truck/${truckId}`,
  );

  return data;
};

//TODO: 서버데이터 이슈가 있어 더미로 테스트 + 기존 getMonitoringOrderDetailAPI 사용 시 타입이 너무 상이하여 따로 만듬
export const getShipperMonitoringOrderDetailAPI = async (orderId: string) => {
  const { data } = await ax.get<GetShipperMonitoringOrderDetailServerModel>(
    `/monitoring/order/${orderId}`,
  );

  const profileUrls: (string | undefined)[] = await Promise.all(
    data.allocationList.map((allocation) => {
      allocation.profileS3Url = "";
      if (allocation.driverProfile)
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(allocation.driverProfile, "profile"),
        );
    }),
  );

  profileUrls.forEach((profileUrl, idx) => {
    if (profileUrl) {
      data.allocationList[idx].profileS3Url = profileUrl;
    }
  });

  const puLocation = {
    lat: +data.puLat,
    lng: +data.puLng,
    type: "PICK" as PickDropType,
  };
  const doLocation = {
    lat: +data.doLat,
    lng: +data.doLng,
    type: "DROP" as PickDropType,
  };

  const coords = filterValidCoords(data.allocationList);

  return {
    ...data,
    coords,
    latLngs: [
      { lat: +data.puLat, lng: +data.puLng },
      { lat: +data.doLat, lng: +data.doLng },
    ],
    locations: [puLocation, doLocation],
    allocationList: data.allocationList.map((item, idx) => {
      return {
        ...item,
        profileUrl: profileUrls[idx] ?? null,
      };
    }),
  };
};

// NOTE: 더미로는 확인했으나 실 데이터의 위,경도 확인이 필요
export const getMonitoringOrderDetailAPI = async (orderId: string) => {
  const { data } = await ax.get<GetMonitoringOrderDetailServerModel>(
    `/monitoring/order/${orderId}`,
  );

  const profileUrls = await Promise.all(
    data.allocations.map(({ driverInfo }) => {
      if (driverInfo.profile) {
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(driverInfo.profile, "profile"),
        );
      } else {
        return "";
      }
    }),
  );

  profileUrls.forEach((profileUrl, i) => {
    data.allocations[i].driverInfo.profile = profileUrl;
  });

  data.allocations.forEach((allocation) => {
    allocation.deliveries.forEach((delivery) => {
      if (delivery.completed && dayjs(delivery.completed).isValid()) {
        delivery.completed = wrongFormatDateTimeConvertToUTC(
          delivery.completed,
        );
      }
    });
  });

  return data;
};
