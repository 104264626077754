import React from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "@/contexts";
import { IESupport, Modal, Toast } from "@/components";
import { browserInfo } from "@/utils";

import { ScrollToTop } from "components";
import { useGetTruckTypeOption } from "services";
import useRoot from "./useRoot";

const Root = () => {
  useRoot();
  const { auth } = useAuth();
  useGetTruckTypeOption(auth.isAuth);

  return (
    <>
      {browserInfo() === "MS IE" ? (
        <IESupport />
      ) : (
        <>
          <Toast />
          <Modal />
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        </>
      )}
    </>
  );
};

export default Root;
