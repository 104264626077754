import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TableOfContent } from "@/components";
import { hasNotCanceledDriverDeliveryStatus } from "@/utils";

import {
  OrderManagementDefaultInfo,
  OrderManagementAllocationInfo,
  OrderManagementPickUpInfo,
  OrderManagementDropOffInfo,
  OrderManagementCargoDetails,
  OrderManagementTruckingMethods,
  OrderManagementPayment,
  OrderManagementTruckList,
  OrderManagementDetailLayout,
} from "components";
import { useGetOrderManagementDetail } from "services";
import * as S from "./OrderManagementDetail.styled";

const ForwarderOrderManagementDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetOrderManagementDetail({
    suspense: false,
    orderId: id!,
  });

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const status = data.status;
  const isHideOrderCancelBtn =
    !data.isSelfOrder ||
    status === "CANCELED" ||
    status === "COMPLETED" ||
    hasNotCanceledDriverDeliveryStatus(data.allocationInfo.drivers);

  const contents = [
    {
      text: "Default information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDefaultInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: `Truck list`,
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementTruckList data={data} />
        </Suspense>
      ),
    },
    {
      text: "Allocation information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementAllocationInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Pick-up information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPickUpInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Drop-off information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDropOffInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: `${t("Cargo details")} (${data.cargoes.length})`,
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementCargoDetails data={data} />
        </Suspense>
      ),
    },
    {
      text: "Trucking methods",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementTruckingMethods data={data} />
        </Suspense>
      ),
    },
    {
      text: "Payment",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPayment data={data} />
        </Suspense>
      ),
    },
  ];

  return (
    <OrderManagementDetailLayout
      tableOfContent={
        <TableOfContent
          css={S.tableOfContent}
          pageName="Order details"
          contents={contents.map(({ text, content }) => ({
            heading: { headingLevel: "h2", text },
            content,
          }))}
        />
      }
      isHideOrderCancelBtn={isHideOrderCancelBtn}
      data={data}
    />
  );
};

export default ForwarderOrderManagementDetail;
