import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import type { S3FileUploadType } from "@/types";

import { ax } from "apis/axios";
import { getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded, makeS3PresignedUrlFileUuids } from "utils";
import type {
  CreateTruckLinkQueryModel,
  ChangeDriverPasswordQueryModel,
  CreateDriverQueryModel,
  DeleteDriverQueryModel,
  GetDriverServerModel,
  GetDriverQueryModel,
  GetDriverAllocationQueryModel,
  GetDriverAllocationServerModel,
  GetDriverDetailQueryModel,
  GetDriverDetailServerModel,
  GetTruckSearchQueryModel,
  DeleteTruckLinkQueryModel,
  UpdateDriverDefaultInfoQueryModel,
  UpdateDriverDefaultInfoClientModel,
  UpdateDriverAccountInfoQueryModel,
  GetTruckSearchServerModel,
} from "types";

export const getPropertyDriverAPI = async (req: GetDriverQueryModel) => {
  const { data } = await ax.get<GetDriverServerModel>("/property/driver", {
    params: req.query,
  });

  const profileS3Key: (string | null)[] = await Promise.all(
    data.drivers.map(async ({ profile }) =>
      profile
        ? await getS3PresignedUrlAPI(
            profile.includes("profile") ? profile : `profile/${profile}`,
          )
        : null,
    ),
  );

  const driversProfileS3Key = data.drivers.map((driver, index) => ({
    //TODO: img 확인 필요
    ...driver,
    profileS3Key: profileS3Key[index],
  }));

  return { ...data, drivers: driversProfileS3Key };
};

export const createPropertyDriverAPI = async (req: CreateDriverQueryModel) => {
  if (req.license || req.profile) {
    try {
      const files: S3FileUploadType = {
        license: {
          file: req.license,
          path: "license",
        },
        ...(req.profile && {
          profile: {
            file: req.profile,
            path: "profile",
          },
        }),
      };

      const fileUids = await makeS3PresignedUrlFileUuids(files);
      const body = {
        ...req,
        ...fileUids,
      };

      return ax.post("/property/driver", body);
    } catch (err) {
      const { addToast } = useToast();

      addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
    }
  } else {
    return ax.post("/property/driver", req);
  }
};

export const getPropertyDriverAllocationAPI = async (
  req: GetDriverAllocationQueryModel,
) => {
  const { data } = await ax.get<GetDriverAllocationServerModel>(
    `/property/driver/${req.driverId}/allocation`,
    { params: req.query },
  );

  return data;
};

export const getPropertyDriverTruckAPI = async (
  req: GetTruckSearchQueryModel,
) => {
  const { data } = await ax.get<GetTruckSearchServerModel>(
    "/property/driver/truck",
    { params: req },
  );
  return data;
};

export const getPropertyDriverDetailAPI = async (
  req: GetDriverDetailQueryModel,
) => {
  const { data } = await ax.get<GetDriverDetailServerModel>(
    `/property/driver/detail/${req.driverId}`,
  );
  const profileUrl: string = await getS3PresignedUrlAPI(
    addPrefixToUrlIfNeeded(data.profile, "profile"),
  );

  return { ...data, profileUrl: profileUrl ? profileUrl : null };
};

export const createTruckLinkAPI = (req: CreateTruckLinkQueryModel) => {
  return ax.post(`/property/driver/${req.driverId}/truck`, req.body);
};

export const deleteTruckLinkAPI = (req: DeleteTruckLinkQueryModel) => {
  return ax.delete(`/property/driver/${req.driverId}/truck`);
};

export const updatePropertyDriverDefaultInfoAPI = async (
  req: UpdateDriverDefaultInfoQueryModel,
) => {
  try {
    const files: S3FileUploadType = {
      ...(req.body.license && {
        license: {
          file: req.body.license,
          path: "license",
        },
      }),
    };

    const fileUids = await makeS3PresignedUrlFileUuids(files);

    return ax.put<UpdateDriverDefaultInfoClientModel>(
      `/property/driver/${req.driverId}/default`,
      { ...req.body, ...fileUids },
    );
  } catch (e) {
    const { addToast } = useToast();

    addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
  }
};

export const updatePropertyDriverAccountInfoAPI = (
  req: UpdateDriverAccountInfoQueryModel,
) => {
  return ax.put(`/property/driver/${req.driverId}/account`, req.body);
};

export const changeDriverPasswordAPI = (
  req: ChangeDriverPasswordQueryModel,
) => {
  return ax.patch(`/property/driver/${req.driverId}/password`, req.body);
};

export const deletePropertyDriverAPI = (req: DeleteDriverQueryModel) =>
  ax.delete(`/property/driver/${req.driverId}`);

export const postTruckLink = (req: CreateTruckLinkQueryModel) =>
  ax.post(`/property/driver/${req.driverId}/truck`, req.body);

export const deleteTruckLink = (req: DeleteTruckLinkQueryModel) =>
  ax.delete(`/property/driver/${req.driverId}/truck`);
