import React from "react";

import {
  QuotationCanceled,
  QuotationCompleted,
  QuotationLayout,
  QuotationOngoing,
} from "components";

const CarrierQuotation = () => {
  const render = {
    ongoing: <QuotationOngoing clientType="carrier" />,
    completed: <QuotationCompleted clientType="carrier" />,
    canceled: <QuotationCanceled clientType="carrier" />,
  } as const;

  return <QuotationLayout render={render} />;
};

export default CarrierQuotation;
