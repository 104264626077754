import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@/contexts";
import { PATH } from "@/assets";

import { NAV } from "assets";

const ClientTypeGuard = () => {
  const { pathname } = useLocation();

  const {
    auth: { clientType, gpsType },
  } = useAuth();

  const isUnauthorized =
    !clientType ||
    !NAV(clientType, !!gpsType).some(({ path }) => pathname.includes(path));

  if (isUnauthorized) {
    return <Navigate to={PATH.NOT_FOUND} />;
  }

  return <Outlet />;
};

export default ClientTypeGuard;
