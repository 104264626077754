import React from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { Filter } from "@/components";
import { clientLanguage } from "@/stores";
import { TRUCK_FILTER_RADIOS } from "@/assets";

import { truckTypeOptionsState } from "stores";
import { formatTruckOptions, formatTruckTypes } from "utils";

const PropertyTruckFilter = () => {
  const [searchParams] = useSearchParams();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptions = useRecoilValue(truckTypeOptionsState);
  const truckTypes = formatTruckTypes(language, truckTypeOptions);
  const truckOptions = formatTruckOptions(
    language,
    truckTypeOptions,
    searchParams.get("truckTypeId") ?? "",
  );

  // TODO: 추후 별도 파일로 분리 필요
  const searchLabels = [
    { queryKey: "status", label: "Search", options: TRUCK_FILTER_RADIOS },
    { queryKey: "plateNumber", label: "Plate number" },
    { queryKey: "linked", label: "Linking" },
    {
      queryKey: "truckTypeId",
      label: "Truck type",
      options: truckTypes,
      link: "truckOptionId",
    },
    {
      queryKey: "truckOptionId",
      label: "Truck option",
      options: truckOptions,
    },
  ];

  const selectedTruckOptions = searchParams.get("truckTypeId")
    ? truckOptions
    : undefined;

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio queryKey="status" radios={TRUCK_FILTER_RADIOS} />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Plate number">
          <Filter.Input
            maxLength={20}
            queryKey="plateNumber"
            placeholder="Search by plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input
            maxLength={150}
            queryKey="linked"
            placeholder="Search by driver name"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Truck type">
          <Filter.Dropdown
            queryKey="truckTypeId"
            dropdowns={truckTypes}
            placeholder="Select the truck type"
            link="truckOptionId"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Truck option">
          <Filter.Dropdown
            queryKey="truckOptionId"
            dropdowns={selectedTruckOptions}
            placeholder="Select the truck option"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default PropertyTruckFilter;
