import { calcTableWidth } from "@/utils";
import { CELL, RADIO, TAB } from "@/assets";
import type { Languages, RadioType } from "@/types";

export const ORDER_MANAGEMENT_FORM = {
  truckCnt: 0,
};

export const CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.TRUCKS],
  [CELL.COMPANY_CLIENT],
  [CELL.PICKUP_PUADDRESS, [CELL.ADDRESS_PUADDRESS, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDRESS, [CELL.ADDRESS_DOADDRESS, CELL.ETA_DOETA]],
] as const;

export const SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.TRUCKS],
  [CELL.PICKUP_PUADDRESS, [CELL.ADDRESS_PUADDRESS, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDRESS, [CELL.ADDRESS_DOADDRESS, CELL.ETA_DOETA]],
] as const;

export const ORDER_SHIPPING_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.TRUCKS],
  [CELL.PICKUP_PUADDRESS, [CELL.ADDRESS_PUADDRESS, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDRESS, [CELL.ADDRESS_DOADDRESS, CELL.ETA_DOETA]],
] as const;

export const ORDER_COMPLETED_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.TRUCKS],
  [CELL.PICKUP_PUADDRESS, [CELL.ADDRESS_PUADDRESS, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDRESS, [CELL.ADDRESS_DOADDRESS, CELL.ETA_DOETA]],
] as const;

export const ORDER_CANCELED_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.TRUCKS],
  [CELL.PICKUP_PUADDRESS, [CELL.ADDRESS_PUADDRESS, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDRESS, [CELL.ADDRESS_DOADDRESS, CELL.ETA_DOETA]],
] as const;

export const CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_CELL_SIZES =
  calcTableWidth([
    "100px",
    "150px",
    "152px",
    "169px",
    ["230px", "450px"],
    "128px",
    ["230px", "450px"],
    "128px",
  ]);

export const SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_CELL_SIZES =
  calcTableWidth([
    "150px",
    "190px",
    "152px",
    ["270px", "500px"],
    "128px",
    ["270px", "500px"],
    "128px",
  ]);

export const ORDER_SHIPPING_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  "190px",
  "152px",
  ["270px", "500px"],
  "128px",
  ["270px", "5000px"],
  "128px",
]);

export const ORDER_COMPLETED_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  "190px",
  "152px",
  ["270px", "500px"],
  "128px",
  ["270px", "5000px"],
  "128px",
]);

export const ORDER_CANCELED_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  "190px",
  "152px",
  ["270px", "500px"],
  "128px",
  ["270px", "5000px"],
  "128px",
]);

export const ORDER_ALLOCATION_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.WAITING,
  RADIO.ONGOING,
  RADIO.ALLOCATED,
  RADIO.REJECTED,
];

export const ORDER_MANAGEMENT_TABS = [
  TAB.ALLOCATION,
  TAB.SHIPPING,
  TAB.COMPLETED,
  TAB.CANCELED,
] as const;

export const ORDERMANAGEMENT_DETAIL_TRUCK_FORM = {
  truck: [
    {
      truckType: { key: "", label: "" as Languages },
      truckOption: { key: "", label: "" as Languages },
      truckNum: 1,
    },
  ],
};
