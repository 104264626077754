import React from "react";

import { LabelContentTable } from "@/components";
import { addrFormatter, formatICTDateTime, formatPhoneNum } from "@/utils";

import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementPickUp.styled";

interface OrderManagementPickUpInfoProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementPickUpInfo = ({
  data,
}: OrderManagementPickUpInfoProps) => {
  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address">
          <S.AddressWrapper>
            <div>{data.pickUpInfo.puName}</div>
            <S.AddressContent>
              {addrFormatter(
                data.pickUpInfo.puAddr,
                data.pickUpInfo.puAddrDetail,
              )}
            </S.AddressContent>
          </S.AddressWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge">
          {data.pickUpInfo.puPic} ({formatPhoneNum(data.pickUpInfo.puPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA">
          {formatICTDateTime(data.pickUpInfo.puEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD">
          {data.pickUpInfo.puEtd
            ? formatICTDateTime(data.pickUpInfo.puEtd)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementPickUpInfo;
