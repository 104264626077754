import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useToast } from "@/hooks";
import { PATH } from "@/assets";

import {
  Foldable1DepthLayout,
  Foldable2DepthLayout,
  DetailLeft,
  DetailRight,
} from "components";
import { useAllocation, useClose2DepthLayout } from "hooks";
import {
  useCreateAllocation,
  useGetAllocationDriverDetailAllocation,
} from "services";
import { isPickDropSortOk } from "utils";
import { CARRIER_TOAST_MSG } from "constants/index";
import type { GetAllocationDriverDetailServerModel } from "types";

const AllocationCreate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const driverId = searchParams.get("driverId")!;

  const { data } = useGetAllocationDriverDetailAllocation(driverId);
  const {
    mutate: createAllocationMutate,
    isLoading: isUpdateAllocationLoading,
  } = useCreateAllocation();

  const [selectedAllocationDriver, setSelectedAllocationDriver] =
    useState<GetAllocationDriverDetailServerModel | null>(null);

  const { isClose, handleClickClose, handleClickOpen } = useClose2DepthLayout();
  const { addToast } = useToast();
  const {
    allocations,
    handleDragDrop,
    changeMemo,
    handleAllocationAdd,
    handleAllocationDelete,
    handleAllocationWithSmartDispatchAdd,
  } = useAllocation();

  const changeSelectedAllocationCreate = (
    allocation: GetAllocationDriverDetailServerModel,
  ): void => {
    setSelectedAllocationDriver(allocation);
  };

  const handleAllocationCreate = (): void => {
    if (!selectedAllocationDriver) return;

    if (!selectedAllocationDriver.truckId) {
      addToast(CARRIER_TOAST_MSG.WARNING.NO_TRUCK_SELECTED);
      return;
    }

    const allocationRoutes = allocations.map((item) => ({
      orderId: item.orderId,
      type: item.type,
      ...(item.memo && { memo: item.memo.trim() }),
    }));

    const body = {
      driverId: selectedAllocationDriver.driverId,
      truckId: selectedAllocationDriver.truckId,
      allocations: allocationRoutes,
    };

    if (!isPickDropSortOk(allocationRoutes)) {
      addToast(CARRIER_TOAST_MSG.WARNING.PICK_DROP_SORT_NOT_OK);
      return;
    }

    const req = {
      driverId: selectedAllocationDriver.driverId,
      body,
    };

    createAllocationMutate(req, {
      onSuccess: (allocationId) => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.ALLOCATION_ADD_DONE);
        navigate(`${PATH.ALLOCATION}?tab=ongoing&id=${allocationId}`);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    setSelectedAllocationDriver(data);
  }, [data]);

  return (
    <>
      <Foldable1DepthLayout heading="Allocation Create">
        <DetailLeft
          selectedAllocationDriver={selectedAllocationDriver}
          isUpdateAllocationLoading={isUpdateAllocationLoading}
          allocations={allocations}
          isNeedDriverEdit={false}
          driverDetail={data}
          handleDragDrop={handleDragDrop}
          changeMemo={changeMemo}
          changeSelectedAllocationCreate={changeSelectedAllocationCreate}
          handleSubmit={handleAllocationCreate}
          handleClickOpen={handleClickOpen}
        />
      </Foldable1DepthLayout>
      <Foldable2DepthLayout
        isClose={isClose}
        handleClickClose={handleClickClose}
        handleClickOpen={handleClickOpen}
      >
        <DetailRight
          allocations={allocations}
          handleAllocationAdd={handleAllocationAdd}
          handleAllocationDelete={handleAllocationDelete}
          handleAllocationWithSmartDispatchAdd={
            handleAllocationWithSmartDispatchAdd
          }
        />
      </Foldable2DepthLayout>
    </>
  );
};

export default AllocationCreate;
