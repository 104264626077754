import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { formatICTToUTC0 } from "@/utils";
import type { CalendarModalSelectDate } from "@/types";

import { useGetDeliveriesAllocation } from "services";
import type {
  GetAllocationDeliveryServerModel,
  AllocationRoute,
  GetAllocationDetailServerModel,
  SelectedAllocationDelivery,
} from "types";

const useDelivery = (
  selectDate: CalendarModalSelectDate,
  allocations: AllocationRoute[],
  originAllocations?: GetAllocationDetailServerModel["allocationInfo"],
) => {
  const [searchParams] = useSearchParams();

  const [deliveries, setDeliveries] = useState<SelectedAllocationDelivery[]>(
    [],
  );

  const driverId = searchParams.get("driverId")!;
  const allocationId = searchParams.get("allocationId");

  const req = {
    query: {
      driverId,
      ...(allocationId && { allocationId }),
      ...(selectDate[0] && {
        startDate: formatICTToUTC0(selectDate[0]),
      }),
      ...(selectDate[1] && {
        endDate: formatICTToUTC0(selectDate[1]),
      }),
    },
  };

  const { isLoading, data } = useGetDeliveriesAllocation(req);

  const updateDeliveries = (
    deliveries: GetAllocationDeliveryServerModel,
  ): void => {
    const orderIds = allocations?.map((allocation) => allocation.orderId);

    setDeliveries(
      deliveries.map((delivery, idx) => ({
        ...delivery,
        selected: orderIds?.includes(delivery.orderId),
        idx,
      })),
    );
  };

  useEffect(() => {
    if (!data) return;

    // NOTE: 배차 요청 상태일 경우
    if (!allocationId) {
      updateDeliveries(data);

      return;
    }

    // NOTE: 배차 수정 상태일 경우
    const allocatedOrderIds = originAllocations?.map(
      (delivery) => delivery.orderId,
    );

    const { existingDeliveries, deliveries } = data.reduce<{
      existingDeliveries: GetAllocationDeliveryServerModel;
      deliveries: GetAllocationDeliveryServerModel;
    }>(
      (acc, cur) => {
        if (allocatedOrderIds?.includes(cur.orderId)) {
          const delivery = {
            ...cur,
            allocatedCnt: cur.allocatedCnt - 1,
          };
          return {
            ...acc,
            existingDeliveries: [...acc.existingDeliveries, delivery],
          };
        }
        return {
          ...acc,
          deliveries: [...acc.deliveries, cur],
        };
      },
      { existingDeliveries: [], deliveries: [] },
    );

    updateDeliveries([...existingDeliveries, ...deliveries]);
  }, [data, allocations]);

  return { isLoading, deliveries, setDeliveries };
};

export default useDelivery;
