import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
`;

export const readOnlyInput = (theme: Theme) => css`
  padding-right: 60px;

  :focus {
    border-color: ${theme.color.gray_30};
  }

  &[aria-invalid="true"] {
    border-color: ${theme.color.red_20};
  }
`;

export const UtilBox = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 100%;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 0 10px 10px 0;

    &[data-status="true"] {
      border-color: ${theme.color.red_20};
    }

    &:not(:disabled):hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

const mixin_util = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    pointer-events: none;
  }
`;

export const Uploader = styled.label`
  ${mixin_util};
  cursor: pointer;

  & > input {
    display: none;
  }
`;

export const DeleteBtn = styled.button`
  ${mixin_util};
`;
