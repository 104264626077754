import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "@/types";

interface AutoHeightTextareaProps {
  className?: string;
  value: string;
  placeholder: Languages;
  onChange: (e: any) => void; //TODO: 사용하는 함수의 타입 수정 필요
}

const AutoHeightTextarea = ({
  className,
  value,
  placeholder,
  onChange,
}: AutoHeightTextareaProps) => {
  const ref = useRef() as any;

  const { t } = useTranslation();

  useEffect(() => {
    // textarea scroll height 설정
    ref.current.style.height = "0px";
    const scrollHeight = ref.current.scrollHeight;
    ref.current.style.height = scrollHeight + "px";
  }, [value]);

  return (
    <textarea
      ref={ref}
      className={className}
      value={value}
      placeholder={t(placeholder)}
      onChange={onChange}
    />
  );
};

export default AutoHeightTextarea;
