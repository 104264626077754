import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

import { NamePhoneLabel } from "components";

export const Root = styled.div`
  left: -20px;
  width: 260px;
`;

export const searchInput = css`
  > input {
    border-radius: 2px 2px 0 0;
  }
`;

export const Body = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    height: calc(52px * 5);
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
  `}
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 8px;
    text-align: left;

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const CustomNamePhoneLabel = styled(NamePhoneLabel)`
  ${({ theme }) => css`
    span {
      ${theme.font.regular_13};
    }
  `}
`;
