import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@/components";
import { QuestionIcon } from "@/assets";
import type { Languages, TooltipContent } from "@/types";

import * as S from "./LabelContent.styled";

interface CommonProps {
  className?: string;
  label: Languages;
  children: React.ReactNode;
  tooltip?: TooltipContent;
  isRequired?: boolean;
  icon?: React.ReactNode;
}

interface Horizontal extends CommonProps {
  direction: "horizontal";
  columnWidth: number;
}

interface Vertical extends CommonProps {
  direction: "vertical";
  columnWidth?: never;
}

type LabelContentProps = Horizontal | Vertical;

const LabelContent = ({
  className,
  label,
  tooltip,
  isRequired,
  direction,
  columnWidth,
  children,
  icon,
}: LabelContentProps) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper
      className={className}
      direction={direction}
      columnWidth={columnWidth}
    >
      <S.LabelWrapper>
        <S.Label isRequired={isRequired}>
          {icon && icon}
          {t(label)}
        </S.Label>
        {tooltip && (
          <Tooltip
            title={tooltip.title}
            tooltipBtnContent={QuestionIcon}
            position="BOTTOM_RIGHT"
          >
            {t(tooltip.contents)}
          </Tooltip>
        )}
      </S.LabelWrapper>
      {children}
    </S.Wrapper>
  );
};

export default LabelContent;
