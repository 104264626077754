import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const PlanContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const detailLink = (theme: Theme) => css`
  & > svg {
    margin-right: 0;
    transform: rotate(-90deg);

    > path {
      fill: ${theme.color.primary_40};
    }
  }
`;

export const editBtn = css`
  margin: 12px 0 0 auto;
`;

export const DownloadBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;
