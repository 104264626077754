import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";

import { useUpdatePropertyDriverAccountInfo } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";
import type {
  DriverAccountForm,
  GetPropertyDriverDetailClientModel,
  PropertyDetailEditCardType,
} from "types";

const schema = yup.object({
  bankName: yup.string().defined(),
  bankBranch: yup.string().defined(),
  bankAccountNo: yup
    .string()
    .defined()
    .when("lengthCheck", {
      is: true,
      then: () =>
        yup
          .string()
          .min(6, CARRIER_VALID_MSG.ACCOUNT_NUMBER)
          .max(15, CARRIER_VALID_MSG.ACCOUNT_NUMBER),
      otherwise: () => yup.string().notRequired(),
    }),
  lengthCheck: yup.string().defined(),
});

const accountFormState = {
  bankName: "",
  bankBranch: "",
  bankAccountNo: "",
  lengthCheck: "",
};

const useAccountInfo = (
  data: GetPropertyDriverDetailClientModel["accountInfo"],
  driverId: string,
  handleEditState: (
    label: PropertyDetailEditCardType | null,
    type?: boolean,
  ) => () => void,
) => {
  const formMethod = useForm<DriverAccountForm>({
    defaultValues: accountFormState,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const {
    mutate: updatePropertyDriverAccoutInfoMutate,
    isSuccess: isAccountUpdateDone,
    isLoading: isAccountUpdateLoading,
  } = useUpdatePropertyDriverAccountInfo();

  const { addToast } = useToast();

  const handleSubmitAccountInfo = formMethod.handleSubmit((data) => {
    if (isCompareAccountInfoData()) {
      handleEditState(null, true)();
      return;
    }

    const body = {
      bankName: data.bankName || null,
      bankBranch: data.bankBranch || null,
      bankAccountNo: data.bankAccountNo || null,
    };

    const req = {
      driverId,
      body,
    };

    updatePropertyDriverAccoutInfoMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_UPDATE_DONE);
        handleEditState(null, true)();
      },
      onError: () => addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
    });
  });

  const handleCancelAccountInfo = () => {
    if (!data) return;

    formMethod.reset(makeOriginDriverAccountInfoForm(data));
  };
  const makeOriginDriverAccountInfoForm = (
    originData: GetPropertyDriverDetailClientModel["accountInfo"],
  ) => {
    return {
      bankName: originData.bankName ?? "",
      bankBranch: originData.bankBranch ?? "",
      bankAccountNo: originData.bankAccountNo ?? "",
    };
  };

  const isCompareAccountInfoData = () => {
    if (!data) return false;

    const originFormData = makeOriginDriverAccountInfoForm(data);
    const currentFormData = formMethod.getValues();

    return isEqual(originFormData, currentFormData);
  };

  useEffect(() => {
    if (!data) return;

    formMethod.setValue("bankName", data.bankName ?? "");
    formMethod.setValue("bankBranch", data.bankBranch ?? "");
    formMethod.setValue("bankAccountNo", data.bankAccountNo ?? "");
  }, [data]);

  return {
    formMethod,
    isAccountUpdateDone,
    isAccountUpdateLoading,
    isCompareAccountInfoData,
    handleSubmitAccountInfo,
    handleCancelAccountInfo,
  };
};

export default useAccountInfo;
