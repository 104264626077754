import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  ErrMsg,
  FormFileInput,
  FormRadioBtn,
  Input,
  LabelContentTable,
  LabelContent,
  Textarea,
} from "@/components";
import { formatICTDateTime, onlyNumber } from "@/utils";
import { AVAILABLE_RADIOS, PHONE_MAX_LENGTH } from "@/assets";
import { COMMON_VALID_MSG, FILE_DESC } from "@/constants";
import type { Languages } from "@/types";

import type { DriverDefaultInfoForm } from "types";
import * as S from "./DriverDefaultInfoFieldEdit.styled";

interface DefaultInfoFieldEditProps {
  handleSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
  handleChangeDefaultInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickCancel: () => void;
  handleDelFile: (name: string) => () => void;
}

const DefaultInfoFieldEdit = ({
  handleChangeDefaultInfo,
  handleSubmit,
  handleDelFile,
  handleClickCancel,
}: DefaultInfoFieldEditProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<DriverDefaultInfoForm>();

  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit}>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.row} label="Driver" isRequired>
            <div>
              <S.LabelContentWrapper>
                <div>
                  <LabelContent
                    css={S.labelContent}
                    label="Last name"
                    direction="vertical"
                    isRequired
                  >
                    <Input
                      placeholder="Last name"
                      value={watch("lastName")}
                      hasError={!!errors.lastName?.message}
                      maxLength={100}
                      register={register("lastName")}
                    />
                  </LabelContent>
                  <LabelContent
                    css={S.labelContent}
                    label="Middle name"
                    direction="vertical"
                  >
                    <Input
                      placeholder="(Optional)"
                      value={watch("middleName") ?? ""}
                      hasError={!!errors.middleName?.message}
                      maxLength={100}
                      register={register("middleName")}
                    />
                  </LabelContent>
                  <LabelContent
                    css={S.labelContent}
                    label="First name"
                    direction="vertical"
                    isRequired
                  >
                    <Input
                      placeholder="First name"
                      value={watch("firstName")}
                      hasError={!!errors.firstName?.message}
                      maxLength={100}
                      register={register("firstName")}
                    />
                  </LabelContent>
                </div>
                {(errors.lastName?.message || errors.firstName?.message) && (
                  <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
                )}
              </S.LabelContentWrapper>
              <LabelContent
                css={S.labelContent}
                label="Phone number"
                direction="vertical"
                isRequired
              >
                <Input
                  placeholder="Only numbers"
                  value={watch("phone")}
                  hasError={!!errors.phone?.message}
                  maxLength={PHONE_MAX_LENGTH}
                  register={register("phone", { setValueAs: onlyNumber })}
                />
              </LabelContent>
              {errors.phone?.message && (
                <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
              )}
            </div>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            <FormRadioBtn
              radioList={AVAILABLE_RADIOS}
              register={register("status")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created on">
            {formatICTDateTime(watch("created"), "DD/MM/YYYY")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Linking">
            {watch("linkedRegistNumber") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.content} label="Memo">
            <Textarea
              id="memo"
              placeholder="Write anything about the driver."
              maxLength={200}
              hasError={!!errors.memo?.message}
              value={watch("memo") ?? ""}
              register={register("memo")}
            />
            {errors.memo?.message && (
              <ErrMsg>{errors.memo?.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Driver's license" isRequired>
            <S.DriverLicenseWrapper>
              <FormFileInput
                placeholder="Upload driver’s license"
                hasErr={!!errors.license?.message}
                file={watch("license")}
                fileKey="license"
                register={register}
                handleDeleteFile={handleDelFile}
                handleInputChange={handleChangeDefaultInfo}
              />
              <S.Desc>{`- ${t(FILE_DESC)}`}</S.Desc>
              {errors.license?.message && (
                <ErrMsg>{errors.license?.message as Languages}</ErrMsg>
              )}
            </S.DriverLicenseWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleClickCancel}
        />
        <Button
          type="submit"
          variant="primaryMedium"
          label="Update"
          isDisabled={Object.keys(errors).length > 0}
        />
      </S.BtnWrapper>
    </form>
  );
};

export default DefaultInfoFieldEdit;
