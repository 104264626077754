import * as yup from "yup";

import { deleteComma } from "@/utils";
import {
  CARGO_TYPE,
  HAZARD_GOODS_RADIOS,
  LOADING_METHOD_TYPE,
  LOADING_STATUS_RADIOS,
  PACKAGING_TYPE,
  PAYMENT_METHOD_RADIOS,
  STACKABLE_GOODS_RADIOS,
  TAX_RADIOS,
} from "@/assets";
import { COMMON_VALID_MSG } from "@/constants";
import type { DropdownUtiltyType, DropdownOptionType } from "@/types";

export const ORDER_FORM = {
  clientType: null,
  companyInformation: {
    company: [],
    truckingCompany: [],
    isCheckSelfTransport: false,
  },
  pickUp: {
    address: "",
    addressDetail: "",
    buildingName: "",
    coord: { lng: 0, lat: 0 },
    city: "",
    charge: "",
    phone: "",
    eta: [],
    etd: [],
  },
  dropOff: {
    address: "",
    addressDetail: "",
    buildingName: "",
    coord: { lng: 0, lat: 0 },
    city: "",
    charge: "",
    phone: "",
    eta: [],
    etd: [],
  },
  truckingMethods: {
    loadingStatus: "LTL" as (typeof LOADING_STATUS_RADIOS)[number]["key"],
    loadingMethod: { key: "", label: "" } as DropdownUtiltyType<
      typeof LOADING_METHOD_TYPE
    >,
    loadingOtherMethod: "",
    attachedFiles: { file1: null, file2: null },
    memo: "",
  },
  cargo: [
    {
      cargoType: { key: "", label: "" } as DropdownUtiltyType<
        typeof CARGO_TYPE
      >,
      cargoOtherType: "",
      packagingType: { key: "", label: "" } as DropdownUtiltyType<
        typeof PACKAGING_TYPE
      >,
      size: { length: "", depth: "", height: "" },
      weight: "",
      quantity: "",
      hazardGoods: "no" as (typeof HAZARD_GOODS_RADIOS)[number]["key"],
      stackable: "no" as (typeof STACKABLE_GOODS_RADIOS)[number]["key"],
    },
  ],
  payment: {
    method: "ACCOUNT_TRANSFER" as (typeof PAYMENT_METHOD_RADIOS)[number]["key"],
    subtotal: "",
    tax: "8" as (typeof TAX_RADIOS)[number]["key"],
  },
  truck: [
    {
      truckType: { key: "", label: "" } as DropdownOptionType,
      truckOption: { key: "", label: "" } as DropdownOptionType,
      truckNum: 1,
    },
  ],
  addFavorite: false,
};

const pickUpDropOffSchema = yup
  .object({
    address: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    addressDetail: yup.string().optional(),
    buildingName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    coord: yup
      .object({
        lat: yup.number().required(COMMON_VALID_MSG.REQUIRED),
        lng: yup.number().required(COMMON_VALID_MSG.REQUIRED),
      })
      .required(COMMON_VALID_MSG.REQUIRED),
    city: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    charge: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    phone: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    eta: yup
      .array(yup.string().required(COMMON_VALID_MSG.REQUIRED))
      .required(COMMON_VALID_MSG.REQUIRED)
      .test({
        name: "required",
        test: (value) => value.length > 0,
        message: COMMON_VALID_MSG.REQUIRED,
      }),
    etd: yup.array(yup.string().required(COMMON_VALID_MSG.REQUIRED)).optional(),
  })
  .required();

export const orderCreationSchema = yup.object().shape({
  clientType: yup
    .string()
    .required()
    .oneOf(["carrier", "forwarder", "forwardercarrier", "shipper"])
    .nullable(),
  companyInformation: yup
    .object({
      company: yup.array().required(),
      truckingCompany: yup.array().required(),
      isCheckSelfTransport: yup.boolean().required(),
    })
    .when("clientType", {
      is: (value: string) =>
        value === "forwarder" || value === "forwardercarrier",
      then: () =>
        yup.object({
          company: yup
            .array()
            .required()
            .test({
              name: "required",
              test: (value) => value.length > 0,
              message: COMMON_VALID_MSG.REQUIRED,
            }),
          truckingCompany: yup.array().when("isCheckSelfTransport", {
            is: (isCheckSelfTransport: boolean) => !isCheckSelfTransport,
            then: () =>
              yup
                .array()
                .required()
                .test({
                  name: "required",
                  test: (value) => value.length > 0,
                  message: COMMON_VALID_MSG.REQUIRED,
                }),
            otherwise: () => yup.array().optional(),
          }),
          isCheckSelfTransport: yup
            .boolean()
            .required(COMMON_VALID_MSG.REQUIRED),
        }),
      otherwise: () =>
        yup
          .object({
            company: yup.array().required(),
            truckingCompany: yup.array().required(),
            isCheckSelfTransport: yup.boolean().required(),
          })
          .when("clientType", {
            is: (clientType: string) => clientType === "shipper",
            then: () =>
              yup.object({
                company: yup.array().optional(),
                truckingCompany: yup
                  .array()
                  .required()
                  .test({
                    name: "required",
                    test: (value) => value.length > 0,
                    message: COMMON_VALID_MSG.REQUIRED,
                  }),
                isCheckSelfTransport: yup.boolean().optional(),
              }),
            otherwise: () =>
              yup.object({
                company: yup
                  .array()
                  .required()
                  .test({
                    name: "required",
                    test: (value) => value.length > 0,
                    message: COMMON_VALID_MSG.REQUIRED,
                  }),
                truckingCompany: yup.array().optional(),
                isCheckSelfTransport: yup.boolean().optional(),
              }),
          }),
    }),
  pickUp: pickUpDropOffSchema,
  dropOff: pickUpDropOffSchema,
  truckingMethods: yup
    .object({
      loadingStatus: yup.string().required(),
      loadingMethod: yup
        .mixed<DropdownUtiltyType<typeof LOADING_METHOD_TYPE>>()
        .required()
        .test({
          name: "required",
          test: ({ key, label }) => !!(key && label),
          message: COMMON_VALID_MSG.REQUIRED,
        }),
      loadingOtherMethod: yup.string().when("loadingMethod", {
        is: (loadingMethod: DropdownUtiltyType<typeof LOADING_METHOD_TYPE>) =>
          loadingMethod.key === "others",
        then: () => yup.string().required(COMMON_VALID_MSG.REQUIRED),
        otherwise: () => yup.string().optional(),
      }),
      attachedFiles: yup
        .object({
          file1: yup.mixed<FileList>().required().nullable(),
          file2: yup.mixed<FileList>().required().nullable(),
        })
        .optional(),
      memo: yup
        .string()
        .optional()
        .test(
          "maxLength",
          COMMON_VALID_MSG.INVALID_MEMO_200,
          (value) => (value?.length ?? 0) <= 200,
        ),
    })
    .required(),
  cargo: yup
    .array(
      yup
        .object({
          cargoType: yup
            .mixed<DropdownUtiltyType<typeof CARGO_TYPE>>()
            .required()
            .test({
              name: "required",
              test: ({ key, label }) => !!(key && label),
              message: COMMON_VALID_MSG.REQUIRED,
            }),
          cargoOtherType: yup.string().when("cargoType", {
            is: (cargoType: DropdownUtiltyType<typeof CARGO_TYPE>) =>
              cargoType.key === "others",
            then: () => yup.string().required(COMMON_VALID_MSG.REQUIRED),
            otherwise: () => yup.string().optional(),
          }),
          packagingType: yup
            .mixed<DropdownUtiltyType<typeof PACKAGING_TYPE>>()
            .required()
            .test({
              name: "required",
              test: ({ key, label }) => !!(key && label),
              message: COMMON_VALID_MSG.REQUIRED,
            }),
          size: yup
            .object({
              length: yup
                .string()
                .required(COMMON_VALID_MSG.REQUIRED)
                .test(
                  "notZero",
                  COMMON_VALID_MSG.NOT_ZERO,
                  (value) => value !== "0",
                )
                .test(
                  "maxValue",
                  COMMON_VALID_MSG.MAX_VALUE_LIMIT,
                  (value) => +value <= 13.56,
                ),
              depth: yup
                .string()
                .required(COMMON_VALID_MSG.REQUIRED)
                .test(
                  "notZero",
                  COMMON_VALID_MSG.NOT_ZERO,
                  (value) => value !== "0",
                )
                .test(
                  "maxValue",
                  COMMON_VALID_MSG.MAX_VALUE_LIMIT,
                  (value) => +value <= 2.35,
                ),
              height: yup
                .string()
                .required(COMMON_VALID_MSG.REQUIRED)
                .test(
                  "notZero",
                  COMMON_VALID_MSG.NOT_ZERO,
                  (value) => value !== "0",
                )
                .test(
                  "maxValue",
                  COMMON_VALID_MSG.MAX_VALUE_LIMIT,
                  (value) => +value <= 2.7,
                ),
            })
            .required(),
          weight: yup
            .string()
            .required(COMMON_VALID_MSG.REQUIRED)
            .test(
              "maxValue",
              COMMON_VALID_MSG.MAX_VALUE_LIMIT,
              (value) => +deleteComma(value) <= 100000,
            ),
          quantity: yup.string().required(COMMON_VALID_MSG.REQUIRED),
          hazardGoods: yup.string().required(COMMON_VALID_MSG.REQUIRED),
          stackable: yup.string().required(COMMON_VALID_MSG.REQUIRED),
        })
        .required(),
    )
    .required(),
  payment: yup
    .object({
      method: yup.string().required(COMMON_VALID_MSG.REQUIRED),
      subtotal: yup
        .string()
        .defined()
        .test({
          name: "maxTotal",
          test: (value) => +deleteComma(value) < 2147483647,
          message: COMMON_VALID_MSG.INVALID_PRICE,
        }),
      tax: yup.string().required(),
    })
    .required(),
  truck: yup
    .array()
    .when(["clientType", "companyInformation"], {
      is: (
        clientType: string,
        { isCheckSelfTransport }: { isCheckSelfTransport: boolean },
      ) =>
        clientType === "carrier" ||
        (clientType === "forwardercarrier" && isCheckSelfTransport),
      then: () =>
        yup.array(
          yup.object({
            truckType: yup
              .mixed<DropdownOptionType>()
              .required()
              .test({
                name: "required",
                test: ({ key, label }) => !!(key && label),
                message: COMMON_VALID_MSG.REQUIRED,
              }),
            truckOption: yup
              .mixed<DropdownOptionType>()
              .required()
              .test({
                name: "required",
                test: ({ key, label }) => !!(key && label),
                message: COMMON_VALID_MSG.REQUIRED,
              }),
            truckNum: yup.number().required(),
          }),
        ),
      otherwise: () =>
        yup.array(
          yup.object({
            truckType: yup.mixed<DropdownOptionType>().optional(),
            truckOption: yup.mixed<DropdownOptionType>().optional(),
            truckNum: yup.number().optional(),
          }),
        ),
    })
    .required(),
  addFavorite: yup.boolean().required(),
});
