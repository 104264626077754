import React from "react";

import { useGoogleMap } from "@/contexts";
import { makeMarkerLayer } from "@/utils";

import * as S from "./GoogleMap.styled";

const GoogleMap = () => {
  const { ref, googleMap } = useGoogleMap();

  googleMap && makeMarkerLayer(googleMap);

  return <S.GoogleMap ref={ref} />;
};

export default GoogleMap;
