import React from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { useDetailFormEdit } from "hooks";
import type { GetPropertyDriverDetailClientModel } from "types";
import AccountInfoField from "./field/AccountInfoField";
import AccountInfoFieldEdit from "./fieldEdit/AccountInfoFieldEdit";
import { useAccountInfo } from "../hooks";

interface AccountInfoProps {
  accoutHolder: string;
  data: GetPropertyDriverDetailClientModel["accountInfo"];
}

const AccountInfo = ({ accoutHolder, data }: AccountInfoProps) => {
  const { id } = useParams();

  const { editState, handleEditState, handleCancel } = useDetailFormEdit();
  const { formMethod, handleSubmitAccountInfo, handleCancelAccountInfo } =
    useAccountInfo(data, id!, handleEditState);

  const handleClickEdit = () => {
    handleEditState("AccountInfo")();
  };

  const handleClickCancel = () => {
    handleCancelAccountInfo();
    handleCancel();
  };

  return (
    <FormProvider {...formMethod}>
      {editState !== "AccountInfo" ? (
        <AccountInfoField
          accountHolder={accoutHolder ?? "-"}
          handleClickEdit={handleClickEdit}
        />
      ) : (
        <AccountInfoFieldEdit
          accountHolder={accoutHolder ?? "-"}
          handleSubmit={handleSubmitAccountInfo}
          handleClickCancel={handleClickCancel}
        />
      )}
    </FormProvider>
  );
};

export default AccountInfo;
