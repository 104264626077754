import React from "react";

import { LabelContentTable } from "@/components";
import { formatOrderNum } from "@/utils";

import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationDefaultInformation.styled";

interface QuotationDefaultInformationProps {
  data: GetQuotationDetailServerModel<CompanyType>;
}

const QuotationDefaultInformation = ({
  data,
}: QuotationDefaultInformationProps) => {
  return (
    <>
      <S.OrderNumber>
        {formatOrderNum(data.defaultInfo.orderNumber)}
      </S.OrderNumber>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Company">
            {data.defaultInfo.client}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </>
  );
};

export default QuotationDefaultInformation;
