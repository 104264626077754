import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

import { CheckboxBtn, DataSection } from "@/components";
import { useCheckTableWithCondition } from "@/hooks";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";
import { PATH } from "@/assets";

import { FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_COLUMNS } from "assets";
import type { GetQuotationPendingServerModel } from "types";
import * as S from "./QuotationPendingSection.styled";

interface QuotationPendingSectionProps {
  data: GetQuotationPendingServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const QuotationPendingSection = ({
  data,
  dataUpdatedAt,
  refetch,
}: QuotationPendingSectionProps) => {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const { checkedIds, isChecked, isAllChecked, handleCheck, handleAllCheck } =
    useCheckTableWithCondition(data.pending, "orderId");

  const filteredColumns = filterTableColumns(
    FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_COLUMNS,
  );

  const formatTableData = (
    data: GetQuotationPendingServerModel["pending"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "orderNumber":
        return formatOrderNum(data[key]);

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddr":
        return addrFormatter(data.puAddr, data.puAddrDetail);
      case "doAddr":
        return addrFormatter(data.doAddr, data.doAddrDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
      activeBtns={
        <Link
          css={(theme) => S.link(theme, isEmpty(checkedIds))}
          to={isEmpty(checkedIds) ? "#" : PATH.QUOTATION_SEND}
          state={{
            selectedOrders: data.pending.filter((item) =>
              checkedIds.includes(item.orderId),
            ),
          }}
        >
          {t("Send quotation")}
        </Link>
      }
    >
      <DataSection.CheckTable
        css={S.checktable}
        isAllChecked={isAllChecked}
        columnList={FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_COLUMNS}
        isStickyThead
        handleAllCheck={handleAllCheck}
      >
        {data?.pending.length ? (
          data.pending.map((item) => {
            return (
              <DataSection.CheckTable.SelectRowMovePage
                key={item.offerId}
                id={item.offerId}
                path={item.offerId}
                state={{ from: searchParams.get("tab") }}
              >
                <DataSection.CheckTable.CheckTd>
                  <CheckboxBtn
                    shape="rectangle"
                    isChecked={isChecked(item.orderId)}
                    handleCheck={handleCheck(item.orderId)}
                  />
                </DataSection.CheckTable.CheckTd>
                {filteredColumns.map(({ key }) => (
                  <DataSection.CheckTable.Td key={key}>
                    {formatTableData(item, key)}
                  </DataSection.CheckTable.Td>
                ))}
              </DataSection.CheckTable.SelectRowMovePage>
            );
          })
        ) : (
          <DataSection.CheckTable.NoData>
            <Link css={S.linknoData} to={PATH.ORDER_CREATION}>
              {t("Order creation")}
            </Link>
          </DataSection.CheckTable.NoData>
        )}
      </DataSection.CheckTable>
    </DataSection>
  );
};

export default QuotationPendingSection;
