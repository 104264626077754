import * as type from "types";

// PICK, DROP 정렬 올바른지 확인하는 로직
export const isPickDropSortOk = (routeArr: type.SelectedRoute[]) => {
  // PICK, DROP 중 같은 orderId에서 뒤에 있는 값을 넣어줌
  const orderLastTypeObj = routeArr.reduce((acc, val) => {
    acc[val.orderId] = val.type;
    return acc;
  }, {} as { [key: string]: string });

  return Object.values(orderLastTypeObj).includes("PICK") ? false : true;
};
