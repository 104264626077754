import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Form = styled.form`
  ${({ theme }) => css`
    width: 520px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_32};
    margin-bottom: 40px;
  `}
`;

export const passwordInput = (hasError: boolean, theme: Theme) => css`
  border-bottom: 1px solid
    ${hasError ? theme.color.red_20 : theme.color.gray_30};
  padding-right: 20px;
`;

export const ConditionWrapper = styled.ul`
  display: flex;
  flex-flow: column;
  row-gap: 16px;
  margin-bottom: 40px;
`;

export const Condition = styled.li`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
    list-style: inside;
  `}
`;

export const loginErrMsg = css`
  margin-bottom: 24px;
`;

export const confirmBtn = (theme: Theme) => css`
  ${theme.font.bold_16};
  margin-top: 16px;
`;

export const NewPasswordTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const ConfirmPasswordTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const input = (theme: Theme) => css`
  ${theme.font.regular_16};
  width: 100%;
  margin-bottom: 40px;
  border: 0;
  border-bottom: 1px solid ${theme.color.gray_30};
  border-radius: 0;
  padding: 4px 0;

  :focus {
    outline: none;
    border: 0;
    border-bottom: 1px solid ${theme.color.primary_40};
  }
`;

export const InputRoot = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0px;
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const PasswordShowButton = styled.button`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    margin-top: 20px;
    background-color: inherit;

    & > svg {
      fill: ${theme.color.gray_50};
    }

    &[data-value="true"] > svg {
      fill: ${theme.color.primary_40};
    }
  `}
`;
