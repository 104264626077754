import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContent = css`
  width: 764px;
`;

export const plateNumberWrapper = css`
  & > div:last-of-type {
    display: flex;
    justify-content: space-between;
  }
`;

export const PlateNumberButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const plateNumberButton = css`
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 11px;
`;

export const FileWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const gpsIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    & > path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `;
