import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InternalLink, LabelContentTable } from "@/components";
import { formatICTDateTime, formatOrderNum } from "@/utils";
import { PATH } from "@/assets";

import type { GetPropertyDriverDetailClientModel } from "types";
import * as S from "./DrivingRecords.styled";

interface DrivingRecordsProps {
  data: GetPropertyDriverDetailClientModel["drivingRecords"];
}

const DrivingRecords = ({ data }: DrivingRecordsProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment time">
          {data.totalTransitTime ? `${data.totalTransitTime} hours` : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total shipment distance">
          {data.totalTransitDistance ? `${data.totalTransitDistance} km` : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Last shipping on">
          {data.lastShippingOn
            ? formatICTDateTime(data.lastShippingOn, "DD/MM/YYYY")
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Recent allocation">
          {data.recentAllocation ? (
            <S.AllocationContent>
              <span>{formatOrderNum(data.recentAllocation)}</span>
              <InternalLink
                css={S.link}
                variant="outlineMedium"
                to={`${PATH.PROPERTY_DRIVER_ALLOCATION}/${id!}`}
              >
                {t("See all")}
              </InternalLink>
            </S.AllocationContent>
          ) : (
            "-"
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Completed orders">
          {data.progressed}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default DrivingRecords;
