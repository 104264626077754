import { makeS3Promises } from "@/utils";
import type { S3FileUploadType } from "@/types";

import { postS3PresignedUrlAPI } from "apis";

export const makeS3PresignedUrlFileUuids = async (files: S3FileUploadType) => {
  const { fileUuids, S3Promises } = await makeS3Promises(
    files,
    postS3PresignedUrlAPI,
  );
  const s3Responses = await Promise.all(S3Promises);

  if (s3Responses.every((response) => response.status === 204)) {
    return fileUuids;
  }
  return {};
};
