import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinBtn_icon } from "@/styles";

export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const input = css`
  height: 44px;
  padding-right: 32px;
`;

export const PasswordShowBtn = styled.button<{ isShowPassword: boolean }>`
  ${({ theme, isShowPassword }) => css`
    position: absolute;
    right: 12px;
    height: 28px;

    & > svg > path {
      width: 20px;
      height: 20px;
      fill: ${isShowPassword ? theme.color.primary_40 : theme.color.gray_50};
    }
  `}
`;
