import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  DownloadBtn,
  InternalLink,
  LabelContentTable,
  Loading,
  SettingDetailLayout,
} from "@/components";
import {
  findLookupTableLabel,
  formatICTDateTime,
  formatOrderNum,
  upperCaseToupperFirst,
} from "@/utils";
import { PATH } from "@/assets";

import { useGetInquiryDetail, useDownloadFile } from "services";
import { INQUIRY_CATEGORY_OPTIONS } from "assets";

import * as S from "./SupportDetail.styled";

const SupportDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useGetInquiryDetail(id!);
  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  if (!data) return <Loading />;

  const supportCategoryLabel =
    findLookupTableLabel(INQUIRY_CATEGORY_OPTIONS, data.type) ?? "-";

  return (
    <SettingDetailLayout>
      <LabelContentTable css={S.detailTable} variant="bg" subject="Details">
        <LabelContentTable.Row partition={2}>
          <LabelContentTable.Content label="Category">
            {supportCategoryLabel}
          </LabelContentTable.Content>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(data.created) ?? "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {data.type === "ORDER" && (
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Order number">
              {formatOrderNum(data.orderNumber!)}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            {data.status ? upperCaseToupperFirst(data.status) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row marginTop={10}>
          <LabelContentTable.Content label="Subject">
            {data.title ?? "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Description">
            {data.content ?? "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Attached file">
            <S.FileWrapper>
              {!data.file1 && !data.file2 && !data.file3 ? (
                "-"
              ) : (
                <>
                  {[data.file1, data.file2, data.file3]
                    .filter((fileKey) => !!fileKey)
                    .map((fileKey, idx) => (
                      <DownloadBtn
                        key={fileKey}
                        fileKey={fileKey!}
                        downloadFileNamePrefix={"support"}
                        fileName={`File 0${idx + 1}`}
                        isLoading={isLoading}
                        downloadFileMutate={downloadFileMutate}
                      />
                    ))}
                </>
              )}
            </S.FileWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row marginTop={34}>
          <LabelContentTable.Content label="Answered">
            {data.replyCreated ? formatICTDateTime(data.replyCreated) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.description} label="Description">
            {data.replyContent ?? "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <InternalLink
        css={S.internalLink}
        variant="outlineMedium"
        to={PATH.SETTINGS_SUPPORT}
      >
        {t("Back to list")}
      </InternalLink>
    </SettingDetailLayout>
  );
};

export default SupportDetail;
