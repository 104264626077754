import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    width: 564px;
    min-height: calc(100vh - ${theme.size.HEADER_HEIGHT});
    margin: 0 auto;
    padding-top: 33px;
    padding-bottom: 40px;
  `}
`;

export const pageBackHeader = (theme: Theme) => css`
  border: 0;
  padding: 0;
  padding-bottom: 12px;
  background-color: inherit;

  span {
    ${theme.font.bold_18};
  }
`;

export const card = css`
  width: 564px;
  row-gap: 32px;

  & > div > div:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
