import React from "react";

import { LabelContentTable } from "@/components";
import { formatICTDateTime, formatPhoneNum } from "@/utils";

import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationDropOffInformation.styled";

interface QuotationDropOffInformationProps {
  data: GetQuotationDetailServerModel<CompanyType>;
}
const QuotationDropOffInformation = ({
  data,
}: QuotationDropOffInformationProps) => {
  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address">
          <S.AddrWrapper>
            <span>{data.dropOffInfo.doAddr}</span>
            {data.dropOffInfo.doAddrDetail && (
              <S.AddrDetail>{data.dropOffInfo.doAddrDetail}</S.AddrDetail>
            )}
          </S.AddrWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge">
          {data.dropOffInfo.doPic}{" "}
          {`(${formatPhoneNum(data.dropOffInfo.doPhone)})`}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA">
          {formatICTDateTime(data.dropOffInfo.doEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD">
          {data.dropOffInfo.doEtd
            ? formatICTDateTime(data.dropOffInfo.doEtd)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default QuotationDropOffInformation;
