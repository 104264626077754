import React from "react";

import { DataSection, Profile, StatusLabel } from "@/components";
import {
  filterTableColumns,
  formatICTDateTime,
  formatPhoneNum,
  makeNameString,
} from "@/utils";
import { PROPERTY_DRIVER_TABLE_COLUMNS } from "assets";
import type { GetDriverServerModel } from "types";
import * as S from "./DriverSection.styled";

interface DriverSectionProps {
  data?: GetDriverServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const DriverSection = ({
  data,
  dataUpdatedAt,
  refetch,
}: DriverSectionProps) => {
  const filteredColumns = filterTableColumns(PROPERTY_DRIVER_TABLE_COLUMNS);

  const formatTableData = (
    data: GetDriverServerModel["drivers"][number],
    key: keyof GetDriverServerModel["drivers"][number],
  ) => {
    switch (key) {
      case "status":
        return <StatusLabel status={data[key]} />;

      case "phone":
        return formatPhoneNum(data[key]);

      case "profile": {
        const name = makeNameString(
          data.lastName,
          data.middleName,
          data.firstName,
        );

        return (
          <S.Driver>
            <Profile src={data.profileS3Key ?? ""} />
            <span>{name}</span>
          </S.Driver>
        );
      }
      case "lastShippingOn": {
        const date = data[key]; // NOTE: TS 에러로 인하여 중간변수 사용
        return date ? formatICTDateTime(date) : "-";
      }

      case "created":
        return formatICTDateTime(data[key], "DD/MM/YYYY");

      case "linkedRegistNumber":
        return data[key] || "-";

      default:
        return data[key];
    }
  };

  if (!data) return null;

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Driver"
        isStickyThead
        rowCount={data.drivers.length}
      >
        <DataSection.Table.Head columnList={PROPERTY_DRIVER_TABLE_COLUMNS} />
        <DataSection.Table.Body css={S.body}>
          {data.drivers.length ? (
            data.drivers.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.driverId}
                path={`driver/${item.driverId}`}
              >
                {filteredColumns.map(({ key }) => (
                  <DataSection.Table.Cell key={key}>
                    {formatTableData(item, key as keyof typeof item)}
                  </DataSection.Table.Cell>
                ))}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData />
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default DriverSection;
