import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  ErrMsg,
  FormFileInput,
  Input,
  LabelContent,
  Textarea,
} from "@/components";
import { getFormattedOrderNum } from "@/utils";
import { FILE_DESC } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import type { InquiryForm } from "types";
import * as S from "./SupportField.styled";
import { selectDropdownForm } from "utils";

interface SupportFieldProps {
  className?: string;
  categories: readonly DropdownOptionType[];
  handleFileAdd: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (fileKey: string) => () => void;
}

const SupportField = ({
  className,
  categories,
  handleFileDelete,
  handleFileAdd,
}: SupportFieldProps) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { errors },
    resetField,
    register,
  } = useFormContext<InquiryForm>();

  return (
    <S.Root className={className}>
      <S.ContentWrapper>
        <LabelContent
          css={S.labelContent}
          label="Category"
          direction="vertical"
          isRequired
        >
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange } }) => {
              const handleSelect = (categoryKey: string) => {
                resetField("orderNumber");
                selectDropdownForm(categories, onChange)(categoryKey);
              };

              return (
                <Dropdown
                  css={S.dropdown}
                  options={categories}
                  selectedOption={value}
                  handleSelect={handleSelect}
                />
              );
            }}
          />
        </LabelContent>
        <S.OrderNumberWrapper>
          {watch("type").key === "ORDER" && (
            <LabelContent label="Order number" direction="vertical" isRequired>
              <Input
                maxLength={13}
                placeholder="Order number"
                value={watch("orderNumber")}
                hasError={!!errors.orderNumber?.message}
                register={register("orderNumber", {
                  setValueAs: getFormattedOrderNum,
                })}
              />
            </LabelContent>
          )}
          {!!errors.orderNumber?.message && (
            <ErrMsg css={S.errorMsg}>
              {errors.orderNumber?.message as Languages}
            </ErrMsg>
          )}
        </S.OrderNumberWrapper>
      </S.ContentWrapper>

      <LabelContent
        css={S.labelContent}
        label="Subject"
        direction="vertical"
        isRequired
      >
        <Input
          maxLength={50}
          placeholder="Subject"
          value={watch("title")}
          hasError={!!errors.title?.message}
          register={register("title")}
        />
        {!!errors.title?.message && (
          <ErrMsg css={S.errorMsg}>{errors.title?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent
        css={S.labelContent}
        label="Description"
        direction="vertical"
        isRequired
      >
        <Textarea
          css={S.textarea}
          id="content"
          maxLength={1000}
          placeholder="Please enter your inquiry"
          value={watch("content")}
          hasError={!!errors.content?.message}
          register={register("content")}
        />
        {!!errors.content?.message && (
          <ErrMsg css={S.errorMsg}>
            {errors.content?.message as Languages}
          </ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Attached file" direction="vertical">
        <FormFileInput
          css={S.attachFile}
          placeholder="Upload files related to inquiry"
          file={watch("file1")}
          fileKey="file1"
          hasErr={!!errors.file1?.message}
          register={register}
          handleInputChange={handleFileAdd}
          handleDeleteFile={handleFileDelete}
        />
        <FormFileInput
          css={S.attachFile}
          placeholder="Upload files related to inquiry"
          file={watch("file2")}
          fileKey="file2"
          hasErr={!!errors.file2?.message}
          register={register}
          handleInputChange={handleFileAdd}
          handleDeleteFile={handleFileDelete}
        />
        <FormFileInput
          css={S.attachFile}
          placeholder="Upload files related to inquiry"
          file={watch("file3")}
          fileKey="file3"
          hasErr={!!errors.file3?.message}
          register={register}
          handleInputChange={handleFileAdd}
          handleDeleteFile={handleFileDelete}
        />
      </LabelContent>
      <S.DescWrapper>
        <S.Desc>{`- ${t(FILE_DESC)}`}</S.Desc>
      </S.DescWrapper>
    </S.Root>
  );
};

export default SupportField;
