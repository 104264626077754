import React from "react";
import { useTranslation } from "react-i18next";
import { isNil } from "lodash-es";

import { InternalLink, LabelContentTable } from "@/components";
import { formatICTDateTime } from "@/utils";
import { DRIVER_DELIVERY_STATUS, PATH } from "@/assets";
import type { Languages } from "@/types";

import { AllocationDriver } from "components";
import { useDownloadFile } from "services";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementAllocation.styled";

interface OrderManagementAllocationInfoProps {
  isEdit?: boolean;
  truckSum?: number;
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementAllocationInfo = ({
  isEdit,
  truckSum,
  data,
}: OrderManagementAllocationInfoProps) => {
  const { t } = useTranslation();
  const { mutate: downloadFileMutate } = useDownloadFile();

  const isShowAllocateLink =
    (data.status === "SHIPPING" || data.status === "OFFER_ACCEPTED") &&
    !!data.isSelfTransport &&
    !isEdit &&
    (isNil(truckSum) || truckSum > (data.allocationInfo.drivers?.length || 0));

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Request date">
          {data.allocationInfo.requestedDate
            ? formatICTDateTime(data.allocationInfo.requestedDate)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      {data.allocationInfo.drivers.length
        ? data.allocationInfo.drivers.map((driver, i) => (
            <LabelContentTable.Content
              css={S.content}
              key={i}
              // NOTE: label에 (number)도 들어가기 때문에 as Languages 삭제하면 안됨
              label={`${t("Drive")} (${i + 1})` as Languages}
            >
              <AllocationDriver
                profileUrl={driver.profileS3Url ?? null}
                // TODO: allocationId 서버에서 반환하도록 수정 후 확인 필요
                allocationPath={`/orderManagement/allocation?tab=ongoing&driverId=${driver.driverId}&allocationId=${driver.allocationId}`}
                name={driver.driverName ?? ""}
                phone={driver.driverPhone ?? ""}
                status={
                  driver.driverStatus ??
                  ("" as keyof typeof DRIVER_DELIVERY_STATUS)
                }
                plateNumber={driver.plateNumber ?? ""}
                deliveryLogs={{
                  shipmentsStart: driver.shipmentsStart,
                  puAta: driver.driverPuAta,
                  puAtd: driver.driverPuAtd,
                  doAta: driver.driverDoAta,
                  doAtd: driver.driverDoAtd,
                }}
                certificationImgs={{
                  puSign: driver.driverPuSign,
                  puPhoto: driver.driverPuPhoto,
                  doSign: driver.driverDoSign,
                  doPhoto: driver.driverDoPhoto,
                }}
                isSelfTransport={!!data.isSelfTransport}
                downloadFileMutate={downloadFileMutate}
              />
            </LabelContentTable.Content>
          ))
        : Array.from({ length: truckSum! }).map((_, i) => (
            <LabelContentTable.Content
              css={S.content}
              key={i}
              // NOTE: label에 (number)도 들어가기 때문에 as Languages 삭제하면 안됨
              label={`${t("Drive")} (${i + 1})` as Languages}
            >
              -
            </LabelContentTable.Content>
          ))}
      {isShowAllocateLink && (
        <S.BottomWrapper>
          <InternalLink
            css={S.allocateLink}
            variant="primaryLarge"
            to={PATH.ALLOCATION}
          >
            {t("Allocate now")}
          </InternalLink>
        </S.BottomWrapper>
      )}
    </LabelContentTable>
  );
};

export default OrderManagementAllocationInfo;
