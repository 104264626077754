import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.article`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 24px;

    &[aria-selected="true"] {
      border-color: ${theme.color.primary_40};
      background-color: ${theme.color.primary_10};
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    z-index: 10;

    & > span {
      ${theme.font.regular_13};
      color: ${theme.color.gray_60};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    margin-right: 12px;
    color: ${theme.color.black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`;

export const Container = styled.dl`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.color.gray_60};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 100px 320px;
    padding: 12px 0;

    &:not(:first-of-type) {
      border-top: 1px solid ${theme.color.gray_30};
    }

    & > dt {
      ${theme.font.medium_14};
      color: ${theme.color.gray_60};
    }
  `}
`;

export const DescInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;

    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:nth-of-type(1) {
      margin-bottom: 4px;

      & > span {
        ${theme.font.medium_14};
        color: ${theme.color.black};
      }
    }

    &:not(:last-of-type):nth-of-type(2) {
      margin-bottom: 8px;
    }

    &:nth-of-type(2) > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_70};
    }
  `}
`;

export const CardSelectBtn = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const BtnWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 8px;
`;

export const editBtn = (theme: Theme) => css`
  ${theme.button.icon};
  width: 20px;
  height: 20px;
  z-index: 2;

  & > svg {
    margin: 0;
  }
`;

export const deleteBtn = css`
  width: 20px;
  height: 20px;
  z-index: 2;

  & > svg {
    margin: 0;
  }
`;
