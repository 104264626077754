import React from "react";
import { useTranslation } from "react-i18next";

import { Button, DialogBtn } from "@/components";
import { useModal, useToast } from "@/hooks";
import { addrFormatter } from "@/utils";
import { PencilIcon, TrashSmallIcon } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";
import type { FavoriteOrdersClientModel } from "@/types";

import { useDeleteFavoriteOrder } from "services";
import EditFavoriteNameDialog from "./nameDialog/EditFavoriteNameDialog";
import DelFavoriteOrderModal from "./modal/DelFavoriteOrderModal";
import * as S from "./FavoriteOrderCard.styled";

interface FavoriteOrderCardProps<
  T extends Omit<FavoriteOrdersClientModel, "cargo">,
> {
  data: T;
  selectedOrderId: string | null;
  searchValue: string;
  handleSelectFavorite: () => void;
}

const FavoriteOrderCard = <T extends Omit<FavoriteOrdersClientModel, "cargo">>({
  data,
  selectedOrderId,
  handleSelectFavorite,
}: FavoriteOrderCardProps<T>) => {
  const { t } = useTranslation();

  const { mutate: deleteFavoriteOrderMutate } = useDeleteFavoriteOrder();

  const { modalRef, handleModalOpen } = useModal();
  const { addToast } = useToast();

  const handleDeleteFavoriteOrderMutate = (favoriteId: string) => {
    deleteFavoriteOrderMutate(favoriteId, {
      onError: () => {
        // TODO: API error에 따라 설정해주기
        addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
      },
    });
  };

  return (
    <S.Root aria-selected={selectedOrderId === data.favoriteId}>
      <S.Header>
        <S.Title>{data.favoriteName}</S.Title>
        <S.BtnWrapper>
          <DialogBtn
            css={S.editBtn}
            popup={({ isDialogOpen, handleDialogClose }) => (
              <EditFavoriteNameDialog
                initName={data.favoriteName}
                favoriteId={data.favoriteId}
                isDialogOpen={isDialogOpen!}
                handleCloseDialog={handleDialogClose}
              />
            )}
          >
            <PencilIcon />
          </DialogBtn>
          <Button
            css={S.deleteBtn}
            variant="icon"
            icon={<TrashSmallIcon />}
            handleClickBtn={handleModalOpen(
              <DelFavoriteOrderModal
                ref={modalRef}
                favoriteId={data.favoriteId}
                handleDelFavoriteOrder={handleDeleteFavoriteOrderMutate}
              />,
            )}
          />
        </S.BtnWrapper>
      </S.Header>
      <S.Container>
        <S.Content>
          <dt>{t("Pick-up")}</dt>
          <dd>
            <S.DescInfo>
              <span>{data.pickUp.buildingName}</span>
            </S.DescInfo>
            <S.DescInfo>
              <span>
                {addrFormatter(data.pickUp.addr, data.pickUp.addrDetail)}
              </span>
            </S.DescInfo>
          </dd>
        </S.Content>
        <S.Content>
          <dt>{t("Drop-off")}</dt>
          <dd>
            <S.DescInfo>
              <span>{data.dropOff.buildingName}</span>
            </S.DescInfo>
            <S.DescInfo>
              <span>
                {addrFormatter(data.dropOff.addr, data.dropOff.addrDetail)}
              </span>
            </S.DescInfo>
          </dd>
        </S.Content>
      </S.Container>
      <S.CardSelectBtn type="button" onClick={handleSelectFavorite}>
        <span className="a11y">order select toggle</span>
      </S.CardSelectBtn>
    </S.Root>
  );
};

export default FavoriteOrderCard;
