import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { isEmpty } from "lodash-es";
import { useTranslation } from "react-i18next";

import { NoResult, SearchInput } from "@/components";
import { clientLanguage } from "@/stores";

import { useGetAllocationTruckAll } from "services";
import type { AllocationAllTruck } from "types";
import * as S from "./TruckEditDialog.styled";

interface TruckEditDialogProps {
  selectedTruckId: string;
  handleDialogClose: () => void;
  cbFn: any;
}

const TruckEditDialog = ({
  selectedTruckId,
  handleDialogClose,
  cbFn,
}: TruckEditDialogProps) => {
  const { t } = useTranslation();

  const [searchWord, setSearchWord] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const languages = useRecoilValue(clientLanguage);

  const req = {
    query: {
      ...(searchWord && { registNumber: searchWord }),
    },
  };

  const { data: trucks } = useGetAllocationTruckAll(req);

  const filterExcludeSelectedTrucks = trucks?.filter(
    (truck) => truck.truckId !== selectedTruckId,
  );

  const handleSearch = (q: string) => {
    setSearchWord(q);
  };

  const handleChangeTruck = (item: AllocationAllTruck) => () => {
    handleDialogClose();
    setSearchValue("");
    setSearchWord("");
    cbFn(item);
  };

  const handleClickReload = () => {
    setSearchWord("");
    setSearchValue("");
  };

  const changeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <S.Root>
      <SearchInput
        placeholder="Search by plate number"
        value={searchValue}
        handleChange={changeSearchValue}
        handleSearch={handleSearch}
        handleReset={handleClickReload}
      />
      <S.Body>
        {!isEmpty(filterExcludeSelectedTrucks) ? (
          filterExcludeSelectedTrucks?.map((item) => {
            return (
              <li key={item.truckId}>
                <S.Btn type="button" onClick={handleChangeTruck(item)}>
                  <div>
                    <S.TruckNum>{item.registNumber}</S.TruckNum>
                  </div>
                  <div>
                    <S.TruckInfo>
                      {languages === "en" ? item.typeEn : item.typeVi} (
                      {languages === "en" ? item.optionEn : item.optionVi})
                    </S.TruckInfo>
                  </div>
                </S.Btn>
              </li>
            );
          })
        ) : (
          <NoResult />
        )}
      </S.Body>
    </S.Root>
  );
};
export default TruckEditDialog;
