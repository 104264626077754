import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const grayIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;

export const orderCard = css`
  height: 130px;
  padding: 12px 20px;
`;

export const svg = (theme: Theme) => css`
  ${grayIcon(theme)};
  width: 20px;
  height: 20px;
`;

export const Ellipsis = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
