import styled from "@emotion/styled";

import { mixin_scrollbar } from "@/styles";

export const Root = styled.div`
  ${mixin_scrollbar};
  height: 536px;
`;

export const Favorites = styled.ul`
  & > li {
    margin-bottom: 12px;
  }
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & > svg {
    margin-bottom: 8px;
  }
`;
