import React from "react";
import { useFormContext } from "react-hook-form";

import { Button, LabelContentTable } from "@/components";

import type { DriverAccountForm } from "types";
import * as S from "./AccountInfoField.styled";

interface AccountInfoFieldProps {
  accountHolder: string;
  handleClickEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AccountInfoField = ({
  accountHolder,
  handleClickEdit,
}: AccountInfoFieldProps) => {
  const { watch } = useFormContext<DriverAccountForm>();

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Name of bank">
            {watch("bankName") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Branch">
            {watch("bankBranch") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Account number">
            {watch("bankAccountNo") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Account holder">
            {accountHolder}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleClickEdit}
        />
      </S.BtnWrapper>
    </>
  );
};

export default AccountInfoField;
