import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Layout = styled.section`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 270px 1fr;
    column-gap: 32px;
    min-width: ${theme.size.SECTION_MIN_WIDTH};
    max-width: ${theme.size.SECTION_MAX_WIDTH};
    min-height: calc(100vh - ${theme.size.HEADER_HEIGHT}) -
      ${theme.size.FOOTER_HEIGHT};
    margin: 0 auto;
    padding: 33px 40px 50px;
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 12px;

    & > span {
      ${theme.font.regular_14};
      display: block;
      margin-top: 2px;
      color: ${theme.color.gray_60};
    }
  `}
`;

export const MenuList = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
  `}
`;

export const MenuItem = styled.li`
  ${({ theme }) => css`
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray_30};
    }
  `}
`;

export const navLink = (theme: Theme) => css`
  ${theme.font.regular_15};
  display: block;
  padding: 13px 17px;
  color: ${theme.color.gray_70};

  &.active {
    font-weight: 500;
    color: ${theme.color.primary_40};
  }
`;
