import type { Languages } from "@/types";

import type { GetTruckGarageServerModel } from "types";

// TODO: any 타입 수정
export const deleteObjectKeyWithEmptyValue = (obj: {
  [key: string]: any;
}): {
  [key: string]: any;
} => {
  for (const key in obj) {
    if (obj[key] !== 0 && !obj[key]) {
      delete obj[key];
    }
  }
  return obj;
};

export const swapKeyValue = (obj: {
  [key: string]: string;
}): {
  [key: string]: string;
} => {
  const swapObj: { [key: string]: string } = {};
  for (const key in obj) {
    swapObj[obj[key]] = key;
  }
  return swapObj;
};

export const getTruckGarageData = (
  garages: GetTruckGarageServerModel[],
): {
  key: string;
  label: Languages;
}[] => {
  const formattedGarages = garages.map(
    (garages: { garageId: string; name: Languages }) => ({
      key: garages.garageId,
      label: garages.name,
    }),
  );

  return formattedGarages;
};

export const addPrefixToUrlIfNeeded = (url: string, prefix: string): string => {
  if (!url) return "";
  return url.includes(prefix) ? url : `${prefix}/${url}`;
};
