import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 16px;
`;

export const loadingMethodInput = css`
  margin-top: 4px;
`;

export const FileLimit = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const labelcontent = (theme: Theme) => css`
  svg path {
    fill: ${theme.color.gray_70};
  }
`;
