import React from "react";

import { CompanyLicenseRegistrationLayout } from "components";
import ShipperCompanyLicenseRegistrationForm from "./form/ShipperCompanyLicenseRegistrationForm";

const ShipperCompanyLicenseRegistration = () => {
  return (
    <CompanyLicenseRegistrationLayout
      licenseRegistrationForm={<ShipperCompanyLicenseRegistrationForm />}
    />
  );
};

export default ShipperCompanyLicenseRegistration;
