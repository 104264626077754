import { ax } from "apis/axios";
import { getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded } from "utils";
import type {
  GetGarageQueryModel,
  GetGarageServerModel,
  GetGarageDetailServerModel,
  CreateGarageQueryModel,
  UpdateGarageQueryModel,
} from "types";

export const getPropertyGaragesAPI = async (req: GetGarageQueryModel) => {
  const { data } = await ax.get<GetGarageServerModel>("/property/garage", {
    params: req.query,
  });
  return data;
};

export const getPropertyGarageDetailAPI = async (garageId: string) => {
  const { data } = await ax.get<GetGarageDetailServerModel>(
    `property/garage/${garageId}`,
  );

  const trucks = await Promise.all(
    data.trucks.map(async (truck) => ({
      ...truck,
      drivers: await Promise.all(
        truck.drivers.map(async (driver, i) => {
          if (driver.profile && i === 0) {
            const profileUrl = await getS3PresignedUrlAPI(
              addPrefixToUrlIfNeeded(driver.profile, "profile"),
            );

            return { ...driver, profileUrl };
          }
          return driver;
        }),
      ),
    })),
  );

  return { ...data, trucks };
};

export const createPropertyGarageAPI = (req: CreateGarageQueryModel) =>
  ax.post("/property/garage", req.body);

export const updatePropertyGarageAPI = (req: UpdateGarageQueryModel) =>
  ax.put(`/property/garage/${req.garageId}`, req.body);

export const deletePropertyGarageAPI = (garageId: string) =>
  ax.delete(`/property/garage/${garageId}`);
