// NOTE: https://velog.io/@leeguts/react-google-mapsapi-%EC%82%AC%EC%9A%A9%EC%8B%9C-%EB%A7%88%EC%BB%A4%ED%9A%8C%EC%A0%84%EC%9D%98-%EB%AC%B8%EC%A0%9C%ED%95%B4%EA%B2%B0%EA%B3%BC%EC%A0%95
export const rotateSVGIcon = async ({
  svgUrl,
  rotation,
}: {
  svgUrl: string;
  rotation: number;
}): Promise<string | undefined> => {
  try {
    const res = await fetch(svgUrl);
    const svgData = await res.text();

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx) {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.translate(image.width / 2, image.height / 2);
          ctx.rotate((rotation * Math.PI) / 180);
          ctx.translate(-image.width / 2, -image.height / 2);

          ctx.drawImage(image, 0, 0);

          resolve(canvas.toDataURL());
        } else {
          reject(
            new Error("2D context not supported or canvas already initialized"),
          );
        }
      };

      image.src = "data:image/svg+xml;base64," + btoa(svgData);
    });
  } catch (error) {
    console.error("Loading SVG error:", error);
  }
};
