import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AutoHeightTextarea, Button, Tooltip } from "@/components";
import { addrFormatter, formatPhoneNum } from "@/utils";
import {
  PhoneIcon,
  ArrowVariantIcon,
  TrashSmallIcon,
  PencilIcon,
  PointIcon,
} from "@/assets";
import type { Languages } from "@/types";

import { OrderCard, OrderInfo } from "components";
import * as S from "./RouteCard.styled";

interface RouteCardProps {
  data: any; // TODO: 배차 생성/수정 route, 진행중인 배차 상세 route 공통 Type 적용하기
  isNeedMemo?: boolean;
  changeMemo?: (id: string, memo: string) => void;
  isMute?: boolean;
  dropArea?: boolean;
  isLifted?: boolean;
  needDragDrop?: boolean;
  draggable?: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
}

const RouteCard = ({
  data,
  isNeedMemo = false,
  isMute,
  dropArea,
  isLifted,
  needDragDrop,
  changeMemo,
  draggable,
  onDragStart,
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onMouseDown,
  onMouseUp,
}: RouteCardProps) => {
  const allocationChargeTooltip = {
    title: data.pic, //TODO: pickup / dropoff 담당자 데이터 요청 필요
    contents: (
      <>
        <PhoneIcon />
        {formatPhoneNum(data.phone)}
      </>
    ),
  };

  // TODO: any 타입, 옵셔널 수정 예정
  const deliveryCargoes = data.cargoes?.map((cargo: any) => ({
    cargoType: cargo.cargoType,
    weight: cargo.weight,
    cargoCnt: cargo.cargoCnt,
  }));

  return (
    <OrderCard
      css={S.orderCard({ isMute, dropArea, isLifted, needDragDrop })}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <S.TitleWrapper>
        <PointIcon css={S.pointIcon(data.type)} />
        <OrderCard.Title css={S.orderTitle} content={data.name} />
        <S.OrderIdx>(#{data.idx + 1})</S.OrderIdx>
        <Tooltip
          css={S.phoneTooltip}
          title={allocationChargeTooltip.title as Languages}
          tooltipBtnContent={PhoneIcon}
          position="BOTTOM_RIGHT"
        >
          {allocationChargeTooltip.contents}
        </Tooltip>
      </S.TitleWrapper>
      <S.Address>{addrFormatter(data.addr, data.addrDetail)}</S.Address>
      <OrderInfo>
        <OrderInfo.Cargo
          css={S.orderInfoRow}
          loadingStatus={data.loadingStatus}
          cargoes={deliveryCargoes}
        />
        <OrderInfo.Time css={S.orderInfoRow} label="ETA" time={data.eta} />
        <OrderInfo.Time
          css={S.orderInfoEtdRow(isNeedMemo)}
          label="ETD"
          time={data.etd}
        />
      </OrderInfo>
      {isNeedMemo && (
        <RouteCard.Memo
          id={data.id}
          changeMemo={changeMemo}
          initMemo={data.memo}
        />
      )}
    </OrderCard>
  );
};

export default RouteCard;

interface RouteCardMemoProps {
  id: string;
  initMemo: string;
  changeMemo?: (id: string, memo: string) => void;
}

RouteCard.Memo = function RouteCardMemo({
  id,
  initMemo,
  changeMemo,
}: RouteCardMemoProps) {
  const [isWriteMsg, setIsWriteMsg] = useState(false);
  const [isSubmit, setIsSubmit] = useState(initMemo ? true : false);
  const [msg, setMsg] = useState(initMemo || "");

  const { t } = useTranslation();

  const handleClickWriteMsg = (e: any) => {
    e.stopPropagation();
    setIsWriteMsg(true);
  };

  const handleChangeMsg = (e: any) => {
    const value = e.target.value;
    if (value.length > 200) return;
    setMsg(value);
  };

  const handleSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSubmit(true);
    changeMemo && changeMemo(id, msg);
  };

  const handleDeleteMsg = (e: any) => {
    e.stopPropagation();
    setMsg("");
    setIsSubmit(false);
    changeMemo && changeMemo(id, "");
  };

  const handleEditMsg = (e: any) => {
    e.stopPropagation();
    setIsSubmit(false);
  };

  return (
    <>
      {msg || isWriteMsg ? (
        isSubmit ? (
          <S.MsgWrapper>
            <S.Content>{msg}</S.Content>
            <S.IconWrapper>
              <button type="button" onClick={handleDeleteMsg}>
                <TrashSmallIcon css={S.trashIcon} />
              </button>
              <button type="button" onClick={handleEditMsg}>
                <PencilIcon css={S.pencilIcon} />
              </button>
            </S.IconWrapper>
          </S.MsgWrapper>
        ) : (
          <S.WriteMsgForm onSubmit={handleSubmit}>
            <S.MessageTitle>
              <span>{t("Message")}</span>
              <S.ContentLength isEnter={!!msg.length}>
                <span>{msg.length}</span>/200
              </S.ContentLength>
            </S.MessageTitle>
            <AutoHeightTextarea
              css={S.textarea}
              placeholder="Send a message to the driver..."
              value={msg}
              onChange={handleChangeMsg}
            />
            {Boolean(msg) && (
              <S.Wrapper>
                <S.SubmitWriteMsgBtn
                  type="submit"
                  isMsg={Boolean(msg)}
                  disabled={!msg}
                >
                  <ArrowVariantIcon />
                </S.SubmitWriteMsgBtn>
              </S.Wrapper>
            )}
          </S.WriteMsgForm>
        )
      ) : (
        <Button
          css={S.createMsgBtn}
          type="button"
          label={`+ ${t("Message")}` as Languages}
          variant="ghostPrimary"
          handleClickBtn={handleClickWriteMsg}
        />
      )}
    </>
  );
};
