import React from "react";

import { OngoingDetail, Foldable2DepthLayout } from "components";

const ForwarderCarrierOrderManagementAllocation = () => {
  return (
    <Foldable2DepthLayout>
      <OngoingDetail type="order" />
    </Foldable2DepthLayout>
  );
};

export default ForwarderCarrierOrderManagementAllocation;
