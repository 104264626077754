import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Root = styled.div`
  position: relative;
  display: flex;
  column-gap: 72px;
`;

export const PageName = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 8px;
  `}
`;

export const StickyTop = styled.div`
  margin-bottom: 20px;
`;

export const Sticky = styled.div`
  position: sticky;
  top: calc(32px + 52px);
  width: 216px;
  height: fit-content;
`;

export const Link = styled.a<{ isSelect: boolean }>`
  ${({ theme, isSelect }) => css`
    ${theme.font.regular_15};
    display: block;
    height: 40px;
    border-left: 4px solid
      ${isSelect ? theme.color.primary_40 : theme.color.gray_30};
    padding: 9px 16px;
    color: ${isSelect ? theme.color.black : theme.color.gray_50};

    :hover {
      color: ${theme.color.gray_80};
    }
  `}
`;
export const StickyBottom = styled.div`
  margin-top: 20px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 32px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 28px;
    border: 1px solid ${theme.color.gray_30};
    padding: 32px;
    background-color: ${theme.color.white};
  `}
`;

export const Heading = styled.div`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    color: ${theme.color.black};
    border-bottom: 1px solid ${theme.color.gray_60};
    padding-bottom: 20px;
  `}
`;

export const Body = styled.div`
  width: 744px;
  margin-bottom: 12px;
`;
