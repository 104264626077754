import React, {
  useMemo,
  useState,
  createContext,
  useRef,
  useContext,
  useEffect,
} from "react";

import { useAuth } from "@/contexts";
import { DEFAULT_MAP_COORD } from "@/constants";

export const GoogleMapContext = createContext<{
  googleMap: google.maps.Map | null;
  setGoogleMap: ((map: google.maps.Map) => void) | null;
}>({
  googleMap: null,
  setGoogleMap: null,
});

export const useGoogleMap = () => {
  const ref = useRef(null);

  const { googleMap, setGoogleMap } = useContext(GoogleMapContext);

  const { auth } = useAuth();

  useEffect(() => {
    if (ref.current && setGoogleMap) {
      setGoogleMap(
        new google.maps.Map(ref.current, {
          center: auth.position || DEFAULT_MAP_COORD,
          zoom: 13,
          streetViewControl: false,
        }),
      );
    }
  }, [ref.current, setGoogleMap]);

  return { ref, googleMap };
};

export const GoogleMapProvider = ({ children }: { children: JSX.Element }) => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);

  const value = useMemo(
    () => ({
      googleMap,
      setGoogleMap,
    }),
    [googleMap],
  );

  return (
    <GoogleMapContext.Provider value={value}>
      {children}
    </GoogleMapContext.Provider>
  );
};
