import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Container = styled.dl`
  ${({ theme }) => css`
    display: grid;
    margin-bottom: 12px;
    border: 1px solid ${theme.color.gray_35};
    background-color: ${theme.color.gray_30};
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 160px 1fr;
    align-items: center;
    padding: 16px 0;
    background-color: ${theme.color.white};

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray_30};
    }

    dt,
    dd {
      padding: 0 25px;
      white-space: nowrap;
    }

    dt {
      ${theme.font.medium_13};
      color: ${theme.color.gray_60};
    }

    dd {
      ${theme.font.regular_14};
      color: ${theme.color.black};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `}
`;

export const editBtn = css`
  margin: 12px 0 0 auto;
`;
