import React from "react";

import {
  OrderShipping,
  OrderCompleted,
  OrderCanceled,
  OrderManagementLayout,
} from "components";
import { ShipperOrderAllocation } from "./panel";

const ShipperOrderManagement = () => {
  const render = {
    allocation: <ShipperOrderAllocation />,
    shipping: <OrderShipping />,
    completed: <OrderCompleted />,
    canceled: <OrderCanceled />,
  } as const;

  return <OrderManagementLayout render={render} />;
};

export default ShipperOrderManagement;
