import { Theme, css } from "@emotion/react";

import { mixin_detail_button } from "@/styles";

export const clickCardHeader = (isNeedDriverEdit?: boolean) => css`
  column-gap: 4px;
  position: relative;
  justify-content: start;

  & h3 {
    max-width: ${isNeedDriverEdit ? "270px" : "350px"};
  }
`;

export const clickCardBodyDetail = (theme: Theme) => css`
  position: relative;
  column-gap: 8px;
  & > span {
    margin-left: 4px;
  }
  & > svg > path {
    fill: ${theme.color.gray_40};
  }
`;

export const editDriverDialogBtn = (theme: Theme) => css`
  & > button {
    ${mixin_detail_button};
    height: 20px;
    border-radius: 2px;
    color: ${theme.color.gray_60};
  }

  > dialog > div {
    border: 0;
    right: 0;
  }
`;

export const editTruckDialogBtn = (theme: Theme) => css`
  position: static;

  & > button {
    ${mixin_detail_button};
    height: 20px;
    border-radius: 2px;
    color: ${theme.color.gray_60};
  }

  > dialog > div {
    right: 0;
    border: 0;
  }
`;

export const phoneIcon = (theme: Theme) => css`
  fill: ${theme.color.gray_40};
`;
