import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.li<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    border-bottom: 1px solid ${theme.color.gray_30};
    background-color: ${isSelected ? theme.color.gray_00 : "transparent"};
    cursor: pointer;

    :hover {
      background-color: ${theme.color.gray_00};
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 20px;

    & > div {
      display: flex;
      column-gap: 2px;
      align-items: center;
    }
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.medium_15};
  `}
`;

export const OrderIdx = styled.span`
  ${({ theme }) => css`
    ${theme.font.semibold_14};
    margin: 0 4px 0 2px;
    color: ${theme.color.gray_60};
  `}
`;

export const TruckCount = styled.span<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    ${theme.font.semibold_14};
    color: ${isSelected ? theme.color.primary_40 : theme.color.gray_50};
  `}
`;

export const CheckBtn = styled.button<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    display: flex;
    column-gap: 8px;
    align-items: center;

    & > svg > g > path {
      fill: ${isSelected ? theme.color.primary_40 : theme.color.gray_40};
    }
  `}
`;

export const Body = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const orderInfo = css`
  & > div > div:first-of-type > span {
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
  }
`;

export const orderInfoRow = css`
  ${orderInfo};

  border-bottom: 0;
`;
