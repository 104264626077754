import React from "react";

import * as S from "./Foldable1DepthLayout.styled";

interface Foldable1DepthLayoutProps {
  children: React.ReactNode;
  heading: string;
}

const Foldable1DepthLayout = ({
  children,
  heading,
}: Foldable1DepthLayoutProps) => {
  return (
    <S.Root>
      <h2 className="a11y">{heading}</h2>
      {children}
    </S.Root>
  );
};

export default Foldable1DepthLayout;
