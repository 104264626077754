import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface ExternalLinkProps {
  variant: "ghostPrimary" | "ghostGray" | "ghostNoUnderlineGray";
}

export const ExternalLink = styled.a<ExternalLinkProps>`
  ${({ theme, variant }) => css`
    ${variant === "ghostPrimary" && theme.button.ghostPrimary};
    ${variant === "ghostGray" && theme.button.ghostGray};
    ${variant === "ghostNoUnderlineGray" && theme.button.ghostNoUnderlineGray};
  `}
`;
