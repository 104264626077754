import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface SelectCompanyModalBtnProps {
  hasErr: boolean;
  hasCompany: boolean;
}

export const SelectCompanyModalBtn = styled.button<SelectCompanyModalBtnProps>`
  ${({ theme, hasErr, hasCompany }) => css`
    ${theme.button.outlineMedium};
    ${theme.font.regular_14};
    display: flex;
    justify-content: space-between;
    height: 40px;
    border: 1px solid ${hasErr ? theme.color.red_20 : theme.color.gray_35};
    padding: 10px 12px;
    color: ${hasCompany ? theme.color.gray_50 : theme.color.black};
    user-select: none;

    & > svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme.color.black};
      }
    }

    :hover {
      cursor: pointer;
    }

    :disabled {
      border: 1px solid ${theme.color.gray_35};
      background-color: ${theme.color.gray_10};
      opacity: 1;
    }
  `}
`;

export const SelectedCompanyInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const SelectedCompanyCnt = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 0 6px;
    color: ${theme.color.gray_70};
  `}
`;
