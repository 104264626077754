import { useSetRecoilState } from "recoil";
import { useQuery } from "@tanstack/react-query";

import type { LinkPlayerQueryModel } from "@/types";

import { truckTypeOptionsState } from "stores";
import { getLinkPlayerAPI, getStatistics, getTruckTypeOptionAPI } from "apis";

const commonKeys = {
  all: ["common"] as const,
  linkPlayer: (req: LinkPlayerQueryModel) => [{ req }] as const,
};

export const useGetLinkPlayer = (
  req: LinkPlayerQueryModel,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: commonKeys.linkPlayer(req),
    queryFn: () => getLinkPlayerAPI(req),
    enabled,
  });
};

export const useGetTruckTypeOption = (enabled: boolean) => {
  const setTruckTypeOptions = useSetRecoilState(truckTypeOptionsState);

  return useQuery({
    queryKey: ["trucks"],
    queryFn: () => getTruckTypeOptionAPI(),
    enabled,
    onSuccess: (res) => setTruckTypeOptions(res),
  });
};

export const useGetStatistics = () =>
  useQuery({
    queryKey: ["report"],
    queryFn: () => getStatistics(),
    suspense: true,
  });
