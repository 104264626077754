/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요

import React from "react";

import { Input } from "@/components";
import { DeleteIcon20, SearchIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./SearchInput.styled";

interface SearchInputProps {
  className?: string;
  placeholder: Languages;
  value: string;
  maxLength?: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (search: string) => void;
  handleReset: () => void;
}

const SearchInput = ({
  className,
  placeholder,
  value,
  maxLength = 100,
  handleChange,
  handleSearch,
  handleReset,
}: SearchInputProps) => {
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSearch(value);
  };

  return (
    <S.Form className={className} onSubmit={handleSubmit}>
      <S.SearchBtn type="submit">
        <SearchIcon />
      </S.SearchBtn>
      <Input
        css={S.input}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        handleChangeValue={handleChange}
      />
      {value && (
        <S.CloseBtn type="button" onClick={handleReset}>
          <DeleteIcon20 />
        </S.CloseBtn>
      )}
    </S.Form>
  );
};

export default SearchInput;
