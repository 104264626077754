import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const AddressWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const AddressContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;
