import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";
import { filterOrderNum } from "@/utils";

import { useGetOrderManagementCanceled } from "services";
import type {
  GetOrderManagementCanceledQueryModel,
  GetOrderManagementCompletedServerModel,
} from "types";
import CanceledSection from "./section/CanceledSection";
import OrderCanceledFilter from "./filter/OrderCanceledFilter";
import * as S from "./OrderCanceled.styled";

const OrderCanceled = () => {
  const { filters } = useFilterParams<
    GetOrderManagementCanceledQueryModel["query"]
  >(["page", "orderNumber"]);

  const query = {
    ...filters,
    ...(filters.orderNumber && {
      orderNumber: filterOrderNum(filters.orderNumber),
    }),
  };

  const { data, dataUpdatedAt, refetch } = useGetOrderManagementCanceled({
    query,
  });

  return (
    <>
      <OrderCanceledFilter />
      {data && (
        <>
          <CanceledSection
            data={data}
            dataUpdatedAt={dataUpdatedAt}
            refetch={refetch}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default OrderCanceled;
