import React from "react";

import { ConfirmModal } from "@/components";
import { useToast } from "@/hooks";

import { useDeleteMember } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

interface DeleteMemberModalProps {
  staffId: string;
  handleModalClose: () => void;
}

const DeleteMemberModal = React.forwardRef(
  (
    { staffId, handleModalClose }: DeleteMemberModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { mutate: deleteMemberMutate } = useDeleteMember();

    const { addToast } = useToast();

    const handleDeleteMember = () => {
      deleteMemberMutate(staffId, {
        onSuccess: () => {
          handleModalClose();
          addToast(CARRIER_TOAST_MSG.SUCCESS.MEMBER_DELETE_DONE);
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Delete member"
        desc="Are you sure you want to delete the member?"
        closeBtnLabel="Cancel"
        negBtnLabel="Delete"
        callbackFn={handleDeleteMember}
      />
    );
  },
);

DeleteMemberModal.displayName = "DeleteMemberModal";

export default DeleteMemberModal;
