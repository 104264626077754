import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  deleteTruckAPI,
  getLinkedDriverAPI,
  getTrucksAPI,
  getTruckAllocationsAPI,
  getTruckDetailAPI,
  getTruckGaragesAPI,
  createTruckAPI,
  updateTruckDefaultInfoAPI,
  updateTruckSpecificationAPI,
  connectLinkAPI,
  getGpsTrucksAPI,
} from "apis";
import type {
  CreateDriverLinkQueryModel,
  CreateTruckQueryModel,
  GetDriverSearchQueryModel,
  GetDriverSearchServerModel,
  GetTruckAllocationQueryModel,
  GetTruckDetailClientModel,
  GetTruckDetailServerModel,
  GetTruckQueryModel,
  UpdateTruckDefaultInfoQueryModel,
  UpdateTruckSpecificationInfoQueryModel,
} from "types";

const truckKeys = {
  all: ["truck"] as const,
  lists: () => [...truckKeys.all, "list"] as const,
  list: (filters: GetTruckQueryModel) =>
    [...truckKeys.lists(), { filters }] as const,
  details: () => [...truckKeys.all, "detail"] as const,
  detail: (id: string) => [...truckKeys.details(), id] as const,
  linkDrivers: () => [...truckKeys.all, "linkDriver"] as const,
  linkDriver: (filters: GetDriverSearchQueryModel) =>
    [...truckKeys.linkDrivers(), { filters }] as const,
  allocations: () => [...truckKeys.all, "allocation"] as const,
  allocation: (filters: GetTruckAllocationQueryModel) =>
    [...truckKeys.allocations(), { filters }] as const,
  gpsTrucks: () => [...truckKeys.all, "gpsTruck"] as const,
  gpsTruck: () => [...truckKeys.gpsTrucks()] as const,
};

export const useGetTruckGarage = () => {
  return useQuery({
    queryKey: ["truckGarage"],
    queryFn: () => getTruckGaragesAPI(),
  });
};

export const useGetPropertyTrucks = (filters: GetTruckQueryModel) => {
  return useQuery({
    queryKey: truckKeys.list(filters),
    queryFn: () => getTrucksAPI(filters),
  });
};

export const useCreatePropertyTruck = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateTruckQueryModel
  >({
    mutationFn: (req) => createTruckAPI(req),
  });
};

export const useGetPropertyTruckDetail = (
  truckId: string,
  options?: UseQueryOptions<
    GetTruckDetailServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    GetTruckDetailClientModel,
    ReturnType<typeof truckKeys.detail>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: truckKeys.detail(truckId),
    queryFn: () => getTruckDetailAPI(truckId),
    select: (res) => ({
      truckId: res.truckId,
      defaultInfo: {
        plateNumber: res.plateNumber,
        category: res.category,
        created: res.created,
        parkedGarage: res.parkedGarage,
        linked: res.linked,
        registration: res.registrationS3Key,
        truckPhoto: res.truckPhotoS3Key,
        memo: res.memo,
        gpsAvailable: res.gpsAvailable,
      },
      specification: {
        truckTypeId: res.truckTypeId,
        truckOptionId: res.truckOptionId,
        width: res.width,
        length: res.length,
        height: res.height,
        brand: res.brand,
        model: res.model,
        year: res.year,
        vin: res.vin,
      },
      drivingRecords: {
        totalShipmentTime: res.totalShipmentTime,
        totalShipmentDistance: res.totalShipmentDistance,
        lastShippingOn: res.lastShippingOn,
        recentAllocation: res.recentAllocation,
        progressed: res.progressed,
      },
    }),
  });
};

export const useDeletePropertyTruck = () => {
  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (truckId) => deleteTruckAPI(truckId),
  });
};

export const useGetPropertyLinkDriver = (
  filters: GetDriverSearchQueryModel,
  options?: UseQueryOptions<
    GetDriverSearchServerModel,
    AxiosError,
    GetDriverSearchServerModel,
    ReturnType<typeof truckKeys.linkDriver>
  >,
): UseQueryResult<GetDriverSearchServerModel> => {
  return useQuery({
    ...options,
    queryKey: truckKeys.linkDriver(filters),
    queryFn: () => getLinkedDriverAPI(filters),
  });
};

// NOTE: 링크 관리의 링크 연결 API와 같이 사용
export const useCreateDriverLink = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateDriverLinkQueryModel
  >({
    mutationFn: (req) => connectLinkAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(truckKeys.details());
    },
  });
};

export const useUpdateTruckDefaultInfo = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateTruckDefaultInfoQueryModel
  >({
    mutationFn: (req) => updateTruckDefaultInfoAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(truckKeys.details());
    },
  });
};

export const useUpdateTruckSpecificationInfo = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateTruckSpecificationInfoQueryModel
  >({
    mutationFn: (req) => updateTruckSpecificationAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(truckKeys.details());
    },
  });
};

export const useGetTruckAllocations = (
  filters: GetTruckAllocationQueryModel,
) => {
  return useQuery({
    queryKey: truckKeys.allocation(filters),
    queryFn: () => getTruckAllocationsAPI(filters),
  });
};

export const useGetGpsTrucks = (enabled: boolean) => {
  return useQuery({
    queryKey: truckKeys.gpsTruck(),
    queryFn: () => getGpsTrucksAPI(),
    enabled,
  });
};
