import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetPropertyTrucks } from "services";
import type { GetTruckQueryModel } from "types";
import TruckSection from "./section/TruckSection";
import PropertyTruckFilter from "./filter/PropertyTruckFilter";
import * as S from "./PropertyTruck.styled";

const PropertyTruck = () => {
  const { filters } = useFilterParams<GetTruckQueryModel["query"]>([
    "page",
    "status",
    "linked",
    "pageSize",
    "plateNumber",
    "truckTypeId",
    "truckOptionId",
  ]);

  const { data, dataUpdatedAt, refetch } = useGetPropertyTrucks({
    query: { ...filters, status: filters.status ?? "ALL" },
    //NOTE: status가 필수값으로 api가 설정되어 있어 데이터가 없는 경우 임의로 "ALL" 보내주도록 작성 -> 확인요청 상태
  });

  return (
    <>
      <PropertyTruckFilter />
      <TruckSection
        data={data}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
    </>
  );
};

export default PropertyTruck;
