import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AddrAutoComplete,
  Button,
  Card,
  ErrMsg,
  Input,
  LabelContent,
  PageBackHeader,
} from "@/components";
import { comma } from "@/utils";
import type { Languages } from "@/types";

import { CARRIER_VALID_MSG } from "constants/index";
import useCreateGarage from "./hooks/useCreateGarage";
import * as S from "./GarageCreate.styled";

const GarageCreate = () => {
  const navigate = useNavigate();

  const {
    isCreateGarageLoading,
    register,
    watch,
    errors,
    handleSelectAddr,
    handleSubmitGarage,
  } = useCreateGarage();

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <S.Root>
      <form>
        <h3 className="a11y">Add the garage</h3>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Add the garage"
          path="/property?tab=garage"
        />
        <Card css={S.card}>
          <Card.Content heading="Default information">
            <LabelContent label="Garage name" isRequired direction="vertical">
              <Input
                maxLength={50}
                placeholder="Garage name"
                hasError={!!errors.garageName?.message}
                value={watch("garageName")}
                register={register("garageName", {
                  required: CARRIER_VALID_MSG.REQUIRED,
                  maxLength: 50,
                })}
              />
              {errors.garageName?.message && (
                <ErrMsg>{errors.garageName.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent
              label="Garage address"
              isRequired
              direction="vertical"
            >
              <AddrAutoComplete
                err={errors.garageAddr?.address?.message}
                type="garage"
                value={watch("garageAddr")}
                placeholder="Please enter your address or choose from map"
                handleSelect={handleSelectAddr}
              />
              <Input
                maxLength={100}
                placeholder="Detail address (Optional)"
                value={watch("garageAddr.addressDetail")}
                register={register("garageAddr.addressDetail")}
              />
              {errors.garageAddr?.address?.message && (
                <ErrMsg>
                  {errors.garageAddr.address.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
            <LabelContent label="Capacity" isRequired direction="vertical">
              <Input
                placeholder="Only numbers"
                hasError={!!errors.capacity?.message}
                value={watch("capacity")}
                maxLength={3}
                register={register("capacity", {
                  required: CARRIER_VALID_MSG.REQUIRED,
                  setValueAs: (value) => comma(value),
                  maxLength: 3,
                })}
              />
              {errors.capacity?.message && (
                <ErrMsg>{errors.capacity.message as Languages}</ErrMsg>
              )}
            </LabelContent>
          </Card.Content>
        </Card>
        <S.BtnWrapper>
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleClickCancel}
          />
          <Button
            isLoading={isCreateGarageLoading}
            isDisabled={Object.keys(errors).length > 0}
            variant="primaryMedium"
            label={"Add"}
            handleClickBtn={handleSubmitGarage}
          />
        </S.BtnWrapper>
      </form>
    </S.Root>
  );
};

export default GarageCreate;
