/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useEffect } from "react";
import { isEmpty } from "lodash-es";

import {
  CheckboxBtn,
  CheckTable,
  DetailModal,
  NoResult,
  SearchInput,
} from "@/components";
import {
  useCheckTableWithCondition,
  useModal,
  useSearchInputWithoutQuery,
} from "@/hooks";
import { filterTableColumns, formatPhoneNum } from "@/utils";

import type {
  CompanyInfo,
  Languages,
  ModalPosLabel,
  SelectCompanyInfo,
  TableColumns,
} from "@/types";

import * as S from "./TruckingCompanySearchModal.styled";

interface TruckingCompanySearchModalProps {
  className?: string;
  columns: TableColumns<keyof SelectCompanyInfo>;
  selectedCompany: CompanyInfo[];
  tableDatas: SelectCompanyInfo[];
  isLoading?: boolean;
  handleCompanySelect: (companyInfo: CompanyInfo[]) => void;
}

const TruckingCompanySearchModal = React.forwardRef(
  (
    {
      className,
      columns,
      selectedCompany,
      tableDatas,
      isLoading,
      handleCompanySelect,
    }: TruckingCompanySearchModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      searchValue,
      filteredDatas,
      handleSearch,
      handleChangeInput,
      handleResetInput,
    } = useSearchInputWithoutQuery<SelectCompanyInfo>(tableDatas, "name");

    const { handleModalClose } = useModal();

    const filteredColumns = filterTableColumns(columns);

    const {
      isChecked,
      isAllChecked,
      checkedIds,
      setCheckedIds,
      handleCheck,
      handleAllCheck,
    } = useCheckTableWithCondition(
      tableDatas,
      "id",
      ({ checked }) => checked !== 1,
    );

    const handleCloseSelectCompany = () => {
      handleModalClose();
      if (!checkedIds) return;
      handleCompanySelect(
        tableDatas
          .filter((item) => checkedIds.includes(item.id))
          .map((item) => ({ id: item.id, name: item.name })),
      );
    };

    const formatTableData = (
      data: SelectCompanyInfo,
      key: keyof SelectCompanyInfo,
    ) => {
      switch (key) {
        case "name":
          return <div>{data[key]}</div>;

        case "phone":
          return formatPhoneNum(data[key]);

        default:
          return data[key];
      }
    };

    useEffect(() => {
      setCheckedIds(selectedCompany.map((item) => item.id));
    }, []);

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        className={className}
        type="submit"
        title="Select company"
        posBtnLabel={"Select" as ModalPosLabel & Languages}
        closeBtnLabel="Close"
        isLoading={isLoading}
        isDisabled={isEmpty(checkedIds)}
        callbackFn={handleCloseSelectCompany}
      >
        <SearchInput
          css={S.searchInput}
          placeholder="Search by company name"
          value={searchValue}
          handleChange={handleChangeInput}
          handleSearch={handleSearch}
          handleReset={handleResetInput}
        />
        <CheckTable
          css={S.checkTable}
          isAllChecked={isAllChecked}
          columnList={columns}
          handleAllCheck={handleAllCheck}
        >
          {filteredDatas.length ? (
            filteredDatas.map((item) => (
              <CheckTable.SelectRow
                css={S.selectRow(isChecked(item.id), item.checked === 1)}
                id={item.id}
                key={item.id}
                isDisabled={item.checked === 1}
                selectFn={handleCheck(item.id)}
              >
                <CheckTable.CheckTd>
                  <CheckboxBtn
                    disabled={item.checked === 1}
                    isChecked={isChecked(item.id)}
                    shape="rectangle"
                    handleCheck={handleCheck(item.id)}
                  />
                </CheckTable.CheckTd>
                {filteredColumns.map(({ key }) => (
                  <CheckTable.Td key={key}>
                    {formatTableData(item, key)}
                  </CheckTable.Td>
                ))}
              </CheckTable.SelectRow>
            ))
          ) : (
            <NoResult />
          )}
        </CheckTable>
      </DetailModal>
    );
  },
);

TruckingCompanySearchModal.displayName = "TruckingCompanySearchModal";
export default TruckingCompanySearchModal;
