import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useQueryClient } from "@tanstack/react-query";

import { Auth } from "@/utils";
import type { LoginServerModel } from "@/types";

export const auth = Auth.getInstance();

export const AuthContext = createContext<
  | {
      auth: Auth;
      initializing: boolean;
      user: LoginServerModel | null;
      error: { message: string } | null;
    }
  | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<LoginServerModel | null>(null);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [initializing, setInitializing] = useState(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    auth
      .resolveUser()
      .onAuthStateChanged((user: LoginServerModel | null, error) => {
        if (user) {
          setUser(user);
          setError(null);
        } else {
          setUser(null);

          // NOTE: 모든 쿼리 캐시를 지우지만 특정 쿼리는 유지
          queryClient
            .getQueryCache()
            .getAll()
            .forEach((query) => {
              if (
                typeof query.queryKey === "string" &&
                query.queryKey !== "report"
              ) {
                queryClient.removeQueries(query.queryKey);
              }
            });

          if (error) {
            setError(error);
          }
        }
        setInitializing(false);
      });
  }, []);

  const value = useMemo(
    () => ({
      user,
      error,
      auth,
      initializing,
    }),
    [user, error, auth, initializing],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return auth;
}
