import React from "react";
import { Link } from "react-router-dom";

import * as S from "./ClickCard.styled";

interface ClickCardProps {
  children: React.ReactNode;
  isClickAble?: boolean;
  isSelected?: boolean;
  path?: string;
  className?: string;
}

const ClickCard = ({
  children,
  className,
  isClickAble = false,
  isSelected = false,
  path = "",
}: ClickCardProps) => {
  return (
    <S.Root
      className={className}
      isClickAble={isClickAble}
      isSelected={isSelected}
    >
      {isClickAble ? (
        <Link to={isClickAble ? path : ""}>{children}</Link>
      ) : (
        <>{children}</>
      )}
    </S.Root>
  );
};

ClickCard.Header = function HeaderItem({ children, ...rest }: any) {
  return <S.Header {...rest}>{children}</S.Header>;
};

ClickCard.Body = function BodyItem({ children, ...rest }: any) {
  return <S.Ul {...rest}>{children}</S.Ul>;
};

ClickCard.BodyDetail = function BodyDetailItem({ children, ...rest }: any) {
  return <S.Li {...rest}>{children}</S.Li>;
};

export default ClickCard;
