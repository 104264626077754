import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { Profile, Table } from "@/components";
import { clientLanguage } from "@/stores";
import { filterTableColumns } from "@/utils";
import { LinkedIcon, UnLinkedIcon } from "@/assets";
import type { Languages } from "@/types";

import { LINK_MANAGE_TABLE_COLUMNS } from "assets";
import type { LinkManageTruckServerItem, TableKeyUnion } from "types";
import { useLinkDriver } from "../hooks";
import * as S from "./LinkManageTruckTable.styled";

interface LinkManageTruckTableProps {
  data?: LinkManageTruckServerItem[];
  isLoading: boolean;
  selectedTruckId: string | null;
  handleSelectTruckId: (id: string) => void;
}

const LinkManageTruckTable = ({
  data,
  isLoading,
  selectedTruckId,
  handleSelectTruckId,
}: LinkManageTruckTableProps) => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const { handleDisconnectLinkDriver } = useLinkDriver();

  const filteredColumns = filterTableColumns(LINK_MANAGE_TABLE_COLUMNS);

  const getLanguageKey = <T extends string>(key: T): T => {
    if (language === "en") {
      return `${key}En` as T;
    }

    if (language === "vi") {
      return `${key}Vi` as T;
    }

    return key;
  };

  const columnValue = (
    key: TableKeyUnion<(typeof LINK_MANAGE_TABLE_COLUMNS)[number]>,
    data: LinkManageTruckServerItem,
  ) => {
    const hasLinkedDriver = data.drivers.length > 0;

    switch (key) {
      case "utils":
        return (
          <S.LinkBtn
            type="button"
            aria-label={
              hasLinkedDriver ? "link disconnecting" : "link connecting"
            }
            disabled={!hasLinkedDriver}
            onClick={handleDisconnectLinkDriver(data.truckId)}
          >
            {hasLinkedDriver ? <LinkedIcon /> : <UnLinkedIcon />}
          </S.LinkBtn>
        );

      case "drivers":
        return (
          <S.Linked>
            {hasLinkedDriver ? (
              <S.Driver>
                <Profile src={data.drivers[0].profile} />
                <span>{data.drivers[0].driverName}</span>
              </S.Driver>
            ) : (
              "-"
            )}
            <S.OtherDriverNum>{`(${data.drivers.length})`}</S.OtherDriverNum>
          </S.Linked>
        );

      case "truckType":
      case "truckOption": {
        const languageKey = getLanguageKey(key);

        return (
          <span>
            {data[languageKey] ? t(data[languageKey] as Languages) : "-"}
          </span>
        );
      }

      default:
        return <span>{data[key]}</span>;
    }
  };

  return (
    <Table
      css={S.table}
      title="link management truck list"
      isLoading={isLoading}
      hasRowBtn
      handleSelectRow={handleSelectTruckId}
      rowCount={data?.length ?? 0}
    >
      <Table.Head columnList={LINK_MANAGE_TABLE_COLUMNS} />
      <Table.Body css={S.tBody}>
        {data?.length ? (
          data.map((rowData) => (
            <Table.ClickRow
              key={rowData.truckId}
              id={rowData.truckId}
              aria-selected={selectedTruckId === rowData.truckId}
            >
              {filteredColumns.map(({ key }) => (
                <Table.Cell
                  css={S.cell(selectedTruckId === rowData.truckId)}
                  key={key}
                >
                  <span>{columnValue(key, rowData)}</span>
                </Table.Cell>
              ))}
            </Table.ClickRow>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default LinkManageTruckTable;
