import { calcTableWidth } from "@/utils";
import { CELL, PAYMENT_METHOD_RADIOS, RADIO, TAB, TAX_RADIOS } from "@/assets";
import type { Languages, RadioType } from "@/types";

import type { CompanyType } from "types";

export const QUOTATION_ONGOING_TABLE_COLUMNS = (clientType: CompanyType) =>
  [
    [CELL.STATUS],
    [CELL.ORDER_NUMBER],
    clientType === "shipper" || clientType === "carrier"
      ? [CELL.COMPANY_COMPANYNAME]
      : [CELL.COMPANY_SHIPPERNAME],
    [CELL.PICKUP_PUADDR, [CELL.ADDRESS_PUADDR, CELL.ETA_PUETA]],
    [CELL.DROPOFF_DOADDR, [CELL.ADDRESS_DOADDR, CELL.ETA_DOETA]],
    [CELL.LF_LOADINGSTATUS],
  ] as const;

export const FORWARDER_FORWARDERCARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES =
  calcTableWidth([
    "174px",
    "150px",
    "210px",
    ["220px", "500px"],
    "128px",
    ["220px", "500px"],
    "128px",
    "56px",
  ]);

export const SHIPPER_QUOTATION_ONGOING_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  "150px",
  "247px",
  ["224px", "488px"],
  "128px",
  ["224px", "488px"],
  "128px",
  "56px",
]);

export const CARRIER_QUOTATION_ONGOING_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  "150px",
  "247px",
  ["224px", "488px"],
  "128px",
  ["224px", "488px"],
  "128px",
  "56px",
]);

export const CARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.REQUESTED,
  RADIO.ANSWERED,
];

export const SHIPPER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.WAITING,
  RADIO.ANSWERED,
];

export const FORWARDER_FORWARDERCARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS: RadioType[] =
  [RADIO.ALL, RADIO.FORWARDING, RADIO.DELIVERED];

export const FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_CELL_SIZES =
  calcTableWidth([
    "40px",
    "150px",
    "305px",
    ["240px", "500px"],
    "128px",
    ["240px", "500px"],
    "128px",
    "56px",
  ]);

export const FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_SHIPPERNAME],
  [CELL.PICKUP_PUADDR, [CELL.ADDRESS_PUADDR, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDR, [CELL.ADDRESS_DOADDR, CELL.ETA_DOETA]],
  [CELL.LF_LOADINGSTATUS],
] as const;

export const FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPLETED],
  [CELL.COMPANY_SHIPPERNAME],
  [CELL.PICKUP_PUADDR, [CELL.ADDRESS_PUADDR, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDR, [CELL.ADDRESS_DOADDR, CELL.ETA_DOETA]],
  [CELL.LF_LOADINGSTATUS],
  [CELL.TRUCKINGCOMPANY_CARRIERNAME],
] as const;

export const SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS = [
  [CELL.ORDER_NUMBER],
  [CELL.COMPLETED],
  [CELL.COMPANY_COMPANYNAME],
  [CELL.PICKUP_PUADDR, [CELL.ADDRESS_PUADDR, CELL.ETA_PUETA]],
  [CELL.DROPOFF_DOADDR, [CELL.ADDRESS_DOADDR, CELL.ETA_DOETA]],
  [CELL.LF_LOADINGSTATUS],
] as const;

export const SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES =
  calcTableWidth([
    "150px",
    "130px",
    "247px",
    ["224px", "488px"],
    "128px",
    ["224px", "488px"],
    "128px",
    "56px",
  ]);

export const FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES =
  calcTableWidth([
    "150px",
    "128px",
    "162px",
    ["170px", "360px"],
    "128px",
    ["170px", "360px"],
    "128px",
    "56px",
    "194px",
  ]);

export const QUOTATION_CANCELED_TABLE_COLUMNS = (clientType: CompanyType) =>
  //NOTE:함수이지만 정해진 clientType에 따라 정해진 cell을 도출하는 코드라 statics 파일에 넣어둠
  [
    [CELL.ORDER_NUMBER],
    clientType === "shipper" || clientType === "carrier"
      ? [CELL.COMPANY_COMPANYNAME]
      : [CELL.COMPANY_SHIPPERNAME],
    [CELL.PICKUP_PUADDR, [CELL.ADDRESS_PUADDR, CELL.ETA_PUETA]],
    [CELL.DROPOFF_DOADDR, [CELL.ADDRESS_DOADDR, CELL.ETA_DOETA]],
    [CELL.LF_LOADINGSTATUS],
  ] as const;

export const SHIPPER_FORWARDERCARRIER_CARRIER_QUOTATION_CANCELED_TABLE_CELL_SIZES =
  calcTableWidth([
    "150px",
    "326px",
    ["250px", "488px"],
    "128px",
    ["250px", "488px"],
    "128px",
    "56px",
  ]);

export const FORWARDER_QUOTATION_CANCELED_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  "346px",
  ["240px", "500px"],
  "128px",
  ["240px", "500px"],
  "128px",
  "56px",
]);

export const SHIPPER_CARRIER_QUOTATION_TABS = [
  TAB.ONGOING,
  TAB.COMPLETED,
  TAB.CANCELED,
] as const;

export const FORWARDER_FORWARDERCARRIER_QUOTATION_TABS = [
  TAB.PENDING,
  TAB.ONGOING,
  TAB.COMPLETED,
  TAB.CANCELED,
] as const;

export const QUOTATION_DETAIL_FORM = {
  truck: [
    {
      truckType: { key: "", label: "" as Languages },
      truckOption: { key: "", label: "" as Languages },
      truckNum: 1,
    },
  ],
  pay: "ACCOUNT_TRANSFER" as (typeof PAYMENT_METHOD_RADIOS)[number]["key"],
  subTotal: "",
  tax: "8" as (typeof TAX_RADIOS)[number]["key"],
  memo: "",
};

export const QUOTATION_SEND_FORM = {
  companyIds: [],
  isCheckSelfTransport: false,
  orders: [
    {
      subtotal: "",
      tax: "8",
      memo: "",
      orderId: "",
      orderNumber: "",
      pay: "Account transfer",
      truck: [
        {
          truckType: { key: "", label: "" as Languages },
          truckOption: { key: "", label: "" as Languages },
          truckNum: 1,
        },
      ],
    },
  ],
};

export const QUOTATION_SEND_TO_SHIPPER_TRUCKLIST_TABLE_CELL_SIZES =
  calcTableWidth(["221px", "236px", ["151px", "auto"]]);

export const SEND_TO_SHIPPER_QUOTATION_FORM = {
  subTotal: "",
  tax: "8" as (typeof TAX_RADIOS)[number]["key"],
  memo: "",
};

export const FORWARDERCARRIER_FORWARDER_TO_SHIPPER_QUOTATION_FORM = {
  truck: [
    {
      truckType: { key: "", label: "" as Languages },
      truckOption: { key: "", label: "" as Languages },
      truckNum: 1,
    },
  ],
  subtotal: "",
  tax: "8" as (typeof TAX_RADIOS)[number]["key"],
  memo: "",
};

export const QUOTATION_DETAIL_TABLE_CELL_SIZES = calcTableWidth([
  "96px",
  "314px",
  "155px",
  ["122px", "auto"],
]);

export const QUOTATION_DETAIL_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.TRUCKINGCOMPANY],
  [CELL.PRICE],
  [CELL.EMPTY],
] as const;
