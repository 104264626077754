import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

const mixinButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:disabled {
    opacity: 0.2;
  }

  & > svg {
    margin-right: 8px;
  }
`;

const buttonSize = {
  xSmall: css`
    ${font.semibold_12};
    height: 20px;
    padding: 0 2px;
  `,
  small: css`
    ${font.semibold_12};
    height: 24px;
    padding: 0 12px;
  `,
  medium: css`
    ${font.semibold_14};
    height: 36px;
    padding: 0 16px;
  `,
  large: css`
    ${font.medium_15};
    height: 48px;
    padding: 0 20px;
  `,
};

const buttonStyle = {
  /* NOTE: svg 사용 시 path외 다른 경로로 사용되는 경우가 있기때문에 확인 필요. 주로 svg path 경로라 해당 경로로 작성함 */
  primary: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.primary_40};

    &:not(:disabled):hover {
      background-color: ${color.primary_50};
    }

    & svg path {
      fill: ${color.white};
    }
  `,
  tertiary: css`
    ${mixinButton};
    color: ${color.primary_40};
    background-color: ${color.primary_10};

    &:not(:disabled):hover {
      background-color: ${color.primary_20};
    }

    & svg path {
      fill: ${color.primary_40};
    }
  `,
  secondary: css`
    ${mixinButton};
    border: 1px solid ${color.primary_40};
    color: ${color.primary_40};

    &:not(:disabled):hover {
      background-color: ${color.primary_10};
    }
  `,
  outlineXS: css`
    ${mixinButton};
    border: 1px solid ${color.gray_35};
    color: ${color.gray_60};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }

    & svg path {
      fill: ${color.gray_60};
    }
  `,
  outlineMedium: css`
    ${mixinButton};
    border: 1px solid ${color.gray_35};
    color: ${color.gray_80};
    background-color: ${color.white};

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }

    & svg path {
      fill: ${color.gray_80};
    }
  `,
  error: css`
    ${mixinButton};
    color: ${color.white};
    background-color: ${color.red_20};

    &:not(:disabled):hover {
      background-color: ${color.red_30};
    }
  `,
  ghostPrimary: css`
    ${mixinButton};
    color: ${color.primary_40};
    ${font.regular_14_underline};

    &:not(:disabled):hover {
      color: ${color.primary_50};
    }
  `,
  ghostGray: css`
    ${mixinButton};
    color: ${color.gray_70};
    ${font.regular_14_underline};

    &:not(:disabled):hover {
      color: ${color.gray_80};
    }

    & svg path {
      fill: ${color.gray_40};
    }
  `,
  ghostRed: css`
    ${mixinButton};
    color: ${color.red_20};
    ${font.regular_14_underline};

    &:not(:disabled):hover {
      color: ${color.red_30};
    }
  `,

  ghostNoUnderlineGray: css`
    ${mixinButton};
    ${font.regular_14};
    color: ${color.gray_70};

    &:not(:disabled):hover {
      color: ${color.gray_80};
    }

    & svg path {
      fill: ${color.gray_40};
    }
  `,

  ghostNoUnderlinePrimary: css`
    ${mixinButton};
    ${font.regular_14};
    color: ${color.primary_40};

    & svg path {
      fill: ${color.primary_40};
    }

    &:not(:disabled):hover {
      color: ${color.primary_50};

      & svg path {
        fill: ${color.primary_50};
      }
    }
  `,
  text: css`
    /* TODO: 디자인 시스템 내 컬러와 상이함. 확인 요청 필요 */
    ${font.medium_15};
    position: relative;
    padding: 0;
    color: ${color.gray_50};

    &:not(:disabled):hover {
      svg > path {
        fill: ${color.gray_70};
      }
      color: ${color.gray_70};

      &::before {
        background-color: ${color.gray_70};
      }
    }

    &:disabled {
      opacity: 0.6;
    }
  `,

  // TODO: 임의로 작성한 것임 추후 디자인 시스템 확인 후 수정 필요
  icon: css`
    ${mixinButton};

    & svg path {
      fill: ${color.gray_50};
    }

    &:not(:disabled):hover {
      background-color: ${color.gray_10};
    }
  `,
  textIcon: css`
    ${font.regular_14};
    display: flex;
    align-items: center;
    color: ${color.primary_40};

    svg {
      margin-right: 4px;
      path {
        fill: ${color.primary_40};
      }
    }

    &:not(:disabled):hover {
      svg > path {
        fill: ${color.primary_50};
      }
      color: ${color.primary_50};
    }
  `,
};

export const button = {
  primaryXSmall: css`
    ${buttonStyle.primary};
    ${buttonSize.xSmall};
  `,
  primarySmall: css`
    ${buttonStyle.primary};
    ${buttonSize.small};
  `,
  primaryMedium: css`
    ${buttonStyle.primary};
    ${buttonSize.medium};
  `,
  primaryLarge: css`
    ${buttonStyle.primary};
    ${buttonSize.large};
  `,
  secondaryLarge: css`
    ${buttonStyle.secondary};
    ${buttonSize.large};
  `,
  outlineXSmall: css`
    ${buttonStyle.outlineXS};
    ${buttonSize.xSmall};
  `,
  outlineMedium: css`
    ${buttonStyle.outlineMedium};
    ${buttonSize.medium};
  `,
  errorMedium: css`
    ${buttonSize.medium};
    ${buttonStyle.error};
  `,
  ghostPrimary: css`
    ${buttonStyle.ghostPrimary};
  `,
  ghostGray: css`
    ${buttonStyle.ghostGray};
  `,
  ghostRed: css`
    ${buttonStyle.ghostRed};
  `,
  ghostNoUnderlineGray: css`
    ${buttonStyle.ghostNoUnderlineGray};
  `,
  ghostNoUnderlinePrimary: css`
    ${buttonStyle.ghostNoUnderlinePrimary};
  `,
  icon: css`
    /* TODO: 아이콘 관련하여 primary / gray 로 들어가는 2가지 경우가 있어 추후 디자이너님께 정리 요청 필요(현 디자인 시스템에서는 Primary만 있음) */
    ${buttonStyle.icon};
  `,
  text: css`
    ${buttonStyle.text};
  `,
  textIcon: css`
    ${buttonStyle.textIcon};
  `,

  tertiaryIconLarge: css`
    ${buttonSize.large};
    ${buttonStyle.tertiary};
  `,
} as const;

export type BtnTheme = typeof button;
