import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { useCustomParams } from "@/hooks";
import type { CalendarModalSelectDate } from "@/types";

const useSelectEtaDate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectDate, setSelectDate] = useState<CalendarModalSelectDate>([
    null,
    null,
  ]);

  const { handleUpdateQuery } = useCustomParams();

  const handleChangeSelectDate = (
    selectDate: CalendarModalSelectDate,
  ): void => {
    if (!selectDate[0] || !selectDate[1]) return;

    setSelectDate(selectDate);
    setSearchParams(searchParams);

    handleUpdateQuery({
      date: `${selectDate[0].format("YYYY-MM-DD HH:mm")}~${selectDate[1].format(
        "YYYY-MM-DD HH:mm",
      )}`,
    });
  };

  const handleSearchCancel = (): void => {
    setSelectDate([null, null]);

    searchParams.delete("date");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const selectDateQuery = searchParams.get("date")?.trim().split("~");

    if (selectDateQuery === undefined) {
      setSelectDate([null, null]);
    }

    if (selectDateQuery && selectDateQuery.length === 2) {
      setSelectDate(
        [dayjs(selectDateQuery[0]), dayjs(selectDateQuery[1])] ?? [null, null],
      );
    }
  }, [searchParams.get("date")]);

  return { selectDate, handleChangeSelectDate, handleSearchCancel };
};

export default useSelectEtaDate;
