import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useModal, useToast } from "@/hooks";
import { deleteComma } from "@/utils";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";

import { usePatchSendQuotationReject, usePostSelectQuotation } from "services";
import { SEND_TO_SHIPPER_QUOTATION_FORM } from "assets";
import type { sendToShipperForm } from "types";

const FORM_SCHEMA = yup.object().shape({
  subTotal: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test(
      "maxTotal",
      COMMON_VALID_MSG.INVALID_PRICE,
      (value) => +deleteComma(value) < 2147483647,
    ),
  tax: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  memo: yup
    .string()
    .optional()
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_MEMO_150,
      (value) => (value?.length ?? 0) <= 150,
    ),
});

const useSendToShipperModal = (offerId: string) => {
  const { isLoading: isSelectLoading, mutate: selectQuotationMutate } =
    usePostSelectQuotation("forwarder");

  const { mutate: rejectQuotationSendMutate, isLoading: isRejectLoading } =
    usePatchSendQuotationReject();

  const { addToast } = useToast();
  const { handleModalClose } = useModal();

  const formMethod = useForm<sendToShipperForm>({
    defaultValues: SEND_TO_SHIPPER_QUOTATION_FORM,
    mode: "onTouched",
    resolver: yupResolver(FORM_SCHEMA),
  });

  const handleSend = formMethod.handleSubmit((data) => {
    selectQuotationMutate(
      {
        id: offerId,
        body: {
          price: +deleteComma(data.subTotal),
          vat: +data.tax,
          ...(data.memo && { remarks: data.memo.trim() }),
        },
      },
      {
        onSuccess: () => {
          addToast(COMMON_TOAST_MSG.SUCCESS.SEND_QUOTAIUON_DONE);
          handleModalClose();
        },
        onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      },
    );
  });

  const handleRejectQuotationSend = (offerId: string) => () => {
    rejectQuotationSendMutate(offerId, {
      onSuccess: () => {
        addToast(
          COMMON_TOAST_MSG.SUCCESS.CARRIER_REQUEST_QUOTATION_REJECT_DONE,
        );
        handleModalClose();
      },
      onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
    });
  };

  return {
    formMethod,
    isSelectLoading,
    isRejectLoading,
    handleSend,
    handleRejectQuotationSend,
  };
};

export default useSendToShipperModal;
