import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import { DataSection, StatusLabel } from "@/components";
import {
  calculateTotalAmount,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
  formatPrice,
} from "@/utils";

import { SETTLEMENT_TABLE_COLUMNS } from "assets";
import type { GetSettlementClientModel } from "types";
import * as S from "./SettlementSection.styled";

interface SettlementSectionProps {
  hasRequiredFilter: boolean;
  data?: GetSettlementClientModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const SettlementSection = ({
  hasRequiredFilter,
  data,
  dataUpdatedAt,
  refetch,
}: SettlementSectionProps) => {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const filteredColumns = filterTableColumns(SETTLEMENT_TABLE_COLUMNS);

  const formatTableData = (
    data: GetSettlementClientModel["orders"][number],
    key: keyof GetSettlementClientModel["orders"][number],
  ) => {
    const tab = searchParams.get("tab");

    switch (key) {
      case "status":
        if (
          data[key] === "ONGOING" &&
          (tab === "carrier" || auth.clientType === "shipper")
        ) {
          return <StatusLabel status={"REQUESTED"} />;
        }

        return <StatusLabel status={data[key]} />;

      case "orderNumber":
        return formatOrderNum(data[key]);

      case "confirmation":
        return data[key] ? t("Yes") : t("No");

      case "puEtd":
      case "doEtd": {
        const date = data[key]; // NOTE: TS 에러로 인하여 중간변수 사용
        return date ? formatICTDateTime(date) : "-";
      }

      case "puEta":
      case "puAta":
      case "puAtd":
      case "doEta":
      case "doAta":
      case "doAtd":
        return formatICTDateTime(data[key]);

      case "price":
        return formatPrice(
          +calculateTotalAmount(`${data[key]}`, `${data.vat}`),
        );

      default:
        return data[key];
    }
  };

  //  TODO: API 연동 시 작성 예정
  const handleDownload = () => {};

  const RenderTableContent = () => {
    if (!hasRequiredFilter) {
      return <DataSection.Table.NotSelectRequiredData />;
    }

    if (!data?.orders.length) {
      return <DataSection.Table.NoData />;
    }

    return (
      <>
        {data.orders.map((item) => (
          <DataSection.Table.SelectRowMovePage
            key={item.orderNumber}
            path={item.offerId}
          >
            {filteredColumns.map(({ key }) => (
              <DataSection.Table.Cell key={key}>
                {formatTableData(item, key as keyof typeof item)}
              </DataSection.Table.Cell>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </>
    );
  };

  return (
    <DataSection
      dataUpdatedAt={hasRequiredFilter ? dataUpdatedAt : null}
      totalData={data ? data.pageInfo.totalData : null}
      refetch={refetch}
      // NOTE: 이번 기능에는 반영되지 않아 주석처리
      // activeBtns={
      //   <DataSection.ExcelDownloadBtn
      //     isDisabled={!hasRequiredFilter || !data?.order.length}
      //     handleDownload={handleDownload}
      //   />
      // }
    >
      <DataSection.Table
        css={S.table}
        title="Settlement"
        rowCount={data?.orders.length!}
      >
        <DataSection.Table.Head columnList={SETTLEMENT_TABLE_COLUMNS} />
        <DataSection.Table.Body css={S.body}>
          <RenderTableContent />
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default SettlementSection;
