/**
 * NOTE: 노션 url: https://www.notion.so/coconutsilo/usePrompt-a30093065dcf4045bad99880e759817a?pvs=4
 */

import React, { useEffect } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { PromptModal } from "@/components";
import { isOpenPromptState } from "@/stores";

import useModal from "./useModal";

/**
 * @source https://reactrouter.com/en/main/hooks/use-blocker
 */

const usePrompt = (when: boolean) => {
  const navigate = useNavigate();

  const [isOpenPrompt, setIsOpenPrompt] = useRecoilState(isOpenPromptState);

  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isOpenPrompt &&
      when &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  const beforeUnloadHandler = (event: Event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  useEffect(() => {
    if (!isOpenPrompt || blocker.state !== "blocked") return;

    const handleLeave = () => {
      blocker.proceed();
      handleModalClose();
      navigate(blocker.location);
    };

    const handleNegBtn = () => {
      blocker.reset();
      handleModalClose();
    };

    handleModalOpen(
      <PromptModal
        ref={modalRef}
        callbackFn={handleLeave}
        handleNegBtn={handleNegBtn}
      />,
    )();
  }, [blocker.state]);

  useEffect(() => {
    setIsOpenPrompt(when);

    when
      ? window.addEventListener("beforeunload", beforeUnloadHandler)
      : window.removeEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      setIsOpenPrompt(false);
    };
  }, [when]);
};

export default usePrompt;
