import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Name = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
  `}
`;

export const PhoneNumWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4px;

    & > svg {
      fill: ${theme.color.gray_40};
    }
  `}
`;

export const phoneIcon = (theme: Theme) => css`
  width: 16px;
  height: 16px;

  > path {
    fill: ${theme.color.gray_50};
  }
`;

export const PhoneNum = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;
