import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContentTableRow = css`
  grid-template-columns: 64px 1fr;
  align-items: flex-start;
  column-gap: 16px;
  border-bottom: 0;
`;

export const CargoWrapper = styled.div`
  position: relative;
  display: flex;
  column-gap: 8px;
`;

export const CargoContents = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_80};
      white-space: pre-wrap;
    }
  `}
`;

export const moreCargoTooltip = (theme: Theme) => css`
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  & > button {
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 1px 5px;
    color: ${theme.color.gray_70};
    background-color: ${theme.color.white};

    :hover {
      background-color: ${theme.color.gray_10};
    }
  }

  /* NOTE: overflow 이슈로 인하여 고정 */
  > dl {
    top: calc(100% + 2px);
    left: inherit;
    right: 0;
  }
`;

export const AdditionalCargoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    > span {
      color: ${theme.color.white};
    }
  `}
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const AddressContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_80};
  `}
`;

export const AddressTimeLabel = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    margin-right: 8px;
    color: ${theme.color.gray_80};
  `}
`;
