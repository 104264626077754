import React from "react";
import { useTranslation } from "react-i18next";

import { Button, LabelContentTable, Loading } from "@/components";
import { findLookupTableLabel, formatPhoneNum, makeNameString } from "@/utils";
import { MEMBER_GRADE_OPTIONS } from "@/assets";

import { useGetAccountInfo } from "services";
import * as S from "./ReadField.styled";

interface ReadFieldProps {
  handleChangeUpdateField: () => void;
}

const ReadField = ({ handleChangeUpdateField }: ReadFieldProps) => {
  const { t } = useTranslation();

  const { data } = useGetAccountInfo();

  if (!data) {
    return <Loading />;
  }

  const grade = findLookupTableLabel(MEMBER_GRADE_OPTIONS, data.grade);

  return (
    <>
      <LabelContentTable variant="whiteBg">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Name">
            {makeNameString(data.lastName, data.middleName, data.firstName)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Grade">
            {grade ? t(grade) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Phone number">
            {formatPhoneNum(data.phone)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <Button
        css={S.editBtn}
        variant="outlineMedium"
        label="Edit"
        handleClickBtn={handleChangeUpdateField}
      />
    </>
  );
};

export default ReadField;
