import React from "react";
import Skeleton from "react-loading-skeleton";

import * as S from "./ReportLayer.styled";

const ReportLayerSkeleton = () => {
  return (
    <S.Root>
      <S.Wrapper>
        <S.Title>Báo cáo</S.Title>
        <S.ContentWrapper>
          <S.Card>
            <S.CardItem css={S.title}>Số lượng truy cập</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số người bán</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Số lượng người bán mới</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số sản phẩm</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Số sản phẩm đăng bán mới</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số đơn hàng</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số đơn hàng thành công</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>
              Tổng số đơn hàng không thành công
            </S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng giá trị giao dịch</S.CardItem>
            <S.CardItem css={S.desc}>
              <Skeleton width={100} />
            </S.CardItem>
          </S.Card>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Root>
  );
};

export default ReportLayerSkeleton;
