import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import { downloadFile, getFileAPI } from "@/utils";
import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError, FileRequestType } from "@/types";

import { getS3PresignedUrlAPI } from "apis";

// TODO: useQuery 로 변경 필요
export const useDownloadFile = () => {
  return useMutation<
    AxiosResponse,
    APIError<typeof COMMON_ERROR_CODE>,
    FileRequestType
  >({
    mutationFn: async (req) => {
      const url = await getS3PresignedUrlAPI(req.fileKey);

      return await getFileAPI(url);
    },
    onSuccess: (file: AxiosResponse, req) => {
      downloadFile(file, req.fileName);
    },
  });
};
