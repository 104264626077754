import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { beforePagePathState } from "@/stores";

const useRoot = () => {
  const { pathname, search } = useLocation();

  const setBeforePagePath = useSetRecoilState(beforePagePathState); // NOTE: 이전 페이지 주소

  useEffect(() => {
    return () => {
      setBeforePagePath(`${pathname}${search ?? ""}`);
    };
  }, [pathname, search]);
};

export default useRoot;
