import React from "react";

import { comma } from "@/utils";

import { useGetStatistics } from "services";
import * as S from "./ReportLayer.styled";

const ReportyLayer = () => {
  const { data } = useGetStatistics();

  if (!data) return null;

  return (
    <S.Root>
      <S.Wrapper>
        <S.Title>Báo cáo</S.Title>
        <S.ContentWrapper>
          <S.Card>
            <S.CardItem css={S.title}>Số lượng truy cập</S.CardItem>
            <S.CardItem css={S.desc}>{comma(data.soLuongTruyCap)}</S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số người bán</S.CardItem>
            <S.CardItem css={S.desc}>{comma(data.tongSoNguoiBan)}</S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Số lượng người bán mới</S.CardItem>
            <S.CardItem css={S.desc}>
              {comma(data.soLuongNguoiBanMoi)}
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số sản phẩm</S.CardItem>
            <S.CardItem css={S.desc}>{comma(data.tongSoSanPham)}</S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Số sản phẩm đăng bán mới</S.CardItem>
            <S.CardItem css={S.desc}>
              {comma(data.soSamPhamDangBanMoi)}
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số đơn hàng</S.CardItem>
            <S.CardItem css={S.desc}>{comma(data.tongSoDonHang)}</S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng số đơn hàng thành công</S.CardItem>
            <S.CardItem css={S.desc}>
              {comma(data.tongSoDonHangThanhCong)}
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>
              Tổng số đơn hàng không thành công
            </S.CardItem>
            <S.CardItem css={S.desc}>
              {comma(data.tongSoDonHangKhongThanhCong)}
            </S.CardItem>
          </S.Card>
          <S.Card>
            <S.CardItem css={S.title}>Tổng giá trị giao dịch</S.CardItem>
            <S.CardItem css={S.desc}>
              {comma(data.tongGiaTriGiaoDich)}
            </S.CardItem>
          </S.Card>
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Root>
  );
};

export default ReportyLayer;
