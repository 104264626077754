import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Dropdown, ErrMsg, Input, LabelContent } from "@/components";
import { onlyNumber } from "@/utils";
import { MEMBER_GRADE_OPTIONS, PHONE_MAX_LENGTH } from "@/assets";
import type { EditMemberForm, Languages } from "@/types";

import { selectDropdownForm } from "utils";
import * as S from "./EditMemberField.styled";

interface EditMemberFieldProps {
  className?: string;
}

const EditMemberField = ({ className }: EditMemberFieldProps) => {
  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { errors },
    register,
  } = useFormContext<EditMemberForm>();

  return (
    <S.Root className={className}>
      <S.FieldSet>
        <S.FieldTitle>{t("Default information")}</S.FieldTitle>
        <S.FieldContent>
          <S.FieldRow>
            <S.LabelContentWrapper>
              <LabelContent
                css={S.labelContent}
                label="Last name"
                direction="vertical"
                isRequired
              >
                <Input
                  placeholder="Last name"
                  value={watch("lastName")}
                  hasError={!!errors.lastName?.message}
                  register={register("lastName")}
                />
                {!!errors.lastName?.message && (
                  <ErrMsg css={S.errorMsg}>
                    {errors.lastName?.message as Languages}
                  </ErrMsg>
                )}
              </LabelContent>
              <LabelContent
                css={S.labelContent}
                label="Middle name"
                direction="vertical"
              >
                <Input
                  placeholder="(Optional)"
                  value={watch("middleName")}
                  register={register("middleName")}
                />
              </LabelContent>
              <LabelContent
                css={S.labelContent}
                label="First name"
                direction="vertical"
                isRequired
              >
                <Input
                  hasError={!!errors.firstName?.message}
                  placeholder="First name"
                  value={watch("firstName")}
                  register={register("firstName")}
                />
                {!!errors.firstName?.message && (
                  <ErrMsg css={S.errorMsg}>
                    {errors.firstName?.message as Languages}
                  </ErrMsg>
                )}
              </LabelContent>
            </S.LabelContentWrapper>
          </S.FieldRow>
          <S.FieldRow>
            <LabelContent
              css={S.labelContent}
              label="Phone number"
              direction="vertical"
              isRequired
            >
              <Input
                placeholder="Only numbers"
                value={watch("phone")}
                maxLength={PHONE_MAX_LENGTH}
                hasError={!!errors.phone?.message}
                register={register("phone", { setValueAs: onlyNumber })}
              />
              {!!errors.phone?.message && (
                <ErrMsg css={S.errorMsg}>
                  {errors.phone?.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
          </S.FieldRow>
          <S.FieldRow>
            <LabelContent
              css={S.labelContent}
              label="Grade"
              direction="vertical"
            >
              <Controller
                name="grade"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    css={S.dropdown}
                    options={MEMBER_GRADE_OPTIONS}
                    selectedOption={value}
                    handleSelect={selectDropdownForm(
                      MEMBER_GRADE_OPTIONS,
                      onChange,
                    )}
                  />
                )}
              />
            </LabelContent>
          </S.FieldRow>
        </S.FieldContent>
      </S.FieldSet>
    </S.Root>
  );
};

export default EditMemberField;
