import React from "react";

import { DetailModal, ErrMsg, LabelContent, PasswordInput } from "@/components";
import type { Languages } from "@/types";

import useChangePassword from "./hooks/useChangePassword";
import * as S from "./ChangePasswordModal.styled";

interface ChangePasswordModalProps {
  className?: string;
}

const ChangePasswordModal = React.forwardRef(
  (
    { className }: ChangePasswordModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const {
      isChangePasswordLoading,
      register,
      errors,
      watch,
      handleChangePassword,
    } = useChangePassword();

    return (
      <DetailModal
        css={S.modal}
        ref={ref}
        className={className}
        type="submit"
        isLoading={isChangePasswordLoading}
        isDisabled={Object.keys(errors).length > 0}
        title="Change password"
        closeBtnLabel="Cancel"
        posBtnLabel="Confirm"
        callbackFn={handleChangePassword}
      >
        <LabelContent
          css={S.labelContent}
          label="Current password"
          direction="vertical"
          isRequired
        >
          <PasswordInput
            id="currentPassword"
            placeholder="Current password"
            value={watch("currentPassword")}
            hasError={!!errors.currentPassword?.message}
            register={register("currentPassword")}
          />
          {!!errors.currentPassword?.message && (
            <ErrMsg css={S.errorMsg}>
              {errors.currentPassword?.message as Languages}
            </ErrMsg>
          )}
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="New password"
          direction="vertical"
          isRequired
        >
          <PasswordInput
            id="newPassword"
            placeholder="New password"
            value={watch("newPassword")}
            hasError={!!errors.newPassword?.message}
            register={register("newPassword")}
          />
          {!!errors.newPassword?.message && (
            <ErrMsg css={S.errorMsg}>
              {errors.newPassword?.message as Languages}
            </ErrMsg>
          )}
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Confirm password"
          direction="vertical"
          isRequired
        >
          <PasswordInput
            id="confirmPassword"
            placeholder="Confirm password"
            value={watch("confirmPassword")}
            hasError={!!errors.confirmPassword?.message}
            register={register("confirmPassword")}
          />
          {!!errors.confirmPassword?.message && (
            <ErrMsg css={S.errorMsg}>
              {errors.confirmPassword?.message as Languages}
            </ErrMsg>
          )}
        </LabelContent>
      </DetailModal>
    );
  },
);

ChangePasswordModal.displayName = "ChangePasswordModal";

export default ChangePasswordModal;
