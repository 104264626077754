import React, { useState, useCallback, useEffect } from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetLinkManageTrucks } from "services";
import type { GetLinkManageTruckQueryModel } from "types";
import LinkManageTruckTable from "./table/LinkManageTruckTable";
import DriverList from "./driver/DriverList";
import * as S from "./PropertyLink.styled";
import PropertyManageFilter from "./filter/PropertyManageFilter";

const PropertyLink = () => {
  const [selectedTruckId, setSelectedTruckId] = useState<string | null>(null);
  const { filters } = useFilterParams<GetLinkManageTruckQueryModel["query"]>([
    "page",
    "registNumber",
    "truckTypeId",
    "driverName",
  ]);

  const { isLoading: isLinkMangeTrucksLoading, data: linkManageTrucks } =
    useGetLinkManageTrucks({ query: filters });

  const initSelectedTruckId = useCallback(() => {
    setSelectedTruckId(null);
  }, []);

  const handleSelectTruckId = useCallback((id: string) => {
    setSelectedTruckId((prev) => (prev === id ? "" : id));
  }, []);

  useEffect(() => {
    setSelectedTruckId(null);
  }, [linkManageTrucks]);

  return (
    <>
      <PropertyManageFilter />
      <h3 className="a11y">manage link</h3>
      <S.LinkWrapper>
        <div>
          <LinkManageTruckTable
            isLoading={isLinkMangeTrucksLoading}
            data={linkManageTrucks?.links}
            selectedTruckId={selectedTruckId}
            handleSelectTruckId={handleSelectTruckId}
          />
          <S.PaginationWrapper>
            <QueryPagination
              css={S.queryPagination}
              pageInfo={linkManageTrucks?.pageInfo}
            />
          </S.PaginationWrapper>
        </div>
        <DriverList
          selectedTruckId={selectedTruckId}
          initSelectedTruckId={initSelectedTruckId}
        />
      </S.LinkWrapper>
    </>
  );
};

export default PropertyLink;
