import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixinBtn_icon } from "@/styles";

export const Root = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    max-height: ${theme.size.LIST_LAYOUT_HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border: solid ${theme.color.gray_20};
    border-width: 0 0 1px 0;
    background-color: ${theme.color.white};
  `}
`;

export const FrontWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    display: flex;
    column-gap: 10px;
    align-items: center;
  `}
`;

export const customLink = (theme: Theme) => css`
  ${mixinBtn_icon};
  display: flex;
  align-items: center;

  & > svg > path {
    fill: ${theme.color.black};
  }
`;
