import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  ErrMsg,
  FormFileInput,
  LabelContent,
} from "@/components";
import { IMG_DESC } from "@/constants";
import type { Languages } from "@/types";

import { useRegisterCompanyLicense } from "services";
import type { CompanyLicenseForm } from "types";
import useCompanyLicenseRegistrationForm from "./hooks/useCompanyLicenseRegistrationForm";
import useAccessLicenseRegistration from "./hooks/useAccessLicenseRegistration";
import * as S from "./CompanyLicenseRegistrationForm.styled";

const CarrierCompanyLicenseRegistrationForm = () => {
  const { t } = useTranslation();

  const {
    formMethod,
    licenseRegisterMutateOption,
    handleFileChange,
    handleFileDelete,
    handleCancelClick,
    makeLicenseRegisterBody,
  } = useCompanyLicenseRegistrationForm();
  useAccessLicenseRegistration();

  const { isLoading: isRegisterLoading, mutate: registerCompanyLicenseMutate } =
    useRegisterCompanyLicense("carrier");

  const registerLicense = (data: CompanyLicenseForm) => {
    registerCompanyLicenseMutate(
      makeLicenseRegisterBody("carrier", data),
      licenseRegisterMutateOption,
    );
  };

  return (
    <form onSubmit={formMethod.handleSubmit(registerLicense)}>
      <Card css={S.card}>
        <Card.Content heading="ERC" headingLevel="h4">
          <LabelContent
            css={S.labelContent}
            direction="vertical"
            label="Front of ERC"
            isRequired
          >
            <FormFileInput
              accept="image/jpg, image/jpeg, image/png"
              hasErr={!!formMethod.formState.errors.newLicense1}
              file={formMethod.watch("newLicense1")}
              fileKey="newLicense1"
              placeholder="Upload front of ERC"
              register={formMethod.register}
              handleInputChange={handleFileChange}
              handleDeleteFile={handleFileDelete}
            />
            <S.FileLimitInfo>- {t(IMG_DESC)}</S.FileLimitInfo>
            {formMethod.formState.errors.newLicense1?.message && (
              <ErrMsg>
                {formMethod.formState.errors.newLicense1.message as Languages}
              </ErrMsg>
            )}
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            direction="vertical"
            label="Back of ERC"
            isRequired
          >
            <FormFileInput
              hasErr={!!formMethod.formState.errors.newLicense2}
              accept="image/jpg, image/jpeg, image/png"
              file={formMethod.watch("newLicense2")}
              fileKey="newLicense2"
              placeholder="Upload back of ERC"
              register={formMethod.register}
              handleInputChange={handleFileChange}
              handleDeleteFile={handleFileDelete}
            />
            <S.FileLimitInfo>- {t(IMG_DESC)}</S.FileLimitInfo>
            {formMethod.formState.errors.newLicense2?.message && (
              <ErrMsg>
                {formMethod.formState.errors.newLicense2.message as Languages}
              </ErrMsg>
            )}
          </LabelContent>
        </Card.Content>
      </Card>
      <Card css={S.card}>
        <Card.Content
          heading="Transportation business license"
          headingLevel="h4"
        >
          <LabelContent
            css={S.labelContent}
            direction="vertical"
            label="Front of Transportation business license"
            isRequired
          >
            <FormFileInput
              hasErr={!!formMethod.formState.errors.newTransportLicense1}
              accept="image/jpg, image/jpeg, image/png"
              file={formMethod.watch("newTransportLicense1")}
              fileKey="newTransportLicense1"
              placeholder="Upload front of transportation business license"
              register={formMethod.register}
              handleInputChange={handleFileChange}
              handleDeleteFile={handleFileDelete}
            />
            <S.FileLimitInfo>- {t(IMG_DESC)}</S.FileLimitInfo>
            {formMethod.formState.errors.newTransportLicense1?.message && (
              <ErrMsg>
                {
                  formMethod.formState.errors.newTransportLicense1
                    .message as Languages
                }
              </ErrMsg>
            )}
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            direction="vertical"
            label="Back of Transportation business license"
            isRequired
          >
            <FormFileInput
              hasErr={!!formMethod.formState.errors.newTransportLicense2}
              accept="image/jpg, image/jpeg, image/png"
              file={formMethod.watch("newTransportLicense2")}
              fileKey="newTransportLicense2"
              placeholder="Upload back of transportation business license"
              register={formMethod.register}
              handleInputChange={handleFileChange}
              handleDeleteFile={handleFileDelete}
            />
            <S.FileLimitInfo>- {t(IMG_DESC)}</S.FileLimitInfo>
            {formMethod.formState.errors.newTransportLicense2?.message && (
              <ErrMsg>
                {
                  formMethod.formState.errors.newTransportLicense2
                    .message as Languages
                }
              </ErrMsg>
            )}
          </LabelContent>
        </Card.Content>
      </Card>
      <S.ButtonWrapper>
        <Button
          label="Cancel"
          variant="outlineMedium"
          handleClickBtn={handleCancelClick}
        />
        <Button
          type="submit"
          isLoading={isRegisterLoading}
          isDisabled={!Object.values(formMethod.watch()).every(Boolean)}
          label="Send"
          variant="primaryMedium"
        />
      </S.ButtonWrapper>
    </form>
  );
};

export default CarrierCompanyLicenseRegistrationForm;
