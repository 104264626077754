import React from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable } from "@/components";
import {
  calculateVatAmount,
  calculateTotalAmount,
  findLookupTableLabel,
  formatPrice,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS } from "@/assets";

import type { CompanyType, GetSettlementDetailServerModel } from "types";
import * as S from "./SettlementDetailPreviousPayment.styled";

interface SettlementDetailPreviousPaymentProps {
  data: GetSettlementDetailServerModel<CompanyType>;
}

const SettlementDetailPreviousPayment = ({
  data,
}: SettlementDetailPreviousPaymentProps) => {
  const { t } = useTranslation();

  const payMethodLabel =
    findLookupTableLabel(PAYMENT_METHOD_RADIOS, data.payment.paymentMethod) ??
    "Account transfer";

  const vatPrice = formatPrice(
    calculateVatAmount(`${data.payment.price}`, `${data.payment.vat}`),
  );
  const vat = `(${data.payment.vat}%) ${vatPrice}`;
  const totalPrice = formatPrice(
    calculateTotalAmount(`${data.payment.price}`, `${data.payment.vat}`),
  );

  return (
    <LabelContentTable css={S.labelContent} variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content labelWidth={200} label="Payment method">
          <span>{t(payMethodLabel) || ""}</span>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content labelWidth={200} label="Total">
          <S.PriceWrapper>
            <S.Total>{totalPrice}</S.Total>
            <S.SubtotalWrapper>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                <S.SubtotalContent>
                  {formatPrice(data.payment.price)}
                </S.SubtotalContent>
              </S.SubtotalItem>
              <S.SubtotalItem>
                <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                <S.SubtotalContent>{vat}</S.SubtotalContent>
              </S.SubtotalItem>
            </S.SubtotalWrapper>
          </S.PriceWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default SettlementDetailPreviousPayment;
