import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { clientLanguage } from "@/stores";
import { useToast } from "@/hooks";
import { COMMON_VALID_MSG } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import {
  useGetPropertyTruckDetail,
  useUpdateTruckSpecificationInfo,
} from "services";
import { truckTypeOptionsState } from "stores";
import { formatTruckTypeOption } from "utils";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";
import type {
  GetTruckDetailClientModel,
  TruckSpecificationInfoForm,
} from "types";

const schema = yup.object({
  truckType: yup
    .mixed<DropdownOptionType>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => !!value?.key),
  truckOption: yup
    .mixed<DropdownOptionType>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => !!value?.key),
  size: yup.object({
    length: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;

        return +value <= 13.56;
      }),
    depth: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;
        return +value <= 2.35;
      }),
    height: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;

        return +value <= 2.7;
      }),
  }),
  brand: yup.string().defined(),
  model: yup.string().defined(),
  year: yup.mixed<DropdownOptionType>().defined(),
  vin: yup
    .string()
    .defined()
    .test("checkVin", CARRIER_VALID_MSG.VIN, (value) => value.length === 17)
    .required(CARRIER_VALID_MSG.REQUIRED),
});

const INIT_FORM = {
  truckType: { key: "", label: "" as Languages },
  truckOption: { key: "", label: "" as Languages },
  size: { length: "", depth: "", height: "" },
  brand: "",
  model: "",
  year: { key: "", label: "" as Languages },
  vin: "",
};

const useSpecificationInfo = (handleEditState: any) => {
  const { id } = useParams();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptions = useRecoilValue(truckTypeOptionsState);

  const { data: truckDetail } = useGetPropertyTruckDetail(id!);
  const {
    isLoading: isLoadingUpdateTruckSpecification,
    mutate: updateTruckSpecificationInfoMutate,
  } = useUpdateTruckSpecificationInfo();

  const { addToast } = useToast();

  const formMethod = useForm<TruckSpecificationInfoForm>({
    defaultValues: INIT_FORM,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const updateTruckSpecification = (data: TruckSpecificationInfoForm) => {
    const compareSpecificationInfoData = () => {
      const originData = makeInitData(truckDetail?.specification);
      const currentData = formMethod.getValues();

      return isEqual(originData, currentData);
    };

    if (compareSpecificationInfoData()) {
      handleEditState("", true)();
      return;
    }

    if (!data.truckOption) return;

    const body = {
      truckOptionId: +data.truckOption.key, // TODO: 서버 필수값 설정에 따라 수정필요
      width: data.size.depth ? +data.size.depth : null,
      length: data.size.length ? +data.size.length : null,
      height: data.size.height ? +data.size.height : null,
      brand: data.brand ? data.brand : null,
      model: data.model ? data.model : null,
      year: +data.year.key,
      vin: data.vin,
    };

    updateTruckSpecificationInfoMutate(
      { truckId: id!, body },
      {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
          handleEditState(null, true)();
        },
        onError: () => {
          addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        },
      },
    );
  };

  const makeInitData = (
    originData?: GetTruckDetailClientModel["specification"],
  ) => {
    if (!truckDetail || !originData) return;

    const matchedTypeOptions = formatTruckTypeOption(
      truckDetail?.specification.truckOptionId,
      language,
      truckTypeOptions,
    );

    return {
      truckType: matchedTypeOptions.truckType,
      truckOption: matchedTypeOptions.truckOption,
      size: {
        length: originData.length ? `${originData.length}` : "",
        depth: originData.width ? `${originData.width}` : "",
        height: originData.height ? `${originData.height}` : "",
      },
      brand: originData.brand ?? "",
      model: originData.model ?? "",
      year: {
        key: originData.year.toString(),
        label: originData.year.toString() as Languages,
      },
      vin: originData.vin ?? "",
    };
  };

  useEffect(() => {
    const initData = makeInitData(truckDetail?.specification);

    formMethod.reset({
      ...formMethod.getValues(),
      ...initData,
    });
  }, [truckDetail]);

  return {
    formMethod,
    updateTruckSpecification,
    isLoadingUpdateTruckSpecification,
  };
};

export default useSpecificationInfo;
