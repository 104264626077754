import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import type { PickDropType } from "@/types";

export const TruckingCompanyInfoWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px 20px;
    background-color: ${theme.color.gray_00};

    & > div > div > label {
      ${theme.font.medium_14};
    }

    & > div > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_80};
    }
  `}
`;

export const OrderInfoCargo = (theme: Theme) => css`
  & > div {
    display: grid;
    grid-template-columns: 80px 1fr;
    padding: 0;

    & > div {
      &:first-of-type > span {
        ${theme.font.medium_14};
        height: 100%;
        color: ${theme.color.gray_70};
      }

      &:last-of-type {
        padding: 0;
      }
    }
  }
`;

export const pointIcon = (type: PickDropType) => (theme: Theme) =>
  css`
    path {
      fill: ${type === "PICK" ? theme.color.primary_40 : theme.color.red_20};
    }
  `;

export const Division = styled.hr`
  ${({ theme }) => css`
    width: 350px;
    margin: 0 auto;
    border: 1px solid ${theme.color.gray_20};
  `}
`;
