import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import type { UseMutateFunction } from "@tanstack/react-query";

import { DownloadBtn, LabelContent, ProfileInfo } from "@/components";
import {
  formatICTDateTime,
  formatPhoneNum,
  printDriverDeliveryStatusLabel,
} from "@/utils";
import { DELIVERY_LOG_LABELS, DRIVER_DELIVERY_STATUS } from "@/assets";
import type {
  APIError,
  CertificationImg,
  DeliveryLog,
  FileRequestType,
  Languages,
} from "@/types";

import * as S from "./AllocationDriver.styled";

interface AllocationDriverProps {
  profileUrl: string | null;
  allocationPath?: string;
  name: string;
  phone: string;
  status: keyof typeof DRIVER_DELIVERY_STATUS;
  plateNumber: string;
  deliveryLogs: DeliveryLog;
  certificationImgs: CertificationImg;
  isSelfTransport?: boolean;
  downloadFileMutate: UseMutateFunction<
    AxiosResponse<any, any>,
    APIError<any>,
    FileRequestType,
    unknown
  >;
}

const AllocationDriver = ({
  profileUrl,
  allocationPath,
  name,
  phone,
  status,
  plateNumber,
  deliveryLogs,
  certificationImgs,
  isSelfTransport,
  downloadFileMutate,
}: AllocationDriverProps) => {
  const { t } = useTranslation();

  const hasDeliveryLogs = !!Object.values(deliveryLogs).filter(Boolean).length;
  const hasCertificationImgs =
    !!Object.values(certificationImgs).filter(Boolean).length;

  const CERTIFICATION_IMG_LABEL = {
    puSign: `(P) ${t("Signature")}`,
    puPhoto: `(P) ${t("Photo")}`,
    doSign: `(D) ${t("Signature")}`,
    doPhoto: `(D) ${t("Photo")}`,
  };

  const DOWNLOAD_IMG_NAME = {
    puSign: "Pick-up Signature",
    puPhoto: "Pick-up Photo",
    doSign: "Drop-off Signature",
    doPhoto: "Drop-off Photo",
  };

  return (
    <S.DriverWrapper>
      <ProfileInfo
        profileUrl={profileUrl ?? "-"}
        allocationPath={allocationPath}
        name={name}
        phone={formatPhoneNum(phone)}
        hasInternalLink={isSelfTransport}
      />
      <S.StatusPlateNumber>
        <LabelContent
          css={S.labelContent}
          direction="horizontal"
          columnWidth={100}
          label="Status"
        >
          {status
            ? t(printDriverDeliveryStatusLabel(status) as Languages) //TODO: Empty truck 번역 추가 요청
            : "-"}
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          direction="horizontal"
          columnWidth={100}
          label="Plate number"
        >
          <S.Gray80Text>{plateNumber}</S.Gray80Text>
        </LabelContent>
      </S.StatusPlateNumber>
      {hasDeliveryLogs && (
        <S.DeliveryLogWrapper>
          {Object.entries(deliveryLogs).map(
            ([key, time]) =>
              time && (
                <LabelContent
                  key={key}
                  css={S.logLabelContent}
                  direction="horizontal"
                  columnWidth={200}
                  label={
                    DELIVERY_LOG_LABELS[key as keyof typeof DELIVERY_LOG_LABELS]
                  }
                >
                  <S.Gray80Text>{formatICTDateTime(time)}</S.Gray80Text>
                </LabelContent>
              ),
          )}
        </S.DeliveryLogWrapper>
      )}
      {hasCertificationImgs && (
        <S.CertificationImgWrapper>
          {Object.entries(certificationImgs).map(
            ([key, fileKey]) =>
              fileKey && (
                <DownloadBtn
                  key={fileKey}
                  fileKey={`order/${fileKey}`}
                  downloadFileNamePrefix={
                    DOWNLOAD_IMG_NAME[key as keyof typeof certificationImgs]
                  }
                  fileName={
                    CERTIFICATION_IMG_LABEL[
                      key as keyof typeof certificationImgs
                    ]
                  }
                  downloadFileMutate={downloadFileMutate}
                />
              ),
          )}
        </S.CertificationImgWrapper>
      )}
    </S.DriverWrapper>
  );
};

export default AllocationDriver;
