import React from "react";

import {
  QuotationCanceled,
  QuotationCompleted,
  QuotationLayout,
  QuotationOngoing,
} from "components";

const ShipperQuotation = () => {
  const render = {
    ongoing: <QuotationOngoing clientType="shipper" />,
    completed: <QuotationCompleted clientType="shipper" />,
    canceled: <QuotationCanceled clientType="shipper" />,
  } as const;

  return <QuotationLayout render={render} />;
};
export default ShipperQuotation;
