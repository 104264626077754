import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: ${theme.size.FOLDABLE_SHORT_LAYOUT_WIDTH};
    background-color: ${theme.color.white};
  `}
`;
