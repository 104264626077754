import React, { Suspense } from "react";

import { ReportLayer, ReportLayerSkeleton } from "./containers";

const Report = () => {
  return (
    <Suspense fallback={<ReportLayerSkeleton />}>
      <ReportLayer />
    </Suspense>
  );
};

export default Report;
