import React from "react";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";

import { usePatchQuotationRequote } from "services";

interface QuotationRequoteModal {
  offerId: string;
}

const QuotationRequoteModal = React.forwardRef(
  (
    { offerId }: QuotationRequoteModal,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { addToast } = useToast();
    const { handleModalAllClose } = useModal();

    const { mutate: requoteQuotationMutate } = usePatchQuotationRequote();

    const handleQuotationRequote = () => {
      requoteQuotationMutate(offerId, {
        onSuccess: () => {
          addToast(COMMON_TOAST_MSG.SUCCESS.RESEND_QUOTATION_DONE);
          handleModalAllClose();
        },
        onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Requote"
        desc="Ask the company to requote this quotation."
        closeBtnLabel="Cancel"
        posBtnLabel="Send"
        callbackFn={handleQuotationRequote}
      />
    );
  },
);

QuotationRequoteModal.displayName = "QuotationRequoteModal";

export default QuotationRequoteModal;
