/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { isEmpty, upperCase } from "lodash-es";

import { Button, ErrMsg } from "@/components";
import {
  MONTH,
  WEEKS,
  CalendarIcon,
  TimeIcon,
  DownArrowIcon20,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@/assets";
import type { Languages } from "@/types";

import SingleDates from "./dates/single/SingleDates";
import FreeDates from "./dates/free/FreeDates";
import useCalendar from "./hooks/useCalendar";
import * as S from "./Calendar.styled";

interface CalendarProps {
  className?: string;
  isDialogOpen?: boolean;
  hasTime?: boolean;
  type?: Readonly<"date" | "free">;
  selectedDate: string[];
  disabledCondition?: (date: dayjs.Dayjs) => boolean;
  handleChangeDate: (date: dayjs.Dayjs[] | []) => void;
  handleDialogClose?: () => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Calendar = ({
  className,
  isDialogOpen = false,
  hasTime = false,
  type = "date",
  selectedDate,
  disabledCondition,
  handleChangeDate,
  handleDialogClose,
  handleFocusCondition,
  handleBlurCondition,
}: CalendarProps) => {
  const { t } = useTranslation();

  const { calendar, datePicker, timePicker } = useCalendar(
    isDialogOpen,
    hasTime,
    type,
    selectedDate,
    handleChangeDate,
    handleDialogClose,
    handleFocusCondition,
    handleBlurCondition,
  );

  return (
    <S.Calendar className={className} aria-modal="true">
      <S.Header>
        <S.MonthYear
          type="button"
          aria-controls="month-list"
          aria-expanded={calendar.isOpenMonthDialog}
          aria-label="open month list"
          onClick={calendar.handleMonthDialog}
        >
          <S.MonthYearContent>
            {!calendar.isOpenMonthDialog && (
              <span>
                {t(
                  upperCase(
                    datePicker.monthYear.value.format("MMM"),
                  ) as Languages,
                )}
              </span>
            )}
            <span>{datePicker.monthYear.value.format("YYYY")}</span>
          </S.MonthYearContent>
          <S.OpenMonthWrapper>
            <DownArrowIcon20 />
          </S.OpenMonthWrapper>
        </S.MonthYear>
        <S.MoveBtnWrapper>
          <S.MoveBtn
            type="button"
            aria-label="move previous month"
            onClick={
              calendar.isOpenMonthDialog
                ? datePicker.handleChangePrevYear
                : datePicker.handleChangePrevMonth
            }
          >
            <ChevronLeftIcon />
          </S.MoveBtn>
          <S.MoveBtn
            type="button"
            aria-label="move next month"
            onClick={
              calendar.isOpenMonthDialog
                ? datePicker.handleChangeNextYear
                : datePicker.handleChangeNextMonth
            }
          >
            <ChevronRightIcon />
          </S.MoveBtn>
        </S.MoveBtnWrapper>
      </S.Header>
      {calendar.isOpenMonthDialog ? (
        <S.MonthWrapper id="month-list">
          {Object.entries(MONTH).map(([label, value]) => (
            <li key={value}>
              <S.MonthBtn
                type="button"
                aria-current={
                  value === +datePicker.monthYear.currentMonth &&
                  datePicker.monthYear.year === datePicker.monthYear.currentYear
                }
                aria-selected={+datePicker.monthYear.month === value}
                onClick={calendar.handleClickMonth(value)}
              >
                {t(upperCase(label) as Languages)}
              </S.MonthBtn>
            </li>
          ))}
        </S.MonthWrapper>
      ) : (
        <>
          <S.WeekRow>
            {WEEKS.map((week, i) => (
              <li key={`${week}-${i}`}>{week}</li>
            ))}
          </S.WeekRow>
          <S.DateRow
            className={className}
            data-status={type}
            onMouseLeave={calendar.handleMouseLeave}
          >
            {type === "date" && (
              <SingleDates
                disabledCondition={disabledCondition}
                datePicker={datePicker}
                calendar={calendar}
              />
            )}
            {type === "free" && (
              <FreeDates datePicker={datePicker} calendar={calendar} />
            )}
          </S.DateRow>
        </>
      )}
      {hasTime && !calendar.isOpenMonthDialog && (
        <S.DateTimeContainer>
          <S.DateTimeWrapper hasErr={!!timePicker.timeErr}>
            <S.DateWrapper>
              <CalendarIcon css={S.calendarIcon} />
              {!isEmpty(calendar.currentDate) ? (
                <time>
                  {dayjs(calendar.currentDate[0]).format("DD/MM/YYYY")}
                </time>
              ) : (
                <S.Placeholder>DD/MM/YYYY</S.Placeholder>
              )}
            </S.DateWrapper>
            <S.TimeWrapper>
              <TimeIcon />
              <S.TimeInput
                value={timePicker.time}
                onChange={timePicker.handleChangeTime}
                onBlur={timePicker.handleBlurTime}
              />
            </S.TimeWrapper>
          </S.DateTimeWrapper>
          <ErrMsg css={S.errMsg}>{timePicker.timeErr as Languages}</ErrMsg>
        </S.DateTimeContainer>
      )}
      {!calendar.isOpenMonthDialog && (
        <S.BtnWrapper>
          <Button
            css={S.resetBtn}
            variant="outlineMedium"
            label="Reset"
            handleClickBtn={calendar.handleReset}
          />
          <Button
            css={S.applyBtn}
            isDisabled={calendar.isDisabledApplyBtn}
            variant="primaryMedium"
            label="Apply"
            handleClickBtn={calendar.handleApply}
          />
        </S.BtnWrapper>
      )}
    </S.Calendar>
  );
};

export default Calendar;
