import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const labelContentTable = css`
  width: 764px;
`;

export const content = css`
  & > div:not(:first-of-type) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const suffixInput = css`
  width: 100%;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const Total = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding-bottom: 12px;
    color: ${theme.color.black};
  `}
`;

export const SubtotalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;

export const SubtotalItem = styled.div`
  display: flex;
`;

export const SubtotalLabel = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    width: 120px;
    color: ${theme.color.gray_70};
  `}
`;

export const SubtotalContent = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 24px 0 0 auto;
`;

export const FileWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const FormFileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
`;

export const FileLimit = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const memo = css`
  & > span {
    align-items: flex-start;
    margin-top: 12px;
  }
  & > div {
    ${content};
  }
`;
