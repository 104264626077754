import { useEffect, useState } from "react";

import { useGoogleMap } from "@/contexts";
import { hideMarkers, createMarker } from "@/utils";
import dropOffMarkerSvg from "@/assets/icon/ic_dropOffPin.svg";
import pickUpMarkerSvg from "@/assets/icon/ic_pickUpPin.svg";
import garageMarkerSvg from "@/assets/icon/ic_garagePin.svg";
import { zIndex } from "@/styles";
import type { LocationMarker } from "@/types";

const LOCATION_MARKER_TYPE = {
  pickUp: {
    icon: pickUpMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_PICKUP_ICON_MARKER,
  },
  dropOff: {
    icon: dropOffMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_DROPOFF_ICON_MARKER,
  },
  garage: {
    icon: garageMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_GARAGE_ICON_MARKER,
  },
};

const createLocationMarkerAllocation = (
  map: google.maps.Map,
  location: LocationMarker,
  idx: number,
  maxLength: number,
): google.maps.Marker => {
  const { icon, zIndex } = getMarkerSvg(idx, maxLength);

  return createMarker(map, +location.lat, +location.lng, icon, zIndex);
};

const createLocationMarkerTracking = (
  map: google.maps.Map,
  location: LocationMarker,
): google.maps.Marker =>
  createMarker(
    map,
    +location.lat,
    +location.lng,
    location.type === "PICK" ? pickUpMarkerSvg : dropOffMarkerSvg,
  );
// NOTE: 0: pick up, maxLength-1: drop off, others: garage
const getMarkerSvg = (
  idx: number,
  maxLength: number,
): { icon: string; zIndex: number } => {
  if (idx === 0) {
    return {
      icon: LOCATION_MARKER_TYPE["pickUp"].icon,
      zIndex: LOCATION_MARKER_TYPE["pickUp"].zIndex,
    };
  } else if (idx === maxLength - 1) {
    return {
      icon: LOCATION_MARKER_TYPE["dropOff"].icon,
      zIndex: LOCATION_MARKER_TYPE["dropOff"].zIndex,
    };
  }
  return {
    icon: LOCATION_MARKER_TYPE["garage"].icon,
    zIndex: LOCATION_MARKER_TYPE["garage"].zIndex,
  };
};

const useLocationMarker = (page: "Allocation" | "Tracking") => {
  const { googleMap } = useGoogleMap();
  const [locationMarkers, setLocationMarkers] = useState<google.maps.Marker[]>(
    [],
  );

  const displayLocationMarker = (locations: LocationMarker[]): void => {
    const markers = locations.map((location, idx) => {
      switch (page) {
        case "Allocation":
          return createLocationMarkerAllocation(
            googleMap!,
            location,
            idx,
            locations.length,
          );
        case "Tracking":
          return createLocationMarkerTracking(googleMap!, location);
      }
    });

    hideMarkers(locationMarkers);
    setLocationMarkers(markers);
  };

  const resetLocationMarker = (): void => {
    setLocationMarkers([]);
  };

  useEffect(() => {
    return () => {
      hideMarkers(locationMarkers);
    };
  }, [locationMarkers]);

  return { displayLocationMarker, resetLocationMarker };
};

export default useLocationMarker;
