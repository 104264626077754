import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from "@/contexts";
import { PATH } from "@/assets";
import { MEMBER_GRADE } from "@/constants";

const ManagerGuard = () => {
  const { auth } = useAuth();

  return auth.grade === MEMBER_GRADE.manager ? (
    <Outlet />
  ) : (
    <Navigate to={PATH.ROOT} />
  );
};

export default ManagerGuard;
