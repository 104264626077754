import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";

import { useCancelAllocation } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

const CancelAllocationModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { mutate: cancelAllocationMutate } = useCancelAllocation();

    const { addToast } = useToast();
    const { handleModalClose } = useModal();

    const handleCancelAllocation = () => {
      cancelAllocationMutate(searchParams.get("allocationId")!, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.ALLOCATION_DELETE_DONE);
          navigate(`${PATH.ALLOCATION}?tab=ongoing`);
        },
        onError: (err) => {
          if (err.response?.data.response === "ALREADY_ACCEPTED") {
            addToast(COMMON_TOAST_MSG.WARNING.ALREADY_ACCEPTED_ALLOCATION_FAIL);
          } else if (err.response?.data.response === "ALREADY_SHIPPING") {
            addToast(COMMON_TOAST_MSG.WARNING.ALREADY_SHIPPING_ALLOCATION_FAIL);
          } else {
            addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
          }
        },
        onSettled: () => {
          handleModalClose();
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Cancel allocation"
        desc="Are you sure you want to cancel the allocation?"
        closeBtnLabel={"No"}
        negBtnLabel={"Yes"}
        callbackFn={handleCancelAllocation}
      />
    );
  },
);

CancelAllocationModal.displayName = "CancelAllocationModal";

export default CancelAllocationModal;
