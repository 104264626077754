import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";
import {
  Button,
  DownloadBtn,
  InternalLink,
  LabelContentTable,
} from "@/components";
import { formatPhoneNum, upperCaseToupperFirst } from "@/utils";
import { DownArrowIcon16, PATH } from "@/assets";
import { MEMBER_GRADE } from "@/constants";
import type { Languages } from "@/types";

import { LicenseRegistrationLink } from "components";
import { useDownloadFile, useGetCompanyInfo } from "services";
import * as S from "./ReadField.styled";

interface ReadFieldProps {
  handleChangeUpdateField: any;
}

export const CSVN_CARRIER_COMPANY_ID = "f789aa03-04bb-4273-a4bf-a5998cfb2aed";

const ReadField = ({ handleChangeUpdateField }: ReadFieldProps) => {
  const { t } = useTranslation();

  const { auth } = useAuth();

  const { data: companyInfo } = useGetCompanyInfo(true);
  const {
    mutate: downloadFrontBusinessLicenseFileMutate,
    isLoading: isFrontBusinessLicenseLoading,
  } = useDownloadFile();
  const {
    mutate: downloadBackBusinessLicenseFileMutate,
    isLoading: isBackBusinessLicenseLoading,
  } = useDownloadFile();
  const { mutate: downloadFrontERCFileMutate, isLoading: isFrontERCLoading } =
    useDownloadFile();
  const { mutate: downloadBackERCFileMutate, isLoading: isBackERCLoading } =
    useDownloadFile();

  if (!companyInfo || !auth.grade) return null;

  return (
    <>
      <LabelContentTable variant="whiteBg">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Company name">
            {companyInfo.name}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {(auth.clientType === "carrier" ||
          auth.clientType === "forwarder" ||
          auth.clientType === "forwardercarrier") && (
          <>
            <LabelContentTable.Row>
              <LabelContentTable.Content labelWidth={180} label="Plan">
                <S.PlanContent>
                  {t(upperCaseToupperFirst(companyInfo.plan) as Languages)}
                  {auth.companyId === CSVN_CARRIER_COMPANY_ID && (
                    <InternalLink
                      css={S.detailLink}
                      variant="ghostPrimary"
                      to={PATH.SETTINGS_COMPANY_PLAN_EDIT}
                    >
                      {t("Edit")} <DownArrowIcon16 />
                    </InternalLink>
                  )}
                </S.PlanContent>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LabelContentTable.Row>
              <LabelContentTable.Content
                labelWidth={180}
                label="Payment history"
              >
                <S.PlanContent>
                  <InternalLink
                    css={S.detailLink}
                    variant="ghostPrimary"
                    to={PATH.SETTINGS_COMPANY_PLAN}
                  >
                    {t("Details")} <DownArrowIcon16 />
                  </InternalLink>
                </S.PlanContent>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
          </>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Address">
            {companyInfo.addr}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {auth.grade === MEMBER_GRADE.manager && (
          <LabelContentTable.Row>
            <LabelContentTable.Content labelWidth={180} label="Tax code">
              {companyInfo.taxCode}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Tel.">
            {formatPhoneNum(companyInfo.phone)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="E-mail">
            {companyInfo.email}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {auth.grade === MEMBER_GRADE.manager && (
          <>
            {(auth.clientType === "carrier" ||
              auth.clientType === "forwardercarrier") && (
              <LabelContentTable.Row>
                <LabelContentTable.Content
                  labelWidth={180}
                  label="Business license"
                >
                  <S.DownloadBtnWrapper>
                    {companyInfo.transportLicense1 && (
                      <DownloadBtn
                        downloadFileNamePrefix="Front of Transportation business license"
                        fileName="Front of Transportation business license"
                        fileKey={companyInfo.transportLicense1}
                        isLoading={isFrontBusinessLicenseLoading}
                        downloadFileMutate={
                          downloadFrontBusinessLicenseFileMutate
                        }
                      />
                    )}
                    {companyInfo.transportLicense2 && (
                      <DownloadBtn
                        downloadFileNamePrefix="Back of Transportation business license"
                        fileName="Back of Transportation business license"
                        fileKey={companyInfo.transportLicense2}
                        isLoading={isBackBusinessLicenseLoading}
                        downloadFileMutate={
                          downloadBackBusinessLicenseFileMutate
                        }
                      />
                    )}
                  </S.DownloadBtnWrapper>
                </LabelContentTable.Content>
              </LabelContentTable.Row>
            )}
            <LabelContentTable.Row>
              <LabelContentTable.Content labelWidth={180} label="ERC">
                <S.DownloadBtnWrapper>
                  {companyInfo.license1 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Front of ERC"
                      fileName="Front of ERC"
                      fileKey={companyInfo.license1}
                      isLoading={isFrontERCLoading}
                      downloadFileMutate={downloadFrontERCFileMutate}
                    />
                  )}
                  {companyInfo.license2 && (
                    <DownloadBtn
                      downloadFileNamePrefix="Back of ERC"
                      fileName="Back of ERC"
                      fileKey={companyInfo.license2}
                      isLoading={isBackERCLoading}
                      downloadFileMutate={downloadBackERCFileMutate}
                    />
                  )}
                </S.DownloadBtnWrapper>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LicenseRegistrationLink
              licenseRequestState={companyInfo.licenseRequest}
            />
          </>
        )}
      </LabelContentTable>
      {auth.grade === MEMBER_GRADE.manager && (
        <Button
          css={S.editBtn}
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleChangeUpdateField}
        />
      )}
    </>
  );
};

export default ReadField;
