import React from "react";

import { useFilterParams } from "@/hooks";
import { filterOrderNum } from "@/utils";

import { OrderAllocation } from "components";
import { useGetOrderManagementAllocation } from "services";
import type { GetOrderAllocationQueryModel } from "types";

const CarrierForwarderCarrierOrderAllocation = () => {
  const { filters } = useFilterParams<
    GetOrderAllocationQueryModel<"forwardercarrier">["query"]
  >(["page", "status", "orderNumber"]);

  const query = {
    ...filters,
    ...(filters.orderNumber && {
      orderNumber: filterOrderNum(filters.orderNumber),
    }),
  };

  const { data, dataUpdatedAt, refetch } =
    useGetOrderManagementAllocation<"forwardercarrier">({ query });

  return (
    <OrderAllocation
      data={data}
      dataUpdatedAt={dataUpdatedAt}
      refetch={refetch}
    />
  );
};

export default CarrierForwarderCarrierOrderAllocation;
