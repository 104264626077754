import React from "react";
import { useTranslation } from "react-i18next";

import { QueryPagination, SettingLayout } from "@/components";
import { useFilterParams } from "@/hooks";

import { NoticeTable } from "components";
import { useGetNotices } from "services";
import { SETTING_MENU } from "assets";
import type { GetNoticesQueryModel } from "types";
import * as S from "./Notice.styled";

const Notice = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<GetNoticesQueryModel["query"]>(["page"], {
    pageSize: 10,
  });

  const { data } = useGetNotices({ suspense: false, req: { query: filters } });

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <section>
        <S.Header>
          <S.Title>{t("Notice")}</S.Title>
        </S.Header>
        <NoticeTable />
        <S.PaginationWrapper>
          <QueryPagination pageInfo={data?.pageInfo} />
        </S.PaginationWrapper>
      </section>
    </SettingLayout>
  );
};

export default Notice;
