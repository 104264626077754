import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";

import { NAV, NAV_ITEM } from "assets";
import * as S from "./Navbar.styled";

const Navbar = () => {
  const { t } = useTranslation();

  const { auth } = useAuth();

  const CSVN_ID_UUID = "dce5a759-7612-4778-aae3-4eff3dbbc231";

  return (
    <S.Navbar>
      {(auth.companyId === CSVN_ID_UUID
        ? [NAV_ITEM.ORDER_CREATION, NAV_ITEM.ORDERMANAGEMENT, NAV_ITEM.SETTINGS]
        : NAV(auth.clientType || "shipper", !!auth.gpsType)
      ).map(({ key, path, Icon }) => (
        <NavLink css={S.link(t(key))} key={key} to={path}>
          {Icon && <Icon />}
        </NavLink>
      ))}
    </S.Navbar>
  );
};

export default Navbar;
