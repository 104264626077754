import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAuth } from "@/contexts";
import { useToast } from "@/hooks";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";

import { COMPANY_LICENSE_FORM } from "assets";
import type {
  CompanyLicenseForm,
  CompanyType,
  PutCommonCompanyLicenseQueryModel,
  PutLicenseQueryModel,
} from "types";

const licenseRegistrationSchema = yup.object().shape({
  clientType: yup
    .string()
    .required()
    .oneOf(["carrier", "forwarder", "forwardercarrier", "shipper"])
    .nullable(),
  newLicense1: yup.mixed<FileList>().required().nullable(),
  newLicense2: yup.mixed<FileList>().required().nullable(),
  newTransportLicense1: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .when("clientType", {
      is: (value: CompanyType) =>
        value === "carrier" || value === "forwardercarrier",
      then: () =>
        yup
          .mixed<FileList>()
          .required()
          .test({
            name: "required",
            test: (value) => value.length > 0,
            message: COMMON_VALID_MSG.REQUIRED,
          }),
      otherwise: () => yup.mixed<FileList>().nullable(),
    }),
  newTransportLicense2: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .when("clientType", {
      is: (value: CompanyType) =>
        value === "carrier" || value === "forwardercarrier",
      then: () =>
        yup
          .mixed<FileList>()
          .required()
          .test({
            name: "required",
            test: (value) => value.length > 0,
            message: COMMON_VALID_MSG.REQUIRED,
          }),
      otherwise: () => yup.mixed<FileList>().nullable(),
    }),
});

const useCompanyLicenseRegistrationForm = () => {
  const navigate = useNavigate();

  const {
    auth: { clientType },
  } = useAuth();

  const formMethod = useForm<CompanyLicenseForm>({
    defaultValues: COMPANY_LICENSE_FORM,
    mode: "onTouched",
    resolver: yupResolver(licenseRegistrationSchema),
  });

  const { addToast } = useToast();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const key = name as keyof typeof COMPANY_LICENSE_FORM;

    if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
      addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
      formMethod.setError(key, {
        type: "capacityOver",
      });
      formMethod.setValue(key, null);
    } else {
      formMethod.clearErrors(key);
    }
  };

  const handleFileDelete = (fileKey: string) => () => {
    formMethod.setValue(fileKey as keyof typeof COMPANY_LICENSE_FORM, null);
    formMethod.setError(fileKey as keyof typeof COMPANY_LICENSE_FORM, {
      message: COMMON_VALID_MSG.REQUIRED,
    });
  };

  const handleCancelClick = () => {
    navigate(PATH.SETTINGS_COMPANY);
  };

  function makeLicenseRegisterBody(
    clientType: "carrier" | "forwardercarrier",
    data: CompanyLicenseForm,
  ): PutLicenseQueryModel<"carrier" | "forwardercarrier">;
  function makeLicenseRegisterBody(
    clientType: "forwarder" | "shipper",
    data: CompanyLicenseForm,
  ): PutLicenseQueryModel<"forwarder" | "shipper">;
  function makeLicenseRegisterBody(
    clientType: CompanyType,
    data: CompanyLicenseForm,
  ): PutLicenseQueryModel<typeof clientType> {
    const commonBody: PutCommonCompanyLicenseQueryModel["body"] = {
      newLicense1: data.newLicense1 && data.newLicense1[0],
      newLicense2: data.newLicense2 && data.newLicense2[0],
    };

    switch (clientType) {
      case "carrier":
      case "forwardercarrier":
        return {
          body: {
            ...commonBody,
            newTransportLicense1:
              data.newTransportLicense1 && data.newTransportLicense1[0],
            newTransportLicense2:
              data.newTransportLicense2 && data.newTransportLicense2[0],
          },
        };
      case "forwarder":
      case "shipper":
        return {
          body: { ...commonBody },
        };
    }
  }

  const licenseRegisterMutateOption = {
    onSuccess: () => {
      addToast(COMMON_TOAST_MSG.SUCCESS.COMPANY_LICENSE_UPDATE_DONE);
      navigate(PATH.SETTINGS_COMPANY);
    },
  };

  useEffect(() => {
    if (!clientType) return;

    formMethod.reset({ ...COMPANY_LICENSE_FORM, clientType });
  }, [clientType]);

  return {
    formMethod,
    licenseRegisterMutateOption,
    handleFileChange,
    handleFileDelete,
    handleCancelClick,
    makeLicenseRegisterBody,
  };
};

export default useCompanyLicenseRegistrationForm;
