import { useState } from "react";

const useClose2DepthLayout = () => {
  const [isClose, setIsClose] = useState(false);

  const handleClickClose = () => {
    setIsClose(true);
  };

  const handleClickOpen = () => {
    setIsClose(false);
  };

  return { isClose, handleClickClose, handleClickOpen };
};

export default useClose2DepthLayout;
