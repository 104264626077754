import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const plateNumberWrapper = css`
  display: flex;
  flex-flow: column;
`;

export const PlateList = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    grid-column: span 3;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const viewDetailsButton = (theme: Theme) => css`
  ${theme.font.regular_13};
  color: ${theme.color.primary_40};
`;
