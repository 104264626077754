import React from "react";
import { FormProvider } from "react-hook-form";

import { useDetailFormEdit } from "hooks";
import type { PropertyDetailEditCardType } from "types";
import DefaultInfoField from "./field/TruckDefaultInfoField";
import DefaultInfoFieldEdit from "./fieldEdit/TruckDefaultInfoFieldEdit";
import { useDefaultInfo } from "../hooks";

const TruckDefaultInfo = () => {
  const { editState, handleEditState, handleCancel } = useDetailFormEdit();
  const {
    formMethod,
    isLoadingUpdateTruckDefaultInfo,
    updateDefaultInfo,
    handleFileChange,
  } = useDefaultInfo(handleEditState);

  const handleClickEdit =
    (editName: PropertyDetailEditCardType | null) => () => {
      handleEditState(editName, true)();
    };

  const handleClickCancel = () => {
    formMethod.reset();
    handleCancel();
  };

  return (
    <FormProvider {...formMethod}>
      {editState !== "DefaultInfo" ? (
        <DefaultInfoField
          editState={editState}
          handleClickEdit={handleClickEdit}
          handleCancel={handleCancel}
        />
      ) : (
        <DefaultInfoFieldEdit
          isLoading={isLoadingUpdateTruckDefaultInfo}
          updateDefaultInfo={updateDefaultInfo}
          handleClickCancel={handleClickCancel}
          handleFileChange={handleFileChange}
        />
      )}
    </FormProvider>
  );
};

export default TruckDefaultInfo;
