import React from "react";
import { useTranslation } from "react-i18next";

import { ExternalLink, LabelContentTable } from "@/components";
import { formatOrderNum } from "@/utils";
import { DefaultArrowIcon, PATH } from "@/assets";

import type { CompanyType, GetSettlementDetailServerModel } from "types";
import * as S from "./SettlementDetailDefaultInfo.styled";

interface SettlementDefaultInfoProps {
  data: GetSettlementDetailServerModel<CompanyType>;
}

const SettlementDetailDefaultInfo = ({ data }: SettlementDefaultInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Order number">
            <S.ContentWrapper>
              <span>{formatOrderNum(data.defaultInfo.orderNumber)}</span>
              <ExternalLink
                css={S.externalLink}
                variant="ghostPrimary"
                href={`${PATH.ORDER_MANAGEMENT}/${data.defaultInfo.orderId}`}
              >
                <>
                  {t("Order management")}
                  <DefaultArrowIcon />
                </>
              </ExternalLink>
            </S.ContentWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Company">
            <S.ContentWrapper>
              <span>{data.defaultInfo.company}</span>
              <ExternalLink
                css={S.externalLink}
                variant="ghostPrimary"
                href={`${PATH.QUOTATION}/${data.defaultInfo.offerId}`}
              >
                {t("Quotation management")}
                <DefaultArrowIcon />
              </ExternalLink>
            </S.ContentWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
    </>
  );
};

export default SettlementDetailDefaultInfo;
