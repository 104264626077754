import React from "react";

import { ConfirmModal } from "@/components";

import * as S from "./UnlinkModal.styled";

interface UnlinkModalProps {
  handleDisconnect: () => void;
}

const UnlinkModal = React.forwardRef(
  (
    { handleDisconnect }: UnlinkModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        css={S.confirmModal}
        ref={ref}
        title="Unlink all drivers"
        desc="Are you sure you want to unlink all drivers?"
        closeBtnLabel="Cancel"
        negBtnLabel="Confirm"
        callbackFn={handleDisconnect}
      />
    );
  },
);

UnlinkModal.displayName = "UnlinkModal";

export default UnlinkModal;
