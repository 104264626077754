import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  ErrMsg,
  Input,
  LabelContent,
  LabelContentTable,
} from "@/components";
import { findLookupTableLabel, onlyNumber } from "@/utils";
import { MEMBER_GRADE_OPTIONS, PHONE_MAX_LENGTH } from "@/assets";
import type { Languages } from "@/types";

import useEditDefaultInfo from "./hooks/useEditDefaultInfo";
import * as S from "./UpdateField.styled";

interface UpdateFieldProps {
  handleChangeReadField: () => void;
}

const UpdateField = ({ handleChangeReadField }: UpdateFieldProps) => {
  const { t } = useTranslation();

  const { isUpdateLoading, data, formMethod, handleSubmit } =
    useEditDefaultInfo(handleChangeReadField);

  if (!data) return null;

  const grade = findLookupTableLabel(MEMBER_GRADE_OPTIONS, data.grade);

  return (
    <form onSubmit={handleSubmit}>
      <LabelContentTable variant="whiteBg">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Name">
            <S.NameWrapper>
              <div>
                <LabelContent
                  css={S.labelContent}
                  label="Last name"
                  direction="vertical"
                  isRequired
                >
                  <Input
                    css={S.nameInput}
                    hasError={!!formMethod.formState.errors.lastName?.message}
                    maxLength={50}
                    placeholder="Last name"
                    value={formMethod.watch("lastName")}
                    register={formMethod.register("lastName")}
                  />
                  {!!formMethod.formState.errors.lastName?.message && (
                    <ErrMsg css={S.errorMsg}>
                      {
                        formMethod.formState.errors.lastName
                          ?.message as Languages
                      }
                    </ErrMsg>
                  )}
                </LabelContent>
                <LabelContent
                  css={S.labelContent}
                  label="Middle name"
                  direction="vertical"
                >
                  <Input
                    css={S.nameInput}
                    maxLength={50}
                    placeholder="(Optional)"
                    value={formMethod.watch("middleName")}
                    register={formMethod.register("middleName")}
                  />
                </LabelContent>
                <LabelContent
                  css={S.labelContent}
                  label="First name"
                  direction="vertical"
                  isRequired
                >
                  <Input
                    css={S.nameInput}
                    maxLength={50}
                    hasError={!!formMethod.formState.errors.firstName?.message}
                    placeholder="First name"
                    value={formMethod.watch("firstName")}
                    register={formMethod.register("firstName")}
                  />
                  {!!formMethod.formState.errors.firstName?.message && (
                    <ErrMsg css={S.errorMsg}>
                      {
                        formMethod.formState.errors.firstName
                          ?.message as Languages
                      }
                    </ErrMsg>
                  )}
                </LabelContent>
              </div>
            </S.NameWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Grade">
            {grade ? t(grade) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Phone number">
            <LabelContent
              css={S.labelContent}
              label="Phone number"
              direction="vertical"
              isRequired
            >
              <Input
                css={S.phoneInput}
                maxLength={PHONE_MAX_LENGTH}
                placeholder="Only numbers"
                value={formMethod.watch("phone")}
                register={formMethod.register("phone", {
                  setValueAs: onlyNumber,
                })}
                hasError={!!formMethod.formState.errors.phone?.message}
              />
              {!!formMethod.formState.errors.phone?.message && (
                <ErrMsg css={S.errorMsg}>
                  {formMethod.formState.errors.phone?.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.ButtonWrapper>
        <Button
          type="button"
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleChangeReadField}
        />
        <Button
          isLoading={isUpdateLoading}
          type="submit"
          variant="primaryMedium"
          label="Update"
          isDisabled={Object.keys(formMethod.formState.errors).length > 0}
        />
      </S.ButtonWrapper>
    </form>
  );
};

export default UpdateField;
