import React from "react";

import { Tab, TableLayout } from "@/components";

import { SettlementForCarrier, SettlementForShipper } from "components";
import { FORWARDER_SETTLEMENT_TABS } from "assets";
import * as S from "./Settlement.styled";

const ForwarderSettlement = () => {
  const render = {
    shipper: <SettlementForShipper />,
    carrier: <SettlementForCarrier />,
  } as const;

  return (
    <TableLayout h2="Settlement">
      <Tab tabs={FORWARDER_SETTLEMENT_TABS}>
        <Tab.TabList css={S.customTab} />
        <Tab.TabPanel render={render} />
      </Tab>
    </TableLayout>
  );
};

export default ForwarderSettlement;
