import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 520px;
    padding: 60px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 8px;
    ${theme.font.semibold_32};
  `}
`;

export const Content = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 40px;
    color: ${theme.color.gray_70};
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

export const emailAddressWrapper = css`
  margin: 0;
`;

export const emailAddressInput = css`
  padding-right: 55px;

  &:disabled {
    background-color: inherit;
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const SendBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: absolute;
    bottom: 7px;
    right: 0;
    color: ${theme.color.primary_40};

    :disabled {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const verificationCodeWrapper = (hasError: boolean) => css`
  position: relative;
  margin: ${hasError ? "0 0 24px 0" : "0 0 40px 0"};
`;

export const VerificationInput = styled.input<{ hasError: boolean }>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_16};
    width: 100%;
    border: 0;
    border-bottom: 1px solid
      ${hasError ? theme.color.red_20 : theme.color.gray_30};
    padding: 4px 0;

    :focus {
      outline: none;
      border-bottom: 1px solid ${theme.color.primary_40};
    }
  `}
`;

export const timer = css`
  position: absolute;
  top: 15px;
  right: 0;
`;

export const loginErrMsg = css`
  margin-bottom: 24px;
`;

export const nextBtn = (theme: Theme) => css`
  ${theme.font.bold_16};
`;

export const input = (theme: Theme) => css`
  ${theme.font.regular_16};
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${theme.color.gray_30};
  border-radius: 0;
  padding: 4px 0;

  :focus {
    outline: none;
    border: 0;
    border-bottom: 1px solid ${theme.color.primary_40};
  }
`;

export const EmailInputTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;
