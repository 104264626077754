import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const PlanContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const detailLink = (theme: Theme) => css`
  & > svg {
    margin-right: 0;
    transform: rotate(-90deg);

    > path {
      fill: ${theme.color.primary_40};
    }
  }
`;

export const LicenseWrapper = styled.div`
  && {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }
`;

export const labelContent = css`
  & > p {
    position: static;
  }
`;

export const labelContentInput = css`
  width: 500px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 12px;
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const DownloadBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;
