import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Layout = styled.section`
  width: fit-content;
  padding: 32px 0 0 40px;
`;

export const toc = css`
  margin-left: 139px;
`;

export const TocHeading = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_18};
  `}
`;

export const TocBottom = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${theme.color.gray_70};
  `}
`;

export const backLink = css`
  width: fit-content;
  margin: 32px 0 0 auto;
  padding: 8px 16px;
`;

export const button = css`
  text-decoration: none;
`;
