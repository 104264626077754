import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Dialog = styled.dialog`
  ${({ theme }) => css`
    width: 100%;
    z-index: ${theme.zIndex.DIALOG};

    & > div,
    & > ul {
      position: absolute;
      top: 4px;
      border: 1px solid ${theme.color.gray_30};
      border-radius: 2px;
      background-color: ${theme.color.white};
    }
  `}
`;
