import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import { DataSection, StatusLabel } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";
import { PATH } from "@/assets";

import { QUOTATION_ONGOING_TABLE_COLUMNS } from "assets";
import type { CompanyType, GetQuotationOngoingServerModel } from "types";
import * as S from "./QuotationOngoingSection.styled";

interface QuotationOngoingSectionProps {
  data: GetQuotationOngoingServerModel<CompanyType>;
  refetch: () => void;
  dataUpdatedAt: number;
}
const QuotationOngoingSection = ({
  data,
  refetch,
  dataUpdatedAt,
}: QuotationOngoingSectionProps) => {
  const { t } = useTranslation();

  const filteredColumns = filterTableColumns(
    QUOTATION_ONGOING_TABLE_COLUMNS(auth.clientType!),
  );

  const formatTableData = (
    data: GetQuotationOngoingServerModel<CompanyType>["ongoing"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "status": {
        const hasCompanyCnt =
          auth.clientType === "forwarder" ||
          auth.clientType === "forwardercarrier";

        return hasCompanyCnt &&
          +("companyCnt" in data) > 0 &&
          data.status === "FORWARDING" ? (
          <S.StatusWrapper>
            <StatusLabel status={data[key]} />
            <S.Count>+{data.companyCnt}</S.Count>
          </S.StatusWrapper>
        ) : (
          <StatusLabel status={data[key]} />
        );
      }

      case "companyName": {
        if (!("companyName" in data)) return;

        if ("companyCnt" in data) {
          return (
            <S.CompanyWrapper>
              {data[key]}
              {+data.companyCnt > 1 && <span>+ {+data.companyCnt - 1}</span>}
            </S.CompanyWrapper>
          );
        } else {
          return data[key];
        }
      }

      case "shipperName":
        return "shipperName" in data && data[key];

      case "orderNumber":
        return formatOrderNum(data[key]);

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddr":
        return addrFormatter(data.puAddr, data.puAddrDetail);
      case "doAddr":
        return addrFormatter(data.doAddr, data.doAddrDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table(auth.clientType!)}
        title="Quotation"
        isStickyThead
        rowCount={data.ongoing.length}
      >
        <DataSection.Table.Head
          columnList={QUOTATION_ONGOING_TABLE_COLUMNS(auth.clientType!)}
        />
        <DataSection.Table.Body css={S.body}>
          {data.ongoing.length ? (
            data.ongoing.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.offerId}
                path={item.offerId}
              >
                {filteredColumns.map(({ key }) => {
                  return (
                    <DataSection.Table.Cell key={key} css={S.cell}>
                      {formatTableData(item, key)}
                    </DataSection.Table.Cell>
                  );
                })}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData>
              <Link css={S.linknoData} to={PATH.ORDER_CREATION}>
                {t("Order creation")}
              </Link>
            </DataSection.Table.NoData>
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default QuotationOngoingSection;
