import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const Ul = styled.ul`
  ${mixin_scrollbar}

  height: 100%;

  & > li:last-of-type {
    & div {
      border-bottom: 0;
    }
  }
`;

export const pagination = (theme: Theme) => css`
  height: 40px;
  border-top: 1px solid ${theme.color.gray_30};
`;

export const li = css`
  width: 390px;
`;
