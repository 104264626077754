import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useToast } from "@/hooks";
import { PATH } from "@/assets";

import {
  Foldable1DepthLayout,
  Foldable2DepthLayout,
  DetailLeft,
  DetailRight,
} from "components";
import { useAllocation, useClose2DepthLayout } from "hooks";
import { useEditAllocation, useGetAllocationDetail } from "services";
import { isPickDropSortOk } from "utils";
import { CARRIER_TOAST_MSG } from "constants/index";
import type { GetAllocationDetailDriverServerItem } from "types";

const AllocationEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const allocationId = searchParams.get("allocationId")!;

  const { data: allocationDetail } = useGetAllocationDetail(allocationId, {
    onSuccess: (data) => {
      setSelectedAllocationDriver(data.driverInfo);
    },
  });
  const {
    mutate: updateAllocationMutate,
    isLoading: isUpdateAllocationLoading,
  } = useEditAllocation();

  const [selectedAllocationDriver, setSelectedAllocationDriver] =
    useState<GetAllocationDetailDriverServerItem | null>(null);

  const { isClose, handleClickClose, handleClickOpen } = useClose2DepthLayout();
  const { addToast } = useToast();
  const {
    allocations,
    handleDragDrop,
    changeMemo,
    handleAllocationAdd,
    handleAllocationDelete,
    handleAllocationWithSmartDispatchAdd,
  } = useAllocation(allocationDetail?.allocationInfo);

  const changeSelectedAllocationEdit = (
    allocation: GetAllocationDetailDriverServerItem,
  ): void => {
    setSelectedAllocationDriver(allocation);
  };

  const handleAllocationUpdate = (): void => {
    if (!selectedAllocationDriver) return;

    const allocationRoutes = allocations.map((item) => ({
      orderId: item.orderId,
      type: item.type,
      ...(item.memo && { memo: item.memo.trim() }),
    }));

    const body = {
      driverId: selectedAllocationDriver.driverId,
      truckId: selectedAllocationDriver.truckId,
      allocations: allocationRoutes,
    };

    if (!isPickDropSortOk(allocationRoutes)) {
      addToast(CARRIER_TOAST_MSG.WARNING.PICK_DROP_SORT_NOT_OK);
      return;
    }

    const req = { allocationId, body };

    updateAllocationMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.ALLOCATION_UPDATE_DONE);
        navigate(`${PATH.ALLOCATION}?tab=ongoing&id=${req.allocationId}`);
      },
      onError: (err) => {
        if (err.response?.data.response === "DISPATCH_ALREADY_EXISTS") {
          addToast(CARRIER_TOAST_MSG.WARNING.ALREADY_ALLOCATIOND);
        }
      },
    });
  };

  return (
    <>
      <Foldable1DepthLayout heading="Allocation Edit">
        <DetailLeft
          selectedAllocationDriver={selectedAllocationDriver}
          isUpdateAllocationLoading={isUpdateAllocationLoading}
          allocations={allocations}
          isNeedDriverEdit={true}
          driverDetail={allocationDetail?.driverInfo}
          handleDragDrop={handleDragDrop}
          changeMemo={changeMemo}
          changeSelectedAllocationEdit={changeSelectedAllocationEdit}
          handleSubmit={handleAllocationUpdate}
          handleClickOpen={handleClickOpen}
        />
      </Foldable1DepthLayout>
      <Foldable2DepthLayout
        isClose={isClose}
        handleClickClose={handleClickClose}
        handleClickOpen={handleClickOpen}
      >
        <DetailRight
          allocations={allocations}
          originAllocations={allocationDetail?.allocationInfo}
          handleAllocationAdd={handleAllocationAdd}
          handleAllocationDelete={handleAllocationDelete}
          handleAllocationWithSmartDispatchAdd={
            handleAllocationWithSmartDispatchAdd
          }
        />
      </Foldable2DepthLayout>
    </>
  );
};

export default AllocationEdit;
