import { useEffect } from "react";

type ElementType = HTMLElement | null;

const useOnClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  handler: () => void,
  exceptEl?: ElementType | ElementType[],
) => {
  const checkIncludeExceptEl = (
    e: MouseEvent,
    exceptEl?: ElementType | ElementType[],
  ) => {
    if (Array.isArray(exceptEl)) {
      return !!exceptEl?.filter(
        (el) => el && el.contains(e?.target as HTMLElement),
      ).length;
    }

    return exceptEl && exceptEl?.contains(e?.target as HTMLElement);
  };

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      const el = ref?.current;
      const isIncludeEl = !el || el.contains(e?.target as HTMLElement);
      const isIncludeExceptEl = checkIncludeExceptEl(e, exceptEl);

      if (isIncludeEl || isIncludeExceptEl) return;

      handler();
    };
    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
