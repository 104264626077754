import React, { useState } from "react";
import { isEmpty } from "lodash-es";

import { NoResult, SearchInput } from "@/components";
import { formatPhoneNum } from "@/utils";

import { useGetDriversForAllocationEdit } from "services";
import type { GetDriversForAllocationEditServerModel } from "types";
import * as S from "./DriverEditDialog.styled";

interface DriverEditDialogProps {
  selectedDriverId: string;
  handleDialogClose: () => void;
  cbFn: any;
}

const DriverEditDialog = ({
  selectedDriverId,
  handleDialogClose,
  cbFn,
}: DriverEditDialogProps) => {
  const [searchWord, setSearchWord] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const query = {
    ...(searchWord && { driverName: searchWord }),
  };

  const { data: drivers } = useGetDriversForAllocationEdit(query);
  const filterExcludeSelectedDrivers = drivers?.filter(
    (driver) => driver.driverId !== selectedDriverId,
  );

  const handleSearch = (q: string) => {
    setSearchWord(q);
  };

  const handleChangeDriver =
    (item: GetDriversForAllocationEditServerModel[number]) => () => {
      handleDialogClose();
      setSearchValue("");
      setSearchWord("");
      cbFn(item);
    };

  const handleClickReload = () => {
    setSearchWord("");
  };

  const changeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  return (
    <S.Root>
      <SearchInput
        css={S.searchInput}
        placeholder="Search by driver name"
        value={searchValue}
        handleChange={changeSearchValue}
        handleSearch={handleSearch}
        handleReset={handleClickReload}
      />
      <S.Body>
        {!isEmpty(filterExcludeSelectedDrivers) ? (
          filterExcludeSelectedDrivers?.map((item) => {
            return (
              <li key={item.driverId}>
                <S.Btn type="button" onClick={handleChangeDriver(item)}>
                  <S.CustomNamePhoneLabel
                    name={item.driverName}
                    phone={formatPhoneNum(item.phone)}
                  />
                </S.Btn>
              </li>
            );
          })
        ) : (
          <NoResult />
        )}
      </S.Body>
    </S.Root>
  );
};

export default DriverEditDialog;
