import { css } from "@emotion/react";

import { mixin_table_pagination } from "@/styles";

export const simpleStatistics = css`
  margin-bottom: 40px;
`;

export const pagination = css`
  ${mixin_table_pagination}
`;

export const customTab = css`
  margin-bottom: 20px;
`;
