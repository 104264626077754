import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { QueryPagination } from "@/components";

export const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 395px;
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
    background-color: ${theme.color.white};
  `}
`;

export const queryPagination = css`
  border-top: 0px;
`;
