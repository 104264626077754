import React from "react";

import { DetailLayout } from "@/components";

import { OrderManagementBtns } from "components";
import type { GetOrderManagementDetailServerModel } from "types";

interface OrderManagementDetailLayoutProps {
  tableOfContent: React.ReactNode;
  isHideOrderCancelBtn: boolean;
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementDetailLayout = ({
  tableOfContent,
  isHideOrderCancelBtn,
  data,
}: OrderManagementDetailLayoutProps) => {
  return (
    <DetailLayout>
      {tableOfContent}
      <OrderManagementBtns
        isHideOrderCancelBtn={isHideOrderCancelBtn}
        data={data}
      />
    </DetailLayout>
  );
};

export default OrderManagementDetailLayout;
