import React, { ReactNode } from "react";

import { Tab, TableLayout } from "@/components";
import { useTab } from "@/hooks";

import { ORDER_MANAGEMENT_TABS } from "assets";
import * as S from "./OrderManagementLayout.styled";

interface OrderManagementLayoutProps {
  render: { [key: string]: ReactNode };
}

const OrderManagementLayout = ({ render }: OrderManagementLayoutProps) => {
  const { selectedTab } = useTab(ORDER_MANAGEMENT_TABS);

  return (
    <TableLayout h2="Order Management">
      <h3 className="a11y">{selectedTab}</h3>
      <S.InfoWrapper>
        <Tab css={S.customTabList} tabs={ORDER_MANAGEMENT_TABS}>
          <Tab.TabList css={S.customTab} isOrder />
          <Tab.TabPanel render={render} />
        </Tab>
      </S.InfoWrapper>
    </TableLayout>
  );
};

export default OrderManagementLayout;
