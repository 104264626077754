import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import { NoResult, Profile } from "@/components";
import { useToast } from "@/hooks";
import { clientLanguage } from "@/stores";
import { formatPhoneNum } from "@/utils";
import { PhoneIcon, SuccessIcon, TruckIcon } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";

import { AllocationLocation, OrderCard } from "components";
import { useGpsPolylines } from "hooks";
import type { Allocation } from "types";
import * as S from "./OrderDetailBody.styled";

interface OrderDetailBodyProps {
  allocations: Allocation[];
}

const OrderDetailBody = ({ allocations }: OrderDetailBodyProps) => {
  const { t } = useTranslation();

  const languages = useRecoilValue(clientLanguage);

  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const { addToast } = useToast();
  useGpsPolylines(allocations[selectedCardIndex].coords);

  const handleOrderCardClick = (index: number) => (): void => {
    setSelectedCardIndex(index);

    !allocations[selectedCardIndex].gpsAvailable &&
      addToast(COMMON_TOAST_MSG.WARNING.CANNOT_FIND_GPS);
  };

  return (
    <S.TrackingOrderDetailBody>
      <S.Title>{t("Allocation list")}</S.Title>
      {!allocations?.length ? (
        <NoResult css={S.noResult} />
      ) : (
        <S.Wrapper>
          {allocations.map(({ deliveries, gpsAvailable, driverInfo }, i) => {
            const truckType =
              languages === "en"
                ? driverInfo.truckTypeEn
                : driverInfo.truckTypeVi;

            return (
              <OrderCard
                css={S.orderCard(selectedCardIndex === i)}
                key={i}
                onClick={handleOrderCardClick(i)}
              >
                <S.TitleWrapper>
                  <Profile src={driverInfo.profile} />
                  <OrderCard.Title
                    css={S.orderCardTitle}
                    content={driverInfo.name}
                  />
                  <S.Gps hasGps={!!gpsAvailable}>
                    {/* TODO: 동일한 주문 건이나 화주 / 운송사 사이에 gpsAvailable이 상이하게 들어옴 
                    -> 서버 확인 결과 : 화주와 운송사가 다른 api로 사용하고 있기 때문 
                    추후 api 통합 후 재확인 필요 */}
                    <SuccessIcon /> GPS
                  </S.Gps>
                </S.TitleWrapper>
                <OrderCard.Body>
                  <OrderCard.InfoWrapper>
                    <OrderCard.InfoDetail>
                      <TruckIcon css={S.svg} />
                      <span>
                        {truckType} ({driverInfo.registNumber})
                      </span>
                    </OrderCard.InfoDetail>
                    <OrderCard.InfoDetail css={S.driverInfo}>
                      <PhoneIcon css={S.svg} />
                      <span>{formatPhoneNum(driverInfo.phone)}</span>
                    </OrderCard.InfoDetail>
                  </OrderCard.InfoWrapper>
                  <AllocationLocation>
                    {deliveries.map((item) => (
                      <AllocationLocation.Detail
                        key={+item.lat + +item.lng}
                        type={item.type}
                        destination={item.addr}
                        state={item.completed}
                      />
                    ))}
                  </AllocationLocation>
                </OrderCard.Body>
              </OrderCard>
            );
          })}
        </S.Wrapper>
      )}
    </S.TrackingOrderDetailBody>
  );
};

export default OrderDetailBody;
