import React from "react";
import { useTranslation } from "react-i18next";

import { DialogBtn, ExternalLink, MoreDialog } from "@/components";
import { formatOrderNum } from "@/utils";
import { PATH } from "@/assets";

import { AllocationLocation, OrderCard } from "components";
import type { GetMonitoringTruckDetailServerModel } from "types";
import * as S from "./TruckAllocationInfo.styled";

interface TruckAllocationInfoProps {
  trucksAllocations: GetMonitoringTruckDetailServerModel["allocations"];
}

const TruckAllocationInfo = ({
  trucksAllocations,
}: TruckAllocationInfoProps) => {
  const { t } = useTranslation();

  return (
    <S.AllocationInfoWrapper>
      <h3>{t("Allocation list")}</h3>
      <S.OrderCardWrapper>
        {trucksAllocations.length &&
          trucksAllocations.map((allocation, i) => (
            <OrderCard key={i}>
              <S.OrderCardTitleWrapper>
                <ExternalLink
                  variant="ghostNoUnderlineGray"
                  href={`${PATH.ORDER_MANAGEMENT}/${allocation.orders[0].orderId}`}
                >
                  <OrderCard.Title
                    css={S.orderCardTitle}
                    content={formatOrderNum(
                      allocation.orders?.[0]?.orderNumber,
                    )}
                  />
                </ExternalLink>
                {allocation.orders.length > 1 && (
                  <DialogBtn
                    css={S.moreBtn}
                    popup={() => <MoreDialog orders={allocation.orders} />}
                  >
                    {allocation.orders.length - 1}+
                  </DialogBtn>
                )}
              </S.OrderCardTitleWrapper>
              <OrderCard.Body>
                <AllocationLocation>
                  {allocation.deliveries.map((delivery, i) => (
                    <AllocationLocation.Detail
                      key={i}
                      type={delivery.type}
                      destination={`${delivery.name}, ${delivery.city}`}
                      state={delivery.completed || "-"}
                    />
                  ))}
                </AllocationLocation>
              </OrderCard.Body>
            </OrderCard>
          ))}
      </S.OrderCardWrapper>
    </S.AllocationInfoWrapper>
  );
};

export default TruckAllocationInfo;
