import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Form = styled.form`
  display: flex;
  flex-flow: column;

  & > input {
    display: none;
  }
`;

export const payNowBtn = css`
  width: fit-content;
  margin: 10px 0 0 auto;
`;
