import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import { DataSection } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";
import { PATH } from "@/assets";

import { QUOTATION_CANCELED_TABLE_COLUMNS } from "assets";
import type { CompanyType, GetQuotationCanceledServerModel } from "types";
import * as S from "./QuotationCanceledSection.styled";

interface QuotationCanceledProps {
  data: GetQuotationCanceledServerModel<CompanyType>;
  refetch: () => void;
  dataUpdatedAt: number;
}

const QuotationCanceled = ({
  data,
  refetch,
  dataUpdatedAt,
}: QuotationCanceledProps) => {
  const { t } = useTranslation();

  const filteredColumns = filterTableColumns(
    QUOTATION_CANCELED_TABLE_COLUMNS(auth.clientType!),
  );

  const formatTableData = (
    data: GetQuotationCanceledServerModel<CompanyType>["canceled"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "orderNumber":
        return formatOrderNum(data[key]);

      case "shipperName":
        return "shipperName" in data && data[key];
      case "companyName":
        return "companyName" in data && data[key];

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddr":
        return addrFormatter(data.puAddr, data.puAddrDetail);
      case "doAddr":
        return addrFormatter(data.doAddr, data.doAddrDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table(auth.clientType!)}
        title="Quotation"
        isStickyThead
        rowCount={data?.canceled.length}
      >
        <DataSection.Table.Head
          columnList={QUOTATION_CANCELED_TABLE_COLUMNS(auth.clientType!)}
        />
        <DataSection.Table.Body css={S.body}>
          {data.canceled.length ? (
            data.canceled.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.orderId}
                path={item.offerId}
              >
                {filteredColumns.map(({ key }) => {
                  return (
                    <DataSection.Table.Cell key={key}>
                      {formatTableData(item, key)}
                    </DataSection.Table.Cell>
                  );
                })}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData>
              <Link css={S.linknoData} to={PATH.ORDER_CREATION}>
                {t("Order creation")}
              </Link>
            </DataSection.Table.NoData>
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default QuotationCanceled;
