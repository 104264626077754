import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { SearchInput } from "@/components";
import { mixin_scrollbar } from "@/styles";

export const Root = styled.article`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_30};
    border-left: 0;
    background-color: ${theme.color.white};
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 24px 24px 16px;
  `}
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.bold_16};
    margin-bottom: 2px;
  `}
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const CustomSearchInput = styled(SearchInput)`
  height: 44px;
`;

export const Utils = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 0 24px;
  `}
`;

export const SelectCount = styled.strong`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    column-gap: 4px;
    color: ${theme.color.gray_60};

    & > span {
      font-weight: 500;
      color: ${theme.color.gray_70};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const List = styled.ul`
  ${mixin_scrollbar};
  height: 700px;
  padding: 16px 24px 0;

  & > li:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;
