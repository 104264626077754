import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import { Button, InternalLink, LabelContentTable } from "@/components";
import {
  calculateTotalAmount,
  calculateVatAmount,
  findLookupTableLabel,
  formatPrice,
} from "@/utils";
import { PATH, PAYMENT_METHOD_RADIOS } from "@/assets";

import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationPayment.styled";

interface QuotationPaymentProps {
  data: GetQuotationDetailServerModel<CompanyType>;
  handleReject?: () => void;
  handleCheckValidForm?: () => void;
}

const QuotationPayment = ({
  data,
  handleReject,
  handleCheckValidForm,
}: QuotationPaymentProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const isForwardercarrierOrForwarder =
    auth.clientType === "forwarder" || auth.clientType === "forwardercarrier";

  const price = formatPrice(data.paymentInfo.price);
  const totalPrice = formatPrice(
    calculateTotalAmount(
      `${data.paymentInfo.price}`,
      `${data.paymentInfo.vat}`,
    ),
  );
  const vatPrice = formatPrice(
    calculateVatAmount(`${data.paymentInfo.price}`, `${data.paymentInfo.vat}`),
  );
  const vat = `(${data.paymentInfo.vat}%) ${vatPrice}`;
  const paymethodLabel =
    findLookupTableLabel(PAYMENT_METHOD_RADIOS, data.paymentInfo.pay) ?? "";

  const isShowButton =
    data.defaultInfo.isSelfTransport &&
    data.defaultInfo.pageStatus === "ONGOING" &&
    data.defaultInfo.offerStatus !== "DELIVERED";

  const sendQuotationData = {
    orderId: data.defaultInfo.orderId,
    orderNumber: data.defaultInfo.orderNumber,
    orderMemo: data.truckingMethods.orderMemo ?? "",
    offerId: id!,
    shipperName: data.defaultInfo.client,
    puEta: data.pickUpInfo.puEta,
    puAddr: data.pickUpInfo.puAddr,
    puAddrDetail: data.pickUpInfo.puAddrDetail ?? "",
    doEta: data.dropOffInfo.doEta,
    doAddr: data.dropOffInfo.doAddr,
    doAddrDetail: data.dropOffInfo.doAddrDetail ?? "",
    price: data.paymentInfo.price,
    vat: data.paymentInfo.vat,
    cargoes: data.cargoes,
    pay: data.paymentInfo.pay,
  };

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Payment method">
            {paymethodLabel ? t(paymethodLabel) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Price">
            <S.PriceWrapper>
              <S.Total>{totalPrice}</S.Total>
              <S.SubtotalWrapper>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                  <S.SubtotalContent>{price}</S.SubtotalContent>
                </S.SubtotalItem>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                  <S.SubtotalContent>{vat}</S.SubtotalContent>
                </S.SubtotalItem>
              </S.SubtotalWrapper>
            </S.PriceWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {((data.defaultInfo.offerStatus === "DELIVERED" &&
          !!(data.history && data.history.length)) ||
          (isForwardercarrierOrForwarder &&
            data.defaultInfo.pageStatus === "COMPLETED")) && (
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Memo">
              {data.paymentInfo.remarks ?? "-"}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
      </LabelContentTable>
      {(data.defaultInfo.pageStatus === "PENDING" || isShowButton) && (
        <S.ButtonWrapper>
          <Button
            variant="outlineMedium"
            label="Reject"
            handleClickBtn={handleReject}
          />
          {data.defaultInfo.pageStatus !== "ONGOING" ? (
            <InternalLink
              variant="primaryMedium"
              to={PATH.QUOTATION_SEND}
              state={{ selectedOrders: [sendQuotationData] }}
            >
              {t("Send quotation")}
            </InternalLink>
          ) : (
            <Button
              variant="primaryMedium"
              label="Confirm"
              handleClickBtn={handleCheckValidForm}
            />
          )}
        </S.ButtonWrapper>
      )}
    </>
  );
};

export default QuotationPayment;
