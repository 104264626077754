import React, { useCallback } from "react";

import { useModal, useToast } from "@/hooks";

import { useConnectLinkManage } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";
import UnlinkModal from "../driver/modal/UnlinkModal";

const useLinkDriver = () => {
  const { mutate: connectLinkMutate } = useConnectLinkManage();

  const { addToast } = useToast();
  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const handleConnectLinkDriver = useCallback(
    (truckId: string, driverIds: string[]) => () => {
      const req = {
        truckId,
        body: { driverIds },
      };

      connectLinkMutate(req, {
        onSuccess: () => addToast(CARRIER_TOAST_MSG.SUCCESS.LINK_CONNECT_DONE),
      });
    },
    [],
  );

  const handleDisconnectLinkDriver = useCallback(
    (truckId: string) => () => {
      const handleDisconnect = () => {
        const req = {
          truckId,
          body: { driverIds: [] },
        };

        connectLinkMutate(req, {
          onSuccess: () => {
            addToast(CARRIER_TOAST_MSG.SUCCESS.LINK_DISCONNECT_DONE);
            handleModalClose();
          },
        });
      };

      handleModalOpen(
        <UnlinkModal ref={modalRef} handleDisconnect={handleDisconnect} />,
      )();
    },
    [],
  );

  return {
    handleConnectLinkDriver,
    handleDisconnectLinkDriver,
  };
};

export default useLinkDriver;
