import { css } from "@emotion/react";

import { theme } from "../themes";

export const mixin_ul = css`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const mixin_li = css`
  display: flex;
  align-items: center;
  column-gap: 9px;
  grid-template-columns: 16px 1fr;

  & > span,
  time {
    ${theme.font.regular_14};
    color: ${theme.color.gray_80};
  }
`;

export const mixin_detail_button = css`
  ${theme.font.semibold_12};
  padding: 2px 4px;
  border: 1px solid ${theme.color.gray_30};
  border-radius: 5px;
  color: ${theme.color.gray_50};

  :hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const mixin_tab_navbar = css`
  height: 50px;
  padding: 20px 20px 10px;
  border-bottom: 1px solid ${theme.color.gray_20};
`;

export const mixin_scrollbar = css`
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${theme.color.gray_20};
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    margin-right: 2px;
    border-radius: 100px;
    background-color: ${theme.color.gray_40};
  }
`;

export const mixin_horizontal_scrollbar = css`
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    background-color: ${theme.color.gray_20};
  }

  ::-webkit-scrollbar-thumb {
    margin-right: 2px;
    border-radius: 100px;
    background-color: ${theme.color.gray_40};
  }
`;

export const mixin_list_layout_main_header = css`
  position: sticky;
  top: 0;
  background-color: ${theme.color.white};
`;

export const mixin_list_layout_detail_body = css`
  height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT})`};
  ${mixin_scrollbar};
`;

export const mixin_hr = css`
  height: 8px;
  border: 0;
  background-color: ${theme.color.gray_20};
`;

export const mixin_limit_one_line = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const mixin_table_pagination = css`
  position: relative;
  display: flex;
  justify-content: center;
  height: 60px;
  border: 1px solid ${theme.color.gray_35};
  border-top: 0;
  padding: 12px 20px;
  background-color: ${theme.color.white};
`;
