/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";
import { useTranslation } from "react-i18next";

import DetailModal from "../detailModal/DetailModal";
import * as S from "./PromptModal.styled";

interface PromptModalProps {
  callbackFn: () => void;
  handleNegBtn?: () => void;
}
const PromptModal = React.forwardRef(
  (
    { callbackFn, handleNegBtn }: PromptModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="Leave this page?"
        closeBtnLabel="Cancel"
        posBtnLabel="Leave"
        callbackFn={callbackFn}
        handleNegBtn={handleNegBtn}
      >
        {t("Changes you made may not be saved.")}
      </DetailModal>
    );
  },
);

PromptModal.displayName = "PromptModal";

export default PromptModal;
