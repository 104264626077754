import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Root = styled.header`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 190px auto;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${theme.size.HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 0 ${theme.size.LAYOUT_PADDING} 0 20px;
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.HEADER};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 22px;
`;

export const logoutBtn = (theme: Theme) => css`
  ${theme.font.regular_13};

  svg {
    fill: ${theme.color.gray_50};
  }
`;
