import React from "react";
import { useLocation } from "react-router-dom";

import { ExternalLink } from "@/components";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "@/constants";

import * as S from "./PrivateFooter.styled";

const PrivateFooter = () => {
  const location = useLocation();
  // TODO: footer를 숨김 필요한 페이지는 매번 추가해주어야 함, 리팩토링 필요
  const isView =
    ["tracking", "allocation", "monitoring"].includes(
      location.pathname.split("/")[1],
    ) || ["map", "allocation"].includes(location.pathname.split("/")[2]);

  if (isView) return null;

  return (
    !isView && (
      <S.Root>
        <S.Wrapper>
          <S.ItemWrapper>
            <S.Title>CÔNG TY TNHH COCONUT SILO VINA</S.Title>
            <S.Address>
              Địa chỉ: 135 Hai Bà Trưng, Phường Bến Nghé, Quận 1, Thành Phố Hồ
              Chí Minh, Việt Nam | Giấy chứng nhận đăng ký kinh doanh số
              0316662169
            </S.Address>
            <S.Desc>
              Ngày cấp: 30/12/2020 | Nơi cấp: Sở kế hoạch và đầu tư thành phố Hồ
              Chí Minh | Điện thoại: 083 373 1900 | E-mail:
              contact@coconutsilo.com
            </S.Desc>
          </S.ItemWrapper>
          <S.ItemWrapper>
            <S.Title>Thông tin</S.Title>
            <ExternalLink
              css={S.link}
              href={PRIVACY_POLICY}
              variant="ghostGray"
            >
              Chính sách bảo mật
            </ExternalLink>
            <ExternalLink
              css={S.link}
              href={TERMS_CONDITIONS}
              variant="ghostGray"
            >
              Quy chế hoạt động
            </ExternalLink>
          </S.ItemWrapper>
        </S.Wrapper>
      </S.Root>
    )
  );
};

export default PrivateFooter;
