import React from "react";

import { INIT_PAGE_INFO, INIT_MAX_PAGE_COUNT } from "@/constants";
import type { PageInfo } from "@/types";

import Pagination from "../Pagination";
import useQueryPagination from "./hooks/useQueryPagination";

interface QueryPaginationProps {
  className?: string;
  hasDoubleBtn?: boolean;
  pageInfo?: PageInfo;
  maxPageCount?: number;
}

const QueryPagination = ({
  className,
  hasDoubleBtn = true,
  pageInfo = INIT_PAGE_INFO,
  maxPageCount = INIT_MAX_PAGE_COUNT,
}: QueryPaginationProps) => {
  const { totalPages } = pageInfo;

  const {
    currentPage,
    handlePrevPage,
    handleFirstPage,
    handleNextPage,
    handleLastPage,
    handleClickNum,
  } = useQueryPagination(maxPageCount, totalPages);

  return (
    <Pagination
      className={className}
      hasDoubleBtn={hasDoubleBtn}
      currentPage={currentPage}
      totalPages={totalPages}
      maxPageCount={maxPageCount}
      handlePrevPage={handlePrevPage}
      handleFirstPage={handleFirstPage}
      handleNextPage={handleNextPage}
      handleLastPage={handleLastPage}
      handleClickNum={handleClickNum}
    />
  );
};

export default QueryPagination;
