import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { PROPERTY_DRIVER_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${PROPERTY_DRIVER_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

export const Driver = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > span {
    margin-right: 8px;
  }
`;
