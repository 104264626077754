import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { comma, deleteComma } from "@/utils";
import { PAYMENT_METHOD_RADIOS, TAX_RADIOS } from "@/assets";

import type {
  CompanyType,
  GetSettlementDetailServerModel,
  SettlementDetailPaymentForm,
} from "types";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";
import { useUpdateSettlementDetail } from "services";
import { useParams } from "react-router-dom";
import { VAT } from "@/types";

const schema = yup.object({
  status: yup
    .string()
    .required()
    .oneOf(["WAITING", "ONGOING", "COMPLETED"] as const),
  paymentMethod: yup
    .mixed<(typeof PAYMENT_METHOD_RADIOS)[number]["key"]>()
    .required(COMMON_VALID_MSG.REQUIRED),
  price: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test(
      "maxTotal",
      COMMON_VALID_MSG.INVALID_PRICE,
      (value) => +deleteComma(value) < 2147483647,
    ),
  vat: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  attachment: yup
    .object()
    .shape({
      attachment1: yup.mixed<FileList | string>().required().nullable(),
      attachment2: yup.mixed<FileList | string>().required().nullable(),
    })
    .optional(),
  memo: yup
    .string()
    .defined()
    .nullable()
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_MEMO_150,
      (value) => (value?.length ?? 0) <= 150,
    ),
});

const useSettlementDetailPaymentForm = (
  data: GetSettlementDetailServerModel<CompanyType>,
) => {
  const { id } = useParams();

  const { addToast } = useToast();

  const { mutate: updateSettlementDetailMutate } = useUpdateSettlementDetail();

  const formMethod = useForm<SettlementDetailPaymentForm>({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key =
      e.target.name.split(".").at(-1) === "file1"
        ? "attachment.attachment1"
        : "attachment.attachment2";

    if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
      addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
      formMethod.setError("attachment", { type: "capacityOver" });
      formMethod.setValue(key, null);
    } else {
      formMethod.clearErrors(key);
    }
  };

  const handleDeleteFile = (fileKey: string) => () => {
    if (
      fileKey === "attachment.attachment1" ||
      fileKey === "attachment.attachment2"
    ) {
      formMethod.setValue(fileKey, null);
      formMethod.clearErrors(fileKey);
    }
  };

  const handleFormSuccess = (data: SettlementDetailPaymentForm): void => {
    const body = {
      status: data.status,
      paymentMethod: data.paymentMethod,
      price: +deleteComma(data.price),
      vat: +data.vat as VAT,
      // TODO: 서버 로직 수정된 거 확인 후 주석 제거
      ...(typeof data.attachment?.attachment1 !== "string" && {
        attachment1: data.attachment?.attachment1
          ? data.attachment.attachment1[0]
          : null,
      }),
      // TODO: 서버 로직 수정된 거 확인 후 주석 제거
      ...(typeof data.attachment?.attachment2 !== "string" && {
        attachment2: data.attachment?.attachment2
          ? data.attachment.attachment2[0]
          : null,
      }),
      ...(data.memo && { remarks: data.memo.trim() }),
    };

    updateSettlementDetailMutate(
      { offerId: id!, body },
      { onSuccess: () => formMethod.reset() },
    );
  };

  const handleFormFail = (): void => {
    addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
  };

  useEffect(() => {
    formMethod.reset({
      status: data.status,
      paymentMethod: data.payment.paymentMethod,
      price: `${comma(`${data.payment.price}`)}`,
      vat: `${data.payment.vat}` as (typeof TAX_RADIOS)[number]["key"],
      attachment: data.payment.attachment,
      memo: data.memo,
    });
  }, [data]);

  return {
    formMethod,
    handleInputChange,
    handleDeleteFile,
    handleFormSuccess,
    handleFormFail,
  };
};

export default useSettlementDetailPaymentForm;
