import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { useAuth } from "@/contexts";
import {
  Card,
  LabelContent,
  SelectCompanyBtn,
  CompanySearchModal,
  TruckingCompanySearchModal,
  CheckboxBtn,
} from "@/components";
import { useModal } from "@/hooks";
import { COMPANY_COLUMNS, TRUCKING_COMPANY_COLUMNS } from "@/assets";
import type { CompanyInfo, Languages } from "@/types";

import { useGetLinkPlayer } from "services";
import type { OrderForm } from "types";
import * as S from "./OrderCreationDefaultInfo.styled";

const OrderCreationDefaultInfo = () => {
  const {
    auth: { clientType },
  } = useAuth();

  const { data: carrierLinkPlayers } = useGetLinkPlayer({
    query: { type: "carrier" },
  });
  const { data: shipperLinkPlayers } = useGetLinkPlayer({
    query: { type: "shipper" },
  });
  const { data: forwarderLinkPlayers } = useGetLinkPlayer({
    query: { type: "forwarder" },
  });

  const { modalRef, handleModalOpen } = useModal();

  const {
    control,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext<OrderForm>();

  if (!carrierLinkPlayers || !shipperLinkPlayers || !forwarderLinkPlayers)
    return null;

  return (
    <Card>
      <Card.Content
        css={S.content}
        heading="Default information"
        headingLevel="h3"
      >
        {(clientType === "carrier" ||
          clientType === "forwarder" ||
          clientType === "forwardercarrier") && (
          <LabelContent label="Company" direction="vertical" isRequired>
            <SelectCompanyBtn
              err={errors?.companyInformation?.company?.message as Languages}
              label="Please select the shipper company"
              selectedCompany={watch("companyInformation.company")!}
              handleClickBtn={handleModalOpen(
                <Controller
                  control={control}
                  name="companyInformation.company"
                  render={({ field: { value, onChange } }) => {
                    const handleCompanySelect = (
                      companyInfos: CompanyInfo[],
                    ): void => onChange(companyInfos);

                    return (
                      <CompanySearchModal
                        ref={modalRef}
                        columns={COMPANY_COLUMNS}
                        selectedCompany={value}
                        tableDatas={shipperLinkPlayers}
                        handleCompanySelect={handleCompanySelect}
                      />
                    );
                  }}
                />,
              )}
            />
          </LabelContent>
        )}
        {(clientType === "forwarder" ||
          clientType === "forwardercarrier" ||
          clientType === "shipper") && (
          <>
            <LabelContent
              label={clientType === "shipper" ? "Company" : "Trucking company"}
              direction="vertical"
              isRequired
            >
              {
                <Controller
                  control={control}
                  name="companyInformation.truckingCompany"
                  render={({ field: { value, onChange } }) => {
                    const tableDatas =
                      clientType === "shipper"
                        ? [...forwarderLinkPlayers, ...carrierLinkPlayers]
                        : carrierLinkPlayers;

                    const handleCompanySelect = (
                      companyInfos: CompanyInfo[],
                    ): void => onChange(companyInfos);

                    return (
                      <>
                        <SelectCompanyBtn
                          err={
                            errors?.companyInformation?.truckingCompany
                              ?.message as Languages
                          }
                          label={
                            clientType === "forwarder"
                              ? "Please select company to sent the quotation to"
                              : "Please select company to request quotation"
                          }
                          isDisabled={watch(
                            "companyInformation.isCheckSelfTransport",
                          )}
                          selectedCompany={
                            watch("companyInformation.truckingCompany")!
                          }
                          handleClickBtn={handleModalOpen(
                            <TruckingCompanySearchModal
                              ref={modalRef}
                              columns={TRUCKING_COMPANY_COLUMNS}
                              selectedCompany={value}
                              tableDatas={tableDatas}
                              handleCompanySelect={handleCompanySelect}
                            />,
                          )}
                        />
                        {clientType === "forwardercarrier" && (
                          <CheckboxBtn
                            css={S.checkbox}
                            shape="rectangle"
                            isChecked={watch(
                              "companyInformation.isCheckSelfTransport",
                            )}
                            label="Transport the cargo by myself."
                            register={register(
                              "companyInformation.isCheckSelfTransport",
                              {
                                onChange: () => {
                                  clearErrors(
                                    "companyInformation.truckingCompany",
                                  );
                                  clearErrors("truck");
                                },
                              },
                            )}
                          />
                        )}
                      </>
                    );
                  }}
                />
              }
            </LabelContent>
          </>
        )}
      </Card.Content>
    </Card>
  );
};

export default OrderCreationDefaultInfo;
