import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const InfoWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    background-color: ${theme.color.white};
  `}
`;

export const customTabList = (theme: Theme) => css`
  padding-bottom: 12px;
  background-color: ${theme.color.gray_00};
`;

export const customTab = css`
  margin-bottom: 20px;
`;
