/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useToast } from "@/hooks";
import { SuccessIcon, WarningIcon24 } from "@/assets";
import { Languages, ToastMsg } from "@/types";

import * as S from "./ToastItem.styled";

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

interface ToastItemProps {
  id: string;
  type: ToastMsg;
  content: Languages;
  prefix?: string;
}

const ToastItem = ({ type, id, content, prefix }: ToastItemProps) => {
  const { t } = useTranslation();

  const [isClosing, setIsClosing] = useState(false);

  const { removeToast } = useToast();

  useEffect(() => {
    const existTimeout = setTimeout(() => {
      setIsClosing(true);
    }, TOAST_DURATION);

    const expireToastTimeout = setTimeout(() => {
      removeToast(id);
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(existTimeout);
      clearTimeout(expireToastTimeout);
    };
  }, []);

  const message = prefix ? `${prefix} ${t(content)}` : t(content);

  return (
    <S.ToastItem isClosing={isClosing}>
      <S.Item role="status">
        {type === "success" ? (
          <SuccessIcon css={S.successIcon} />
        ) : (
          <WarningIcon24 css={S.warningIcon} />
        )}
        <pre>{message}</pre>
      </S.Item>
    </S.ToastItem>
  );
};

export default ToastItem;
