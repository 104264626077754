import { useEffect } from "react";

import type { GetShipperMonitoringOrderDetailClientModel } from "types";

const useAllocationMarkerEventListener = (
  monitoringAllocations: GetShipperMonitoringOrderDetailClientModel["allocationList"],
  selectedOrderCard: number,
  handleAllocationMarkerClick: (
    plateNumber: string,
    isSamePlateNum?: boolean,
  ) => Promise<void>,
  checkSelectedOrderCard: (index: number) => void,
) => {
  useEffect(() => {
    if (!monitoringAllocations) return;

    monitoringAllocations.forEach((truck) => {
      if (truck.doAtd) {
        truck.truckMarker?.setCursor("not-allowed"); //NOTE: 운행이 종료된 차량의 마커는 선택 불가능

        return;
      }

      truck.truckMarker?.addListener("click", () => {
        const index = monitoringAllocations.findIndex(
          (item) => item.plateNumber === truck.plateNumber,
        );

        checkSelectedOrderCard(index);

        if (selectedOrderCard === index) {
          checkSelectedOrderCard(-1);
        }
        handleAllocationMarkerClick(
          truck.plateNumber,
          index === selectedOrderCard,
        );
      });
    });

    return () => {
      monitoringAllocations.forEach((truck) => {
        if (!truck.truckMarker) return;

        google.maps.event.clearListeners(truck.truckMarker, "click");
      });
    };
  }, [monitoringAllocations, handleAllocationMarkerClick]);
};

export default useAllocationMarkerEventListener;
