import { useState, useLayoutEffect, useCallback } from "react";

import type { DropdownOptionType, Languages } from "@/types";

const useDropdownValue = (
  options: readonly DropdownOptionType[],
  initKey?: string,
) => {
  const [selectedOption, setSelectedOption] = useState({
    key: "",
    label: "" as Languages,
  });

  const handleSelect = useCallback(
    (key: string) => {
      const selectIndex = options.findIndex((item) => item.key === key);

      setSelectedOption(options[selectIndex]);
    },
    [selectedOption],
  );

  useLayoutEffect(() => {
    initKey && handleSelect(initKey);
  }, []);

  return { selectedOption, handleSelect };
};

export default useDropdownValue;
