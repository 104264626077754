import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_table_pagination } from "@/styles";

import { SETTING_COMPANY_PLAN_TABLE_CELL_SIZES } from "assets";

export const Title = styled.div`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 8px;
  `}
`;

export const table = css`
  tr {
    grid-template-columns: ${SETTING_COMPANY_PLAN_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_HALF_BODY_HEIGHT};

  & > tr:nth-of-type(10) {
    border-bottom: 0;
  }
`;

export const pagination = css`
  ${mixin_table_pagination}

  margin-bottom: 12px;
`;

export const closeBtn = css`
  width: fit-content;
  margin-left: auto;
`;
