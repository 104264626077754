import React from "react";
import { FormProvider } from "react-hook-form";

import { Button, CheckboxBtn } from "@/components";
import { useModal, usePrompt } from "@/hooks";
import { StarIcon } from "@/assets";

import {
  FavoriteOrderModal,
  OrderCreationDefaultInfo,
  OrderCreationTruck,
  OrderCreationPickUpInfo,
  OrderCreationDropOffInfo,
  OrderCreationCargo,
  OrderCreationTruckingMethods,
  OrderCreationPayment,
  OrderCreationSummaryInfo,
  OrderCreationLayout,
} from "components";
import { useCreateOrderCreation } from "services";
import type { OrderForm } from "types";
import useOrderCreation from "./hooks/useOrderCreation";
import * as S from "./OrderCreation.styled";

const ForwarderCarrierOrderCreation = () => {
  const { mutate, isLoading, isSuccess } =
    useCreateOrderCreation("forwardercarrier");

  const {
    formMethod,
    isChangeFormValue,
    checkDisabledDateCondition,
    deleteCalendarInput,
    makeCreationOrderFormBody,
    orderCreationMutateOption,
    handleSelectFavoriteOrder,
  } = useOrderCreation(isSuccess);
  const { modalRef, handleModalOpen } = useModal();
  usePrompt(isChangeFormValue);

  const createOrder = (data: OrderForm): void => {
    mutate(
      makeCreationOrderFormBody("forwardercarrier", data),
      orderCreationMutateOption,
    );
  };

  return (
    <FormProvider {...formMethod}>
      <OrderCreationLayout
        favoriteButton={
          <Button
            variant="ghostGray"
            label="Import favorite"
            icon={<StarIcon />}
            handleClickBtn={handleModalOpen(
              <FavoriteOrderModal
                ref={modalRef}
                handleSelectFavoriteOrder={handleSelectFavoriteOrder}
              />,
            )}
          />
        }
        LeftContent={
          <>
            <OrderCreationDefaultInfo />
            <OrderCreationPickUpInfo
              checkDisabledDateCondition={checkDisabledDateCondition}
              deleteCalendarInput={deleteCalendarInput}
            />
            <OrderCreationDropOffInfo
              checkDisabledDateCondition={checkDisabledDateCondition}
              deleteCalendarInput={deleteCalendarInput}
            />
            <OrderCreationCargo />
            <OrderCreationTruckingMethods />
            {formMethod.watch("companyInformation").isCheckSelfTransport && (
              <OrderCreationTruck />
            )}
            <OrderCreationPayment />
          </>
        }
        RightContent={
          <>
            <OrderCreationSummaryInfo />
            <Button
              type="submit"
              isLoading={isLoading}
              isDisabled={!!Object.keys(formMethod.formState.errors).length}
              label="Confirm"
              variant="primaryLarge"
              handleClickBtn={formMethod.handleSubmit(createOrder)}
            />
            <CheckboxBtn
              css={S.formBtn}
              label="Save as favorite"
              isChecked={formMethod.watch("addFavorite")}
              shape="rectangle"
              register={formMethod.register("addFavorite")}
            />
          </>
        }
      />
    </FormProvider>
  );
};

export default ForwarderCarrierOrderCreation;
