import React from "react";
import { FormProvider } from "react-hook-form";

import { useDetailFormEdit } from "hooks";
import { useSpecificationInfoForm } from "../hooks";
import SpecificationField from "./field/SpecificationField";
import SpecificationFieldEdit from "./fieldEdit/SpecificationFieldEdit";

const Specification = () => {
  const { editState, handleEditState, handleCancel } = useDetailFormEdit();
  const {
    formMethod,
    updateTruckSpecification,
    isLoadingUpdateTruckSpecification,
  } = useSpecificationInfoForm(handleEditState);

  return (
    <FormProvider {...formMethod}>
      {editState !== "Specification" ? (
        <SpecificationField
          handleClickEdit={handleEditState("Specification")}
        />
      ) : (
        <SpecificationFieldEdit
          isLoading={isLoadingUpdateTruckSpecification}
          updateTruckSpecification={updateTruckSpecification}
          handleCancel={handleCancel}
        />
      )}
    </FormProvider>
  );
};

export default Specification;
