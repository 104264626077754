import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash-es";

import { Button, Loading, NoResult } from "@/components";
import { useCheckTableWithCondition } from "@/hooks";

import DriverCard from "./card/DriverCard";
import useSearchDriver from "./hooks/useSearchDriver";
import { useLinkDriver } from "../hooks";
import * as S from "./DriverList.styled";

interface DriverListProps {
  selectedTruckId: string | null;
  initSelectedTruckId: () => void;
}

const DriverList = ({
  selectedTruckId,
  initSelectedTruckId,
}: DriverListProps) => {
  const { t } = useTranslation();

  const { handleConnectLinkDriver } = useLinkDriver();
  const {
    drivers,
    isLoading,
    searchText,
    handleSearch,
    handleChangeSearchText,
    handleResetSearch,
  } = useSearchDriver(selectedTruckId);

  const selectedDriverInitState = useMemo(() => {
    if (!selectedTruckId) return [];

    return drivers?.filter((driver) => driver.truckId === selectedTruckId);
  }, [drivers, selectedTruckId]);

  const driverList = useMemo(() => {
    if (!drivers?.length) {
      return [];
    }

    return [...drivers].sort((driver1, driver2) => {
      if (
        driver1.truckId === selectedTruckId ||
        (driver2.truckId && driver2.truckId !== selectedTruckId)
      ) {
        return -1;
      }

      if (
        (driver1.truckId && driver1.truckId !== selectedTruckId) ||
        driver2.truckId === selectedTruckId
      ) {
        return 1;
      }

      return 0;
    });
  }, [drivers, selectedTruckId]);

  const {
    checkedIds: selectedDrivers,
    handleCheck: handleSelectDrivers,
    handleInitCheck,
    handleComparePreviousCheckedIds,
  } = useCheckTableWithCondition(selectedDriverInitState, "driverId");

  const handleCancel = () => {
    initSelectedTruckId();
  };

  const isChangeCheckedDrivers = (selectedTruckId: string | null): boolean => {
    if (!selectedTruckId || !selectedDriverInitState) return false;

    return !isEqual(
      selectedDriverInitState.map(({ driverId }) => driverId).sort(),
      selectedDrivers.sort(),
    );
  };

  useEffect(() => {
    handleResetSearch();

    if (selectedTruckId) {
      handleComparePreviousCheckedIds(selectedTruckId);
    }
  }, [selectedTruckId]);

  useEffect(() => {
    if (!selectedDriverInitState || selectedDriverInitState?.length === 0)
      return;

    handleInitCheck(); // TODO: 선택된 드라이버를 checkId 에 넣는 로직 수정 필요
  }, [selectedDriverInitState]);

  return (
    <S.Root>
      {selectedTruckId ? (
        <>
          <S.Header>
            <S.TitleWrapper>
              <S.Title>{t("Driver list")}</S.Title>
              <S.Desc>{t("Select the driver in charge.")}</S.Desc>
            </S.TitleWrapper>
            <S.CustomSearchInput
              placeholder="Search by driver name"
              value={searchText}
              handleChange={handleChangeSearchText}
              handleSearch={handleSearch}
              handleReset={handleResetSearch}
            />
          </S.Header>
          <S.Utils>
            <S.SelectCount>
              <span>{selectedDrivers.length}</span>
              {t("Selected")}
            </S.SelectCount>
            {isChangeCheckedDrivers(selectedTruckId) && (
              <S.ButtonWrapper>
                <Button
                  variant="ghostNoUnderlineGray"
                  label="Cancel"
                  handleClickBtn={handleCancel}
                />
                <Button
                  variant="ghostNoUnderlinePrimary"
                  label="Update"
                  handleClickBtn={handleConnectLinkDriver(
                    selectedTruckId,
                    selectedDrivers,
                  )}
                />
              </S.ButtonWrapper>
            )}
          </S.Utils>
          <S.List>
            {isLoading ? (
              <Loading />
            ) : driverList.length ? (
              driverList
                ?.filter((driver) => driver.phone !== null)
                .map((driver) => (
                  <li key={driver.driverId}>
                    <DriverCard
                      data={driver}
                      selectedTruckId={selectedTruckId}
                      selectedDrivers={selectedDrivers}
                      handleSelectDrivers={handleSelectDrivers}
                    />
                  </li>
                ))
            ) : (
              <NoResult />
            )}
          </S.List>
        </>
      ) : (
        <NoResult />
      )}
    </S.Root>
  );
};

export default DriverList;
