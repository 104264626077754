import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "@/types";

import * as S from "./LoginErrMsg.styled";

interface LoginErrMsgProps {
  className?: string;
  id?: string;
  text: Languages;
}

const LoginErrMsg = ({ className, id, text }: LoginErrMsgProps) => {
  const { t } = useTranslation();

  return (
    <S.Root id={`${id}-err-msg`} className={className}>
      {t(text)}
    </S.Root>
  );
};

export default LoginErrMsg;
