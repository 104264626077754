import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  CheckboxBtn,
  ErrMsg,
  FormFileInput,
  HideEmailPassword,
  Input,
  LabelContent,
  PageBackHeader,
} from "@/components";
import { onlyNumber, checkPasswordConfirm } from "@/utils";
import {
  PATH,
  PROFILE_TOOLTIP,
  PHONE_MAX_LENGTH,
  VisibleIcon,
  UnvisibleIcon,
} from "@/assets";
import { COMMON_VALID_MSG, FILE_DESC } from "@/constants";
import type { Languages } from "@/types";

import { TruckLinkAutoComplete } from "components";
import { CARRIER_VALID_MSG } from "constants/index";
import useDriverCreateForm from "./hooks/useDriverCreateForm";
import * as S from "./DriverCreate.styled";

const DriverCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const {
    errors,
    isCreateDriverLoading,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmitDriverCreate,
    handleDelFile,
    handleSelectLink,
    handleCheckAgree,
    handleChangeAttachments,
  } = useDriverCreateForm();

  const handleToggleShowPassword = () =>
    setIsShowPassword((password) => !password);
  const handleToggleShowConfirmPassword = () =>
    setIsShowConfirmPassword((password) => !password);

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <S.Root>
      <form>
        <h3 className="a11y">Add the driver</h3>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Add the driver"
          path={PATH.PROPERTY}
        />
        <Card css={S.card}>
          <Card.Content heading="Default information">
            <S.CardContentWrapper>
              <div>
                <LabelContent label="Last name" direction="vertical" isRequired>
                  <Input
                    placeholder="Last name"
                    value={watch("lastName")}
                    hasError={!!errors.lastName?.message}
                    maxLength={50}
                    register={register("lastName")}
                  />
                </LabelContent>
                <LabelContent label="Middle name" direction="vertical">
                  <Input
                    placeholder="(Optional)"
                    maxLength={50}
                    value={watch("middleName")}
                    register={register("middleName")}
                  />
                </LabelContent>
                <LabelContent
                  label="First name"
                  direction="vertical"
                  isRequired
                >
                  <Input
                    placeholder="First name"
                    value={watch("firstName")}
                    maxLength={50}
                    hasError={!!errors.firstName?.message}
                    register={register("firstName")}
                  />
                </LabelContent>
              </div>
              {(errors.lastName?.message || errors.firstName?.message) && (
                <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
              )}
            </S.CardContentWrapper>
            <LabelContent
              css={S.labelContent}
              label="Phone number"
              direction="vertical"
              isRequired
            >
              <Input
                placeholder="Only numbers"
                value={watch("phone")}
                maxLength={PHONE_MAX_LENGTH}
                hasError={!!errors.phone?.message}
                register={register("phone", { setValueAs: onlyNumber })}
              />
              {errors.phone?.message && (
                <ErrMsg>{errors.phone?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent label="Linking" direction="vertical" isRequired>
              <TruckLinkAutoComplete
                name="Linked"
                driverId=""
                id=""
                placeholder="Search by plate number"
                maxLength={20}
                value={watch("linked")}
                hasError={!!errors.linked?.message}
                onSelect={handleSelectLink}
                register={register("linked", {
                  required: true,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue("linked", e.target.value);
                    clearErrors("truckId");
                    clearErrors("linked");
                  },
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                    if (!watch("truckId") && e.target.value) {
                      setError("linked", {
                        message: CARRIER_VALID_MSG.PLATE_NUMBER,
                      });
                    }
                  },
                })}
              />
              {errors.linked?.message && (
                <ErrMsg>{errors.linked?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
          </Card.Content>
          <Card.Content heading="Attachments">
            <LabelContent
              label="Driver's license"
              direction="vertical"
              isRequired
            >
              <FormFileInput
                placeholder="Upload driver’s license"
                hasErr={!!errors.license?.message}
                file={watch("license")}
                fileKey="license"
                register={register}
                handleDeleteFile={handleDelFile}
                handleInputChange={handleChangeAttachments}
              />
              {errors.license?.message && (
                <ErrMsg>{errors.license?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent
              label="Profile picture"
              direction="vertical"
              tooltip={PROFILE_TOOLTIP}
            >
              <FormFileInput
                placeholder="Upload driver profile picture"
                hasErr={!!errors.profile?.message}
                file={watch("profile")}
                fileKey="profile"
                register={register}
                handleDeleteFile={handleDelFile}
                handleInputChange={handleChangeAttachments}
              />
              <S.Desc>- {t(FILE_DESC)}</S.Desc>
            </LabelContent>
          </Card.Content>
          <Card.Content heading="ID and password">
            <LabelContent label="Login ID" direction="vertical" isRequired>
              <HideEmailPassword />
              <Input
                placeholder="Login ID"
                value={watch("loginId")}
                hasError={!!errors.loginId?.message}
                register={register("loginId")}
              />
              {errors.loginId?.message && (
                <ErrMsg>{errors.loginId?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Password"
              direction="vertical"
              isRequired
            >
              <Input
                type={isShowPassword ? "text" : "password"}
                placeholder="Password"
                value={watch("password")}
                hasError={!!errors.password?.message}
                register={register("password", {
                  onBlur: (e) => {
                    const value = e.target.value;
                    if (
                      !checkPasswordConfirm(value, watch("confirmPassword"))
                    ) {
                      setError("confirmPassword", {
                        message: CARRIER_VALID_MSG.PASSWORD_CONFIRM,
                      });
                    }
                  },
                })}
              />
              <S.PasswordShowBtn
                type="button"
                isShowPassword={isShowPassword}
                onClick={handleToggleShowPassword}
              >
                {isShowPassword ? <VisibleIcon /> : <UnvisibleIcon />}
              </S.PasswordShowBtn>
              {errors.password?.message && (
                <ErrMsg>{errors.password?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent
              css={S.labelContent}
              label="Confirm password"
              direction="vertical"
              isRequired
            >
              <Input
                type={isShowConfirmPassword ? "text" : "password"}
                placeholder="Confirm password"
                value={watch("confirmPassword")}
                hasError={!!errors.confirmPassword?.message}
                register={register("confirmPassword")}
              />
              <S.PasswordShowBtn
                type="button"
                isShowPassword={isShowConfirmPassword}
                onClick={handleToggleShowConfirmPassword}
              >
                {isShowConfirmPassword ? <VisibleIcon /> : <UnvisibleIcon />}
              </S.PasswordShowBtn>
              {errors.confirmPassword?.message && (
                <ErrMsg>{errors.confirmPassword?.message as Languages}</ErrMsg>
              )}
            </LabelContent>
          </Card.Content>
          <Card.Content heading="Account information">
            <LabelContent label="Name of bank" direction="vertical">
              <Input
                placeholder="Name of bank"
                value={watch("bankName") ?? ""}
                maxLength={100}
                register={register("bankName")}
              />
            </LabelContent>
            <LabelContent label="Branch" direction="vertical">
              <Input
                placeholder="Branch"
                maxLength={100}
                value={watch("bankBranch") ?? ""}
                register={register("bankBranch")}
              />
            </LabelContent>
            <LabelContent label="Account number" direction="vertical">
              <Input
                maxLength={PHONE_MAX_LENGTH}
                placeholder="Account number (6-15 characters)"
                value={watch("bankAccountNo") ?? ""}
                hasError={!!errors.bankAccountNo?.message}
                register={register("bankAccountNo", { setValueAs: onlyNumber })}
              />
              {errors.bankAccountNo?.message && (
                <ErrMsg>{errors.bankAccountNo.message as Languages}</ErrMsg>
              )}
            </LabelContent>
          </Card.Content>
          <S.AgreementProperty>
            <CheckboxBtn
              css={S.checkbtn}
              shape="rectangle"
              label="I consent to providing personal information to third parties."
              isChecked={!!watch("isProvideInfo")}
              register={register("isProvideInfo", {
                onChange: handleCheckAgree,
              })}
            />
            <CheckboxBtn
              css={S.checkbtn}
              shape="rectangle"
              label="I agree to the collection and use of personal information."
              isChecked={!!watch("isAgreeCollection")}
              register={register("isAgreeCollection", {
                onChange: handleCheckAgree,
              })}
            />
            {errors.agreement?.message && (
              <ErrMsg>{COMMON_VALID_MSG.REQUIRED}</ErrMsg>
            )}
          </S.AgreementProperty>
        </Card>
        <S.BtnWrapper>
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleClickCancel}
          />
          <Button
            isLoading={isCreateDriverLoading}
            isDisabled={Object.keys(errors).length > 0}
            variant="primaryMedium"
            label={"Add"}
            handleClickBtn={handleSubmitDriverCreate}
          />
        </S.BtnWrapper>
      </form>
    </S.Root>
  );
};

export default DriverCreate;
