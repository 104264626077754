import React from "react";
import { useTranslation } from "react-i18next";

import { Button, DetailModal, LabelContent, Table } from "@/components";
import { useModal, useToast } from "@/hooks";
import { filterTableColumns, findLookupTableLabel } from "@/utils";
import { PAYMENT_METHOD_RADIOS } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";

import { usePostSelectQuotation, usePatchSendQuotationReject } from "services";
import { formatTruckNumTypeOption, formatTrucksTableData } from "utils";
import { TRUCKLIST_TABLE_COLUMNS } from "assets";
import type { CompanyType, GetQuotationDetailServerModel } from "types";
import {
  QuotationSelectModal,
  QuotationRejectModal,
  QuotationRequoteModal,
} from "components/domain/quotation";
import * as S from "./QuotationDetailSelectModal.styled";

interface QuotationDetailSelectModalProps {
  offerId: string;
  quotationHistory: GetQuotationDetailServerModel<CompanyType>["history"][number];
  companyInfo: Record<string, string | number>;
  paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"];
  trucks?: GetQuotationDetailServerModel<
    "forwarder" | "forwardercarrier" | "shipper"
  >["history"][number]["trucks"];
}

const QuotationDetailSelectModal = React.forwardRef(
  (
    {
      offerId,
      quotationHistory,
      companyInfo,
      paymentMethod,
      trucks,
    }: QuotationDetailSelectModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    const { mutate: selectQuotationMutate, isLoading: isSelectLoading } =
      usePostSelectQuotation("shipper");
    const { mutate: quotationToLowerMutate, isLoading: isRejectLoading } =
      usePatchSendQuotationReject();

    const { modalRef, handleModalOpen, handleModalClose } = useModal();
    const { addToast } = useToast();

    const filteredColumns = filterTableColumns(TRUCKLIST_TABLE_COLUMNS);

    const payMethodLabel = findLookupTableLabel(
      PAYMENT_METHOD_RADIOS,
      paymentMethod,
    );

    if (!quotationHistory) return null;

    const tableTruckNumTypeOption = formatTruckNumTypeOption(trucks ?? {});

    const handleRejectQuotationSend = (offerId: string) => () => {
      quotationToLowerMutate(offerId, {
        onSuccess: () => {
          addToast(
            COMMON_TOAST_MSG.SUCCESS.CARRIER_REQUEST_QUOTATION_REJECT_DONE,
          );
          handleModalClose();
        },
        onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      });
    };

    const handleSend = (offerId: string) => () => {
      selectQuotationMutate(
        { id: offerId },
        {
          onSuccess: () => {
            addToast(COMMON_TOAST_MSG.SUCCESS.SEND_QUOTAIUON_DONE);
            handleModalClose();
          },
          onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
        },
      );
    };

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        type="submit"
        title="Quotation details"
        posBtnLabel="Select"
        closeBtnLabel="Reject"
        handleNegBtn={handleModalOpen(
          <QuotationRejectModal
            ref={modalRef}
            isLoading={isRejectLoading}
            handleReject={handleRejectQuotationSend(offerId)}
          />,
        )}
        callbackFn={handleModalOpen(
          <QuotationSelectModal
            ref={modalRef}
            isLoading={isSelectLoading}
            handleSelect={handleSend(offerId)}
          />,
        )}
      >
        <LabelContent
          css={S.labelContent}
          label="Trucking company"
          direction="horizontal"
          columnWidth={200}
        >
          <span>{companyInfo ? companyInfo.truckingCompany : "-"}</span>
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Payment method"
          direction="horizontal"
          columnWidth={200}
        >
          <S.PaymentMethodText>
            {payMethodLabel ? t(payMethodLabel) : "-"}
          </S.PaymentMethodText>
        </LabelContent>
        <LabelContent
          css={S.verticalLabelContent}
          label="Truck list"
          direction="vertical"
        >
          <Table
            css={S.table(tableTruckNumTypeOption.length)}
            title="Truck list"
            rowCount={tableTruckNumTypeOption.length}
          >
            <Table.Head columnList={TRUCKLIST_TABLE_COLUMNS} />
            <Table.Body css={S.tbody(tableTruckNumTypeOption.length > 0)}>
              {tableTruckNumTypeOption.length ? (
                tableTruckNumTypeOption.map((data, i) => (
                  <Table.Row key={i}>
                    {filteredColumns.map(({ key }) => (
                      <Table.Cell key={key}>
                        {formatTrucksTableData(key, data)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <Table.NoData />
              )}
            </Table.Body>
          </Table>
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Received price"
          direction="horizontal"
          columnWidth={200}
        >
          <S.PriceInfoWrapper>
            <div>
              {companyInfo ? companyInfo.receivedPrice : "-"}
              <span> ({t("VAT Included")})</span>
            </div>
            <Button
              variant="ghostPrimary"
              label="Requote"
              handleClickBtn={handleModalOpen(
                <QuotationRequoteModal ref={modalRef} offerId={offerId} />,
              )}
            />
          </S.PriceInfoWrapper>
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Memo"
          direction="horizontal"
          columnWidth={200}
        >
          <div>{quotationHistory.remarks ?? "-"}</div>
        </LabelContent>
      </DetailModal>
    );
  },
);

QuotationDetailSelectModal.displayName = "QuotationDetailSelectModal";

export default QuotationDetailSelectModal;
