import { css, Theme } from "@emotion/react";

export const formBtn = (theme: Theme) => css`
  ${theme.font.regular_14};
  column-gap: 8px;
  justify-content: flex-start;
  color: ${theme.color.gray_80};

  & > label {
    width: fit-content;
  }
`;
