import { atom } from "recoil";

import type { Toast } from "@/types";

const toastState = atom<[] | Toast[]>({
  key: "toastState",
  default: [],
});
const modalCompoState = atom<React.ReactNode[] | []>({
  key: "modalCompoState",
  default: [],
});

const isModalOpenState = atom({
  key: "isModalOpenState",
  default: false,
});

export { toastState, modalCompoState, isModalOpenState };
