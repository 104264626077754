import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { isEqual } from "lodash-es";

import { useAuth } from "@/contexts";
import { useToast } from "@/hooks";
import { deleteComma, formatCreateICTDate } from "@/utils";
import { COMMON_TOAST_MSG } from "@/constants";
import type { Address, FavoriteOrdersClientModel } from "@/types";

import { orderCreationSchema, ORDER_FORM } from "assets";
import type { OrderForm, CreateOrderQueryModel, CreateOrderBody } from "types";

const useOrderCreation = (isSuccessCreateOrderCreation: boolean) => {
  const { addToast } = useToast();

  const {
    auth: { clientType },
  } = useAuth();

  const formMethod = useForm<OrderForm>({
    defaultValues: ORDER_FORM,
    mode: "onTouched",
    resolver: yupResolver(orderCreationSchema),
  });

  const isChangeFormValue = !isEqual(
    {
      ...formMethod.getValues(),
      clientType,
      cargo: formMethod.getValues().cargo.map((item) => ({ ...item, id: "" })),
    },
    { ...ORDER_FORM, clientType, cargo: [{ ...ORDER_FORM.cargo[0], id: "" }] },
  );

  const checkDisabledDateCondition =
    (compare: dayjs.Dayjs | string) =>
    (selectedDate: dayjs.Dayjs): boolean => {
      return dayjs(selectedDate).isBefore(compare);
    };

  const deleteCalendarInput = (
    deleteKeys: ("pickUp.etd" | "dropOff.eta" | "dropOff.etd")[],
  ): void => {
    deleteKeys.forEach((deleteKey) => formMethod.setValue(deleteKey, []));
  };

  const handleSelectFavoriteOrder = ({
    pickUp,
    dropOff,
  }: FavoriteOrdersClientModel) => {
    const setFormValues = (
      formMethod: UseFormReturn<OrderForm>,
      location: Address,
      type: "pickUp" | "dropOff",
    ): void => {
      formMethod.setValue(type, {
        address: location.addr,
        addressDetail: location.addrDetail ?? "",
        buildingName: location.buildingName,
        coord: { lat: +location.lat, lng: +location.lng },
        city: location.city,
        phone: location.phone,
        charge: location.pic,
        eta: [],
      });
    };

    setFormValues(formMethod, pickUp, "pickUp");
    setFormValues(formMethod, dropOff, "dropOff");

    formMethod.clearErrors("pickUp");
    formMethod.clearErrors("dropOff");
  };

  function makeCreationOrderFormBody(
    clientType: "carrier",
    data: OrderForm,
  ): CreateOrderQueryModel<"carrier">;
  function makeCreationOrderFormBody(
    clientType: "forwarder",
    data: OrderForm,
  ): CreateOrderQueryModel<"forwarder">;
  function makeCreationOrderFormBody(
    clientType: "forwardercarrier",
    data: OrderForm,
  ): CreateOrderQueryModel<"forwardercarrier">;
  function makeCreationOrderFormBody(
    clientType: "shipper",
    data: OrderForm,
  ): CreateOrderQueryModel<"shipper">;
  function makeCreationOrderFormBody(
    clientType: "carrier" | "forwarder" | "forwardercarrier" | "shipper",
    data: OrderForm,
  ):
    | CreateOrderQueryModel<"carrier">
    | CreateOrderQueryModel<"forwarder">
    | CreateOrderQueryModel<"forwardercarrier">
    | CreateOrderQueryModel<"shipper"> {
    const cargoes = data.cargo.map((item) => ({
      cargoType:
        item.cargoType.key === "others"
          ? item.cargoOtherType!
          : item.cargoType.key,
      packagingType: item.packagingType.key,
      width: +item.size.depth,
      height: +item.size.height,
      length: +item.size.length,
      weight: +deleteComma(item.weight),
      cargoCnt: +deleteComma(item.quantity),
      hazardGoods: item.hazardGoods === "yes",
      doubleLoading: item.stackable === "yes",
    }));

    const commonBody: CreateOrderBody = {
      puAddr: data.pickUp.address,
      ...(data.pickUp.addressDetail && {
        puAddrDetail: data.pickUp.addressDetail,
      }),
      puLat: data.pickUp.coord.lat,
      puLng: data.pickUp.coord.lng,
      puName: data.pickUp.buildingName,
      puCity: data.pickUp.city,
      puPic: data.pickUp.charge,
      puPhone: data.pickUp.phone,
      puEta: formatCreateICTDate(data.pickUp.eta[0]),
      ...(data.pickUp.etd?.[0] && {
        puEtd: formatCreateICTDate(data.pickUp.etd?.[0]),
      }),
      doAddr: data.dropOff.address,
      ...(data.dropOff.addressDetail && {
        doAddrDetail: data.dropOff.addressDetail,
      }),
      doLat: data.dropOff.coord.lat,
      doLng: data.dropOff.coord.lng,
      doName: data.dropOff.buildingName,
      doCity: data.dropOff.city,
      doPic: data.dropOff.charge,
      doPhone: data.dropOff.phone,
      doEta: formatCreateICTDate(data.dropOff.eta[0]),
      ...(data.dropOff.etd?.[0] && {
        doEtd: formatCreateICTDate(data.dropOff.etd?.[0]),
      }),
      cargoes,
      loadingStatus: data.truckingMethods.loadingStatus,
      loadingMethod:
        data.truckingMethods.loadingMethod.key === "others"
          ? data.truckingMethods.loadingOtherMethod!
          : data.truckingMethods.loadingMethod.key,
      ...(data.truckingMethods.attachedFiles?.file1 && {
        file1: data.truckingMethods.attachedFiles?.file1?.[0],
      }),
      ...(data.truckingMethods.attachedFiles?.file2 && {
        file2: data.truckingMethods.attachedFiles?.file2?.[0],
      }),
      ...(data.truckingMethods.memo && {
        orderMemo: data.truckingMethods.memo.trim(),
      }),
      pay: data.payment.method,
      price: data.payment.subtotal ? +deleteComma(data.payment.subtotal) : 0,
      vat: +data.payment.tax,
      addFavorite: data.addFavorite,
    };

    switch (clientType) {
      case "carrier": {
        return {
          body: {
            ...commonBody,
            shippingCompanyId: data.companyInformation.company[0].id,
            trucks: data.truck.reduce((acc, { truckOption, truckNum }) => {
              acc[truckOption.key] = truckNum;
              return acc;
            }, {} as Record<string, number>),
          },
        };
      }
      case "forwarder": {
        return {
          body: {
            ...commonBody,
            shippingCompanyId: data.companyInformation.company[0].id,
            truckingCompanyId: data.companyInformation.truckingCompany.map(
              ({ id }) => id,
            ),
          },
        };
      }
      case "forwardercarrier": {
        const isCheckSelfTransport =
          data.companyInformation.isCheckSelfTransport;

        return {
          body: {
            ...commonBody,
            shippingCompanyId: data.companyInformation.company[0].id,
            isSelfTransport: isCheckSelfTransport,
            ...(isCheckSelfTransport
              ? {
                  trucks: data.truck.reduce<Record<string, number>>(
                    (acc, { truckOption, truckNum }) => {
                      acc[truckOption.key] = truckNum;
                      return acc;
                    },
                    {},
                  ),
                }
              : {
                  truckingCompanyId:
                    data.companyInformation.truckingCompany.map(({ id }) => id),
                }),
          },
        };
      }
      case "shipper": {
        return {
          body: {
            ...commonBody,
            truckingCompanyId: data.companyInformation.truckingCompany.map(
              ({ id }) => id,
            ),
          },
        };
      }
    }
  }

  const orderCreationMutateOption = {
    onSuccess: () => {
      addToast(COMMON_TOAST_MSG.SUCCESS.ORDER_CREATE_SELF_DONE);
      window.scrollTo(0, 0);
    },
    onError: () => {
      addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
    },
  };

  useEffect(() => {
    if (!clientType) return;

    formMethod.reset({ ...ORDER_FORM, clientType });
  }, [clientType]);

  // NOTE: API 성공 후 reset 이 잘 안 되어 로직 작성함
  useEffect(() => {
    if (!isSuccessCreateOrderCreation) return;

    formMethod.reset({ ...ORDER_FORM, clientType });
  }, [isSuccessCreateOrderCreation]);

  return {
    formMethod,
    isChangeFormValue,
    checkDisabledDateCondition,
    deleteCalendarInput,
    makeCreationOrderFormBody,
    orderCreationMutateOption,
    handleSelectFavoriteOrder,
  };
};

export default useOrderCreation;
