import { css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

import { DETAIL_TRUCKLIST_TABLE_CELL_SIZES } from "assets";

export const table = (rowCount: number) => css`
  margin: 24px 0 0;

  tr {
    grid-template-columns: ${DETAIL_TRUCKLIST_TABLE_CELL_SIZES};

    &:nth-of-type(${rowCount}) {
      border-bottom: ${rowCount >= 5 && "0"};
    }
  }
`;

export const tbody = (isEmpty: boolean) => css`
  ${mixin_scrollbar};
  display: ${isEmpty && "block"};
  height: 204px;
`;
