import { COMMON_VALID_MSG } from "@/constants";

const VALID_MSG = {
  CARGO_SIZE: "Please enter a valid cargo size.",
  CONTAINER_SIZE: "Please enter a valid container size.",
  PHONE: "Please enter a vaild phone number.",
  NUMBER_OF_TRUCK: "Please write at least 1+",
  ADDRESS: "Please select an address in the list.",
  LINKEDTRUCK: "Please select a vaild truck.",
  DUPLICATE_EMAIL: "This ID is already taken.",
  PLATE_NUMBER_EXISTS: "Plate number already exists",
  VIN: "Please enter a valid VIN.",
  PHONE_EXISTS: "Phone number already exists.",
  ID_EXISTS: "This ID is already taken.",
  PLATE_NUMBER: "Please enter a valid plate number",
  DRIVER_NAME: "Please enter a valid driver name",
  ORDER_NUMBER: "Please enter a valid order number",
  EXIST_ORDER_NUMBER: "Order number is not checked.",
  ACCOUNT_NUMBER: "Account number length is 6-15 characters.",
  INVALID_ORDERNUMBER: "Order number is not checked.",
} as const;

export const CARRIER_VALID_MSG = {
  ...VALID_MSG,
  ...COMMON_VALID_MSG,
};
