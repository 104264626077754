import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const changeBtn = css`
  margin-left: 8px;
`;

export const PasswordContent = styled.div`
  display: flex;
`;
