import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { PATH } from "@/assets";
import { COMMON_VALID_MSG } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import { useCreatePropertyTruck } from "services";
import { CARRIER_VALID_MSG, CARRIER_TOAST_MSG } from "constants/index";
import type { AutoCompleteDropdown, TruckForm } from "types";

const schema = yup.object({
  plateNumber: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
  parkedGarage: yup
    .mixed<DropdownOptionType>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => !!value?.key),
  autoCompletes: yup.mixed<AutoCompleteDropdown[]>().defined(),
  driverId: yup.mixed<(string | undefined)[]>().defined(),
  truckType: yup
    .mixed<DropdownOptionType>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => !!value?.key),
  truckOption: yup
    .mixed<DropdownOptionType>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => !!value?.key),
  size: yup.object({
    length: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;

        return +value <= 13.56;
      }),
    depth: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;
        return +value <= 2.35;
      }),
    height: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("notZero", COMMON_VALID_MSG.NOT_ZERO, (value) => value !== "0")
      .test("maxValue", COMMON_VALID_MSG.MAX_VALUE_LIMIT, (value) => {
        if (!value) return true;

        return +value <= 2.7;
      }),
  }),
  brand: yup.string().defined(),
  model: yup.string().defined(),
  year: yup.mixed<DropdownOptionType>().defined(),
  vin: yup
    .string()
    .defined()
    .test("checkVin", CARRIER_VALID_MSG.VIN, (value) => value.length === 17)
    .required(CARRIER_VALID_MSG.REQUIRED),
  registration: yup
    .mixed<FileList>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .nullable()
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => {
      return value instanceof FileList && value.length > 0;
    }),
  truckPhoto: yup.mixed<FileList>().defined().nullable(),
});

export const TRUCK_FORM_INIT_STATE = {
  plateNumber: "",
  parkedGarage: { key: "", label: "" as Languages },
  autoCompletes: [{ name: "", id: "" }],
  driverId: [undefined],
  registration: null,
  truckPhoto: null,
  truckType: { key: "", label: "" as Languages },
  truckOption: { key: "", label: "" as Languages },
  size: {
    length: "",
    depth: "",
    height: "",
  },
  brand: "",
  model: "",
  year: {
    key: `${new Date().getFullYear()}`,
    label: `${new Date().getFullYear()}` as Languages,
  },
  vin: "",
};

const useTruckCreateForm = () => {
  const navigate = useNavigate();

  const formMethod = useForm<TruckForm>({
    defaultValues: TRUCK_FORM_INIT_STATE,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { mutate: createTruckMutate, isLoading: isCreateTruckLoading } =
    useCreatePropertyTruck();

  const { addToast } = useToast();

  const createTruck = (data: TruckForm): void => {
    if (!data.registration) return;

    const req = {
      registration: data.registration[0],
      ...(data.truckPhoto && { truckPhoto: data.truckPhoto[0] }),
      body: {
        plateNumber: data.plateNumber,
        garageId: data.parkedGarage.key || null,
        driverIds: data.autoCompletes
          .filter((item) => !!item.id)
          .map((item) => item.id),
        truckOptionId: +data.truckOption.key, // TODO: 서버 필수값 설정에 따라 수정필요
        width: data.size.depth ? +data.size.depth : null,
        length: data.size.length ? +data.size.length : null,
        height: data.size.height ? +data.size.height : null,
        brand: data.brand ? data.brand : null,
        model: data.model ? data.model : null,
        year: +data.year.key,
        vin: data.vin,
      },
    };

    createTruckMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.TRUCK_ADD_DONE);
        navigate(`${PATH.PROPERTY}?tab=truck`);
      },
      onError: (err) => {
        switch (err.response?.data.response) {
          case "PLATE_NUMBER_EXISTS":
            formMethod.setError("plateNumber", {
              message: CARRIER_VALID_MSG.PLATE_NUMBER_EXISTS,
            });
            formMethod.setFocus("plateNumber");
            return;

          case "VIN_EXISTS":
            formMethod.setError("vin", { message: CARRIER_VALID_MSG.VIN });
            formMethod.setFocus("vin");
            return;

          default:
            addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        }
      },
    });
  };

  return {
    isCreateTruckLoading,
    createTruck,
    formMethod,
  };
};

export default useTruckCreateForm;
