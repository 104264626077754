type cardAnimateType = {
  height: "auto" | number;
  overflow?: string;
};

export const foldCardAnimate = (
  isFold: boolean,
): {
  open: cardAnimateType;
  close: cardAnimateType;
} => {
  return isFold
    ? {
        open: { height: "auto" },
        close: { height: 0, overflow: "hidden" },
      }
    : {
        open: { height: "auto" },
        close: { height: "auto" },
      };
};
