export const checkIdValidation = (step = 1, id: string) => {
  if (step === 1) {
    const checkId = /^[a-zA-Z]/;

    return checkId.test(id);
  } else if (step === 2) {
    const checkId = /^[a-zA-Z]{1}[a-zA-Z0-9]{0,}$/;

    return checkId.test(id);
  } else {
    const checkId = /^[a-zA-Z][a-zA-Z0-9]{5,15}$/;

    return checkId.test(id);
  }
};
