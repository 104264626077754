import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContentTable = css`
  width: 764px;
`;

export const externalLink = (theme: Theme) => css`
  & > svg > g > path {
    fill: ${theme.color.primary_40};
  }

  &:hover svg > g > path {
    fill: ${theme.color.primary_50};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
