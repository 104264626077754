/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useCallback } from "react";
import { QueryKey } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { queryClient } from "@/services/queryClient";
import { comma, formatICTDateTime } from "@/utils/formatter"; // TODO: Import 에러로 임시적 조치 (추후 수정 필요)
import { ResetIcon, DownloadIcon } from "@/assets";
import type { Languages } from "@/types";

import { Button } from "../button";
import Table from "../table/table/Table";
import CheckTable from "../table/check/CheckTable";
import * as S from "./DataSection.styled";

interface DataSectionProps {
  className?: string;
  children: React.ReactNode;
  totalData: number | null;
  activeBtns?: React.ReactNode;
  dataUpdatedAt: number | null;
  refetch: () => void;
  title?: Languages;
  queryKey?: QueryKey; // TODO: 추후 API 연동 완료 후 필수값으로 변경해야함
}

interface ActiveWrapperProps {
  className?: string;
  activeBtn: React.ReactNode;
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  handleConfirm?: (e: React.MouseEvent) => void;
  handleUnActiveCheckTable?: () => void;
}

interface ExcelDownloadBtnProps {
  className?: string;
  isDisabled?: boolean;
  handleDownload: () => void;
}

export interface ActiveBtnProps {
  className?: string;
  label: Languages;
  variant?: "primaryMedium" | "outlineMedium";
  isDisabled?: boolean;
  handleClickActiveBtn: () => void;
}

const DataSection = ({
  children,
  className,
  totalData,
  activeBtns,
  dataUpdatedAt,
  refetch,
  queryKey,
  title = "List",
}: DataSectionProps) => {
  const { t } = useTranslation();

  const handleRefetch = useCallback(() => {
    queryKey && queryClient.removeQueries({ queryKey, exact: true });
    refetch && refetch();
  }, [queryKey, refetch]);

  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>
            {`${t(title)}`}&#32;(
            {totalData ? comma(`${totalData}`) : "-"})
          </h2>
          <S.Refetch>
            <span>{t("Latest update")}:</span>
            <time>
              {dataUpdatedAt
                ? formatICTDateTime(dataUpdatedAt, "DD/MM/YYYY, HH:mm")
                : "-"}
            </time>
            {dataUpdatedAt && (
              <S.RefetchBtn type="button" onClick={handleRefetch}>
                <ResetIcon css={S.resetIcon} />
              </S.RefetchBtn>
            )}
          </S.Refetch>
        </S.LeftContent>
        {activeBtns}
      </S.Header>
      {children}
    </section>
  );
};

DataSection.ActiveWrapper = function ActiveWrapper({
  className,
  activeBtn,
  isActive,
  isLoading,
  isDisabled,
  handleConfirm,
  handleUnActiveCheckTable,
}: ActiveWrapperProps) {
  return (
    <S.ActiveWrapper className={className}>
      {isActive ? (
        <>
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleUnActiveCheckTable}
          />
          <Button
            isLoading={isLoading}
            isDisabled={!isDisabled}
            variant="primaryMedium"
            label="Confirm"
            handleClickBtn={handleConfirm}
          />
        </>
      ) : (
        <>{activeBtn}</>
      )}
    </S.ActiveWrapper>
  );
};

DataSection.ExcelDownloadBtn = function ExcelDownloadBtn({
  handleDownload,
  isDisabled,
}: ExcelDownloadBtnProps) {
  return (
    <Button
      icon={<DownloadIcon css={S.downloadIcon} />}
      isDisabled={isDisabled}
      variant="outlineMedium"
      label="Excel download"
      handleClickBtn={handleDownload}
    />
  );
};

DataSection.ActiveBtn = function CheckTableActiveBtn({
  className,
  label,
  variant = "outlineMedium",
  isDisabled = false,
  handleClickActiveBtn,
}: ActiveBtnProps) {
  return (
    <Button
      className={className}
      variant={variant}
      label={label}
      isDisabled={isDisabled}
      handleClickBtn={handleClickActiveBtn}
    />
  );
};

DataSection.Table = Table;

DataSection.CheckTable = CheckTable;

export default DataSection;
