import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

import { Button, Loading, PageBackHeader, Tooltip } from "@/components";
import { useCustomParams, useDragDrop } from "@/hooks";
import { PATH, QuestionIcon, NoSelectIcon } from "@/assets";

import { RouteCard } from "components";
import type {
  GetAllocationDriverDetailServerModel,
  GetAllocationDetailDriverServerItem,
  AllocationRoute,
} from "types";
import SelectedAllocationCard from "../selectedCard/SelectedAllocationCard";
import * as S from "./DetailLeft.styled";

interface DetailLeftProps {
  selectedAllocationDriver:
    | GetAllocationDriverDetailServerModel
    | GetAllocationDetailDriverServerItem
    | null;
  isUpdateAllocationLoading: boolean;
  allocations: AllocationRoute[];
  isNeedDriverEdit: boolean;
  driverDetail?: GetAllocationDriverDetailServerModel;
  handleDragDrop: (dragTargetId: string, dropTargetId: string) => void;
  changeMemo: (id: string, memo: string) => void;
  changeSelectedAllocationEdit?: (
    allocation: GetAllocationDetailDriverServerItem,
  ) => void;
  changeSelectedAllocationCreate?: (
    allocation: GetAllocationDriverDetailServerModel,
  ) => void;
  handleSubmit: () => void;
  handleClickOpen?: () => void;
}

const DetailLeft = ({
  selectedAllocationDriver,
  isUpdateAllocationLoading,
  allocations,
  isNeedDriverEdit,
  driverDetail,
  handleDragDrop,
  changeMemo,
  changeSelectedAllocationEdit,
  changeSelectedAllocationCreate,
  handleSubmit,
  handleClickOpen,
}: DetailLeftProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { id: allocationId } = useCustomParams();
  const {
    isMute,
    draggabledId,
    dragTargetId,
    dropTargetId,
    handleSetDraggabledId,
    handleDragStart,
    handleDragEnd,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  } = useDragDrop(handleDragDrop);

  const pageType = location.pathname.includes("edit") ? "edit" : "create";

  return (
    <>
      <PageBackHeader
        title={pageType === "edit" ? "Edit allocation" : "Allocate"}
        path={
          pageType === "edit"
            ? `${PATH.ALLOCATION}?tab=ongoing&id=${allocationId}`
            : PATH.ALLOCATION
        }
      />
      {isEmpty(driverDetail) ? (
        <Loading />
      ) : (
        <S.Body onClick={handleClickOpen}>
          <SelectedAllocationCard
            selectedAllocationDriver={selectedAllocationDriver}
            isNeedDriverEdit={isNeedDriverEdit}
            isNeedTruckEdit
            changeSelectedAllocationEdit={changeSelectedAllocationEdit}
            changeSelectedAllocationCreate={changeSelectedAllocationCreate}
          />
          <S.Hr />
          <S.TitleWrapper>
            <h4>{t("Route")}</h4>
            <Tooltip
              css={S.tooltip}
              title="Change the route"
              tooltipBtnContent={QuestionIcon}
              position="BOTTOM_RIGHT"
            >
              {t(
                "You can change the route by drag and drop.\nTap the box and move it!",
              )}
            </Tooltip>
          </S.TitleWrapper>
          <S.Wrapper>
            {allocations.length ? (
              <S.RouteWrapper onDragOver={handleDragOver} onDrop={handleDrop}>
                {allocations.map((allocation) => {
                  const { id } = allocation;

                  return (
                    <RouteCard
                      key={id}
                      data={allocation}
                      isNeedMemo
                      needDragDrop
                      isMute={isMute}
                      dropArea={dropTargetId === id}
                      isLifted={dragTargetId === id}
                      draggable={draggabledId === id}
                      onDragStart={handleDragStart(id)}
                      onDragEnd={handleDragEnd(id)}
                      onDragEnter={handleDragEnter(id)}
                      onDragLeave={handleDragLeave(id)}
                      onMouseDown={handleSetDraggabledId(id)}
                      onMouseUp={handleSetDraggabledId("")}
                      changeMemo={changeMemo}
                    />
                  );
                })}
              </S.RouteWrapper>
            ) : (
              <S.EmptyBox>
                <NoSelectIcon css={S.noSelectIcon} />
                <div>
                  {t("No orders have been selected.\nPlease select an order.")}
                </div>
              </S.EmptyBox>
            )}
          </S.Wrapper>
        </S.Body>
      )}
      <S.BtnWrapper>
        <Button
          css={S.button}
          type="submit"
          variant="primaryLarge"
          isLoading={isUpdateAllocationLoading}
          isDisabled={!allocations.length}
          label="Complete"
          handleClickBtn={handleSubmit}
        />
      </S.BtnWrapper>
    </>
  );
};

export default DetailLeft;
