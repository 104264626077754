import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const baseModal = css`
  padding: 24px 32px;
`;

export const Header = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const button = (theme: Theme) => css`
  position: absolute;
  top: 5px;
  right: 0;

  svg {
    margin: 0;

    & > path {
      fill: ${theme.color.black};
    }
  }

  &:not(:disabled):hover {
    background-color: inherit;
  }
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 4px;
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;

export const Body = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
    padding-top: 16px;
  `}
`;
