import React, { forwardRef } from "react";

import { ErrMsg } from "@/components";

import * as S from "./ErrInput.styled";

interface InputProps {
  name: any;
  err: any;
  rest: any;
}

const Input = forwardRef(({ name, err, ...rest }: InputProps, ref: any) => {
  return (
    <S.Root>
      <S.InputBox
        name={name}
        id={name}
        isErr={err || false}
        ref={ref}
        {...rest}
      />
      <ErrMsg css={S.errMsg}>{err}</ErrMsg>
    </S.Root>
  );
});

Input.displayName = "Input";

export default Input;
