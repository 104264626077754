import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Profile, Table } from "@/components";
import { comma, filterTableColumns, makeNameString } from "@/utils";

import { useGetPropertyGarageDetail } from "services";
import { GARAGE_USAGE_TABLE_COLUMNS } from "assets";
import type { GarageTruckServerItem } from "types";
import * as S from "./GarageUsage.styled";

interface GarageUsageProps {
  selectedGarageId: string;
}

const GarageUsage = ({ selectedGarageId }: GarageUsageProps) => {
  const { t } = useTranslation();

  const filteredColumns = filterTableColumns(GARAGE_USAGE_TABLE_COLUMNS);
  const { isLoading, data, refetch } = useGetPropertyGarageDetail(
    selectedGarageId,
    { enabled: !!selectedGarageId },
  );

  const columnValue = (
    key: (typeof filteredColumns)[number]["key"],
    data: GarageTruckServerItem,
  ) => {
    switch (key) {
      case "linkedDriver": {
        const driver = data.drivers[0];

        const count = data.drivers.length;
        const name = makeNameString(
          driver?.lastName,
          driver?.middleName,
          driver?.firstName,
        );

        return (
          <S.Driver hasName={!!name}>
            {name ? (
              <>
                <Profile src={driver.profileUrl ?? ""} />
                <span>{name}</span>
              </>
            ) : (
              <span>-</span>
            )}
            {<S.Count>({count ?? 0})</S.Count>}
          </S.Driver>
        );
      }

      default:
        return <span>{data[key]}</span>;
    }
  };

  useEffect(() => {
    if (!selectedGarageId) return;

    refetch();
  }, [selectedGarageId]);

  return (
    <S.Root>
      <S.TitleWrapper>
        <h4>{data?.name ? data?.name : t("Garage usage")}</h4>
        <span>{`(${comma(data?.trucks.length ?? 0)})`}</span>
      </S.TitleWrapper>
      <Table
        css={S.table}
        isLoading={!!selectedGarageId && isLoading}
        title="garage usage list"
        aria-rowcount={data?.trucks.length}
        rowCount={data?.trucks.length ?? 0}
      >
        <Table.Head columnList={GARAGE_USAGE_TABLE_COLUMNS} />
        <Table.Body css={S.body}>
          {data?.trucks.length ? (
            data?.trucks.map((rowData) => (
              <Table.Row key={rowData.truckId}>
                {filteredColumns.map(({ key }) => (
                  <Table.Cell key={key}>
                    <span>{columnValue(key, rowData)}</span>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.NoData />
          )}
        </Table.Body>
      </Table>
    </S.Root>
  );
};

export default GarageUsage;
