import { atom, selector } from "recoil";

import { clientLanguage } from "@/stores";
import { CARGO_TYPE, LOADING_METHOD_TYPE, PACKAGING_TYPE } from "@/assets";

import { convertLanguagesType } from "utils";
import type { TrucksType, TrucksTypeOptionServerModel } from "types";

const truckTypeOptionsState = atom<TrucksTypeOptionServerModel[] | []>({
  key: "truckTypeOptionsState",
  default: [],
});

const truckTypeOptionsSelector = selector({
  key: "truckTypeOptionsSelector",
  get: ({ get }) => {
    // NOTE: [] | : TrucksTypeOptionServerModel[]로 추론되기
    const truckOptionTypes: TrucksTypeOptionServerModel[] = get(
      truckTypeOptionsState,
    );

    const truckTypes = truckOptionTypes.filter(
      (trucks, i) =>
        truckOptionTypes.findIndex(
          (truck) => trucks.truckTypeId === truck.truckTypeId,
        ) === i,
    );

    const truckOptions = truckOptionTypes.reduce((acc: TrucksType, truck) => {
      if (!acc[truck.truckTypeId]) {
        acc[truck.truckTypeId] = [];
      }

      acc[truck.truckTypeId].push(truck);

      return acc;
    }, {});

    const language = get(clientLanguage);

    const commonDropdownData = {
      truckType: Object.values(truckOptionTypes).map((value) => ({
        key: `${value.truckTypeId}`,
        label: value[convertLanguagesType(language, "type")],
      })),
      truckOption: Object.values(truckOptionTypes).map((value) => ({
        key: `${value.truckOptionId}`,
        label: value[convertLanguagesType(language, "option")],
      })),
      cargoType: CARGO_TYPE,
      packagingType: PACKAGING_TYPE,
      loadingMethod: LOADING_METHOD_TYPE,
    };

    return { truckTypes, truckOptions, commonDropdownData };
  },
});

export { truckTypeOptionsState, truckTypeOptionsSelector };
