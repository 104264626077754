import { ax } from "apis/axios";
import { getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded } from "utils";
import type {
  CompanyType,
  GetOrderAllocationQueryModel,
  GetOrderAllocationServerModel,
  GetOrderManagementCanceledQueryModel,
  GetOrderManagementCanceledServerModel,
  GetOrderManagementCompletedQueryModel,
  GetOrderManagementCompletedServerModel,
  GetOrderManagementShippingQueryModel,
  GetOrderManagementShippingServerModel,
  GetOrderManagementDetailServerModel,
  OrderManagementDetailTrackingHistoryQueryModel,
  OrderManagementDetailTrackingHistoryServerModel,
  UpdateOrderManagementDetailQueryModel,
} from "types";

export const getOrderManagementAllocationAPI = async <T extends CompanyType>(
  req: GetOrderAllocationQueryModel<T>,
) => {
  const { data } = await ax.get<GetOrderAllocationServerModel<T>>(
    `/order/allocation`,
    { params: req.query },
  );

  return data;
};

export const getOrderManagementShippingAPI = async (
  req: GetOrderManagementShippingQueryModel,
) => {
  const { data } = await ax.get<GetOrderManagementShippingServerModel>(
    `/order/shipping`,
    { params: req.query },
  );

  return data;
};

export const getOrderManagementCompletedAPI = async (
  req: GetOrderManagementCompletedQueryModel,
) => {
  const { data } = await ax.get<GetOrderManagementCompletedServerModel>(
    `/order/completed`,
    { params: req.query },
  );

  return data;
};

export const getOrderManagementCanceledAPI = async (
  req: GetOrderManagementCanceledQueryModel,
) => {
  const { data } = await ax.get<GetOrderManagementCanceledServerModel>(
    `/order/canceled`,
    { params: req.query },
  );

  return data;
};

export const cancelOrderManagementAPI = (orderNumber: string) =>
  ax.delete(`/order/${orderNumber}`);

export const getOrderManagementDetailAPI = async (orderId: string) => {
  const { data } = await ax.get<GetOrderManagementDetailServerModel>(
    `/order/detail/${orderId}`,
  );

  const profileUrls: (string | undefined)[] = await Promise.all(
    data.allocationInfo.drivers.map((driver) => {
      driver.profileS3Url = "";
      if (driver.profile)
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(driver.profile, "profile"),
        );
    }),
  );

  profileUrls.forEach((profileUrl, idx) => {
    if (profileUrl) {
      data.allocationInfo.drivers[idx].profileS3Url = profileUrl;
    }
  });

  return data;
};

export const updateOrderManagementDetailAPI = (
  req: UpdateOrderManagementDetailQueryModel,
) => ax.put(`/order/${req.orderNumber}`, req.body);

export const getOrderManagementDetailTrackingHistoryAPI = async (
  req: OrderManagementDetailTrackingHistoryQueryModel,
) => {
  const { data } =
    await ax.get<OrderManagementDetailTrackingHistoryServerModel>(
      `/order/tracking/${req.orderId}`,
    );

  const profileUrls = await Promise.all(
    data.delivery.map(({ driverProfile }) => {
      if (driverProfile) {
        return getS3PresignedUrlAPI(
          addPrefixToUrlIfNeeded(driverProfile, "profile"),
        );
      } else {
        return "";
      }
    }),
  );

  data.delivery.forEach((delivery, i) => {
    delivery.driverProfile = profileUrls[i];
  });

  return data;
};
