import React from "react";
import { useTranslation } from "react-i18next";

import { badge } from "@/styles";
import type { Languages } from "@/types";

import * as S from "./Badge.styled";

interface BadgeProps {
  className?: string;
  content: Languages;
  variant: keyof typeof badge;
  icon?: React.SVGProps<SVGSVGElement>;
}

const Badge = ({ className, content, variant, icon }: BadgeProps) => {
  const { t } = useTranslation();

  return (
    <S.Badge className={className} variant={variant}>
      <>
        {icon && icon}
        {t(content)}
      </>
    </S.Badge>
  );
};

export default Badge;
