import React from "react";

import { QueryPagination } from "@/components";

import type { CompanyType, GetOrderAllocationServerModel } from "types";
import AllocationSection from "./section/AllocationSection";
import OrderAllocationFilter from "./filter/OrderAllocationFilter";
import * as S from "./OrderAllocation.styled";

interface OrderAllocationProps {
  data?: GetOrderAllocationServerModel<CompanyType>;
  dataUpdatedAt: number;
  refetch: () => void;
}

const OrderAllocation = ({
  data,
  dataUpdatedAt,
  refetch,
}: OrderAllocationProps) => {
  return (
    <>
      <OrderAllocationFilter />
      {data && (
        <>
          <AllocationSection
            data={data}
            dataUpdatedAt={dataUpdatedAt}
            refetch={refetch}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default OrderAllocation;
