import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const tab = (theme: Theme) => css`
  height: ${`calc(100% - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT})`};
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${theme.size.LIST_LAYOUT_HEADER_HEIGHT};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 0 20px;
  `}
`;

export const tabPanel = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const dropdown = (theme: Theme) => css`
  display: flex;
  align-items: center;
  width: max-content;

  > ul > li {
    border-bottom: 0;

    > button {
      color: ${theme.color.gray_80};
    }
  }

  & > button {
    column-gap: 4px;
    width: max-content;
    height: 24px;
    border: 0;
    padding: 0;

    &:focus,
    &[aria-expanded="true"] {
      border: 0;
    }
  }

  & > ul {
    flex-direction: column;
    min-width: 120px;
    width: max-content;

    & > li {
      display: flex;
      column-gap: 12px;
      /* NOTE: 베트남어 - 운송중일 때 글자수가 길어 꽉차는 현상 : 디자인 확인 필요 */
      background-color: ${theme.color.white};
    }
  }
`;

export const querySearhInput = (theme: Theme) => css`
  & > input {
    ${theme.font.regular_15};

    ::placeholder {
      ${theme.font.regular_15};
    }
  }
`;
