import { ax } from "apis/axios";
import type {
  GetOngoingDriverServerModel,
  GetOngoingDriverQueryModel,
  GetOngoingDriverDetailServerModel,
} from "types";

export const getOngoingDrivers = async (req: GetOngoingDriverQueryModel) => {
  const { data } = await ax.get<GetOngoingDriverServerModel>(
    "/allocation/inprogress",
    { params: req.query },
  );

  return data;
};

export const getOngoingDriverDetail = async (allocationId: string) => {
  const { data } = await ax.get<GetOngoingDriverDetailServerModel>(
    `/allocation/detail/${allocationId}`,
  );

  return data;
};
