import dayjs from "dayjs";
import { calcHourDiffICTWithUTC0 } from "./date";

export const formatGetTime = (time: string) => `${new Date(time).getTime()}`;

export const formatVstUtcYYYYMMDDHHmm = (date: string) =>
  dayjs(dayjs(date, "DD/MM/YYYY HH:mm"))
    .subtract(7, "h")
    .format("YYYY-MM-DD HH:mm");

export const formatStartVstUtc = (date: string) =>
  dayjs(date).subtract(7, "h").format("YYYY-MM-DD HH:mm:ss");

export const formatEndVstUtc = (date: string) =>
  dayjs(date).add(17, "h").format("YYYY-MM-DD HH:mm:ss");

export const formatPeriodICTToUtc = (localDate: string): string[] => {
  const [startDate, endDate] = localDate.split("~").map((date) => date.trim());

  const makeDate = (date: string, type: "startDate" | "endDate") => {
    const ICTDate = dayjs
      .utc(date, "DD/MM/YYYY")
      .subtract(calcHourDiffICTWithUTC0(), "h");

    return type === "startDate"
      ? ICTDate.toISOString()
      : ICTDate.add(1, "day").toISOString();
  };
  return [makeDate(startDate, "startDate"), makeDate(endDate, "endDate")];
};
