import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { button } from "@/styles";

interface ButtonProps {
  variant: keyof typeof button;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, variant }) => css`
    ${variant === "primaryXSmall" && theme.button.primaryXSmall};
    ${variant === "primarySmall" && theme.button.primarySmall};
    ${variant === "primaryMedium" && theme.button.primaryMedium};
    ${variant === "primaryLarge" && theme.button.primaryLarge};
    ${variant === "secondaryLarge" && theme.button.secondaryLarge};
    ${variant === "outlineXSmall" && theme.button.outlineXSmall};
    ${variant === "outlineMedium" && theme.button.outlineMedium};
    ${variant === "errorMedium" && theme.button.errorMedium};
    ${variant === "ghostPrimary" && theme.button.ghostPrimary};
    ${variant === "ghostGray" && theme.button.ghostGray};
    ${variant === "ghostRed" && theme.button.ghostRed};
    ${variant === "ghostNoUnderlineGray" && theme.button.ghostNoUnderlineGray};
    ${variant === "ghostNoUnderlinePrimary" &&
    theme.button.ghostNoUnderlinePrimary};
    ${variant === "icon" && theme.button.icon};
    ${variant === "text" && theme.button.text};
    ${variant === "textIcon" && theme.button.textIcon};
    ${variant === "tertiaryIconLarge" && theme.button.tertiaryIconLarge};
  `}
`;
`}
`;

export const LoadingSpinnerContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    visibility: hidden;
  }

  & > span {
    position: absolute;
  }
`;
