export const WEEKS = ["S", "M", "T", "W", "T", "F", "S"];
export const MONTH = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

export const TIME_EXPLANATION = "Based on Indochina time (ICT)";

export const DEFAULT_FILE = {
  id: "",
  name: "",
  option: null,
} as const;

export const PHONE_MAX_LENGTH = 15 as const;
