import { css, Theme } from "@emotion/react";

import { SETTING_SUPPORT_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${SETTING_SUPPORT_TABLE_CELL_SIZES};
  }
`;

export const tableHead = css`
  th {
    height: 32px;
  }
`;

export const tableBody = (theme: Theme) => css`
  height: ${theme.size.TABLE_HALF_BODY_HEIGHT};

  & > tr {
    &:nth-of-type(10) {
      border-bottom: 0;

      & > td {
        white-space: nowrap;
      }
    }
  }
`;
