import { ax } from "apis/axios";

import type {
  GetNoticeDetailServerModel,
  GetNoticesQueryModel,
  GetNoticesServerModel,
} from "types";

export const getNoticesAPI = async (req: GetNoticesQueryModel) => {
  const { data } = await ax.get<GetNoticesServerModel>("/setting/notice", {
    params: req.query,
  });

  return data;
};

export const getNoticeDetailAPI = async (noticeId: string) => {
  const { data } = await ax.get<GetNoticeDetailServerModel>(
    `/setting/notice/${noticeId}`,
  );

  return data;
};
