import { ax } from "apis/axios";
import type {
  CompanyType,
  GetQuotationCanceledQueryModel,
  GetQuotationCanceledServerModel,
  GetQuotationCompletedQueryModel,
  GetQuotationCompletedServerModel,
  GetQuotationOngoingQueryModel,
  GetQuotationOngoingServerModel,
  GetQuotationPendingQueryModel,
  GetQuotationPendingServerModel,
} from "types";

export const getQuotationsPendingAPI = async (
  req: GetQuotationPendingQueryModel,
) => {
  const { data } = await ax.get<GetQuotationPendingServerModel>(
    "/offer/pending",
    { params: req.query },
  );

  return data;
};

export const getQuotationsOngoingAPI = async <T extends CompanyType>(
  req: GetQuotationOngoingQueryModel<T>,
) => {
  const { data } = await ax.get<GetQuotationOngoingServerModel<T>>(
    "/offer/ongoing",
    { params: req.query },
  );

  return data;
};

export const getQuotationsCompletedAPI = async <T extends CompanyType>(
  req: GetQuotationCompletedQueryModel,
) => {
  const { data } = await ax.get<GetQuotationCompletedServerModel<T>>(
    "/offer/completed",
    { params: req.query },
  );

  return data;
};

export const getQuotationsCanceledAPI = async <T extends CompanyType>(
  req: GetQuotationCanceledQueryModel,
) => {
  const { data } = await ax.get<GetQuotationCanceledServerModel<T>>(
    "/offer/canceled",
    { params: req.query },
  );

  return data;
};
