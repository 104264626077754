import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { auth } from "@/contexts";
import {
  CheckboxBtn,
  ErrMsg,
  LabelContent,
  SelectCompanyBtn,
  TruckingCompanySearchModal,
} from "@/components";
import { useModal } from "@/hooks";
import { TRUCKING_COMPANY_COLUMNS } from "@/assets";
import type { CompanyInfo, Languages } from "@/types";

import { useGetLinkPlayer } from "services";
import type { GetQuotationPendingClientModel, QuotationSendForm } from "types";
import * as S from "./SendQuotationTruckingCompany.styled";

interface SendQuotationTruckingCompanyProps {
  selectedOrders: GetQuotationPendingClientModel["pending"];
}

const SendQuotationTruckingCompany = ({
  selectedOrders,
}: SendQuotationTruckingCompanyProps) => {
  const { data: carrierLinkPlayers } = useGetLinkPlayer({
    query: { type: "carrier" },
    //TODO: 화주가 이미 요청한 운송사는 비활성화 처리되도록 수정 -> orderId(현재 string)가 복수인 경우 서버 확인 요청
  });

  const { modalRef, handleModalOpen } = useModal();

  const {
    control,
    watch,
    formState: { errors },
    register,
  } = useFormContext<QuotationSendForm>();

  return (
    <S.Wrapper>
      <LabelContent
        label="Trucking company"
        isRequired
        direction="horizontal"
        columnWidth={200}
      >
        <S.SelectWrapper>
          {carrierLinkPlayers && (
            <SelectCompanyBtn
              css={S.selectCompanyBtn(!!errors.companyIds?.message)}
              label="Please select company to request quotation"
              isDisabled={watch("isCheckSelfTransport")}
              selectedCompany={watch("companyIds")}
              handleClickBtn={handleModalOpen(
                <Controller
                  control={control}
                  name="companyIds"
                  render={({ field: { value, onChange } }) => {
                    const handleCompanySelect = (
                      companyInfos: CompanyInfo[],
                    ): void => onChange(companyInfos);

                    return (
                      <TruckingCompanySearchModal
                        ref={modalRef}
                        columns={TRUCKING_COMPANY_COLUMNS}
                        selectedCompany={value}
                        tableDatas={carrierLinkPlayers}
                        handleCompanySelect={handleCompanySelect}
                      />
                    );
                  }}
                />,
              )}
            />
          )}
          {auth.clientType === "forwardercarrier" && (
            <CheckboxBtn
              css={S.checkbox}
              shape="rectangle"
              isChecked={watch("isCheckSelfTransport")}
              label="Transport the cargo by myself."
              register={register("isCheckSelfTransport")}
            />
          )}
          {errors.companyIds?.message && (
            <ErrMsg>{errors.companyIds.message as Languages}</ErrMsg>
          )}
        </S.SelectWrapper>
      </LabelContent>
    </S.Wrapper>
  );
};

export default SendQuotationTruckingCompany;
