import axios from "axios";

import type { RenewAccessTokenServerModel } from "@/types";

export const renewAccessTokenAPI = async (refreshToken: string) => {
  const { data } = await axios.get<RenewAccessTokenServerModel>(
    `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
    { headers: { Authorization: `Bearer ${refreshToken}` } },
  );
  return data;
};
