import { css, Theme } from "@emotion/react";

export const content = css`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const checkbox = (theme: Theme) => css`
  ${theme.font.regular_14};
  justify-content: flex-start;
  column-gap: 8px;

  & > label {
    color: ${theme.color.gray_70};

    &:first-of-type {
      width: fit-content;
    }
  }
`;

export const selectCompanyBtn = (theme: Theme) => css`
  &:disabled {
    opacity: inherit;
    border: 1px solid ${theme.color.gray_35};
    background-color: ${theme.color.gray_10};
    cursor: not-allowed;
  }
`;
