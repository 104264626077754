import React from "react";
import { useTranslation } from "react-i18next";

import { Loading, SettingLayout } from "@/components";
import { useToggle } from "@/hooks";

import { ReadField, UpdateField } from "components";
import { useGetCompanyInfo } from "services";
import { SETTING_MENU } from "assets";
import * as S from "./Company.styled";

const Company = () => {
  const { t } = useTranslation();

  const [isEdit, handleIsEdit] = useToggle();

  const { data } = useGetCompanyInfo(false);

  const Field = isEdit ? (
    <UpdateField handleChangeReadField={handleIsEdit} />
  ) : (
    <ReadField handleChangeUpdateField={handleIsEdit} />
  );

  if (!data) {
    return <Loading />;
  }

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <section>
        <h3 className="a11y">company</h3>
        <article>
          <S.Title>{t("Default information")}</S.Title>
          {Field}
        </article>
      </section>
    </SettingLayout>
  );
};

export default Company;
