import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_hr, mixin_list_layout_detail_body } from "@/styles";

export const OngoingHeader = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 20px;
  `}
`;

export const H2 = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
  `}
`;

export const Body = styled.div`
  ${mixin_list_layout_detail_body};
`;

export const Hr = styled.hr`
  ${mixin_hr};
`;

export const menuBtn = (theme: Theme) => css`
  position: static;

  & > button {
    width: 28px;
    height: 28px;
    border-radius: 4px;

    :hover {
      background-color: ${theme.color.gray_10};
    }
  }

  > dialog > div {
    right: -46px;
    padding: 0;

    > ul > li {
      > button,
      > a {
        ${theme.font.regular_14};
      }
    }
  }
`;
