import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  QuotationDefaultInformation,
  QuotationPickUpInformation,
  QuotationDropOffInformation,
  QuotationCargoDetails,
  QuotationTruckingMethods,
  QuotationPayment,
  QuotationTruckForm,
  QuotationTruckList,
  QuotationOfferPrice,
  QuotationDetailLayout,
} from "components";
import { useGetQuotationDetail } from "services";
import useQuotationDetail from "./hooks/useQuotationDetail";

const CarrierQuotationDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetQuotationDetail<"carrier">({
    suspense: false,
    quotationId: id!,
  });

  const { formMethod, handleRejectModalOpen, handleSubmit } =
    useQuotationDetail(data);

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const contents = [
    {
      heading: { text: "Default information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDefaultInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Pick-up information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationPickUpInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Drop-off information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDropOffInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: `${t("Cargo details")} (${data.cargoes.length})` },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationCargoDetails data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Trucking methods" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTruckingMethods data={data} />
        </Suspense>
      ),
    },
    data.defaultInfo.pageStatus === "ONGOING" &&
    data.defaultInfo.offerStatus !== "DELIVERED"
      ? {
          heading: { text: "Trucks" },
          content: (
            <Suspense fallback={<Fragment />}>
              <QuotationTruckForm />
            </Suspense>
          ),
        }
      : {
          heading: {
            text: `${t("Truck list")} (${
              data.trucks ? Object.keys(data.trucks).length : 0
            })`,
          },
          content: (
            <Suspense fallback={<Fragment />}>
              <QuotationTruckList trucks={data.trucks} />
            </Suspense>
          ),
        },
    data.defaultInfo.pageStatus === "ONGOING" &&
    data.defaultInfo.offerStatus !== "DELIVERED" &&
    data.paymentInfo.price === 0
      ? {
          heading: { text: "Offer price" },
          content: (
            <Suspense fallback={<Fragment />}>
              <QuotationOfferPrice
                data={data}
                handleReject={handleRejectModalOpen}
                handleCheckValidForm={handleSubmit}
              />
            </Suspense>
          ),
        }
      : {
          heading: { text: "Payment" },
          content: (
            <Suspense fallback={<Fragment />}>
              <QuotationPayment
                data={data}
                handleReject={handleRejectModalOpen}
                handleCheckValidForm={handleSubmit}
              />
            </Suspense>
          ),
        },
  ];

  return (
    <FormProvider {...formMethod}>
      <QuotationDetailLayout
        contents={contents}
        pageStatus={data.defaultInfo.pageStatus}
      />
    </FormProvider>
  );
};

export default CarrierQuotationDetail;
