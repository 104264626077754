import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const Root = styled.section`
  width: 564px;
  margin: 0 auto;
  padding-top: 33px;
  padding-bottom: 40px;
`;

export const pageBackHeader = (theme: Theme) => css`
  border: 0;
  padding: 0;
  padding-bottom: 12px;
  background-color: inherit;

  span {
    ${theme.font.bold_18};
  }
`;

export const card = css`
  width: 564px;

  & > div > div:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;
