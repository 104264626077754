import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { checkPhoneNum } from "@/utils";
import { MEMBER_GRADE_OPTIONS, PATH } from "@/assets";
import { COMMON_VALID_MSG, MEMBER_GRADE } from "@/constants";
import type {
  DropdownOptionType,
  EditMemberForm,
  Languages,
  ValueOf,
} from "@/types";

import { useGetMemberDetail, useUpdateMember } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";

const initForm = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  grade: { key: MEMBER_GRADE.moderator, label: "Moderator" as Languages },
};

const schema = yup
  .object({
    lastName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    middleName: yup.string().defined(),
    firstName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    phone: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("phone", CARRIER_VALID_MSG.PHONE, (value) => checkPhoneNum(value)),
    grade: yup.mixed<DropdownOptionType>().defined(),
    email: yup.string().defined(),
  })
  .required();

const useEditMember = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: memberDetail } = useGetMemberDetail(id!);
  const { mutate: updateMemberMutate, isLoading } = useUpdateMember();

  const formMethod = useForm<EditMemberForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const editMember = (data: EditMemberForm) => {
    const req = {
      staffId: id!,
      body: {
        ...data,
        grade: data.grade.key as ValueOf<typeof MEMBER_GRADE>,
      },
    };

    updateMemberMutate(req, {
      onSuccess: () => {
        navigate(PATH.SETTINGS_MEMBER);
        addToast(CARRIER_TOAST_MSG.SUCCESS.MEMBER_UPDATE_DONE);
      },
    });
  };

  const handleCancel = () => navigate(PATH.SETTINGS_MEMBER);

  useEffect(() => {
    if (!memberDetail) return;

    formMethod.reset({
      lastName: memberDetail.lastName ?? "",
      middleName: memberDetail.middleName ?? "",
      firstName: memberDetail.firstName ?? "",
      phone: memberDetail.phone ?? "",
      grade: MEMBER_GRADE_OPTIONS.filter(
        ({ key }) => key === memberDetail.grade,
      )[0],
      email: memberDetail.email ?? "",
    });
  }, [memberDetail]);

  return {
    formMethod,
    isLoading,
    editMember,
    handleCancel,
  };
};

export default useEditMember;
