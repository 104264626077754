import React from "react";
import { useLocation } from "react-router-dom";

import { PATH, CloseIcon12, ForwardingIcon } from "@/assets";

import * as S from "./Foldable2DepthLayout.styled";

interface Foldable2DepthLayoutProps {
  isClose?: boolean;
  handleClickClose?: () => void;
  handleClickOpen?: () => void;
  children: React.ReactNode;
}

const Foldable2DepthLayout = ({
  isClose,
  handleClickClose,
  handleClickOpen,
  children,
}: Foldable2DepthLayoutProps) => {
  const location = useLocation();

  return (
    <>
      <S.Root isClose={!!isClose}>
        {children}
        {location.pathname !== PATH.ORDER_MANAGEMENT_MAP.toString() && (
          <S.CloseBtn
            onClick={handleClickClose}
            type="button"
            aria-label="close"
          >
            <CloseIcon12 />
          </S.CloseBtn>
        )}
      </S.Root>
      {isClose && (
        <S.FolderBtn onClick={handleClickOpen} type="button" aria-label="open">
          <ForwardingIcon />
        </S.FolderBtn>
      )}
    </>
  );
};

export default Foldable2DepthLayout;
