import { isNil } from "lodash-es";

import type { LatLngOnlyNumber, LatLngWithNull } from "@/types";

export const filterValidCoords = (
  coords: LatLngWithNull[],
): LatLngOnlyNumber[] =>
  coords
    .filter(
      (coord): coord is LatLngOnlyNumber =>
        !isNil(coord.lat) &&
        !isNil(coord.lng) &&
        !isNaN(+coord.lat) &&
        !isNaN(+coord.lng),
    )
    .map((coord) => ({
      lat: +coord.lat,
      lng: +coord.lng,
    }));
