import React from "react";

import { auth } from "@/contexts";
import { Button, StatusLabel, Table, Tooltip } from "@/components";
import { useModal } from "@/hooks";
import { filterTableColumns, calculateTotalAmount, formatPrice } from "@/utils";
import { MemoIcon } from "@/assets";

import {
  QuotationDetailsModal,
  SendToShipperModal,
  QuotationDetailSelectModal,
} from "components";
import { QUOTATION_DETAIL_TABLE_COLUMNS } from "assets";
import type { GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationTable.styled";

interface QuotationTableProps {
  data: GetQuotationDetailServerModel<
    "shipper" | "forwarder" | "forwardercarrier"
  >;
}

const QuotationTable = ({ data }: QuotationTableProps) => {
  const { modalRef, handleModalOpen } = useModal();

  const filteredColumns = filterTableColumns(QUOTATION_DETAIL_TABLE_COLUMNS);

  const formatTableData = (
    history: GetQuotationDetailServerModel<
      "shipper" | "forwarder" | "forwardercarrier"
    >["history"][number],
    key: (typeof filteredColumns)[number]["key"],
    i: number,
  ) => {
    switch (key) {
      case "truckingCompany":
        return (
          <Table.Cell key={i}>
            <S.TruckingCompanyWrapper>
              <span>{history.name}</span>
              {history.remarks && (
                <Tooltip
                  css={S.tooltipBtn}
                  title="Memo"
                  tooltipBtnContent={MemoIcon}
                  position="BOTTOM_RIGHT"
                >
                  {history.remarks}
                </Tooltip>
              )}
            </S.TruckingCompanyWrapper>
          </Table.Cell>
        );

      case "price": {
        const totalPrice = formatPrice(
          calculateTotalAmount(`${history.price}`, `${history.vat}`),
        );

        return (
          <Table.Cell key={i}>
            <span>{totalPrice}</span>
          </Table.Cell>
        );
      }

      case "": {
        const totalPrice = formatPrice(
          calculateTotalAmount(`${history.price}`, `${history.vat}`),
        );

        const isAccepted = !!data.history.find(
          (history) => history?.status === "ACCEPTED",
        );

        const companyInfo = {
          truckingCompany: history.name,
          receivedPrice: totalPrice,
        };

        return (
          <Table.Cell key={i}>
            {!isAccepted && history.status === "ANSWERED" && (
              <Button
                css={S.button}
                variant="primarySmall"
                label="Reply"
                handleClickBtn={handleModalOpen(
                  auth.clientType === "shipper" ? (
                    <QuotationDetailSelectModal
                      ref={modalRef}
                      offerId={history.offerId}
                      quotationHistory={history}
                      companyInfo={companyInfo}
                      paymentMethod={data.paymentInfo.pay}
                      trucks={history.trucks}
                    />
                  ) : (
                    <SendToShipperModal
                      ref={modalRef}
                      offerId={history.offerId}
                      companyInfo={companyInfo}
                      isSelfTransport={data.defaultInfo.isSelfTransport}
                      paymentMethod={data.paymentInfo.pay}
                      trucks={history.trucks}
                    />
                  ),
                )}
              />
            )}
            {history.status === "ACCEPTED" &&
              data.defaultInfo.pageStatus === "COMPLETED" && (
                <Button
                  css={S.button}
                  variant="ghostGray"
                  label="Details"
                  handleClickBtn={handleModalOpen(
                    <QuotationDetailsModal
                      ref={modalRef}
                      quotationHistory={history}
                      companyInfo={companyInfo}
                      paymentMethod={data.paymentInfo.pay}
                      trucks={history.trucks}
                    />,
                  )}
                />
              )}
          </Table.Cell>
        );
      }

      case "status": {
        const statusLabel =
          data.defaultInfo.pageStatus === "COMPLETED" &&
          history.status === "ACCEPTED"
            ? "COMPLETED"
            : history.status;

        return (
          <Table.Cell key={i}>
            <StatusLabel status={statusLabel} />
          </Table.Cell>
        );
      }

      default:
        break;
    }
  };

  return (
    <Table css={S.table} title="Quotation Table" rowCount={20}>
      <Table.Head columnList={QUOTATION_DETAIL_TABLE_COLUMNS} />
      <Table.Body css={S.tbody(data.history.length > 0)}>
        {data.history.length ? (
          data.history.map((history, i) => (
            <Table.Row
              css={S.row(
                history?.status === "CANCELED" ||
                  history?.status === "REJECTED_BY_RECEIVER" ||
                  history?.status === "REJECTED_BY_SENDER",
              )}
              key={i}
            >
              {filteredColumns.map(({ key }, i) =>
                formatTableData(history, key, i),
              )}
            </Table.Row>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default QuotationTable;
