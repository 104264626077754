import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dompurify from "dompurify";

import {
  InternalLink,
  LabelContentTable,
  SettingDetailLayout,
} from "@/components";
import { formatICTDateTime } from "@/utils";
import { PATH } from "@/assets";

import { useGetNoticeDetail } from "services";
import * as S from "./NoticeDetail.styled";

const NoticeDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useGetNoticeDetail(id!);

  const sanitizer = dompurify.sanitize;

  return (
    <SettingDetailLayout>
      <LabelContentTable css={S.detailTable} variant="bg" subject="Details">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created date">
            {formatICTDateTime(data?.created ?? "")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Subject">
            {data?.title ?? ""}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Description">
            {data?.content ? (
              <S.ContentBox
                dangerouslySetInnerHTML={{
                  __html: sanitizer(data.content),
                }}
              />
            ) : (
              ""
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <InternalLink
        css={S.internalLink}
        variant="outlineMedium"
        to={PATH.SETTINGS_NOTICE}
      >
        {t("Back to list")}
      </InternalLink>
    </SettingDetailLayout>
  );
};

export default NoticeDetail;
