import { css, Theme } from "@emotion/react";

import {
  CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_CELL_SIZES,
  SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_CELL_SIZES,
} from "assets";
import type { CompanyType } from "types";

export const table = (clientType: CompanyType) => css`
  tr {
    grid-template-columns: ${clientType === "carrier" ||
    clientType === "forwardercarrier"
      ? CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_CELL_SIZES
      : SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;
