import React from "react";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";

import { useCancelQuotation } from "services";

interface QuotationCancelOrderModalProps {
  offerId: string;
}

const QuotationCancelOrderModal = React.forwardRef(
  (
    { offerId }: QuotationCancelOrderModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { mutate: cancelQuotationMutate } = useCancelQuotation();

    const { addToast } = useToast();
    const { handleModalClose } = useModal();

    const handleOrderCancel = () => {
      cancelQuotationMutate(offerId, {
        onSuccess: () => {
          addToast(COMMON_TOAST_MSG.SUCCESS.CANCEL_ORDER_DONE);
          handleModalClose();
        },
        onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Cancel order"
        desc={`Are you sure you want to cancel this order?\nYou can't undo this action.`}
        negBtnLabel="Yes"
        closeBtnLabel="No"
        callbackFn={handleOrderCancel}
      />
    );
  },
);

QuotationCancelOrderModal.displayName = "QuotationCancelOrderModal";
export default QuotationCancelOrderModal;
