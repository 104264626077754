import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { useOnClickOutside } from "@/hooks";
import type { DropdownOptionType } from "@/types";

const useFilterDropdown = (
  queryKey: string,
  dropdowns: DropdownOptionType[],
  handleFocusCondition: () => void,
  handleBlurCondition: () => void,
  link?: string,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDropdown = dropdowns.filter(
    (dropdown) => dropdown.key === searchParams.get(queryKey),
  )[0];

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [selectDropdown, setSelectDropdown] =
    useState<DropdownOptionType | null>(selectedDropdown ?? null);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setIsOpenDropdown(true);
    handleFocusCondition();
  };
  const handleCloseDropdown = () => {
    setIsOpenDropdown(false);
    handleBlurCondition();
  };

  const handleSelectDropdown =
    (dropdown: DropdownOptionType, queryKey: string) => () => {
      setSelectDropdown(dropdown);

      searchParams.set(queryKey, dropdown.key);
      link && searchParams.delete(link);

      searchParams.get("page")
        ? searchParams.set("page", "1")
        : searchParams.append("page", "1");

      setSearchParams(searchParams);
      handleCloseDropdown();
    };

  useOnClickOutside(dropdownRef, handleCloseDropdown);

  useEffect(() => {
    setSelectDropdown(selectedDropdown);
  }, [searchParams, selectedDropdown]);

  return {
    dropdownRef,
    isOpenDropdown,
    selectDropdown,
    handleOpenDropdown,
    handleCloseDropdown,
    handleSelectDropdown,
  };
};

export default useFilterDropdown;
