import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { PROPERTY_ALLOCATION_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${PROPERTY_ALLOCATION_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

const ellipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Driver = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > span {
    ${ellipsis};
    margin-right: 8px;
  }
`;

export const Truck = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > span {
    ${ellipsis};
    margin-right: 8px;
  }
`;

export const Length = styled.span`
  ${({ theme }) => css`
    ${theme.font.semibold_12};
    border-radius: 5px;
    padding: 2px 4px;
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_20};
  `}
`;
