import { useRecoilValue } from "recoil";
import { UseFieldArrayAppend, UseFieldArrayRemove } from "react-hook-form";

import { useToast } from "@/hooks";
import { clientLanguage } from "@/stores";
import { COMMON_TOAST_MSG } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import { truckTypeOptionsSelector } from "stores";
import { convertLanguagesType } from "utils";
import type {
  OrderForm,
  QuotationDetailForm,
  QuotationSendForm,
  OrderManagementForm,
} from "types";

interface Truck {
  truckType: DropdownOptionType;
  truckOption: DropdownOptionType;
  truckNum: number;
}

interface TruckField extends Truck {
  id: string;
}

const useTruck = () => {
  const language = useRecoilValue(clientLanguage);
  const { truckTypes, truckOptions } = useRecoilValue(truckTypeOptionsSelector);

  const { addToast } = useToast();

  const dropdownTruckTypes = truckTypes.map((value) => {
    const key = `${value.truckTypeId}`;
    const label = value[convertLanguagesType(language, "type")] as Languages;

    return { key, label };
  });

  const dropdownTruckOptions = (
    truckTypeId: number,
  ): readonly DropdownOptionType[] => {
    const selectTruckOptions = truckOptions[truckTypeId] || [
      { key: "", label: "" },
    ];

    return selectTruckOptions.map((value) => ({
      key: `${value.truckOptionId}`,
      label: value[convertLanguagesType(language, "option")] as Languages,
    }));
  };

  interface HandleTruckTypeSelect {
    trucks: Truck[];
    selectIndex: number;
    onChange: (...event: any[]) => void;
  }

  const handleTruckTypeSelect =
    ({ trucks, selectIndex, onChange }: HandleTruckTypeSelect) =>
    (selectKey: string): void => {
      const selectTruckType = {
        truckType: dropdownTruckTypes.find(({ key }) => key === selectKey) || {
          key: "",
          label: "",
        },
        truckOption: { key: "", label: "" },
        truckNum: 1,
      };

      onChange(
        trucks.map((truck, i) => (selectIndex === i ? selectTruckType : truck)),
      );
    };

  interface HandleTruckOptionSelect {
    trucks: Truck[];
    selectIndex: number;
    onChange: (...event: any[]) => void;
  }

  const handleTruckOptionSelect =
    ({ trucks, selectIndex, onChange }: HandleTruckOptionSelect) =>
    (selectKey: string): void => {
      if (trucks.find(({ truckOption: { key } }) => key === selectKey)) {
        return addToast(COMMON_TOAST_MSG.WARNING.DUPLICATE_TRUCK_OPTION);
      }

      const truckOption = dropdownTruckOptions(
        +trucks[selectIndex].truckType.key,
      ).find(({ key }) => key === selectKey) || { key: "", label: "" };

      onChange(
        trucks.map((truck, i) =>
          selectIndex === i ? { ...truck, truckOption } : truck,
        ),
      );
    };

  const handleTruckAdd =
    (
      append:
        | UseFieldArrayAppend<OrderForm, "truck">
        | UseFieldArrayAppend<QuotationDetailForm, "truck">
        | UseFieldArrayAppend<QuotationSendForm, `orders.${number}.truck`>
        | UseFieldArrayAppend<OrderManagementForm, "truck">,
    ) =>
    (): void => {
      append({
        truckType: { key: "", label: "" },
        truckOption: { key: "", label: "" },
        truckNum: 1,
      });
    };

  interface HandleTruckDelete {
    trucks: TruckField[];
    remove: UseFieldArrayRemove;
    index: number;
  }

  const handleTruckDelete =
    ({ trucks, remove, index }: HandleTruckDelete) =>
    (): void => {
      if (trucks.length === 1) {
        addToast(COMMON_TOAST_MSG.WARNING.TRUCK_DELETE_FAIL);
        return;
      }

      remove(index);
    };

  interface HandleTruckNum {
    trucks: Truck[];
    num: number;
    selectIndex: number;
    onChange: (...event: any[]) => void;
  }

  const handleTruckNum =
    ({ trucks, num, selectIndex, onChange }: HandleTruckNum) =>
    (): void => {
      onChange(
        trucks.map((truck, i) =>
          selectIndex === i
            ? { ...truck, truckNum: truck.truckNum + num }
            : truck,
        ),
      );
    };

  return {
    dropdownTruckTypes,
    dropdownTruckOptions,
    handleTruckAdd,
    handleTruckDelete,
    handleTruckTypeSelect,
    handleTruckOptionSelect,
    handleTruckNum,
  };
};

export default useTruck;
