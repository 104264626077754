import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Dropdown, QuerySearchInput, Tab } from "@/components";
import { useCustomParams } from "@/hooks";
import type { Languages } from "@/types";

import {
  AllocationAllocateList,
  Foldable1DepthLayout,
  Foldable2DepthLayout,
  OngoingDetail,
  AllocationOngoingList,
} from "components";
import { useClose2DepthLayout } from "hooks";
import {
  ALLOCATION_ONGOING_CATEGORIES,
  ALLOCATION_QUERY_PARAMS,
  ALLOCATION_TABS,
} from "assets";
import * as S from "./Allocation.styled";

const Allocation = () => {
  const [searchParams] = useSearchParams();

  const { handleUpdateQuery } = useCustomParams();

  const { isClose, handleClickClose, handleClickOpen } = useClose2DepthLayout();

  const isTabOngoing =
    searchParams.get(ALLOCATION_QUERY_PARAMS.TAB) === "ongoing";
  const heading = isTabOngoing ? "Ongoing" : "Allocate";
  const placeholder = isTabOngoing
    ? "Search by driver name, order number"
    : "Search by driver name";

  const [selectedDropdownOption, setSelectedDropdownOption] = useState<{
    key: string;
    label: Languages;
  }>(ALLOCATION_ONGOING_CATEGORIES[0]);

  const handleSelectDropdown = (key: string) => {
    if (!isTabOngoing) return;

    const selectedKey = ALLOCATION_ONGOING_CATEGORIES.filter(
      (item) => item.key === key,
    )[0];

    setSelectedDropdownOption(selectedKey);
    handleUpdateQuery({ [ALLOCATION_QUERY_PARAMS.CATEGORY]: key }, [
      ALLOCATION_QUERY_PARAMS.PAGE,
    ]);
  };

  useEffect(() => {
    if (!isTabOngoing) return;

    const currentCategoryQueryOption = ALLOCATION_ONGOING_CATEGORIES.filter(
      (item) => item.key === searchParams.get(ALLOCATION_QUERY_PARAMS.CATEGORY),
    )[0];

    setSelectedDropdownOption(
      currentCategoryQueryOption || ALLOCATION_ONGOING_CATEGORIES[0],
    );
  }, [searchParams.get(ALLOCATION_QUERY_PARAMS.TAB)]);

  const render = {
    allocate: <AllocationAllocateList />,
    ongoing: <AllocationOngoingList />,
  };

  return (
    <>
      <Foldable1DepthLayout heading={heading}>
        <QuerySearchInput
          css={S.querySearhInput}
          placeholder={placeholder}
          searchQuery={ALLOCATION_QUERY_PARAMS.SEARCH}
          deleteQueries={[ALLOCATION_QUERY_PARAMS.PAGE]}
        />
        <Tab css={S.tab} tabs={ALLOCATION_TABS}>
          <S.Wrapper>
            <Tab.TabList />
            {isTabOngoing && (
              <Dropdown
                css={S.dropdown}
                options={ALLOCATION_ONGOING_CATEGORIES}
                selectedOption={selectedDropdownOption}
                handleSelect={handleSelectDropdown}
              />
            )}
          </S.Wrapper>
          <Tab.TabPanel
            css={S.tabPanel}
            render={render}
            handleClickOpen={handleClickOpen}
          />
        </Tab>
      </Foldable1DepthLayout>
      {searchParams.get(ALLOCATION_QUERY_PARAMS.ALLOCATION_ID) && (
        <Foldable2DepthLayout
          isClose={isClose}
          handleClickClose={handleClickClose}
          handleClickOpen={handleClickOpen}
        >
          <OngoingDetail type="allocation" />
        </Foldable2DepthLayout>
      )}
    </>
  );
};

export default Allocation;
