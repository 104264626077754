import { atom } from "recoil";

import { initialLanguage } from "@/utils";
import { COCOTRUCK_LANGUAGE } from "@/assets";

import { localStorageEffect } from "./hooks";

const clientLanguage = atom<keyof typeof COCOTRUCK_LANGUAGE>({
  key: "clientLanguage",
  default: initialLanguage(),
  effects: [
    localStorageEffect<keyof typeof COCOTRUCK_LANGUAGE>("clientLanguage"),
  ],
});

export { clientLanguage };
