import React from "react";

import { Filter } from "@/components";

const OrderShippingFilter = () => {
  const searchLabels = [{ queryKey: "orderNumber", label: "Search" }];

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Search">
          <Filter.Input
            maxLength={17}
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default OrderShippingFilter;
