import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const baseModal = css`
  padding: 24px 28px 28px;
  min-width: 400px;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 4px;
  `}
`;

export const Desc = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    margin-bottom: 28px;
    color: ${theme.color.gray_60};
    white-space: break-spaces;
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;
  `}
`;
