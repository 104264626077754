import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type {
  APIError,
  FavoriteOrdersClientModel,
  FavoriteOrdersServerModel,
  UpdateFavoriteOrderNameQueryModel,
} from "@/types";

import {
  deleteFavoriteAPI,
  getFavoriteOrdersAPI,
  updateFavoriteNameAPI,
  createOrderCreationAPI,
} from "apis";
import type { CompanyType, CreateOrderQueryModel } from "types";

const clientsKeys = {
  all: ["clients"] as const,
  searchLists: () => [...clientsKeys.all, "searchList"] as const,
  searchList: (searchText: string) =>
    [...clientsKeys.searchLists(), searchText] as const,
};

export function useCreateOrderCreation(
  clientType: "carrier",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  CreateOrderQueryModel<"carrier">
>;
export function useCreateOrderCreation(
  clientType: "forwarder",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  CreateOrderQueryModel<"forwarder">
>;
export function useCreateOrderCreation(
  clientType: "forwardercarrier",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  CreateOrderQueryModel<"forwardercarrier">
>;
export function useCreateOrderCreation(
  clientType: "shipper",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  CreateOrderQueryModel<"shipper">
>;
export function useCreateOrderCreation(clientType: CompanyType) {
  const queryClient = useQueryClient();

  switch (clientType) {
    case "carrier":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        CreateOrderQueryModel<typeof clientType>
      >({
        mutationFn: (req) => createOrderCreationAPI(req, clientType),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: favoriteOrderKeys });
        },
      });
    case "forwarder":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        CreateOrderQueryModel<typeof clientType>
      >({
        mutationFn: (req) => createOrderCreationAPI(req, clientType),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: favoriteOrderKeys });
        },
      });
    case "forwardercarrier":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        CreateOrderQueryModel<typeof clientType>
      >({
        mutationFn: (req) => createOrderCreationAPI(req, "forwarder"), // NOTE: forwardercarrier는 forwarder로 주문생성하기 때문
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: favoriteOrderKeys });
        },
      });
    case "shipper":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        CreateOrderQueryModel<typeof clientType>
      >({
        mutationFn: (req) => createOrderCreationAPI(req, clientType),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: favoriteOrderKeys });
        },
      });
  }
}

const favoriteOrderKeys = ["favoriteOrders"];

export const useGetFavoriteOrders = (
  options?: UseQueryOptions<
    FavoriteOrdersServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    FavoriteOrdersClientModel[],
    string[]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: favoriteOrderKeys,
    queryFn: () => getFavoriteOrdersAPI(),
    select: (data) =>
      data.map((v) => ({
        companyId: v.companyId,
        companyName: v.companyName,
        created: v.created,
        favoriteId: v.favoriteId,
        favoriteName: v.favoriteName,
        pickUp: {
          addr: v.puAddr,
          addrDetail: v.puAddrDetail,
          city: v.puCity,
          buildingName: v.puName,
          lat: +v.puLat,
          lng: +v.puLng,
          pic: v.puPic,
          phone: v.puPhone,
        },
        dropOff: {
          addr: v.doAddr,
          addrDetail: v.doAddrDetail,
          city: v.doCity,
          buildingName: v.doName,
          lat: +v.doLat,
          lng: +v.doLng,
          pic: v.doPic,
          phone: v.doPhone,
        },
      })),
  });
};

export const useUpdateFavoriteOrderName = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateFavoriteOrderNameQueryModel
  >({
    mutationFn: (req) => updateFavoriteNameAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: favoriteOrderKeys,
      });
    },
  });
};

export const useDeleteFavoriteOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (favoriteOrderId) => deleteFavoriteAPI(favoriteOrderId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: favoriteOrderKeys,
      });
    },
  });
};
