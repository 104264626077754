export const checkPasswordLength = (password: string) => {
  const regexp = /^.{8,16}$/;

  return regexp.test(password);
};

export const checkPhoneLength = (value: string) => {
  const regex = /^[0-9]/g;

  return value.toString().replaceAll(regex, "").length > 12;
};
