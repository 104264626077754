import axios, { AxiosResponse } from "axios";

export const download = (url: string, name: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};

export const downloadFile = (
  file: AxiosResponse<any, any>,
  fileName: string,
) => {
  const URL = window.URL ?? window.webkitURL;
  const downloadUrl = URL.createObjectURL(
    new Blob([file.data], { type: file.headers["content-type"] }),
  );
  download(downloadUrl, fileName);
};

export const getFileAPI = (url: string) =>
  axios.get(url, { responseType: "blob" });
