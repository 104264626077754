import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  HideEmailPassword,
  InternalLink,
  SettingDetailLayout,
} from "@/components";
import { PATH, BackArrowIcon } from "@/assets";

import { CreateMemberField } from "components";
import useCreateMember from "./hooks/useCreateMember";
import * as S from "./CreateMember.styled";

const CreateMember = () => {
  const { t } = useTranslation();
  const { formMethod, isLoading, handleCancel, handleSubmit } =
    useCreateMember();

  return (
    <SettingDetailLayout>
      <S.Header>
        <InternalLink variant="ghostNoUnderlineGray" to={PATH.SETTINGS_MEMBER}>
          <BackArrowIcon />
          <S.Title>{t("Add member")}</S.Title>
        </InternalLink>
      </S.Header>
      <FormProvider {...formMethod}>
        <form onSubmit={handleSubmit}>
          <HideEmailPassword />
          <CreateMemberField css={S.addMemberField} />
          <S.ButtonWrapper>
            <Button
              type="button"
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleCancel}
            />
            <Button
              type="submit"
              variant="primaryMedium"
              label="Add"
              isLoading={isLoading}
              isDisabled={Object.keys(formMethod.formState.errors).length > 0}
            />
          </S.ButtonWrapper>
        </form>
      </FormProvider>
    </SettingDetailLayout>
  );
};

export default CreateMember;
