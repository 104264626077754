import React, { useRef } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

import { Input } from "@/components";
import { useOnClickOutside } from "@/hooks";
import { formatSearchData } from "@/utils";
import type { Languages } from "@/types";

import type { AutoCompleteDropdown } from "types";
import useAutoComplete from "./hooks/useAutoComplete";
import * as S from "./AutoComplete.styled";

interface AutoCompleteProps {
  className?: string;
  hasError?: boolean;
  placeholder: Languages;
  id: string;
  dropdowns: AutoCompleteDropdown[];
  value: string;
  maxLength?: number;
  selectDropdown: (selectedDropdown: AutoCompleteDropdown) => void;
  register: UseFormRegisterReturn<string>;
}

const AutoComplete = ({
  className,
  hasError,
  placeholder,
  id,
  dropdowns,
  value,
  maxLength,
  selectDropdown,
  register,
}: AutoCompleteProps) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const filteredDropdowns = dropdowns.filter((item) =>
    formatSearchData(item.name).includes(formatSearchData(value ?? "")),
  );

  const { listRef, isFocused, handleSelectOption, handleFocus, handleBlur } =
    useAutoComplete(value, selectDropdown, filteredDropdowns);

  useOnClickOutside(listRef, handleBlur, inputRef.current);

  return (
    <S.Root className={className} ref={inputRef}>
      <Input
        placeholder={placeholder}
        hasError={hasError}
        autoComplete="off"
        aria-haspopup="listbox"
        aria-controls={`${id}-list`}
        aria-expanded={dropdowns.length}
        value={value}
        maxLength={maxLength}
        handleFocus={handleFocus}
        register={register}
      />
      {isFocused && (
        <S.DropDownWrapper
          ref={listRef}
          isExpanded={!!filteredDropdowns.length}
          id={`${id}-list`}
          role="listbox"
          aria-label={`${id} list`}
        >
          {filteredDropdowns.map(({ id, name }) => (
            <S.DropDownItem key={id}>
              <button type="button" onClick={handleSelectOption({ name, id })}>
                <span>{name}</span>
              </button>
            </S.DropDownItem>
          ))}
        </S.DropDownWrapper>
      )}
    </S.Root>
  );
};

export default AutoComplete;
