import { useEffect, useState } from "react";

import { useGoogleMap } from "@/contexts";
import { displayPolylines } from "@/utils";
import type { LatLng } from "@/types";

const useGpsPolylines = (coords: LatLng[]) => {
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);

  const { googleMap } = useGoogleMap();

  const displayGpsPolylines = (): void => {
    const outlinePolyline = displayPolylines("outline", coords, googleMap);
    const inlinePolyline = displayPolylines("inline", coords, googleMap);

    setPolylines([outlinePolyline, inlinePolyline]);
  };

  const hideGpsPolylines = (): void => {
    polylines.forEach((polyline) => polyline.setMap(null));
  };

  useEffect(() => {
    if (!coords || !googleMap) return;

    displayGpsPolylines();
  }, [coords, googleMap]);

  useEffect(() => {
    return () => {
      hideGpsPolylines();
    };
  }, [polylines]);
};

export default useGpsPolylines;
