/* eslint-disable no-useless-escape */

export const deleteHyphen = (value: string) => {
  if (!value) return;

  return value.replaceAll("-", "");
};

export const deleteComma = (value = "") => {
  return value.replaceAll(",", "");
};

export const parseFileExtension = (fileType: string) =>
  `.${fileType.split("/")[1]}`;

export const parseFileExtensionByPoint = (fileType: string) =>
  `.${fileType.split(".")[1]}`;

export const devideFileExtension = (file: string | File) => {
  const fileName = file && (typeof file === "string" ? file : file.name);
  switch (fileName?.split(".")[1]) {
    case "pdf":
      return "PDF";
    default:
      return "Photo";
  }
};

export const parsePresignedUrl = (fileType: string) => {
  return `.${fileType?.split("/")[1]}`;
};

export const formatVin = (vin: string) => {
  return vin.replace(/[^a-zA-Z0-9]/, "").toUpperCase();
};

export const formatSearchData = (value: string) => {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, "");
};

export const formatPlateNumber = (value: string) => {
  if (!value) return value;

  const regex = /[^a-zA-Z0-9]/g;
  const plateNumber = value.toUpperCase()?.replace(regex, "");

  return plateNumber;
};

export const formatPhoneNum = (phoneNum: string | null) => {
  if (!phoneNum) return "-";

  return phoneNum.replace(/^([0-9]{3})([0-9]*)$/, "$1 $2");
};
