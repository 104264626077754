import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 8px;
    color: ${theme.color.black};
  `}
`;
