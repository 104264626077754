import React from "react";
import type { AxiosResponse } from "axios";
import type { UseMutateFunction } from "@tanstack/react-query";

import { Button } from "@/components";
import { makeDownloadFileName } from "@/utils";
import type { FileRequestType, APIError, Languages } from "@/types";

interface DownloadBtnProps {
  fileName: string;
  downloadFileNamePrefix: string; // TODO: 타입 좁히기
  fileKey: string;
  isLoading?: boolean; //TODO: isLoading 상태에서 ClipLoader가 하얀색이라 보이지 않음, 디자인 수정 필요
  downloadFileMutate: UseMutateFunction<
    AxiosResponse<any, any>,
    APIError<any>,
    FileRequestType,
    unknown
  >;
}

const DownloadBtn = ({
  fileName,
  fileKey,
  isLoading,
  downloadFileNamePrefix,
  downloadFileMutate: mutate,
}: DownloadBtnProps) => {
  const handleDownload = () => {
    const downloadFileName = makeDownloadFileName(downloadFileNamePrefix);

    mutate({ fileKey, fileName: downloadFileName });
  };

  return (
    <Button
      type="button"
      variant="ghostPrimary"
      label={fileName as Languages}
      // TODO: 로딩 스피너 디자인 추가되면 적용할 것
      //isLoading={isLoading}
      handleClickBtn={handleDownload}
    />
  );
};

export default DownloadBtn;
