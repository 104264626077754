import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const ToolBoxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border: 1px solid ${theme.color.gray_35};
    border-bottom: 0;
    padding: 0 22px 0 24px;
    background-color: ${theme.color.white};
  `}
`;

export const ToolBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`;

export const SelectedCount = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: relative;
    color: ${theme.color.primary_10};
    ::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -16px;
      width: 1px;
      height: 12px;
      background-color: ${theme.color.gray_30};
      transform: translateY(-50%);
    }
  `}
`;

export const Table = styled.table<{ isStickyThead?: boolean }>`
  ${({ theme, isStickyThead }) => css`
    width: 100%;
    /* NOTE: 전체 테이블에 높이가 걸려 문제가 발생하여 주석처리하였습니다. */
    /* height: 883px; */
    border: 1px solid ${theme.color.gray_35};
    border-top: 0;
    cursor: default;

    & > thead {
      position: ${isStickyThead && "sticky"};
      top: ${isStickyThead &&
      `calc(${theme.size.HEADER_HEIGHT} + ${theme.size.TABLE_HEADER_HEIGHT} - 8px)`};
      z-index: ${isStickyThead && theme.zIndex.STICKY};

      tr:first-of-type {
        border-top: 1px solid ${theme.color.gray_35};
      }
    }

    tbody > tr:nth-of-type(20) {
      border-bottom: 0;
    }
  `}
`;

export const Trow = styled.tr`
  position: relative;
  display: grid;

  > th {
    text-align: left;
  }
`;

const mixinCell = css`
  display: flex;
  align-items: center;
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    height: 100%;
    background-color: ${theme.color.white};

    & > tr {
      border-bottom: 1px solid ${theme.color.gray_30};

      :last-of-type:nth-of-type(20) {
        border-bottom: 0;
      }
    }
  `}
`;

interface ThProps {
  isLast?: boolean;
  hasMultiCol?: boolean;
  columnCnt?: number;
  rowCnt?: number;
}

export const Th = styled.th<ThProps>`
  ${({ hasMultiCol = false, isLast, columnCnt, rowCnt, theme }) => css`
    ${mixinCell};
    ${theme.font.medium_13};
    grid-row: ${rowCnt && `1/${rowCnt}`};
    grid-column: ${columnCnt && `span ${columnCnt}`};
    border-bottom: 1px solid ${theme.color.gray_35};
    padding: ${hasMultiCol ? "5px 12px" : "7px 12px"};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};

    &:not(:last-of-type) {
      border-right: ${!isLast ? `1px solid ${theme.color.gray_35}` : "0"};
    }
  `}
`;

export const CheckTh = styled(Th)`
  padding: 0;
`;

export const checkBtn = css`
  width: 100%;
`;

export const Row = styled(Trow)`
  & > :last-child {
    border-right: 0;
  }

  :nth-of-type(20) {
    border-bottom: 0;
  }
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${mixinCell};
    ${theme.font.regular_13};
    height: 41px;
    padding: 0 12px;
    color: ${theme.color.black};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;

    &:not(:nth-last-of-type(-n + 2)) {
      border-right: 1px solid ${theme.color.gray_30};
    }
  `}
`;

export const CheckTd = styled(Td)`
  padding: 0;

  & > div {
    width: 100%;
  }
`;

interface SelectableRowProps {
  isSelected?: boolean;
}

export const SelectRow = styled(Trow)<SelectableRowProps>`
  ${({ theme, isSelected }) => css`
    background-color: ${isSelected && theme.color.gray_10};

    &:hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

const mixinRowBtn = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
`;

export const RowBtn = styled.button`
  ${mixinRowBtn}
`;

export const rowlink = css`
  ${mixinRowBtn};
`;

export const NoData = styled.tr`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    background-color: ${theme.color.white};

    &:last-of-type {
      border-bottom: 0;
    }

    & > td {
      ${theme.font.regular_14};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      text-align: center;
      color: ${theme.color.gray_70};

      & > button {
        margin: 16px 0 0;
      }
    }
  `}
`;
