export const initialLanguage = () => {
  // https://m.blog.naver.com/linknote/10089613246
  const browserInitLanguage = navigator.language
    .slice(0, 2)
    .toLocaleLowerCase();

  switch (browserInitLanguage) {
    case "vi":
      return browserInitLanguage;
    default:
      return "en";
  }
};
