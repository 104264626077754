import React from "react";

import {
  ChevronLeftDoubleIcon,
  ChevronLeftIcon,
  ChevronRightDoubleIcon,
  ChevronRightIcon,
} from "@/assets";

import usePagination from "./hooks/usePagination";
import * as S from "./Pagination.styled";

interface PaginationProps {
  className?: string;
  hasDoubleBtn?: boolean;
  currentPage: number;
  totalPages: number;
  maxPageCount: number;
  handlePrevPage: () => void;
  handleFirstPage: () => void;
  handleNextPage: () => void;
  handleLastPage: () => void;
  handleClickNum: (idx: number) => () => void;
}

const Pagination = ({
  className,
  hasDoubleBtn = true,
  currentPage,
  totalPages,
  maxPageCount,
  handlePrevPage,
  handleFirstPage,
  handleNextPage,
  handleLastPage,
  handleClickNum,
}: PaginationProps) => {
  const { isDisabledPrev, isDisabledNext, pageNumList } = usePagination(
    currentPage,
    maxPageCount,
    totalPages,
  );

  return (
    <S.Root className={className}>
      <S.Wrapper>
        {hasDoubleBtn && (
          <S.ArrowBtn
            type="button"
            disabled={isDisabledPrev}
            onClick={handleFirstPage}
          >
            <ChevronLeftDoubleIcon />
          </S.ArrowBtn>
        )}

        <S.ArrowBtn
          type="button"
          disabled={isDisabledPrev}
          onClick={handlePrevPage}
        >
          <ChevronLeftIcon />
        </S.ArrowBtn>
        <S.NumWrapper>
          {pageNumList.length ? (
            pageNumList.map((num) => (
              <S.NumBtn
                key={num}
                type="button"
                isCurrentPage={currentPage === num}
                onClick={handleClickNum(num)}
              >
                {num}
              </S.NumBtn>
            ))
          ) : (
            <S.NumBtn type="button" isCurrentPage={true} disabled>
              1
            </S.NumBtn>
          )}
        </S.NumWrapper>
        <S.ArrowBtn
          type="button"
          disabled={isDisabledNext}
          onClick={handleNextPage}
        >
          <ChevronRightIcon />
        </S.ArrowBtn>
        {hasDoubleBtn && (
          <S.ArrowBtn
            type="button"
            disabled={isDisabledNext}
            onClick={handleLastPage}
          >
            <ChevronRightDoubleIcon />
          </S.ArrowBtn>
        )}
      </S.Wrapper>
    </S.Root>
  );
};

export default Pagination;
