import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  left: -280px;
  width: 320px;
  padding: 20px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 8px rgba(25, 31, 40, 0.12));
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    position: relative;
    margin-bottom: 8px;
    color: ${theme.color.gray_70};

    &::after {
      content: "*";
      margin-left: 4px;
      color: ${theme.color.red_20};
    }
  `}
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
`;

export const input = (theme: Theme) => css`
  height: 30px;
  border: 0px;
  border-bottom: 1px solid ${theme.color.gray_35};
  padding: 0px 0px 8px 0px;

  &:focus {
    border: 0px;
    border-bottom: 1px solid ${theme.color.primary_40};
  }
`;

export const errMsg = css`
  margin-top: 4px;
`;
