import React from "react";

import { Button, QuerySearchInput, Tab } from "@/components";
import { ResetIcon } from "@/assets";

import { Foldable1DepthLayout, OrderMonitoringShippingList } from "components";
import { useGetOrderManagementShipping } from "services";
import { MONITORING_QUERY_PARAMS, MONITORING_TABS } from "assets";
import { useMonitoring } from "./hooks";
import * as S from "./Monitoring.styled";

const ShipperMonitoring = () => {
  const { heading, deleteSearchParams, makeMonitoringFilters } =
    useMonitoring("shipper");

  const { data, refetch } = useGetOrderManagementShipping({
    query: {
      ...makeMonitoringFilters("shipper").restFilters,
      orderNumber: makeMonitoringFilters("shipper").search,
    },
  });

  if (!data) return null;

  const render = {
    shipping: <OrderMonitoringShippingList orders={data} />,
  };

  const resetClick = (): void => {
    refetch();
    deleteSearchParams();
  };

  return (
    <Foldable1DepthLayout heading={heading}>
      <QuerySearchInput
        css={S.querySearchInput}
        placeholder="Search by order number"
        searchQuery={MONITORING_QUERY_PARAMS.SEARCH}
        deleteQueries={[
          MONITORING_QUERY_PARAMS.PAGE,
          MONITORING_QUERY_PARAMS.ORDER_ID,
          MONITORING_QUERY_PARAMS.TYPE,
        ]}
      />
      <Tab css={S.tab} tabs={[MONITORING_TABS[0]]}>
        <S.TabListWrapper>
          <Tab.TabList />
          <Button
            css={S.resetBtn}
            variant="text"
            icon={<ResetIcon />}
            label="Reset"
            handleClickBtn={resetClick}
          />
        </S.TabListWrapper>
        <Tab.TabPanel css={S.tabPanel} render={render} />
      </Tab>
    </Foldable1DepthLayout>
  );
};

export default ShipperMonitoring;
