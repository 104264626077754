import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Input = styled.input<{ hasError: boolean }>`
  ${({ hasError, theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    min-height: 44px;
    height: inherit;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_30};
    border-radius: 10px;
    padding: 0 40px 0 12px;
    background-color: ${theme.color.white};

    &:focus {
      border-color: ${theme.color.primary_40};
    }

    &:disabled {
      background-color: ${theme.color.gray_10};
    }

    &::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const UnitSuffix = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    position: absolute;
    right: 12px;
    color: ${theme.color.black};
  `}
`;
