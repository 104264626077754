import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DialogBtn, Loading, PageBackHeader } from "@/components";
import { useAlignCenter, useLocationMarker } from "@/hooks";
import { KebabMenuIcon } from "@/assets";

import { OngoingDetailBody } from "components";
import { useGetOngoingDriverDetail } from "services";
import MenuDialog from "./menu/MenuDialog";
import OngoingCard from "./ongoingCard/OngoingCard";
import * as S from "./OngoingDetail.styled";

interface OngoingDetailProps {
  type: "order" | "allocation";
}

const OngoingDetail = ({ type }: OngoingDetailProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const allocationId = searchParams.get("allocationId") ?? "";

  const { data } = useGetOngoingDriverDetail(allocationId);

  const { displayLocationMarker } = useLocationMarker("Allocation");
  useAlignCenter(data?.coords);

  useEffect(() => {
    if (!data) return;

    const allocationMarkers = data.locations.map((delivery) => ({
      type: delivery.type,
      lat: delivery.lat,
      lng: delivery.lng,
    }));

    displayLocationMarker(allocationMarkers);
  }, [data]);

  const MenuBtn = () => {
    return (
      <DialogBtn
        css={S.menuBtn}
        popup={({ handleDialogClose }) => (
          <MenuDialog handleDialogClose={handleDialogClose} />
        )}
      >
        <KebabMenuIcon />
      </DialogBtn>
    );
  };

  return (
    <>
      {type === "allocation" ? (
        <S.OngoingHeader>
          <S.H2>{t("Ongoing")}</S.H2>
          {MenuBtn()}
        </S.OngoingHeader>
      ) : (
        <PageBackHeader title={t("Allocation")} path={-1}>
          {MenuBtn()}
        </PageBackHeader>
      )}

      {!data ? (
        <Loading />
      ) : (
        <S.Body>
          <OngoingCard data={data.driverInfo} />
          <S.Hr />
          <OngoingDetailBody allocationInfo={data.allocationInfo} />
        </S.Body>
      )}
    </>
  );
};

export default OngoingDetail;
