import React from "react";

import { CompanyLicenseRegistrationLayout } from "components";
import ForwarderCompanyLicenseRegistrationForm from "./form/ForwarderCompanyLicenseRegistrationForm";

const ForwarderCompanyLicenseRegistration = () => {
  return (
    <CompanyLicenseRegistrationLayout
      licenseRegistrationForm={<ForwarderCompanyLicenseRegistrationForm />}
    />
  );
};

export default ForwarderCompanyLicenseRegistration;
