import type { TableColumn, TableColumns } from "@/types";

export const calcTableWidth = (arr: (string[] | string)[]) => {
  const tableColumnArr = arr.map((designColumnWidth: string | string[]) =>
    typeof designColumnWidth === "string"
      ? designColumnWidth
      : `minmax(${designColumnWidth[0]}, ${designColumnWidth[1]})`,
  );

  return tableColumnArr.join(" ");
};

// TODO: 타입 수정 예정
export const filterTableColumns = <T extends string>(
  tableColumns: TableColumns<T>,
) => {
  const filteredTableColumns = tableColumns.reduce(
    (acc: TableColumn<T>[], cur) => {
      if (cur.length !== 1) {
        const columns = cur[1] as TableColumn<T>[];
        return [...acc, ...columns];
      }

      const columns = [...cur] as TableColumn<T>[];
      return [...acc, ...columns];
    },
    [],
  );

  return filteredTableColumns;
};
