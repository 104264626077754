import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useModal, usePrompt, useToast } from "@/hooks";
import { deleteComma } from "@/utils";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";
import type { CompanyInfo, DropdownOptionType } from "@/types";

import { QuotationConfirmModal, QuotationRejectModal } from "components";
import {
  useGetLinkPlayer,
  usePatchRequestQuotationReject,
  usePostQuotationSend,
} from "services";
import { QUOTATION_DETAIL_FORM } from "assets";
import type {
  QuotationDetailForm,
  GetQuotationDetailServerModel,
  CompanyType,
  PostQuotationRequestQueryModel,
} from "types";

const FORM_SCHEMA = yup.object().shape({
  truck: yup
    .array()
    .of(
      yup.object({
        truckType: yup
          .mixed<DropdownOptionType>()
          .required(COMMON_VALID_MSG.REQUIRED)
          .test({
            name: "required",
            test: ({ key, label }) => !!(key && label),
            message: COMMON_VALID_MSG.REQUIRED,
          }),
        truckOption: yup
          .mixed<DropdownOptionType>()
          .required(COMMON_VALID_MSG.REQUIRED)
          .test({
            name: "required",
            test: ({ key, label }) => !!(key && label),
            message: COMMON_VALID_MSG.REQUIRED,
          }),
        truckNum: yup.number().required(COMMON_VALID_MSG.REQUIRED),
      }),
    )
    .required(),
  subTotal: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test(
      "maxTotal",
      COMMON_VALID_MSG.INVALID_PRICE,
      (value) => +deleteComma(value) < 2147483647,
    ),
  tax: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  memo: yup
    .string()
    .optional()
    .test(
      "maxTotal",
      COMMON_VALID_MSG.INVALID_MEMO_150,
      (value) => (value?.length ?? 0) <= 150,
    ),
});

const useQuotationDetail = (
  data?: GetQuotationDetailServerModel<CompanyType>,
) => {
  const { id } = useParams();

  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo[]>([]);

  //NOTE: 운송사(/포+운 -> api 확인 필요)에서만 사용하는 send api
  const { mutate: quotationSendMutate } = usePostQuotationSend();
  //NOTE: 상위 견적 거절(운송사, pending 상태의 포,포+운)
  const { mutate: rejectQuotationRequest } = usePatchRequestQuotationReject();
  const { data: carrierLinkPlayers, refetch: carrierRefetch } =
    useGetLinkPlayer(
      {
        query: { type: "carrier", orderId: data?.defaultInfo?.orderId },
      },
      !!data?.defaultInfo?.orderId,
    );
  const { data: forwarderLinkPlayers, refetch: forwarderRefetch } =
    useGetLinkPlayer(
      {
        query: { type: "forwarder", orderId: data?.defaultInfo?.orderId },
      },
      !!data?.defaultInfo?.orderId,
    );

  const companyLinkPlayers = [
    ...(carrierLinkPlayers || []),
    ...(forwarderLinkPlayers || []),
  ];

  const formMethod = useForm<QuotationDetailForm>({
    defaultValues: QUOTATION_DETAIL_FORM,
    mode: "onTouched",
    resolver: yupResolver(FORM_SCHEMA),
  });

  const { handleModalOpen, modalRef, handleModalClose } = useModal();
  const { addToast } = useToast();

  const makeInitData = (data?: GetQuotationDetailServerModel<CompanyType>) => {
    if (!data) return;

    return {
      ...QUOTATION_DETAIL_FORM,
      subTotal: data?.paymentInfo.price ? `${data?.paymentInfo.price}` : "",
      tax: `${data?.paymentInfo.vat}`,
      memo: "",
    };
  };

  usePrompt(!isEqual(formMethod.getValues(), makeInitData(data)));

  const handleRejectModalOpen = (): void => {
    const handleRejectQuotationRequest = (): void => {
      rejectQuotationRequest(id!, {
        onSuccess: () => {
          formMethod.reset(QUOTATION_DETAIL_FORM);
          addToast(COMMON_TOAST_MSG.SUCCESS.REJECT_QUOTATION_DONE);
          handleModalClose();
        },
        onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
      });
    };

    handleModalOpen(
      <QuotationRejectModal
        ref={modalRef}
        handleReject={handleRejectQuotationRequest}
      />,
    )();
  };

  const handleCheckValidForm = () => {
    if (Object.keys(formMethod.formState.errors).length > 0) return;

    if (
      +deleteComma(formMethod.watch("subTotal")) > 0 ||
      (data && +data.paymentInfo.price > 0)
    ) {
      const handleConfirm = () => {
        if (!data) return;

        const trucks = formMethod
          .watch("truck")
          .reduce((acc: Record<string, number>, item) => {
            acc[`${item.truckOption.key}`] = item.truckNum;
            return acc;
          }, {});

        const offerPriceReq = {
          id: id!,
          body: {
            trucks,
            price: +deleteComma(formMethod.watch("subTotal")),
            vat: +formMethod.watch("tax"),
            ...(formMethod.watch("memo")?.trim() && {
              remarks: formMethod.watch("memo")?.trim(),
            }),
          },
        };

        const paymentReq = {
          id: id!,
          body: {
            trucks,
            price: data.paymentInfo.price,
            vat: data.paymentInfo.vat,
            ...(data.paymentInfo.remarks && {
              remarks: data.paymentInfo.remarks,
            }),
          },
        };

        quotationSendMutate(
          data.paymentInfo.price ? paymentReq : offerPriceReq,
          {
            onSuccess: () => {
              +deleteComma(formMethod.watch("subTotal")) > 0
                ? addToast(COMMON_TOAST_MSG.SUCCESS.APPROVE_QUOTATION_DONE)
                : addToast(COMMON_TOAST_MSG.SUCCESS.SEND_QUOTATION_DONE);
              handleModalClose();
              formMethod.reset(QUOTATION_DETAIL_FORM);
            },
            onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
          },
        );
      };

      handleModalOpen(
        <QuotationConfirmModal ref={modalRef} handleConfirm={handleConfirm} />,
      )();
    } else {
      addToast(COMMON_TOAST_MSG.WARNING.ENTER_ABLE_PRICE);
      formMethod.setError("subTotal", {
        message: COMMON_TOAST_MSG.WARNING.ENTER_ABLE_PRICE.content,
      });
    }
  };

  function makeCompanyRequestMutateFormBody(
    clientType: "forwarder",
    companyInfo: CompanyInfo[],
  ): PostQuotationRequestQueryModel<"forwarder" | "shipper">;
  function makeCompanyRequestMutateFormBody(
    clientType: "forwardercarrier",
    companyInfo: CompanyInfo[],
  ): PostQuotationRequestQueryModel<"forwardercarrier">;
  function makeCompanyRequestMutateFormBody(
    clientType: "forwarder" | "forwardercarrier" | "shipper",
    companyInfo: CompanyInfo[],
  ): PostQuotationRequestQueryModel<
    "forwarder" | "forwardercarrier" | "shipper"
  > {
    const commonBody = {
      orders: [
        {
          orderId: data?.defaultInfo.orderId ?? "",
          price: data?.paymentInfo.price ?? 0,
          vat: data?.paymentInfo.vat ?? 8,
          ...(data?.paymentInfo.remarks && {
            remarks: data?.paymentInfo.remarks,
          }),
        },
      ],
    };

    switch (clientType) {
      case "forwarder":
      case "shipper":
        return {
          body: {
            ...commonBody,
            truckingCompanyId: companyInfo.map(({ id }) => id),
          },
        };
      case "forwardercarrier":
        return {
          body: {
            ...commonBody,
            isSelfTransport: false, //NOTE: 상세 페이지에서는 isSelfTransport true 체크 불가능하여 false로 적용
            truckingCompanyId: companyInfo.map(({ id }) => id),
          },
        };
    }
  }

  const companyRequestMutateOption = (clientType: "forwarder" | "shipper") => ({
    onSuccess: () => {
      addToast(COMMON_TOAST_MSG.SUCCESS.SEND_QUOTAIUON_DONE);

      switch (clientType) {
        case "forwarder":
          return carrierRefetch();

        case "shipper": {
          forwarderRefetch();
          carrierRefetch();
        }
      }
    },
    onError: () => addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API),
  });

  useEffect(() => {
    formMethod.reset(makeInitData(data));
  }, [data]);

  return {
    carrierRefetch,
    selectedCompany,
    carrierLinkPlayers,
    companyLinkPlayers,
    formMethod,
    handleRejectModalOpen,
    makeCompanyRequestMutateFormBody,
    companyRequestMutateOption,
    handleSubmit: formMethod.handleSubmit(handleCheckValidForm),
  };
};

export default useQuotationDetail;
