import React from "react";

import { ConfirmModal } from "@/components";

interface RequestConfirmModalProps {
  handleRequestConfirm: () => void;
}

const RequestConfirmModal = React.forwardRef(
  (
    { handleRequestConfirm }: RequestConfirmModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="Check requested payment"
        desc="Are you sure you want to request this payment?"
        closeBtnLabel="Cancel"
        posBtnLabel="Confirm"
        callbackFn={handleRequestConfirm}
      />
    );
  },
);

RequestConfirmModal.displayName = "RequestConfirmModal";

export default RequestConfirmModal;
