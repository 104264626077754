import React from "react";

import { LabelContentTable } from "@/components";
import { formatOrderNum, formatPhoneNum } from "@/utils";

import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementDefaultInfo.styled";

interface OrderManagementDefaultInfoProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementDefaultInfo = ({
  data,
}: OrderManagementDefaultInfoProps) => {
  return (
    <LabelContentTable variant="underline">
      <S.OrderNumber>
        {formatOrderNum(data.defaultInformation.orderNumber)}
      </S.OrderNumber>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Shipper company">
          {data.defaultInformation.client}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Trucking company">
          {data.defaultInformation.truckingCompany} (
          {formatPhoneNum(data.defaultInformation.truckingCompanyPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementDefaultInfo;
