import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, InternalLink, TableOfContent } from "@/components";
import { useModal } from "@/hooks";
import { makeNameString } from "@/utils";
import { PATH, DelPersonIcon } from "@/assets";

import {
  AccountInfo,
  IdPasswordInfo,
  DrivingRecords,
  DriverDefaultInfo,
  DelDriverModal,
} from "components";
import { useGetPropertyDriverDetail } from "services";
import * as S from "./DriverDetail.styled";

const DriverDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { data: driverDetails } = useGetPropertyDriverDetail(id!);

  const { handleModalOpen, modalRef } = useModal();

  if (!driverDetails) return null;

  const accountHolder = makeNameString(
    driverDetails.defaultInfo.lastName,
    driverDetails.defaultInfo.middleName,
    driverDetails.defaultInfo.firstName,
  );

  const contents = [
    {
      heading: { text: "Default information" },
      content: <DriverDefaultInfo data={driverDetails.defaultInfo} />,
    },
    {
      heading: { text: "Account information" },
      content: (
        <AccountInfo
          accoutHolder={accountHolder}
          data={driverDetails.accountInfo}
        />
      ),
    },
    {
      heading: { text: "ID and password" },
      content: <IdPasswordInfo id={driverDetails.id} />,
    },
    {
      heading: { text: "Driving records" },
      content: <DrivingRecords data={driverDetails.drivingRecords} />,
    },
  ];

  return (
    <S.Layout>
      <TableOfContent
        css={S.toc}
        stickyTop={<S.TocHeading>{t("Driver details")}</S.TocHeading>}
        stickyBottom={
          <Button
            css={S.button}
            variant="ghostGray"
            label="Delete the driver"
            icon={<DelPersonIcon />}
            handleClickBtn={handleModalOpen(<DelDriverModal ref={modalRef} />)}
          />
        }
        contents={contents}
      />
      <InternalLink
        css={S.backLink}
        variant="outlineMedium"
        to={`${PATH.PROPERTY}?tab=driver`}
      >
        {t("Back to list")}
      </InternalLink>
    </S.Layout>
  );
};

export default DriverDetail;
