import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const LanguageWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    border-radius: 2px;
    padding: 0 16px;
    background-color: ${theme.color.gray_10};

    :first-of-type {
      path {
        fill: ${theme.color.gray_50};
      }
    }

    &:not([disabled]):hover {
      background-color: ${theme.color.gray_20};
    }
  `}
`;

export const dropdown = (theme: Theme) => css`
  min-width: 87px;
  width: max-content;
  padding: 0;

  & > button {
    border: 0;
    padding: 0;

    &:focus,
    &[aria-expanded="true"] {
      border: 0;
    }

    & > span {
      ${theme.font.regular_13};
      color: ${theme.color.gray_80};
    }

    & > svg {
      fill: ${theme.color.gray_50};
    }
  }

  & > ul {
    left: -40px;
    width: 143px;

    & > li {
      ${theme.font.regular_14};
    }
  }
`;
