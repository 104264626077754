import React from "react";
import { useLocation } from "react-router-dom";
import { isEqual } from "lodash-es";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import { DetailLayout } from "@/components";
import { usePrompt } from "@/hooks";

import {
  SendQuotationBtns,
  SendQuotationOrderInfo,
  SendQuotationTruckingCompany,
} from "components";
import { usePostQuotationRequest } from "services";
import type { GetQuotationPendingServerModel, QuotationSendForm } from "types";
import { useHasQuotations, useQuotationSendForm } from "./hooks";
import * as S from "./QuotationSend.styled";
const ForwarderCarrierQuotationSend = () => {
  const location = useLocation();
  const locationState = location.state as {
    selectedOrders: GetQuotationPendingServerModel["pending"];
  }; // NOTE: location.state 타입 unknown이라 강제로 해야됩니다.
  const { t } = useTranslation();

  const { mutate } = usePostQuotationRequest("forwardercarrier");

  const {
    formMethod,
    makeInitData,
    makeCompanyRequestMutateFormBody,
    selectMutateOption,
    handleSendModalOpen,
  } = useQuotationSendForm(locationState);
  useHasQuotations();

  const handleSend = formMethod.handleSubmit(
    (data: QuotationSendForm): void => {
      mutate(
        makeCompanyRequestMutateFormBody("forwardercarrier", data),
        selectMutateOption,
      );
    },
  );

  usePrompt(
    !isEqual(
      formMethod.getValues(),
      makeInitData(locationState.selectedOrders),
    ),
  );

  return (
    <DetailLayout css={S.layout}>
      <S.Heading>{t("Send quotation")}</S.Heading>
      <FormProvider {...formMethod}>
        <SendQuotationTruckingCompany
          selectedOrders={locationState.selectedOrders}
        />
        {locationState?.selectedOrders.map((order, i) => (
          <SendQuotationOrderInfo key={i} idx={i} data={order} />
        ))}
        <SendQuotationBtns
          handleSendModalOpen={handleSendModalOpen(handleSend)}
        />
      </FormProvider>
    </DetailLayout>
  );
};

export default ForwarderCarrierQuotationSend;
