import React from "react";

import { ConfirmModal } from "@/components";
import type { Languages } from "@/types";

import * as S from "./QuotationSelectModal.styled";

interface QuotationSelectModalProps {
  isLoading?: boolean;
  handleSelect: () => void;
}

const QuotationSelectModal = React.forwardRef(
  (
    { isLoading, handleSelect }: QuotationSelectModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        css={S.modal}
        ref={ref}
        isLoading={isLoading}
        title="Select this quotation?"
        desc={
          "Please check the price and company. Once selected, \ntransportation will start and cannot be canceled." as Languages
        } //TODO: 줄바뀜 포함된 번역으로 요청 필요
        posBtnLabel="Select"
        closeBtnLabel="Cancel"
        callbackFn={handleSelect}
      />
    );
  },
);

QuotationSelectModal.displayName = "QuotationSelectModal";

export default QuotationSelectModal;
