import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal } from "@/hooks";
import { PATH } from "@/assets";

const useHasQuotations = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const handleCloseConfirmModal = () => {
    navigate(PATH.QUOTATION);
    handleModalClose();
  };

  useEffect(() => {
    if (state === null) {
      handleModalOpen(
        <ConfirmModal
          ref={modalRef}
          title="Invalid access"
          desc="Please try throught the correct path."
          posBtnLabel="Go to list"
          callbackFn={handleCloseConfirmModal}
        />,
      )();
    }
  }, []);
};

export default useHasQuotations;
