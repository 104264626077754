import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const layout = css`
  width: 764px;
`;

export const Heading = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 24px;
  `}
`;
