import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";
import type { TooltipPosition } from "@/types";

export const Root = styled.div`
  position: relative;
`;

export const OpenerBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.semibold_12};
    width: 100%;
    height: 100%;

    & > svg {
      pointer-events: none;
      width: 18px;
      height: 18px;
    }
  `}
`;

interface TooltipBoxProps {
  position: TooltipPosition;
}

export const TooltipBox = styled.dl<TooltipBoxProps>`
  ${({ position, theme }) => css`
    ${mixin_scrollbar};
    position: absolute;
    top: ${position === "BOTTOM_LEFT" || position === "BOTTOM_RIGHT"
      ? "calc(100% + 4px)"
      : "auto"};
    bottom: ${position === "TOP_LEFT" || position === "TOP_RIGHT"
      ? "calc(100% + 4px)"
      : "auto"};
    left: ${position === "TOP_RIGHT" || position === "BOTTOM_RIGHT"
      ? "0"
      : "auto"};
    right: ${position === "TOP_LEFT" || position === "BOTTOM_LEFT"
      ? "0"
      : "auto"};
    width: 307px;
    max-height: 168px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 2px;
    padding: 12px 16px;
    background-color: ${theme.color.secondary};
    box-shadow: ${theme.boxShadow.tooltip};
    z-index: 10;

    ::-webkit-scrollbar {
      background-color: ${theme.color.gray_80};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.color.gray_70};
    }
  `}
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;

    & > svg path {
      width: 12px;
      height: 12px;
      fill: ${theme.color.white};
      pointer-events: none;
    }
  `}
`;

export const Title = styled.dt`
  ${({ theme }) => css`
    ${theme.font.semibold_14};
    margin-bottom: 4px;
    color: ${theme.color.white};
  `}
`;

export const Desc = styled.dd`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_50};
    white-space: pre-line;
  `}

  * {
    ${({ theme }) => css`
      ${theme.font.regular_14};
      color: ${theme.color.gray_50};
      white-space: pre-line;
    `}
  }
`;

export const TopTooltip = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    display: flex;
    background-color: ${theme.color.secondary};
  `}
`;

export const badge = (theme: Theme) => css`
  margin-right: 4px;
  background-color: ${theme.color.primary_40_20};
`;
