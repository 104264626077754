import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  NoResult,
  Loading,
  CalendarModal,
  Button,
  Tooltip,
} from "@/components";
import { useModal } from "@/hooks";
import { clientLanguage } from "@/stores";
import { formatDate } from "@/utils";
import { CalendarIcon, DeleteIcon20, QuestionIcon } from "@/assets";

import { DeliveryCard } from "components";
import { useDispatchOptimizing, useDelivery, useSelectEtaDate } from "hooks";
import type {
  AllocationRoute,
  GetAllocationDetailServerModel,
  SelectedAllocationDelivery,
} from "types";
import * as S from "./DetailRight.styled";

interface DetailRightProps {
  allocations: AllocationRoute[];
  handleAllocationAdd: (
    selectedDelivery: SelectedAllocationDelivery,
  ) => () => void;
  handleAllocationDelete: (
    selectedDelivery: SelectedAllocationDelivery,
  ) => () => void;
  handleAllocationWithSmartDispatchAdd: (
    allocations: AllocationRoute[],
  ) => void;
  originAllocations?: GetAllocationDetailServerModel["allocationInfo"];
}

const DetailRight = ({
  allocations,
  handleAllocationAdd,
  handleAllocationDelete,
  handleAllocationWithSmartDispatchAdd,
  originAllocations,
}: DetailRightProps) => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const [isSmartDispatchBtnDisabled, setIsSmartDispatchBtnDisabled] =
    useState(true);

  const { modalRef, handleModalOpen } = useModal();
  const { selectDate, handleChangeSelectDate, handleSearchCancel } =
    useSelectEtaDate();
  const { isLoading, deliveries, setDeliveries } = useDelivery(
    selectDate,
    allocations,
    originAllocations,
  );
  const { refetch, isSmartDispatchFetching } = useDispatchOptimizing(
    deliveries,
    handleAllocationWithSmartDispatchAdd,
    setDeliveries,
  );

  const handleCalendarModal = () => {
    handleModalOpen(
      <CalendarModal
        ref={modalRef}
        title="Search by pick-up ETA date"
        selectDate={selectDate}
        handleChangeSelectDate={handleChangeSelectDate}
        byStartDate={dayjs().format("YYYY-MM-DD")}
      />,
    )();
  };

  const handleSmartDispatch = (): void => {
    if (deliveries) {
      refetch();
    }
  };

  useEffect(() => {
    if (deliveries.length) {
      const puEtaTimes = deliveries.map((delivery) =>
        new Date(delivery.puEta).getTime(),
      );
      const minTime = new Date(Math.min(...puEtaTimes));
      const maxTime = new Date(Math.max(...puEtaTimes));
      const timeDifference = maxTime.getTime() - minTime.getTime();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      const isOver24Hours = hoursDifference > 24;

      setIsSmartDispatchBtnDisabled(isOver24Hours);
    }
  }, [deliveries]);

  return (
    <S.Wrapper>
      <S.CalendarBtnWrapper>
        <S.CalendarBtnsWrapper>
          <S.CalendarBtn type="button" onClick={handleCalendarModal}>
            <CalendarIcon />
            {selectDate[0] && selectDate[1] ? (
              <S.CalendarInputValue>
                {formatDate(selectDate[0])} ~ {formatDate(selectDate[1])}
              </S.CalendarInputValue>
            ) : (
              <S.CalendarInputPlaceholder>
                {t("Search by pick-up ETA date")}
              </S.CalendarInputPlaceholder>
            )}
          </S.CalendarBtn>
          {selectDate[0] && selectDate[1] && (
            <Button
              variant="icon"
              css={S.deleteIconBtn}
              icon={<DeleteIcon20 />}
              handleClickBtn={handleSearchCancel}
            />
          )}
        </S.CalendarBtnsWrapper>
      </S.CalendarBtnWrapper>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {deliveries.length ? (
              <S.Body hasBottomHr={deliveries.length > 1}>
                {deliveries.map((delivery) => (
                  <DeliveryCard
                    key={delivery.orderId}
                    delivery={delivery}
                    handleAllocationAdd={handleAllocationAdd(delivery)}
                    handleAllocationDelete={handleAllocationDelete(delivery)}
                  />
                ))}
              </S.Body>
            ) : (
              <NoResult />
            )}
          </>
        )}
        <S.BtnWrapper>
          <Button
            css={S.smartDispatchBtn}
            isLoading={isSmartDispatchFetching}
            isDisabled={isSmartDispatchBtnDisabled}
            label="Smart dispatch"
            variant="secondaryLarge"
            handleClickBtn={handleSmartDispatch}
          />
          <Tooltip
            css={S.tooltip(language)}
            badgeContent="Beta"
            title="Smart dispatch"
            tooltipBtnContent={QuestionIcon}
            position="TOP_RIGHT"
          >
            <ol>
              <li>{t("ETA time range within 24 hours")}</li>
              <li>{t("Fixed 80km/h speed")}</li>
              <li>{t("Without considering vehicle type and capacity")}</li>
            </ol>
          </Tooltip>
        </S.BtnWrapper>
      </div>
    </S.Wrapper>
  );
};

export default DetailRight;
