import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    position: relative;
    height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT})`};
  `}
`;

export const FoldableLayout = styled.section<{ isFold: boolean }>`
  ${({ theme, isFold }) => css`
    position: absolute;
    display: flex;
    height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT})`};
    transform: ${isFold ? "translateX(-100%)" : "none"};
    transition-property: transform;
    transition-duration: 0.5s;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
  `}
`;
