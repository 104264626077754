import { css, Theme } from "@emotion/react";

import {
  FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES,
  SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES,
} from "assets";
import type { CompanyType } from "types";

export const table = (clientType: CompanyType) => css`
  tr {
    grid-template-columns: ${clientType === "shipper" ||
    clientType === "carrier"
      ? SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES
      : FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

export const linknoData = (theme: Theme) => css`
  ${theme.button.outlineMedium};
  margin: 16px 0 0;
`;
