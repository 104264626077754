import React from "react";

import { LabelContentTable } from "@/components";
import { addrFormatter, formatICTDateTime, formatPhoneNum } from "@/utils";

import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementDropOffInfo.styled";

interface OrderManagementDropOffInfoProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementDropOffInfo = ({
  data,
}: OrderManagementDropOffInfoProps) => {
  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address">
          <S.AddressWrapper>
            <div>{data.dropOffInfo.doName}</div>
            <S.AddressContent>
              {addrFormatter(
                data.dropOffInfo.doAddr,
                data.dropOffInfo.doAddrDetail,
              )}
            </S.AddressContent>
          </S.AddressWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge">
          {data.dropOffInfo.doPic} ({formatPhoneNum(data.dropOffInfo.doPhone)})
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA">
          {formatICTDateTime(data.dropOffInfo.doEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD">
          {data.dropOffInfo.doEtd
            ? formatICTDateTime(data.dropOffInfo.doEtd)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementDropOffInfo;
