import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@/contexts";

const ROOT = "/";

const AuthRoute = () => {
  const { pathname, search } = useLocation();

  const { initializing, auth } = useAuth();

  if (initializing) return null;

  return auth.isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={ROOT} state={{ from: pathname + search }} />
  );
};

export default AuthRoute;
