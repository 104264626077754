import React from "react";
import { isEmpty } from "lodash-es";

import { NoResult } from "@/components";
import type { FavoriteOrdersClientModel } from "@/types";

import FavoriteOrderCard from "./card/FavoriteOrderCard";
import * as S from "./FavoriteOrderList.styled";

interface FavoriteOrderListProps {
  className?: string;
  favoriteOrders: FavoriteOrdersClientModel[];
  searchValue: string;
  selectedOrderId: string | null;
  handleSelectFavorite: (favoriteId: string) => () => void;
}

const FavoriteOrderList = ({
  className,
  favoriteOrders,
  searchValue,
  selectedOrderId,
  handleSelectFavorite,
}: FavoriteOrderListProps) => {
  return (
    <S.Root className={className}>
      {!isEmpty(favoriteOrders) ? (
        <S.Favorites>
          {favoriteOrders?.map((order) => (
            <li key={order.favoriteId}>
              <FavoriteOrderCard
                data={order}
                selectedOrderId={selectedOrderId}
                searchValue={searchValue}
                handleSelectFavorite={handleSelectFavorite(order.favoriteId)}
              />
            </li>
          ))}
        </S.Favorites>
      ) : (
        <S.NoDataContainer>
          <NoResult />
        </S.NoDataContainer>
      )}
    </S.Root>
  );
};

export default FavoriteOrderList;
