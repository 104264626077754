import { TAB } from "@/assets";
import SelectedTruckMarkerSvg from "@/assets/icon/ic_selected_truck.svg";
import DefaultTruckMarkerSvg from "@/assets/icon/ic_default_truck.svg";

export const MONITORING_QUERY_PARAMS = {
  ORDER_ID: "orderId",
  PAGE: "page",
  SEARCH: "search",
  TAB: "tab",
  TRUCK_ID: "truckId",
  TYPE: "type",
} as const;

export const MONITORING_TABS = [TAB.SHIPPING, TAB.WAITING] as const;

export const TRUCK_ICON = {
  default: DefaultTruckMarkerSvg,
  selected: SelectedTruckMarkerSvg,
} as const;
