import React from "react";

import {
  WarningIcon64,
  ChromeIcon,
  FirefoxIcon,
  EdgeIcon,
  SafariIcon,
} from "@/assets";

import * as S from "./IESupport.styled";

const IESupport = () => {
  const title = "Browser not supported";
  const desc =
    "You're using a web browser we don't support. \n Please use one of these options to imporve your experience.";
  const browsers = {
    "Google Chrome": <ChromeIcon />,
    "Mozilla Firefox": <FirefoxIcon />,
    "Microsoft Edge": <EdgeIcon />,
    Safari: <SafariIcon />,
  };

  return (
    <S.Root>
      <h1 className="a11y">IE not supported</h1>
      <S.ContentWrapper>
        <WarningIcon64 />
        <S.ContentItem>
          <S.Title>{title}</S.Title>
          <S.Desc>{desc}</S.Desc>
        </S.ContentItem>
      </S.ContentWrapper>
      <S.BrowserWrapper>
        {Object.entries(browsers).map(([browser, icon]) => (
          <S.BrowserItem key={browser}>
            {icon}
            <span>{browser}</span>
          </S.BrowserItem>
        ))}
      </S.BrowserWrapper>
    </S.Root>
  );
};

export default IESupport;
