import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { lowerCase } from "lodash-es";

import { Button, ConfirmModal, InternalLink } from "@/components";
import { useModal, useToast } from "@/hooks";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";

import { useCancelOrderManagement } from "services";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementBtns.styled";

interface OrderManagementBtnsProps {
  isHideOrderCancelBtn: boolean;
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementBtns = ({
  isHideOrderCancelBtn,
  data,
}: OrderManagementBtnsProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { mutate: cancelOrderMutate } = useCancelOrderManagement();

  const { handleModalOpen, handleModalClose, modalRef } = useModal();
  const { addToast } = useToast();

  const handleOrderCancel = () => {
    cancelOrderMutate(id!, {
      onSuccess: () => {
        addToast(COMMON_TOAST_MSG.SUCCESS.ORDER_CANCEL_DONE);
      },
      onError: () => {
        // TODO: 맞는 에러에 따라 토스트 할 수 있도록 수정
        addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
      },
    });

    handleModalClose();
  };

  return (
    <S.BtnWrapper>
      <InternalLink
        variant="outlineMedium"
        to={`${PATH.ORDER_MANAGEMENT}?tab=${lowerCase(data?.status)}`}
      >
        {t("Back to list")}
      </InternalLink>
      {!isHideOrderCancelBtn && (
        <Button
          variant="errorMedium"
          label="Cancel order"
          handleClickBtn={handleModalOpen(
            <ConfirmModal
              ref={modalRef}
              title="Cancel order"
              desc={`Are you sure you want to cancel this order?\nYou can't undo this action.`}
              closeBtnLabel="No"
              negBtnLabel="Yes"
              callbackFn={handleOrderCancel}
            />,
          )}
        />
      )}
    </S.BtnWrapper>
  );
};

export default OrderManagementBtns;
