import Cookies from "js-cookie";

import type { LoginServerModel } from "@/types";

export type UserCB = (user: LoginServerModel | null, error: any) => void;

export class Auth {
  private static instance: Auth | null = null;

  static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private cookieKey = "COCOTRUCK_LOGISTICS";
  private user: LoginServerModel | null = null;
  private cb: UserCB | null = null;

  private constructor() {}

  get isAuth() {
    return !!this.user?.refreshToken;
  }

  get grade() {
    return this.user?.grade;
  }

  get accessToken() {
    return this.user?.accessToken;
  }

  get refreshToken() {
    return this.user?.refreshToken!;
  }

  get staffId() {
    return this.user?.staffId;
  }

  get companyId() {
    return this.user?.companyId;
  }

  get companyName() {
    return this.user?.companyName;
  }

  get language() {
    return this.user?.language;
  }

  get clientType() {
    return this.user?.clientType;
  }

  get position() {
    if (this.user !== null && "lat" in this.user && "lng" in this.user) {
      if (this.user.lat === null) return null;

      return { lat: +this.user.lat, lng: +this.user.lng };
    }
  }

  get gpsType() {
    if (this.user !== null && "gpsType" in this.user) {
      return this.user.gpsType;
    }
  }

  changeAccessToken(token: string) {
    if (!this.user) return;

    const newUser = { ...this.user, accessToken: token };
    Cookies.set(this.cookieKey, JSON.stringify(newUser));
    this.setUser(newUser);
  }

  onAuthStateChanged(cb: UserCB) {
    this.cb = cb;

    this.onUserChange(this.user);

    return () => {
      this.cb = null;
    };
  }

  private onUserChange(
    user: LoginServerModel | null,
    error?: { message: string },
  ) {
    this.cb && this.cb(user, error);
  }

  private setUser(user: LoginServerModel) {
    this.user = user;
  }

  save(data: LoginServerModel) {
    Cookies.set(this.cookieKey, JSON.stringify(data), {
      expires: 14,
      secure: true,
    });
    this.onUserChange(data);
    this.resolveUser();
  }

  load() {
    const key = Cookies.get(this.cookieKey);

    if (!key) return;

    this.user = JSON.parse(key);
  }

  clear() {
    Cookies.remove(this.cookieKey);
    this.user = null;
  }

  resolveUser() {
    if (window) {
      const signedInUser = Cookies.get(this.cookieKey);

      if (signedInUser) {
        this.setUser(JSON.parse(Cookies.get(this.cookieKey)!));
      }
    }

    return this;
  }
}
