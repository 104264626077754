import React from "react";

import { Filter } from "@/components";

import { PROPERTY_DRIVER_FILTER_STATUS_RADIOS } from "assets";

const PropertyDriverFilter = () => {
  const searchLabels = [
    {
      queryKey: "status",
      label: "Search",
      options: PROPERTY_DRIVER_FILTER_STATUS_RADIOS,
    },
    { queryKey: "name", label: "Driver name" },
    { queryKey: "linked", label: "Linking" },
  ];

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio
            queryKey="status"
            radios={PROPERTY_DRIVER_FILTER_STATUS_RADIOS}
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Driver name">
          <Filter.Input
            maxLength={150}
            queryKey="name"
            placeholder="Driver name"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input
            maxLength={20}
            queryKey="linked"
            placeholder="Plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default PropertyDriverFilter;
