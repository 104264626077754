import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ hasFile: boolean; hasErr: boolean }>`
  ${({ theme, hasFile, hasErr }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    border: 1px solid ${hasErr ? theme.color.red_20 : theme.color.gray_35};
    border-radius: 2px;
    padding-left: 12px;
    color: ${hasFile ? theme.color.black : theme.color.gray_50};
    background-color: ${theme.color.white};
  `}
`;

export const FileBtnWrapper = styled.div`
  ${({ theme }) => css`
    width: 48px;
    height: 100%;
    margin-left: auto;
    border-left: 1px solid ${theme.color.gray_30};
  `}
`;

export const FileUpload = styled.label`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 0 2px 2px 0;
    padding: 0 12px;
    color: ${theme.color.black};
    background-color: ${theme.color.white};

    input {
      display: none;
    }

    svg path {
      fill: ${theme.color.gray_60};
    }

    :hover {
      cursor: pointer;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const DeleteBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg path {
      fill: ${theme.color.gray_60};
    }

    :hover {
      cursor: pointer;
      background-color: ${theme.color.gray_10};
    }
  `}
`;
