import React from "react";
import { useTranslation } from "react-i18next";

import { DialogBtn } from "@/components";
import { formatPhoneNum } from "@/utils";
import { PATH, PhoneIcon, TruckIcon } from "@/assets";

import { ClickCard } from "components";
import type {
  AllocationAllTruck,
  GetAllocationDriverDetailServerModel,
  GetAllocationDriversServerItem,
  GetAllocationDetailDriverServerItem,
} from "types";
import DriverEditDialog from "./driverEditDialog/DriverEditDialog";
import TruckEditDialog from "./truckEditDialog/TruckEditDialog";
import * as S from "./SelectedAllocationCard.styled";

interface SelectedAllocationCardProps {
  selectedAllocationDriver:
    | GetAllocationDriverDetailServerModel
    | GetAllocationDetailDriverServerItem
    | GetAllocationDriversServerItem
    | null;
  changeSelectedAllocationEdit?: (
    allocation: GetAllocationDetailDriverServerItem,
  ) => void;
  changeSelectedAllocationCreate?: (
    allocation: GetAllocationDriverDetailServerModel,
  ) => void;
  refetch?: () => void;
  isClickAble?: boolean;
  isNeedDriverEdit?: boolean;
  isNeedTruckEdit?: boolean;
}

const SelectedAllocationCard = ({
  selectedAllocationDriver,
  changeSelectedAllocationEdit,
  changeSelectedAllocationCreate,
  refetch,
  isClickAble = false,
  isNeedDriverEdit,
  isNeedTruckEdit,
}: SelectedAllocationCardProps) => {
  const { t } = useTranslation();

  const handleChangeDriver = (item: any) => {
    if (!changeSelectedAllocationEdit) return;

    changeSelectedAllocationEdit({
      ...(selectedAllocationDriver as GetAllocationDetailDriverServerItem),
      driverId: item.driverId,
      driverName: item.driverName,
      phone: item.phone,
    });
  };

  const handleChangeTruck = (item: AllocationAllTruck) => {
    changeSelectedAllocationCreate &&
      changeSelectedAllocationCreate({
        ...(selectedAllocationDriver as GetAllocationDriverDetailServerModel),
        truckId: item.truckId,
        truckType: item.typeEn,
        registNumber: item.registNumber,
      });

    changeSelectedAllocationEdit &&
      changeSelectedAllocationEdit({
        ...(selectedAllocationDriver as GetAllocationDetailDriverServerItem),
        truckId: item.truckId,
        truckType: item.typeEn,
        registNumber: item.registNumber,
      });

    if (changeSelectedAllocationCreate || changeSelectedAllocationEdit) {
      refetch && refetch();
    }
  };

  if (!selectedAllocationDriver) return null; // TODO: 스켈레톤으로 수정 필요

  return (
    <ClickCard
      isClickAble={isClickAble}
      path={`${PATH.ALLOCATION_CREATE}?driverId=${selectedAllocationDriver?.driverId}`}
    >
      <ClickCard.Header css={S.clickCardHeader(isNeedDriverEdit)}>
        <h3>{selectedAllocationDriver.driverName}</h3>
        {isNeedDriverEdit && (
          <DialogBtn
            css={S.editDriverDialogBtn}
            popup={({ handleDialogClose }) => (
              <DriverEditDialog
                selectedDriverId={selectedAllocationDriver?.driverId!}
                handleDialogClose={handleDialogClose}
                cbFn={handleChangeDriver}
              />
            )}
          >
            {t("Change")}
          </DialogBtn>
        )}
      </ClickCard.Header>
      <ClickCard.Body>
        <ClickCard.BodyDetail css={S.clickCardBodyDetail}>
          <TruckIcon />
          <span>
            {t(selectedAllocationDriver.truckType) ?? "-"}{" "}
            {`(${selectedAllocationDriver.registNumber ?? "-"})`}
          </span>
          {isNeedTruckEdit && (
            <DialogBtn
              css={S.editTruckDialogBtn}
              popup={({ handleDialogClose }) => (
                <TruckEditDialog
                  selectedTruckId={
                    (
                      selectedAllocationDriver as GetAllocationDetailDriverServerItem
                    )?.truckId!
                  }
                  handleDialogClose={handleDialogClose}
                  cbFn={handleChangeTruck}
                />
              )}
            >
              {t("Change")}
            </DialogBtn>
          )}
        </ClickCard.BodyDetail>
        <ClickCard.BodyDetail css={S.clickCardBodyDetail}>
          <PhoneIcon css={S.phoneIcon} />
          {selectedAllocationDriver && (
            <span>
              {formatPhoneNum(selectedAllocationDriver?.phone) || "-"}
            </span>
          )}
        </ClickCard.BodyDetail>
      </ClickCard.Body>
    </ClickCard>
  );
};

export default SelectedAllocationCard;
