import { numericOnly } from "@/utils";
import { deleteComma } from "@/utils/formatter"; // TODO: Import 에러로 임시적 조치 (추후 수정 필요)

export const calculateVatAmount = (subtotal = "0", taxRate: string) => {
  const subtotalNum = deleteComma(subtotal);

  return Math.round((+numericOnly(subtotalNum) * +taxRate) / 100);
};

export const calculateTotalAmount = (subtotal: string, taxRate: string) => {
  const subtotalNum = deleteComma(subtotal);
  const vatAmount = calculateVatAmount(subtotalNum, taxRate);

  return +subtotalNum + vatAmount;
};

export const calculateTaxRateAmount = (price: string | number, vat: number) => {
  if (!vat) return 8;

  if (Math.round((100 * vat) / +numericOnly(price)) >= 10) {
    return 10;
  } else {
    return 8;
  }
};

export const calculateTaxAmount = (
  totalAmount: string,
  taxPercentage: string,
) => {
  const taxAmount = Math.round((+totalAmount * +taxPercentage) / 100);
  const principalAmount = +totalAmount - taxAmount;

  return { principalAmount, taxAmount };
};

export const calculateDropDecimal = (number: number) => {
  const rounded = Math.round(number * 10) / 10;

  return Math.round(rounded);
};
