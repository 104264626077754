import { useQuery } from "@tanstack/react-query";

import {
  getMonitoringOrderDetailAPI,
  getShipperMonitoringOrderDetailAPI,
  getMonitoringTruckDetailAPI,
  getMonitoringTrucksAPI,
} from "apis";
import type { GetMonitoringTrucksQueryModel } from "types";

export const monitoringKeys = {
  all: ["monitoring"] as const,
  lists: () => [...monitoringKeys.all, "list"] as const,
  list: (req: GetMonitoringTrucksQueryModel) =>
    [...monitoringKeys.lists(), { req }] as const,
  details: () => [...monitoringKeys.all, "detail"] as const,
  detail: (truckId: string, type: "truck" | "order") =>
    [...monitoringKeys.details(), { truckId, type }] as const,
};

export const useGetMonitoringTrucks = (req: GetMonitoringTrucksQueryModel) => {
  return useQuery({
    queryKey: monitoringKeys.list(req),
    queryFn: () => getMonitoringTrucksAPI(req),
  });
};

export const useGetMonitoringTruckDetail = (
  truckId: string,
  { suspense, enabled }: { suspense: boolean; enabled: boolean },
) => {
  return useQuery({
    queryKey: monitoringKeys.detail(truckId, "truck"),
    queryFn: () => getMonitoringTruckDetailAPI(truckId),
    suspense,
    enabled,
  });
};

export const useGetMonitoringOrderDetail = (orderId: string) => {
  return useQuery({
    queryKey: monitoringKeys.detail(orderId, "order"),
    queryFn: () => getMonitoringOrderDetailAPI(orderId),
  });
};

//TODO: 타입이 상이하여 api 호출 분리함 추후 확인 후 수정 필요
export const useGetShipperMonitoringOrderDetail = (
  orderId: string,
  { suspense, enabled }: { suspense: boolean; enabled: boolean },
) => {
  return useQuery({
    queryKey: monitoringKeys.detail(orderId, "order"),
    queryFn: () => getShipperMonitoringOrderDetailAPI(orderId),
    suspense,
    enabled,
  });
};
