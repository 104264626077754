import React from "react";

import { LabelContentTable } from "@/components";
import { formatICTDateTime, formatPhoneNum } from "@/utils";

import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationPickUpInformation.styled";

interface QuotationPickUpInformationProps {
  data: GetQuotationDetailServerModel<CompanyType>;
}

const QuotationPickUpInformation = ({
  data,
}: QuotationPickUpInformationProps) => {
  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Address">
          <S.AddrWrapper>
            <span>{data.pickUpInfo.puAddr}</span>
            {data.pickUpInfo.puAddrDetail && (
              <S.AddrDetail>{data.pickUpInfo.puAddrDetail}</S.AddrDetail>
            )}
          </S.AddrWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Person in charge">
          {data.pickUpInfo.puPic}{" "}
          {`(${formatPhoneNum(data.pickUpInfo.puPhone)})`}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETA">
          {formatICTDateTime(data.pickUpInfo.puEta)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="ETD">
          {data.pickUpInfo.puEtd
            ? formatICTDateTime(data.pickUpInfo.puEtd)
            : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default QuotationPickUpInformation;
