import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";
import { filterOrderNum } from "@/utils";

import { useGetOrderManagementCompleted } from "services";
import type { GetOrderManagementCompletedQueryModel } from "types";
import CompletedSection from "./section/CompletedSection";
import OrderCompletedFilter from "./filter/OrderCompletedFilter";
import * as S from "./OrderCompleted.styled";

const OrderCompleted = () => {
  const { filters } = useFilterParams<
    GetOrderManagementCompletedQueryModel["query"]
  >(["page", "orderNumber"]);

  const query = {
    ...filters,
    ...(filters.orderNumber && {
      orderNumber: filterOrderNum(filters.orderNumber),
    }),
  };

  const { data, dataUpdatedAt, refetch } = useGetOrderManagementCompleted({
    query,
  });

  return (
    <>
      <OrderCompletedFilter />
      {data && (
        <>
          <CompletedSection
            data={data}
            dataUpdatedAt={dataUpdatedAt}
            refetch={refetch}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default OrderCompleted;
