import React from "react";

import { ConfirmModal } from "@/components";

interface QuotationRejectModalProps {
  isLoading?: boolean;
  handleReject: () => void;
}

const QuotationRejectModal = React.forwardRef(
  (
    { isLoading, handleReject }: QuotationRejectModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        isLoading={isLoading}
        title="Reject this quotation?"
        desc="You cannot proceed with this quotation after rejecting."
        negBtnLabel="Reject"
        closeBtnLabel="Cancel"
        callbackFn={handleReject}
      />
    );
  },
);

QuotationRejectModal.displayName = "QuotationRejectModal";

export default QuotationRejectModal;
