import React, { useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

import * as S from "./Loading.styled";

interface LoadingProps {
  className?: string;
  as?: any;
  icon?: any;
}

const Loading = ({ className, as, icon }: LoadingProps) => {
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    // susepense 처럼 동작
    // 특정 조건을 만족하기 전까지 Rendering을 막음
    const isTimeout = setTimeout(() => {
      setIsDelayed(true);
    }, 1000);
    return () => clearTimeout(isTimeout);
  }, []);

  if (!isDelayed) {
    return icon ? icon : null;
  }

  return (
    <S.Root className={className} as={as}>
      <ScaleLoader color="black" width={8} height={50} speedMultiplier={0.5} />
    </S.Root>
  );
};

export default Loading;
