import React from "react";

import { Table } from "@/components";
import { filterTableColumns } from "@/utils";

import { formatTrucksTableData, formatTruckNumTypeOption } from "utils";
import { TRUCKLIST_TABLE_COLUMNS } from "assets";
import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationTruckList.styled";

interface QuotationTruckListProps {
  trucks: GetQuotationDetailServerModel<CompanyType>["trucks"];
}

const QuotationTruckList = ({ trucks }: QuotationTruckListProps) => {
  const filteredColumns = filterTableColumns(TRUCKLIST_TABLE_COLUMNS);

  const tableTruckNumTypeOption = formatTruckNumTypeOption(trucks);

  return (
    <Table
      css={S.table(tableTruckNumTypeOption.length)}
      title="Truck list"
      rowCount={tableTruckNumTypeOption.length}
    >
      <Table.Head columnList={TRUCKLIST_TABLE_COLUMNS} />
      <Table.Body css={S.tbody(tableTruckNumTypeOption.length > 0)}>
        {tableTruckNumTypeOption.length ? (
          tableTruckNumTypeOption.map((data, i) => (
            <Table.Row key={i}>
              {filteredColumns.map(({ key }) => (
                <Table.Cell key={key}>
                  {formatTrucksTableData(key, data)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default QuotationTruckList;
