import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const TruckStatusInfoWrapper = styled.div<{ hasBorderBottom: boolean }>`
  ${({ theme, hasBorderBottom }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-bottom: ${hasBorderBottom && `1px solid ${theme.color.gray_30}`};
    padding: 20px;
    background-color: ${theme.color.gray_00};

    & > div > div > label {
      ${theme.font.medium_14};
    }
  `}
`;

export const addrLabelContent = css`
  align-items: unset;
`;

export const TruckStatusInfoContent = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_80};
  `}
`;

export const labelContent = (theme: Theme) => css`
  & > div > label {
    ${theme.font.medium_14};
  }
`;
