import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_hr, mixin_list_layout_detail_body } from "@/styles";

export const Body = styled.div`
  ${mixin_list_layout_detail_body};
  display: flex;
  flex-direction: column;
`;

export const Hr = styled.hr`
  ${mixin_hr};
`;

export const H2 = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    height: 48px;
    padding: 12px 20px;
    border-bottom: 1px solid ${theme.color.gray_30};
  `}
`;
