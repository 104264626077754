import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@/components";
import type { Languages } from "@/types";

import * as S from "./TableOfContent.styled";

type TableOfContentProps = {
  className?: string;
  pageName?: Languages;
  stickyTop?: React.ReactNode;
  stickyBottom?: React.ReactNode;
  contents: {
    heading: {
      headingLevel?: "h2" | "h3" | "h4";
      addHeadingElement?: React.ReactNode;
      text: string; // TODO: Language로 변경 필요.
    };
    content: React.ReactElement;
  }[];
};

const TableOfContent = ({
  className,
  pageName,
  stickyTop,
  stickyBottom,
  contents,
}: TableOfContentProps) => {
  const { t } = useTranslation();

  const [currentId, setCurrentId] = useState(contents[0].heading.text);

  const smoothScroll =
    (id: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      const targetElement = document.getElementById(id);
      if (targetElement) {
        window.scrollBy({
          top: targetElement.getBoundingClientRect().top - 100,
          behavior: "smooth",
        });
      }
    };

  useEffect(() => {
    const getIntersectionObserver = (
      setState: React.Dispatch<React.SetStateAction<string>>,
    ) => {
      const observerOption = {
        threshold: 0.1,
        rootMargin: "-104px 0px 0px 0px",
      };

      let prevYposition = 0;

      const observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries[0]?.isIntersecting ?? false;
        const currentY = window.scrollY;
        const direction = currentY > prevYposition ? "down" : "up";

        if (
          (direction === "down" && !isIntersecting) ||
          (direction === "up" && isIntersecting)
        ) {
          setState(entries[0]?.target?.id || "");
        }

        prevYposition = currentY;
      }, observerOption);

      return observer;
    };

    const observer = getIntersectionObserver(setCurrentId);
    const headingElements = contents.map(
      ({ heading }) => document.getElementById(heading.text) as HTMLElement,
    );

    headingElements.map((header) => observer.observe(header));
  }, []);

  return (
    <S.Root className={className}>
      <S.Sticky>
        {pageName && <S.PageName>{t(pageName)}</S.PageName>}
        {stickyTop && <S.StickyTop>{stickyTop}</S.StickyTop>}
        {contents.map(({ heading }, i) => (
          <S.Link
            key={i}
            href={`#${heading.text}`}
            isSelect={currentId === heading.text}
            onClick={smoothScroll(heading.text)}
          >
            {t(heading.text as Languages)}
          </S.Link>
        ))}
        {stickyBottom && <S.StickyBottom>{stickyBottom}</S.StickyBottom>}
      </S.Sticky>
      <S.CardWrapper>
        {contents.map(({ heading, content }, i) => (
          <Card key={i}>
            <Card.Content
              heading={heading.text as Languages}
              headingLevel={heading.headingLevel}
              addHeadingElement={heading.addHeadingElement}
            >
              {content}
            </Card.Content>
          </Card>
        ))}
      </S.CardWrapper>
    </S.Root>
  );
};

export default TableOfContent;
