/* eslint-disable no-useless-escape */
import { COMMON_VALID_MSG } from "@/constants";

import { checkPasswordLength } from "./length";

export const checkPasswordValidation = (password: string) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 체크
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // 소문자 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckThreeOfTwo =
    checkList.filter((element) => true === element).length >= 2;
  // 4개 중 2개가 true 이면 true

  return boolenCheckThreeOfTwo;
};

export const checkPwdValidation = (password: string) => {
  const checkPsssword = /^.{8,16}$/;
  if (!checkPsssword.test(password)) {
    return COMMON_VALID_MSG.PASSWORD_STEP_ONE;
  } else if (!checkPasswordValidation(password)) {
    return COMMON_VALID_MSG.PASSWORD_STEP_TWO;
  }
};

export const checkPasswordConfirm = (pw: string, otherPw: string) =>
  pw === otherPw;

export const checkPasswordsMatch = (
  password: string,
  confirmPassword: string,
) => {
  const isValidPassword =
    checkPasswordLength(password) && checkPasswordValidation(password);
  const isValidConfirmPassword =
    checkPasswordLength(confirmPassword) &&
    checkPasswordValidation(confirmPassword);

  return isValidPassword && isValidConfirmPassword;
};
