import React from "react";
import { useRecoilValue } from "recoil";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Card,
  Dropdown,
  ErrMsg,
  Input,
  LabelContent,
  UnitSuffixInput,
} from "@/components";
import { clientLanguage } from "@/stores";
import {
  checkTwoDecimalDigit,
  formatterDecimal,
  formatVin,
  getNumPeriods,
} from "@/utils";
import type { Languages } from "@/types";

import { truckTypeOptionsState } from "stores";
import {
  checkCargoSizeLimit,
  formatTruckOptions,
  formatTruckTypes,
  selectDropdownForm,
} from "utils";
import { CARRIER_VALID_MSG, TEXT_LIMIT } from "constants/index";
import type { TruckForm } from "types";
import * as S from "./TruckCreateSpectification.styled";

const TruckCreateSpectification = () => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptions = useRecoilValue(truckTypeOptionsState);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    resetField,
    register,
  } = useFormContext<TruckForm>();

  const truckTypes = formatTruckTypes(language, truckTypeOptions);
  const truckOptions = formatTruckOptions(
    language,
    truckTypeOptions,
    watch("truckType")?.key!,
  );

  const getFormattedSizeValue = (
    prevValue: string,
    currentValue: string,
  ): string => {
    return (currentValue && !checkTwoDecimalDigit(currentValue)) ||
      !checkCargoSizeLimit(currentValue)
      ? prevValue
      : currentValue;
  };

  const handleBlurSizeInput = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setValue(
      name as "size.length" | "size.depth" | "size.height",
      `${+formatterDecimal(value ?? "")}`,
    );
  };

  return (
    <Card.Content heading="Specification">
      <LabelContent label="Truck type" direction="vertical" isRequired>
        <Controller
          name="truckType"
          control={control}
          render={({ field: { value, onChange } }) => {
            const selectDropdown = (key: string) => {
              resetField("truckOption");
              selectDropdownForm(truckTypes, onChange)(key);
            };

            return (
              <Dropdown
                placeholder="Select the truck type"
                options={truckTypes ?? []}
                hasError={!!errors.truckType?.message}
                selectedOption={value}
                handleSelect={selectDropdown}
              />
            );
          }}
        />
        {errors.truckType?.message && (
          <ErrMsg>{errors.truckType?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Truck option" direction="vertical" isRequired>
        <Controller
          name="truckOption"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Dropdown
              placeholder="Select the truck option"
              options={truckOptions ?? []}
              hasError={!!errors.truckOption?.message}
              selectedOption={value}
              disabled={!watch("truckType").key}
              handleSelect={selectDropdownForm(truckOptions, onChange)}
            />
          )}
        />
        {errors.truckOption?.message && (
          <ErrMsg>{errors.truckOption?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent
        css={S.labelContentSize}
        label="Container size"
        direction="vertical"
        isRequired
      >
        <S.SizeInputWrapper>
          <UnitSuffixInput
            id="length"
            placeholder="Length"
            value={watch("size").length || ""}
            unit="m"
            err={errors.size?.length?.message}
            register={register?.("size.length", {
              setValueAs: (currentValue: string) =>
                getFormattedSizeValue(`${watch("size").length}`, currentValue),
              onBlur: handleBlurSizeInput,
            })}
          />
          <UnitSuffixInput
            id="depth"
            placeholder="Depth"
            value={watch("size").depth || ""}
            unit="m"
            err={errors.size?.depth?.message}
            register={register?.("size.depth", {
              setValueAs: (currentValue: string) =>
                getFormattedSizeValue(`${watch("size").depth}`, currentValue),
              onBlur: handleBlurSizeInput,
            })}
          />
          <UnitSuffixInput
            id="height"
            placeholder="Height"
            value={watch("size").height || ""}
            unit="m"
            err={errors.size?.height?.message}
            register={register?.("size.height", {
              setValueAs: (currentValue: string) =>
                getFormattedSizeValue(`${watch("size").height}`, currentValue),
              onBlur: handleBlurSizeInput,
            })}
          />
        </S.SizeInputWrapper>
        <S.SizeInfo>
          {`- ${t("Maximum: Length 13.56 m, Depth 2.35 m, Height 2.7 m")}`}
        </S.SizeInfo>
        {errors?.size && !!Object.keys(errors.size).length && (
          <ErrMsg>
            {(errors?.size?.length?.message as Languages) ||
              (errors?.size?.depth?.message as Languages) ||
              (errors?.size?.height?.message as Languages)}
          </ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Brand" direction="vertical">
        <Input
          placeholder="Brand"
          value={watch("brand")}
          maxLength={TEXT_LIMIT.BRAND_LIMIT_VALUE}
          register={register("brand")}
        />
      </LabelContent>
      <LabelContent label="Model" direction="vertical">
        <Input
          placeholder="Model"
          value={watch("model")}
          maxLength={TEXT_LIMIT.MODEL_LIMIT_VALUE}
          register={register("model")}
        />
      </LabelContent>
      <LabelContent label="Year" direction="vertical">
        <Controller
          name="year"
          control={control}
          render={({ field: { value, onChange } }) => {
            const years = getNumPeriods(1990, new Date().getFullYear());

            return (
              <Dropdown
                css={S.dropdown}
                options={years}
                selectedOption={value}
                handleSelect={selectDropdownForm(years, onChange)}
              />
            );
          }}
        />
      </LabelContent>
      <LabelContent label="VIN" direction="vertical" isRequired>
        <Input
          placeholder="Enter 17 characters"
          value={watch("vin")}
          hasError={!!errors.vin?.message}
          maxLength={17}
          register={register("vin", {
            required: CARRIER_VALID_MSG.REQUIRED,
            setValueAs: formatVin,
            validate: (value) => value.length === 17 || CARRIER_VALID_MSG.VIN,
          })}
        />
        {errors.vin?.message && (
          <ErrMsg>{errors.vin?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
    </Card.Content>
  );
};

export default TruckCreateSpectification;
