import React from "react";
import { useFormContext } from "react-hook-form";

import { Card, ErrMsg, FormRadioBtn, Input, LabelContent } from "@/components";
import {
  calculateTotalAmount,
  calculateVatAmount,
  comma,
  deleteComma,
  formatPrice,
  numericOnly,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS, TAX_RADIOS } from "@/assets";
import type { Languages } from "@/types";

import type { OrderForm } from "types";
import * as S from "./OrderCreationPayment.styled";

const OrderCreationPayment = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<OrderForm>();

  const vatPrice = formatPrice(
    calculateVatAmount(watch("payment.subtotal"), watch("payment.tax")),
  );
  const totalPrice = formatPrice(
    calculateTotalAmount(watch("payment.subtotal"), watch("payment.tax")),
  );

  return (
    <Card>
      <Card.Content heading="Payment" headingLevel="h3">
        <S.ContentWrapper>
          <LabelContent label="Payment method" isRequired direction="vertical">
            <FormRadioBtn
              radioList={PAYMENT_METHOD_RADIOS}
              register={register("payment.method")}
            />
          </LabelContent>
          <LabelContent label="Subtotal" direction="vertical">
            <Input
              id="subtotal"
              maxLength={13}
              hasError={!!errors.payment?.subtotal?.message}
              placeholder="Enter a price (only numbers)"
              value={watch("payment.subtotal")}
              register={register("payment.subtotal", {
                setValueAs: (value: string) =>
                  comma(deleteComma(numericOnly(value))),
              })}
            />
            {errors.payment?.subtotal?.message && (
              <ErrMsg>{errors.payment.subtotal?.message as Languages}</ErrMsg> // NOTE: 필수값 에러가 아닌 서버에서 설정한 숫자보다 높을 때 나오는 발생하는 에러 메세지
            )}
          </LabelContent>
          <LabelContent label="Tax" direction="vertical">
            <FormRadioBtn
              radioList={TAX_RADIOS}
              register={register("payment.tax")}
            />
          </LabelContent>
          <S.PriceWrapper>
            <S.Hr />
            <LabelContent
              label="Subtotal"
              direction="horizontal"
              columnWidth={200}
            >
              <S.Price>{watch("payment.subtotal") || 0} ₫</S.Price>
            </LabelContent>
            <LabelContent label="Tax" direction="horizontal" columnWidth={200}>
              <S.Price>{vatPrice}</S.Price>
            </LabelContent>
            <LabelContent
              label="Total"
              direction="horizontal"
              columnWidth={200}
            >
              <S.Total>{totalPrice}</S.Total>
            </LabelContent>
          </S.PriceWrapper>
        </S.ContentWrapper>
      </Card.Content>
    </Card>
  );
};

export default OrderCreationPayment;
