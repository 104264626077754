import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { comma, deleteComma } from "@/utils";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";
import { AddressSearchType } from "@/types";

import { useGetPropertyGarageDetail, useUpdatePropertyGarage } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";

const schema = yup.object({
  garageName: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
  garageAddr: yup.object().shape({
    address: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
    addressDetail: yup.string().defined(),
    buildingName: yup.string().defined(),
    city: yup.string().defined(),
    coord: yup.object().shape({
      lng: yup.number().defined(),
      lat: yup.number().defined(),
    }),
  }),
  capacity: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
});

const initFormState = {
  garageName: "",
  garageAddr: {
    address: "",
    addressDetail: "",
    buildingName: "",
    city: "",
    coord: {
      lng: 0,
      lat: 0,
    },
  },
  capacity: "",
};

const useEditGarage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const garageId = searchParams.get("id") as string;

  const {
    register,
    watch,
    formState: { errors },
    setValue,
    setError,
    reset,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: initFormState,
    mode: "onTouched",
    resolver: yupResolver<typeof initFormState>(schema),
  });

  const { isLoading: isUpdateGarageLoading, mutate: editPropertyCarageMutate } =
    useUpdatePropertyGarage();
  const { data: garageDetail } = useGetPropertyGarageDetail(garageId, {
    enabled: !!garageId,
  });

  const { addToast } = useToast();

  const initEditData = {
    garageName: garageDetail?.name,
    garageAddr: {
      address: garageDetail?.address,
      addressDetail: garageDetail?.addressDetail ?? "",
      buildingName: "",
      city: "",
      coord: {
        lng: 0,
        lat: 0,
      },
    },
    capacity: `${comma(garageDetail ? garageDetail.capacity : 0)}`,
  };

  const handleSelectAddr = (locationInfo: AddressSearchType) => {
    setValue("garageAddr.address", locationInfo.address);
    setValue("garageAddr.buildingName", locationInfo.buildingName);
    setValue("garageAddr.city", locationInfo.city);
    setValue("garageAddr.coord.lng", +locationInfo.coord.lng);
    setValue("garageAddr.coord.lat", +locationInfo.coord.lat);

    clearErrors("garageAddr.address");
  };

  const customErrorCheck = () => {
    if (!watch("garageAddr.address")) {
      setError("garageAddr.address", { message: CARRIER_VALID_MSG.ADDRESS });
    }
  };

  const handleSubmitGarage = handleSubmit(
    (data) => {
      customErrorCheck();

      if (isEqual(initEditData, data)) {
        return navigate(-1);
      }

      const body = {
        name: data.garageName,
        address: data.garageAddr.address,
        ...(data.garageAddr.addressDetail && {
          addressDetail: data.garageAddr.addressDetail,
        }),
        lat: +data.garageAddr.coord.lat,
        lng: +data.garageAddr.coord.lng,
        capacity: +deleteComma(data.capacity),
      };

      editPropertyCarageMutate(
        { garageId, body },
        {
          onSuccess: () => {
            addToast(CARRIER_TOAST_MSG.SUCCESS.GARAGE_UPDATE_DONE);
            navigate(`${PATH.PROPERTY}?tab=garage`);
          },
          onError: (err) => {
            switch (err.response?.data.response) {
              case "GARAGE_NAME_EXISTS":
                addToast(CARRIER_TOAST_MSG.WARNING.GARAGE_NAME_EXIST);
                break;

              default:
                addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
            }
          },
        },
      );
    },
    () => customErrorCheck(),
  );

  useEffect(() => {
    if (!garageDetail) return;

    reset({
      garageName: garageDetail.name,
      garageAddr: {
        address: garageDetail.address,
        addressDetail: garageDetail.addressDetail ?? "",
        buildingName: "",
        city: "",
        coord: {
          lng: 0,
          lat: 0,
        },
      },
      capacity: `${comma(garageDetail.capacity)}`,
    });
  }, [garageDetail]);

  return {
    isUpdateGarageLoading,
    errors,
    register,
    watch,
    handleSelectAddr,
    handleSubmitGarage,
  };
};

export default useEditGarage;
