import { useSearchParams } from "react-router-dom";

import { useGoogleMap } from "@/contexts";
import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import { zIndex } from "@/styles";

import { rotateSVGIcon } from "utils";
import { TRUCK_ICON } from "assets";
import type { GetShipperMonitoringOrderDetailClientModel } from "types";

const updateTruckMarkerIcon = async (
  iconType: keyof typeof TRUCK_ICON,
  truckMarker?: GetShipperMonitoringOrderDetailClientModel["allocationList"][number],
) => {
  if (!truckMarker) return;

  const icon = await rotateSVGIcon({
    svgUrl: TRUCK_ICON[iconType],
    rotation: truckMarker.direction,
  });

  truckMarker.truckMarker?.setOptions({
    icon,
    zIndex:
      iconType === "selected"
        ? zIndex.GOOGLE_MAP_SELECTED_TRUCK_MARKER
        : zIndex.GOOGLE_MAP_DEFAULT_TRUCK_MARKER,
  });
};

const useOrderSelect = (
  orders?: GetShipperMonitoringOrderDetailClientModel["allocationList"],
  truckRef?: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null;
  }>,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { googleMap } = useGoogleMap();
  const { addToast } = useToast();

  const focusTruckCard = (selectedPlateNum: string): void => {
    truckRef?.current?.[selectedPlateNum]?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const focusTruckMarker = async (
    selectedPlateNum: string,
    isSamePlateNum?: boolean,
  ) => {
    if (!orders) return;

    const selectedTruck = orders.find(
      (allocation) => allocation.plateNumber === selectedPlateNum,
    );

    if (!selectedTruck) return;

    const truckPosition = selectedTruck.truckMarker?.getPosition();
    if (truckPosition) {
      googleMap?.panTo(truckPosition);
    }
    const updateTruckMarkerIcons = async (): Promise<void> => {
      const currentTruckMarker = orders.find(
        (truck) => truck.plateNumber === selectedPlateNum,
      );
      const otherTruckMarkers = orders.find(
        (truck) => truck.plateNumber !== selectedPlateNum,
      );

      if (!currentTruckMarker?.gpsAvailable) {
        await updateTruckMarkerIcon("default", otherTruckMarkers);
        return addToast(COMMON_TOAST_MSG.WARNING.CANNOT_FIND_GPS);
      }

      if (isSamePlateNum) {
        await updateTruckMarkerIcon("default", currentTruckMarker);
      } else {
        await updateTruckMarkerIcon("selected", currentTruckMarker);
        await updateTruckMarkerIcon("default", otherTruckMarkers);
      }
    };

    await updateTruckMarkerIcons();
    focusTruckCard(selectedPlateNum);
  };

  const updateOrderMarkerState = (
    isSameOrderId: boolean,
    selectedOrderId: string,
  ) => {
    const updateQueryParams = (): void => {
      if (isSameOrderId) {
        searchParams.delete("type");
        searchParams.delete("orderId");
      } else {
        searchParams.set("type", "card");
        searchParams.set("orderId", selectedOrderId);
      }

      setSearchParams(searchParams);
    };

    updateQueryParams();
  };

  const handleOrderCardClick =
    (selectedOrderId: string) => async (): Promise<void> => {
      const previousOrderId = searchParams.get("orderId");
      const isSameOrderId = previousOrderId === selectedOrderId;

      updateOrderMarkerState(isSameOrderId, selectedOrderId);
    };

  return { handleOrderCardClick, focusTruckMarker };
};

export default useOrderSelect;
