import { lowerCase, upperFirst } from "lodash-es";

export const makeNameString = (...name: (string | null)[]) => {
  return name.filter(Boolean).join(" ");
};

export const slashFirstUpperCase = (word: string) => {
  if (word.includes("/")) {
    const parts = word.split("/");
    const capitalizedParts = parts.map((part) => upperFirst(part));
    return capitalizedParts.join("/");
  } else {
    return upperFirst(word);
  }
};

export const upperCaseToupperFirst = (word: string) =>
  upperFirst(lowerCase(word));
