import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArrowVariantIcon } from "@/assets";

import * as S from "./PageBackHeader.styled";

interface PageBackHeaderProps {
  className?: string;
  children?: any;
  title: any;
  path: any;
}

const PageBackHeader = ({
  className,
  children,
  title,
  path,
}: PageBackHeaderProps) => {
  const { t } = useTranslation();

  return (
    <S.Root className={className}>
      <S.FrontWrapper>
        <Link to={path} replace>
          <ArrowVariantIcon />
        </Link>
        <span>{t(title)}</span>
      </S.FrontWrapper>
      {children}
    </S.Root>
  );
};

export default PageBackHeader;
