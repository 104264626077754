import React from "react";

import { DetailModal, SearchInput } from "@/components";
import { useModal } from "@/hooks";
import type { FavoriteOrdersClientModel } from "@/types";

import { useGetFavoriteOrders } from "services";
import useCarrierFavoriteModal from "./hooks/useFavoriteModal";
import FavoriteOrderList from "./list/FavoriteOrderList";
import * as S from "./FavoriteOrderModal.styled";

interface FavoriteOrderModalProps {
  className?: string;
  handleSelectFavoriteOrder: (
    selectedFavoriteOrder: FavoriteOrdersClientModel,
  ) => void;
}

const FavoriteOrderModal = React.forwardRef(
  (
    { className, handleSelectFavoriteOrder }: FavoriteOrderModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { data: favoriteOrders } = useGetFavoriteOrders();

    const { handleModalClose } = useModal();
    const {
      searchValue,
      selectedFavoriteId,
      filteredDatas,
      handleSearch,
      handleChangeInput,
      handleResetInput,
      handleSelectFavorite,
    } = useCarrierFavoriteModal(favoriteOrders);

    const handleApplyFormState = () => {
      const selectedFavoriteOrder = filteredDatas.filter(
        (data) => data.favoriteId === selectedFavoriteId,
      )[0];

      handleSelectFavoriteOrder(selectedFavoriteOrder);
      handleModalClose();
    };

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        className={className}
        type="submit"
        title="Favorite"
        posBtnLabel="Select"
        closeBtnLabel="Close"
        callbackFn={handleApplyFormState}
      >
        <SearchInput
          css={S.searchInput}
          placeholder="Search by favorite name"
          value={searchValue}
          handleChange={handleChangeInput}
          handleSearch={handleSearch}
          handleReset={handleResetInput}
        />
        <FavoriteOrderList
          css={S.favoriteList}
          favoriteOrders={filteredDatas}
          searchValue={searchValue}
          selectedOrderId={selectedFavoriteId}
          handleSelectFavorite={handleSelectFavorite}
        />
      </DetailModal>
    );
  },
);

FavoriteOrderModal.displayName = "FavoriteOrderModal";

export default FavoriteOrderModal;
