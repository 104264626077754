import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  ErrMsg,
  FormRadioBtn,
  LabelContentTable,
  Textarea,
  UnitSuffixInput,
} from "@/components";
import {
  calculateTotalAmount,
  comma,
  deleteComma,
  formatPrice,
  calculateVatAmount,
  findLookupTableLabel,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS, TAX_RADIOS } from "@/assets";
import { COMMON_VALID_MSG } from "@/constants";
import type { Languages } from "@/types";

import type {
  QuotationDetailForm,
  CompanyType,
  GetQuotationDetailServerModel,
} from "types";
import * as S from "./QuotationOfferPrice.styled";

interface QuotationOfferPriceProps {
  data: GetQuotationDetailServerModel<CompanyType>;
  handleReject: () => void;
  handleCheckValidForm: () => void;
}

const QuotationOfferPrice = ({
  data,
  handleReject,
  handleCheckValidForm,
}: QuotationOfferPriceProps) => {
  const { t } = useTranslation();

  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<QuotationDetailForm>();

  const subTotal = deleteComma(watch("subTotal"));
  const price = formatPrice(+subTotal);
  const totalPrice = formatPrice(calculateTotalAmount(subTotal, watch("tax")));
  const vatPrice = formatPrice(calculateVatAmount(subTotal, watch("tax")));
  const vat = `(${watch("tax")}%) ${vatPrice}`;

  const paymethodLabel =
    findLookupTableLabel(PAYMENT_METHOD_RADIOS, data.paymentInfo.pay) ?? "";

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Payment method">
            {paymethodLabel ? t(paymethodLabel) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.content}
            label="Subtotal"
            isRequired
          >
            <UnitSuffixInput
              css={S.suffixInput}
              id="subTotal"
              placeholder="Enter a price (only numbers)"
              value={watch("subTotal")}
              err={errors.subTotal}
              unit="₫"
              maxLength={13}
              register={register("subTotal", {
                required: COMMON_VALID_MSG.REQUIRED,
                setValueAs: comma,
              })}
            />
            {errors.subTotal?.message && (
              <ErrMsg>{errors.subTotal.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Tax rate" isRequired>
            <FormRadioBtn radioList={TAX_RADIOS} register={register("tax")} />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Total">
            <S.PriceWrapper>
              <S.Total>{totalPrice}</S.Total>
              <S.SubtotalWrapper>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                  <S.SubtotalContent>{price}</S.SubtotalContent>
                </S.SubtotalItem>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                  <S.SubtotalContent>{vat}</S.SubtotalContent>
                </S.SubtotalItem>
              </S.SubtotalWrapper>
            </S.PriceWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content css={S.content} label="Memo">
            <Textarea
              id="memo"
              hasError={!!errors.memo?.message}
              placeholder="Write a comment about the order"
              value={watch("memo") ?? ""}
              maxLength={150}
              register={register("memo")}
            />
            {errors.memo?.message && (
              <ErrMsg>{errors.memo?.message as Languages}</ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Reject"
          handleClickBtn={handleReject}
        />
        <Button
          type="submit"
          variant="primaryMedium"
          label="Send quotation"
          isDisabled={Object.keys(errors).length > 0}
          handleClickBtn={handleCheckValidForm}
        />
      </S.BtnWrapper>
    </>
  );
};

export default QuotationOfferPrice;
