import React from "react";
import { useTranslation } from "react-i18next";

import { InternalLink, LabelContentTable } from "@/components";
import { ArrowVariantIcon, PATH, SuccessIcon, WarningIcon24 } from "@/assets";
import type { LicenseProposalStatus } from "@/types";

import * as S from "./LicenseRegistrationLink.styled";

interface LicenseRegistrationLinkProps {
  licenseRequestState: LicenseProposalStatus;
}

const LicenseRegistrationLink = ({
  licenseRequestState,
}: LicenseRegistrationLinkProps) => {
  const { t } = useTranslation();

  return (
    <LabelContentTable.Row css={S.labelContentTableRow}>
      {licenseRequestState === "APPROVED" && (
        <InternalLink
          css={S.acceptContent}
          variant="ghostNoUnderlineGray"
          to={PATH.SETTINGS_COMPANY_LICENSE}
          state={{ licenseRequestState }}
        >
          {t("Do you want to change other information?")}
          <ArrowVariantIcon css={S.arrowIcon} />
        </InternalLink>
      )}
      {licenseRequestState === "REQUESTED" && (
        <S.PendingContent>
          <SuccessIcon css={S.successIcon} />
          {t(
            "We checking the changes. We’ll take care of it as soon as possible!",
          )}
        </S.PendingContent>
      )}
      {licenseRequestState === "REJECTED" && (
        <InternalLink
          css={S.rejectContent}
          variant="ghostNoUnderlineGray"
          to={PATH.SETTINGS_COMPANY_LICENSE}
          state={{ licenseRequestState }}
        >
          <WarningIcon24 css={S.warningIcon} />
          {t(
            "Difficult to identify due to low image quality. Please upload it again.",
          )}
          <ArrowVariantIcon css={S.arrowIcon} />
        </InternalLink>
      )}
    </LabelContentTable.Row>
  );
};

export default LicenseRegistrationLink;
