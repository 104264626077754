import type {
  GetMembersQueryModel,
  GetMembersServerModel,
  GetMemberDetailServerModel,
  CreateMemberQueryModel,
  UpdateMemberQueryModel,
} from "@/types";

import { ax } from "apis/axios";

export const getMembersAPI = async (req: GetMembersQueryModel) => {
  const { data } = await ax.get<GetMembersServerModel>("/setting/member", {
    params: req.query,
  });

  return data;
};

export const getMemberDetailAPI = async (staffId: string) => {
  const { data } = await ax.get<GetMemberDetailServerModel>(
    `/setting/member/${staffId}`,
  );

  return data;
};

export const postMemberAPI = (req: CreateMemberQueryModel) =>
  ax.post("/setting/member", req.body);

export const putMemberAPI = (req: UpdateMemberQueryModel) =>
  ax.put(`/setting/member/${req.staffId}`, req.body);

export const deleteMemberAPI = (staffId: string) =>
  ax.delete(`/setting/member/${staffId}`);
