import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.p`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    padding-left: 12px;
    color: ${theme.color.red_30};
    background-color: ${theme.color.gray_10};
  `}
`;
