import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

import { mixinBtn_ghost_g, mixinBtn_ghost_b } from "@/styles";

export const AutoCompleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const AddBtn = styled.button`
  ${mixinBtn_ghost_b}
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 11px;
`;

export const CancelBtn = styled.button`
  ${mixinBtn_ghost_g}
`;

export const UpdateBtn = styled.button`
  ${mixinBtn_ghost_b}
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const LinkedDriverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

export const Driver = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;

    & > span {
      ${theme.font.regular_14};
      width: 340px;
    }
  `}
`;

export const moreDialogBtn = (theme: Theme) => css`
  & > button {
    opacity: 50%;

    svg path {
      fill: ${theme.color.gray_50};
    }

    &:hover,
    &[aria-pressed="true"] {
      opacity: 100%;
    }
  }
`;
