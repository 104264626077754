import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TruckDropdownOptionsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
  width: 100%;
`;

export const truckDropdown = css`
  width: 100%;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;

export const dropdownContent = css`
  margin-bottom: 6px;
`;

export const dropdown = css`
  width: 214px;
`;

export const SizeInputWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
    align-items: center;
    width: 100%;

    & > div:not(:last-of-type) {
      &::after {
        ${theme.font.medium_14};
        position: absolute;
        right: -16px;
        content: "x";
        color: ${theme.color.black};
      }
    }
  `}
`;

export const SizeInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    grid-column: span 3;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const sizeErrMsg = css`
  grid-column: span 3;
  margin-top: 4px;
`;

export const labelContentTableContent = css`
  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
