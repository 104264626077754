import axios from "axios";

import { getS3FileFormData } from "@/utils";
import type {
  LinkPlayerQueryModel,
  LinkPlayerServerModel,
  S3PresignedServerModel,
} from "@/types";

import { ax } from "apis/axios";
import type { TrucksTypeOptionServerModel } from "types";

export const getTruckTypeOptionAPI = async () => {
  const { data } = await ax.get<TrucksTypeOptionServerModel[]>(
    "/property/truck/option",
  );

  return data;
};

export const getLinkPlayerAPI = async (req: LinkPlayerQueryModel) => {
  const { data } = await ax.get<LinkPlayerServerModel>("/link/player", {
    params: req.query,
  });

  return data;
};

export const postS3PresignedUrlAPI = async (
  key: string,
  file: File,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/${key}`);

  const formData = getS3FileFormData(res, file);

  return await axios.post(res.data.url, formData);
};

export const getS3PresignedUrlAPI = async (
  s3FileKey: string,
): Promise<string> => {
  if (!s3FileKey) return "";

  const { data } = await ax.get(`/presigned/${s3FileKey}`);

  return data;
};

// LINK: https://www.notion.so/coconutsilo/common-statistics-233e3283fea744f1861d240ec9a334a6
export const getStatistics = async () => {
  const { data } = await axios.get<{
    soLuongTruyCap: number;
    tongSoNguoiBan: number;
    soLuongNguoiBanMoi: number;
    tongSoSanPham: number;
    soSamPhamDangBanMoi: number;
    tongSoDonHang: number;
    tongSoDonHangThanhCong: number;
    tongSoDonHangKhongThanhCong: number;
    tongGiaTriGiaoDich: number;
  }>(`${process.env.REACT_APP_BASE_URL}/common/statistics`);

  return data;
};
