import React from "react";
import { useTranslation } from "react-i18next";

import { RouteCard } from "components";
import type { GetOngoingDriverDetailClientModel } from "types";
import * as S from "./OngoingDetailBody.styled";

interface OngoingDetailBodyProps {
  allocationInfo: GetOngoingDriverDetailClientModel["allocationInfo"];
}

const OngoingDetailBody = ({ allocationInfo }: OngoingDetailBodyProps) => {
  const { t } = useTranslation();

  return (
    <S.Root>
      <S.Title>{t("Route")}</S.Title>
      <S.Wrapper>
        {allocationInfo?.map((item) => (
          <RouteCard key={`${item.type}-${item.deliveryId}`} data={item} />
        ))}
      </S.Wrapper>
    </S.Root>
  );
};

export default OngoingDetailBody;
