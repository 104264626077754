import React, { useState } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

import { Input } from "@/components";
import { UnvisibleIcon, VisibleIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./PasswordInput.styled";

interface PasswordInputProps {
  className?: string;
  id: string;
  placeholder: Languages;
  value: any;
  register?: UseFormRegisterReturn<any>;
  hasError?: boolean;
}

const PasswordInput = ({
  className,
  id,
  placeholder,
  value,
  register,
  hasError,
}: PasswordInputProps) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleToggleShowPassword = () => setIsShowPassword((prev) => !prev);

  return (
    <S.Root>
      <Input
        className={className}
        css={S.input}
        placeholder={placeholder}
        value={value}
        id={id}
        type={isShowPassword ? "text" : "password"}
        register={register}
        hasError={hasError}
      />
      <S.PasswordShowBtn
        type="button"
        isShowPassword={isShowPassword}
        onClick={handleToggleShowPassword}
      >
        {isShowPassword ? <VisibleIcon /> : <UnvisibleIcon />}
      </S.PasswordShowBtn>
    </S.Root>
  );
};

export default PasswordInput;
