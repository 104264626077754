import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContentTableRow = (theme: Theme) => css`
  background-color: ${theme.color.gray_10};
`;

export const licenseContent = (theme: Theme) => css`
  justify-content: left;
  padding: 16px 24px;
  color: ${theme.color.gray_80};
  word-break: break-all;
`;

export const arrowIcon = (theme: Theme) => css`
  margin: 0 0 0 12px;
  rotate: 180deg;

  path {
    fill: ${theme.color.gray_50};
  }
`;

export const successIcon = (theme: Theme) => css`
  width: 20px;
  height: 20px;
  margin-right: 12px;

  & > path {
    fill: ${theme.color.primary_30};
  }
`;

export const warningIcon = (theme: Theme) => css`
  width: 20px;
  height: 20px;

  & > path {
    fill: ${theme.color.orange_20};
  }
`;

export const acceptContent = (theme: Theme) => css`
  ${licenseContent(theme)};

  &:hover > svg > path {
    fill: ${theme.color.gray_70};
  }
`;

export const PendingContent = styled.div`
  ${({ theme }) => css`
    ${licenseContent(theme)};
    ${theme.font.regular_14};
    display: flex;
    align-items: center;
    color: ${theme.color.gray_60};
  `}
`;

export const rejectContent = (theme: Theme) => css`
  ${licenseContent(theme)};
  color: ${theme.color.gray_80};

  & > svg {
    margin-right: 12px;
  }

  &:hover > svg:last-of-type > path {
    fill: ${theme.color.gray_70};
  }
`;
