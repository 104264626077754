import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  margin-bottom: 32px;

  & > span {
    display: block;
    margin-bottom: 8px;
  }
`;

export const content = css`
  grid-template-columns: 214px 1fr;
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 13px;
`;

export const Th = styled.th`
  ${({ theme }) => css`
    padding: 16px 12px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.gray_10};
    text-align: left;

    :nth-of-type(1) {
      width: 142px;
    }
    :nth-of-type(2),
    :nth-of-type(4),
    :nth-of-type(5) {
      width: 205px;
    }
    :nth-of-type(3) {
      width: 237px;
    }
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    padding: 12px 11px;
    border: 1px solid ${theme.color.gray_30};
  `}
`;

export const RadioWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 8px;
    width: fit-content;
    margin-bottom: 24px;
    cursor: pointer;

    & > label {
      ${theme.font.semibold_13};
    }

    & > input,
    & > label {
      cursor: pointer;
    }
  `}
`;

export const PriceWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
`;

export const Price = styled.span`
  ${({ theme }) => css`
    ${theme.font.semibold_13};
    color: ${theme.color.primary_40};
  `}
`;

export const Month = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_12};
  `}
`;

export const BtnsWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin-left: auto;

  & > form > button {
    margin: 0;
  }
`;
