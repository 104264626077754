import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const cardContent = css`
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const CargoWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 16px;
    border: 1px solid ${theme.color.gray_20};
    border-radius: 8px;
    padding: 24px 16px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const deleteBtn = (theme: Theme) => css`
  position: absolute;
  top: 12px;
  right: 12px;

  svg path {
    margin: 0;
    fill: ${theme.color.gray_60};
  }
`;

export const cargoManagement = css`
  margin: 16px 0;
`;

export const content = (theme: Theme) => css`
  margin-bottom: 32px;

  svg path {
    fill: ${theme.color.gray_70};
  }
`;

export const packagingTypeContent = css`
  display: flex;
  flex-direction: column;
`;

export const DropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;

export const dropdown = (theme: Theme) => css`
  background-color: ${theme.color.white};
`;

export const cargoTypeInput = css`
  margin-top: 4px;
`;

const mixinLayout = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  align-items: center;
  width: 100%;
`;

export const SizeInputWrapper = styled.div`
  ${({ theme }) => css`
    ${mixinLayout}

    & > div:not(:last-of-type) {
      &::after {
        ${theme.font.medium_14};
        content: "x";
        position: absolute;
        right: -16px;
        color: ${theme.color.black};
      }
    }
  `}
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const sizeErrorMsg = css`
  ${errorMsg};
  grid-column: span 3;
`;

export const LabelContentWrapper = styled.div`
  ${mixinLayout}
  ${content}
  align-items: flex-start;
`;

export const CargoStatusWrapper = styled.div`
  ${({ theme }) => css`
    ${content(theme)};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
  `}
`;

export const CargoWeightQuantityWrapper = styled(CargoStatusWrapper)`
  align-items: flex-start;
  column-gap: 20px;
`;

export const SizeInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    grid-column: span 3;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const WeightInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: inline-block;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const addCargoBtn = (theme: Theme) => css`
  width: 100%;
  height: 48px;
  border: 0;
  color: ${theme.color.primary_40};
  background-color: ${theme.color.primary_10};
`;
