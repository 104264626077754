import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, InternalLink, SettingDetailLayout } from "@/components";
import { PATH, BackArrowIcon } from "@/assets";

import { EditMemberField } from "components";
import useEditMember from "./hooks/useEditMember";
import * as S from "./EditMember.styled";

const EditMember = () => {
  const { t } = useTranslation();

  const { formMethod, isLoading, editMember, handleCancel } = useEditMember();

  return (
    <SettingDetailLayout>
      <S.Header>
        <InternalLink variant="ghostNoUnderlineGray" to={PATH.SETTINGS_MEMBER}>
          <BackArrowIcon />
          <S.Title>{t("Edit member")}</S.Title>
        </InternalLink>
      </S.Header>
      <FormProvider {...formMethod}>
        <form onSubmit={formMethod.handleSubmit(editMember)}>
          <EditMemberField css={S.editMemberField} />
          <S.ButtonWrapper>
            <Button
              type="button"
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleCancel}
            />
            <Button
              type="submit"
              variant="primaryMedium"
              label="Update"
              isLoading={isLoading}
              isDisabled={Object.keys(formMethod.formState.errors).length > 0}
            />
          </S.ButtonWrapper>
        </form>
      </FormProvider>
    </SettingDetailLayout>
  );
};

export default EditMember;
