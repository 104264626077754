import React, { useState, useCallback } from "react";
import dayjs from "dayjs";

import { transform24Hr } from "@/utils";
// NOTE: @/utils만 하면 import 에러가 발생
import { numericOnlyWithColons } from "@/utils/formatter";
import { COMMON_VALID_MSG } from "@/constants";

const useTimePicker = (selectedValue: string) => {
  const [time, setTime] = useState(
    selectedValue
      ? dayjs(selectedValue, "DD/MM/YYYY, HH:mm").format("HH:mm")
      : "00:00",
  );
  const [timeErr, setTimeErr] = useState("");

  const getTimeAppliedDate = useCallback(
    (date: dayjs.Dayjs | string, time: string) => {
      const [hour, min] = time.split(":"),
        newDate = dayjs(date).set("hour", +hour).set("minute", +min);

      return newDate;
    },
    [],
  );

  const applyTime = useCallback((time: string) => {
    const formattedTime = transform24Hr(time);
    if (!formattedTime) return;

    if (!formattedTime.valid) {
      setTimeErr(COMMON_VALID_MSG.TIME);
      return;
    }

    setTime(formattedTime.value);
    setTimeErr("");
  }, []);

  const handleChangeTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeErr("");
      const value = e.target?.value;

      if (value.length > 5) return;

      setTime(numericOnlyWithColons(value));
    },
    [],
  );

  const handleBlurTime = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      applyTime(e.target.value);
    },
    [selectedValue],
  );

  const handleResetTime = useCallback(() => {
    setTime("00:00");
  }, []);

  const handleResetTimeErr = useCallback(() => {
    setTimeErr("");
  }, []);

  return {
    time,
    timeErr,
    getTimeAppliedDate,
    applyTime,
    handleChangeTime,
    handleBlurTime,
    handleResetTime,
    handleResetTimeErr,
  };
};

export default useTimePicker;
