import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal } from "@/hooks";
import { PATH } from "@/assets";

const useAccessLicenseRegistration = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const handleCloseConfirmModal = () => {
    navigate(PATH.SETTINGS_COMPANY);
    handleModalClose();
  };

  useEffect(() => {
    if (state !== null) return;

    handleModalOpen(
      <ConfirmModal
        ref={modalRef}
        title="Invalid access"
        desc="Please try throught the correct path."
        posBtnLabel="Go to list"
        callbackFn={handleCloseConfirmModal}
      />,
    )();
  }, []);
};

export default useAccessLicenseRegistration;
