import { css } from "@emotion/react";

import { font } from "./font";
import { color } from "./color";

const commonInfoWindow = css`
  display: flex;
  flex-direction: column;
  cursor: initial;
`;

export const infoWindow = {
  common: css`
    .gm-style-iw-d {
      overflow: hidden !important;
      word-wrap: break-word;
    }
    // InfoWindow x 버튼
    .gm-ui-hover-effect {
      top: 0px !important;
      right: -2px !important;
      width: 20px !important;
      height: 20px !important;

      & > span {
        margin: 0 !important;
      }
    }

    // NOTE: marker에 이미지 사용시
    .profile-marker img {
      border-radius: 50%;
    }

    .driver-infowindow {
      ${commonInfoWindow};

      .driver-name {
        ${font.medium_15};
        color: ${color.gray_80};
        margin-top: 4px;
      }
    }
  `,
};

export type InfoWindow = typeof infoWindow;
