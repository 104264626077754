import React from "react";
import { useRecoilValue } from "recoil";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  ErrMsg,
  Input,
  LabelContentTable,
  UnitSuffixInput,
} from "@/components";
import { clientLanguage } from "@/stores";
import {
  checkTwoDecimalDigit,
  formatVin,
  formatterDecimal,
  getNumPeriods,
} from "@/utils";
import type { Languages } from "@/types";

import { truckTypeOptionsState } from "stores";
import {
  checkCargoSizeLimit,
  formatTruckOptions,
  formatTruckTypes,
  selectDropdownForm,
} from "utils";
import { TEXT_LIMIT } from "constants/index";
import type { TruckSpecificationInfoForm } from "types";
import * as S from "./SpecificationFieldEdit.styled";

interface SpecificationFieldEditProps {
  isLoading: boolean;
  updateTruckSpecification: (data: TruckSpecificationInfoForm) => void;
  handleCancel: () => void;
}

const SpecificationFieldEdit = ({
  isLoading,
  updateTruckSpecification,
  handleCancel,
}: SpecificationFieldEditProps) => {
  const { t } = useTranslation();

  const language = useRecoilValue(clientLanguage);
  const truckTypeOptions = useRecoilValue(truckTypeOptionsState);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    register,
    reset,
    handleSubmit,
  } = useFormContext<TruckSpecificationInfoForm>();

  const truckTypes = formatTruckTypes(language, truckTypeOptions);
  const truckOptions = formatTruckOptions(
    language,
    truckTypeOptions,
    watch("truckType")?.key!,
  );

  const getFormattedSizeValue = (
    prevValue: string,
    currentValue: string,
  ): string => {
    if (
      (currentValue && !checkTwoDecimalDigit(currentValue)) ||
      !checkCargoSizeLimit(currentValue)
    ) {
      return prevValue;
    }

    return currentValue;
  };

  const handleBlurSizeInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const { id, name, value } = e.target;

    switch (id) {
      case "length":
      case "depth":
      case "height": {
        setValue(
          name as "size.length" | "size.depth" | "size.height",
          value ? `${+formatterDecimal(value)}` : value,
        );
        break;
      }
    }
  };

  const handleClickCancelBtn = () => {
    reset();
    handleCancel();
  };

  return (
    <form onSubmit={handleSubmit(updateTruckSpecification)}>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content isRequired label="Truck type">
            <Controller
              name="truckType"
              control={control}
              render={({ field: { value, onChange } }) => {
                const selectTruckType = (truckTypeKey: string) => {
                  setValue("truckOption", { key: "", label: "" });
                  selectDropdownForm(truckTypes, onChange)(truckTypeKey);
                };

                return (
                  <Dropdown
                    css={S.truckDropdown}
                    options={truckTypes}
                    hasError={!!errors.truckType?.message}
                    selectedOption={value}
                    handleSelect={selectTruckType}
                  />
                );
              }}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content isRequired label="Truck option">
            <S.TruckDropdownOptionsWrapper>
              <Controller
                name="truckOption"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    css={S.truckDropdown}
                    options={truckOptions}
                    hasError={!!errors.truckOption?.message}
                    selectedOption={value}
                    placeholder={"Select the truck option"}
                    handleSelect={selectDropdownForm(truckOptions, onChange)}
                  />
                )}
              />
              {errors.truckOption?.message && (
                <ErrMsg>{errors.truckOption?.message as Languages}</ErrMsg>
              )}
            </S.TruckDropdownOptionsWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Container size" isRequired>
            <S.SizeInputWrapper>
              <UnitSuffixInput
                id="length"
                placeholder="Length"
                value={watch("size").length || ""}
                unit="m"
                err={errors.size?.length?.message}
                register={register?.("size.length", {
                  setValueAs: (currentValue: string) =>
                    getFormattedSizeValue(
                      `${watch("size").length}`,
                      currentValue,
                    ),
                  onBlur: handleBlurSizeInput,
                })}
              />
              <UnitSuffixInput
                id="depth"
                placeholder="Depth"
                value={watch("size").depth || ""}
                unit="m"
                err={errors.size?.depth?.message}
                register={register?.("size.depth", {
                  setValueAs: (currentValue: string) =>
                    getFormattedSizeValue(
                      `${watch("size").depth}`,
                      currentValue,
                    ),
                  onBlur: handleBlurSizeInput,
                })}
              />
              <UnitSuffixInput
                id="height"
                placeholder="Height"
                value={watch("size").height || ""}
                unit="m"
                err={errors.size?.height?.message}
                register={register?.("size.height", {
                  setValueAs: (currentValue: string) =>
                    getFormattedSizeValue(
                      `${watch("size").height}`,
                      currentValue,
                    ),
                  onBlur: handleBlurSizeInput,
                })}
              />
              <S.SizeInfo>
                {`- ${t(
                  "Maximum: Length 13.56 m, Depth 2.35 m, Height 2.7 m",
                )}`}
              </S.SizeInfo>
              {errors?.size && !!Object.keys(errors.size).length && (
                <ErrMsg css={S.sizeErrMsg}>
                  {(errors?.size?.length?.message as Languages) ||
                    (errors?.size?.depth?.message as Languages) ||
                    (errors?.size?.height?.message as Languages)}
                </ErrMsg>
              )}
            </S.SizeInputWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Brand">
            <Input
              placeholder="Brand"
              value={watch("brand") ?? ""}
              maxLength={TEXT_LIMIT.BRAND_LIMIT_VALUE}
              register={register("brand")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Model">
            <Input
              placeholder="Model"
              value={watch("model") ?? ""}
              maxLength={TEXT_LIMIT.MODEL_LIMIT_VALUE}
              register={register("model")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Year">
            <Controller
              name="year"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  css={S.dropdown}
                  options={getNumPeriods(1990, new Date().getFullYear())}
                  selectedOption={value}
                  handleSelect={selectDropdownForm(
                    getNumPeriods(1990, new Date().getFullYear()),
                    onChange,
                  )}
                />
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.labelContentTableContent}
            label="VIN"
            isRequired
          >
            <Input
              placeholder="Enter 17 characters"
              value={watch("vin")}
              hasError={!!errors.vin?.message}
              maxLength={TEXT_LIMIT.VIN}
              register={register("vin", {
                setValueAs: formatVin,
              })}
            />
            {errors.vin?.message && (
              <ErrMsg css={S.sizeErrMsg}>
                {errors.vin?.message as Languages}
              </ErrMsg>
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          label="Cancel"
          variant="outlineMedium"
          handleClickBtn={handleClickCancelBtn}
        />
        <Button
          type="submit"
          isLoading={isLoading}
          isDisabled={Object.keys(errors).length > 0}
          label="Update"
          variant="primaryMedium"
        />
      </S.BtnWrapper>
    </form>
  );
};

export default SpecificationFieldEdit;
