import React from "react";
import { useRecoilState } from "recoil";

import { Button, LangDropdown, PromptModal } from "@/components";
import { isOpenPromptState } from "@/stores";
import { useModal } from "@/hooks";
import { HeaderLogoIcon, LogoutIcon } from "@/assets";

import { useLogout } from "services";
import * as S from "./Header.styled";

const Header = () => {
  const [isOpenPrompt, setIsOpenPrompt] = useRecoilState(isOpenPromptState);

  const { handleModalOpen, handleModalClose, modalRef } = useModal();

  const { mutate: logoutMutate } = useLogout();

  const header = process.env.REACT_APP_BASE_URL.split("/").at(-1);

  const handleLogout = () => {
    if (isOpenPrompt) {
      const cbFn = () => {
        handleModalClose();
        setIsOpenPrompt(false);
        logoutMutate();
      };

      handleModalOpen(<PromptModal ref={modalRef} callbackFn={cbFn} />)();
    } else {
      logoutMutate();
    }
  };

  return (
    <S.Root>
      <h1 className="a11y">COCOTRUCK {header}</h1>
      <HeaderLogoIcon />
      <S.Wrapper>
        <LangDropdown />
        <Button
          css={S.logoutBtn}
          variant="ghostNoUnderlineGray"
          label="Sign out"
          icon={<LogoutIcon />}
          handleClickBtn={handleLogout}
        />
      </S.Wrapper>
    </S.Root>
  );
};

export default Header;
