import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError, LocationMarker } from "@/types";

import {
  cancelAllocationAPI,
  getOngoingDriverDetail,
  getOngoingDrivers,
} from "apis";
import type { GetOngoingDriverQueryModel } from "types";

const ongoingDriverKeys = {
  all: ["ongoingDriver"] as const,
  lists: () => [...ongoingDriverKeys.all, "list"] as const,
  list: (filters: GetOngoingDriverQueryModel) =>
    [...ongoingDriverKeys.lists(), { filters }] as const,
  details: () => [...ongoingDriverKeys.all, "detail"] as const,
  detail: (id: string) => [...ongoingDriverKeys.details(), id] as const,
};

export const useGetOngoingDrivers = (req: GetOngoingDriverQueryModel) =>
  useQuery({
    queryKey: ongoingDriverKeys.list(req),
    queryFn: () => getOngoingDrivers(req),
  });

export const useGetOngoingDriverDetail = (allocationId: string) => {
  return useQuery({
    queryKey: ongoingDriverKeys.detail(allocationId),
    queryFn: () => getOngoingDriverDetail(allocationId),
    select: (res) => {
      const driverInfo = {
        ...res.driverInfo,
        /* NOTE: 
           서버에서 배차 상태 (AVAILABLE, DRIVING, UNAVAILABLE)를 반환해주시는데 디자인에서는 드라이버 상태 (AVAILABLE, UNAVAILABLE) 를 보여주어야해서
           DRIVING 상태도 결국 AVAILABLE 범주에 들어가기 때문에 하기와 같이 작성
        */
        status:
          res.driverInfo.status === "DRIVING"
            ? "AVAILABLE"
            : res.driverInfo.status,
      };

      const locations = res.allocationInfo.reduce<LocationMarker[]>(
        (acc, cur) => [
          ...acc,
          {
            lat: +cur.lat,
            lng: +cur.lng,
            type: cur.type,
          },
        ],
        [],
      );

      const coords = res.allocationInfo?.map(({ lat, lng }) => ({
        lat: +lat,
        lng: +lng,
      }));

      return { ...res, driverInfo, coords, locations };
    },
  });
};

export const useCancelAllocation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (allocationId) => cancelAllocationAPI(allocationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ongoingDriverKeys.lists(),
      });
    },
  });
};
