import React, { useState } from "react";

import { DetailModal, ErrMsg, Input, LabelContent } from "@/components";
import { UnvisibleIcon, VisibleIcon } from "@/assets";
import { Languages } from "@/types";

import usePassword from "./hooks/usePassword";
import * as S from "./ChangeDriverPasswordModal.styled";

interface ChangePasswordModalProps {
  className?: string;
}

const ChangeDriverPasswordModal = React.forwardRef(
  (
    { className }: ChangePasswordModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const [isCurrentPasswordShow, setIsCurrentPasswordShow] = useState(false);
    const [isNewPasswordShow, setIsNewPasswordShow] = useState(false);
    const [isConfirmPasswordShow, setIsConfirmPasswordShow] = useState(false);

    const { errors, register, watch, handleChangePasswordSubmit } =
      usePassword();

    const handlePasswordShow = (state: string) => () => {
      switch (state) {
        case "isCurrentPasswordShow":
          return setIsCurrentPasswordShow(!isCurrentPasswordShow);

        case "isNewPasswordShow":
          return setIsNewPasswordShow(!isNewPasswordShow);

        case "isConfirmPasswordShow":
          return setIsConfirmPasswordShow(!isConfirmPasswordShow);
      }
    };

    return (
      <DetailModal
        css={S.modal}
        ref={ref}
        className={className}
        type="submit"
        isDisabled={Object.keys(errors).length > 0}
        title="Change password"
        posBtnLabel="Confirm"
        callbackFn={handleChangePasswordSubmit}
      >
        <LabelContent
          css={S.labelContent}
          label="Current password"
          direction="vertical"
          isRequired
        >
          <S.IconWrapper>
            <Input
              placeholder="Current password"
              type={isCurrentPasswordShow ? "text" : "password"}
              value={watch("currentPassword")}
              hasError={!!errors.currentPassword?.message}
              register={register("currentPassword")}
            />
            <S.PasswordShowButton
              type="button"
              tabIndex={-1}
              isPasswordShow={isCurrentPasswordShow}
              onClick={handlePasswordShow("isCurrentPasswordShow")}
              aria-label="show password"
            >
              {isCurrentPasswordShow ? <VisibleIcon /> : <UnvisibleIcon />}
            </S.PasswordShowButton>
          </S.IconWrapper>
          {errors.currentPassword?.message && (
            <ErrMsg>{errors.currentPassword?.message as Languages}</ErrMsg>
          )}
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="New password"
          direction="vertical"
          isRequired
        >
          <S.IconWrapper>
            <Input
              placeholder="New password"
              type={isNewPasswordShow ? "text" : "password"}
              value={watch("newPassword")}
              hasError={!!errors.newPassword?.message}
              register={register("newPassword")}
            />
            <S.PasswordShowButton
              type="button"
              tabIndex={-1}
              isPasswordShow={isNewPasswordShow}
              onClick={handlePasswordShow("isNewPasswordShow")}
              aria-label="show password"
            >
              {isNewPasswordShow ? <VisibleIcon /> : <UnvisibleIcon />}
            </S.PasswordShowButton>
          </S.IconWrapper>
          {errors.newPassword?.message && (
            <ErrMsg>{errors.newPassword?.message as Languages}</ErrMsg>
          )}
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Confirm password"
          direction="vertical"
          isRequired
        >
          <S.IconWrapper>
            <Input
              type={isConfirmPasswordShow ? "text" : "password"}
              placeholder="Confirm password"
              value={watch("confirmPassword")}
              hasError={!!errors.confirmPassword?.message}
              register={register("confirmPassword")}
            />
            <S.PasswordShowButton
              type="button"
              tabIndex={-1}
              isPasswordShow={isConfirmPasswordShow}
              onClick={handlePasswordShow("isConfirmPasswordShow")}
              aria-label="show password"
            >
              {isConfirmPasswordShow ? <VisibleIcon /> : <UnvisibleIcon />}
            </S.PasswordShowButton>
          </S.IconWrapper>
          {errors.confirmPassword?.message && (
            <ErrMsg>{errors.confirmPassword?.message as Languages}</ErrMsg>
          )}
        </LabelContent>
      </DetailModal>
    );
  },
);

ChangeDriverPasswordModal.displayName = "ChangeDriverPasswordModal";

export default ChangeDriverPasswordModal;
