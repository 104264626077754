import React from "react";

import { CheckboxBtn } from "@/components";
import { formatPhoneNum } from "@/utils";
import { LinkedIcon, PhoneIcon } from "@/assets";

import type { GetDriverSearchServerModel } from "types";
import * as S from "./DriverCard.styled";

interface DriverCardProps {
  data: GetDriverSearchServerModel[number];
  selectedTruckId: string;
  selectedDrivers: string[];
  handleSelectDrivers: (id: string) => () => void;
}

const DriverCard = ({
  data,
  selectedTruckId,
  selectedDrivers,
  handleSelectDrivers,
}: DriverCardProps) => {
  const disabled =
    !!selectedTruckId && !!data.truckId && data.truckId !== selectedTruckId;

  return (
    <S.Root>
      <S.CoverLabel disabled={disabled}>
        <S.DriverProfile src={data.profile} />
        <div>
          <S.DriverName>{data.name}</S.DriverName>
          <S.AdditionalInfo>
            <PhoneIcon />
            <span>{formatPhoneNum(data.phone)}</span>
          </S.AdditionalInfo>
          {disabled && (
            <S.AdditionalInfo>
              <LinkedIcon />
              <span>{data.registNumber}</span>
            </S.AdditionalInfo>
          )}
        </div>
        <CheckboxBtn
          shape="circle"
          disabled={disabled}
          isChecked={selectedDrivers.includes(data.driverId)}
          handleCheck={handleSelectDrivers(data.driverId)}
        />
      </S.CoverLabel>
    </S.Root>
  );
};

export default DriverCard;
