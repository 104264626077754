import { useQuery } from "@tanstack/react-query";

import { getPropertyTabAPI } from "apis";

export const propertyTabListQueryKey = "propertyTabList";

export const useGetPropertyTab = () => {
  return useQuery({
    queryKey: [propertyTabListQueryKey],
    queryFn: () => getPropertyTabAPI(),
  });
};
