import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./CompanyLicenseRegistrationHeader.styled";

const CompanyLicenseRegistrationHeader = () => {
  const { t } = useTranslation();

  return (
    <S.CompanyLicenseRegistrationHeader>
      <h3 className="a11y">Company license</h3>
      <S.PageTitle>{t("Change request")}</S.PageTitle>
      <S.PageDesc>
        {t("If Business license or ERC has been changed, please upload it.")}
      </S.PageDesc>
      <S.PageSubDesc>{t("*It may take 1-2 business days.")}</S.PageSubDesc>
    </S.CompanyLicenseRegistrationHeader>
  );
};

export default CompanyLicenseRegistrationHeader;
