import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "@/types";

import * as S from "./LabelContentTable.styled";

export type LabelContentType = "bg" | "underline" | "empty" | "whiteBg";

interface LabelContentTableProps {
  className?: string;
  subject?: Languages;
  variant: LabelContentType;
  children: React.ReactNode;
}

const Context = createContext<{ variant?: LabelContentType }>({});

const LabelContentTable = ({
  className,
  subject,
  variant,
  children,
}: LabelContentTableProps) => {
  const { t } = useTranslation();

  return (
    <Context.Provider value={{ variant }}>
      <div className={className}>
        {subject && <S.Subject>{t(subject)}</S.Subject>}
        <div>{children}</div>
      </div>
    </Context.Provider>
  );
};

interface RowProps {
  className?: string;
  partition?: number;
  hasError?: boolean;
  marginTop?: number;
  children: React.ReactNode;
}

LabelContentTable.Row = function Row({
  className,
  partition = 1,
  marginTop,
  hasError,
  children,
}: RowProps) {
  const { variant } = useContext(Context);

  return (
    <S.Row
      className={className}
      variant={variant}
      marginTop={marginTop}
      hasError={hasError}
      partition={partition}
    >
      {children}
    </S.Row>
  );
};

interface ContentProps {
  className?: string;
  label: Languages;
  labelWidth?: number;
  isRequired?: boolean;
  hasError?: boolean;
  children: React.ReactNode;
}

LabelContentTable.Content = function Content({
  className,
  label,
  labelWidth,
  isRequired,
  hasError,
  children,
}: ContentProps) {
  const { t } = useTranslation();

  const { variant } = useContext(Context);

  return (
    <S.ContentWrapper
      className={className}
      variant={variant}
      labelWidth={labelWidth}
    >
      <S.NameWrapper>
        <S.Name variant={variant} hasError={hasError}>
          {t(label)}
          {isRequired && <S.Required>*</S.Required>}
        </S.Name>
      </S.NameWrapper>
      <S.Content variant={variant}>{children}</S.Content>
    </S.ContentWrapper>
  );
};

export default LabelContentTable;
