import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const AddQuotation = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${theme.color.primary_40};

    & > svg {
      width: 12px;
      height: 12px;

      path {
        fill: ${theme.color.primary_40};
      }
    }
  `}
`;
