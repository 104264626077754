import React from "react";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AddrAutoComplete,
  CalendarInput,
  Card,
  ErrMsg,
  Input,
  LabelContent,
} from "@/components";
import { useToast } from "@/hooks";
import { PHONE_MAX_LENGTH } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";
import { onlyNumber } from "@/utils";
import type { AddressSearchType, Languages } from "@/types";

import type { OrderForm } from "types";
import * as S from "./OrderCreationDropOffInfo.styled";

interface OrderCreationDropOffInfoProps {
  className?: string;
  checkDisabledDateCondition: (
    compare: dayjs.Dayjs | string,
  ) => (selectedDate: dayjs.Dayjs) => boolean;
  deleteCalendarInput: (
    deleteKeys: ("pickUp.etd" | "dropOff.eta" | "dropOff.etd")[],
  ) => void;
}

const OrderCreationDropOffInfo = ({
  className,
  checkDisabledDateCondition,
  deleteCalendarInput,
}: OrderCreationDropOffInfoProps) => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const {
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
    register,
  } = useFormContext<OrderForm>();

  return (
    <Card className={className}>
      <Card.Content
        css={S.content}
        heading="Drop-off information"
        headingLevel="h3"
      >
        <LabelContent
          css={S.labelContent}
          label="Address"
          direction="vertical"
          isRequired
        >
          <Controller
            control={control}
            name="dropOff"
            render={({ field: { value } }) => {
              const handleSelect = (addr: AddressSearchType): void => {
                setValue("dropOff", { ...watch("dropOff"), ...addr });
                clearErrors("dropOff");
              };

              return (
                <AddrAutoComplete
                  err={errors.dropOff?.address?.message}
                  type="dropOff"
                  value={value}
                  placeholder="Please enter your address or choose from map"
                  handleSelect={handleSelect}
                />
              );
            }}
          />
          <Input
            maxLength={100}
            placeholder="Detail address (Optional)"
            value={watch("dropOff.addressDetail")}
            register={register("dropOff.addressDetail")}
          />
          {errors.dropOff?.address?.message && (
            <ErrMsg>{errors.dropOff.address.message as Languages}</ErrMsg>
          )}
        </LabelContent>
        <LabelContent label="Person in charge" direction="vertical" isRequired>
          <Input
            maxLength={100}
            hasError={!!errors.dropOff?.charge?.message}
            placeholder="Enter the person in charge"
            value={watch("dropOff.charge")}
            register={register("dropOff.charge")}
          />
          {errors.dropOff?.charge?.message && (
            <ErrMsg>{errors.dropOff.charge.message as Languages}</ErrMsg>
          )}
        </LabelContent>
        <LabelContent label="Phone number" direction="vertical" isRequired>
          <Input
            hasError={!!errors.dropOff?.phone?.message}
            maxLength={PHONE_MAX_LENGTH}
            placeholder="Only numbers"
            value={watch("dropOff.phone")}
            register={register("dropOff.phone", { setValueAs: onlyNumber })}
          />
          {errors.dropOff?.phone?.message && (
            <ErrMsg>{errors.dropOff.phone.message as Languages}</ErrMsg>
          )}
        </LabelContent>
        <S.DatesWrapper>
          <LabelContent label="ETA" direction="vertical" isRequired>
            <Controller
              control={control}
              name="dropOff.eta"
              render={({ field: { value, onChange } }) => {
                const handleDateChange = (date: dayjs.Dayjs[]): void => {
                  const hasPickUpEtd = !!watch("pickUp.etd")?.length;
                  const isBeforePickUp = !!dayjs(date[0]).isBefore(
                    dayjs(
                      hasPickUpEtd
                        ? watch("pickUp.etd")?.[0]
                        : watch("pickUp.eta")[0],
                      "DD/MM/YYYY HH:mm",
                    ),
                  );

                  if (isBeforePickUp) {
                    addToast(
                      hasPickUpEtd
                        ? COMMON_TOAST_MSG.WARNING.INVALID_ETA_DATE
                        : COMMON_TOAST_MSG.WARNING.INVALID_ETD_DATE,
                    );
                    onChange([]);

                    return setError("dropOff.eta", {
                      type: "required",
                      message: COMMON_VALID_MSG.REQUIRED,
                    });
                  }

                  onChange([date[0].format("DD/MM/YYYY HH:mm")]);
                  clearErrors([
                    "pickUp.eta",
                    "pickUp.etd",
                    "dropOff.eta",
                    "dropOff.etd",
                  ]);
                  deleteCalendarInput(["dropOff.etd"]);
                };

                return (
                  <CalendarInput
                    css={S.dateInput}
                    hasTime
                    hasError={!!errors.dropOff?.eta?.type}
                    placeholder="Select a date"
                    value={value[0] ?? ""}
                    selectedDate={value}
                    disabledCondition={checkDisabledDateCondition(
                      dayjs().startOf("day"),
                    )}
                    handleChangeDate={handleDateChange}
                  />
                );
              }}
            />
            {errors.dropOff?.eta?.message ? (
              <ErrMsg>{errors.dropOff.eta.message as Languages}</ErrMsg>
            ) : (
              <S.DateExplanation>
                -{` ${t("Based on Indochina Time (ICT).")}`}
              </S.DateExplanation>
            )}
          </LabelContent>
          <LabelContent label="ETD" direction="vertical">
            <Controller
              control={control}
              name="dropOff.etd"
              render={({ field: { value, onChange } }) => {
                const handleDateChange = (date: dayjs.Dayjs[]): void => {
                  const isBeforeETA = dayjs(date[0]).isBefore(
                    dayjs(watch("dropOff.eta")[0], "DD/MM/YYYY HH:mm"),
                  );

                  if (isBeforeETA) {
                    addToast(COMMON_TOAST_MSG.WARNING.INVALID_ETD_DATE);
                    onChange([]);

                    return setError("dropOff.etd", {
                      type: "required",
                      message: COMMON_VALID_MSG.REQUIRED,
                    });
                  }

                  onChange([date[0].format("DD/MM/YYYY HH:mm")]);
                  clearErrors([
                    "pickUp.eta",
                    "pickUp.etd",
                    "dropOff.eta",
                    "dropOff.etd",
                  ]);
                };

                return (
                  <CalendarInput
                    css={S.dateInput}
                    hasTime
                    hasError={!!errors.dropOff?.etd?.type}
                    placeholder="Select a date"
                    value={value?.[0] ?? ""}
                    selectedDate={value ?? []}
                    disabledCondition={checkDisabledDateCondition(
                      dayjs().startOf("day"),
                    )}
                    handleChangeDate={handleDateChange}
                  />
                );
              }}
            />

            <S.DateExplanation>
              -{` ${t("Based on Indochina Time (ICT).")}`}
            </S.DateExplanation>
          </LabelContent>
        </S.DatesWrapper>
      </Card.Content>
    </Card>
  );
};

export default OrderCreationDropOffInfo;
