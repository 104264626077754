import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TruckingCompanySearchModal } from "@/components";
import { useModal } from "@/hooks";
import { PlusCircleIcon12, TRUCKING_COMPANY_COLUMNS } from "@/assets";
import type { CompanyInfo } from "@/types";

import {
  QuotationCancelOrderModal,
  QuotationDefaultInformation,
  QuotationTable,
  QuotationPickUpInformation,
  QuotationDropOffInformation,
  QuotationCargoDetails,
  QuotationTruckingMethods,
  QuotationPayment,
  QuotationDetailLayout,
} from "components";
import { useGetQuotationDetail, usePostQuotationRequest } from "services";
import useQuotationDetail from "./hooks/useQuotationDetail";
import * as S from "./QuotationDetail.styled";

const ShipperQuotationDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetQuotationDetail<"shipper">({
    suspense: false,
    quotationId: id!,
  });

  const { mutate, isLoading: isQuotationSendLoading } =
    usePostQuotationRequest("shipper");

  const {
    selectedCompany,
    companyLinkPlayers,
    makeCompanyRequestMutateFormBody,
    companyRequestMutateOption,
  } = useQuotationDetail(data);
  const { modalRef, handleModalOpen } = useModal();

  const handleCompanySelect = (companyInfo: CompanyInfo[]): void => {
    mutate(
      makeCompanyRequestMutateFormBody("forwarder", companyInfo),
      companyRequestMutateOption("shipper"),
    );
  };

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const contents = [
    {
      heading: { text: "Default information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDefaultInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: {
        addHeadingElement: data.defaultInfo.pageStatus === "ONGOING" && (
          <S.AddQuotation
            type="button"
            onClick={handleModalOpen(
              companyLinkPlayers && (
                <TruckingCompanySearchModal
                  ref={modalRef}
                  isLoading={isQuotationSendLoading}
                  columns={TRUCKING_COMPANY_COLUMNS}
                  selectedCompany={selectedCompany}
                  tableDatas={companyLinkPlayers}
                  handleCompanySelect={handleCompanySelect}
                />
              ),
            )}
          >
            <PlusCircleIcon12 /> {t("Add quotation")}
          </S.AddQuotation>
        ),
        text: `${t("Quotation")} (${data.history?.length})`,
      },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTable data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Pick-up information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationPickUpInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Drop-off information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDropOffInformation data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: `${t("Cargo details")} (${data.cargoes.length})` },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationCargoDetails data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Trucking methods" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTruckingMethods data={data} />
        </Suspense>
      ),
    },
    {
      heading: { text: "Payment" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationPayment data={data} />
        </Suspense>
      ),
    },
  ];

  return (
    <QuotationDetailLayout
      contents={contents}
      isShowCancelButton={data.defaultInfo.pageStatus === "ONGOING"}
      pageStatus={data.defaultInfo.pageStatus}
      cancelModal={<QuotationCancelOrderModal ref={modalRef} offerId={id!} />}
    />
  );
};

export default ShipperQuotationDetail;
