import React from "react";
import { useTranslation } from "react-i18next";

import { InternalLink, QueryPagination, SettingLayout } from "@/components";
import { useFilterParams } from "@/hooks";
import { PATH, PlusCircleIcon12 } from "@/assets";
import type { GetMembersQueryModel } from "@/types";

import { MemberTable } from "components";
import { useGetMembers } from "services";
import { SETTING_MENU } from "assets";
import * as S from "./Member.styled";

const Member = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<GetMembersQueryModel["query"]>(["page"], {
    pageSize: 10,
  });

  const { data } = useGetMembers({ suspense: false, req: { query: filters } });

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <section>
        <h3 className="a11y">Member management</h3>
        <article>
          <S.Header>
            <S.Title>{t("Member")}</S.Title>
            <InternalLink
              variant="ghostNoUnderlinePrimary"
              to={PATH.SETTINGS_CREATE_MEMBER}
            >
              <PlusCircleIcon12 />
              {t("New member")}
            </InternalLink>
          </S.Header>
          <MemberTable />
          <S.PaginationWrapper>
            <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
          </S.PaginationWrapper>
        </article>
      </section>
    </SettingLayout>
  );
};

export default Member;
