import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import type { UseQueryOptions } from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  deletePropertyGarageAPI,
  getPropertyGaragesAPI,
  getPropertyGarageDetailAPI,
  createPropertyGarageAPI,
  updatePropertyGarageAPI,
} from "apis";
import type {
  GetGarageQueryModel,
  GetGarageServerModel,
  CreateGarageQueryModel,
  UpdateGarageQueryModel,
} from "types";
import { propertyTabListQueryKey } from "./property";

const garageKeys = {
  // query parameters 를 보고있는 table list
  all: ["garage"] as const,
  lists: () => [...garageKeys.all, "list"] as const,
  list: (filters: GetGarageQueryModel) =>
    [...garageKeys.lists(), { filters }] as const,
  details: () => [...garageKeys.all, "detail"] as const,
  detail: (id: string) => [...garageKeys.details(), id] as const,
};

export const useGetPropertyGarage = (
  req: GetGarageQueryModel,
  options?: UseQueryOptions<
    GetGarageServerModel,
    AxiosError,
    GetGarageServerModel,
    ReturnType<typeof garageKeys.list>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: garageKeys.list(req),
    queryFn: () => getPropertyGaragesAPI(req),
  });
};

export const useGetPropertyGarageDetail = (
  garageId: string,
  { enabled }: { enabled: boolean },
) => {
  return useQuery({
    queryKey: garageKeys.detail(garageId),
    queryFn: () => getPropertyGarageDetailAPI(garageId),
    keepPreviousData: true,
    enabled,
  });
};

export const useCreatePropertyGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateGarageQueryModel
  >({
    mutationFn: (req) => createPropertyGarageAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: garageKeys.lists() });
    },
  });
};

export const useUpdatePropertyGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateGarageQueryModel
  >({
    mutationFn: (req) => updatePropertyGarageAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: garageKeys.lists() });
    },
  });
};

export const useDeletePropertyGarage = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (garageId) => deletePropertyGarageAPI(garageId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: garageKeys.lists() });
      queryClient.invalidateQueries({ queryKey: [propertyTabListQueryKey] });
    },
  });
};
