import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { auth } from "@/contexts";
import { Button, DownloadBtn, LabelContentTable } from "@/components";
import { useModal, useToast } from "@/hooks";
import { formatICTDateTime, upperCaseToupperFirst } from "@/utils";
import { COMMON_TOAST_MSG } from "@/constants";
import { FileSearchFillIcon, SuccessIcon } from "@/assets";
import type { Languages } from "@/types";

import { PlateNumberModal } from "components";
import {
  useDownloadFile,
  useGetGpsTrucks,
  useGetPropertyTruckDetail,
} from "services";
import type { PropertyDetailEditCardType } from "types";
import LinkingDriver from "./linkingDriver/LinkingDriver";
import * as S from "./TruckDefaultInfoField.styled";

interface TruckDefaultInfoFieldProps {
  editState: PropertyDetailEditCardType | null;
  handleClickEdit: (editName: PropertyDetailEditCardType | null) => () => void;
  handleCancel: () => void;
}

const TruckDefaultInfoField = ({
  editState,
  handleClickEdit,
  handleCancel,
}: TruckDefaultInfoFieldProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: truckDetail } = useGetPropertyTruckDetail(id!);
  const { data: gpsTrucks } = useGetGpsTrucks(!!auth.gpsType);

  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const {
    isLoading: isDownloadTruckPhotoLoading,
    mutate: downloadTruckPhotoMutate,
  } = useDownloadFile();
  const {
    isLoading: isDownloadRegistrationLoading,
    mutate: downloadRegistrationMutate,
  } = useDownloadFile();

  const truckDetailDefaultInfo = truckDetail?.defaultInfo;

  const handlePlateListClick = (): void => {
    const handleCancelClick = (): void => {
      handleModalClose();
    };

    auth.gpsType && gpsTrucks?.plateList.length
      ? handleModalOpen(
          <PlateNumberModal
            ref={modalRef}
            type="copy"
            plateNumbers={gpsTrucks.plateList}
            handleCancelClick={handleCancelClick}
          />,
        )()
      : addToast(COMMON_TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  if (!truckDetailDefaultInfo) return null;

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContent}>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            css={S.plateNumberWrapper}
            label="Plate number"
          >
            {truckDetailDefaultInfo.plateNumber}
            <S.PlateNumberButtonWrapper>
              <Button
                css={S.plateNumberButton}
                variant="textIcon"
                label="GPS company registered vehicles"
                icon={<FileSearchFillIcon />}
                handleClickBtn={handlePlateListClick}
              />
            </S.PlateNumberButtonWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label={"GPS" as Languages}>
            <SuccessIcon
              css={S.gpsIcon(!!truckDetailDefaultInfo.gpsAvailable)}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Status">
            {t(
              upperCaseToupperFirst(
                truckDetailDefaultInfo.category,
              ) as Languages,
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Created on">
            {truckDetailDefaultInfo.created
              ? formatICTDateTime(truckDetailDefaultInfo.created, "DD/MM/YYYY")
              : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Parked garage">
            {truckDetailDefaultInfo.parkedGarage || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Linking">
            <LinkingDriver
              editState={editState}
              handleClickEdit={handleClickEdit}
              handleCancel={handleCancel}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Memo">
            {truckDetailDefaultInfo.memo || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Attached file">
            <S.FileWrapper>
              {truckDetailDefaultInfo.truckPhoto ||
              truckDetailDefaultInfo.registration ? (
                <>
                  {truckDetailDefaultInfo.truckPhoto && (
                    <DownloadBtn
                      downloadFileNamePrefix="truck"
                      fileName="Truck photo"
                      // TODO: s3 prefix로 license 잘 오는지 확인 필요
                      fileKey={truckDetailDefaultInfo.truckPhoto as string} // TODO: as 단언 수정
                      key="truckPhoto"
                      isLoading={isDownloadTruckPhotoLoading}
                      downloadFileMutate={downloadTruckPhotoMutate}
                    />
                  )}
                  {truckDetailDefaultInfo.registration && (
                    <DownloadBtn
                      downloadFileNamePrefix="registration"
                      fileName="Vehicle registration certificate"
                      // TODO: s3 prefix로 license 잘 오는지 확인 필요
                      fileKey={truckDetailDefaultInfo.registration as string} // TODO: as 단언 수정
                      key="registration"
                      isLoading={isDownloadRegistrationLoading}
                      downloadFileMutate={downloadRegistrationMutate}
                    />
                  )}
                </>
              ) : (
                "-"
              )}
            </S.FileWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleClickEdit("DefaultInfo")}
        />
      </S.BtnWrapper>
    </>
  );
};

export default TruckDefaultInfoField;
