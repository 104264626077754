import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

import { COCOTRUCK_LANGUAGE } from "assets";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 64px 1fr 80px;
  height: 100%;
`;

export const Body = styled.ul<{ hasBottomHr: boolean }>`
  ${({ theme, hasBottomHr }) => css`
    ${mixin_scrollbar};
    height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT} - 64px - 80px)`};

    & > li:not(:last-of-type) {
      border-bottom: ${hasBottomHr && `12px solid ${theme.color.gray_20}`};
    }
  `}
`;

export const CalendarBtnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 12px 16px;
    background-color: ${theme.color.gray_20};
  `}
`;

export const CalendarBtnsWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 8px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid ${theme.color.gray_30};
    padding: 9px 12px;
    background-color: ${theme.color.white};
  `}
`;

export const CalendarBtn = styled.button`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 8px;
    width: 100%;
    text-align: start;

    :hover {
      border-color: ${theme.color.primary_10};
    }

    & > svg path {
      fill: ${theme.color.gray_70};
    }
  `}
`;

export const CalendarInputValue = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const CalendarInputPlaceholder = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_50};
  `}
`;

export const deleteIconBtn = css`
  width: 100%;
  height: 100%;

  & > svg {
    margin: 0;
  }
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 80px;
    border: 1px solid ${theme.color.gray_30};
    border-left: 0px;
    padding: 12px 20px 20px;
  `}
`;

export const smartDispatchBtn = css`
  width: 100%;
`;

export const tooltip =
  (language: keyof typeof COCOTRUCK_LANGUAGE) => (theme: Theme) =>
    css`
      position: absolute;
      top: 27px;
      left: ${language === "en" ? "calc(242px + 8px)" : "calc(262px + 8px)"};
      z-index: 2;

      ol {
        counter-reset: liNumber;

        & > li {
          ::before {
            padding-right: 2px;
            counter-increment: liNumber;
            content: counter(liNumber) ".";
          }
        }
      }

      svg path {
        fill: ${theme.color.gray_70};
      }
    `;
