import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_30};
    padding: 32px;
    margin-bottom: 12px;
    background-color: ${theme.color.white};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 12px;
`;

export const labelContent = (theme: Theme) => css`
  width: 100%;
  margin-bottom: 28px;

  && > :nth-of-type(1) > label {
    color: ${theme.color.gray_60};
    margin-bottom: 2px;
  }
`;

export const OrderNumberWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;

    & > div > div > label {
      color: ${theme.color.gray_60};
      margin-bottom: 2px;
    }
  `}
`;

export const dropdown = css`
  max-width: 244px;
`;

export const textarea = css`
  width: 500px;
  height: 200px;
  padding-right: 92px;
`;

export const fileUploader = css`
  & > div:not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 8px;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const attachFile = css`
  margin-bottom: 8px;
`;
