import React from "react";

import { formatPhoneNum } from "@/utils";
import { PhoneIcon } from "@/assets";

import * as S from "./NamePhoneLabel.styled";

interface NamePhoneLabelProps {
  className?: string;
  name: string;
  phone: string;
}

const NamePhoneLabel = ({ className, name, phone }: NamePhoneLabelProps) => {
  return (
    <S.Root className={className}>
      <S.Name>{name}</S.Name>
      <S.PhoneNumWrapper>
        <PhoneIcon css={S.phoneIcon} />
        <S.PhoneNum>{formatPhoneNum(phone)}</S.PhoneNum>
      </S.PhoneNumWrapper>
    </S.Root>
  );
};

export default NamePhoneLabel;
