import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  min-width: 1036px;
  width: 1036px;
  margin: 0 auto;
  padding: 33px 40px 50px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.black};
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  column-gap: 32px;
  justify-content: space-between;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 564px;
`;

export const RightContent = styled.div`
  position: sticky;
  top: 113px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 364px;
  height: max-content;
`;
