import React, { useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { QueryPagination, QuerySearchInput } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetPropertyGarage } from "services";
import type { GetGarageQueryModel } from "types";
import GarageTable from "./table/GarageTable";
import GarageUsage from "./usage/GarageUsage";
import * as S from "./PropertyGarage.styled";

const PropertyGarage = () => {
  const [searchParams] = useSearchParams();

  const searchGarageName = searchParams.get("search");
  const [selectedGarageId, setSelectedGarageId] = useState("");

  const { filters } = useFilterParams<GetGarageQueryModel["query"]>([
    "name",
    "page",
  ]);
  const { data, isLoading } = useGetPropertyGarage({
    query: {
      ...filters,
      ...(searchGarageName && { name: searchGarageName }),
    },
  });

  const handleSelectGarage = useCallback(
    (garageId: string) => () => {
      setSelectedGarageId(garageId);
    },
    [],
  );

  return (
    <S.Root>
      <h3 className="a11y">garage list</h3>
      <div>
        <QuerySearchInput
          css={S.querySearchInput}
          placeholder="Search by garage name"
          searchQuery="search"
          deleteQueries={["page"]}
        />
        <GarageTable
          garages={data?.garages}
          isLoading={isLoading}
          selectedGarageId={selectedGarageId}
          handleSelectGarage={handleSelectGarage}
        />
        <QueryPagination css={S.queryPagination} pageInfo={data?.pageInfo} />
      </div>
      <GarageUsage selectedGarageId={selectedGarageId} />
    </S.Root>
  );
};

export default PropertyGarage;
