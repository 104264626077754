import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    &[type="checkbox"] {
      display: none;
    }

    &[type="checkbox"]:checked + .label {
      & > .box {
        border: 0;
        background: ${theme.color.primary_40} no-repeat center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 3.75L4.75 6.75L10.25 1.25' stroke='white' stroke-width='1.8' stroke-linecap='round'/%3E%3C/svg%3E");
      }
    }

    &[type="checkbox"]:disabled + .label {
      & > .box {
        border: 1px solid ${theme.color.gray_40};
        background: ${theme.color.gray_40} no-repeat center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 3.75L4.75 6.75L10.25 1.25' stroke='white' stroke-width='1.8' stroke-linecap='round'/%3E%3C/svg%3E");

        :hover {
          cursor: not-allowed;
        }
      }
    }

    &[type="checkbox"]:checked:disabled + .label {
      & > .box {
        background: ${theme.color.gray_10} no-repeat center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 3.75L4.75 6.75L10.25 1.25' stroke='white' stroke-width='1.8' stroke-linecap='round'/%3E%3C/svg%3E");

        :hover {
          border: 1px solid ${theme.color.gray_30};
          cursor: not-allowed;
        }
      }
    }
  `}
`;

interface CheckboxClickAbleAreaProps {
  disabled?: boolean;
}

export const CheckboxClickAbleArea = styled.label<CheckboxClickAbleAreaProps>`
  ${({ theme, disabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndex.CHECKBOX};

    :hover {
      & > .box {
        border: 1px solid ${theme.color.primary_40};
      }
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }
  `}
`;

export const CheckBox = styled.span<{ shape: "rectangle" | "circle" }>`
  ${({ theme, shape }) => css`
    width: ${shape === "circle" ? "16px" : "16px"};
    height: ${shape === "circle" ? "16px" : "16px"};
    border: 1px solid ${theme.color.gray_40};
    border-radius: ${shape === "circle" ? "50%" : "2px"};
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.color.black};

    :hover {
      cursor: pointer;
    }
  `}
`;
