import React from "react";
import { useFormContext } from "react-hook-form";

import { Card } from "@/components";
import { calculateTotalAmount, addrFormatter, formatPrice } from "@/utils";
import { TimeIcon, PriceIcon, PointIcon } from "@/assets";

import type { OrderForm } from "types";
import * as S from "./OrderCreationSummary.styled";

const OrderCreationSummaryInfo = () => {
  const { watch } = useFormContext<OrderForm>();

  const totalPrice = formatPrice(
    calculateTotalAmount(watch("payment.subtotal"), watch("payment.tax")),
  );

  return (
    <Card>
      <Card.Content heading="Summary" headingLevel="h3">
        <S.SummaryWrapper>
          <S.Place>
            <PointIcon css={S.pickUpPointIcon} />
            <span>{watch("pickUp.buildingName") ?? "-"}</span>
          </S.Place>
          <S.LocationTimePrice>
            <span>
              {addrFormatter(
                watch("pickUp.address"),
                watch("pickUp.addressDetail"),
              )}
            </span>
          </S.LocationTimePrice>
          <S.LocationTimePrice>
            <TimeIcon />
            <span>
              {watch("pickUp.eta").length ? watch("pickUp.eta") : "-"}
            </span>
          </S.LocationTimePrice>
        </S.SummaryWrapper>
        <S.SummaryWrapper>
          <S.Place>
            <PointIcon css={S.dropOffPointIcon} />
            <span>{watch("dropOff.buildingName") ?? "-"}</span>
          </S.Place>
          <S.LocationTimePrice>
            <span>
              {addrFormatter(
                watch("dropOff.address"),
                watch("dropOff.addressDetail"),
              )}
            </span>
          </S.LocationTimePrice>
          <S.LocationTimePrice>
            <TimeIcon />
            <span>
              {watch("dropOff.eta").length ? watch("dropOff.eta") : "-"}
            </span>
          </S.LocationTimePrice>
        </S.SummaryWrapper>
        <S.SummaryWrapper>
          <S.LocationTimePrice>
            <PriceIcon css={S.priceIcon} />
            <span>{totalPrice}</span>
          </S.LocationTimePrice>
        </S.SummaryWrapper>
      </Card.Content>
    </Card>
  );
};

export default OrderCreationSummaryInfo;
