import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  width: 100%;
`;

export const InputBox = styled.input<{ isErr: boolean }>`
  ${({ theme, isErr }) => css`
    width: 100%;
    height: 44px;
    border: 1px solid ${isErr ? theme.color.red_20 : theme.color.gray_30};
    border-radius: 10px;
    padding: 0 12px;
    font-size: 1.3rem;
    color: ${theme.color.black};
    background-color: ${theme.color.white};

    &::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const errMsg = css`
  margin-top: 5px;
`;
