import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import type { S3FileUploadType } from "@/types";

import { ax } from "apis/axios";
import { makeS3PresignedUrlFileUuids } from "utils";
import type {
  CompanyType,
  GetSettlementDetailServerModel,
  PatchSettlementDetailAccept,
  PatchSettlementDetailReject,
  UpdateSettlementDetailQueryModal,
} from "types";

export const getSettlementDetailAPI = async <T extends CompanyType>(
  offerId: string,
) => {
  const { data } = await ax.get<GetSettlementDetailServerModel<T>>(
    `/settlement/${offerId}`,
  );

  return data;
};

export const updateSettlementDetailAPI = async (
  req: UpdateSettlementDetailQueryModal,
) => {
  try {
    const files: S3FileUploadType = {
      ...(req.body.attachment1 instanceof File && {
        attachment1: {
          file: req.body.attachment1,
          path: "settlement",
        },
      }),
      ...(req.body.attachment2 instanceof File && {
        attachment2: {
          file: req.body.attachment2,
          path: "settlement",
        },
      }),
    };
    const fileUids = await makeS3PresignedUrlFileUuids(files);

    return ax.post(`/settlement/${req.offerId}`, { ...req.body, ...fileUids });
  } catch (err) {
    const { addToast } = useToast();

    addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
  }
};

export const patchSettlementDetailAccept = async (
  req: PatchSettlementDetailAccept,
) => {
  return await ax.patch(`/settlement/${req.offerId}/accept`);
};

export const patchSettlementDetailReject = async (
  req: PatchSettlementDetailReject,
) => {
  return await ax.patch(`/settlement/${req.offerId}/reject`);
};
