import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { isEmpty } from "lodash-es";

import { useToast } from "@/hooks";

import { useCreateDriverLink, useGetPropertyTruckDetail } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";
import type {
  CreateDriverLinkQueryModel,
  GetTruckDetailClientModel,
  AutoCompleteDropdown,
  PropertyDetailEditCardType,
} from "types";

interface LinkForm {
  autoCompletes: {
    id: string;
    name: string;
    profileUrl?: string | null;
  }[];
}

const TRUCK_FORM_INIT_STATE = {
  autoCompletes: [{ name: "", id: "", profileUrl: null }],
};

const useLinkDriver = (
  handleClickEdit: (editName: PropertyDetailEditCardType | null) => () => void,
) => {
  const { id } = useParams();

  const formMethod = useForm<LinkForm>({
    defaultValues: TRUCK_FORM_INIT_STATE,
    mode: "onTouched",
  });

  const { control } = formMethod;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "autoCompletes",
  });

  const controlledFields = fields.map((field, i) => {
    return {
      ...field,
      ...formMethod.watch("autoCompletes")[i],
    };
  });

  const { data } = useGetPropertyTruckDetail(id!);
  const { mutate: createDriverLinkMutate } = useCreateDriverLink();

  const { addToast } = useToast();

  const createDriverLink = (req: CreateDriverLinkQueryModel) => {
    createDriverLinkMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
        handleClickEdit(null)();
      },
      onError: (err) => {
        if (err.response?.data.response === "DRIVER_ALREADY_LINKED") {
          addToast(CARRIER_TOAST_MSG.WARNING.DRIVER_ALREADY_LINK);
          return;
        }

        addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
      },
    });
  };

  const handleLinkSelect =
    (idx: number) =>
    (selectedDropdown: AutoCompleteDropdown): void => {
      update(idx, selectedDropdown);

      if (!formMethod.watch("autoCompletes")[idx].id && selectedDropdown.name) {
        formMethod.setError(`autoCompletes.${idx}`, {
          message: CARRIER_VALID_MSG.DRIVER_NAME,
        });
        return;
      }

      if (formMethod.watch("autoCompletes")[idx].id || !selectedDropdown.name) {
        formMethod.clearErrors(`autoCompletes.${idx}`);
        return;
      }
    };

  const handleLinkSlotAdd = (): void => {
    append({ name: "", id: "" }, { shouldFocus: false });
  };

  const handleLinkSlotDelete = (i: number) => (): void => {
    remove(i);
  };

  const handleLinkDriver = (): void => {
    const body = {
      driverIds: controlledFields
        .filter((driver) => driver.name !== "")
        .filter((item) => !!item.id)
        .map((item) => item.id),
    };

    const req = {
      truckId: id!,
      body,
    };

    createDriverLink(req);
  };

  const handleUnlinkDriver = (): void => {
    const body = {
      driverIds: [],
    };

    const req = {
      truckId: id!,
      body,
    };

    createDriverLink(req);
  };

  const handleResetEdit = (): void => {
    if (!data) return;

    formMethod.reset(makeInitData(data.defaultInfo));
    formMethod.clearErrors();
  };

  const makeInitData = (
    originData?: GetTruckDetailClientModel["defaultInfo"],
  ) => {
    if (!originData) return;

    return {
      autoCompletes: isEmpty(originData.linked)
        ? [{ name: "", id: "" }]
        : originData.linked.map((item) => ({
            name: item.name,
            id: item.driverId,
            profileUrl: item.profileUrl,
          })),
    };
  };

  useEffect(() => {
    const initData = makeInitData(data?.defaultInfo);
    formMethod.reset({
      ...formMethod.getValues(),
      ...initData,
    });
  }, [data]);

  return {
    formMethod,
    fields: controlledFields,
    handleLinkSelect,
    handleLinkSlotAdd,
    handleLinkSlotDelete,
    handleLinkDriver,
    handleUnlinkDriver,
    handleResetEdit,
  };
};

export default useLinkDriver;
