import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { COMMON_TOAST_MSG, COMMON_VALID_MSG, MEMBER_GRADE } from "@/constants";
import { useToast } from "@/hooks";
import {
  checkEmailValidation,
  checkPasswordValidation,
  checkPasswordLength,
  checkPhoneNum,
  makeCryptoFunction,
} from "@/utils";
import { PATH } from "@/assets";
import type {
  DropdownOptionType,
  Languages,
  MemberForm,
  ValueOf,
} from "@/types";

import { usePostMember } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";

const initForm = {
  lastName: "",
  middleName: "",
  firstName: "",
  phone: "",
  grade: { key: MEMBER_GRADE.moderator, label: "Moderator" as Languages },
  email: "",
  password: "",
  confirmPassword: "",
};

const schema = yup
  .object({
    lastName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    middleName: yup.string().defined(),
    firstName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    phone: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("phone", CARRIER_VALID_MSG.PHONE, (value) => checkPhoneNum(value)),
    grade: yup.mixed<DropdownOptionType>().defined(),
    email: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("email", CARRIER_VALID_MSG.EMAIL, (value) =>
        checkEmailValidation(value),
      ),
    password: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("passwordStepOne", CARRIER_VALID_MSG.PASSWORD_STEP_ONE, (value) =>
        checkPasswordLength(value),
      )
      .test("passwordStepTwo", CARRIER_VALID_MSG.PASSWORD_STEP_TWO, (value) =>
        checkPasswordValidation(value),
      ),
    confirmPassword: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test(
        "confirmPasswordStepOne",
        CARRIER_VALID_MSG.PASSWORD_STEP_ONE,
        (value) => checkPasswordLength(value),
      )
      .test(
        "confirmPasswordStepTwo",
        CARRIER_VALID_MSG.PASSWORD_STEP_TWO,
        (value) => checkPasswordValidation(value),
      )
      .test(
        "passwordConfirm",
        CARRIER_VALID_MSG.PASSWORD_CONFIRM,
        function (value) {
          return value === this.resolve(yup.ref("password")); // NOTE: this를 사용하려면 화살표 함수를 사용할 수 없음
        },
      ),
  })
  .required();

const useCreateMember = () => {
  const navigate = useNavigate();

  const { mutate: createMemberMutate, isLoading } = usePostMember();

  const { addToast } = useToast();

  const formMethod = useForm<MemberForm>({
    defaultValues: initForm,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const createMember = formMethod.handleSubmit((data: MemberForm) => {
    const req = {
      body: {
        firstName: data.firstName,
        ...(data.middleName && { middleName: data.middleName }),
        lastName: data.lastName,
        phone: data.phone,
        grade: data.grade.key as ValueOf<typeof MEMBER_GRADE>,
        email: data.email,
        password: makeCryptoFunction(data.password),
      },
    };

    createMemberMutate(req, {
      onSuccess: () => {
        navigate(PATH.SETTINGS_MEMBER);
        addToast(CARRIER_TOAST_MSG.SUCCESS.MEMBER_ADD_DONE);
      },
      onError: (err) => {
        if (err.response?.data.response === "EMAIL_EXISTS") {
          formMethod.setError("email", {
            message: CARRIER_VALID_MSG.DUPLICATE_EMAIL,
          });
          addToast(COMMON_TOAST_MSG.WARNING.DUPLICATE_EMAIL);
        }

        if (err.response?.data.response === "PHONE_EXISTS") {
          formMethod.setError("phone", {
            message: CARRIER_VALID_MSG.PHONE_EXISTS,
          });
          addToast(COMMON_TOAST_MSG.WARNING.PHONE_EXISTS);
        }
      },
    });
  });

  const handleCancel = () => navigate(PATH.SETTINGS_MEMBER);

  return {
    formMethod,
    isLoading,
    handleCancel,
    handleSubmit: createMember,
  };
};

export default useCreateMember;
