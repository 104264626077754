/* eslint-disable no-useless-escape */

const CAPACITY_LIMIT_VALUE = 2147483648;
const PIC_LIMIT_VALUE = 36;
const TRUCK_COUNT_LIMIT_VALUE = 100;
const CARGO_SIZE_LIMIT_VALUE = 1000;
const CARGO_NAME_LIMIT_VALUE = 101;
const CARGO_WEIGHT_LIMIT_VALUE = 80001;
const CLIENT_LIMIT_VALUE = 101;

export const checkCargoSizeLimit = (cargoSize: string) =>
  +cargoSize < CARGO_SIZE_LIMIT_VALUE;

export const checkClientLimit = (clientLength: string) =>
  +clientLength < CLIENT_LIMIT_VALUE;

export const checkGoodsCountLimit = (goodsCount: string) =>
  +goodsCount < CAPACITY_LIMIT_VALUE;

export const checkCargoNameLimit = (cargoNameLen: string) =>
  +cargoNameLen < CARGO_NAME_LIMIT_VALUE;

export const checkTruckCountLimit = (truckCount: string) =>
  +truckCount < TRUCK_COUNT_LIMIT_VALUE;

export const checkWeightLimit = (weight: string) =>
  +weight < CARGO_WEIGHT_LIMIT_VALUE;

export const checkPicLimit = (picLength: string) =>
  +picLength < PIC_LIMIT_VALUE;

// TODO: any 타입 수정
export const isNode = (v: any) => {
  return typeof Node === "object"
    ? v instanceof Node
    : v &&
        typeof v === "object" &&
        typeof v.nodeType === "number" &&
        typeof v.nodeName === "string";
};

export const checkValidCargoSize = (...size: string[]) => {
  const filledValueCnt = size.filter(Boolean).length;
  return !filledValueCnt || filledValueCnt === size.length;
};
