import React from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { useDetailFormEdit } from "hooks";
import type {
  GetPropertyDriverDetailClientModel,
  PropertyDetailEditCardType,
} from "types";
import DefaultInfoField from "./field/DriverDefaultInfoField";
import DefaultInfoFieldEdit from "./fieldEdit/DriverDefaultInfoFieldEdit";
import { useDefaultInfo } from "../hooks";

interface DriverDefaultInfoProps {
  data: GetPropertyDriverDetailClientModel["defaultInfo"];
}

const DriverDefaultInfo = ({ data }: DriverDefaultInfoProps) => {
  const { id } = useParams();

  const { editState, handleEditState, handleCancel } = useDetailFormEdit();

  const {
    formMethod,
    handleChangeDefaultInfo,
    handleBlurlinkedRegistNumber,
    handleSubmitDefaultInfo,
    handleCancelDefaultInfo,
    handleUpdateLink,
    handleUnlinkTruck,
    handleDelFile,
    handleSelectLink,
  } = useDefaultInfo(data, id!, handleEditState);

  const handleClickEdit = (editName: PropertyDetailEditCardType) => () => {
    handleEditState(editName, true)();
  };

  const handleClickCancel = () => {
    handleCancelDefaultInfo();
    handleCancel();
  };

  return (
    <FormProvider {...formMethod}>
      {editState !== "DefaultInfo" ? (
        <DefaultInfoField
          data={data}
          editState={editState}
          handleUpdate={handleUpdateLink}
          handleClickEdit={handleClickEdit}
          handleSelectLink={handleSelectLink}
          handleUnlinkTruck={handleUnlinkTruck}
          handleClickCancel={handleClickCancel}
          handleChangeDefaultInfo={handleChangeDefaultInfo}
          handleBlurlinkedRegistNumber={handleBlurlinkedRegistNumber}
        />
      ) : (
        <DefaultInfoFieldEdit
          handleDelFile={handleDelFile}
          handleClickCancel={handleClickCancel}
          handleSubmit={handleSubmitDefaultInfo}
          handleChangeDefaultInfo={handleChangeDefaultInfo}
        />
      )}
    </FormProvider>
  );
};

export default DriverDefaultInfo;
