import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, InternalLink, TableOfContent } from "@/components";
import { hasNotCanceledDriverDeliveryStatus } from "@/utils";
import { MapIcon, PATH, PencilIcon } from "@/assets";
import type { Languages } from "@/types";

import {
  OrderManagementDefaultInfo,
  OrderManagementAllocationInfo,
  OrderManagementPickUpInfo,
  OrderManagementDropOffInfo,
  OrderManagementCargoDetails,
  OrderManagementTruckingMethods,
  OrderManagementPayment,
  OrderManagementTruckForm,
  OrderManagementTruckList,
  OrderManagementDetailLayout,
} from "components";
import { useGetOrderManagementDetail } from "services";
import { useOrderManagementDetail } from "./hooks";
import * as S from "./OrderManagementDetail.styled";

const CarrierOrderManagementDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetOrderManagementDetail({
    suspense: false,
    orderId: id!,
  });

  const {
    formMethod,
    isEdit,
    truckSum,
    handleIsEdit,
    handleTruckCntCancel,
    handleSubmit,
  } = useOrderManagementDetail(data);

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const status = data.status;
  const isShowCargoTrackingLink = !!status && status === "COMPLETED";
  const isHideOrderCancelBtn =
    !data.isSelfOrder ||
    status === "CANCELED" ||
    status === "COMPLETED" ||
    hasNotCanceledDriverDeliveryStatus(data.allocationInfo.drivers);

  const contents = [
    {
      text: "Default information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDefaultInfo data={data} />
        </Suspense>
      ),
    },
    {
      addHeadingElement:
        (data.status === "OFFER_ACCEPTED" || data.status === "SHIPPING") &&
        (!isEdit ? (
          <Button
            icon={<PencilIcon />}
            label="Edit"
            variant="ghostNoUnderlinePrimary"
            handleClickBtn={handleIsEdit}
          />
        ) : (
          (data.status === "OFFER_ACCEPTED" || data.status === "SHIPPING") && (
            <S.BtnWrapper>
              <Button
                type="button"
                label="Cancel"
                variant="ghostNoUnderlineGray"
                handleClickBtn={handleTruckCntCancel}
              />
              <Button
                type="button"
                label="Confirm"
                variant="ghostNoUnderlinePrimary"
                isDisabled={Object.keys(formMethod.formState.errors).length > 0}
                handleClickBtn={handleSubmit}
              />
            </S.BtnWrapper>
          )
        )),
      text:
        data.status === "OFFER_ACCEPTED" || data.status === "SHIPPING"
          ? "Trucks"
          : `Truck list (${data.trucks ? Object.keys(data.trucks).length : 0})`,
      content: (
        <Suspense fallback={<Fragment />}>
          {data.status === "OFFER_ACCEPTED" || data.status === "SHIPPING" ? (
            <OrderManagementTruckForm isEdit={isEdit} />
          ) : (
            <OrderManagementTruckList data={data} />
          )}
        </Suspense>
      ),
    },
    {
      text: "Allocation information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementAllocationInfo
            isEdit={isEdit}
            truckSum={truckSum}
            data={data}
          />
        </Suspense>
      ),
    },
    {
      text: "Pick-up information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPickUpInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Drop-off information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDropOffInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: `${t("Cargo details")} (${data.cargoes.length})`,
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementCargoDetails data={data} />
        </Suspense>
      ),
    },
    {
      text: "Trucking methods",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementTruckingMethods data={data} />
        </Suspense>
      ),
    },
    {
      text: "Payment",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPayment data={data} />
        </Suspense>
      ),
    },
  ];

  return (
    <FormProvider {...formMethod}>
      <OrderManagementDetailLayout
        tableOfContent={
          <TableOfContent
            css={S.tableOfContent}
            pageName="Order details"
            contents={contents.map(({ addHeadingElement, text, content }) => ({
              heading: { headingLevel: "h2", text, addHeadingElement },
              content,
            }))}
            stickyBottom={
              isShowCargoTrackingLink ? (
                <InternalLink
                  css={S.cargoTracking}
                  variant="ghostNoUnderlineGray"
                  to={`${PATH.ORDER_MANAGEMENT_MAP}?orderId=${id}`}
                >
                  <MapIcon />
                  {/* TODO: 번역 추가되면 as Languages 삭제할 것 */}
                  {t("Tracking history" as Languages)}
                </InternalLink>
              ) : null
            }
          />
        }
        isHideOrderCancelBtn={isHideOrderCancelBtn}
        data={data}
      />
    </FormProvider>
  );
};

export default CarrierOrderManagementDetail;
