import React from "react";
import { useParams } from "react-router-dom";

import {
  SettlementDetailLayout,
  SettlementDetailPayment,
  SettlementDetailDefaultInfo,
  SettlementDetailRequestPayment,
  SettlementDetailPreviousPayment,
} from "components";
import { useGetSettlementDetail } from "services";

const CarrierSettlementDetail = () => {
  const { id } = useParams();

  const { data } = useGetSettlementDetail<"carrier">(id!);

  if (!data) return null;

  const payment = {
    heading: { headingLevel: "h2" as const, text: "Payment" },
    content: <SettlementDetailPayment data={data} />,
  };
  const defaultInfo = {
    heading: { headingLevel: "h2" as const, text: "Default information" },
    content: <SettlementDetailDefaultInfo data={data} />,
  };
  const requiestedPayment = {
    heading: { headingLevel: "h2" as const, text: "Requested payment" },
    content: <SettlementDetailRequestPayment data={data} />,
  };
  const previousPayment = {
    heading: { headingLevel: "h2" as const, text: "Payment" },
    content: <SettlementDetailPreviousPayment data={data} />,
  };

  const contents = {
    WAITING: [payment, defaultInfo],
    ONGOING: [requiestedPayment, previousPayment, defaultInfo],
    COMPLETED: [payment, defaultInfo],
  };

  return (
    <SettlementDetailLayout
      status={data.status}
      content={contents[data.status].map(({ heading, content }) => ({
        heading,
        content,
      }))}
    />
  );
};

export default CarrierSettlementDetail;
