import { useState, useEffect } from "react";

import { useGetLinkManageDriver } from "services";
import type { GetDriverSearchServerModel } from "types";

const useSearchDriver = (selectedTruckId: string | null) => {
  const [searchText, setSearchText] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState<
    GetDriverSearchServerModel | undefined
  >(undefined);

  const { isLoading, data } = useGetLinkManageDriver({
    enabled: !!selectedTruckId,
  });

  const handleSearch = () => {
    setFilteredDrivers(
      data?.filter((driver) =>
        driver.name
          .toLocaleLowerCase()
          .includes(searchText.toLocaleLowerCase()),
      ),
    );
  };

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length > 100) return;
    setSearchText(value);
  };

  const handleResetSearch = () => {
    setSearchText("");
    setFilteredDrivers(data);
  };

  useEffect(() => {
    setFilteredDrivers(data);
  }, [data]);

  return {
    drivers: filteredDrivers,
    isLoading,
    searchText,
    handleSearch,
    handleChangeSearchText,
    handleResetSearch,
  };
};

export default useSearchDriver;
