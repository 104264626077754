import React from "react";

import { formatOrderNum } from "@/utils";
import { DownArrowIcon16 } from "@/assets";

import * as S from "./BtnFoldOrderNumber.styled";

interface BtnFoldOrderNumberProps {
  isFold: boolean;
  orderNumber: string;
  handleFoldBtnClick: () => void;
}

const BtnFoldOrderNumber = ({
  isFold,
  orderNumber,
  handleFoldBtnClick,
}: BtnFoldOrderNumberProps) => {
  return (
    <S.BtnFoldOrderNumber
      type="button"
      isFold={isFold}
      onClick={handleFoldBtnClick}
    >
      <S.OrderInfoFoldBtnContentWrapper>
        {formatOrderNum(orderNumber)}
      </S.OrderInfoFoldBtnContentWrapper>
      <DownArrowIcon16 css={S.foldBtn(isFold)} />
    </S.BtnFoldOrderNumber>
  );
};

export default BtnFoldOrderNumber;
