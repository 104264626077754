import React from "react";

import { auth } from "@/contexts";
import { DataSection, StatusLabel } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";

import {
  CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_COLUMNS,
  SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_COLUMNS,
} from "assets";
import type { CompanyType, GetOrderAllocationServerModel } from "types";
import * as S from "./AllocationSection.styled";

interface AllocationSectionProps {
  data: GetOrderAllocationServerModel<CompanyType>;
  dataUpdatedAt: number;
  refetch: () => void;
}

const AllocationSection = ({
  data,
  dataUpdatedAt,
  refetch,
}: AllocationSectionProps) => {
  const columns =
    auth.clientType === "carrier" || auth.clientType === "forwardercarrier"
      ? CARRIER_FORWARDERCARRIER_ORDER_ALLOCATION_TABLE_COLUMNS
      : SHIPPER_FORWARDER_ORDER_ALLOCATION_TABLE_COLUMNS;
  const filteredColumns = filterTableColumns(columns);

  const formatTableData = (
    data: GetOrderAllocationServerModel<CompanyType>["order"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "status":
        return "status" in data ? <StatusLabel status={data.status} /> : null;

      case "orderNumber":
        return formatOrderNum(data[key]);

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddress":
        return addrFormatter(data.puAddress, data.puAddressDetail);
      case "doAddress":
        return addrFormatter(data.doAddress, data.doAddressDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table(auth.clientType!)}
        title="Allocation"
        isStickyThead
        rowCount={data.order?.length}
      >
        <DataSection.Table.Head columnList={columns} />
        <DataSection.Table.Body css={S.body}>
          {data.order?.length ? (
            data.order.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.orderId}
                path={item.orderId}
              >
                {filteredColumns.map(({ key }) => {
                  return (
                    <DataSection.Table.Cell key={key}>
                      {formatTableData(item, key)}
                    </DataSection.Table.Cell>
                  );
                })}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData />
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default AllocationSection;
