import React from "react";

import { Input } from "@/components";
import { useOnClickOutside } from "@/hooks";
import type { Languages } from "@/types";

import useTruckLinkAutoComplete from "./hooks/useTruckLinkAutoComplete";
import * as S from "./TruckLinkAutoComplete.styled";

interface TruckLinkAutoCompleteProps {
  className?: string;
  id: string;
  name: string;
  placeholder: Languages;
  driverId: string;
  value: string;
  hasError?: boolean;
  maxLength?: number;
  onChange?: any;
  onSelect: any;
  onBlur?: any;
  register: any;
}

const TruckLinkAutoComplete = ({
  className,
  id,
  placeholder,
  driverId,
  value,
  hasError,
  maxLength,
  onSelect,
  onBlur,
  register,
}: TruckLinkAutoCompleteProps) => {
  const {
    listRef,
    truckLinks,
    isFocused,
    handleFocus,
    handleKeyDown,
    handleUnsetValue,
    handleSelectOption,
  } = useTruckLinkAutoComplete(value, driverId, onSelect, onBlur);

  useOnClickOutside(listRef, handleUnsetValue);

  return (
    <S.Root className={className}>
      <S.InputWrapper>
        <Input
          css={S.input}
          id={id}
          placeholder={placeholder}
          aria-haspopup="listbox"
          aria-controls="link-list"
          aria-expanded={truckLinks?.length ? true : false}
          value={value}
          maxLength={maxLength}
          hasError={hasError}
          handleKeyDown={handleKeyDown}
          handleFocus={handleFocus}
          register={register}
        />
        {isFocused && (
          <S.DropDownWrapper
            ref={listRef}
            id="link-list"
            role="listbox"
            aria-label="link list"
          >
            {truckLinks?.map(({ truckId, registNumber }) => (
              <S.DropDownItem key={truckId}>
                <button
                  type="button"
                  onClick={handleSelectOption({ registNumber, truckId })}
                >
                  {registNumber}
                </button>
              </S.DropDownItem>
            ))}
          </S.DropDownWrapper>
        )}
      </S.InputWrapper>
    </S.Root>
  );
};

export default TruckLinkAutoComplete;
