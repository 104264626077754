import React from "react";

import { CompanyLicenseRegistrationLayout } from "components";
import CarrierCompanyLicenseRegistrationForm from "./form/CarrierCompanyLicenseRegistrationForm";

const CarrierCompanyLicenseRegistration = () => {
  return (
    <CompanyLicenseRegistrationLayout
      licenseRegistrationForm={<CarrierCompanyLicenseRegistrationForm />}
    />
  );
};

export default CarrierCompanyLicenseRegistration;
