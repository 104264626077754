import React from "react";
import { useRecoilValue } from "recoil";

import { Filter } from "@/components";
import { clientLanguage } from "@/stores";

import { truckTypeOptionsState } from "stores";
import { formatTruckTypes } from "utils";

const PropertyManageFilter = () => {
  const language = useRecoilValue(clientLanguage);
  const truckTypeOptions = useRecoilValue(truckTypeOptionsState);
  const truckTypes = formatTruckTypes(language, truckTypeOptions);

  const searchLabels = [
    { queryKey: "driverName", label: "Linking" },
    { queryKey: "truckTypeId", label: "Truck type", options: truckTypes },
    { queryKey: "registNumber", label: "Plate number" },
  ];

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Linking">
          <Filter.Input
            maxLength={150}
            queryKey="driverName"
            placeholder="Search by driver name"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Truck type">
          <Filter.Dropdown
            queryKey="truckTypeId"
            dropdowns={truckTypes}
            placeholder="Select the truck type"
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Plate number">
          <Filter.Input
            maxLength={20}
            queryKey="registNumber"
            placeholder="Plate number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default PropertyManageFilter;
