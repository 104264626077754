/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Input, CalendarInput } from "@/components";
import { useModal } from "@/hooks";
import { CheckerIcon, CloseIcon12, DownArrowIcon20, ResetIcon } from "@/assets";
import type { Languages, SearchLabelsType } from "@/types";

import {
  useFilterBorderLine,
  useFilterCalendar,
  useFilterDropdown,
  useFilterInput,
  useFilterRadio,
  useFilterSearchLabel,
} from "./hooks";
import * as S from "./Filter.styled";

interface FilterProps {
  className?: string;
  searchLabels: SearchLabelsType;
  children: React.ReactNode;
}

const Filter = ({ className, searchLabels, children }: FilterProps) => {
  const { t } = useTranslation();

  const { labels, hasLabel, handleDeleteSearchLabel, handleResetSearchLabel } =
    useFilterSearchLabel(searchLabels);

  return (
    <S.Filter className={className}>
      {children}
      {hasLabel && (
        <S.SearchLabelBoxWrapper>
          <S.ResetBtn type="button" onClick={handleResetSearchLabel}>
            <ResetIcon css={S.resetIcon} />
            {t("Reset")}
          </S.ResetBtn>
          <S.SearchLabelWrapper>
            {Object.entries(labels).map(
              ([label, { searchs, queryKey, options, link }]) =>
                searchs.map((search, i) => (
                  <S.SearchLabel key={i}>
                    <S.SearchName>{t(label as Languages)}: </S.SearchName>
                    <S.SearchValue>
                      {options
                        ? t(
                            options.find((option) => option.key === search)
                              ?.label as Languages,
                          )
                        : search}
                    </S.SearchValue>
                    <S.CloseBtn
                      type="button"
                      onClick={handleDeleteSearchLabel(queryKey, search, link)}
                    >
                      <CloseIcon12 />
                    </S.CloseBtn>
                  </S.SearchLabel>
                )),
            )}
          </S.SearchLabelWrapper>
        </S.SearchLabelBoxWrapper>
      )}
    </S.Filter>
  );
};

interface RowProps {
  className?: string;
  partition?: number;
  children: React.ReactNode;
}

Filter.Row = function Row({ className, partition = 1, children }: RowProps) {
  return (
    <S.Row className={className} partition={partition}>
      {children}
    </S.Row>
  );
};

interface ConditionContext {
  isConditionFocus: boolean;
  isConditionError: boolean;
  handleFocusCondition: () => void;
  handleBlurCondition: () => void;
  handleCreateConditionError: () => void;
  handleDeleteConditionError: () => void;
}

const ConditionContext = createContext({} as ConditionContext);

interface ConditionProps {
  conditionName: Languages;
  isRequired?: boolean;
  children: React.ReactNode;
}

Filter.Condtion = function Condition({
  conditionName,
  isRequired,
  children,
}: ConditionProps) {
  const { t } = useTranslation();

  const {
    isConditionFocus,
    isConditionError,
    handleFocusCondition,
    handleBlurCondition,
    handleCreateConditionError,
    handleDeleteConditionError,
  } = useFilterBorderLine();

  return (
    <ConditionContext.Provider
      value={{
        isConditionFocus,
        isConditionError,
        handleFocusCondition,
        handleBlurCondition,
        handleCreateConditionError,
        handleDeleteConditionError,
      }}
    >
      <S.ConditionWrapper>
        <S.ConditionName
          isConditionFocus={isConditionFocus}
          isConditionError={isConditionError}
        >
          {t(conditionName)}
          {isRequired && <S.Required>*</S.Required>}
        </S.ConditionName>
        <S.Condition
          isConditionFocus={isConditionFocus}
          isConditionError={isConditionError}
        >
          {children}
        </S.Condition>
      </S.ConditionWrapper>
    </ConditionContext.Provider>
  );
};

interface InputProps {
  maxLength?: number;
  queryKey: string;
  placeholder: Languages;
  exclusionRegExp?: RegExp;
}

Filter.Input = function FilterInput({
  maxLength,
  queryKey,
  placeholder,
  exclusionRegExp,
}: InputProps) {
  const { t } = useTranslation();

  const {
    handleFocusCondition,
    handleBlurCondition,
    handleCreateConditionError,
    handleDeleteConditionError,
  } = useContext(ConditionContext);

  const {
    inputRef,
    applyBtnRef,
    isVisibleApplyBtn,
    searchValue,
    handleFocus,
    handleBlur,
    handleInput,
    handleForm,
  } = useFilterInput(
    queryKey,
    handleFocusCondition,
    handleBlurCondition,
    handleCreateConditionError,
    handleDeleteConditionError,
    exclusionRegExp,
  );

  return (
    <S.InputWrapper onSubmit={handleForm}>
      <Input
        css={S.input}
        ref={inputRef}
        value={searchValue}
        maxLength={maxLength}
        placeholder={placeholder}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        handleChangeValue={handleInput}
      />
      {isVisibleApplyBtn && (
        <S.InputApplyBtn ref={applyBtnRef} onBlur={handleBlur}>
          {t("Apply")}
        </S.InputApplyBtn>
      )}
    </S.InputWrapper>
  );
};

interface RadioProps {
  queryKey: string;
  radios: { key: string; label: Languages }[];
}

Filter.Radio = function Radio({ queryKey, radios }: RadioProps) {
  const { t } = useTranslation();

  const { uuid, selectRadio, handleClickRadio } = useFilterRadio(queryKey);

  return (
    <S.RadioWrapper>
      {radios.map((radio, i) => (
        <S.RadioBtn key={i} radioKey={radio.key}>
          <input
            id={uuid + i}
            type="radio"
            name={queryKey}
            checked={selectRadio === radio.key}
            value={radio.key}
            onChange={handleClickRadio(queryKey, radio.key)}
          />
          <label htmlFor={uuid + i} tabIndex={0}>
            {t(radio.label)}
          </label>
        </S.RadioBtn>
      ))}
    </S.RadioWrapper>
  );
};

interface DropdownProps {
  queryKey: string;
  placeholder?: Languages;
  link?: string;
  dropdowns?: { key: string; label: Languages }[];
}

Filter.Dropdown = function Dropdown({
  queryKey,
  placeholder,
  link,
  dropdowns,
}: DropdownProps) {
  const { t } = useTranslation();

  const { handleFocusCondition, handleBlurCondition } =
    useContext(ConditionContext);

  const {
    dropdownRef,
    isOpenDropdown,
    selectDropdown,
    handleOpenDropdown,
    handleCloseDropdown,
    handleSelectDropdown,
  } = useFilterDropdown(
    queryKey,
    dropdowns ?? [],
    handleFocusCondition,
    handleBlurCondition,
    link,
  );

  return (
    <S.Dropdown ref={dropdownRef}>
      <S.OpenBtn
        type="button"
        isOpenDropdown={isOpenDropdown}
        disabled={!dropdowns}
        onClick={isOpenDropdown ? handleCloseDropdown : handleOpenDropdown}
      >
        <S.SelectDropdown isSelected={!!selectDropdown}>
          {t((selectDropdown?.label ?? placeholder) as Languages)}
        </S.SelectDropdown>
        <DownArrowIcon20 />
      </S.OpenBtn>
      {isOpenDropdown && dropdowns && (
        <S.DropdownsWrapper>
          {dropdowns.map((dropdown, i) => (
            <li key={i}>
              <S.DropdownItem
                type="button"
                isSelected={dropdown.key === selectDropdown?.key}
                onClick={handleSelectDropdown(dropdown, queryKey)}
              >
                {t(dropdown.label)}
                {dropdown.key === selectDropdown?.key && (
                  <CheckerIcon css={S.checkerIcon} />
                )}
              </S.DropdownItem>
            </li>
          ))}
        </S.DropdownsWrapper>
      )}
    </S.Dropdown>
  );
};

interface CalendarProps {
  queryKey: string;
}

Filter.Calendar = function Calendar({ queryKey }: CalendarProps) {
  const { handleFocusCondition, handleBlurCondition } =
    useContext(ConditionContext);

  const { selectedDates, selectedDate, handleChangeFilterDate } =
    useFilterCalendar(queryKey);

  return (
    <CalendarInput
      css={S.calendar}
      type="free"
      selectedDate={selectedDates}
      value={selectedDate}
      handleChangeDate={handleChangeFilterDate}
      handleFocusCondition={handleFocusCondition}
      handleBlurCondition={handleBlurCondition}
    />
  );
};

interface ModalProps {
  selectModalData: string;
  modal: React.ReactNode;
  btnLabel?: Languages;
}

Filter.Modal = function Modal({
  btnLabel = "Select",
  selectModalData,
  modal,
}: ModalProps) {
  const { t } = useTranslation();

  const { handleModalOpen } = useModal();

  return (
    <S.ModalWrapper>
      <S.SelectModalData>{selectModalData}</S.SelectModalData>
      <S.ModalBtn type="button" onClick={handleModalOpen(modal)}>
        {t(btnLabel)}
      </S.ModalBtn>
    </S.ModalWrapper>
  );
};

export default Filter;
