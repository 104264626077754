import React from "react";
import { useSearchParams } from "react-router-dom";

import { addrFormatter, filterValidCoords } from "@/utils";

import { Foldable2DepthLayout, OrderDetail } from "components";
import { useOrderDetailTrackingHistory } from "services";
import { MONITORING_QUERY_PARAMS } from "assets";

const ShipperOrderMap = () => {
  const [searchParams] = useSearchParams();

  const { isLoading, data: monitoringOrderDetailData } =
    useOrderDetailTrackingHistory(
      searchParams.get(MONITORING_QUERY_PARAMS.ORDER_ID) as string,
    );

  if (!monitoringOrderDetailData) return null;

  return (
    <Foldable2DepthLayout>
      <OrderDetail
        isLoading={isLoading}
        orderCard={{
          orderNumber: monitoringOrderDetailData.orderNumber,
          name: monitoringOrderDetailData.truckingCompany,
          phone: monitoringOrderDetailData.truckingCompanyPhone,
          puEta: monitoringOrderDetailData.puEta,
          status: monitoringOrderDetailData.status,
        }}
        allocations={monitoringOrderDetailData.delivery.map((allocation) => ({
          driverInfo: {
            profile: allocation.driverProfile,
            name: allocation.driverName,
            truckTypeEn: allocation.truckTypeEn,
            truckTypeVi: allocation.truckTypeVi,
            registNumber: allocation.plateNumber,
            phone: allocation.phone,
          },
          gpsAvailable: allocation.gpsAvailable,
          deliveries: [
            {
              lat: allocation.puLat,
              lng: allocation.puLng,
              type: "PICK",
              addr: addrFormatter(allocation.puAddr, allocation.puAddrDetail),
              completed: allocation.puAta,
            },
            {
              lat: allocation.doLat,
              lng: allocation.doLng,
              type: "DROP",
              addr: addrFormatter(allocation.doAddr, allocation.doAddrDetail),
              completed: allocation.doAta,
            },
          ],
          coords: filterValidCoords(
            allocation.coord.map(([lat, lng]) => ({ lat, lng })),
          ),
        }))}
        googleMapCenterLatLng={{
          pickUp: {
            lat: +monitoringOrderDetailData.delivery[0].puLat,
            lng: +monitoringOrderDetailData.delivery[0].puLng,
            type: "PICK",
          },
          dropOff: {
            lat: +monitoringOrderDetailData.delivery[0].doLat,
            lng: +monitoringOrderDetailData.delivery[0].doLng,
            type: "DROP",
          },
        }}
      />
    </Foldable2DepthLayout>
  );
};

export default ShipperOrderMap;
