import { motion } from "framer-motion";
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const MonitoringShippingListWrapper = styled.ul`
  ${mixin_scrollbar};
  height: 100%;

  & > li {
    width: 100%;
  }
`;

export const pagination = (theme: Theme) => css`
  height: 40px;
  border-top: 1px solid ${theme.color.gray_30};
`;

export const TruckDetailInfoWrapper = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background-color: ${theme.color.gray_20};
  `}
`;
