import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { auth } from "@/contexts";
import { useTab } from "@/hooks";
import { InternalLink, Tab, TableLayout } from "@/components";
import { PATH, PlusCircleIcon12 } from "@/assets";

import {
  FORWARDER_FORWARDERCARRIER_QUOTATION_TABS,
  SHIPPER_CARRIER_QUOTATION_TABS,
} from "assets";
import * as S from "./QuotationLayout.styled";

interface QuotationLayoutProps {
  render: { [key: string]: ReactNode };
}

const QuotationLayout = ({ render }: QuotationLayoutProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const tabs =
    auth.clientType === "shipper" || auth.clientType === "carrier"
      ? SHIPPER_CARRIER_QUOTATION_TABS
      : FORWARDER_FORWARDERCARRIER_QUOTATION_TABS;

  const { selectedTab } = useTab(tabs);

  return (
    <TableLayout h2="Quotation">
      <h3 className="a11y">{selectedTab}</h3>
      <Tab tabs={tabs} css={S.tabs}>
        <S.TabTitle hasMargin={searchParams.get("tab") === "ongoing"}>
          <Tab.TabList />
          <InternalLink
            variant="ghostNoUnderlinePrimary"
            to={PATH.ORDER_CREATION}
          >
            <PlusCircleIcon12 />
            {t("Order creation")}
          </InternalLink>
        </S.TabTitle>
        <Tab.TabPanel render={render} />
      </Tab>
    </TableLayout>
  );
};

export default QuotationLayout;
