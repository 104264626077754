export const formatOrderNum = (num?: string): string => {
  if (!num) return "";
  return "#" + " " + num.replace(/(\S{3})(\S{6})(\S{4})/g, "$1-$2-$3");
};

export const getFormattedOrderNum = (orderNum: string): string => {
  const regex = /[^a-zA-Z0-9]/g;
  const newOrderNum = orderNum
    ?.replace(regex, "")
    .toUpperCase()
    ?.replace(/([A-Z0-9]{3})([A-Z0-9]{6})([A-Z0-9]{4})$/, "$1-$2-$3")
    ?.replace("--", "-");

  return newOrderNum;
};

export const filterOrderNum = (orderNum?: string): string => {
  if (!orderNum) return "";

  return orderNum.replace(/[-#]/g, "");
};
