import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";
import { Button, InternalLink, Table } from "@/components";
import { useFilterParams, useModal } from "@/hooks";
import {
  filterTableColumns,
  findLookupTableLabel,
  formatPhoneNum,
} from "@/utils";
import {
  MEMBER_GRADE_OPTIONS,
  MEMBER_TABLE_COLUMNS,
  PencilIcon,
  TrashSmallIcon,
} from "@/assets";
import type {
  GetMembersQueryModel,
  GetMembersServerModel,
  Languages,
} from "@/types";

import { useGetMembers } from "services";
import DeleteMemberModal from "./deleteMemberModal/DeleteMemberModal";
import * as S from "./MemberTable.styled";

const MemberTable = () => {
  const { filters } = useFilterParams<GetMembersQueryModel["query"]>(["page"], {
    pageSize: 10,
  });

  const { data, isLoading } = useGetMembers({
    suspense: false,
    req: { query: filters },
  });

  const filteredColumns = filterTableColumns(MEMBER_TABLE_COLUMNS);

  const { t } = useTranslation();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { auth } = useAuth();

  const formatTableData = (
    data: GetMembersServerModel["staffs"][number],
    key: keyof GetMembersServerModel["staffs"][number] | "utils",
  ) => {
    const grade = findLookupTableLabel(MEMBER_GRADE_OPTIONS, data.grade);

    switch (key) {
      case "phone":
        return formatPhoneNum(data[key]);

      case "grade":
        return t(grade as Languages);

      case "utils":
        return (
          auth.staffId !== data.staffId && (
            <S.Utils>
              <Button
                variant="icon"
                icon={<TrashSmallIcon />}
                handleClickBtn={handleModalOpen(
                  <DeleteMemberModal
                    ref={modalRef}
                    staffId={data.staffId}
                    handleModalClose={handleModalClose}
                  />,
                )}
              />
              <InternalLink variant="icon" to={`edit/${data.staffId}`}>
                <PencilIcon />
              </InternalLink>
            </S.Utils>
          )
        );

      default:
        return data[key];
    }
  };

  return (
    <Table
      css={S.memberTable}
      title="member list"
      isLoading={isLoading}
      rowCount={data?.staffs.length ?? 0}
    >
      <Table.Head css={S.tableHead} columnList={MEMBER_TABLE_COLUMNS} />
      <Table.Body css={S.tableBody}>
        {data?.staffs.length ? (
          data.staffs.map((staff) => (
            <Table.Row key={staff.staffId}>
              {filteredColumns.map((column) => (
                <Table.Cell key={column.key}>
                  {formatTableData(staff, column.key)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default MemberTable;
