import React from "react";
import dayjs from "dayjs";

import type { MonthYear } from "@/types";

import SingleDate from "./date/SingleDate";

interface SingleDatesProps {
  disabledCondition?: (date: dayjs.Dayjs) => boolean;
  datePicker: {
    monthYear: MonthYear;
    handleChangePrevMonth: () => void;
    handleChangeNextMonth: () => void;
  };
  calendar: {
    currentDate: dayjs.Dayjs[] | [];
    handleClickDate: (date: dayjs.Dayjs[]) => () => void;
  };
}

const SingleDates = ({
  disabledCondition,
  datePicker,
  calendar,
}: SingleDatesProps) => {
  return (
    <>
      {[...Array(datePicker.monthYear.firstDOW)].map((_, i) => (
        <SingleDate
          key={i}
          disabled
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.firstWeekPrevMonthDate.add(i, "d")}
          changeMonth={datePicker.handleChangePrevMonth}
          handleClick={calendar.handleClickDate}
        />
      ))}
      {[...Array(datePicker.monthYear.lastDate)].map((_, i) => {
        const date = datePicker.monthYear.startDate.add(i, "d");

        return (
          <SingleDate
            key={i}
            disabled={disabledCondition && disabledCondition(date)}
            isThisMonth={true}
            currentDate={calendar.currentDate}
            date={date}
            handleClick={calendar.handleClickDate}
          />
        );
      })}
      {[
        ...Array(
          42 - (datePicker.monthYear.firstDOW + datePicker.monthYear.lastDate),
        ),
      ].map((_, i) => (
        <SingleDate
          key={i}
          disabled
          currentDate={calendar.currentDate}
          date={datePicker.monthYear.nextMonthStartDate.add(i, "d")}
          changeMonth={datePicker.handleChangeNextMonth}
          handleClick={calendar.handleClickDate}
        />
      ))}
    </>
  );
};

export default SingleDates;
