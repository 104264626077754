import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Status = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
  `}
`;

export const OrangeLabel = styled(Status)`
  ${({ theme }) => css`
    color: ${theme.color.orange_20};
  `}
`;

export const BlueLabel = styled(Status)`
  ${({ theme }) => css`
    color: ${theme.color.primary_40};
  `}
`;

export const GrayLabel = styled(Status)`
  ${({ theme }) => css`
    color: ${theme.color.gray_70};
  `}
`;

export const RedLabel = styled(Status)`
  ${({ theme }) => css`
    color: ${theme.color.red_20};
  `}
`;
