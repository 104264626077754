import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "@/services/queryClient";
import { COMMON_ERROR_CODE } from "@/constants";
import type {
  APIError,
  CreateMemberQueryModel,
  GetMembersQueryModel,
  UpdateMemberQueryModel,
} from "@/types";

import {
  getMembersAPI,
  getMemberDetailAPI,
  postMemberAPI,
  putMemberAPI,
  deleteMemberAPI,
} from "apis";

const memberKeys = {
  all: ["member"] as const,
  lists: () => [...memberKeys.all, "list"] as const,
  list: (filters: GetMembersQueryModel) =>
    [...memberKeys.lists(), { filters }] as const,
  details: () => [...memberKeys.all, "detail"] as const,
  detail: (id: string) => [...memberKeys.details(), id] as const,
};

interface UseGetMembersProps {
  suspense: boolean;
  req: GetMembersQueryModel;
}

export const useGetMembers = ({ suspense, req }: UseGetMembersProps) => {
  return useQuery({
    queryKey: memberKeys.list(req),
    queryFn: () => getMembersAPI(req),
    suspense,
  });
};

export const useGetMemberDetail = (memberId: string) => {
  return useQuery({
    queryKey: memberKeys.detail(memberId),
    queryFn: () => getMemberDetailAPI(memberId),
  });
};

export const usePostMember = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateMemberQueryModel
  >({
    mutationFn: (req) => postMemberAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(memberKeys.lists());
    },
  });
};

export const useUpdateMember = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateMemberQueryModel
  >({
    mutationFn: (req) => putMemberAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(memberKeys.lists());
    },
  });
};

export const useDeleteMember = () => {
  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (staffId) => deleteMemberAPI(staffId),
    onSuccess: () => {
      queryClient.invalidateQueries(memberKeys.lists());
    },
  });
};
