import React from "react";
import { useTranslation } from "react-i18next";

import { Button, LabelContentTable, Loading } from "@/components";
import { useModal } from "@/hooks";

import { useGetAccountInfo } from "services";
import {
  ChangePasswordModal,
  // DeleteAccountModal
} from "./modal";
import * as S from "./CredentialInfo.styled";

const CredentialInfo = () => {
  const { t } = useTranslation();

  const { data } = useGetAccountInfo();
  const { handleModalOpen, modalRef } = useModal();

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <LabelContentTable variant="whiteBg">
        <S.Title>{t("ID and password")}</S.Title>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Login ID">
            {data.email}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={180} label="Password">
            ************
            <Button
              css={S.changeBtn}
              variant="outlineXSmall"
              label="Change"
              handleClickBtn={handleModalOpen(
                <ChangePasswordModal ref={modalRef} />,
              )}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      {/* NOTE: 금번 개발에서 반영 x 주석처리 */}
      {/* <Button
        variant="ghostRed"
        label="Delete account"
        handleClickBtn={handleModalOpen(<DeleteAccountModal ref={modalRef} />)}
      /> */}
    </>
  );
};

export default CredentialInfo;
