/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";
import { isEmpty, isEqual } from "lodash-es";

import { DetailModal, SearchInput, Table } from "@/components";
import { useModal, useSearchInputWithoutQuery } from "@/hooks";
import { filterTableColumns, formatPhoneNum } from "@/utils";
import type { CompanyInfo, SelectCompanyInfo, TableColumns } from "@/types";

import { useSelectCompany } from "./hooks";
import * as S from "./CompanySearchModal.styled";

interface CompanySearchModalProps {
  className?: string;
  columns: TableColumns<keyof SelectCompanyInfo>;
  selectedCompany: CompanyInfo[];
  tableDatas: SelectCompanyInfo[];
  handleCompanySelect: (companyInfo: CompanyInfo[]) => void;
}

const CompanySearchModal = React.forwardRef(
  (
    {
      className,
      columns,
      selectedCompany,
      tableDatas,
      handleCompanySelect,
    }: CompanySearchModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const filteredColumns = filterTableColumns(columns);

    const { handleModalClose } = useModal();
    const {
      searchValue,
      filteredDatas,
      handleSearch,
      handleChangeInput,
      handleResetInput,
    } = useSearchInputWithoutQuery<SelectCompanyInfo>(tableDatas, "name");
    const { companyInfo, clickTableRow } = useSelectCompany(selectedCompany);

    const handleCloseSelectCompany = () => {
      handleModalClose();
      handleCompanySelect(companyInfo);
    };

    const formatTableData = (
      data: SelectCompanyInfo,
      key: keyof SelectCompanyInfo,
    ) => {
      switch (key) {
        case "phone":
          return formatPhoneNum(data[key]);

        default:
          return data[key];
      }
    };

    return (
      <DetailModal
        css={S.detailModal}
        ref={ref}
        className={className}
        type="submit"
        isDisabled={
          isEmpty(companyInfo) || isEqual(companyInfo, selectedCompany)
        }
        title="Select company"
        posBtnLabel="Select"
        closeBtnLabel="Close"
        callbackFn={handleCloseSelectCompany}
      >
        <SearchInput
          css={S.searchInput}
          placeholder="Search by company name"
          value={searchValue}
          handleChange={handleChangeInput}
          handleSearch={handleSearch}
          handleReset={handleResetInput}
        />
        <Table css={S.table} title="Company" rowCount={filteredDatas.length}>
          <Table.Head columnList={columns} />
          <Table.Body>
            {filteredDatas?.map((tableData, i) => (
              <Table.SelectRow
                key={i}
                isSelected={companyInfo[0]?.id === tableData.id}
                handleRowClick={clickTableRow(tableData)}
              >
                {filteredColumns.map(({ key }) => (
                  <Table.Cell key={key}>
                    {formatTableData(tableData, key)}
                  </Table.Cell>
                ))}
              </Table.SelectRow>
            ))}
          </Table.Body>
        </Table>
      </DetailModal>
    );
  },
);
CompanySearchModal.displayName = "CompanySearchModal";
export default CompanySearchModal;
