import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ErrMsg = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: block;
    color: ${theme.color.red_20};
  `}
`;
