export const boxShadow = {
  dialog: "0px 2px 2px rgba(0, 0, 0, 0.08)",
  tooltip: "1px 1px 4px rgba(167, 167, 167, 0.12)",
  toast: "0px 4px 6px rgba(0, 0, 0, 0.16)",
  shadow_modal_scroll: "0px -4px 10px 0px rgba(0, 0, 0, 0.05)",
  shadow_regular: "0px 0px 16px 0 rgba(25, 31, 40, 0.06)",
  shadow_medium: "0px 0px 16px 0 rgba(25, 31, 40, 0.12)",
  shadow_bold: "0px 0px 16px 0 rgba(25, 31, 40, 0.2)",
  shadow_dropdown: "0px 0px 8px 0px rgba(25, 31, 40, 0.12)",
} as const;

export type BoxShadowType = typeof boxShadow;
