import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  connectLinkAPI,
  getLinkManageDriverListAPI,
  getLinkManageTrucksAPI,
} from "apis";
import type {
  ConnectLinkQueryModel,
  GetDriverSearchServerModel,
  GetLinkManageTruckQueryModel,
} from "types";

const linkManageKeys = {
  all: ["linkManage"] as const,
  lists: () => [...linkManageKeys.all, "list"] as const,
  list: (filters: GetLinkManageTruckQueryModel) =>
    [...linkManageKeys.lists(), { filters }] as const,
};

const driverLinkKey = ["driverLink"];

export const useGetLinkManageTrucks = (req: GetLinkManageTruckQueryModel) => {
  return useQuery({
    queryKey: linkManageKeys.list(req),
    queryFn: () => getLinkManageTrucksAPI(req),
  });
};

export const useGetLinkManageDriver = (
  options?: UseQueryOptions<
    GetDriverSearchServerModel,
    AxiosError,
    GetDriverSearchServerModel,
    string[]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: driverLinkKey,
    queryFn: () => getLinkManageDriverListAPI(),
  });
};

export const useConnectLinkManage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    ConnectLinkQueryModel
  >({
    mutationFn: (req) => connectLinkAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(linkManageKeys.lists());
      queryClient.invalidateQueries(driverLinkKey);
    },
  });
};
