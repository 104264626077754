import { ax } from "apis/axios";

import type {
  GetAccountInfoServerModel,
  UpdateAccountInfoQueryModel,
  ChangeAccountPasswordQueryModel,
} from "types";

export const getAccountInfoAPI = async () => {
  const { data } = await ax.get<GetAccountInfoServerModel>("/setting/account");

  return data;
};

export const putAccountInfoAPI = (req: UpdateAccountInfoQueryModel) =>
  ax.put("/setting/account", req.body);

export const changeAccountPasswordAPI = (
  req: ChangeAccountPasswordQueryModel,
) => ax.put("/setting/password", req.body);
