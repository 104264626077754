import React from "react";
import { useTranslation } from "react-i18next";

import { InternalLink, QueryPagination, SettingLayout } from "@/components";
import { useFilterParams } from "@/hooks";
import { PATH, PlusCircleIcon12 } from "@/assets";

import { SupportTable } from "components";
import { useGetInquiries } from "services";
import { SETTING_MENU } from "assets";
import type { GetInquiriesQueryModel } from "types";
import * as S from "./Support.styled";

const Support = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<GetInquiriesQueryModel["query"]>(
    ["page"],
    { pageSize: 10 },
  );

  const { data } = useGetInquiries({
    suspense: false,
    req: { query: filters },
  });

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <section>
        <S.Header>
          <S.Title>{t("Support")}</S.Title>
          <InternalLink
            variant="ghostNoUnderlinePrimary"
            to={PATH.SETTINGS_CREATE_SUPPORT}
          >
            <PlusCircleIcon12 />
            {t("Add inquiry")}
          </InternalLink>
        </S.Header>
        <div>
          <SupportTable />
          <S.PaginationWrapper>
            <QueryPagination
              css={S.CustomPagination}
              pageInfo={data?.pageInfo}
            />
          </S.PaginationWrapper>
        </div>
      </section>
    </SettingLayout>
  );
};

export default Support;
