import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useGoogleMap } from "@/contexts";
import { Loading, PageBackHeader } from "@/components";
import { useAlignCenter, useLocationMarker } from "@/hooks";
import { PATH } from "@/assets";

import { MonitoringOrderCard } from "components";
import { MONITORING_QUERY_PARAMS } from "assets";
import type { Allocation, GoogleMapCenterLatLng, OrderCard } from "types";
import OrderDetailBody from "./OrderDetailBody";
import * as S from "./OrderDetail.styled";

interface OrderDetailProps {
  isLoading: boolean;
  orderCard: OrderCard;
  allocations: Allocation[];
  googleMapCenterLatLng: GoogleMapCenterLatLng;
}

const OrderDetail = ({
  isLoading,
  orderCard,
  allocations,
  googleMapCenterLatLng: { pickUp, dropOff },
}: OrderDetailProps) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { googleMap } = useGoogleMap();
  const { displayLocationMarker } = useLocationMarker("Allocation");
  useAlignCenter([pickUp, dropOff]);

  useEffect(() => {
    if (!googleMap) return;

    displayLocationMarker([pickUp, dropOff]);
  }, [googleMap, allocations]);

  return (
    <>
      <PageBackHeader
        title="Tracking history"
        path={
          !location.state
            ? `${PATH.ORDER_MANAGEMENT}/${searchParams.get(
                MONITORING_QUERY_PARAMS.ORDER_ID,
              )}`
            : -1
        }
      />
      {isLoading ? (
        <Loading />
      ) : (
        <S.Body>
          <MonitoringOrderCard orderCard={orderCard} />
          <S.Hr />
          <OrderDetailBody allocations={allocations} />
        </S.Body>
      )}
    </>
  );
};

export default OrderDetail;
