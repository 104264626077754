import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const tableOfContent = css`
  > div > section {
    width: 764px;
  }
`;

export const cargoTracking = (theme: Theme) => css`
  ${theme.font.medium_14};
  column-gap: 12px;
  width: fit-content;

  svg {
    margin-right: 0;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 16px;
`;
