import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "@/services/queryClient";
import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  getInquiriesAPI,
  getInquiryDetailAPI,
  createInquiryAPI,
  deleteInquiryAPI,
} from "apis";
import type { CreateInquiryQueryModel, GetInquiriesQueryModel } from "types";

const inquiryKeys = {
  all: ["inquiry"] as const,
  lists: () => [...inquiryKeys.all, "list"] as const,
  list: (filters: GetInquiriesQueryModel) =>
    [...inquiryKeys.lists(), { filters }] as const,
  details: () => [...inquiryKeys.all, "detail"] as const,
  detail: (id: string) => [...inquiryKeys.details(), id] as const,
};

interface UseGetInquiriesProps {
  suspense: boolean;
  req: GetInquiriesQueryModel;
}

export const useGetInquiries = ({ suspense, req }: UseGetInquiriesProps) => {
  return useQuery({
    queryKey: inquiryKeys.list(req),
    queryFn: () => getInquiriesAPI(req),
    suspense,
  });
};

export const useGetInquiryDetail = (inquiryId: string) => {
  return useQuery({
    queryKey: inquiryKeys.detail(inquiryId),
    queryFn: () => getInquiryDetailAPI(inquiryId),
  });
};

export const useCreateInquiry = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateInquiryQueryModel
  >({
    mutationFn: (req) => createInquiryAPI(req),
  });
};

export const useDeleteInquiry = () => {
  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (inquiryId) => deleteInquiryAPI(inquiryId),
    onSuccess: () => {
      queryClient.invalidateQueries(inquiryKeys.lists());
    },
  });
};
