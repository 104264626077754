import React from "react";

import { DataSection } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";

import { ORDER_CANCELED_TABLE_COLUMNS } from "assets";
import type { GetOrderManagementCanceledServerModel } from "types";
import * as S from "./CanceledSection.styled";

interface CanceledSectionProps {
  data: GetOrderManagementCanceledServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}

const CanceledSection = ({
  data,
  dataUpdatedAt,
  refetch,
}: CanceledSectionProps) => {
  const filteredColumns = filterTableColumns(ORDER_CANCELED_TABLE_COLUMNS);

  const formatTableData = (
    data: GetOrderManagementCanceledServerModel["order"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "orderNumber":
        return formatOrderNum(data[key]);

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddress":
        return addrFormatter(data.puAddress, data.puAddressDetail);
      case "doAddress":
        return addrFormatter(data.doAddress, data.doAddressDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Canceled"
        isStickyThead
        rowCount={data.order?.length}
      >
        <DataSection.Table.Head columnList={ORDER_CANCELED_TABLE_COLUMNS} />
        <DataSection.Table.Body css={S.body}>
          {data.order?.length ? (
            data.order.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.orderId}
                path={item.orderId}
              >
                {filteredColumns?.map(({ key }) => (
                  <DataSection.Table.Cell key={key}>
                    {formatTableData(item, key)}
                  </DataSection.Table.Cell>
                ))}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData />
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default CanceledSection;
