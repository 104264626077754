import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { PROPERTY_GARAGE_TABLE_CELL_SIZES } from "assets";

export const table = (theme: Theme) => css`
  tr {
    grid-template-columns: ${PROPERTY_GARAGE_TABLE_CELL_SIZES};
    background-color: ${theme.color.white};

    & > td:last-of-type {
      display: flex;
      justify-content: center;
      padding: 0;
    }
    & > td:not(:last-of-type) {
      cursor: pointer;
    }
  }
`;

export const tBody = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};

  & > tr {
    &:nth-of-type(20) {
      border-bottom: 0;
    }

    &[aria-selected="true"] td,
    &:hover td {
      background-color: ${theme.color.gray_10};
    }
  }
`;

export const cell = (isSelected: boolean) => (theme: Theme) =>
  css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${isSelected && `${theme.color.gray_10}`};
  `;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > button,
    a {
      padding: 9px 10px;

      & > svg {
        width: fit-content;
        height: fit-content;
        margin: 0;

        path {
          fill: ${theme.color.gray_50};
        }
      }
    }
  `}
`;

export const loading = css`
  min-height: 800px;
`;

export const NoDataRow = styled.tr`
  display: flex;
  width: 500px;
  height: 500px;
  background-color: red;

  && {
    border-bottom: 0;

    &:hover td {
      background-color: inherit;
    }
  }

  & > td {
    width: 100%;
    height: 100%;
  }
`;

export const noResult = (theme: Theme) => css`
  min-height: ${theme.size.TABLE_BODY_HEIGHT};
`;
