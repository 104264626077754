import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { mixin_scrollbar } from "@/styles";

import { QUOTATION_DETAIL_TABLE_CELL_SIZES } from "assets";

export const table = css`
  margin-top: 20px;
  overflow: hidden;

  tr {
    grid-template-columns: ${QUOTATION_DETAIL_TABLE_CELL_SIZES};
  }
`;

export const button = css`
  width: 100%;
  padding: 4px 0;
`;

export const tbody = (isEmpty: boolean) => (theme: Theme) =>
  css`
    ${mixin_scrollbar};
    display: ${isEmpty && "block"};
    height: 336px;
    overflow-y: scroll;

    & > tr:nth-of-type(20) {
      border-bottom: 1px solid ${theme.color.gray_30};
    }
  `;

export const row = (isCanceled: boolean) => (theme: Theme) =>
  css`
    position: relative;

    & > td {
      &:last-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > span,
      & > div > span {
        color: ${isCanceled && theme.color.gray_60};
      }
    }
  `;

export const TruckingCompanyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    height: 100%;

    & > button {
      ${theme.font.semibold_12};
      height: 24px;
      padding: 4px 8px;
    }
  `}
`;

export const tooltipBtn = (theme: Theme) => css`
  position: unset;

  // NOTE: 테이블 내부에 있어 추가적인 위치 관련 css 추가
  & > dl {
    top: 33px;
    left: initial;
  }

  svg path {
    fill: ${theme.color.gray_50};
  }
`;
