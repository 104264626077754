import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    min-width: 1296px;
    width: 100%;
    max-width: 1378px;
    height: max-content;
    margin: 0 auto;
    padding: 32px 40px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin-bottom: 12px;

    & > span {
      ${theme.font.regular_14};
      color: ${theme.color.gray_60};
    }
  `}
`;

export const pageBackHeader = (theme: Theme) => css`
  border: 0;
  padding: 0;
  background-color: inherit;

  & > div > span {
    ${theme.font.bold_18};
  }
`;

export const PaginationWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
    background-color: ${theme.color.white};
  `}
`;

export const queryPagination = css`
  border-top: 0;
`;
