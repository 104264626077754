import { useState } from "react";

import type { PropertyDetailEditCardType } from "types";

const useDetailFormEdit = () => {
  const [editState, setEditState] = useState<PropertyDetailEditCardType | null>(
    null,
  );
  const [nextEditState, setNextEditState] =
    useState<PropertyDetailEditCardType | null>(null);

  const handleEditState =
    (label: PropertyDetailEditCardType | null, isEdit = false) =>
    () => {
      if (!editState || isEdit) {
        setEditState(label);
      } else {
        setNextEditState(label);
      }
    };

  const handleResetNextEditState = () => {
    setNextEditState(null);
  };

  const handleCancel = () => {
    setEditState(null);
  };

  return {
    editState,
    nextEditState,
    handleEditState,
    handleResetNextEditState,
    handleCancel,
  };
};

export default useDetailFormEdit;
