import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

import { mixin_list_layout_detail_body } from "@/styles";

export const noResult = css`
  height: calc(100% - 49px);
`;

export const TrackingOrderDetailBody = styled.div`
  ${mixin_list_layout_detail_body};
  flex: 1;
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 12px 20px;
  `}
`;

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 20px;
`;

export const orderCardTitle = css`
  margin-bottom: 0;
`;

export const TitleWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 28px 1fr 1fr;
  column-gap: 8px;
  margin-bottom: 8px;

  & > figure {
    width: 28px;
    height: 28px;

    & > svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const Gps = styled.div<{ hasGps: boolean }>`
  ${({ theme, hasGps }) => css`
    ${theme.font.semibold_12};
    display: flex;
    align-items: center;
    column-gap: 2px;
    margin-left: auto;

    & > svg {
      width: 16px;
      height: 16px;
      margin: 0;

      & > path {
        fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
      }
    }
  `}
`;

export const driverInfo = (theme: Theme) => css`
  margin-bottom: 12px;
  border-bottom: 1px solid ${theme.color.gray_30};
  padding-bottom: 12px;
`;

export const orderCard = (isSelected: boolean) => (theme: Theme) =>
  css`
    border-color: ${isSelected && theme.color.primary_40};
    background-color: ${isSelected && theme.color.primary_10};
    cursor: pointer;
  `;

export const svg = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;
