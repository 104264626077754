import { calcTableWidth } from "@/utils";
import { CELL, PATH, RADIO, TAB } from "@/assets";
import type { RadioType } from "@/types";

export const PROPERTY_DRIVER_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.DRIVERNAME_PROFILE],
  [CELL.PHONENUMBER_PHONE],
  [CELL.LINKING_LINKEDREGISTNUMBER],
  [CELL.LASTSHIPPINGON],
  [CELL.COMPLETEDORDERS_PROGRESSED],
  [CELL.CREATEDON_CREATED],
] as const;

export const PROPERTY_TRUCK_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.PLATENUMBER],
  [CELL.GPS],
  [CELL.TRUCKTYPE],
  [CELL.TRUCKOPTION],
  [CELL.YEAR],
  [CELL.COMPLETEDORDERS_PROGRESSED],
  [CELL.PARKEDGARAGE],
  [CELL.LINKING_LINKEDDRIVER],
  [CELL.CREATEDON_CREATED],
] as const;

export const LINK_MANAGE_TABLE_COLUMNS = [
  [CELL.PLATENUMBER_REGISTNUMBER],
  [CELL.TRUCKTYPE],
  [CELL.TRUCKOPTION],
  [CELL.LINKING_DRIVERS],
  [CELL.EMPTY_UTILS],
] as const;

export const GARAGE_TABLE_COLUMNS = [
  [CELL.GARAGENAME_NAME],
  [CELL.GARAGEADDRESS_ADDRESS],
  [CELL.GARAGEUSAGE_USAGE],
  [CELL.CAPACITY],
  [CELL.EMPTY_UTILS],
] as const;

export const GARAGE_USAGE_TABLE_COLUMNS = [
  [CELL.PLATENUMBER_REGISTNUMBER],
  [CELL.LINKING_LINKEDDRIVER],
] as const;

export const DRIVER_ORDER_ALLOCATION_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.PICKUP_PUADDR],
  [CELL.DROPOFF_DOADDR],
  [CELL.PICKUPETA_PUETA],
  [CELL.DROPOFFETA_DOETA],
  [CELL.DRIVINGTRUCK_REGISTNUMBER],
] as const;

export const TRUCK_ORDER_ALLOCATION_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.COMPANY_CLIENT],
  [CELL.PICKUP_PUADDR],
  [CELL.DROPOFF_DOADDR],
  [CELL.PICKUPETA_PUETA],
  [CELL.DROPOFFETA_DOETA],
  [CELL.DRIVINGDRVIER_DRIVERNAME],
] as const;

export const PROPERTY_DRIVER_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  ["300px", "500px"], // NOTE: table width 문제로 name  width 늘어날 수 있도록 설정
  ["180px", "250px"],
  ["180px", "250px"],
  "170px",
  ["200px", "300px"],
  "128px",
]);

export const PROPERTY_TRUCK_TABLE_CELL_SIZES = calcTableWidth([
  "96px",
  "110px",
  "60px",
  "170px",
  "170px",
  "72px",
  "140px",
  "140px",
  ["212px", "350px"], // NOTE: table width 문제로 name / memo width 늘어날 수 있도록 설정
  ["115px", "400px"],
]);

export const PROPERTY_GARAGE_TABLE_CELL_SIZES = calcTableWidth([
  "148px",
  ["416px", "816px"],
  "118px",
  "118px",
  "72px",
]);

export const PROPERTY_ALLOCATION_TABLE_CELL_SIZES = calcTableWidth([
  "120px",
  "170px",
  "140px",
  ["190px", "300px"],
  ["190px", "300px"],
  "170px",
  "170px",
  "137px",
]);

export const GARAGE_LINK_TABLE_CELL_SIZES = calcTableWidth([
  "180px",
  ["230px", "600px"],
  "160px",
  ["286px", "800px"],
  "40px",
]);

export const PROPERTY_TABS = [
  TAB.DRIVERLIST_DRIVER,
  TAB.TRUCKLIST_TRUCK,
  TAB.GARAGELIST_GARAGE,
  TAB.MANAGELINK_LINK,
] as const;

export const TAB_CREATE_LINKS = {
  driver: { path: PATH.PROPERTY_DRIVER_CREATE, label: "New driver" },
  truck: { path: PATH.PROPERTY_TRUCK_CREATE, label: "New truck" },
  garage: { path: PATH.PROPERTY_GARAGE_CREATE, label: "New garage" },
  link: { path: "", label: "" },
} as const;

export const PROPERTY_DRIVER_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.AVAILABLE,
  RADIO.UNAVAILABLE,
];
