import { TAB } from "@/assets";

export const ALLOCATION_ONGOING_CATEGORIES = [
  { key: "ALL", label: "All" },
  { key: "REQUESTED", label: "Requested" },
  { key: "ALLOCATED", label: "Allocated" },
  { key: "REJECTED", label: "Rejected" },
] as const;

// NOTE: Allocation 페이지에서 사용하는 query parameter: tab, page, search, category
export const ALLOCATION_QUERY_PARAMS = {
  SEARCH: "search",
  CATEGORY: "category",
  TAB: "tab",
  PAGE: "page",
  DRIVER_ID: "driverId",
  ALLOCATION_ID: "allocationId",
} as const;

export const ALLOCATION_TABS = [TAB.ALLOCATE, TAB.ONGOING] as const;
