import React from "react";

import { LabelContent } from "@/components";
import type { LatLngWithNull } from "@/types";

import type { TruckStatus } from "types";
import * as S from "./TruckStatusInfo.styled";

interface TruckStatusInfoProps {
  truckStatus: TruckStatus;
  truckInfo: {
    addr: string;
    speed: number;
    temperature: number;
  } & LatLngWithNull;
}

const TruckStatusInfo = ({ truckStatus, truckInfo }: TruckStatusInfoProps) => {
  return (
    <>
      <S.TruckStatusInfoWrapper hasBorderBottom={truckStatus === "WAITING"}>
        <LabelContent label="Location" direction="horizontal" columnWidth={128}>
          <S.TruckStatusInfoContent>{`${truckInfo.lat ?? "-"}, ${
            truckInfo.lng ?? "-"
          }`}</S.TruckStatusInfoContent>
        </LabelContent>
        <LabelContent
          css={S.addrLabelContent}
          label="Address"
          direction="horizontal"
          columnWidth={128}
        >
          <S.TruckStatusInfoContent>
            {truckInfo.addr ?? "-"}
          </S.TruckStatusInfoContent>
        </LabelContent>
        <LabelContent label="Speed" direction="horizontal" columnWidth={128}>
          <S.TruckStatusInfoContent>
            {truckInfo.speed ?? 0} km/h
          </S.TruckStatusInfoContent>
        </LabelContent>
        <LabelContent
          label="Temperature"
          direction="horizontal"
          columnWidth={128}
        >
          <S.TruckStatusInfoContent>
            {truckInfo.temperature ?? 0}°C/NA
          </S.TruckStatusInfoContent>
        </LabelContent>
      </S.TruckStatusInfoWrapper>
    </>
  );
};

export default TruckStatusInfo;
