import React from "react";
import { useRecoilValue } from "recoil";

import { DataSection, Loading, Profile, StatusLabel } from "@/components";
import { clientLanguage } from "@/stores";
import { filterTableColumns, formatICTDateTime } from "@/utils";
import { SuccessIcon } from "@/assets";

import { PROPERTY_TRUCK_TABLE_COLUMNS } from "assets";
import type { GetTruckClientModel, GetTruckServerModel } from "types";
import * as S from "./TruckSection.styled";

interface DriverSectionProps {
  data?: GetTruckServerModel;
  dataUpdatedAt: number;
  refetch: () => void;
}
const TruckSection = ({ data, dataUpdatedAt, refetch }: DriverSectionProps) => {
  const filteredColumns = filterTableColumns(PROPERTY_TRUCK_TABLE_COLUMNS);
  const languages = useRecoilValue(clientLanguage);

  const formatTableData = (
    data: GetTruckClientModel["trucks"][number],
    key: keyof GetTruckClientModel["trucks"][number],
  ) => {
    switch (key) {
      case "status":
        return <StatusLabel status={data[key]} />;

      case "linkedDriver":
        return (
          <S.Truck hasCnt={!!data[key].linked}>
            {data[key].linked ? (
              <>
                <Profile src={data[key]?.profileS3Key ?? ""} />
                <S.Name>{data[key]?.driverName || "-"}</S.Name>
              </>
            ) : (
              <span>-</span>
            )}
            <S.Count>({data[key].linked ?? 0})</S.Count>
          </S.Truck>
        );
      case "created":
        return formatICTDateTime(data[key], "DD/MM/YYYY");

      case "truckType":
        return languages === "en" ? data["truckTypeEn"] : data["truckTypeVi"];

      case "truckOption":
        return languages === "en"
          ? data["truckOptionEn"]
          : data["truckOptionVi"];

      case "gpsAvailable":
        return (
          <S.IconWrapper>
            <SuccessIcon css={S.gpsIcon(!!data[key])} />
          </S.IconWrapper>
        );

      default:
        return data[key];
    }
  };

  const convertClientData = {
    ...data,
    trucks: data?.trucks.map((truck, i) => ({
      ...truck,
      gps: i % 2 === 0 ? true : false,
      linkedDriver: {
        linked: truck.linked,
        driverName: truck.driverName,
        profileS3Key: truck.profileS3Key,
      },
    })),
  };

  if (!convertClientData) return <Loading />;

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data?.pageInfo.totalData ?? 0}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="Trucks"
        isStickyThead
        rowCount={convertClientData?.trucks?.length ?? 0}
      >
        <DataSection.Table.Head columnList={PROPERTY_TRUCK_TABLE_COLUMNS} />
        <DataSection.Table.Body css={S.body}>
          {convertClientData?.trucks?.length ? (
            convertClientData?.trucks.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.truckId}
                path={`truck/${item.truckId}`}
              >
                {filteredColumns.map(({ key }) => {
                  return (
                    <DataSection.Table.Cell key={key}>
                      {formatTableData(
                        item,
                        key as keyof GetTruckClientModel["trucks"][number],
                      )}
                    </DataSection.Table.Cell>
                  );
                })}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData />
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default TruckSection;
