import React from "react";
import { useNavigate } from "react-router-dom";

import { PATH, WarningIcon64 } from "@/assets";

import * as S from "./NotFound.styled";

const NotFound = () => {
  const navigate = useNavigate();

  const handleClickGoHome = (): void => navigate(PATH.ORDER_MANAGEMENT);

  return (
    <S.Root>
      <WarningIcon64 />
      <S.Title>Page not found</S.Title>
      <S.ContentWrapper>
        <span>The requested page could not be found.</span>
        <span>
          Please check that the address on the page you entered is correct.
        </span>
      </S.ContentWrapper>
      <S.GoBackBtn type="button" onClick={handleClickGoHome}>
        Go back
      </S.GoBackBtn>
    </S.Root>
  );
};

export default NotFound;
