import styled from "@emotion/styled";
import { Theme, css, keyframes } from "@emotion/react";

interface SidePanelProps {
  isClosing: boolean;
}

const fadeIn = keyframes`
  from { opacity: 1; transform: translateX(-100%);}
  to { opacity: 1; transform: translateX(0)}
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateX(0)}
  to {  opacity: 1; transform: translateX(-100%)}
 `;

const mixinBtn = (theme: Theme) => css`
  position: absolute;
  top: 50px;
  right: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 0 5px 5px 0;
  background-color: ${theme.color.white};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  z-index: -1;
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    ${mixinBtn(theme)};
    & > svg {
      width: 12px;
      height: 12px;
      fill: ${theme.color.gray_80};
    }
  `}
`;

export const SidePanel = styled.div<SidePanelProps>`
  ${({ isClosing, theme }) => css`
    position: fixed;
    top: ${theme.size.HEADER_HEIGHT};
    left: ${theme.size.NAV_WIDTH};
    display: flex;
    flex-direction: column;
    width: ${theme.size.FOLDABLE_SHORT_LAYOUT_WIDTH};
    height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT})`};
    background-color: ${theme.color.white};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    z-index: ${theme.zIndex.SIDE_PANEL};
    animation: 0.5s forwards ${isClosing ? fadeOut : fadeIn};
  `}
`;

export const closeBtn = (theme: Theme) => css`
  position: absolute;
  top: 50px;
  right: -32px;
  width: 32px;
  height: 32px;
  border: 0;
  padding: 0;
  border-radius: 0 5px 5px 0;
  background-color: ${theme.color.white};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);

  & > svg {
    width: 12px;
    height: 12px;
    fill: ${theme.color.gray_80};
    margin-right: 0;
  }
`;
