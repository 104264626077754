import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;
