/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useCallback } from "react";
import dayjs from "dayjs";

import * as S from "./CalendarDate.styled";

interface CalendarDateProps {
  date: dayjs.Dayjs;
  isPrevEndDateDisabled: boolean;
  isDefaultDisabled?: boolean;
  isCurrentStartDate: boolean;
  isCurrentEndDate: boolean;
  isOriginDate: boolean;
  isDoneCurrentDate: boolean;
  isSameStartEndDate: boolean;
  isStartDate: boolean;
  isPeriodOfSelectDate: boolean;
  startEndOfTheWeek: "start" | "end" | "middle";
  handleClickDate: () => void;
}

const CalendarDate = ({
  date,
  isPrevEndDateDisabled,
  isDefaultDisabled,
  isOriginDate,
  isCurrentStartDate,
  isCurrentEndDate,
  isDoneCurrentDate,
  isSameStartEndDate,
  isStartDate,
  isPeriodOfSelectDate,
  startEndOfTheWeek,
  handleClickDate,
}: CalendarDateProps) => {
  const isLastDate = date.isSame(date.endOf("month"), "day");
  const isToday = useCallback(
    (dateString: dayjs.Dayjs) => dateString.isSame(dayjs(), "day"),
    [],
  );
  const isLastSatDay =
    isLastDate && startEndOfTheWeek === "end" && isPeriodOfSelectDate;
  const isFirstSunDay =
    isStartDate && startEndOfTheWeek === "start" && isPeriodOfSelectDate;

  return (
    <S.CalendarDate
      isPrevEndDateDisabled={isPrevEndDateDisabled}
      isCurrentStartDate={isCurrentStartDate}
      isCurrentEndDate={isCurrentEndDate}
      isOriginDate={isOriginDate}
      isDoneCurrentDate={isDoneCurrentDate}
      isSameStartEndDate={isSameStartEndDate}
      isLastDate={isLastDate}
      isFirstDate={isStartDate}
      isLastSatDay={isLastSatDay}
      isFirstSunDay={isFirstSunDay}
      isPeriodOfSelectDate={isPeriodOfSelectDate}
      startEndOfTheWeek={startEndOfTheWeek}
    >
      <S.DatesBtn
        type="button"
        isCurrentDate={isCurrentStartDate || isCurrentEndDate}
        isPeriodOfSelectDate={isPeriodOfSelectDate}
        isPrevEndDateDisabled={isPrevEndDateDisabled}
        disabled={isDefaultDisabled || isPrevEndDateDisabled}
        onClick={handleClickDate}
      >
        {isToday(date) ? (
          <S.TodayWrapper
            isToday={isToday(date)}
            isSelected={
              isToday(date) && (isCurrentEndDate || isCurrentStartDate)
            }
          >
            {date.format("D")}
          </S.TodayWrapper>
        ) : (
          <>{date.format("D")}</>
        )}
      </S.DatesBtn>
    </S.CalendarDate>
  );
};

export default CalendarDate;
