import {
  OrderCreationIcon,
  QuotationIcon,
  AllocationIcon,
  SettlementIcon,
  ForwardingIcon,
  PropertyIcon,
  SettingIcon,
  MonitorIcon,
  PATH,
} from "@/assets";
import type { Languages } from "@/types";

import type { CompanyType, NavlinkType } from "types";

export const NAV_ITEM = {
  ORDER_CREATION: {
    key: "Order creation",
    path: PATH.ORDER_CREATION,
    Icon: OrderCreationIcon,
  } as const,
  QUOTATION: {
    key: "Quotation",
    path: PATH.QUOTATION,
    Icon: QuotationIcon,
  } as const,
  ALLOCATION: {
    key: "Allocation",
    path: PATH.ALLOCATION,
    Icon: AllocationIcon,
  } as const,
  ORDERMANAGEMENT: {
    key: "Order management",
    path: PATH.ORDER_MANAGEMENT,
    Icon: ForwardingIcon,
  } as const,
  PROPERTY: {
    key: "Property",
    path: PATH.PROPERTY,
    Icon: PropertyIcon,
  } as const,
  SETTLEMENT: {
    key: "Settlement",
    path: PATH.SETTLEMENT,
    Icon: SettlementIcon,
  } as const,
  SETTINGS: {
    key: "Settings",
    path: PATH.SETTINGS,
    Icon: SettingIcon,
  } as const,
  MONITORING: {
    key: "Monitoring" as Languages, // TODO: 번역 추가되면 as Languages 삭제할 것
    path: PATH.MONITORING,
    Icon: MonitorIcon,
  } as const,
};

export const NAV = (
  clientType: CompanyType,
  hasGpsType: boolean,
): readonly NavlinkType[] => {
  switch (clientType) {
    case "carrier":
    case "forwardercarrier":
      return [
        NAV_ITEM.ORDER_CREATION,
        NAV_ITEM.QUOTATION,
        ...(hasGpsType ? [NAV_ITEM.MONITORING] : []),
        NAV_ITEM.ALLOCATION,
        NAV_ITEM.ORDERMANAGEMENT,
        NAV_ITEM.PROPERTY,
        NAV_ITEM.SETTLEMENT,
        NAV_ITEM.SETTINGS,
      ];

    case "forwarder":
      return [
        NAV_ITEM.ORDER_CREATION,
        NAV_ITEM.QUOTATION,
        NAV_ITEM.ORDERMANAGEMENT,
        NAV_ITEM.SETTLEMENT,
        NAV_ITEM.SETTINGS,
      ] as const;

    case "shipper":
      return [
        NAV_ITEM.ORDER_CREATION,
        NAV_ITEM.QUOTATION,
        NAV_ITEM.MONITORING,
        NAV_ITEM.ORDERMANAGEMENT,
        NAV_ITEM.SETTLEMENT,
        NAV_ITEM.SETTINGS,
      ];
  }
};
