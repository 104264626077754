import React from "react";

import { Button, InternalLink, Table } from "@/components";
import { useModal } from "@/hooks";
import { comma } from "@/utils";
import { PATH, TrashSmallIcon, PencilIcon } from "@/assets";

import { GARAGE_TABLE_COLUMNS } from "assets";
import type { GetGarageServerItem, TableKeyUnion } from "types";
import DelGarageModal from "./delGarageModal/DelGarageModal";
import * as S from "./GarageTable.styled";

interface GarageTableProps {
  garages?: GetGarageServerItem[];
  isLoading: boolean;
  selectedGarageId: string;
  handleSelectGarage: (garageId: string) => () => void;
}

const GarageTable = ({
  garages,
  selectedGarageId,
  isLoading,
  handleSelectGarage,
}: GarageTableProps) => {
  const { handleModalOpen, modalRef } = useModal();

  const columnValue = (
    key: TableKeyUnion<(typeof GARAGE_TABLE_COLUMNS)[number]>,
    data: GetGarageServerItem,
  ) => {
    switch (key) {
      case "utils":
        return (
          <S.BtnWrapper>
            <InternalLink
              variant="icon"
              to={`${PATH.PROPERTY_GARAGE_EDIT}?id=${data.garageId}`}
            >
              <PencilIcon />
            </InternalLink>
            <Button
              variant="ghostGray"
              icon={<TrashSmallIcon />}
              handleClickBtn={handleModalOpen(
                <DelGarageModal ref={modalRef} garageId={data.garageId} />,
              )}
            />
          </S.BtnWrapper>
        );
      case "usage":
      case "capacity":
        return comma(data[key]);
      default:
        return data[key];
    }
  };

  return (
    <Table
      css={S.table}
      title="garage list"
      isLoading={isLoading}
      rowCount={garages?.length ?? 0}
    >
      <Table.Head columnList={GARAGE_TABLE_COLUMNS} />
      <Table.Body css={S.tBody}>
        {garages?.length ? (
          garages.map((rowData) => (
            <Table.ClickRow
              key={rowData.garageId}
              id={rowData.garageId}
              aria-selected={selectedGarageId === rowData.garageId}
            >
              {GARAGE_TABLE_COLUMNS.flat().map((column, i) => (
                <Table.Cell
                  css={S.cell(selectedGarageId === rowData.garageId)}
                  key={column.key}
                  onClick={
                    i !== 4 ? handleSelectGarage(rowData.garageId) : null
                  }
                >
                  <span>{columnValue(column.key, rowData)}</span>
                </Table.Cell>
              ))}
            </Table.ClickRow>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default GarageTable;
