import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { CompanySearchModal, Filter } from "@/components";
import { useModal } from "@/hooks";
import { getAllQuery } from "@/utils";
import { COMPANY_COLUMNS } from "@/assets";
import type { CompanyInfo, Languages, LinkPlayerServerModel } from "@/types";

import {
  SETTLEMENT_ONGOING_FILTER_STATUS_RADIOS,
  SETTLEMENT_REQUESTED_FILTER_STATUS_RADIOS,
} from "assets";

interface SettlementFilterProps {
  companyCollection: LinkPlayerServerModel;
}

const SettlementFilter = ({ companyCollection }: SettlementFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isShipperTab = searchParams.get("tab") === "shipper";

  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: isShipperTab
        ? SETTLEMENT_ONGOING_FILTER_STATUS_RADIOS
        : SETTLEMENT_REQUESTED_FILTER_STATUS_RADIOS,
    },
    { queryKey: "orderNumber", label: "Order number" },
    { queryKey: "date", label: "Date" },
    {
      queryKey: "companyId",
      label: "Company",
      options: companyCollection?.map((company) => ({
        key: company.id,
        label: company.name as Languages,
      })),
    },
  ];

  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo[]>([]);

  const { modalRef } = useModal();

  const handleCompanySelect = (companyInfo: CompanyInfo[]) => {
    setSelectedCompany(companyInfo);

    setSearchParams({
      ...getAllQuery(searchParams),
      ...(companyInfo[0] && { companyId: companyInfo[0].id }),
      page: "1",
    });
  };

  useEffect(() => {
    if (!companyCollection) return;

    const selectedCompany = companyCollection.find(
      (company) => company.id === searchParams.get("companyId") ?? "",
    );

    setSelectedCompany(
      selectedCompany
        ? [{ id: selectedCompany.id, name: selectedCompany.name }]
        : [],
    );
  }, [companyCollection, searchParams.get("companyId")]);

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Status" isRequired>
          <Filter.Radio
            queryKey="status"
            radios={
              isShipperTab
                ? SETTLEMENT_ONGOING_FILTER_STATUS_RADIOS
                : SETTLEMENT_REQUESTED_FILTER_STATUS_RADIOS
            }
          />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Order number">
          <Filter.Input
            maxLength={17}
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
      </Filter.Row>
      <Filter.Row partition={2}>
        <Filter.Condtion conditionName="Date" isRequired>
          <Filter.Calendar queryKey="date" />
        </Filter.Condtion>
        <Filter.Condtion conditionName="Company">
          <Filter.Modal
            selectModalData={selectedCompany[0]?.name}
            modal={
              <CompanySearchModal
                ref={modalRef}
                columns={COMPANY_COLUMNS}
                selectedCompany={selectedCompany}
                tableDatas={companyCollection}
                handleCompanySelect={handleCompanySelect}
              />
            }
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default SettlementFilter;
