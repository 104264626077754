import React from "react";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";

import { button, theme } from "@/styles";
import type { Languages } from "@/types";

import * as S from "./Button.styled";

interface ButtonProps {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  ariaLabel?: string;
  id?: string;
  type?: "button" | "submit";
  variant: keyof typeof button;
  label?: Languages; // TODO: 1. Button 관련된 모달 유니온 타입을 만들어서 적용해야 함, 2. 번역 적용 후 타입 변경
  icon?: React.ReactNode;
  handleClickBtn?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  className,
  isLoading = false,
  isDisabled,
  ariaLabel,
  id,
  type = "button",
  variant,
  label,
  icon,
  handleClickBtn,
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <S.Button
      className={className}
      id={id}
      disabled={isDisabled}
      type={type}
      aria-label={ariaLabel}
      onClick={handleClickBtn}
      variant={variant}
    >
      {isLoading ? (
        <S.LoadingSpinnerContent>
          <ClipLoader
            size={25}
            color={
              variant === "secondaryLarge"
                ? theme.color.primary_40
                : theme.color.white
            }
          />
          <div>
            {icon && icon}
            {label && t(label)}
          </div>
        </S.LoadingSpinnerContent>
      ) : (
        <>
          {icon && icon}
          {label && t(label)}
        </>
      )}
    </S.Button>
  );
};

export default Button;
