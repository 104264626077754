import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.nav`
  display: flex;
  column-gap: 20px;
`;

export const LinkBtn = styled(Link)`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.gray_40};
    &[aria-current="true"] {
      color: ${theme.color.black};
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
