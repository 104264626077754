import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Calendar = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 292px;
    border: 1px solid ${theme.color.gray_20};
    border-radius: 5px;
    padding: 20px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_medium};
    z-index: ${theme.zIndex.CALENDAR};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const MonthYear = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 6px;

    & > span {
      ${theme.font.medium_15};
      color: ${theme.color.black};
    }

    &[aria-expanded="true"] svg {
      transform: rotate(180deg);

    :hover > div:last-of-type {
      border-radius: 50%;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const MonthYearContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      ${theme.font.medium_15};
    }
  `}
`;

export const MonthWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  margin-bottom: 116px;

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }
`;

export const MonthBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 100%;
    border-radius: 100px;
    color: ${theme.color.black};

    &:hover {
      background-color: ${theme.color.primary_10};
    }

    &[aria-selected="false"] {
      &[aria-current="true"] {
        border: 1px solid ${theme.color.black};

        &:hover {
          background-color: ${theme.color.gray_10};
        }
      }
    }

    &[aria-selected="true"] {
      color: ${theme.color.white};
      background-color: ${theme.color.primary_40};
    }
  `}
`;

export const OpenMonthWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg > path {
      fill: ${theme.color.gray_60};
    }
  `}
`;

export const WeekRow = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 36px);
    margin-bottom: 12px;

    > li {
      ${theme.font.regular_12};
      color: ${theme.color.gray_70};
      text-align: center;
    }
  `}
`;

export const DateRow = styled.ul`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 36px);
    row-gap: 4px;
    margin-bottom: 16px;
    color: ${theme.color.gray_50};
  `}
`;

export const MoveBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const MoveBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg path {
      fill: ${theme.color.gray_60};
    }

    :hover {
      border-radius: 50%;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const arrowIcon = (rotateDeg: number) => css`
  width: 16px;
  height: 16px;
  transform: rotate(${rotateDeg}deg);
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const resetBtn = css`
  width: 77px;
`;

export const applyBtn = css`
  flex: 1;
`;

export const DateTimeContainer = styled.div`
  margin-bottom: 20px;
`;

interface DateTimeWrapperProps {
  hasErr: boolean;
}

export const DateTimeWrapper = styled.div<DateTimeWrapperProps>`
  ${({ hasErr, theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid ${hasErr ? theme.color.red_20 : theme.color.gray_30};
    border-radius: 2px;
    padding: 12px;

    svg {
      width: 16px;
      height: 16px;
    }

    time {
      ${theme.font.regular_14};
    }
  `}
`;

export const DateWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 162px;
    padding-right: 12px;

    &::after {
      content: "";
      position: absolute;
      right: 41px;
      width: 1.14px;
      height: 20px;
      background-color: ${theme.color.gray_35};
    }
  `}
`;

export const TimeWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: -20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 115px;
    margin: 0 12px;
    svg > g > path {
      fill: ${theme.color.gray_40};
    }
  `}
`;

export const TimeInput = styled.input`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    width: 76px;
    height: 20px;
    border: 0;

    &:focus {
      border: 0;
    }
  `}
`;

export const timeErrorMsg = css`
  margin-top: 4px;
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_50};
  `}
`;

export const errMsg = css`
  margin-top: 5px;
`;

export const calendarIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_40};
  }
`;
