/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useRef, useState, useId } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components";
import { useKeyTrap, useOnClickOutside } from "@/hooks";
import { CloseIcon12 } from "@/assets";
import type { Languages, TooltipPosition } from "@/types";

import * as S from "./Tooltip.styled";

interface TooltipProps {
  className?: string;
  badgeContent?: Languages;
  title?: Languages;
  position: TooltipPosition;
  tooltipBtnContent: string | React.FC<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
}

const Tooltip = ({
  className,
  badgeContent,
  title,
  position,
  children,
  tooltipBtnContent,
}: TooltipProps) => {
  const { t } = useTranslation();

  const openerRef = useRef(null);
  const tooltipRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const uuid = useId();

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const handleClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    setIsOpen(false);
  };

  useOnClickOutside(tooltipRef, handleClose, openerRef.current);
  useKeyTrap(tooltipRef, handleClose);

  return (
    <S.Root className={className} aria-describedby={uuid}>
      <S.OpenerBtn
        ref={openerRef}
        type="button"
        aria-label={isOpen ? "open tooltip" : "close tooltip"}
        aria-expanded={isOpen}
        aria-controls={uuid}
        onClick={handleToggle}
      >
        {typeof tooltipBtnContent === "string"
          ? tooltipBtnContent
          : React.createElement(tooltipBtnContent)}
      </S.OpenerBtn>
      {isOpen && (
        <S.TooltipBox ref={tooltipRef} id={uuid} position={position}>
          <S.TopTooltip>
            <S.CloseBtn
              type="button"
              aria-label="close tooltip"
              onClick={handleClose}
            >
              <CloseIcon12 />
            </S.CloseBtn>
            {/* TODO: 기획, 디자인 확정 되면 변경 필요할 수 있음 */}
            {badgeContent && (
              <Badge css={S.badge} variant="primary" content={badgeContent} />
            )}
            {title && <S.Title>{t(title)}</S.Title>}
          </S.TopTooltip>
          {children && <S.Desc>{children}</S.Desc>}
        </S.TooltipBox>
      )}
    </S.Root>
  );
};

export default Tooltip;
