import React from "react";
import { useFormContext } from "react-hook-form";

import { Button, Input, LabelContentTable } from "@/components";
import { onlyNumber } from "@/utils";
import { PHONE_MAX_LENGTH } from "@/assets";

import type { DriverAccountForm } from "types";
import * as S from "./AccountInfoFieldEdit.styled";

interface AccountInfoFieldEditProps {
  accountHolder: string;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  handleClickCancel: () => void;
}

const AccountInfoFieldEdit = ({
  accountHolder,
  handleSubmit,
  handleClickCancel,
}: AccountInfoFieldEditProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<DriverAccountForm>();

  return (
    <form onSubmit={handleSubmit}>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Name of bank">
            <Input
              placeholder="Name of bank"
              value={watch("bankName") || ""}
              maxLength={100}
              register={register("bankName")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Branch">
            <Input
              placeholder="Branch"
              maxLength={100}
              value={watch("bankBranch") || ""}
              register={register("bankBranch")}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Account number">
            <Input
              placeholder="Only numbers"
              maxLength={PHONE_MAX_LENGTH}
              value={watch("bankAccountNo") || ""}
              register={register("bankAccountNo", { setValueAs: onlyNumber })}
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Account holder">
            {accountHolder}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Cancel"
          handleClickBtn={handleClickCancel}
        />
        <Button
          type="submit"
          variant="primaryMedium"
          label="Update"
          isDisabled={Object.keys(errors).length > 0}
        />
      </S.BtnWrapper>
    </form>
  );
};

export default AccountInfoFieldEdit;
