import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { useSearchParams } from "react-router-dom";

import { useKeyTrap, useOnClickOutside } from "@/hooks";
import { modalCompoState } from "@/stores";
import { CloseIcon12 } from "@/assets";

import * as S from "./SidePanel.styled";

interface SidePanelProps {
  className?: string;
  children?: React.ReactNode;
}

const SidePanel = React.forwardRef(
  (
    { className, children }: SidePanelProps,
    ref: React.ForwardedRef<(HTMLElement | null)[]>,
  ) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const modal = useRecoilValue(modalCompoState);

    const panelRef = useRef(null);
    const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
    const hasSidePanel = !!searchParams.get("sidePanel");

    const handleClose = () => {
      searchParams.delete("sidePanel");
      setSearchParams(searchParams);
    };

    const handleAnimationEnd = () => {
      setIsOpenSidePanel(hasSidePanel);
    };

    useKeyTrap(panelRef, modal.length ? () => {} : handleClose);
    useOnClickOutside(
      panelRef,
      modal.length ? () => {} : handleClose,
      typeof ref !== "function" ? ref?.current : [],
    );

    if (!isOpenSidePanel && !hasSidePanel) return null;

    return (
      <S.SidePanel
        className={className}
        ref={panelRef}
        isClosing={!hasSidePanel}
        onAnimationEnd={handleAnimationEnd}
      >
        <S.CloseBtn onClick={handleClose} type="button" aria-label="close">
          <CloseIcon12 />
        </S.CloseBtn>
        {children}
      </S.SidePanel>
    );
  },
);

export default SidePanel;

SidePanel.displayName = "SidePanel";
