import React from "react";

import {
  QuotationCanceled,
  QuotationCompleted,
  QuotationLayout,
  QuotationOngoing,
  QuotationPending,
} from "components";

const ForwarderQuotation = () => {
  const render = {
    pending: <QuotationPending />,
    ongoing: <QuotationOngoing clientType="forwarder" />,
    completed: <QuotationCompleted clientType="forwarder" />,
    canceled: <QuotationCanceled clientType="forwarder" />,
  } as const;

  return <QuotationLayout render={render} />;
};
export default ForwarderQuotation;
