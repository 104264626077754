import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";
import { filterOrderNum } from "@/utils";

import { useGetOrderManagementShipping } from "services";
import type { GetOrderManagementShippingQueryModel } from "types";
import ShippingSection from "./section/ShippingSection";
import OrderShippingFilter from "./filter/OrderShippingFilter";
import * as S from "./OrderShipping.styled";

const OrderShipping = () => {
  const { filters } = useFilterParams<
    GetOrderManagementShippingQueryModel["query"]
  >(["page", "orderNumber"]);

  const query = {
    ...filters,
    ...(filters.orderNumber && {
      orderNumber: filterOrderNum(filters.orderNumber),
    }),
  };

  const { data, dataUpdatedAt, refetch } = useGetOrderManagementShipping({
    query,
  });

  return (
    <>
      <OrderShippingFilter />
      {data && (
        <>
          <ShippingSection
            data={data}
            dataUpdatedAt={dataUpdatedAt}
            refetch={refetch}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default OrderShipping;
