import { css, Theme } from "@emotion/react";

import { SETTING_NOTICE_TABLE_CELL_SIZES } from "assets";

export const noticeTable = css`
  tr {
    grid-template-columns: ${SETTING_NOTICE_TABLE_CELL_SIZES};
  }
`;

export const tableHead = css`
  th {
    height: 32px;
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_HALF_BODY_HEIGHT};

  & > tr:nth-of-type(10) {
    border-bottom: 0;
  }
`;

export const pinned = (theme: Theme) => css`
  margin-right: 4px;

  path {
    fill: ${theme.color.gray_80};
  }
`;
