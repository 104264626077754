import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { isEmpty } from "lodash-es";

import { useGoogleMap } from "@/contexts";
import { LabelContent, Profile, StatusLabel } from "@/components";
import { useAlignCenter, useLocationMarker } from "@/hooks";
import { clientLanguage } from "@/stores";
import { formatICTDateTime, formatPhoneNum } from "@/utils";
import {
  SuccessIcon,
  PhoneIcon,
  TruckIcon,
  DELIVERY_LOG_LABELS,
} from "@/assets";

import { OrderCard } from "components";
import { useMonitoringTruckMarker } from "hooks";
import type { GetShipperMonitoringOrderDetailClientModel } from "types";
import { useAllocationMarkerEventListener, useOrderSelect } from "../../hooks";
import * as S from "./OrderAllocationInfo.styled";

interface AllocationInfoProps {
  orderDetail: GetShipperMonitoringOrderDetailClientModel;
}

const OrderAllocationInfo = ({ orderDetail }: AllocationInfoProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const truckRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const languages = useRecoilValue(clientLanguage);
  const [selectedOrderCard, setSelectedOrderCard] = useState(-1);

  const { googleMap } = useGoogleMap();
  const { truckMarkers } = useMonitoringTruckMarker(orderDetail.allocationList);

  const monitoringAllocations = orderDetail.allocationList.map(
    (allocation, i) => ({ ...allocation, ...truckMarkers[i] }),
  );

  const { focusTruckMarker } = useOrderSelect(monitoringAllocations, truckRef);

  const { displayLocationMarker, resetLocationMarker } =
    useLocationMarker("Allocation");

  const handleOrderCardClick = (index: number): void => {
    setSelectedOrderCard(index);
  };

  const handleAllocationCardClick = (index: number) => (): void => {
    setSelectedOrderCard(index);

    if (selectedOrderCard === index) setSelectedOrderCard(-1);

    focusTruckMarker(
      orderDetail.allocationList[index]?.plateNumber,
      selectedOrderCard === index,
    );
  };

  useAllocationMarkerEventListener(
    monitoringAllocations,
    selectedOrderCard,
    focusTruckMarker,
    handleOrderCardClick,
  );

  useAlignCenter(
    !orderDetail.allocationList[selectedOrderCard]?.gpsAvailable
      ? orderDetail.locations
      : orderDetail.coords,
  );

  useEffect(() => {
    if (!searchParams.get("orderId")) {
      resetLocationMarker();
    }

    if (!orderDetail.locations || !googleMap || isEmpty(orderDetail.locations))
      return;

    displayLocationMarker(orderDetail.locations);
  }, [orderDetail.locations, googleMap]);

  return (
    <S.AllocationInfoWrapper>
      <h3>{t("Allocation list")}</h3>
      <S.OrderCardWrapper>
        {orderDetail.allocationList.length &&
          orderDetail.allocationList.map((allocation, i) => {
            const allocationEntries = Object.entries({
              shipmentsStart: allocation.shipmentsStart,
              puAta: allocation.puAta,
              puAtd: allocation.puAtd,
              doAta: allocation.doAta,
              doAtd: allocation.doAtd,
            });

            const ongoingIndex = allocationEntries.findIndex(
              ([key, time]) => time === null,
            );

            return (
              <OrderCard
                css={S.orderCard(
                  i === selectedOrderCard && selectedOrderCard !== -1,
                )}
                key={i}
                onClick={handleAllocationCardClick(i)}
              >
                <S.OrderCardButton disabled={!!allocation.doAtd}>
                  <S.TitleWrapper
                    ref={(el) =>
                      (truckRef.current[allocation.plateNumber] = el)
                    }
                  >
                    <Profile src={allocation.profileS3Url} />
                    <span>{allocation.driverName} </span>
                    <S.GpsBoxWrapper>
                      <SuccessIcon
                        css={S.checkIcon(!!allocation.gpsAvailable)}
                      />
                      <span>GPS</span>
                    </S.GpsBoxWrapper>
                  </S.TitleWrapper>
                  <OrderCard.Body>
                    <OrderCard.InfoWrapper>
                      <OrderCard.InfoDetail>
                        <TruckIcon css={S.icon} />
                        <span>
                          {languages === "en"
                            ? allocation.truckTypeEn
                            : allocation.truckTypeVi}{" "}
                          ({allocation.plateNumber})
                        </span>
                      </OrderCard.InfoDetail>
                      <OrderCard.InfoDetail css={S.driverInfo}>
                        <PhoneIcon css={S.icon} />
                        <span>{formatPhoneNum(allocation.driverPhone)}</span>
                      </OrderCard.InfoDetail>
                    </OrderCard.InfoWrapper>
                    <S.DeliveryLogWrapper>
                      {allocationEntries.map(([key, time], index) => (
                        <LabelContent
                          key={key}
                          css={S.logLabelContent}
                          direction="horizontal"
                          columnWidth={200}
                          label={
                            DELIVERY_LOG_LABELS[
                              key as keyof typeof DELIVERY_LOG_LABELS
                            ]
                          }
                        >
                          {index === ongoingIndex ? (
                            <StatusLabel status={"ONGOING"} />
                          ) : (
                            <S.Gray70Text>
                              {time ? formatICTDateTime(time) : "-"}
                            </S.Gray70Text>
                          )}
                        </LabelContent>
                      ))}
                    </S.DeliveryLogWrapper>
                  </OrderCard.Body>
                </S.OrderCardButton>
              </OrderCard>
            );
          })}
      </S.OrderCardWrapper>
    </S.AllocationInfoWrapper>
  );
};

export default OrderAllocationInfo;
