import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.main`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  width: 100%;
  max-width: 1440px;
  padding: 240px 120px 0px 120px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.font.bold_40};
    color: ${theme.color.black};
  `}
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 30px;
`;

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100px;
    border: 1px solid ${theme.color.gray_30};

    &:not(:nth-of-type(5n)):not(:last-child) {
      border-right: none;
    }
  `}
`;

export const CardItem = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

export const title = (theme: Theme) => css`
  ${theme.font.medium_13};
  border-bottom: 1px solid ${theme.color.gray_30};
  color: ${theme.color.white};
  background-color: ${theme.color.green_20};
`;

export const desc = (theme: Theme) => css`
  ${theme.font.regular_14};
`;
