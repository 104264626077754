import React from "react";

import * as S from "./SettingDetailLayout.styled";

interface SettingDetailLayoutProps {
  className?: string;
  children: React.ReactNode;
}

const SettingDetailLayout = ({
  className,
  children,
}: SettingDetailLayoutProps) => {
  return <S.Layout className={className}>{children}</S.Layout>;
};

export default SettingDetailLayout;
