import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: solid ${theme.color.gray_30};
    border-width: 1px 1px 1px 0px;
    padding: 16px 20px;
    background-color: ${theme.color.white};
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-bottom: 7px;

    & > h4 {
      ${theme.font.semibold_16};
    }

    & > span {
      ${theme.font.medium_14};
      color: ${theme.color.primary_40};
    }
  `}
`;

export const table = css`
  height: auto;

  tr {
    grid-template-columns: 112px 1fr;
  }

  &[aria-rowcount="0"] tr:hover td {
    background-color: inherit;
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};

  & > tr :nth-of-type(20) {
    border-bottom: 0;
  }
`;

export const noDataWrapper = (theme: Theme) => css`
  display: flex;
  align-items: center;
  min-height: ${theme.size.TABLE_BODY_HEIGHT};

  && {
    border-bottom: 0;
  }
`;

export const Driver = styled.div<{ hasName: boolean }>`
  ${({ hasName }) => css`
    display: grid;
    grid-template-columns: ${hasName ? "24px 1fr auto" : "1fr auto"};
    align-items: center;
    column-gap: 8px;
    padding-right: 8px;

    & > span:first-of-type {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `}
`;

export const Count = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;
