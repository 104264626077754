import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContentTable = css`
  width: 764px;
`;

export const labelContentTableContent = css`
  & > div:last-of-type {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 2px;

    & > span {
      width: fit-content;
    }
  }
`;

export const PlateList = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    grid-column: span 3;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const viewDetailsButton = (theme: Theme) => css`
  ${theme.font.regular_13};
  color: ${theme.color.primary_40};
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;

export const fileContent = css`
  & > div {
    margin-bottom: 4px;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;

export const LinkedDriverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

export const Driver = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 8px;

    & > span {
      ${theme.font.regular_14};
    }
  `}
`;

export const fileDesc = css`
  margin-bottom: 4px;
`;

export const dropdown = css`
  width: 100%;
`;

export const content = css`
  & > div:not(:first-of-type) {
    flex-direction: column;
    align-items: flex-start;

    & > textarea {
      width: 100%;
      height: 120px;
    }
  }
`;

export const gpsIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    & > path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `;
