import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 488px;
    padding: 60px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.font.semibold_32};
    margin-bottom: 40px;
  `}
`;

export const radio = css`
  margin-bottom: 40px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 24px;

  & > :last-child {
    margin: 0;
  }
`;

export const loginErrMsg = css`
  margin-bottom: 24px;
`;

export const loginBtn = (theme: Theme) => css`
  ${theme.font.bold_16}
  margin: 16px 0px 40px;
`;

export const FlexWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;

    /* ::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 50%;
      width: 1px;
      background-color: ${theme.color.gray_70};
    } */
  `}
`;

const mixinLink = (theme: Theme) => css`
  ${theme.font.regular_13};
  color: ${theme.color.gray_70};

  :hover {
    text-decoration: underline;
  }
`;

export const externalLink = (theme: Theme) => css`
  ${theme.font.regular_13};
`;

export const internalLink = (theme: Theme) => css`
  ${mixinLink(theme)};
  text-decoration: underline;

  :hover {
    color: ${theme.color.gray_70};
  }
`;

export const input = (theme: Theme) => css`
  ${theme.font.regular_16};
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${theme.color.gray_30};
  border-radius: 0;
  padding: 4px 0;

  :focus {
    outline: none;
    border: 0;
    border-bottom: 1px solid ${theme.color.primary_40};
  }
`;

export const EmailInputTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const PasswordInputTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-top: 40px;
    color: ${theme.color.gray_70};
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 0px;
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const PasswordShowButton = styled.button<{ isPasswordShow: boolean }>`
  ${({ theme, isPasswordShow }) => css`
    width: 18px;
    height: 18px;
    background-color: inherit;

    & > svg > path {
      fill: ${isPasswordShow ? theme.color.primary_40 : theme.color.gray_50};
    }
  `}
`;

export const InputRoot = styled.div`
  position: relative;
`;
