import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
`;

export const ArrowBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 2px;

    svg > path {
      fill: ${theme.color.gray_50};
    }

    :hover:not([disabled]) {
      background-color: ${theme.color.gray_10};
    }

    &[disabled] {
      display: none;
    }
  `}
`;

export const NumWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :last-child {
    border-right: 0;
  }
`;

interface NumBtnProps {
  isCurrentPage: boolean;
}

export const NumBtn = styled.button<NumBtnProps>`
  ${({ theme, isCurrentPage }) => css`
    ${theme.font.regular_14}

    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    color: ${isCurrentPage ? theme.color.primary_40 : theme.color.gray_50};

    &:not(:disabled):hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;
