import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import type { UseMutateFunction } from "@tanstack/react-query";

import { useToast } from "@/hooks";
import { makeCryptoFunction } from "@/utils";
// NOTE: @/utils만 import하면 import 에러가 발생합니다.
import { checkEmailValidation } from "@/utils/validator";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";
import type { LoginQueryModel, LoginServerModel } from "@/types";

interface LoginForm {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .required(COMMON_VALID_MSG.EMAIL)
      .test("email", COMMON_VALID_MSG.EMAIL, (value) =>
        checkEmailValidation(value),
      ),
    password: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  })
  .required();

const useLoginForm = <T extends LoginServerModel>(
  loginMutate: UseMutateFunction<T, unknown, LoginQueryModel, unknown>,
) => {
  const { addToast } = useToast();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>({
    mode: "onTouched",
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
  });

  const handleLogin = ({ email, password }: LoginForm) => {
    loginMutate(
      {
        body: {
          email,
          password: makeCryptoFunction(password),
          agentType: "web",
        },
      },
      { onError: () => addToast(COMMON_TOAST_MSG.WARNING.LOGIN_FAIL) },
    );
  };

  return {
    register,
    errors,
    watch,
    handleSubmit: handleSubmit(handleLogin),
  };
};

export default useLoginForm;
