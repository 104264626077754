import { useState } from "react";

import { useSearchInputWithoutQuery } from "@/hooks";
import type { FavoriteOrdersClientModel } from "@/types";

const useFavoriteModal = (
  favoriteOrders: FavoriteOrdersClientModel[] | undefined,
) => {
  const [selectedFavoriteId, setSelectedFavoriteId] = useState<string | null>(
    null,
  );

  const {
    searchValue,
    filteredDatas,
    handleSearch,
    handleChangeInput,
    handleResetInput,
  } = useSearchInputWithoutQuery<FavoriteOrdersClientModel>(
    favoriteOrders,
    "favoriteName",
  );

  const handleSelectFavorite = (orderId: string) => () => {
    setSelectedFavoriteId((prev) => (prev === orderId ? null : orderId));
  };

  return {
    searchValue,
    selectedFavoriteId,
    filteredDatas,
    handleSearch,
    handleChangeInput,
    handleResetInput,
    handleSelectFavorite,
  };
};

export default useFavoriteModal;
