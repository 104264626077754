import React from "react";

import { LabelContent } from "@/components";
import { LOADING_STATUS_RADIOS, PointIcon } from "@/assets";

import { OrderInfo } from "components";
import type { GetShipperMonitoringOrderDetailClientModel } from "types";
import * as S from "./TruckingCompanyInfo.styled";

interface TruckingCompanyInfoProps {
  orderInfo: {
    cargoes: GetShipperMonitoringOrderDetailClientModel["cargoes"];
    puAddr: string;
    doAddr: string;
    loadingStatus: (typeof LOADING_STATUS_RADIOS)[number]["key"];
  };
}

const TruckingCompanyInfo = ({ orderInfo }: TruckingCompanyInfoProps) => {
  if (!orderInfo) return null;

  const deliveryCargoes = orderInfo.cargoes?.map(
    (cargo: GetShipperMonitoringOrderDetailClientModel["cargoes"][number]) => ({
      cargoType: cargo.cargoType,
      weight: cargo.weight,
      cargoCnt: cargo.cargoCnt,
    }),
  );

  return (
    <>
      <S.TruckingCompanyInfoWrapper>
        <OrderInfo.Cargo
          css={S.OrderInfoCargo}
          loadingStatus={orderInfo.loadingStatus ?? ""}
          cargoes={deliveryCargoes ?? []}
        />
        <S.Division />
        <LabelContent
          label="Pick-up"
          direction="horizontal"
          icon={<PointIcon css={S.pointIcon("PICK")} />}
          columnWidth={100}
        >
          <span>{orderInfo.puAddr}</span>
        </LabelContent>
        <S.Division />
        <LabelContent
          label="Drop-off"
          direction="horizontal"
          icon={<PointIcon css={S.pointIcon("DROP")} />}
          columnWidth={100}
        >
          <span>{orderInfo.doAddr}</span>
        </LabelContent>
      </S.TruckingCompanyInfoWrapper>
    </>
  );
};

export default TruckingCompanyInfo;
