import { calcTableWidth } from "@/utils";
import { CELL, RADIO, TAB } from "@/assets";
import type { RadioType } from "@/types";

export const SETTLEMENT_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  "150px",
  "102px",
  ["220px", "250px"],
  "160px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
]);

export const SETTLEMENT_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.CONFIRMATION],
  [CELL.COMPANY_CLIENT],
  [CELL.TOTAL_PRICE],
  [
    CELL.ADDRESS_PUADDR,
    [CELL.ETA_PUETA, CELL.ATA_PUATA, CELL.ETD_PUETD, CELL.ATD_PUATD],
  ],
  [
    CELL.DROPOFF_DOADDR,
    [CELL.ETA_DOETA, CELL.ATA_DOATA, CELL.ETD_DOETD, CELL.ATD_DOATD],
  ],
] as const;

export const SETTLEMENT_ONGOING_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.WAITING,
  RADIO.ONGOING,
  RADIO.COMPLETED,
];

export const FORWARDER_SETTLEMENT_TABS = [TAB.SHIPPER, TAB.CARRIER] as const;

export const FORWARDER_CARRIER_SETTLEMENT_TABS = [
  TAB.SHIPPER,
  TAB.CARRIER,
] as const;

export const SETTLEMENT_REQUESTED_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.WAITING,
  RADIO.REQUESTED_ONGOING,
  RADIO.COMPLETED,
];

export const FORWARDER_SETTLEMENT_CARRIER_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.CONFIRMATION],
  [CELL.COMPANY_CLIENT],
  [CELL.TOTAL_PRICE],
  [
    CELL.ADDRESS_PUADDR,
    [CELL.ETA_PUETA, CELL.ATA_PUATA, CELL.ETD_PUETD, CELL.ATD_PUATD],
  ],
  [
    CELL.DROPOFF_DOADDR,
    [CELL.ETA_DOETA, CELL.ATA_DOATA, CELL.ETD_DOETD, CELL.ATD_DOATD],
  ],
] as const;

export const FORWARDER_SETTLEMENT_CARRIER_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  "150px",
  "102px",
  ["220px", "250px"],
  "160px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
]);

export const FORWARDER_SETTLEMENT_SHIPPER_FILTER_STATUS_RADIOS: RadioType[] = [
  RADIO.ALL,
  RADIO.WAITING,
  RADIO.ONGOING,
  RADIO.COMPLETED,
];

export const FORWARDER_SETTLEMENT_SHIPPER_TABLE_CELL_SIZES = calcTableWidth([
  "130px",
  "150px",
  "102px",
  ["220px", "250px"],
  "160px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
  "128px",
]);

export const FORWARDER_SETTLEMENT_SHIPPER_TABLE_COLUMNS = [
  [CELL.STATUS],
  [CELL.ORDER_NUMBER],
  [CELL.CONFIRMATION],
  [CELL.COMPANY_CLIENT],
  [CELL.TOTAL_PRICE],
  [
    CELL.ADDRESS_PUADDR,
    [CELL.ETA_PUETA, CELL.ATA_PUATA, CELL.ETD_PUETD, CELL.ATD_PUATD],
  ],
  [
    CELL.DROPOFF_DOADDR,
    [CELL.ETA_DOETA, CELL.ATA_DOATA, CELL.ETD_DOETD, CELL.ATD_DOATD],
  ],
] as const;
