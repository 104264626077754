import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { SETTING_MEMBER_TABLE_CELL_SIZES } from "assets";

export const memberTable = css`
  tr {
    grid-template-columns: ${SETTING_MEMBER_TABLE_CELL_SIZES};
  }
`;

export const tableHead = css`
  th {
    height: 32px;
  }
`;

export const tableBody = (theme: Theme) => css`
  height: ${theme.size.TABLE_HALF_BODY_HEIGHT};

  & > tr:nth-of-type(10) {
    border-bottom: 0;
  }
`;

export const Utils = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  width: 64px;
  height: 100%;

  & > button,
  a {
    display: flex;
    width: 32px;
    height: 30px;

    & > svg {
      margin: 0;
    }
  }
`;

export const NoResultWrapper = styled.tr`
  display: flex;
  justify-content: center;
  height: 324px;
`;
