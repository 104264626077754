import React from "react";
import { useTranslation } from "react-i18next";

import { LabelContent, LabelContentTable } from "@/components";
import {
  calculateTotalAmount,
  calculateVatAmount,
  findLookupTableLabel,
  formatPrice,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS } from "@/assets";

import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementPayment.styled";

interface OrderManagementPaymentProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementPayment = ({ data }: OrderManagementPaymentProps) => {
  const { t } = useTranslation();

  const totalPrice = calculateTotalAmount(
    `${data.payments.price}`,
    `${data.payments.vat}`,
  );

  const vat = `(${data.payments.vat}%) ${formatPrice(
    calculateVatAmount(`${data.payments.price}`, `${data.payments.vat}`),
  )}`;

  const payMethodLabel =
    findLookupTableLabel(PAYMENT_METHOD_RADIOS, data.payments.pay) ??
    "Account transfer";

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Payment method">
          {payMethodLabel ? t(payMethodLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Total">
          <S.PriceWrapper>
            {formatPrice(totalPrice)}
            <S.LabelContentWrapper>
              <LabelContent
                css={S.labelContent}
                label="Subtotal"
                direction="horizontal"
                columnWidth={120}
              >
                {formatPrice(data.payments.price)}
              </LabelContent>
              <LabelContent
                css={S.labelContent}
                label="Tax"
                direction="horizontal"
                columnWidth={120}
              >
                {vat}
              </LabelContent>
            </S.LabelContentWrapper>
          </S.PriceWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementPayment;
