import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, InternalLink, SettingDetailLayout } from "@/components";
import { PATH, BackArrowIcon } from "@/assets";

import { SupportField } from "components";
import { INQUIRY_CATEGORY_OPTIONS } from "assets";
import useCreateSupport from "./hooks/useCreateSupport";
import * as S from "./CreateSupport.styled";

const CreateSupport = () => {
  const { t } = useTranslation();

  const {
    formMethod,
    isLoading,
    handleCancel,
    handleSubmit,
    handleFileAdd,
    handleFileDelete,
  } = useCreateSupport();

  return (
    <SettingDetailLayout>
      <S.Header>
        <InternalLink variant="ghostNoUnderlineGray" to={PATH.SETTINGS_SUPPORT}>
          <BackArrowIcon />
          <S.Title>{t("Add inquiry")}</S.Title>
        </InternalLink>
      </S.Header>
      <FormProvider {...formMethod}>
        <form onSubmit={handleSubmit}>
          <SupportField
            categories={INQUIRY_CATEGORY_OPTIONS}
            handleFileAdd={handleFileAdd}
            handleFileDelete={handleFileDelete}
          />
          <S.ButtonWrapper>
            <Button
              type="button"
              variant="outlineMedium"
              label="Cancel"
              handleClickBtn={handleCancel}
            />
            <Button
              type="submit"
              variant="primaryMedium"
              label="Add"
              isLoading={isLoading}
              isDisabled={Object.keys(formMethod.formState.errors).length > 0}
            />
          </S.ButtonWrapper>
        </form>
      </FormProvider>
    </SettingDetailLayout>
  );
};

export default CreateSupport;
