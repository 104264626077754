import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";

import {
  Card,
  Dropdown,
  ErrMsg,
  FormFileInput,
  FormRadioBtn,
  Input,
  LabelContent,
  Textarea,
} from "@/components";
import { useToast } from "@/hooks";
import {
  LOADING_METHOD_TYPE,
  LOADING_STATUS_RADIOS,
  LOADING_STATUS_TOOLTIP,
} from "@/assets";
import { COMMON_TOAST_MSG, convertMB } from "@/constants";
import type { Languages } from "@/types";

import { ORDER_FORM } from "assets";
import type { OrderForm } from "types";
import * as S from "./OrderCreationTruckingMethods.styled";

const OrderCreationTruckingMethods = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const {
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    register,
  } = useFormContext<OrderForm>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    const id = name.split(".").at(-1);

    if (id !== "file1" && id !== "file2") return;

    if ((files?.[0].size ?? 0) > convertMB(5)) {
      addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);

      setValue(`truckingMethods.attachedFiles.${id}`, null);
      setError(name as keyof typeof ORDER_FORM, { type: "capacityOver" });
    } else {
      clearErrors(`truckingMethods.attachedFiles.${id}`);
    }
  };

  const handleDeleteFile = (fileKey: string) => () => {
    if (
      fileKey === "truckingMethods.attachedFiles.file1" ||
      fileKey === "truckingMethods.attachedFiles.file2"
    ) {
      setValue(fileKey, null);
      clearErrors(fileKey);
    }
  };

  return (
    <Card>
      <Card.Content heading="Trucking methods" headingLevel="h3">
        <S.ContentWrapper>
          <LabelContent
            css={S.labelcontent}
            isRequired
            direction="vertical"
            label="Loading status"
            tooltip={LOADING_STATUS_TOOLTIP}
          >
            <FormRadioBtn
              radioList={LOADING_STATUS_RADIOS}
              register={register("truckingMethods.loadingStatus")}
            />
          </LabelContent>
          <LabelContent
            label="Loading/unloading method"
            isRequired
            direction="vertical"
          >
            <Controller
              control={control}
              name="truckingMethods.loadingMethod"
              render={({ field: { value, onChange } }) => {
                const handleSelect = (loadingMethodKey: any): void => {
                  const selectedIndex = LOADING_METHOD_TYPE.findIndex(
                    (loadingMethod) => loadingMethod.key === loadingMethodKey,
                  );

                  onChange(LOADING_METHOD_TYPE[selectedIndex]);
                };

                return (
                  <Dropdown
                    hasError={!!errors.truckingMethods?.loadingMethod?.message}
                    options={LOADING_METHOD_TYPE}
                    placeholder="Select the method"
                    selectedOption={value}
                    handleSelect={handleSelect}
                  />
                );
              }}
            />

            {watch("truckingMethods.loadingMethod").key === "others" && (
              <Input
                id="loadingOtherMethod"
                css={S.loadingMethodInput}
                maxLength={100}
                placeholder="Please enter the loading/unloading method"
                hasError={!!errors.truckingMethods?.loadingOtherMethod?.message}
                register={register("truckingMethods.loadingOtherMethod")}
              />
            )}
            {(!!errors?.truckingMethods?.loadingMethod?.message ||
              !!errors?.truckingMethods?.loadingOtherMethod?.message) && (
              <ErrMsg>
                {(errors?.truckingMethods?.loadingMethod
                  ?.message as Languages) ||
                  (errors?.truckingMethods?.loadingOtherMethod
                    ?.message as Languages)}
              </ErrMsg>
            )}
          </LabelContent>
          <LabelContent label="Attached file" direction="vertical">
            <FormFileInput
              placeholder="Upload files related to order"
              file={watch("truckingMethods.attachedFiles.file1")}
              fileKey="truckingMethods.attachedFiles.file1"
              hasErr={!!errors.truckingMethods?.attachedFiles?.file1}
              register={register}
              handleInputChange={handleInputChange}
              handleDeleteFile={handleDeleteFile}
            />
            <FormFileInput
              placeholder="Upload files related to order"
              file={watch("truckingMethods.attachedFiles.file2")}
              fileKey="truckingMethods.attachedFiles.file2"
              hasErr={!!errors.truckingMethods?.attachedFiles?.file2}
              register={register}
              handleInputChange={handleInputChange}
              handleDeleteFile={handleDeleteFile}
            />
            <S.FileLimit>
              - {t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}
            </S.FileLimit>
          </LabelContent>
          <LabelContent label="Memo" direction="vertical">
            <div>
              <Textarea
                id="memo"
                hasError={!!errors.truckingMethods?.memo?.message}
                placeholder="Please enter a specific information about the cargo."
                maxLength={200}
                value={watch("truckingMethods.memo") ?? ""}
                register={register("truckingMethods.memo")}
              />
              {/*  NOTE: 필수값 에러가 아닌 서버에서 설정한 글자수 보다 많을 때 나오는 발생하는 에러 메세지 */}
              {errors.truckingMethods?.memo?.message && (
                <ErrMsg>
                  {errors.truckingMethods?.memo?.message as Languages}
                </ErrMsg>
              )}
            </div>
          </LabelContent>
        </S.ContentWrapper>
      </Card.Content>
    </Card>
  );
};

export default OrderCreationTruckingMethods;
