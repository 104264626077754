export const createPageList = (
  currentPage: number,
  maxPageCount: number,
  totalPages: number,
) => {
  const result = [];
  const firstIndex =
    Math.floor((currentPage - 1) / maxPageCount) * maxPageCount + 1;
  const lastIndex = Math.min(firstIndex + maxPageCount - 1, totalPages);

  for (let i = firstIndex; i <= lastIndex; i++) {
    result.push(i);
  }

  return result;
};

export const getPrevPageNum = (currentPage: number, maxPageCount: number) => {
  return Math.max(
    maxPageCount * Math.floor((currentPage - maxPageCount - 1) / maxPageCount) +
      1,
    1,
  );
};

export const getNextPageNum = (
  currentPage: number,
  maxPageCount: number,
  totalPages: number,
) => {
  return Math.min(
    Math.floor((currentPage + maxPageCount - 1) / maxPageCount) * maxPageCount +
      1,
    totalPages,
  );
};
