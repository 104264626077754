import type { AxiosResponse } from "axios";

import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import type { S3FileUploadType } from "@/types";

import { ax } from "apis/axios";
import { makeS3PresignedUrlFileUuids } from "utils";
import type {
  CompanyType,
  GetCompanyInfoServerModel,
  GetCompanyPlansServerModel,
  PutLicenseQueryModel,
  UpdateCompanyInfoQueryModel,
} from "types";

export const getCompanyInfoAPI = async () => {
  const { data } = await ax.get<GetCompanyInfoServerModel>("/setting/company");

  return data;
};

export const putCompanyInfoAPI = (req: UpdateCompanyInfoQueryModel) =>
  ax.put("/setting/company", req.body);

export const getCompanyPlanAPI = async () => {
  const { data } = await ax.get<GetCompanyPlansServerModel>("/setting/plan");

  return data;
};

export async function putCompanyLicenseAPI(
  req: PutLicenseQueryModel<"carrier" | "forwardercarrier">,
): Promise<AxiosResponse<any, any>>;
export async function putCompanyLicenseAPI(
  req: PutLicenseQueryModel<"forwarder" | "shipper">,
): Promise<AxiosResponse<any, any>>;
export async function putCompanyLicenseAPI(
  req: PutLicenseQueryModel<CompanyType>,
) {
  try {
    const files: S3FileUploadType = {
      ...("newTransportLicense1" in req.body && {
        newTransportLicense1: {
          file: req.body.newTransportLicense1,
          path: "license",
        },
      }),
      ...("newTransportLicense2" in req.body && {
        newTransportLicense2: {
          file: req.body.newTransportLicense2,
          path: "license",
        },
      }),
      newLicense1: {
        file: req.body.newLicense1,
        path: "license",
      },
      newLicense2: {
        file: req.body.newLicense2,
        path: "license",
      },
    };

    const fileUuids = await makeS3PresignedUrlFileUuids(files);
    return await ax.put("/setting/company/license", fileUuids);
  } catch (err) {
    const { addToast } = useToast();
    addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
  }
}
