import React from "react";
import { useTranslation } from "react-i18next";

import { Table, StatusLabel } from "@/components";
import { useFilterParams } from "@/hooks";
import {
  filterTableColumns,
  findLookupTableLabel,
  formatICTDateTime,
} from "@/utils";
import { SUPPORT_TABLE_COLUMNS } from "@/assets";

import { useGetInquiries } from "services";
import { INQUIRY_CATEGORY_OPTIONS } from "assets";
import type { GetInquiriesQueryModel, GetInquiriesServerModel } from "types";
import * as S from "./SupportTable.styled";

const SupportTable = () => {
  const { t } = useTranslation();

  const { filters } = useFilterParams<GetInquiriesQueryModel["query"]>(
    ["page"],
    { pageSize: 10 },
  );

  const { data, isLoading } = useGetInquiries({
    suspense: false,
    req: { query: filters },
  });

  const filteredColumns = filterTableColumns(SUPPORT_TABLE_COLUMNS);

  const formatTableData = (
    data: GetInquiriesServerModel["inquiries"][number],
    key: keyof GetInquiriesServerModel["inquiries"][number],
  ) => {
    const type =
      findLookupTableLabel(INQUIRY_CATEGORY_OPTIONS, data.type) ?? "";

    switch (key) {
      case "type":
        return type ? t(type) : "-";

      case "created":
        return <time>{formatICTDateTime(data[key], "DD/MM/YYYY")}</time>;

      case "status":
        return <StatusLabel status={data[key]} />;

      default:
        return data[key];
    }
  };

  return (
    <Table
      css={S.table}
      title="support"
      hasRowBtn
      openTriggerColumnIdx={1}
      isLoading={isLoading}
      rowCount={data?.inquiries?.length ?? 0}
    >
      <Table.Head css={S.tableHead} columnList={SUPPORT_TABLE_COLUMNS} />
      <Table.Body css={S.tableBody}>
        {data?.inquiries.length ? (
          data.inquiries.map((inquiryInfo) => (
            <Table.SelectRowMovePage
              key={inquiryInfo.inquiryId}
              path={inquiryInfo.inquiryId}
            >
              {filteredColumns.map((column) => (
                <Table.Cell key={column.key}>
                  {formatTableData(inquiryInfo, column.key)}
                </Table.Cell>
              ))}
            </Table.SelectRowMovePage>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default SupportTable;
