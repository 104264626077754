import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_ul, mixin_li } from "@/styles";

export const OrderCard = styled.li`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_35};
    border-radius: 5px;
    padding: 15px 19px;
  `}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    margin-bottom: 8px;
  `}
`;

export const InfoWrapper = styled.ul`
  ${mixin_ul};
`;

export const InfoDetail = styled.li`
  ${mixin_li};
`;
