export const TEXT_LIMIT = {
  VIN: 17,
  SERVER_LIMIT_VALUE: 2147483647,
  PRICE_LIMIT_VALUE: 1952257860,
  ADDR_LIMIT_VALUE: 50,
  PIC_LIMIT_VALUE: 35,
  TRUCK_COUNT_LIMIT_VALUE: 99,
  CARGO_SIZE_LIMIT_VALUE: 999,
  CARGO_NAME_LIMIT_VALUE: 100,
  CARGO_WEIGHT_LIMIT_VALUE: 80000,
  CLIENT_LIMIT_VALUE: 100,
  MEMO_LIMIT_VALUE: 200,
  BANK_LIMIT_VALUE: 100,
  NAME_LIMIT_VALUE: 100,
  LINK_TRUCK_LIMIT_VALUE: 100,
  LINK_DRIVER_LIMIT_VALUE: 302,
  BRAND_LIMIT_VALUE: 100,
  MODEL_LIMIT_VALUE: 100,
  SEARCH_LIMIT_VALUE: 254,
  ORDER_NUMBER_LIMIT_VALUE: 254,
  ORDER_FILTER_LIMIT_VALUE: 99,
};
