/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";

import { TriangleArrowIcon } from "@/assets";

import * as S from "./FoldBtn.styled";

interface FoldBtnProps {
  className?: string;
  isFold: boolean;
  handleClickFold: any;
}

const FoldBtn = ({ className, isFold, handleClickFold }: FoldBtnProps) => {
  return (
    <S.Root className={className} type="button" onClick={handleClickFold}>
      <TriangleArrowIcon css={S.arrowIcon(isFold)} />
    </S.Root>
  );
};

export default FoldBtn;
