import { useQuery } from "@tanstack/react-query";

import { getNoticeDetailAPI, getNoticesAPI } from "apis";
import type { GetNoticesQueryModel } from "types";

const noticeKeys = {
  all: ["notice"] as const,
  lists: () => [...noticeKeys.all, "list"] as const,
  list: (filters: GetNoticesQueryModel) =>
    [...noticeKeys.lists(), { filters }] as const,
  details: () => [...noticeKeys.all, "detail"] as const,
  detail: (id: string) => [...noticeKeys.details(), id] as const,
};

interface UseGetNoticesProps {
  suspense: boolean;
  req: GetNoticesQueryModel;
}

export const useGetNotices = ({ suspense, req }: UseGetNoticesProps) => {
  return useQuery({
    queryKey: noticeKeys.list(req),
    queryFn: () => getNoticesAPI(req),
    suspense,
  });
};

export const useGetNoticeDetail = (noticeId: string) => {
  return useQuery({
    queryKey: noticeKeys.detail(noticeId),
    queryFn: () => getNoticeDetailAPI(noticeId),
  });
};
