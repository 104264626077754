import React from "react";

import { formatOrderNum } from "@/utils";
import { CircleCheckIcon } from "@/assets";

import { OrderInfo } from "components";
import type { SelectedAllocationDelivery } from "types";
import * as S from "./DeliveryCard.styled";

interface DeliveryCardProps {
  delivery: SelectedAllocationDelivery;
  handleAllocationAdd: () => void;
  handleAllocationDelete: () => void;
}

const DeliveryCard = ({
  delivery,
  handleAllocationAdd,
  handleAllocationDelete,
}: DeliveryCardProps) => {
  const deliveryCargoes = delivery.cargoes.map((cargo) => ({
    cargoType: cargo.cargoType,
    weight: cargo.weight,
    cargoCnt: cargo.cargoCnt,
  }));

  return (
    <S.Root
      isSelected={delivery.selected}
      onClick={delivery.selected ? handleAllocationDelete : handleAllocationAdd}
    >
      <S.Header>
        <div>
          <S.Title>{formatOrderNum(delivery.orderNumber)}</S.Title>
          <S.OrderIdx>(#{delivery.idx + 1})</S.OrderIdx>
        </div>
        <S.CheckBtn isSelected={delivery.selected}>
          <S.TruckCount isSelected={delivery.selected}>
            (
            {delivery.selected
              ? delivery.allocatedCnt + 1
              : delivery.allocatedCnt}
            /{delivery.deliveryCnt})
          </S.TruckCount>
          <CircleCheckIcon />
        </S.CheckBtn>
      </S.Header>
      <S.Body>
        <OrderInfo>
          <OrderInfo.Cargo
            css={S.orderInfo}
            loadingStatus={delivery.loadingStatus}
            cargoes={deliveryCargoes}
          />
          <OrderInfo.Address
            css={S.orderInfo}
            addr={delivery.puAddr}
            addrDetail={delivery.puAddrDetail}
            label="Pick-up"
            eta={delivery.puEta}
            etd={delivery.puEtd}
          />
          <OrderInfo.Address
            css={S.orderInfoRow}
            addr={delivery.doAddr}
            addrDetail={delivery.doAddrDetail}
            label="Drop-off"
            eta={delivery.doEta}
            etd={delivery.doEtd}
          />
        </OrderInfo>
      </S.Body>
    </S.Root>
  );
};

export default DeliveryCard;
