import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const card = css`
  width: 764px;
`;

export const contentTableRow = (isEdit: boolean) => css`
  > div,
  > span {
    padding: ${isEdit && "10px 0"};
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const allocateLink = css`
  width: 100%;
`;

export const content = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.color.gray_30};
`;
