import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

interface CalendarDateProps {
  isOriginDate: boolean;
  isDoneCurrentDate: boolean;
  isPrevEndDateDisabled: boolean;
  isFirstSunDay: boolean;
  isLastSatDay: boolean;
  isCurrentStartDate: boolean;
  isCurrentEndDate: boolean;
  isLastDate: boolean;
  isFirstDate: boolean;
  isSameStartEndDate: boolean;
  isPeriodOfSelectDate: boolean;
  startEndOfTheWeek: "start" | "end" | "middle";
}

const mixinPeriodLine = (
  isPeriodOfSelectDate: boolean,
  isEndDate: boolean,
  isDoneCurrentDate: boolean,
  isPrevEndDateDisabled: boolean,
  isOriginDate: boolean,
  direction: string,
  theme: Theme,
) => css`
  content: "";
  position: absolute;
  top: 0;
  width: 20px;
  height: 36px;
  background: ${isPeriodOfSelectDate &&
  isEndDate &&
  (isDoneCurrentDate || isPrevEndDateDisabled) &&
  `linear-gradient(${direction}, ${
    isPrevEndDateDisabled || isOriginDate
      ? theme.color.gray_10
      : theme.color.primary_20
  }, white)`};
  pointer-events: none;
`;

export const CalendarDate = styled.li<CalendarDateProps>`
  ${({
    theme,
    isOriginDate,
    isDoneCurrentDate,
    isSameStartEndDate,
    isFirstSunDay,
    isLastSatDay,
    isLastDate,
    isFirstDate,
    isCurrentStartDate,
    isCurrentEndDate,
    isPeriodOfSelectDate,
    startEndOfTheWeek,
    isPrevEndDateDisabled,
  }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;

    & > button {
      border-radius: ${isCurrentStartDate || isCurrentEndDate
        ? "100px"
        : startEndOfTheWeek === "start" && !isFirstSunDay
        ? "100px 0 0 100px"
        : startEndOfTheWeek === "end" && !isLastSatDay
        ? "0 100px 100px 0"
        : "0"};
      color: ${(isCurrentStartDate || isCurrentEndDate) && theme.color.white};
      background-color: ${isCurrentStartDate || isCurrentEndDate
        ? isPrevEndDateDisabled
          ? theme.color.gray_50
          : theme.color.primary_40
        : isPeriodOfSelectDate
        ? isPrevEndDateDisabled
          ? theme.color.gray_10
          : isDoneCurrentDate && theme.color.primary_20
        : "inherits"};
    }

    &:first-of-type::before {
      ${mixinPeriodLine(
        isPeriodOfSelectDate,
        (!isCurrentStartDate || !isCurrentEndDate) &&
          (!isCurrentStartDate || !isFirstDate),
        isDoneCurrentDate,
        isPrevEndDateDisabled,
        isOriginDate,
        "to left",
        theme,
      )}

      right: 44px;
    }

    &:last-of-type::before {
      ${mixinPeriodLine(
        isPeriodOfSelectDate,
        (!isCurrentStartDate || !isCurrentEndDate) &&
          (!isCurrentEndDate || !isLastDate),
        isDoneCurrentDate,
        isPrevEndDateDisabled,
        isOriginDate,
        "to right",
        theme,
      )}

      left: 44px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 36px;
      height: 36px;
      border-radius: ${isCurrentStartDate
        ? "100px 0 0 100px"
        : "0 100px 100px 0"};
      background-color: ${!isSameStartEndDate &&
      (isCurrentStartDate || isCurrentEndDate) &&
      isPeriodOfSelectDate
        ? isPrevEndDateDisabled
          ? theme.color.gray_10
          : isDoneCurrentDate && theme.color.primary_20
        : ""};
    }
  `}
`;

export const TodayWrapper = styled.div<{
  isToday: boolean;
  isSelected: boolean;
}>`
  ${({ theme, isToday, isSelected }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: ${isToday && `1px solid ${theme.color.black}`};
    color: ${isSelected && theme.color.red_10};
    border-radius: 100px;
  `}
`;

export const DatesBtn = styled.button<{
  isPeriodOfSelectDate: boolean;
  isCurrentDate: boolean;
  isPrevEndDateDisabled: boolean;
}>`
  ${({
    isPeriodOfSelectDate,
    isCurrentDate,
    isPrevEndDateDisabled,
    theme,
  }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 100%;
    color: ${theme.color.black};
    z-index: ${theme.zIndex.CALENDAR};

    &[disabled] {
      color: ${isCurrentDate
        ? theme.color.white
        : isPeriodOfSelectDate && isPrevEndDateDisabled
        ? theme.color.gray_70
        : theme.color.gray_50};
      text-decoration: line-through;
    }
  `}
`;
