import { ax } from "apis/axios";
import { getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded } from "utils";
import type { GetPropertyTabServerModel } from "types";

export const createImgUrls = (data: (string | number | null)[]) =>
  Promise.all(
    data.map((item) =>
      typeof item === "string"
        ? getS3PresignedUrlAPI(addPrefixToUrlIfNeeded(item, "profile"))
        : item,
    ),
  );

export const getPropertyTabAPI = async () => {
  const { data } = await ax.get<GetPropertyTabServerModel>("/property/tab");

  return data;
};
