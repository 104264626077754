import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray_30};
    padding: 32px;
    background-color: ${theme.color.white};
  `}
`;

export const FieldSet = styled.fieldset`
  &:not(:last-of-type) {
    margin-bottom: 48px;
  }
`;

export const FieldTitle = styled.legend`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 12px;
    color: ${theme.color.black};
  `}
`;

export const FieldContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-top: 1px solid ${theme.color.gray_60};
    padding: 16px 0;
  `}
`;

export const FieldRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, auto));
  column-gap: 10px;

  &:not(:last-of-type) {
    margin-bottom: 28px;
  }
`;

export const labelContent = (theme: Theme) => css`
  && > :nth-of-type(1) > label {
    color: ${theme.color.gray_60};
  }
  && > :nth-of-type(2) {
    height: 40px;
    & > input {
      height: 40px;
    }
  }
`;

export const dropdown = css`
  width: 250px;
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const LabelContentWrapper = styled.div`
  display: grid;
  align-items: flex-start;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
`;
