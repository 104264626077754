import axios from "axios";

import { convertToNumberTypeLatLng } from "@/utils";
import type { LatLng, OrsRes } from "@/types";

import { ax } from "apis/axios";
import type {
  GetAllocationDriverServerModel,
  GetAllocationTruckAllQueryModel,
  GetAllocationTruckAllServerModel,
  GetAllocationDriverDetailServerModel,
  CreateAllocationQueryModel,
  UpdateAllocationQueryModel,
  GetAllocationDriversQueryModel,
  GetAllocationDetailServerModel,
  GetDriversForAllocationEditServerModel,
  GetDriversForAllocationEditQueryModel,
  GetDeliverysQueryModel,
  PostAllocationOptimizingQueryModel,
  PostAllocationOptimizingServerModel,
  GetAllocationDeliveryServerModel,
} from "types";

export const postAllocationAPI = async (req: CreateAllocationQueryModel) => {
  const { data: allocationId } = await ax.post<string>(
    `/allocation/${req.driverId}`,
    req.body,
  );

  return allocationId;
};

export const getAllocationDriversAPI = async (
  req: GetAllocationDriversQueryModel,
) => {
  const { data } = await ax.get<GetAllocationDriverServerModel>(
    "/allocation/request",
    { params: req.query },
  );

  return data;
};

export const getAllocationDriverDetailAPI = async (driverId: string) => {
  const { data } = await ax.get<GetAllocationDriverDetailServerModel>(
    `/allocation/request/${driverId}`,
  );

  return data;
};

export const getAllocationTruckAllAPI = async (
  req?: GetAllocationTruckAllQueryModel,
) => {
  const { data } = await ax.get<GetAllocationTruckAllServerModel>(
    "/allocation/truck",
    { params: req?.query },
  );

  return data;
};

export const getAllocationDeliveriesAPI = async (
  req: GetDeliverysQueryModel,
) => {
  const { data } = await ax.get<GetAllocationDeliveryServerModel>(
    "/allocation/delivery",
    { params: req.query },
  );

  return data;
};

export const putAllocationAPI = (req: UpdateAllocationQueryModel) => {
  return ax.put(`/allocation/${req.allocationId}`, req.body);
};

export const cancelAllocationAPI = (allocationId: string) => {
  return ax.put(`/allocation/${allocationId}/cancel`);
};

export const getDriversForAllocationEditAPI = async (
  params?: GetDriversForAllocationEditQueryModel,
) => {
  const { data } = await ax.get<GetDriversForAllocationEditServerModel>(
    "/allocation/driver",
    { params },
  );

  return data;
};

export const getAllocationDetailAPI = async (allocationId: string) => {
  const { data } = await ax.get<GetAllocationDetailServerModel>(
    `/allocation/detail/${allocationId}`,
  );

  return data;
};

export const postAllocationOrsAPI = async (latLngs: LatLng[]) => {
  const coordinates = latLngs.map((coord) => {
    const { lat, lng } = convertToNumberTypeLatLng(coord);
    return [lng, lat];
  });

  const { data } = await axios.post<OrsRes>(
    `${process.env.REACT_APP_ORS_BASE_URL}/ors/v2/directions/driving-car/geojson`,
    { coordinates },
  );

  return data;
};

export const postAllocationOptimizingAPI = async (
  req: PostAllocationOptimizingQueryModel,
) => {
  const { data } = await axios.post<PostAllocationOptimizingServerModel>(
    `${process.env.REACT_APP_ALLOCATION_OPTIMIZING_BASE_URL}/allocation/optimizing`,
    req.body,
  );

  return data;
};
