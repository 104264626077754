import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Profile = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const profileImg = css`
  width: 40px;
  height: 40px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
`;

export const name = (theme: Theme) => css`
  ${theme.font.medium_14};
  color: ${theme.color.black};
  text-decoration: none;

  &&:hover {
    color: ${theme.color.black};
  }
`;

export const Name = styled.div`
  ${({ theme }) => css`
    ${name(theme)};
  `}
`;

export const Phone = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;
