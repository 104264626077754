import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

import { mixin_scrollbar } from "@/styles";

import { QUOTATION_SEND_TO_SHIPPER_TRUCKLIST_TABLE_CELL_SIZES } from "assets";

export const detailModal = (theme: Theme) => css`
  width: 684px;
  max-height: 700px;
  padding: 0px;

  & > div:first-of-type {
    margin: 0;
    padding: 24px 32px 12px 32px;

    & > button {
      top: 24px;
      right: 32px;
    }
  }

  & > div:nth-of-type(2) {
    ${mixin_scrollbar};
    max-height: 560px;
    padding: 24px 32px;
  }

  & > div:last-of-type {
    padding: 16px 32px 24px 32px;
    box-shadow: ${theme.boxShadow.shadow_modal_scroll};
  }
`;

export const labelContent = (theme: Theme) => css`
  margin-bottom: 24px;

  & > div > label {
    ${theme.font.medium_14};
  }

  & > span,
  & > div:not(first-of-type) {
    ${theme.font.regular_14};
  }
`;

export const verticalLabelContent = css`
  row-gap: 10px;
  margin-bottom: 24px;
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px 0 0;
`;

export const PaymentMethodText = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
  `}
`;

export const table = (rowCount: number) => css`
  tr {
    grid-template-columns: ${QUOTATION_SEND_TO_SHIPPER_TRUCKLIST_TABLE_CELL_SIZES};

    &:nth-of-type(${rowCount}) {
      border-bottom: ${rowCount >= 5 && "0"};
    }
  }
`;

export const tbody = (isEmpty: boolean) => css`
  ${mixin_scrollbar};
  // NOTE: 데이터가 없을 때 해당을 추가하지 않으면 NoData가 깨짐
  display: ${isEmpty && "block"};
  height: 204px;
`;

export const PriceInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
