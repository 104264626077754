import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATH } from "@/assets";
import { useFilterParams } from "@/hooks";

import { AllocationListSection } from "components";
import { useGetPropertyDirverAllocation } from "services";
import { DRIVER_ORDER_ALLOCATION_TABLE_COLUMNS } from "assets";
import type { GetDriverAllocationQueryModel } from "types";
import * as S from "./DriverAllocation.styled";

const DriverAllocation = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { filters } = useFilterParams<GetDriverAllocationQueryModel["query"]>(
    ["page", "pageSize"],
    { pageSize: "20" },
  );

  const req = {
    driverId: id!,
    query: filters,
  };

  const {
    data: driverAllocation,
    dataUpdatedAt,
    refetch,
  } = useGetPropertyDirverAllocation(req);

  return (
    <S.Root>
      <h3 className="a11y">driver allocation</h3>
      <S.TitleWrapper>
        <S.CustomPageBackHeader
          title={t("Allocation list")}
          path={`${PATH.PROPERTY}/driver/${id}`}
        />
        <span>({driverAllocation?.driverName})</span>
      </S.TitleWrapper>
      <AllocationListSection
        columnArr={DRIVER_ORDER_ALLOCATION_TABLE_COLUMNS}
        dataArr={driverAllocation?.order}
        dataUpdatedAt={dataUpdatedAt}
        pageInfo={driverAllocation?.pageInfo}
        refetch={refetch}
      />
      <S.PaginationWrapper>
        <S.CustomPagination pageInfo={driverAllocation?.pageInfo} />
      </S.PaginationWrapper>
    </S.Root>
  );
};

export default DriverAllocation;
