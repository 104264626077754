import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const tabs = css`
  display: flex;
  flex-direction: column;
`;

export const TabTitle = styled.div<{ hasMargin: boolean }>`
  ${({ hasMargin }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${hasMargin ? "20px" : "40px"};
  `}
`;
