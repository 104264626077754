import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Filter = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid ${theme.color.gray_20};
    border-bottom: 0;
  `}
`;

export const SearchLabelBoxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid ${theme.color.gray_20};
    background-color: ${theme.color.white};
  `}
`;

export const SearchLabelWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;
  row-gap: 12px;
  max-width: fit-content;
`;

export const ResetBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_14};

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 73px;
    height: 32px;
    margin-right: 33px;
    padding: 5px 8px;
    color: ${theme.color.gray_60};

    ::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      border-right: 1px solid ${theme.color.gray_20};
    }
  `}
`;

export const resetIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_70};
  }
`;

export const SearchLabel = styled.li`
  ${({ theme }) => css`
    position: relative;
    max-width: 300px;
    height: 32px;
    padding: 5px 32px 5px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: ${theme.color.gray_10};
  `}
`;

export const SearchName = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    margin-right: 4px;
    color: ${theme.color.black};
  `}
`;

export const SearchValue = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const CloseBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    margin-left: 8px;

    svg path {
      fill: ${theme.color.gray_50};
    }
  `}
`;

interface RowProps {
  partition?: number;
}

export const Row = styled.div<RowProps>`
  ${({ theme, partition }) => css`
    display: grid;
    grid-template-columns: repeat(${partition}, 1fr);
    border-bottom: 1px solid ${theme.color.gray_20};
    background-color: ${theme.color.white};
  `}
`;

export const ConditionWrapper = styled.div`
  display: grid;
  grid-template-columns: 210px 1fr;
  height: 48px;
`;

interface ConditionNameProps {
  isConditionFocus: boolean;
  isConditionError: boolean;
}

export const ConditionName = styled.div<ConditionNameProps>`
  ${({ theme, isConditionFocus, isConditionError }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    height: fit-content;
    border-bottom: ${isConditionError
      ? `1px solid ${theme.color.red_10}`
      : isConditionFocus && `1px solid ${theme.color.primary_40}`};
    padding: 14px 20px;
    color: ${isConditionFocus ? theme.color.primary_40 : theme.color.gray_70};
    background-color: ${isConditionError
      ? theme.color.red_10
      : isConditionFocus
      ? theme.color.primary_10
      : theme.color.gray_10};
  `}
`;

interface ConditionProps {
  isConditionFocus: boolean;
  isConditionError: boolean;
}

export const Condition = styled.div<ConditionProps>`
  ${({ theme, isConditionFocus, isConditionError }) => css`
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: ${isConditionError
      ? `1px solid ${theme.color.red_10}`
      : isConditionFocus && `1px solid ${theme.color.primary_40}`};

    svg > g > path {
      fill: ${isConditionFocus && theme.color.primary_40};
    }
  `}
`;

export const Required = styled.span`
  ${({ theme }) => css`
    margin-left: 4px;
    color: ${theme.color.red_30};
  `}
`;

export const InputWrapper = styled.form`
  position: relative;
  width: 100%;
  padding: 14px 20px;
`;

export const input = css`
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0 100px 0 0;

  :focus {
    border: 0;
  }
`;

export const InputApplyBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 28px;
    border-radius: 4px;
    color: ${theme.color.primary_40};
    background-color: ${theme.color.gray_10};
  `}
`;

export const RadioWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 15px;
  max-width: fit-content;
  padding: 14px 20px;
`;

interface RadioBtnProps {
  radioKey: string;
}

export const RadioBtn = styled.li<RadioBtnProps>`
  ${({ theme, radioKey }) => css`
    ${theme.font.regular_14};

    label {
      border-radius: 2px;
      padding: 8px 12px;
      color: ${theme.color.gray_50};

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      :hover {
        color: ${theme.color.primary_40};
        cursor: pointer;
      }
    }

    input {
      display: none;
    }

    input:checked + label {
      color: ${radioKey === "all" ? theme.color.gray_60 : theme.color.white};
      background-color: ${radioKey === "all"
        ? theme.color.gray_10
        : theme.color.primary_40};
    }
  `}
`;

export const Dropdown = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface OpenBtnProps {
  isOpenDropdown: boolean;
}

export const OpenBtn = styled.button<OpenBtnProps>`
  ${({ theme, isOpenDropdown }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 14px 20px;

    & > svg {
      transform: ${isOpenDropdown && "rotate(180deg)"};

      & > path {
        fill: ${theme.color.gray_80};
      }
    }

    &[disabled] {
      border-left: 1px solid ${theme.color.gray_35};
      background-color: ${theme.color.gray_10};

      svg path {
        fill: ${theme.color.gray_50};
      }
    }
  `}
`;

interface SelectDropdown {
  isSelected: boolean;
}

export const SelectDropdown = styled.div<SelectDropdown>`
  ${({ theme, isSelected }) => css`
    color: ${isSelected ? theme.color.black : theme.color.gray_50};
  `}
`;

export const DropdownsWrapper = styled.ul`
  ${({ theme }) => css`
    position: absolute;
    top: 52px;
    width: 100%;
    max-height: 402px;
    border: 1px solid ${theme.color.gray_30};
    border-bottom: 0;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_dropdown};
    overflow: auto;
    z-index: ${theme.zIndex.DIALOG};
  `}
`;

interface DropdownItemProps {
  isSelected: boolean;
}

export const DropdownItem = styled.button<DropdownItemProps>`
  ${({ theme, isSelected }) => css`
    ${theme.font.regular_14};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid ${theme.color.gray_30};
    color: ${isSelected ? theme.color.primary_40 : theme.color.gray_60};

    :hover {
      color: ${isSelected ? theme.color.primary_40 : theme.color.gray_70};
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const calendar = css`
  width: 100%;

  & > input {
    border: 0;
    padding-left: 20px;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;

export const SelectModalData = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const ModalBtn = styled.button`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 26px;
    border-radius: 4px;
    color: ${theme.color.primary_40};
    background-color: ${theme.color.gray_10};
  `}
`;

export const checkerIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.primary_40};
  }
`;
