import React from "react";

import { CompanyLicenseRegistrationLayout } from "components";
import ForwarderCarrierCompanyLicenseRegistrationForm from "./form/ForwarderCarrierCompanyLicenseRegistrationForm";

const ForwarderCarrierCompanyLicenseRegistration = () => {
  return (
    <CompanyLicenseRegistrationLayout
      licenseRegistrationForm={
        <ForwarderCarrierCompanyLicenseRegistrationForm />
      }
    />
  );
};

export default ForwarderCarrierCompanyLicenseRegistration;
