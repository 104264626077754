import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useModal, useToast } from "@/hooks";
import { COMMON_VALID_MSG } from "@/constants";
import { makeCryptoFunction, checkPasswordValidation } from "@/utils";

import { useChangeDriverPassword } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";

interface Form {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .min(8, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .max(16, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .test("currentPassword", CARRIER_VALID_MSG.PASSWORD_STEP_TWO, (value) =>
        checkPasswordValidation(value),
      ),
    newPassword: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .min(8, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .max(16, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .test("newPassword", CARRIER_VALID_MSG.PASSWORD_STEP_TWO, (value) =>
        checkPasswordValidation(value),
      ),
    confirmPassword: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .min(8, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .max(16, CARRIER_VALID_MSG.PASSWORD_STEP_ONE)
      .test("passwordStepTwo", CARRIER_VALID_MSG.PASSWORD_STEP_TWO, (value) =>
        checkPasswordValidation(value),
      )
      .test(
        "passwordConfirm",
        CARRIER_VALID_MSG.PASSWORD_CONFIRM,
        function (value) {
          return value === this.resolve(yup.ref("newPassword")); // NOTE: this를 사용하려면 화살표 함수를 사용할 수 없음
        },
      ),
  })
  .required();

const useUpdatePassword = () => {
  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<Form>({
    mode: "onTouched",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const { id } = useParams();

  const { mutate: changeDriverPasswordMutate } = useChangeDriverPassword();

  const { addToast } = useToast();
  const { handleModalClose } = useModal();

  const handleChangePasswordSubmit = handleSubmit(() => {
    const req = {
      driverId: id!,
      body: {
        currentPassword: makeCryptoFunction(watch("currentPassword")),
        newPassword: makeCryptoFunction(watch("newPassword")),
      },
    };

    changeDriverPasswordMutate(req, {
      onSuccess: () => {
        addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_PASSWORD_CHANGE_DONE);
        handleModalClose();
      },
      onError: (err) => {
        if (err.response?.data.response === "WRONG_PASSWORD") {
          setError("currentPassword", {
            type: "validate",
            message: CARRIER_VALID_MSG.MISMATCH_CURRENT_PASSWORD,
          });
        } else {
          addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        }
      },
    });
  });

  return {
    errors,
    register,
    watch,
    handleChangePasswordSubmit,
  };
};

export default useUpdatePassword;
