import React from "react";
import { useSearchParams } from "react-router-dom";

import { addrFormatter, filterValidCoords } from "@/utils";

import { Foldable2DepthLayout, OrderDetail } from "components";
import { useGetMonitoringOrderDetail } from "services";
import { MONITORING_QUERY_PARAMS } from "assets";

const CarrierOrderMap = () => {
  const [searchParams] = useSearchParams();

  const { isLoading, data: monitoringOrderDetailData } =
    useGetMonitoringOrderDetail(
      searchParams.get(MONITORING_QUERY_PARAMS.ORDER_ID) as string,
    );

  if (!monitoringOrderDetailData) return null;

  return (
    <Foldable2DepthLayout>
      <OrderDetail
        isLoading={isLoading}
        orderCard={{
          orderNumber: monitoringOrderDetailData.info.orderNumber,
          name: monitoringOrderDetailData.info.name,
          phone: monitoringOrderDetailData.info.phone,
          puEta: monitoringOrderDetailData.info.puEta,
          status: monitoringOrderDetailData.info.status,
        }}
        allocations={monitoringOrderDetailData.allocations.map(
          ({ driverInfo, deliveries: [pickUp, dropOff], coord }) => ({
            driverInfo: {
              profile: driverInfo.profile as string,
              name: driverInfo.name,
              truckTypeEn: driverInfo.truckTypeEn,
              truckTypeVi: driverInfo.truckTypeVi,
              registNumber: driverInfo.registNumber,
              phone: driverInfo.phone,
            },
            gpsAvailable: driverInfo.gpsAvailable,
            deliveries: [
              {
                lat: pickUp.lat,
                lng: pickUp.lng,
                type: "PICK",
                addr: addrFormatter(pickUp.addr, pickUp.addrDetail),
                completed: pickUp.completed,
              },
              {
                lat: dropOff.lat,
                lng: dropOff.lng,
                type: "DROP",
                addr: addrFormatter(dropOff.addr, dropOff.addrDetail),
                completed: dropOff.completed,
              },
            ],
            coords: filterValidCoords(coord),
          }),
        )}
        googleMapCenterLatLng={{
          pickUp: {
            lat: +monitoringOrderDetailData.info.puLat,
            lng: +monitoringOrderDetailData.info.puLng,
            type: "PICK",
          },
          dropOff: {
            lat: +monitoringOrderDetailData.info.doLat,
            lng: +monitoringOrderDetailData.info.doLng,
            type: "DROP",
          },
        }}
      />
    </Foldable2DepthLayout>
  );
};

export default CarrierOrderMap;
