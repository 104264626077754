/** @jsxImportSource @emotion/react */ // FIXME: 안 붙

import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";
import { PATH } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./SettingLayout.styled";

interface SettingLayoutProps {
  settingMenu: Record<
    string,
    readonly { readonly label: string; readonly path: string }[]
  >;
  children: React.ReactNode;
}

const SettingLayout = ({ settingMenu, children }: SettingLayoutProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { auth } = useAuth();

  useEffect(() => {
    if (pathname !== PATH.SETTINGS) return;

    navigate(PATH.SETTINGS_ACCOUNT, { replace: true });
  }, [pathname]);

  return (
    <S.Layout>
      <nav>
        <S.Title>
          {t("Settings")}
          <span>{t("Manage your COCOTRUCK account.")}</span>
        </S.Title>
        <S.MenuList>
          {auth.grade &&
            settingMenu[auth.grade].map((menu) => (
              <S.MenuItem key={menu.label}>
                <NavLink css={S.navLink} to={menu.path}>
                  {t(menu.label as Languages)}
                </NavLink>
              </S.MenuItem>
            ))}
        </S.MenuList>
      </nav>
      {children}
    </S.Layout>
  );
};

export default SettingLayout;
