import React from "react";
import { useSearchParams } from "react-router-dom";

import { NoResult, QueryPagination } from "@/components";
import { addrFormatter, formatICTDateTime, formatPhoneNum } from "@/utils";

import {
  BtnFoldOrderNumber,
  DriverInfo,
  OrderAllocationInfo,
  TruckingCompanyInfo,
} from "components";
import { useGetShipperMonitoringOrderDetail } from "services";
import { foldCardAnimate } from "utils";
import type { GetOrderManagementShippingServerModel } from "types";
import { useOrderSelect } from "../../hooks";
import * as S from "./MonitoringShippingList.styled";

interface OrderMonitoringShippingListProps {
  orders: GetOrderManagementShippingServerModel;
}

const OrderMonitoringShippingList = ({
  orders,
}: OrderMonitoringShippingListProps) => {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");

  const { data: orderDetail } = useGetShipperMonitoringOrderDetail(orderId!, {
    suspense: false,
    enabled: !!orderId,
  });

  const { handleOrderCardClick } = useOrderSelect();

  return (
    <>
      {orders.order.length ? (
        <>
          <S.MonitoringShippingListWrapper>
            {orders.order.map((order) => {
              const isFold = order.orderId !== orderId;

              return (
                <li key={order.orderId}>
                  <BtnFoldOrderNumber
                    isFold={isFold}
                    orderNumber={order.orderNumber}
                    handleFoldBtnClick={handleOrderCardClick(order.orderId)}
                  />
                  {!isFold && orderDetail && (
                    <S.TruckDetailInfoWrapper
                      layout
                      initial="closed"
                      animate={isFold ? "closed" : "open"}
                      exit="closed"
                      variants={foldCardAnimate(
                        searchParams.get("type") === "card",
                      )}
                      transition={{ duration: 0.3 }}
                    >
                      <TruckingCompanyInfo
                        orderInfo={{
                          cargoes: orderDetail.cargoes,
                          puAddr: addrFormatter(
                            orderDetail.puAddr,
                            orderDetail.puAddrDetail,
                          ),
                          doAddr: addrFormatter(
                            orderDetail.doAddr,
                            orderDetail.doAddrDetail,
                          ),
                          loadingStatus: orderDetail.loadingStatus,
                        }}
                      />
                      <DriverInfo
                        driverInfo={{
                          title: "Trucking company",
                          name: orderDetail.truckingCompany ?? "-",
                          phone: formatPhoneNum(
                            orderDetail.truckingCompanyPhone,
                          ),
                          addr: orderDetail.puEta
                            ? `(ETA) ${formatICTDateTime(orderDetail.puEta)}`
                            : "-",
                        }}
                      />
                      <OrderAllocationInfo orderDetail={orderDetail} />
                    </S.TruckDetailInfoWrapper>
                  )}
                </li>
              );
            })}
          </S.MonitoringShippingListWrapper>
          <QueryPagination css={S.pagination} pageInfo={orders.pageInfo} />
        </>
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default OrderMonitoringShippingList;
