import * as yup from "yup";

import { calcTableWidth } from "@/utils";
import { CELL, PATH } from "@/assets";
import { COMMON_VALID_MSG, MEMBER_GRADE } from "@/constants";

import type { CompanyType } from "types";

export const SETTING_MENU = {
  [MEMBER_GRADE.moderator]: [
    { label: "My account", path: PATH.SETTINGS_ACCOUNT },
    { label: "Company information", path: PATH.SETTINGS_COMPANY },
    { label: "Notice", path: PATH.SETTINGS_NOTICE },
    { label: "Support", path: PATH.SETTINGS_SUPPORT },
    // { label: "Plan", path: PATH.SETTINGS_PLAN }, NOTE: Plan기능 개발시 주석해제 필요
  ],
  [MEMBER_GRADE.manager]: [
    { label: "My account", path: PATH.SETTINGS_ACCOUNT },
    { label: "Company information", path: PATH.SETTINGS_COMPANY },
    { label: "Member management", path: PATH.SETTINGS_MEMBER },
    { label: "Notice", path: PATH.SETTINGS_NOTICE },
    { label: "Support", path: PATH.SETTINGS_SUPPORT },
    // { label: "Plan", path: PATH.SETTINGS_PLAN }, NOTE: Plan기능 개발시 주석해제 필요
  ],
} as const;

export const INQUIRY_CATEGORY_OPTIONS = [
  { key: "ORDER", label: "Order" },
  { key: "SERVICE", label: "Service" },
  { key: "ERROR", label: "Error" },
  { key: "DELETE_ACCOUNT", label: "Delete account" },
  { key: "OTHER", label: "Other" },
] as const;

export const SETTING_COMPANY_PLAN_TABLE_CELL_SIZES = calcTableWidth([
  "128px",
  "128px",
  "128px",
  ["295px", "auto"],
  "180px",
  "130px",
]);

export const SETTING_NOTICE_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  ["842px", "auto"],
]);

export const SETTING_MEMBER_TABLE_CELL_SIZES = calcTableWidth([
  "260px",
  "200px",
  ["260px", "auto"],
  "206px",
  "64px",
]);

export const SETTING_SUPPORT_TABLE_CELL_SIZES = calcTableWidth([
  "150px",
  "150px",
  ["541px", "auto"],
  "150px",
]);

export const COMPANY_PLAN_TABLE_COLUMNS = [
  [CELL.PLANE_TYPE],
  [CELL.STARTDATE],
  [CELL.ENDDATE],
  [CELL.TOTAL],
  [CELL.PAYMENTMETHOD_PAY],
  [CELL.STATUS],
] as const;

export const MEMBER_TABLE_COLUMNS = [
  [CELL.MEMBERNAME_NAME],
  [CELL.PHONENUMBER_PHONE],
  [CELL.LOGINID_EMAIL],
  [CELL.GRADE],
  [CELL.EMPTY_UTILS],
] as const;

export const SUPPORT_TABLE_COLUMNS = [
  [CELL.CATEGORY, CELL.INQUIRYDATE_WRITTEN, CELL.TITLE, CELL.STATUS],
] as const;

export const NOTICE_TABLE_COLUMNS = [
  [CELL.CREATEDON_CREATED],
  [CELL.SUBJECTS_TITLE],
] as const;

export const licenseRegistrationSchema = yup.object().shape({
  clientType: yup
    .string()
    .required()
    .oneOf(["carrier", "forwarder", "forwardercarrier", "shipper"])
    .nullable(),
  newLicense1: yup.mixed<FileList>().required().nullable(),
  newLicense2: yup.mixed<FileList>().required().nullable(),
  newTransportLicense1: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .when("clientType", {
      is: (value: CompanyType) =>
        value === "carrier" || value === "forwardercarrier",
      then: () =>
        yup
          .mixed<FileList>()
          .required()
          .test({
            name: "required",
            test: (value) => value.length > 0,
            message: COMMON_VALID_MSG.REQUIRED,
          }),
      otherwise: () => yup.mixed<FileList>().nullable(),
    }),
  newTransportLicense2: yup
    .mixed<FileList>()
    .required()
    .nullable()
    .when("clientType", {
      is: (value: CompanyType) =>
        value === "carrier" || value === "forwardercarrier",
      then: () =>
        yup
          .mixed<FileList>()
          .required()
          .test({
            name: "required",
            test: (value) => value.length > 0,
            message: COMMON_VALID_MSG.REQUIRED,
          }),
      otherwise: () => yup.mixed<FileList>().nullable(),
    }),
});

export const COMPANY_LICENSE_FORM = {
  clientType: null,
  newTransportLicense1: null,
  newTransportLicense2: null,
  newLicense1: null,
  newLicense2: null,
};
