import React from "react";
import { useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  DialogBtn,
  DownloadBtn,
  ErrMsg,
  LabelContentTable,
  LinkMenuDialog,
  Profile,
} from "@/components";
import {
  formatICTDateTime,
  formatPhoneNum,
  makeNameString,
  upperCaseToupperFirst,
} from "@/utils";
import { MoreIcon } from "@/assets";
import type { Languages } from "@/types";

import { TruckLinkAutoComplete } from "components";
import { useDownloadFile } from "services";
import type {
  PropertyDetailEditCardType,
  DriverDefaultInfoForm,
  DriverSelectType,
  GetPropertyDriverDetailClientModel,
} from "types";
import * as S from "./DriverDefaultInfoField.styled";

interface DefaultInfoFieldProps {
  data: GetPropertyDriverDetailClientModel["defaultInfo"];
  handleChangeDefaultInfo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurlinkedRegistNumber: () => void;
  handleSelectLink: ({ registNumber, truckId }: DriverSelectType) => void;
  handleUpdate: () => void;
  handleUnlinkTruck: () => void;
  handleClickCancel: () => void;
  editState: PropertyDetailEditCardType | null;
  handleClickEdit: (
    editName: PropertyDetailEditCardType,
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DefaultInfoField = ({
  data,
  handleChangeDefaultInfo,
  handleBlurlinkedRegistNumber,
  handleSelectLink,
  handleUpdate,
  handleUnlinkTruck,
  handleClickCancel,
  handleClickEdit,
  editState,
}: DefaultInfoFieldProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<DriverDefaultInfoForm>();

  const handleUpdateLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleUpdate();
  };

  return (
    <LabelContentTable variant="underline" css={S.labelContentTable}>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Driver">
          <S.ProfileWrapper>
            <Profile css={S.profile} src={data?.profileUrl ?? ""} />
            <S.DriverInfoWrapper>
              <span>
                {makeNameString(
                  watch("lastName"),
                  watch("middleName"),
                  watch("firstName"),
                )}
              </span>
              <S.DriverPhone>{formatPhoneNum(watch("phone"))}</S.DriverPhone>
            </S.DriverInfoWrapper>
          </S.ProfileWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Status">
          {t(upperCaseToupperFirst(watch("status")) as Languages)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Created date">
          {watch("created")
            ? formatICTDateTime(watch("created"), "DD/MM/YYYY")
            : "-"}
          {/* //TODO: 서버 데이터가 넘어오지 않아서 임의 "-"처리 */}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Linking">
          {editState === "DefaultInfo-link" ? (
            <S.LinkWrapper>
              <S.LinkContentWrapper>
                <TruckLinkAutoComplete
                  id="linkedRegistNumber"
                  name="linkedRegistNumber"
                  hasError={!!errors.linkedRegistNumber?.message}
                  driverId={searchParams.get("id") ?? ""}
                  value={watch("linkedRegistNumber")}
                  placeholder="Search by plate number"
                  maxLength={100}
                  onSelect={handleSelectLink}
                  register={register("linkedRegistNumber", {
                    onChange: handleChangeDefaultInfo,
                    onBlur: handleBlurlinkedRegistNumber,
                  })}
                />
                {errors.linkedRegistNumber?.message && (
                  <ErrMsg>
                    {errors.linkedRegistNumber?.message as Languages}
                  </ErrMsg>
                )}
              </S.LinkContentWrapper>
              <S.BtnWrapper>
                <Button
                  variant="ghostNoUnderlineGray"
                  label="Cancel"
                  handleClickBtn={handleClickCancel}
                />
                <Button
                  variant="ghostNoUnderlinePrimary"
                  isDisabled={!!errors.linkedRegistNumber?.message}
                  label="Update"
                  handleClickBtn={handleUpdateLink}
                />
              </S.BtnWrapper>
            </S.LinkWrapper>
          ) : watch("linkedRegistNumber") ? (
            <S.LinkContainer>
              <span>{watch("linkedRegistNumber")}</span>
              <DialogBtn
                css={S.moreDialogBtn}
                popup={() => (
                  <LinkMenuDialog
                    handleClickEdit={handleClickEdit("DefaultInfo-link")}
                    handleUnlink={handleUnlinkTruck}
                  />
                )}
              >
                <MoreIcon />
              </DialogBtn>
            </S.LinkContainer>
          ) : (
            <Button
              variant="ghostNoUnderlinePrimary"
              label={`+ ${t("Add a link")}` as Languages}
              handleClickBtn={handleClickEdit("DefaultInfo-link")}
            />
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Memo">
          {watch("memo") || "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Attached file">
          {data.license ? (
            <DownloadBtn
              downloadFileNamePrefix="Driver's license"
              fileName="Driver’s license"
              fileKey={data.license}
              isLoading={isLoading}
              downloadFileMutate={downloadFileMutate}
            />
          ) : (
            <span>-</span>
          )}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleClickEdit("DefaultInfo")}
        />
      </S.BtnWrapper>
    </LabelContentTable>
  );
};

export default DefaultInfoField;
