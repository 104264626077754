import React, { useState, useEffect } from "react";

import { PersonIcon } from "@/assets";
import PersonGraySvg from "@/assets/icon/ic_personGray.svg";

import * as S from "./Profile.styled";

interface ProfileProps {
  className?: string;
  src?: any;
}

const Profile = ({ className, src }: ProfileProps) => {
  const [isSrcErr, setIsSrcErr] = useState(!!src);

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e.target instanceof HTMLImageElement) {
      e.target.src = PersonGraySvg;
      setIsSrcErr(false);
    }
  };

  useEffect(() => {
    if (src) {
      setIsSrcErr(true);
    }
  }, [src]);

  return (
    <S.Root className={className} data-status={isSrcErr}>
      {src ? (
        <img src={src} onError={handleImgError} alt="profile" />
      ) : (
        <PersonIcon />
      )}
    </S.Root>
  );
};

export default Profile;
