import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TruckingCompanySearchModal } from "@/components";
import { useModal } from "@/hooks";
import { PlusCircleIcon12, TRUCKING_COMPANY_COLUMNS } from "@/assets";
import type { CompanyInfo } from "@/types";

import {
  QuotationDefaultInformation,
  QuotationTable,
  QuotationPayment,
  QuotationCargoDetails,
  QuotationDropOffInformation,
  QuotationPickUpInformation,
  QuotationTruckingMethods,
  QuotationCancelOrderModal,
  QuotationDetailLayout,
  QuotationOfferPrice,
  QuotationTruckForm,
  QuotationTruckList,
} from "components";
import { useGetQuotationDetail, usePostQuotationRequest } from "services";
import useQuotationDetail from "./hooks/useQuotationDetail";
import * as S from "./QuotationDetail.styled";

const ForwarderCarrierQuotationDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetQuotationDetail<"forwardercarrier">({
    suspense: false,
    quotationId: id!,
  });

  const { mutate, isLoading: isQuotationSendLoading } =
    usePostQuotationRequest("forwardercarrier");

  const {
    formMethod,
    selectedCompany,
    carrierLinkPlayers,
    makeCompanyRequestMutateFormBody,
    companyRequestMutateOption,
    handleRejectModalOpen,
    handleSubmit,
  } = useQuotationDetail(data);
  const { modalRef, handleModalOpen } = useModal();

  const handleCompanySelect = (companyInfo: CompanyInfo[]): void => {
    mutate(
      makeCompanyRequestMutateFormBody("forwardercarrier", companyInfo),
      companyRequestMutateOption("forwarder"),
    );
  };

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const CONTENTS = {
    DEFAULT_INFORMATION: {
      heading: { text: "Default information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDefaultInformation data={data} />
        </Suspense>
      ),
    },
    PICKUP_INFORMATION: {
      heading: { text: "Pick-up information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationPickUpInformation data={data} />
        </Suspense>
      ),
    },
    DROPOFF_INFORMATION: {
      heading: { text: "Drop-off information" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationDropOffInformation data={data} />
        </Suspense>
      ),
    },
    CARGO_DETAILS: {
      heading: { text: `${t("Cargo details")} (${data.cargoes.length})` },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationCargoDetails data={data} />
        </Suspense>
      ),
    },
    TRUCKING_METHODS: {
      heading: { text: "Trucking methods" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTruckingMethods data={data} />
        </Suspense>
      ),
    },
    PAYMENT: {
      heading: { text: "Payment" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationPayment
            data={data}
            handleReject={handleRejectModalOpen}
            handleCheckValidForm={handleSubmit}
          />
        </Suspense>
      ),
    },
    OFFER_PRICE: {
      heading: { text: "Offer price" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationOfferPrice
            data={data}
            handleReject={handleRejectModalOpen}
            handleCheckValidForm={handleSubmit}
          />
        </Suspense>
      ),
    },
    TRUCK_FORM: {
      heading: { text: "Trucks" },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTruckForm />
        </Suspense>
      ),
    },
    TRUCK_LIST: {
      heading: {
        text: `${t("Truck list")} (${
          data.trucks
            ? Object.keys(data.trucks).length
            : data.history.length && !!data.history[0].trucks
            ? Object.keys(data.history[0].trucks).length
            : 0
        })`,
      },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTruckList
            trucks={data.history.length && data.history[0].trucks}
          />
        </Suspense>
      ),
    },
    QUOTATION_TABLE: {
      heading: {
        addHeadingElement: !data.defaultInfo.isSelfTransport &&
          data.defaultInfo.offerStatus === "FORWARDING" &&
          data.defaultInfo.pageStatus === "ONGOING" && (
            <S.AddQuotation
              type="button"
              onClick={handleModalOpen(
                carrierLinkPlayers && (
                  <TruckingCompanySearchModal
                    ref={modalRef}
                    isLoading={isQuotationSendLoading}
                    columns={TRUCKING_COMPANY_COLUMNS}
                    selectedCompany={selectedCompany}
                    tableDatas={carrierLinkPlayers}
                    handleCompanySelect={handleCompanySelect}
                  />
                ),
              )}
            >
              <PlusCircleIcon12 /> {t("Add quotation")}
            </S.AddQuotation>
          ),
        text: data.defaultInfo.isSelfTransport
          ? t("Quotation")
          : `${t("Quotation")} (${data.history.length})`,
      },
      content: (
        <Suspense fallback={<Fragment />}>
          <QuotationTable data={data} />
        </Suspense>
      ),
    },
  };

  const commonContent = [
    CONTENTS.DEFAULT_INFORMATION,
    CONTENTS.PICKUP_INFORMATION,
    CONTENTS.DROPOFF_INFORMATION,
    CONTENTS.CARGO_DETAILS,
    CONTENTS.TRUCKING_METHODS,
    data.defaultInfo.isSelfTransport &&
    data.defaultInfo.pageStatus === "ONGOING" &&
    data.defaultInfo.offerStatus === "DELIVERED" &&
    data.defaultInfo.type === "SHIPPER_TO_FORWARDER" &&
    data.paymentInfo.price === 0 //TODO: 재견적 요청시 반환값 확인하여 수정 필요(서버 데이터 수정 예정 -> 기존 status 조합하여 사용해야함)
      ? CONTENTS.OFFER_PRICE
      : CONTENTS.PAYMENT,
  ];
  const quotationContents =
    data.defaultInfo.pageStatus === "PENDING"
      ? commonContent
      : data.defaultInfo.isSelfTransport
      ? [
          ...commonContent.slice(0, -1),
          data.defaultInfo.pageStatus === "ONGOING" && //TODO: 재견적 요청시 반환값 확인하여 수정 필요(서버 데이터 수정 예정 -> 기존 status 조합하여 사용해야함)
          data.defaultInfo.offerStatus === "DELIVERED" &&
          data.paymentInfo.price === 0
            ? CONTENTS.TRUCK_FORM
            : CONTENTS.TRUCK_LIST,
          ...commonContent.slice(-1),
        ]
      : [commonContent[0], CONTENTS.QUOTATION_TABLE, ...commonContent.slice(1)];

  return (
    <FormProvider {...formMethod}>
      <QuotationDetailLayout
        contents={quotationContents}
        isShowCancelButton={
          data.defaultInfo.isSelfOrder &&
          (data.defaultInfo.pageStatus === "PENDING" ||
            data.defaultInfo.pageStatus === "ONGOING")
        }
        pageStatus={data.defaultInfo.pageStatus}
        cancelModal={<QuotationCancelOrderModal ref={modalRef} offerId={id!} />}
      />
    </FormProvider>
  );
};

export default ForwarderCarrierQuotationDetail;
