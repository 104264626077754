import React from "react";
import { useParams } from "react-router-dom";

import { PageBackHeader, QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";
import { PATH } from "@/assets";

import { AllocationListSection } from "components";
import { useGetTruckAllocations } from "services";
import { TRUCK_ORDER_ALLOCATION_TABLE_COLUMNS } from "assets";
import type { GetTruckAllocationQueryModel } from "types";
import * as S from "./TruckAllocation.styled";

const TruckAllocation = () => {
  const { id } = useParams();

  const { filters } = useFilterParams<GetTruckAllocationQueryModel["query"]>(
    ["page", "pageSize"],
    { pageSize: "20" },
  );
  const req = {
    truckId: id!,
    query: filters,
  };
  const {
    data: truckAllocations,
    dataUpdatedAt,
    refetch,
  } = useGetTruckAllocations(req);

  return (
    <S.Root>
      <h3 className="a11y">truck allocation</h3>
      <S.TitleWrapper>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Allocation list"
          path={`${PATH.PROPERTY}/truck/${id}`}
        />
        <span>{`(${truckAllocations?.plateName})`}</span>
      </S.TitleWrapper>
      <AllocationListSection
        columnArr={TRUCK_ORDER_ALLOCATION_TABLE_COLUMNS}
        dataArr={truckAllocations?.allocationList}
        dataUpdatedAt={dataUpdatedAt}
        pageInfo={truckAllocations?.pageInfo}
        refetch={refetch}
      />
      <S.PaginationWrapper>
        <QueryPagination
          css={S.queryPagination}
          pageInfo={truckAllocations?.pageInfo}
        />
      </S.PaginationWrapper>
    </S.Root>
  );
};

export default TruckAllocation;
