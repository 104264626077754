import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const DriverWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 12px;
  width: 100%;
`;

export const labelContent = (theme: Theme) => css`
  & > div {
    & > label {
      ${theme.font.medium_14};
    }

    & > div {
      ${theme.font.regular_14};
    }
  }
`;

export const Gray80Text = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_80};
  `}
`;

export const StatusPlateNumber = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;

export const DeliveryLogWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: calc(100% - 12px);
      border-radius: 100px;
      background-color: ${theme.color.gray_30};
    }
  `}
`;

export const logLabelContent = (theme: Theme) => css`
  padding-left: 16px;
  border-radius: 0 100px 100px 0;

  & > div {
    & > label {
      ${theme.font.medium_14};
    }

    & > div {
      ${theme.font.regular_14};
    }
  }
`;

export const CertificationImgWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 20px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;
