import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEqual, isEmpty } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { AVAILABLE_RADIOS } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import {
  useGetPropertyTruckDetail,
  useGetTruckGarage,
  useUpdateTruckDefaultInfo,
} from "services";
import { getTruckGarageData } from "utils";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";
import type {
  GetTruckDetailClientModel,
  PropertyDetailEditCardType,
  TruckDefaultInfoForm,
} from "types";

const schema = yup.object({
  plateNumber: yup.string().required(CARRIER_VALID_MSG.REQUIRED),
  category: yup.mixed<(typeof AVAILABLE_RADIOS)[number]["key"]>().defined(),
  created: yup.string().defined(),
  parkedGarage: yup.mixed<DropdownOptionType>().required(),
  parkedGarageName: yup.string().defined(),
  memo: yup
    .string()
    .optional()
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_MEMO_200,
      (value) => (value?.length ?? 0) <= 200,
    ),
  registration: yup
    .mixed<FileList | string>()
    .required(CARRIER_VALID_MSG.REQUIRED)
    .nullable()
    .test("required", CARRIER_VALID_MSG.REQUIRED, (value) => value !== null),
  truckPhoto: yup.mixed<FileList | string>().defined().nullable(),
});

const TRUCK_FORM_INIT_STATE = {
  plateNumber: "",
  category: "Available" as (typeof AVAILABLE_RADIOS)[number]["key"],
  created: "",
  parkedGarage: { key: "", label: "" as Languages },
  parkedGarageName: "",
  memo: "",
  truckPhoto: null,
  registration: null,
};

const useDefaultInfo = (
  handleEditState: (
    label: PropertyDetailEditCardType | null,
    type?: boolean,
  ) => () => void,
) => {
  const { id } = useParams();

  const formMethod = useForm<TruckDefaultInfoForm>({
    defaultValues: TRUCK_FORM_INIT_STATE,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { data: truckDetailData } = useGetPropertyTruckDetail(id!);
  const { data: truckGarages } = useGetTruckGarage();
  const {
    isLoading: isLoadingUpdateTruckDefaultInfo,
    mutate: updateTruckDefaultInfoMutate,
  } = useUpdateTruckDefaultInfo();

  const { addToast } = useToast();

  const customTruckGarages = truckGarages && [
    ...getTruckGarageData(truckGarages),
  ];

  const updateDefaultInfo = (data: TruckDefaultInfoForm): void => {
    const isCompareDefaultInfoData = (): boolean => {
      if (!truckDetailData) return false;

      const originData = makeInitData(truckDetailData.defaultInfo);
      const currentData = formMethod.getValues();

      return isEqual(originData, currentData);
    };

    if (isCompareDefaultInfoData()) {
      handleEditState(null, true)();
      return;
    }

    const body = {
      registNumber: data.plateNumber,
      status: data.category,
      garageId: data.parkedGarage.key || null,
      memo: data.memo || null,
      // TODO: 서버 로직 수정된 거 확인 후 주석 제거
      ...(typeof data.truckPhoto !== "string" && {
        truckPhoto: data.truckPhoto ? data.truckPhoto[0] : null,
      }),
      // TODO: 서버 로직 수정된 거 확인 후 주석 제거
      ...(typeof data.registration !== "string" && {
        registration: data.registration ? data.registration[0] : null,
      }),
    };

    updateTruckDefaultInfoMutate(
      { truckId: id!, body },
      {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.TRUCK_UPDATE_DONE);
          handleEditState(null, true)();
        },
        onError: (err) => {
          if (err.response?.data.response === "PLATE_NUMBER_EXISTS") {
            formMethod.setError("plateNumber", {
              message: CARRIER_VALID_MSG.PLATE_NUMBER_EXISTS,
            });
            formMethod.setFocus("plateNumber");
            return;
          }
          addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        },
      },
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const id = name.split(".").at(-1);
    const key = name as keyof typeof TRUCK_FORM_INIT_STATE;

    switch (id) {
      case "registration":
      case "truckPhoto": {
        if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
          addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
          formMethod.setError(key, { type: "capacityOver" });
          formMethod.setValue(id, null);
        } else {
          formMethod.clearErrors(key);
        }
        break;
      }
    }
  };

  const makeInitData = (
    originData: GetTruckDetailClientModel["defaultInfo"],
  ) => {
    if (!truckDetailData || !originData || !customTruckGarages) return;

    const selectedParkedGarage = customTruckGarages.find(
      (garage) => garage.label === truckDetailData.defaultInfo.parkedGarage,
    ) ?? { key: "", label: "" };

    return {
      category: originData.category,
      created: originData.created,
      linked: isEmpty(originData.linked)
        ? [{ name: "", driverId: "" }]
        : originData.linked,
      memo: originData.memo ?? "",
      parkedGarage: selectedParkedGarage,
      parkedGarageName: originData.parkedGarage ?? "",
      plateNumber: originData.plateNumber,
      registration: originData.registration,
      truckPhoto: originData.truckPhoto,
    };
  };

  useEffect(() => {
    if (!truckDetailData) return;

    formMethod.reset(makeInitData(truckDetailData.defaultInfo));
  }, [truckDetailData]);

  return {
    formMethod,
    isLoadingUpdateTruckDefaultInfo,
    updateDefaultInfo,
    handleFileChange,
  };
};

export default useDefaultInfo;
