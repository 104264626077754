import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Layout = styled.section`
  width: fit-content;
  padding: 32px 0 0 40px;
`;

export const toc = css`
  margin-left: 139px;

  & > div:last-of-type > section {
    width: 764px;
  }
`;

export const TocHeading = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_18};
  `}
`;

export const backLink = css`
  width: fit-content;
  padding: 8px 16px;
  margin: 32px 0 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  width: fit-content;
  margin: 32px 0 0 auto;
`;

export const previousPageLink = css`
  width: fit-content;
  margin: 32px 0 0 auto;
`;
