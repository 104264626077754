/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const Root = styled.table<{ isStickyThead?: boolean }>`
  ${({ theme, isStickyThead }) => css`
    width: 100%;
    border: 1px solid ${theme.color.gray_35};
    border-top: 0;

    & > thead {
      position: ${isStickyThead && "sticky"};
      top: ${isStickyThead &&
      `calc(${theme.size.HEADER_HEIGHT} + ${theme.size.TABLE_HEADER_HEIGHT} - 8px)`};
      z-index: ${isStickyThead && theme.zIndex.STICKY};

      tr:first-of-type {
        border-top: 1px solid ${theme.color.gray_35};
      }
    }
  `}
`;

export const Trow = styled.tr`
  ${({ theme }) => css`
    position: relative;
    display: grid;

    > th {
      text-align: left;
    }

    td:not(:last-of-type) {
      border-right: 1px solid ${theme.color.gray_30};
    }
  `}
`;

const mixinCell = css`
  display: flex;
  align-items: center;
`;

export const Tbody = styled.tbody`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    background-color: ${theme.color.white};

    & > tr {
      height: 41px;
      border-bottom: 1px solid ${theme.color.gray_30};

      :last-of-type:nth-of-type(20) {
        border-bottom: 0;
      }
    }
  `}
`;

interface ThProps {
  isLast?: boolean;
  hasMultiCol?: boolean;
  columnCnt?: number;
  rowCnt?: number;
}

export const Th = styled.th<ThProps>`
  ${({ hasMultiCol = false, isLast, columnCnt, rowCnt, theme }) => css`
    ${theme.font.medium_13};
    ${mixinCell};
    grid-row: ${rowCnt && `1/${rowCnt}`};
    grid-column: ${columnCnt && `span ${columnCnt}`};
    border-bottom: 1px solid ${theme.color.gray_35};
    padding: ${hasMultiCol ? "5px 12px" : "7px 12px"};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};

    &:not(:last-of-type) {
      border-right: ${!isLast ? `1px solid ${theme.color.gray_35}` : "0"};
    }
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    height: 40px;
    border-right: 0;
    padding: 0 12px;
    line-height: 40px;
    color: ${theme.color.black};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  `}
`;

export const DetailOpenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
`;

export const DetailRow = styled.tr`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.white};
  `}
`;

export const DetailInfoWrapper = styled.td`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    color: ${theme.color.gray_80};
    background-color: ${theme.color.gray_10};
  `}
`;

export const LoadingRow = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit !important;
`;

export const NoData = styled.tr`
  ${({ theme }) => css`
    position: relative;
    height: 100%;

    &:last-of-type {
      border-bottom: 0;
    }

    & > td {
      ${theme.font.regular_14};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      text-align: center;
      color: ${theme.color.gray_70};

      & > button {
        margin: 16px 0 0;
      }
    }
  `}
`;

export const NotSelectRequiredData = styled(NoData)`
  ${({ theme }) => css`
    & > td {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;

      svg path {
        fill: ${theme.color.gray_35};
      }
    }
  `}
`;

interface SelectableRowProps {
  isSelected?: boolean;
}

export const SelectRow = styled(Trow)<SelectableRowProps>`
  ${({ theme, isSelected }) => css`
    background-color: ${isSelected && theme.color.primary_10};

    &:hover {
      background-color: ${theme.color.gray_10};
    }

    td:nth-last-of-type(2) {
      border-right: 0;
    }
  `}
`;

const mixinRowBtn = (theme: Theme) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:focus-visible {
    position: absolute;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndex.TABLE_SELECT_ROW};
  }
`;

export const rowlink = (theme: Theme) => css`
  ${mixinRowBtn(theme)};
`;
export const RowButton = styled.button`
  ${({ theme }) => css`
    ${mixinRowBtn(theme)};
  `}
`;
