import { ax } from "apis/axios";
import type {
  CompanyType,
  GetSettlementQueryModel,
  GetSettlementServerModel,
} from "types";

export const getSettlementsAPI = async (
  req: GetSettlementQueryModel<CompanyType>,
) => {
  const { data } = await ax.get<GetSettlementServerModel>("/settlement", {
    params: req.query,
  });

  return data;
};
