import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts";
import {
  Button,
  ConfirmModal,
  DownloadBtn,
  LabelContentTable,
  StatusLabel,
} from "@/components";
import { useModal } from "@/hooks";
import {
  calculateVatAmount,
  calculateTotalAmount,
  findLookupTableLabel,
  formatPrice,
  makeOrderFileName,
  createAttachFiles,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS } from "@/assets";

import {
  useDownloadFile,
  usePatchSettlementAccept,
  usePatchSettlementReject,
} from "services";
import type { CompanyType, GetSettlementDetailServerModel } from "types";
import * as S from "./SettlementDetailRequestPayment.styled";

interface SettlementDetailRequestPaymentProps {
  data: GetSettlementDetailServerModel<CompanyType>;
}

const SettlementDetailRequestPayment = ({
  data,
}: SettlementDetailRequestPaymentProps) => {
  const { t } = useTranslation();

  const {
    auth: { clientType },
  } = useAuth();

  const { id } = useParams();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();
  const { mutate: patchSettlementAcceptMutate } = usePatchSettlementAccept();
  const { mutate: patchSettlementRejectMutate } = usePatchSettlementReject();

  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  if (!data.requestPayment) return null;

  const vatPrice = formatPrice(
    calculateVatAmount(
      `${data.requestPayment.price}`,
      `${data.requestPayment.vat}`,
    ),
  );
  const vat = `(${data.requestPayment.vat}%) ${vatPrice}`;
  const totalPrice = formatPrice(
    calculateTotalAmount(
      `${data.requestPayment.price}`,
      `${data.requestPayment.vat}`,
    ),
  );
  const payMethodLabel =
    findLookupTableLabel(
      PAYMENT_METHOD_RADIOS,
      data.requestPayment.paymentMethod,
    ) ?? "Account transfer";
  const hasUploadFile = Object.values(
    data.requestPayment.attachment ?? {},
  ).some(Boolean);

  const isShowButton =
    clientType === "shipper" ||
    (clientType === "forwarder" && data.type === "FORWARDER_TO_CARRIER") ||
    (clientType === "forwardercarrier" && data.type === "FORWARDER_TO_CARRIER");

  const handleConfirm = () => {
    handleModalClose();
    patchSettlementAcceptMutate({ offerId: id! });
  };

  const handleReject = () => {
    handleModalClose();
    patchSettlementRejectMutate({ offerId: id! });
  };

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Status">
            <StatusLabel
              css={S.statusLabel}
              status={
                (clientType === "shipper" ||
                  data.type === "FORWARDER_TO_CARRIER") &&
                data.status === "ONGOING"
                  ? "REQUESTED"
                  : data.status
              }
            />
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Payment method">
            {t(payMethodLabel) || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Total">
            <S.PriceWrapper>
              <S.Total>{totalPrice}</S.Total>
              <S.SubtotalWrapper>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                  <S.SubtotalContent>
                    {formatPrice(data.requestPayment.price)}
                  </S.SubtotalContent>
                </S.SubtotalItem>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                  <S.SubtotalContent>{vat}</S.SubtotalContent>
                </S.SubtotalItem>
              </S.SubtotalWrapper>
            </S.PriceWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Attached file">
            {hasUploadFile && data.requestPayment.attachment ? (
              <S.FileWrapper>
                {createAttachFiles([
                  data.requestPayment.attachment.attachment1 || "",
                  data.requestPayment.attachment.attachment2 || "",
                ]).map(
                  (fileKey, idx) =>
                    fileKey && (
                      <DownloadBtn
                        key={fileKey}
                        downloadFileNamePrefix="SettlementAttachedFile"
                        fileName={makeOrderFileName(fileKey, idx)}
                        fileKey={fileKey}
                        isLoading={isLoading}
                        downloadFileMutate={downloadFileMutate}
                      />
                    ),
                )}
              </S.FileWrapper>
            ) : (
              "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row css={S.memo}>
          <LabelContentTable.Content labelWidth={200} label="Memo">
            {data.memo || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      {isShowButton && (
        <S.ButtonWrapper>
          <Button
            variant="outlineMedium"
            label="Reject"
            handleClickBtn={handleModalOpen(
              <ConfirmModal
                ref={modalRef}
                title="Reject requested payment"
                desc="Are you sure you want to reject this request to revise the amount?"
                closeBtnLabel="Cancel"
                negBtnLabel="Reject"
                callbackFn={handleReject}
              />,
            )}
          />
          <Button
            variant="primaryMedium"
            label="Confirm"
            handleClickBtn={handleModalOpen(
              <ConfirmModal
                ref={modalRef}
                title="Check requested payment"
                desc="Are you sure you want to request this payment?"
                closeBtnLabel="Cancel"
                posBtnLabel="Confirm"
                callbackFn={handleConfirm}
              />,
            )}
          />
        </S.ButtonWrapper>
      )}
    </>
  );
};

export default SettlementDetailRequestPayment;
