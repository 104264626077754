export const createMarker = (
  map: google.maps.Map,
  lat: number,
  lng: number,
  icon?: string | google.maps.Icon | google.maps.Symbol,
  zIndex?: number,
) =>
  new google.maps.Marker({
    map,
    position: { lat: +lat, lng: +lng },
    shape: { coords: [40, 40, 40], type: "circle" },
    optimized: false,
    icon,
    zIndex,
  });

export const hideMarkers = (markers: google.maps.Marker[]): void => {
  markers.forEach((marker) => marker.setMap(null));
};

// NOTE: marker 에 className 적용
export const makeMarkerLayer = (map: google.maps.Map): void => {
  const markerLayer = new google.maps.OverlayView();
  markerLayer.draw = function () {
    this.getPanes()!.markerLayer.className = "profile-marker";
  };
  markerLayer.setMap(map);
};
