import React from "react";
import { useTranslation } from "react-i18next";

import {
  InternalLink,
  LabelContentTable,
  Pg,
  SettingLayout,
} from "@/components";
import { useAuth } from "@/contexts";
import { PATH } from "@/assets";

import { SETTING_MENU } from "assets";
import * as S from "./CompanyPlanEdit.styled";

const ForwarderCarrierCompanyPlanEdit = () => {
  const { t } = useTranslation();

  const { auth } = useAuth();

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <div>
        <LabelContentTable
          // TODO: 스크린에서 확인이 불가능하여 labelContentTable 교체 못함 확인 후 교체 필요
          css={S.labelContentTable}
          variant="bg"
          subject="Plan"
        >
          <LabelContentTable.Row>
            <LabelContentTable.Content css={S.content} label="Plan">
              Standard
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content
              css={S.content}
              label="Number of transport"
            >
              3 / 100
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content css={S.content} label="Payment method">
              Accout transfer
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content
              css={S.content}
              label="Next payment date"
            >
              14/03/2022
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </LabelContentTable>
        <S.Table>
          <thead>
            <tr>
              <S.Th></S.Th>
              <S.Th>
                <S.RadioWrapper>
                  <input
                    type="radio"
                    name="radio"
                    id="Standard"
                    defaultChecked
                    value="Standard"
                  />
                  <label htmlFor="Standard">{t("Standard")}</label>
                </S.RadioWrapper>
                <S.PriceWrapper>
                  <S.Price>
                    {auth.clientType === "carrier" ? "1,500,000" : "2,000,000"}{" "}
                    VND
                  </S.Price>{" "}
                  <S.Month>/ {t("month")}</S.Month>
                </S.PriceWrapper>
              </S.Th>
              <S.Th>
                <S.RadioWrapper>
                  <input
                    type="radio"
                    name="radio"
                    id="Business trial"
                    value="Business trial"
                  />
                  <label htmlFor="Business trial">{t("Business trial")}</label>
                </S.RadioWrapper>
                <S.PriceWrapper>
                  <S.Price>
                    {auth.clientType === "carrier" ? "2,500,000" : "4,000,000"}{" "}
                    VND
                  </S.Price>{" "}
                  <S.Month>/ {t("month")}</S.Month>
                </S.PriceWrapper>
              </S.Th>
              <S.Th>
                <S.RadioWrapper>
                  <input
                    type="radio"
                    name="radio"
                    id="Business"
                    value="Business"
                  />
                  <label htmlFor="Business">{t("Business")}</label>
                </S.RadioWrapper>
                <S.PriceWrapper>
                  <S.Price>
                    {auth.clientType === "carrier" ? "3,000,000" : "5,000,000"}{" "}
                    VND
                  </S.Price>{" "}
                  <S.Month>/ {t("month")}</S.Month>
                </S.PriceWrapper>
              </S.Th>
              <S.Th>
                <S.RadioWrapper>
                  <input
                    type="radio"
                    name="radio"
                    id="Premium"
                    value="Premium"
                  />
                  <label htmlFor="Premium">{t("Premium")}</label>
                </S.RadioWrapper>
                <S.PriceWrapper>
                  <S.Price>
                    {auth.clientType === "carrier" ? "6,000,000" : "10,000,000"}{" "}
                    VND
                  </S.Price>{" "}
                  <S.Month>/ {t("month")}</S.Month>
                </S.PriceWrapper>
              </S.Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <S.Td>{t("Transport limit")}</S.Td>
              <S.Td>100 / {t("month")}</S.Td>
              <S.Td>300 / {t("month")}</S.Td>
              <S.Td>300 / {t("month")}</S.Td>
              <S.Td>{t("No limit")}</S.Td>
            </tr>
            <tr>
              <S.Td>{t("Extra credit")}</S.Td>
              <S.Td>
                {auth.clientType === "carrier" ? "20,000" : "25,000"} VND /{" "}
                {t("Order")}
              </S.Td>
              <S.Td>
                {auth.clientType === "carrier" ? "15,000" : "20,000"} VND /{" "}
                {t("Order")}
              </S.Td>
              <S.Td>
                {auth.clientType === "carrier" ? "15,000" : "20,000"} VND /{" "}
                {t("Order")}
              </S.Td>
              <S.Td>{t("No extra fee")}</S.Td>
            </tr>
          </tbody>
        </S.Table>
        <S.BtnsWrapper>
          <InternalLink variant="outlineMedium" to={PATH.SETTINGS_COMPANY}>
            {t("Close")}
          </InternalLink>
          <Pg label="Payment" amount="100000" />
        </S.BtnsWrapper>
      </div>
    </SettingLayout>
  );
};

export default ForwarderCarrierCompanyPlanEdit;
