import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_hr, mixin_scrollbar } from "@/styles";

export const Body = styled.div`
  ${({ theme }) => css`
    height: ${`calc(100vh - ${theme.size.HEADER_HEIGHT} - ${theme.size.LIST_LAYOUT_HEADER_HEIGHT} - 80px)`};
  `}
`;

export const Hr = styled.hr`
  ${mixin_hr};
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 12px 20px;
    border-bottom: 1px solid ${theme.color.gray_30};

    & > h4 {
      ${theme.font.semibold_16};
    }
  `}
`;

export const tooltip = (theme: Theme) => css`
  & > button {
    width: 20px;
    height: 20px;

    svg path {
      fill: ${theme.color.gray_70};
    }
  }
`;

export const EmptyBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.gray_10};
    border: 1px solid ${theme.color.gray_35};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    padding: 32px 20px;

    & > div {
      ${theme.font.regular_14};
      display: block;
      text-align: center;
      white-space: pre-wrap;
      color: ${theme.color.gray_60};
    }
  `}
`;

export const noSelectIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.gray_50};
  }
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.color.gray_20};
    padding: 12px 20px 20px;
    background-color: ${theme.color.white};
  `}
`;

export const Wrapper = styled.div`
  ${mixin_scrollbar};
  /* NOTE: allcation detail의 (상단 info height + padding)만큼 - 처리 추가 */
  flex-grow: 1;
  height: calc(100% - 151px);
  padding: 20px;
  overflow-x: hidden;
`;

export const RouteWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 350px;
`;

export const button = css`
  width: 100%;
`;
