import { calcTableWidth } from "@/utils";

export const COMPANY_SELECT_TABLE_CELL_SIZES = calcTableWidth([
  "240px",
  "160px",
  "220px",
]);

export const TRUCKING_COMPANY_SELECT_TABLE_CELL_SIZES = calcTableWidth([
  "40px",
  "200px",
  "160px",
  "220px",
]);
