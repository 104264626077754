import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 73px;
    left: 50%;
    height: max-content;
    z-index: ${theme.zIndex.MODAL};
    pointer-events: none;
    transform: translateX(-50%);

    & > div:not(:first-of-type) {
      margin-top: 8px;
    }
  `}
`;
