import React from "react";

import * as S from "./DetailLayout.styled";

interface DetailLayoutProps {
  className?: string;
  children: React.ReactNode;
}

const DetailLayout = ({ className, children }: DetailLayoutProps) => {
  return <S.Layout className={className}>{children}</S.Layout>;
};

export default DetailLayout;
