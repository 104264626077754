import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

import { mixinBtn_36, mixinBtn_primary, mixinBtn_secondary } from "@/styles";

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    margin-bottom: 12px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.gray_30};
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 160px 1fr;
    align-items: center;
    padding: 16px 0;
    background-color: ${theme.color.white};

    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray_30};
    }

    & > div {
      padding: 0 25px;
      white-space: nowrap;

      &:first-of-type {
        ${theme.font.medium_13};
        color: ${theme.color.gray_60};
      }
    }
  `}
`;

export const NameWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    & > :nth-of-type(1) {
      ${theme.font.regular_14};
      align-items: flex-start;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & > div {
      display: flex;
      column-gap: 10px;
    }
  `}
`;

export const labelContent = (theme: Theme) => css`
  & > p {
    position: static;
  }
  && > :nth-of-type(1) > label {
    margin-bottom: 2px;
    color: ${theme.color.gray_60};
  }
`;

export const nameInput = css`
  width: 160px;
`;

export const phoneInput = css`
  width: 500px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  column-gap: 12px;

  & > button {
    ${mixinBtn_36};
    border-radius: 2px;
    text-transform: capitalize;
  }
`;

export const errorMsg = css`
  margin-top: 4px;
`;

export const row = css`
  grid-template-columns: 187px 1fr;

  & > :last-of-type {
    padding: 16px 0;
  }
`;

export const rowTitle = (theme: Theme) => css`
  background-color: ${theme.color.white};
  & > :nth-of-type(1) > div {
    white-space: nowrap;
  }

  & > :nth-of-type(1) > span {
    ${theme.font.medium_13};
    width: 180px;
    padding: 16px 24px;
    color: ${theme.color.gray_60};
  }
`;
