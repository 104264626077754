import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TabList = styled.ul<{ isOrder?: boolean }>`
  display: flex;
  column-gap: ${({ isOrder }) => (isOrder ? "0px" : "20px")};
`;

export const Tab = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & > a {
      display: flex;
      align-items: center;

      span {
        ${theme.font.bold_18};
        color: ${theme.color.gray_40};
      }

      span:nth-of-type(2) {
        ${theme.font.bold_16};
        margin-left: 4px;
      }

      &[aria-selected="true"] span {
        color: ${theme.color.black};
      }

      &:hover span {
        color: ${theme.color.black};
      }
    }

    & > svg {
      width: 16px;
      height: 16px;
      margin: 0px 8px;

      path {
        fill: ${theme.color.gray_40};
      }
    }
  `}
`;
