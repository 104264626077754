import type { AxiosResponse } from "axios";

import type {
  FavoriteOrdersServerModel,
  S3FileUploadType,
  UpdateFavoriteOrderNameQueryModel,
} from "@/types";

import { ax } from "apis/axios";
import { makeS3PresignedUrlFileUuids } from "utils";
import type { CompanyType, CreateOrderQueryModel } from "types";

export async function createOrderCreationAPI(
  req: CreateOrderQueryModel<"carrier">,
  clientType: "carrier",
): Promise<AxiosResponse<any, any>>;
export async function createOrderCreationAPI(
  req: CreateOrderQueryModel<"forwarder">,
  clientType: "forwarder",
): Promise<AxiosResponse<any, any>>;
export async function createOrderCreationAPI(
  req: CreateOrderQueryModel<"forwardercarrier">,
  clientType: "forwarder",
): Promise<AxiosResponse<any, any>>;
export async function createOrderCreationAPI(
  req: CreateOrderQueryModel<"shipper">,
  clientType: "shipper",
): Promise<AxiosResponse<any, any>>;
export async function createOrderCreationAPI(
  req: CreateOrderQueryModel<CompanyType>,
  clientType: "carrier" | "forwarder" | "shipper",
) {
  const files: S3FileUploadType = {
    ...(req.body.file1 && { file1: { file: req.body.file1, path: "order" } }),
    ...(req.body.file2 && { file2: { file: req.body.file2, path: "order" } }),
  };

  const fileUids =
    req.body.file1 || req.body.file2
      ? await makeS3PresignedUrlFileUuids(files)
      : {};

  return ax.post(`/order/${clientType}`, { ...req.body, ...fileUids });
}

export const getFavoriteOrdersAPI = async () => {
  const { data } = await ax.get<FavoriteOrdersServerModel>("/order/favorite");

  return data;
};

export const updateFavoriteNameAPI = (req: UpdateFavoriteOrderNameQueryModel) =>
  ax.put(`/order/favorite/${req.favoriteId}`, req.body);

export const deleteFavoriteAPI = (favoriteId: string) =>
  ax.delete(`/order/favorite/${favoriteId}`);
