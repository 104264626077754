import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const Root = styled.div`
  width: 248px;
`;

export const Body = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    height: calc(52px * 5);
    border: 1px solid ${theme.color.gray_30};
    border-top: 0;
  `}
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px 12px;
    text-align: left;

    & > span:first-of-type {
      margin-bottom: 4px;
    }

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const TruckNum = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    color: ${theme.color.black};
  `}
`;

export const TruckInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;
