import { badge } from "./badge";
import { boxShadow } from "./boxShadow";
import { button } from "./button";
import { color } from "./color";
import { font } from "./font";
import { input } from "./input";
import { size } from "./size";
import { zIndex } from "./zIndex";

export const theme = {
  badge,
  boxShadow,
  button,
  color,
  font,
  input,
  size,
  zIndex,
};
