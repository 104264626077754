import React from "react";

import { Table } from "@/components";
import { useFilterParams } from "@/hooks";
import { filterTableColumns, formatICTDateTime } from "@/utils";
import { NOTICE_TABLE_COLUMNS, PinnedIcon } from "@/assets";

import { useGetNotices } from "services";
import type { GetNoticesQueryModel, GetNoticesServerModel } from "types";
import * as S from "./NoticeTable.styled";

const NoticeTable = () => {
  const { filters } = useFilterParams<GetNoticesQueryModel["query"]>(["page"], {
    pageSize: 10,
  });

  const { data } = useGetNotices({
    suspense: false,
    req: { query: filters },
  });
  const filteredColumns = filterTableColumns(NOTICE_TABLE_COLUMNS);

  const formatTableData = (
    data: GetNoticesServerModel["notices"][number],
    key: keyof GetNoticesServerModel["notices"][number],
  ) => {
    switch (key) {
      case "created":
        return formatICTDateTime(data[key], "DD/MM/YYYY");

      default:
        if (data.pinned) {
          return (
            <>
              <PinnedIcon css={S.pinned} />
              {data[key]}
            </>
          );
        } else {
          return data[key];
        }
    }
  };
  return (
    <Table
      css={S.noticeTable}
      title="notice"
      hasRowBtn
      rowCount={data?.notices.length ?? 0}
    >
      <Table.Head css={S.tableHead} columnList={NOTICE_TABLE_COLUMNS} />
      <Table.Body css={S.body}>
        {data?.notices.length ? (
          data?.notices.map((item) => (
            <Table.SelectRowMovePage key={item.noticeId} path={item.noticeId}>
              {filteredColumns.map(({ key }) => (
                <Table.Cell key={key}>{formatTableData(item, key)}</Table.Cell>
              ))}
            </Table.SelectRowMovePage>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default NoticeTable;
