import { css, Theme } from "@emotion/react";

export const detailModal = (theme: Theme) => css`
  width: 552px;
  padding: 24px 0 0;

  & > div {
    & > h3 {
      margin-left: 32px;
    }

    & > button:nth-last-of-type(1) {
      margin-right: 32px;
    }
  }

  & > div:nth-last-of-type(1) {
    padding-bottom: 24px;
    box-shadow: ${theme.boxShadow.shadow_modal_scroll};
  }
`;

export const searchInput = css`
  width: 300px;
  margin: 0 0 12px 32px;
`;

export const favoriteList = css`
  padding: 0 32px;
`;
