/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { Fragment } from "react";

import { ExternalLink } from "@/components";
import { formatOrderNum } from "@/utils";
import { DefaultArrowIcon, PATH } from "@/assets";
// TODO: 현재는 임시로 해둔 것임, carrier, forwarder에 따라 PATH 인자로 처리하기

import * as S from "./MoreDialog.styled";

interface MoreDialogProps {
  orders: { orderNumber: string; orderId: string }[];
}

const MoreDialog = ({ orders }: MoreDialogProps) => {
  return (
    <S.MoreDialog>
      <ul>
        {orders.map(({ orderId, orderNumber }, idx) => (
          <Fragment key={orderId}>
            {idx > 0 && (
              <li>
                <ExternalLink
                  css={S.goToOrderDetail}
                  variant="ghostNoUnderlineGray"
                  href={`${PATH.ORDER_MANAGEMENT}/${orderId}`}
                >
                  {formatOrderNum(orderNumber)}
                  <DefaultArrowIcon />
                </ExternalLink>
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </S.MoreDialog>
  );
};

MoreDialog.displayName = "MoreDialog";

export default MoreDialog;
