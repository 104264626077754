import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { TRUCKING_COMPANY_SELECT_TABLE_CELL_SIZES } from "@/assets";

export const detailModal = css`
  width: 686px;
  height: 622px;
`;

export const searchInput = css`
  width: 300px;
  margin-bottom: 12px;
`;

export const CheckTableWrapper = styled.div`
  max-height: 460px;
`;

export const checkTable = (theme: Theme) => css`
  th {
    color: ${theme.color.gray_70};
  }

  tbody {
    display: block;
    width: 620px;
    height: calc(420px - 32px); // NOTE: tbody - thead
    overflow-x: hidden;
  }

  tr {
    grid-template-columns: ${TRUCKING_COMPANY_SELECT_TABLE_CELL_SIZES};

    & > td {
      border-right: 1px solid ${theme.color.gray_30};

      :nth-last-of-type(2) {
        border-right: 0;
      }
    }
  }
`;

export const selectRow =
  (isChecked: boolean, isDisabled: boolean) => (theme: Theme) =>
    css`
      background-color: ${isChecked
        ? theme.color.primary_10
        : theme.color.white};

      td > div {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      background-color: ${isDisabled && theme.color.gray_10};
    `;
