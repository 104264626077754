import { isEmpty } from "lodash-es";

import { DRIVER_DELIVERY_STATUS } from "@/assets";
import type { AllocationDriver } from "@/types";

export const hasNotCanceledDriverDeliveryStatus = (
  drivers: AllocationDriver[],
) =>
  drivers.some(
    (driver) =>
      driver.driverStatus === "TO_DROPOFF" ||
      driver.driverStatus === "COMPLETED",
  );

export const printDriverDeliveryStatusLabel = (
  driverDeliveryStatus: keyof typeof DRIVER_DELIVERY_STATUS,
) =>
  isEmpty(driverDeliveryStatus)
    ? ""
    : DRIVER_DELIVERY_STATUS[driverDeliveryStatus];
