import React from "react";
import { useNavigate } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG } from "@/constants";

import { useDeletePropertyGarage } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

interface DelGarageModalProps {
  garageId: string;
}

const DelGarageModal = React.forwardRef(
  (
    { garageId }: DelGarageModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const navigate = useNavigate();

    const { isLoading: isDeleteGarageLoading, mutate: deletePropertyGarage } =
      useDeletePropertyGarage();

    const { addToast } = useToast();
    const { handleModalClose } = useModal();

    const handleDeletePropertyGarage = () => {
      deletePropertyGarage(garageId, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.GARAGE_DELETE_DONE);
          handleModalClose();
          navigate(`${PATH.PROPERTY}?tab=garage`);
        },
        onError: (err) => {
          if (err.response?.data.response === "CONNECTED_TRUCK_EXISTS") {
            addToast(CARRIER_TOAST_MSG.WARNING.DELETE_GARAGE_EXISTING_TRUCK);
            return;
          }

          addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        isLoading={isDeleteGarageLoading}
        title="Delete the garage"
        desc="Are you sure you want to delete the garage?"
        closeBtnLabel="Close"
        negBtnLabel="Confirm"
        callbackFn={handleDeletePropertyGarage}
      />
    );
  },
);

DelGarageModal.displayName = "DelGarageModal";

export default DelGarageModal;
