import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { PATH } from "@/assets";

import { useDeletePropertyDriver } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

const DelDriverModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { mutate: deletePropertyDriverMutate } = useDeletePropertyDriver();

    const { addToast } = useToast();
    const { handleModalClose } = useModal();

    const handleDelDriver = () => {
      const req = {
        driverId: id!,
      };
      deletePropertyDriverMutate(req, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.DRIVER_DELETE_DONE);
          handleModalClose();
          navigate(PATH.PROPERTY);
        },
        onError: (err) => {
          switch (err.response?.data.response) {
            case "DRIVING_DRIVER":
              addToast(CARRIER_TOAST_MSG.WARNING.PROCESS_FAIL);
              break;

            default:
              addToast(CARRIER_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
          }
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Delete the driver"
        desc="Are you sure you want to delete the driver?"
        closeBtnLabel="Cancel"
        negBtnLabel="Confirm"
        callbackFn={handleDelDriver}
      />
    );
  },
);

DelDriverModal.displayName = "DelDriverModal";

export default DelDriverModal;
