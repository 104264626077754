import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CompanyLicenseRegistrationHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.span`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    margin-bottom: 4px;
  `}
`;

export const PageDesc = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    color: ${theme.color.gray_70};
  `}
`;

export const PageSubDesc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 32px;
    color: ${theme.color.gray_60};
  `}
`;
