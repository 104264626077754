import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { CheckboxBtn, Profile } from "@/components";
import { PhoneIcon } from "@/assets";
import { mixin_limit_one_line } from "@/styles";

export const Root = styled.article`
  position: relative;
`;

export const CoverLabel = styled.label<{ disabled: boolean }>`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 40px 204px 1fr;
    column-gap: 12px;
    align-items: center;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 5px;
    padding: 16px 12px 16px 20px;
    background-color: ${theme.color.white};
    cursor: pointer;

    &:not([disabled]):hover {
      background-color: ${theme.color.gray_00};
    }

    &[disabled] {
      opacity: 0.5;
      color: ${theme.color.gray_60};
      cursor: not-allowed;
    }
  `}
`;

export const DriverProfile = styled(Profile)`
  width: 40px;
  height: 40px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const DriverName = styled.h5`
  ${({ theme }) => css`
    ${mixin_limit_one_line};
    ${theme.font.medium_14};
  `}
`;

export const AdditionalInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: 2px;

    & > svg {
      width: 16px;
      min-width: 16px;
      height: 16px;
      margin-right: 8px;

      & > path {
        fill: ${theme.color.gray_40};
      }
    }

    & > span {
      ${mixin_limit_one_line};
      ${theme.font.regular_13};
      color: ${theme.color.gray_80};
    }
  `}
`;
