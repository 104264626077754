import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import { DataSection } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";
import { PATH } from "@/assets";

import {
  SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS,
  FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS,
} from "assets";
import type { CompanyType, GetQuotationCompletedServerModel } from "types";
import * as S from "./QuotationCompletedSection.styled";

interface QuotationCompletedSectionProps {
  data: GetQuotationCompletedServerModel<CompanyType>;
  refetch: () => void;
  dataUpdatedAt: number;
}

const QuotationCompletedSection = ({
  data,
  refetch,
  dataUpdatedAt,
}: QuotationCompletedSectionProps) => {
  const { t } = useTranslation();

  const columns =
    auth.clientType === "forwarder" || auth.clientType === "forwardercarrier"
      ? FORWARDER_FORWARDERCARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS
      : SHIPPER_CARRIER_QUOTATION_COMPLETED_TABLE_COLUMNS;

  const filteredColumns = filterTableColumns(columns);

  const formatTableData = (
    data: GetQuotationCompletedServerModel<CompanyType>["completed"][number],
    key: (typeof filteredColumns)[number]["key"],
  ) => {
    switch (key) {
      case "orderNumber":
        return formatOrderNum(data[key]);

      case "puEta":
      case "doEta":
      case "completed":
        {
          // TODO: 데이터 초기화 후 정상적인 데이터 들어왔을 경우 'puEta', 'doEta' 부분으로 이동
          if (key === "completed") {
            const date = data[key];
            return date ? formatICTDateTime(date) : "-";
          }
        }
        return formatICTDateTime(data[key]);

      case "companyName":
        return key in data && data[key];

      case "shipperName":
        return key in data && data[key];

      case "carrierName":
        return key in data && data[key];

      case "puAddr":
        return addrFormatter(data.puAddr, data.puAddrDetail);
      case "doAddr":
        return addrFormatter(data.doAddr, data.doAddrDetail);

      default:
        return data[key];
    }
  };

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={data.pageInfo.totalData}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table(auth.clientType!)}
        title="Quotation"
        isStickyThead
        rowCount={data?.completed.length}
      >
        <DataSection.Table.Head columnList={columns} />
        <DataSection.Table.Body css={S.body}>
          {data?.completed.length ? (
            data.completed.map((item) => (
              <DataSection.Table.SelectRowMovePage
                key={item.orderId}
                path={item.offerId}
              >
                {filteredColumns.map(({ key }) => {
                  return (
                    <DataSection.Table.Cell key={key}>
                      {formatTableData(item, key)}
                    </DataSection.Table.Cell>
                  );
                })}
              </DataSection.Table.SelectRowMovePage>
            ))
          ) : (
            <DataSection.Table.NoData>
              <Link css={S.linknoData} to={PATH.ORDER_CREATION}>
                {t("Order creation")}
              </Link>
            </DataSection.Table.NoData>
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default QuotationCompletedSection;
