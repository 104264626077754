import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.footer`
  ${({ theme }) => css`
    width: ${`calc(100% - ${theme.size.NAV_WIDTH})`};
    margin-left: ${theme.size.NAV_WIDTH};
    padding: 16px 60px;
    background-color: ${theme.color.gray_00};
    z-index: 2;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    ${theme.font.semibold_14};
    color: ${theme.color.gray_60};
  `}
`;

export const Address = styled.address`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const link = (theme: Theme) => css`
  ${theme.font.regular_13};
  color: ${theme.color.gray_60};
`;
