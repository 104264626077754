import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToast, useToggle } from "@/hooks";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";
import type { DropdownOptionType } from "@/types";

import { useUpdateOrderManagementDetail } from "services";
import { formatTruckNumTypeOption } from "utils";
import {
  OrderManagementForm,
  GetOrderManagementDetailServerModel,
} from "types";
import { ORDERMANAGEMENT_DETAIL_TRUCK_FORM } from "assets";

const orderManagementDetailSchema = yup.object({
  truck: yup
    .array(
      yup.object({
        truckType: yup
          .mixed<DropdownOptionType>()
          .required()
          .test({
            name: "required",
            test: ({ key, label }) => !!(key && label),
            message: COMMON_VALID_MSG.REQUIRED,
          }),
        truckOption: yup
          .mixed<DropdownOptionType>()
          .required()
          .test({
            name: "required",
            test: ({ key, label }) => !!(key && label),
            message: COMMON_VALID_MSG.REQUIRED,
          }),
        truckNum: yup.number().required(),
      }),
    )
    .required(),
});

const useOrderManagementDetail = (
  data?: GetOrderManagementDetailServerModel,
) => {
  const { id } = useParams();

  const [isEdit, handleIsEdit] = useToggle();

  const { mutate: updateOrderManagementDetailMutate } =
    useUpdateOrderManagementDetail();

  const formMethod = useForm<OrderManagementForm>({
    defaultValues: ORDERMANAGEMENT_DETAIL_TRUCK_FORM,
    mode: "onTouched",
    resolver: yupResolver(orderManagementDetailSchema),
  });

  const { addToast } = useToast();

  const trucks = formatTruckNumTypeOption(data?.trucks).map((item) => {
    return {
      truckType: { key: item.truckType?.key, label: item.truckType?.label },
      truckOption: {
        key: item.truckOption?.key,
        label: item.truckOption?.label,
      },
      truckNum: item.truckNum,
    };
  });

  const truckSum = trucks.reduce((acc, truck) => acc + truck.truckNum, 0);

  const handleTruckCntCancel = (): void => {
    if (!data) return;

    formMethod.setValue("truck", trucks);
    formMethod.clearErrors("truck");
    handleIsEdit();
  };

  const handleTrucksConfirm = (): void => {
    const trucks = formMethod
      .watch("truck")
      .reduce<Record<string, number>>((acc, item) => {
        acc[`${item.truckOption.key}`] = item.truckNum;
        return acc;
      }, {});

    const req = {
      orderNumber: id!,
      body: { trucks },
    };

    updateOrderManagementDetailMutate(req, {
      onSuccess: () => handleIsEdit(),
      onError: (err) => {
        switch (err.response?.data.response) {
          case "WRONG_TRUCK_COUNT":
            addToast(COMMON_TOAST_MSG.WARNING.WRONG_TRUCK_COUNT);
            break;

          default:
            addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        }
      },
    });
  };

  useEffect(() => {
    if (!data) return;

    if (data.trucks) {
      formMethod.reset({ truck: trucks });
    }
  }, [data]);
  return {
    formMethod,
    isEdit,
    truckSum,
    handleIsEdit,
    handleTruckCntCancel,
    handleSubmit: formMethod.handleSubmit(handleTrucksConfirm),
  };
};

export default useOrderManagementDetail;
