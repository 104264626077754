import React from "react";

import { auth } from "@/contexts";
import { Filter } from "@/components";

import {
  CARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS,
  FORWARDER_FORWARDERCARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS,
  SHIPPER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS,
} from "assets";

const QuotationOngoingFilter = () => {
  const searchLabelsByClientType =
    auth.clientType === "shipper"
      ? SHIPPER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS
      : auth.clientType === "forwarder" ||
        auth.clientType === "forwardercarrier"
      ? FORWARDER_FORWARDERCARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS
      : CARRIER_QUOTAION_ONGOING_FILTER_STATUS_RADIOS;

  const searchLabels = [
    {
      queryKey: "status",
      label: "Status",
      options: searchLabelsByClientType,
    },
  ];

  return (
    <Filter searchLabels={searchLabels}>
      <Filter.Row>
        <Filter.Condtion conditionName="Status">
          <Filter.Radio queryKey="status" radios={searchLabelsByClientType} />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default QuotationOngoingFilter;
