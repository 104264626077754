import React from "react";

import * as S from "./OrderCard.styled";

interface OrderCardProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  draggable?: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
}

const OrderCard = ({
  children,
  className,
  draggable,
  onDragStart,
  onDragEnd,
  onDragEnter,
  onDragLeave,
  onMouseDown,
  onMouseUp,
  onClick,
}: OrderCardProps) => {
  return (
    <S.OrderCard
      className={className}
      onClick={onClick}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
    </S.OrderCard>
  );
};

interface OrderCardTitleProps {
  className?: string;
  content: any;
}

OrderCard.Title = function TitleItem({
  className,
  content,
}: OrderCardTitleProps) {
  return <S.Title className={className}>{content}</S.Title>;
};

interface OrderCardBodyProps {
  className?: string;
  children: React.ReactNode;
}

OrderCard.Body = function BodyItem({ children }: OrderCardBodyProps) {
  return <>{children}</>;
};

interface OrderCardInfoWrapperProps {
  children: React.ReactNode;
}

OrderCard.InfoWrapper = function InfoWrapperItem({
  children,
}: OrderCardInfoWrapperProps) {
  return <S.InfoWrapper>{children}</S.InfoWrapper>;
};

interface OrderCardInfoDetailProps {
  className?: string;
  children: React.ReactNode;
}

OrderCard.InfoDetail = function InfoDetailItem({
  className,
  children,
}: OrderCardInfoDetailProps) {
  return <S.InfoDetail className={className}>{children}</S.InfoDetail>;
};

export default OrderCard;
