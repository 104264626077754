import React from "react";
import { useTranslation } from "react-i18next";

import { TableLayout, Tab, InternalLink } from "@/components";
import { useTab } from "@/hooks";
import { PlusCircleIcon12 } from "@/assets";

import {
  PropertyGarage,
  PropertyLink,
  PropertyDriver,
  PropertyTruck,
} from "components";
import { useGetPropertyTab } from "services";
import { PROPERTY_TABS, TAB_CREATE_LINKS } from "assets";
import * as S from "./Property.styled";

const Property = () => {
  const { t } = useTranslation();

  const { data } = useGetPropertyTab();

  const { selectedTab } = useTab(PROPERTY_TABS);

  const tabCount = {
    driver: data?.driverCnt ?? 0,
    truck: data?.truckCnt ?? 0,
    garage: data?.garageCnt ?? 0,
  };

  const render = {
    driver: <PropertyDriver />,
    truck: <PropertyTruck />,
    garage: <PropertyGarage />,
    link: <PropertyLink />,
  } as const;

  return (
    <TableLayout h2="Property">
      <Tab css={S.customTabList} tabs={PROPERTY_TABS}>
        <S.Header>
          <Tab.TabList tabCount={tabCount} />
          {TAB_CREATE_LINKS[selectedTab].label && (
            <InternalLink
              variant="ghostNoUnderlinePrimary"
              to={TAB_CREATE_LINKS[selectedTab].path}
            >
              <PlusCircleIcon12 />
              {t(TAB_CREATE_LINKS[selectedTab].label)}
            </InternalLink>
          )}
        </S.Header>
        <Tab.TabPanel render={render} />
      </Tab>
    </TableLayout>
  );
};

export default Property;
