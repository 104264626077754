import type { S3FileUploadType } from "@/types";

import { ax } from "apis/axios";
import { makeS3PresignedUrlFileUuids } from "utils";
import type {
  GetInquiryDetailServerModel,
  CreateInquiryQueryModel,
  GetInquiriesQueryModel,
  GetInquiriesServerModel,
} from "types";

export const getInquiriesAPI = async (req: GetInquiriesQueryModel) => {
  const { data } = await ax.get<GetInquiriesServerModel>("/setting/inquiry", {
    params: req.query,
  });

  return data;
};

export const getInquiryDetailAPI = async (inquiryId: string) => {
  const { data } = await ax.get<GetInquiryDetailServerModel>(
    `/setting/inquiry/${inquiryId}`,
  );

  return data;
};

export const createInquiryAPI = async (req: CreateInquiryQueryModel) => {
  const files: S3FileUploadType = {
    ...(req.body.file1 && { file1: { file: req.body.file1, path: "inquiry" } }),
    ...(req.body.file2 && { file2: { file: req.body.file2, path: "inquiry" } }),
    ...(req.body.file3 && { file3: { file: req.body.file3, path: "inquiry" } }),
  };
  const fileUuids = await makeS3PresignedUrlFileUuids(files);

  const body = { ...req.body, ...fileUuids };

  return await ax.post("/setting/inquiry", body);
};

export const deleteInquiryAPI = (inquiryId: string) =>
  ax.delete(`/setting/inquiry/${inquiryId}`);
