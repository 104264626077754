import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable, ModalPagination } from "@/components";
import { findLookupTableLabel, formatQuantity, formatWeight } from "@/utils";
import {
  CARGO_TYPE,
  HAZARD_GOODS_RADIOS,
  PACKAGING_TYPE,
  STACKABLE_GOODS_RADIOS,
} from "@/assets";
import type { Languages } from "@/types";

import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementCargoDetails.styled";

interface OrderManagementCargoDetailsProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementCargoDetails = ({
  data,
}: OrderManagementCargoDetailsProps) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const {
    cargoType,
    packagingType,
    width,
    height,
    length,
    weight,
    cargoCnt,
    hazardGoods,
    doubleLoading,
  } = data.cargoes[currentPage - 1];

  const cargoTypeLabel =
    findLookupTableLabel(CARGO_TYPE, cargoType) ?? cargoType;
  const packagingTypeLabel =
    findLookupTableLabel(PACKAGING_TYPE, packagingType) ?? "-";
  const hazardGoodsLabel =
    findLookupTableLabel(HAZARD_GOODS_RADIOS, hazardGoods ? "yes" : "no") ??
    "-";
  const doubleLoadingLabel =
    findLookupTableLabel(
      STACKABLE_GOODS_RADIOS,
      doubleLoading ? "yes" : "no",
    ) ?? "-";

  const handleChangePage = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Type">
            {t(cargoTypeLabel as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Packaging type">
            {t(packagingTypeLabel as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Cargo">{`${width}m x ${height}m x ${length}m / ${formatWeight(
            weight,
          )} / ${formatQuantity(cargoCnt)}`}</LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Hazard goods">
            {t(hazardGoodsLabel as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Stackable">
            {t(doubleLoadingLabel as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <ModalPagination
        currentPage={currentPage}
        pageInfo={{
          currentPage,
          dataPerPage: 10,
          startRow: 1,
          totalData: data.cargoes.length,
          totalPages: data.cargoes.length,
        }}
        movePage={handleChangePage}
      />
    </>
  );
};

export default OrderManagementCargoDetails;
