import React from "react";
import { useRecoilValue } from "recoil";
import { lowerCase, upperFirst } from "lodash-es";

import { Badge } from "@/components";
import { clientLanguage } from "@/stores";
import { DownArrowIcon20, TruckIcon } from "@/assets";
import type { Languages } from "@/types";

import type { TruckStatus } from "types";
import * as S from "./BtnFoldInfo.styled";

interface BtnFoldInfoProps {
  isFold: boolean;
  truck: {
    registNumber: string;
    truckTypeEn: string;
    truckTypeVi: string;
  };
  status: TruckStatus;
  handleFoldBtnClick: () => void;
}

const BtnFoldInfo = ({
  isFold,
  truck,
  status,
  handleFoldBtnClick,
}: BtnFoldInfoProps) => {
  const languages = useRecoilValue(clientLanguage);

  if (!truck) return null;

  return (
    <S.BtnFoldInfo type="button" isFold={isFold} onClick={handleFoldBtnClick}>
      <S.BtnFoldInfoContentWrapper>
        <Badge
          css={S.badge}
          variant={status === "SHIPPING" ? "primary" : "gray"}
          icon={<TruckIcon />}
          content={upperFirst(lowerCase(status)) as Languages}
        />
        <h3>
          {truck.registNumber}
          <span>
            ({languages === "en" ? truck.truckTypeEn : truck.truckTypeVi})
          </span>
        </h3>
      </S.BtnFoldInfoContentWrapper>
      <DownArrowIcon20 css={S.foldBtn(isFold)} />
    </S.BtnFoldInfo>
  );
};

export default BtnFoldInfo;
