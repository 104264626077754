import { useEffect } from "react";

import type { GetMonitoringTrucksClientModel } from "types";

const useTruckMarkerEventListener = (
  handleTruckMarkerClick: (truckId: string) => () => void,
  monitoringTrucks: GetMonitoringTrucksClientModel["trucks"],
) => {
  useEffect(() => {
    if (!monitoringTrucks) return;

    monitoringTrucks.forEach((truck) => {
      truck.truckMarker?.addListener("click", () => {
        handleTruckMarkerClick(truck.truckId)();
      });
    });

    return () => {
      monitoringTrucks.forEach((truck) => {
        if (!truck.truckMarker) return;
        google.maps.event.clearListeners(truck.truckMarker, "click");
      });
    };
  }, [monitoringTrucks, handleTruckMarkerClick]);
};

export default useTruckMarkerEventListener;
