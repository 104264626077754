import React from "react";
import { useTranslation } from "react-i18next";
import { lowerCase } from "lodash-es";

import { Button, PreviousPageLink, TableOfContent } from "@/components";
import { useModal } from "@/hooks";
import { PATH } from "@/assets";

import * as S from "./QuotationDetailLayout.styled";

interface QuotationDetailLayoutProps {
  contents: {
    heading: {
      headingLevel?: "h2" | "h3" | "h4";
      addHeadingElement?: React.ReactNode;
      text: string; // TODO: Language로 변경 필요.
    };
    content: React.ReactElement;
  }[];
  pageStatus: string;
  isShowCancelButton?: boolean;
  cancelModal?: React.ReactNode;
}

const QuotationDetailLayout = ({
  contents,
  pageStatus,
  isShowCancelButton,
  cancelModal,
}: QuotationDetailLayoutProps) => {
  const { t } = useTranslation();

  const { handleModalOpen } = useModal();

  return (
    <S.Layout>
      <TableOfContent
        css={S.toc}
        stickyTop={<S.TocHeading>{t("Details")}</S.TocHeading>}
        contents={contents}
      />
      <S.ButtonWrapper>
        <PreviousPageLink
          variant="outlineMedium"
          to={`${PATH.QUOTATION}?tab=${lowerCase(pageStatus)}`}
        >
          {t("Back to list")}
        </PreviousPageLink>
        {isShowCancelButton && (
          <Button
            label="Cancel order"
            variant="errorMedium"
            handleClickBtn={handleModalOpen(cancelModal)}
          />
        )}
      </S.ButtonWrapper>
    </S.Layout>
  );
};

export default QuotationDetailLayout;
