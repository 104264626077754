import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.tz.setDefault("Asia/Ho_Chi_Minh");

type DateArgs = Dayjs | string | number | Date;
type DateTemplate =
  | "DD/MM/YY, HH:mm"
  | "DD/MM/YYYY"
  | "DD/MM/YYYY, HH:mm"
  | "HH:mm";

export const calcHourDiffICTWithUTC0 = (): number =>
  dayjs.utc().tz().utcOffset() / 60;

export const formatDate = (
  date: DateArgs,
  template: DateTemplate = "DD/MM/YY, HH:mm",
): string => dayjs(date).format(template);

// NOTE: ICT 시간으로 포매팅 된 string 필요 시 사용
export const formatICTDateTime = (
  date: DateArgs,
  template: string = "DD/MM/YY, HH:mm",
) => dayjs.utc(date).tz().format(template);

export const formatCreateICTDate = (date: string): string =>
  dayjs(dayjs(date, "DD/MM/YYYY HH:mm").subtract(7, "h")).format(
    "YYYY-MM-DD HH:mm:ss",
  );

export const formatICTToUTC0 = (date: dayjs.Dayjs): string =>
  dayjs(
    dayjs(date, "DD/MM/YYYY HH:mm").subtract(calcHourDiffICTWithUTC0(), "h"),
  ).format("YYYY-MM-DDTHH:mm:ss.sss[Z]");

export const wrongFormatDateTimeConvertToUTC = (dateTime: string) =>
  dayjs(dateTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

export const onlyWithColonsInTime = (v: string): string => {
  // eslint-disable-next-line
  const regex = /[^0-9\:]/g;

  return v.replaceAll(regex, "");
};
