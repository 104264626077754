import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import { getSettlementsAPI } from "apis";
import type {
  CompanyType,
  GetSettlementQueryModel,
  GetSettlementServerModel,
} from "types";

const settlementKeys = {
  all: ["settlement"] as const,
  lists: () => [...settlementKeys.all, "list"] as const,
  list: <T extends CompanyType>(filters: GetSettlementQueryModel<T>) =>
    [...settlementKeys.lists(), { filters }] as const,
};

export const useGetSettlements = <T extends CompanyType>(
  req: GetSettlementQueryModel<T>,
  options?: UseQueryOptions<
    GetSettlementServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    GetSettlementServerModel,
    ReturnType<typeof settlementKeys.list>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: settlementKeys.list(req),
    queryFn: () => getSettlementsAPI(req),
  });
};
