import { useState, useEffect } from "react";
import { isEmpty } from "lodash-es";

import { hideMarkers, createMarker } from "@/utils";
import dropOffMarkerSvg from "@/assets/icon/ic_dropOffPin.svg";
import pickUpMarkerSvg from "@/assets/icon/ic_pickUpPin.svg";
import garageMarkerSvg from "@/assets/icon/ic_garagePin.svg";
import { zIndex } from "@/styles";
import type { AddressSearchType, AddressType } from "@/types";

const LOCATION_MARKER_TYPE = {
  pickUp: {
    icon: pickUpMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_PICKUP_ICON_MARKER,
  },
  dropOff: {
    icon: dropOffMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_DROPOFF_ICON_MARKER,
  },
  garage: {
    icon: garageMarkerSvg,
    zIndex: zIndex.GOOGLE_MAP_GARAGE_ICON_MARKER,
  },
};

const useAddrSearchLocationMarker = (
  googleMap: google.maps.Map,
  locationInfo: AddressSearchType & { type: AddressType },
  isRenderReady: boolean,
) => {
  const [locationLatLngs, setLocationLatLngs] = useState<google.maps.LatLng>();

  const [markers, setMarkers] = useState<google.maps.Marker>();

  const addLocationMarker = (): void => {
    if (!googleMap || isEmpty(locationInfo)) return;

    const { lat, lng } = locationInfo.coord;

    if (markers) {
      markers.setMap(null);
    }

    const { icon, zIndex } = LOCATION_MARKER_TYPE[locationInfo.type];

    const marker = createMarker(
      googleMap!,
      +locationInfo.coord.lat,
      +locationInfo.coord.lng,
      icon,
      zIndex,
    );

    setMarkers(marker);

    setLocationLatLngs(new google.maps.LatLng(+lat, +lng));
  };

  useEffect(() => {
    isRenderReady && addLocationMarker();

    return () => {
      hideMarkers([]);
    };
  }, [googleMap, locationInfo]);

  return { locationLatLngs };
};

export default useAddrSearchLocationMarker;
