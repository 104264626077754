import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Button, PreviousPageLink } from "@/components";
import { PATH } from "@/assets";

import type { QuotationSendForm } from "types";
import * as S from "./SendQuotationBtns.styled";

interface SendQuotationBtnsProps {
  handleSendModalOpen: () => void;
}

const SendQuotationBtns = ({ handleSendModalOpen }: SendQuotationBtnsProps) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    handleSubmit,
  } = useFormContext<QuotationSendForm>();

  return (
    <S.Wrapper>
      <PreviousPageLink variant="outlineMedium" to={PATH.QUOTATION}>
        {t("Back to list")}
      </PreviousPageLink>
      <Button
        type="submit"
        variant="primaryMedium"
        label="Confirm"
        isDisabled={Object.keys(errors).length > 0}
        handleClickBtn={handleSubmit(handleSendModalOpen)}
      />
    </S.Wrapper>
  );
};

export default SendQuotationBtns;
