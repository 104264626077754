import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { checkEmailValidation } from "@/utils";
import { COMMON_VALID_MSG } from "@/constants";

import { useGetCompanyInfo, useUpdateCompanyInfo } from "services";
import { CARRIER_TOAST_MSG, CARRIER_VALID_MSG } from "constants/index";

interface FormData {
  phone: string;
  email: string;
}

const schema = yup
  .object({
    phone: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    email: yup
      .string()
      .required(COMMON_VALID_MSG.REQUIRED)
      .test("email", CARRIER_VALID_MSG.EMAIL, (value) =>
        checkEmailValidation(value),
      ),
  })
  .required();

const useEditCompany = (handleChangeReadField: () => void) => {
  const { data } = useGetCompanyInfo(true);
  const {
    isLoading: isUpdateCompanyInfoLoading,
    mutate: updateCompanyInfoMutate,
  } = useUpdateCompanyInfo();
  const { addToast } = useToast();

  const {
    register,
    watch,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      phone: "",
      email: "",
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const editCompany = handleSubmit((data) => {
    updateCompanyInfoMutate(
      {
        body: {
          phone: data.phone,
          email: data.email,
        },
      },
      {
        onSuccess: () => {
          handleChangeReadField();
          addToast(CARRIER_TOAST_MSG.SUCCESS.COMPANY_UPDATE_DONE);
        },
      },
    );
  });

  useEffect(() => {
    if (!data) return;

    reset({
      phone: data.phone ?? "",
      email: data.email ?? "",
    });
  }, [data]);

  return {
    isUpdateCompanyInfoLoading,
    data,
    errors,
    register,
    watch,
    handleSubmit: editCompany,
  };
};

export default useEditCompany;
