import React from "react";

import { Button, LabelContentTable } from "@/components";
import { useModal } from "@/hooks";

import ChangeDriverPasswordModal from "./changeDriverPasswordModal/ChangeDriverPasswordModal";
import * as S from "./IdPasswordInfo.styled";

interface IdPasswordInfoProps {
  id: string;
}

const IdPasswordInfo = ({ id }: IdPasswordInfoProps) => {
  const { handleModalOpen, modalRef } = useModal();

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Login ID">
          {id}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Password">
          <S.PasswordContent>
            <span>************</span>
            <Button
              css={S.changeBtn}
              variant="outlineXSmall"
              label="Change"
              handleClickBtn={handleModalOpen(
                <ChangeDriverPasswordModal ref={modalRef} />,
              )}
            />
          </S.PasswordContent>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default IdPasswordInfo;
