import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_scrollbar } from "@/styles";

export const Navbar = styled.nav`
  ${({ theme }) => css`
    ${mixin_scrollbar};
    position: fixed;
    top: ${theme.size.HEADER_HEIGHT};
    left: 0;
    width: ${theme.size.NAV_WIDTH};
    height: ${`calc(100% - ${theme.size.HEADER_HEIGHT})`};
    z-index: ${theme.zIndex.NAV};
    background-color: ${theme.color.secondary};
    overflow-x: hidden;
  `}
`;

export const link = (content: string) => (theme: Theme) =>
  css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    background-color: "transparent";

    :hover {
      background-color: ${theme.color.white_00_04};
      :after {
        ${theme.font.medium_13};
        content: "${content}";
        position: fixed;
        left: ${`calc(${theme.size.NAV_WIDTH} + 6px)`};
        border-radius: 2px;
        padding: 4px;
        color: ${theme.color.white};
        background-color: ${theme.color.secondary};
      }
    }

    & > svg path,
    & > svg rect {
      width: 20px;
      height: 20px;
      fill: rgba(255, 255, 255, 0.4);
    }

    &.active {
      background-color: ${theme.color.white_00_04};

      & > svg path,
      & > svg rect {
        fill: ${theme.color.white};
      }
    }
  `;
