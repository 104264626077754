import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  min-width: 139px;
  padding: 4px 0;
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    width: 100%;
    padding: 8px 12px;
    color: ${theme.color.gray_80};
    text-align: left;

    :hover {
      background-color: ${theme.color.gray_10};
    }
  `}
`;
