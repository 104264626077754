/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";
import { useTranslation } from "react-i18next";

import { ErrMsg } from "@/components";
import { DefaultArrowIcon } from "@/assets";
import type { CompanyInfo, Languages } from "@/types";

import * as S from "./SelectCompanyBtn.styled";

interface SelectCompanyBtnProps {
  className?: string;
  isDisabled?: boolean;
  label: Languages;
  err?: Languages;
  selectedCompany: CompanyInfo[];
  handleClickBtn: (e: React.MouseEvent) => void;
}

const SelectCompanyBtn = ({
  className,
  isDisabled,
  label,
  err,
  selectedCompany,
  handleClickBtn,
}: SelectCompanyBtnProps) => {
  const { t } = useTranslation();

  return (
    <>
      <S.SelectCompanyModalBtn
        className={className}
        disabled={isDisabled}
        hasCompany={!!selectedCompany}
        hasErr={!!err}
        onClick={handleClickBtn}
      >
        {selectedCompany.length === 0 ? (
          t(label)
        ) : (
          <S.SelectedCompanyInfoWrapper>
            <span>{selectedCompany[0].name}</span>
            {selectedCompany.length > 1 && (
              <S.SelectedCompanyCnt>
                + {selectedCompany.length - 1}
              </S.SelectedCompanyCnt>
            )}
          </S.SelectedCompanyInfoWrapper>
        )}
        <DefaultArrowIcon />
      </S.SelectCompanyModalBtn>
      {err && !isDisabled && <ErrMsg>{err}</ErrMsg>}
    </>
  );
};

export default SelectCompanyBtn;
