import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ErrMsg, Input } from "@/components";
import type { Languages } from "@/types";

import useUpdateFavoriteName from "./hooks/useUpdateFavoriteName";
import * as S from "./EditFavoriteNameDialog.styled";

interface EditFavoriteNameDialogProps {
  favoriteId: string;
  initName: string;
  isDialogOpen: boolean;
  handleCloseDialog: () => void;
}

const EditFavoriteNameDialog = ({
  favoriteId,
  initName,
  isDialogOpen,
  handleCloseDialog,
}: EditFavoriteNameDialogProps) => {
  const { t } = useTranslation();

  const { errors, register, watch, handleCloseModal, handleUpdateName } =
    useUpdateFavoriteName(
      initName,
      favoriteId,
      isDialogOpen,
      handleCloseDialog,
    );

  return (
    <S.Root>
      <S.ContentWrapper>
        <S.Label>{t("Favorite name")}</S.Label>
        <Input
          css={S.input}
          placeholder="Enter favorite name"
          value={watch("favoriteName")}
          hasError={!!errors.favoriteName?.message}
          register={register("favoriteName")}
        />
        {errors.favoriteName?.message && (
          <ErrMsg css={S.errMsg}>
            {errors.favoriteName.message as Languages}
          </ErrMsg>
        )}
      </S.ContentWrapper>
      <S.Bottom>
        <Button
          variant="ghostNoUnderlineGray"
          label="Cancel"
          handleClickBtn={handleCloseModal}
        />
        <Button
          isDisabled={Object.keys(errors).length > 0}
          variant="ghostNoUnderlinePrimary"
          label="Update"
          handleClickBtn={handleUpdateName}
        />
      </S.Bottom>
    </S.Root>
  );
};

export default EditFavoriteNameDialog;
