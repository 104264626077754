import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash-es";

import {
  Button,
  DetailModal,
  ErrMsg,
  FormRadioBtn,
  Input,
  LabelContent,
  Table,
  Textarea,
} from "@/components";
import { useModal, usePrompt } from "@/hooks";
import {
  calculateTotalAmount,
  calculateVatAmount,
  comma,
  deleteComma,
  filterTableColumns,
  findLookupTableLabel,
  formatPrice,
  numericOnly,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS, TAX_RADIOS } from "@/assets";
import { COMMON_VALID_MSG } from "@/constants";
import type { Languages } from "@/types";

import { formatTruckNumTypeOption, formatTrucksTableData } from "utils";
import {
  SEND_TO_SHIPPER_QUOTATION_FORM,
  TRUCKLIST_TABLE_COLUMNS,
} from "assets";
import type { GetQuotationDetailServerModel } from "types";
import { SendToShipperTruckForm } from "./container";
import QuotationRequoteModal from "../requoteModal/QuotationRequoteModal";
import QuotationRejectModal from "../rejectModal/QuotationRejectModal";
import useSendToShipperModal from "./hook/useSendToShipperModal";
import * as S from "./SendToShipperModal.styled";

interface SendToShipperModalProps {
  offerId: string;
  companyInfo: Record<string, string | number>;
  isSelfTransport: boolean;
  paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"];
  trucks?: GetQuotationDetailServerModel<
    "forwarder" | "forwardercarrier" | "shipper"
  >["history"][number]["trucks"];
}

const SendToShipperModal = React.forwardRef(
  (
    {
      offerId,
      companyInfo,
      isSelfTransport,
      paymentMethod,
      trucks,
    }: SendToShipperModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    const {
      formMethod,
      isSelectLoading,
      isRejectLoading,
      handleSend,
      handleRejectQuotationSend,
    } = useSendToShipperModal(offerId);
    const { modalRef, handleModalOpen } = useModal();

    usePrompt(!isEqual(formMethod.getValues(), SEND_TO_SHIPPER_QUOTATION_FORM));

    const filteredColumns = filterTableColumns(TRUCKLIST_TABLE_COLUMNS);

    const payMethodLabel = findLookupTableLabel(
      PAYMENT_METHOD_RADIOS,
      paymentMethod,
    );

    const totalPrice = calculateTotalAmount(
      `${formMethod.watch("subTotal")}`,
      `${formMethod.watch("tax")}`,
    );

    const vatAmount = calculateVatAmount(
      `${formMethod.watch("subTotal")}`,
      `${formMethod.watch("tax")}`,
    );

    const tableTruckNumTypeOption = formatTruckNumTypeOption(trucks ?? {});

    return (
      <FormProvider {...formMethod}>
        <DetailModal
          css={S.detailModal}
          ref={ref}
          type="submit"
          isLoading={isSelectLoading}
          isDisabled={Object.keys(formMethod.formState.errors).length > 0}
          title="Send to shipper"
          posBtnLabel="Send"
          closeBtnLabel="Reject"
          handleNegBtn={handleModalOpen(
            <QuotationRejectModal
              ref={modalRef}
              isLoading={isRejectLoading}
              handleReject={handleRejectQuotationSend(offerId)}
            />,
          )}
          callbackFn={handleSend}
        >
          <LabelContent
            css={S.labelContent}
            label="Trucking company"
            direction="horizontal"
            columnWidth={200}
          >
            <div>{companyInfo ? companyInfo.truckingCompany : "-"}</div>
          </LabelContent>
          <LabelContent
            css={S.verticalLabelContent}
            label="Truck list"
            direction="vertical"
          >
            {isSelfTransport ? (
              <>
                <SendToShipperTruckForm />
                <S.Hr />
              </>
            ) : (
              <Table
                css={S.table(tableTruckNumTypeOption.length)}
                title="Truck list"
                rowCount={tableTruckNumTypeOption.length}
              >
                <Table.Head columnList={TRUCKLIST_TABLE_COLUMNS} />
                <Table.Body css={S.tbody(tableTruckNumTypeOption.length > 0)}>
                  {tableTruckNumTypeOption.length ? (
                    tableTruckNumTypeOption.map((data, i) => (
                      <Table.Row key={i}>
                        {filteredColumns.map(({ key }) => (
                          <Table.Cell key={key}>
                            {formatTrucksTableData(key, data)}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))
                  ) : (
                    <Table.NoData />
                  )}
                </Table.Body>
              </Table>
            )}
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Payment method"
            direction="horizontal"
            columnWidth={200}
          >
            <S.PaymentMethodText>
              {payMethodLabel ? t(payMethodLabel) : "-"}
            </S.PaymentMethodText>
          </LabelContent>
          {!isSelfTransport && (
            <LabelContent
              css={S.labelContent}
              label="Received price"
              direction="horizontal"
              columnWidth={200}
            >
              <S.PriceInfoWrapper>
                <div>
                  {companyInfo ? companyInfo.receivedPrice : "-"}
                  <span> ({t("VAT Included")})</span>
                </div>
                <Button
                  variant="ghostPrimary"
                  label="Requote"
                  handleClickBtn={handleModalOpen(
                    <QuotationRequoteModal ref={modalRef} offerId={offerId} />,
                  )}
                />
              </S.PriceInfoWrapper>
            </LabelContent>
          )}
          <S.Hr />
          <LabelContent
            css={S.labelContent}
            label="Subtotal"
            direction="horizontal"
            columnWidth={200}
            isRequired
          >
            <div>
              <Input
                id="subTotal"
                maxLength={13}
                placeholder="Enter a price (only numbers)"
                value={formMethod.watch("subTotal")}
                hasError={!!formMethod.formState.errors.subTotal?.message}
                register={formMethod.register("subTotal", {
                  required: COMMON_VALID_MSG.REQUIRED,
                  setValueAs: (value: string) =>
                    comma(deleteComma(numericOnly(value))),
                })}
              />
              {formMethod.formState.errors.subTotal?.message && (
                <ErrMsg css={S.errMsg}>
                  {formMethod.formState.errors.subTotal.message as Languages}
                </ErrMsg>
              )}
            </div>
          </LabelContent>
          <LabelContent
            css={S.labelContent}
            label="Tax"
            direction="horizontal"
            columnWidth={200}
            isRequired
          >
            <FormRadioBtn
              radioList={TAX_RADIOS}
              register={formMethod.register("tax")}
            />
          </LabelContent>
          <LabelContent
            css={S.multiInfoLabelContent}
            label="Total"
            direction="horizontal"
            columnWidth={200}
          >
            <div>
              <S.TotalPriceText>{formatPrice(totalPrice)}</S.TotalPriceText>
              <S.PriceInfo>
                <LabelContent
                  label="Subtotal"
                  direction="horizontal"
                  columnWidth={80}
                >
                  <S.PriceText>
                    {formatPrice(+deleteComma(formMethod.watch("subTotal")))}
                  </S.PriceText>
                </LabelContent>
                <LabelContent
                  label="Tax"
                  direction="horizontal"
                  columnWidth={80}
                >
                  <S.PriceText>{`(${formMethod.watch("tax")}%) ${formatPrice(
                    vatAmount,
                  )}`}</S.PriceText>
                </LabelContent>
              </S.PriceInfo>
            </div>
          </LabelContent>
          <LabelContent
            css={S.multiInfoLabelContent}
            label="Memo"
            direction="horizontal"
            columnWidth={200}
          >
            <div>
              <Textarea
                id="memo"
                hasError={!!formMethod.formState.errors.memo?.message}
                placeholder="Please enter information about the amount"
                maxLength={150}
                value={formMethod.watch("memo") ?? ""}
                register={formMethod.register("memo")}
              />
              {formMethod.formState.errors.memo?.message && (
                <ErrMsg css={S.errMsg}>
                  {formMethod.formState.errors.memo.message as Languages}
                </ErrMsg>
              )}
            </div>
          </LabelContent>
        </DetailModal>
      </FormProvider>
    );
  },
);

SendToShipperModal.displayName = "SendToShipperModal";

export default SendToShipperModal;
