import { useEffect, useState } from "react";

import { useGoogleMap } from "@/contexts";
import { displayPolylines } from "@/utils";
import type { LatLng, OrsRes } from "@/types";

const useOrsPolylines = () => {
  const { googleMap } = useGoogleMap();

  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);

  const displayOrsPolylines = (orsRoutes: OrsRes) => {
    const coords = orsRoutes.features[0].geometry.coordinates.map<LatLng>(
      ([lng, lat]) => ({ lat, lng }),
    );

    const outlinePolyline = displayPolylines("outline", coords, googleMap);
    const inlinePolyline = displayPolylines("inline", coords, googleMap);

    setPolylines([outlinePolyline, inlinePolyline]);
  };

  const hideOrsPolyline = () => {
    polylines.forEach((polyline) => {
      polyline.setMap(null);
    });
  };

  useEffect(() => {
    return () => {
      hideOrsPolyline();
    };
  }, [polylines]);

  return { displayOrsPolylines, hideOrsPolyline };
};

export default useOrsPolylines;
