/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { memo } from "react";
import dayjs from "dayjs";

import { Calendar, DialogBtn, Input } from "@/components";
import { CalendarIcon } from "@/assets";
import type { Languages } from "@/types";

import * as S from "./CalendarInput.styled";

interface CalendarInputProps {
  className?: string;
  type?: Readonly<"date" | "free">;
  value: string;
  selectedDate: string[];
  hasTime?: boolean;
  errorId?: string;
  hasError?: boolean;
  placeholder?: Languages;
  disabledCondition?: (date: dayjs.Dayjs) => boolean;
  handleChangeDate: (date: dayjs.Dayjs[] | []) => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const CalendarInput = ({
  className,
  type,
  value,
  selectedDate,
  hasTime,
  errorId,
  hasError,
  placeholder = "Select the date",
  disabledCondition,
  handleChangeDate,
  handleFocusCondition,
  handleBlurCondition,
}: CalendarInputProps) => {
  return (
    <S.Root className={className}>
      <Input
        css={S.calendarInput}
        placeholder={placeholder}
        disabled
        value={value}
        errorId={errorId}
        hasError={hasError}
      />
      <DialogBtn
        css={S.calendarDialog}
        popup={({ isDialogOpen, handleDialogClose }) => (
          <Calendar
            isDialogOpen={isDialogOpen}
            hasTime={hasTime}
            type={type}
            selectedDate={selectedDate}
            disabledCondition={disabledCondition}
            handleChangeDate={handleChangeDate}
            handleDialogClose={handleDialogClose}
            handleFocusCondition={handleFocusCondition}
            handleBlurCondition={handleBlurCondition}
          />
        )}
      >
        <CalendarIcon css={S.calendarIcon} />
      </DialogBtn>
    </S.Root>
  );
};

export default memo(CalendarInput);
