import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 8px;
    color: ${theme.color.black};
  `}
`;

export const Field = styled.dl`
  ${({ theme }) => css`
    display: grid;
    row-gap: 1px;
    margin-bottom: 12px;
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.gray_30};
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 160px 1fr;
    align-items: center;
    padding: 16px 0;
    background-color: ${theme.color.white};

    dt,
    dd {
      padding: 0 25px;
    }

    dt {
      ${theme.font.medium_13};
      color: ${theme.color.gray_60};
    }

    dd {
      ${theme.font.regular_14};
      display: flex;
      color: ${theme.color.black};
    }
  `}
`;

export const changeBtn = css`
  margin-left: 8px;
`;
