import React from "react";

import { DataSection, Profile, StatusLabel } from "@/components";
import {
  addrFormatter,
  filterTableColumns,
  formatICTDateTime,
  formatOrderNum,
} from "@/utils";
import type { PageInfo, TableColumns } from "@/types";

import type {
  GetDriverAllocationServerModel,
  GetTruckAllocationServerModel,
} from "types";
import * as S from "./AllocationListSection.styled";

interface AllocationListSectionProps {
  columnArr: TableColumns<string>;
  dataArr?:
    | GetDriverAllocationServerModel["order"]
    | GetTruckAllocationServerModel["allocationList"];
  dataUpdatedAt: number;
  pageInfo?: PageInfo;
  refetch: () => void;
}

const AllocationListSection = ({
  columnArr,
  dataArr,
  dataUpdatedAt,
  pageInfo,
  refetch,
}: AllocationListSectionProps) => {
  const filteredColumns = filterTableColumns(columnArr);

  const formatTableData = <T extends Record<string, any>>( //TODO: 제네릭 -> 유니온 타입으로 변경 필요
    data: T,
    key: keyof T,
  ) => {
    switch (key) {
      case "status": {
        if (data[key] === "CANCELLED") return <StatusLabel status="CANCELED" />;
        //TODO: 서버 DB에서 CANCELLED 데이터 삭제 후 코드 삭제 예정

        return <StatusLabel status={data[key]} />;
      }

      case "puEta":
      case "doEta":
        return formatICTDateTime(data[key]);

      case "puAddress":
        return addrFormatter(data.puAddress, data.puAddressDetail);
      case "doAddress":
        return addrFormatter(data.doAddress, data.doAddressDetail);

      case "orderNumber":
        return <span>{formatOrderNum(data[key])}</span>;

      case "driverName":
        return (
          <S.Driver>
            {data[key] ? (
              <>
                <Profile src={data.driverProfileUrl ?? ""} />
                <span>{data[key]}</span>
              </>
            ) : (
              <span>-</span>
            )}
          </S.Driver>
        );

      default:
        return <span>{data[key] ?? "-"}</span>;
    }
  };

  if (!dataArr) return null;

  return (
    <DataSection
      dataUpdatedAt={dataUpdatedAt}
      totalData={pageInfo?.totalData ?? 0}
      refetch={refetch}
    >
      <DataSection.Table
        css={S.table}
        title="allocation"
        rowCount={dataArr.length}
        isStickyThead
      >
        <DataSection.Table.Head columnList={columnArr} />
        <DataSection.Table.Body css={S.body}>
          {dataArr.length ? (
            dataArr.map((item, idx) => {
              return (
                <DataSection.Table.Row key={idx}>
                  {filteredColumns.map(({ key }) => {
                    return (
                      <DataSection.Table.Cell key={key}>
                        {formatTableData(item, key as keyof typeof item)}
                      </DataSection.Table.Cell>
                    );
                  })}
                </DataSection.Table.Row>
              );
            })
          ) : (
            <DataSection.Table.NoData />
          )}
        </DataSection.Table.Body>
      </DataSection.Table>
    </DataSection>
  );
};

export default AllocationListSection;
