import React from "react";

import { ConfirmModal } from "@/components";

import * as S from "./QuotationConfirmModal.styled";

interface QuotationConfirmModalProps {
  handleConfirm: () => void;
}

const QuotationConfirmModal = React.forwardRef(
  (
    { handleConfirm }: QuotationConfirmModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        css={S.modal}
        ref={ref}
        title="Confirm quotation"
        desc={
          "Are you sure you want to confirm this quotation?\nYou can’t undo this action."
        }
        posBtnLabel="Send"
        closeBtnLabel="Cancel"
        callbackFn={handleConfirm}
      />
    );
  },
);

QuotationConfirmModal.displayName = "QuotationConfirmModal";
export default QuotationConfirmModal;
