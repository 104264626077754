/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { upperCase } from "lodash-es";

import { Button } from "@/components";
import { clientLanguage } from "@/stores";
import { makeCryptoFunction } from "@/utils";
import type { Languages } from "@/types";

import * as S from "./Pg.styled";

interface PgProps {
  amount: string;
  label: Languages;
}

const Pg = ({ amount, label }: PgProps) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const [language] = useRecoilState(clientLanguage);

  const timeStamp = new Date().getTime();
  const merId = "EPAY000001";
  const merTrxId = "EPAY0000011810202314380oZ4LSZ";
  const invoiceNo = id ?? "test";
  const encodeKey =
    "rf8whwaejNhJiQG2bsFubSzccfRc/iRYyGUn6SPmT6y/L7A2XABbu9y4GvCoSTOTpvJykFi6b1G0crU8et2O0Q==";
  const merchantToken = makeCryptoFunction(
    timeStamp + merTrxId + merId + amount + encodeKey,
  );

  useEffect(() => {
    const global = window as any;

    const handlePayment = () => {
      global.openPayment(1, "https://sandbox.megapay.vn");
    };

    // 버튼 클릭 시 Megapay Payment 모듈 실행
    const paymentButton = document.getElementById("paymentButton");
    if (paymentButton) {
      paymentButton.addEventListener("click", handlePayment);
    }
  }, []);

  return (
    <S.Form id="megapayForm" name="megapayForm" method="POST">
      <input type="text" id="merId" name="merId" value={merId} />
      <input type="text" id="currency" name="currency" value="VND" />
      <input type="text" id="amount" name="amount" value={amount} />
      <input type="text" id="invoiceNo" name="invoiceNo" value={invoiceNo} />
      <input type="text" id="goodsNm" name="goodsNm" value="test" />
      <input type="text" id="payType" name="payType" value="NO" />
      <input
        type="text"
        id="buyerEmail"
        name="buyerEmail"
        value="coconutsilo@test.com"
      />
      <input
        type="text"
        id="callBackUrl"
        name="callBackUrl"
        value={`https://${process.env.REACT_APP_URL}${pathname}`}
        // value="https://pg.megapay.vn/pg_was/new/sampleMerchant.do"
      />
      <input
        type="text"
        id="notiUrl"
        name="notiUrl"
        value={`https://${process.env.REACT_APP_URL}${pathname}`}
        // value="https://pg.megapay.vn/pg_proxy/api/notiProxyTest.do"
      />
      <input
        type="text"
        id="reqDomain"
        name="reqDomain"
        value={`https://${process.env.REACT_APP_URL}${pathname}`}
        // value="https://pg.megapay.vn/pg_was/test/samplePage.do"
      />
      <input type="text" id="fee" name="fee" value="0" />
      <input
        type="text"
        name="description"
        value="Your payment description here"
      />
      <input
        type="text"
        id="merchantToken"
        name="merchantToken"
        value={merchantToken}
      />
      <input type="text" id="userIP" name="userIP" value="192.168.220.1" />
      <input
        type="text"
        id="userLanguage"
        name="userLanguage"
        value={upperCase(language) === "VI" ? "VN" : "EN"}
      />
      <input type="text" id="timeStamp" name="timeStamp" value={timeStamp} />
      <input type="text" id="merTrxId" name="merTrxId" value={merTrxId} />
      <input type="text" id="windowColor" name="windowColor" value="#ef5459" />
      <input type="text" id="windowType" name="windowType" value="0" />
      <Button
        css={S.payNowBtn}
        type="button"
        id="paymentButton"
        variant="primaryMedium"
        label={label}
      />
    </S.Form>
  );
};

export default Pg;
