import React from "react";
import { FormProvider } from "react-hook-form";

import { Card, PageBackHeader } from "@/components";
import { PATH } from "@/assets";

import {
  TruckCreateAttachments,
  TruckCreateBtns,
  TruckCreateDefaultInfo,
  TruckCreateSpectification,
} from "components/domain/property/create";
import useTruckCreateForm from "./hooks/useTruckCreateForm";
import * as S from "./TruckCreate.styled";

const TruckCreate = () => {
  const { formMethod, createTruck, isCreateTruckLoading } =
    useTruckCreateForm();

  return (
    <S.Root>
      <FormProvider {...formMethod}>
        <form>
          <h3 className="a11y">Add the truck</h3>
          <PageBackHeader
            css={S.pageBackHeader}
            title="Add the truck"
            path={`${PATH.PROPERTY}?tab=truck`}
          />
          <Card css={S.card}>
            <TruckCreateDefaultInfo />
            <TruckCreateSpectification />
            <TruckCreateAttachments />
          </Card>
          <TruckCreateBtns
            isCreateTruckLoading={isCreateTruckLoading}
            createTruck={createTruck}
          />
        </form>
      </FormProvider>
    </S.Root>
  );
};

export default TruckCreate;
