import { ax } from "apis/axios";
import { AxiosResponse } from "axios";
import type {
  CompanyType,
  GetQuotationDetailServerModel,
  PostQuotationRequestQueryModel,
  PostSelectQuotationQueryModel,
  PostSendQuotationQueryModel,
} from "types";

export const getQuotationDetailAPI = async <T extends CompanyType>(
  id: string,
) => {
  const { data } = await ax.get<GetQuotationDetailServerModel<T>>(
    `/offer/detail/${id}`,
  );

  return data;
};

export const patchRequestQuotationRejectAPI = async (offerId: string) =>
  await ax.patch(`/offer/reject/${offerId}`);

export const patchSendQuotationRejectAPI = async (offerId: string) =>
  await ax.patch(`/offer/refuse/${offerId}`);

export const cancelQuotationAPI = async (offerId: string) =>
  await ax.patch(`/offer/cancel/${offerId}`);

export const patchQuotationRequoteAPI = async (offerId: string) =>
  await ax.patch(`/offer/rerequest/${offerId}`);

export async function postSelectQuotationAPI(
  req: PostSelectQuotationQueryModel<"shipper">,
  clientType: "shipper",
): Promise<AxiosResponse<any, any>>;
export async function postSelectQuotationAPI(
  req: PostSelectQuotationQueryModel<"forwarder" | "forwardercarrier">,
  clientType: "forwarder" | "forwardercarrier",
): Promise<AxiosResponse<any, any>>;
export async function postSelectQuotationAPI(
  req: PostSelectQuotationQueryModel<CompanyType>,
  clientType: "forwardercarrier" | "forwarder" | "shipper",
) {
  return ax.post(`/offer/select/${req.id}`, "body" in req ? req.body : {});
}
export async function postQuotationRequestAPI(
  req: PostQuotationRequestQueryModel<"shipper">,
  clientType: "shipper",
): Promise<AxiosResponse<any, any>>;
export async function postQuotationRequestAPI(
  req: PostQuotationRequestQueryModel<"forwarder">,
  clientType: "forwarder",
): Promise<AxiosResponse<any, any>>;
export async function postQuotationRequestAPI(
  req: PostQuotationRequestQueryModel<"forwardercarrier">,
  clientType: "forwardercarrier",
): Promise<AxiosResponse<any, any>>;
export async function postQuotationRequestAPI(
  req: PostQuotationRequestQueryModel<
    "shipper" | "forwarder" | "forwardercarrier"
  >,
  clientType: "shipper" | "forwarder" | "forwardercarrier",
) {
  return ax.post("/offer/request", req.body);
}

export const postQuotationSendAPI = async (req: PostSendQuotationQueryModel) =>
  await ax.post(`/offer/send/${req.id}`, req.body);
