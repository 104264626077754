import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { infoWindow } from "@/styles";
import type { AddressType } from "@/types";

export const searchLocModal = css`
  width: 900px;
  position: relative;
`;

export const LocInfoWrapper = styled.div<{ type: AddressType }>`
  ${({ theme, type }) => css`
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
    background-color: ${theme.color.gray_10};
    svg {
      margin: 0 auto;
      path {
        fill: ${type === "pickUp"
          ? theme.color.primary_40
          : type === "dropOff"
          ? theme.color.red_20
          : theme.color.gray_80};
      }
    }
  `}
`;

export const Location = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    color: ${theme.color.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Address = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    grid-column: 2;
    color: ${theme.color.gray_70};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const Map = styled.section`
  ${infoWindow.common};

  width: 836px;
  height: 484px;
  margin: 0;
`;
