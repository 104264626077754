import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { useAuth } from "@/contexts";

interface PublicRouteProps {
  path: string;
}

const PublicRoute = ({ path }: PublicRouteProps) => {
  const { auth } = useAuth();

  return auth.isAuth ? <Navigate to={path} /> : <Outlet />;
};

export default PublicRoute;
