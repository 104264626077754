/* eslint-disable no-useless-escape */

import { checkTwoDecimalDigit } from "../validator";

const checkFirstNum = (v: string | number) => {
  if (v === "0") {
    return v;
  }

  if (typeof v === "string" && v[0] === "0") {
    v = v.slice(1);
  }
  return v;
};

export const numericOnly = (v: string | number) => {
  const regex = /[^0-9]/g;
  v = checkFirstNum(v);

  return `${v}`.replaceAll(regex, "");
};
// NOTE: toLocaleString을 사용하면 hook-form의 setValueAs에서 적용되지 않음
export const comma = (input: string | number) =>
  numericOnly(input).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const formatterDecimal = (value: string) => {
  return value.replace(/\.$/, "");
};

export const numericOnlyWithColons = (v: string) => {
  // eslint-disable-next-line
  const regex = /[^0-9\:]/g;

  return v.replaceAll(regex, "");
};

export const isNumber = (string: string) => {
  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식

  return regExp.test(string);
};

export const onlyNumber = (string: string) => {
  if (!string) return "";

  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  return string.replace(regExp, "");
};

export const formatTwoDecimalDigit = (
  prevValue: string,
  currentValue: string,
) => {
  if (currentValue && !checkTwoDecimalDigit(currentValue)) {
    return prevValue;
  }

  return currentValue;
};

export const formatPrice = (price: number) => `${comma(price)} ₫`;
export const formatWeight = (weight: number) => `${comma(weight)}kg`;
export const formatQuantity = (quantity: number) => `${comma(quantity)}Qty`;
