import { css, Theme } from "@emotion/react";

import { FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_CELL_SIZES } from "assets";

export const checktable = (theme: Theme) => css`
  tr {
    grid-template-columns: ${FORWARDER_FORWARDERCARRIER_QUOTATION_PENDING_TABLE_CELL_SIZES};
  }

  & > tbody {
    height: ${theme.size.TABLE_BODY_HEIGHT};
  }
`;

export const link = (theme: Theme, isDisabled: boolean) => css`
  ${theme.button.primaryMedium};
  opacity: ${isDisabled && "0.2"};
  cursor: ${isDisabled && "not-allowed"};

  &:not(:disabled):hover {
    background-color: ${isDisabled && theme.color.primary_40};
  }
`;

export const linknoData = (theme: Theme) => css`
  ${theme.button.outlineMedium};
  margin: 16px 0 0;
`;
