import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { COMMON_VALID_MSG } from "@/constants";

import { useGetAccountInfo, useUpdateAccountInfo } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

const schema = yup
  .object({
    lastName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    middleName: yup.string().defined(),
    firstName: yup.string().required(COMMON_VALID_MSG.REQUIRED),
    phone: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  })
  .required();

const useEditDefaultInfo = (successFn: () => void) => {
  const formMethod = useForm({
    defaultValues: {
      lastName: "",
      middleName: "",
      firstName: "",
      phone: "",
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { data } = useGetAccountInfo();
  const { isLoading: isUpdateLoading, mutate: updateAccountInfoMutate } =
    useUpdateAccountInfo();
  const { addToast } = useToast();

  const updateAccountInfo = formMethod.handleSubmit((data) => {
    updateAccountInfoMutate(
      { body: data },
      {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.ACCOUNT_DEFAULT_UPDATE_DONE);
          successFn();
        },
      },
    );
  });

  useEffect(() => {
    if (!data) return;

    formMethod.reset({
      lastName: data.lastName,
      middleName: data.middleName ?? "",
      firstName: data.firstName,
      phone: data.phone,
    });
  }, [data]);

  return {
    isUpdateLoading,
    data,
    formMethod,
    handleSubmit: updateAccountInfo,
  };
};

export default useEditDefaultInfo;
