import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  getSettlementDetailAPI,
  patchSettlementDetailAccept,
  patchSettlementDetailReject,
  updateSettlementDetailAPI,
} from "apis";
import type {
  CompanyType,
  PatchSettlementDetailAccept,
  PatchSettlementDetailReject,
  UpdateSettlementDetailQueryModal,
} from "types";

const settlementDetailKeys = {
  all: ["settlementDetail"] as const,
  details: () => [...settlementDetailKeys.all, "detail"] as const,
  detail: (offerId: string) =>
    [...settlementDetailKeys.details(), { offerId }] as const,
};

export const useGetSettlementDetail = <T extends CompanyType>(
  offerId: string,
) => {
  return useQuery({
    queryKey: settlementDetailKeys.detail(offerId),
    queryFn: () => getSettlementDetailAPI<T>(offerId),
  });
};

export const useUpdateSettlementDetail = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateSettlementDetailQueryModal
  >({
    mutationFn: (req) => updateSettlementDetailAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: settlementDetailKeys.details(),
      });
    },
  });
};

export const usePatchSettlementAccept = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PatchSettlementDetailAccept) =>
      patchSettlementDetailAccept(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: settlementDetailKeys.details(),
      });
    },
  });
};

export const usePatchSettlementReject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PatchSettlementDetailReject) =>
      patchSettlementDetailReject(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: settlementDetailKeys.details(),
      });
    },
  });
};
