export const checkEmailValidation = (email: string) => {
  const checkEmail =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@([\w-])+(\.[a-zA-Z]{2,4}){1,2}$/;
  // 64, 63: 백에서 쓰는 joi 라이버러리의 최대 값

  const [id, domain] = email.split(/[.@]/);

  if (id?.length >= 64 || domain?.length >= 63) return false;
  return checkEmail.test(email);
};
