import { calcTableWidth } from "@/utils";
import { CELL } from "@/assets";

export const TRUCKLIST_TABLE_COLUMNS = [
  [CELL.TRUCKTYPE],
  [CELL.TRUCKOPTION],
  [CELL.NUMBEROFTRUCKS_TRUCKNUM],
] as const;

export const DETAIL_TRUCKLIST_TABLE_CELL_SIZES = calcTableWidth([
  "270px",
  "267px",
  ["151px", "auto"],
]);
