import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import type { SettlementStatus } from "@/types";

export const Layout = styled.section`
  width: fit-content;
  padding: 32px 0 0 40px;
`;

export const detailLayout = (status: SettlementStatus) => (theme: Theme) => {
  if (status === "ONGOING") {
    return css`
      & > div > div {
        row-gap: 0px;

        section:nth-last-of-type(3) {
          border-bottom-style: dashed;
        }

        & > section:nth-last-of-type(2) {
          margin-bottom: 32px;
          border-top: 0px;
          background-color: ${theme.color.gray_10};
        }
      }
    `;
  }

  return css``;
};

export const tableOfContent = css`
  margin-left: 139px;
`;

export const Heading = styled.div`
  ${({ theme }) => css`
    ${theme.font.bold_18};
  `}
`;

export const backLink = css`
  width: fit-content;
  margin: 32px 0 0 auto;
  padding: 8px 16px;
`;
