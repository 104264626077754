import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { GARAGE_LINK_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${GARAGE_LINK_TABLE_CELL_SIZES};

    & > td:last-of-type {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }
`;

export const tBody = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};

  & > tr {
    &[aria-selected="true"] td,
    &:hover td {
      background-color: ${theme.color.gray_00};
    }

    & td:last-of-type {
      z-index: 5;
    }

    &:nth-of-type(20) {
      border-bottom: 0;
    }
  }
`;

export const Linked = styled.div`
  display: grid;
  grid-template-columns: 1fr 24px;
  column-gap: 8px;
  align-items: center;
  width: 100%;
`;

export const Driver = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 8px;
  align-items: center;

  & > figure {
    margin-right: 8px;
  }

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const OtherDriverNum = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;

export const LinkBtn = styled.button`
  ${({ theme }) => css`
    vertical-align: middle;
    width: 40px;
    height: 40px;

    & > svg > path {
      fill: ${theme.color.primary_40};
    }

    &[disabled] > svg > path {
      fill: ${theme.color.gray_40};
    }
  `}
`;

export const cell = (isSelected: boolean) => (theme: Theme) =>
  css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${isSelected && `${theme.color.gray_10}`};
  `;
