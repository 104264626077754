import { css, Theme } from "@emotion/react";

import {
  FORWARDER_QUOTATION_CANCELED_TABLE_CELL_SIZES,
  SHIPPER_FORWARDERCARRIER_CARRIER_QUOTATION_CANCELED_TABLE_CELL_SIZES,
} from "assets";
import type { CompanyType } from "types";

export const table = (clientType: CompanyType) => css`
  tr {
    grid-template-columns: ${clientType === "shipper" ||
    clientType === "forwardercarrier" ||
    clientType === "carrier"
      ? SHIPPER_FORWARDERCARRIER_CARRIER_QUOTATION_CANCELED_TABLE_CELL_SIZES
      : FORWARDER_QUOTATION_CANCELED_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

export const linknoData = (theme: Theme) => css`
  ${theme.button.outlineMedium};
  margin: 16px 0 0;
`;

export const cell = css`
  :nth-last-of-type(2) {
    display: inline-flex;
  }
`;

export const linkDetail = (theme: Theme) => css`
  ${theme.button.ghostGray};
  z-index: ${theme.zIndex.ROW_DETAIL_BUTTON};
`;
