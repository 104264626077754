import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const Btn = styled.button`
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const AutoCompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
`;

export const AutoCompleteItem = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`;

export const DialogWrapper = styled.div`
  position: relative;
`;
export const plusCircleIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.primary_40};
  }
`;

export const deleteIcon = (theme: Theme) => css`
  path {
    fill: ${theme.color.red_20};
  }
`;
