import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { DialogBtn, LinkMenuDialog, Profile } from "@/components";
import { MoreIcon } from "@/assets";

import { AutoCompletes } from "components";
import { useGetPropertyLinkDriver } from "services";
import type { PropertyDetailEditCardType, AutoCompleteDropdown } from "types";
import { useLinkDriver } from "../../../hooks";
import * as S from "./LinkingDriver.styled";

interface LinkingDriverProps {
  editState: PropertyDetailEditCardType | null;
  handleClickEdit: (editName: PropertyDetailEditCardType | null) => () => void;
  handleCancel: () => void;
}

const LinkingDriver = ({
  editState,
  handleClickEdit,
  handleCancel,
}: LinkingDriverProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  // TODO: driverName 필터링을 프론트에서 하고 있기 때문에 QueryModel 수정 필요
  const { data: linkDrivers } = useGetPropertyLinkDriver(
    { query: {} },
    { enabled: editState === "DefaultInfo-link" },
  );

  const {
    formMethod,
    fields,
    handleLinkSelect,
    handleLinkSlotAdd,
    handleLinkSlotDelete,
    handleLinkDriver,
    handleUnlinkDriver,
    handleResetEdit,
  } = useLinkDriver(handleClickEdit);

  const formatLinkDrivers = linkDrivers?.reduce(
    (acc: AutoCompleteDropdown[], current) => {
      if (current.truckId === id || !current.truckId) {
        acc.push({ name: current.name, id: current.driverId });
      }

      return acc;
    },
    [],
  );

  const autoCompletesData = {
    placeholder: "Enter the driver's name",
    label: "link",
  } as const;

  const handleClickAddLink = () => {
    handleClickEdit("DefaultInfo-link")();
  };

  const handleCancelLinking = () => {
    handleCancel();
    handleResetEdit();
  };

  return (
    <>
      {editState === "DefaultInfo-link" ? (
        <FormProvider {...formMethod}>
          <S.AutoCompleteWrapper>
            {formatLinkDrivers && (
              <AutoCompletes
                placeholder="Enter the driver's name"
                id="link"
                dropdowns={formatLinkDrivers}
                fields={fields}
                handleDropdownSelect={handleLinkSelect}
                handleDropdownSlotDelete={handleLinkSlotDelete}
                handleDropdownSlotAdd={handleLinkSlotAdd}
              />
            )}
            <S.BtnWrapper>
              <S.CancelBtn type="button" onClick={handleCancelLinking}>
                {t("Cancel")}
              </S.CancelBtn>
              <S.UpdateBtn
                type="button"
                disabled={!!formMethod.formState.errors.autoCompletes?.length}
                onClick={handleLinkDriver}
              >
                {t("Update")}
              </S.UpdateBtn>
            </S.BtnWrapper>
          </S.AutoCompleteWrapper>
        </FormProvider>
      ) : (
        <S.LinkContainer>
          <S.LinkedDriverWrapper>
            {fields[0] && fields[0].name ? (
              fields.map((driver) => (
                <S.Driver key={driver.id}>
                  <Profile src={driver.profileUrl} />
                  <span>{driver.name}</span>
                </S.Driver>
              ))
            ) : (
              <S.AddBtn type="button" onClick={handleClickAddLink}>
                {`+ ${t("Add a link")}`}
              </S.AddBtn>
            )}
          </S.LinkedDriverWrapper>
          {fields[0].name && (
            <DialogBtn
              css={S.moreDialogBtn}
              popup={() => (
                <LinkMenuDialog
                  handleClickEdit={handleClickEdit("DefaultInfo-link")}
                  handleUnlink={handleUnlinkDriver}
                />
              )}
            >
              <MoreIcon />
            </DialogBtn>
          )}
        </S.LinkContainer>
      )}
    </>
  );
};

export default LinkingDriver;
