import emotionReset from "emotion-reset";
import { css } from "@emotion/react";

import { mixin_scrollbar, theme } from "@/styles";
import "./fonts.css";

const globalStyles = css`
  ${emotionReset}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: ${theme.color.black};
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    height: 100%;
  }
  html {
    font-size: 62.5%; // 10px
  }
  body {
    ${mixin_scrollbar};
  }

  #root {
    display: grid;
    grid-template-rows: 1fr ${theme.size.FOOTER_HEIGHT};
    width: 100%;
    height: 100%;
    font-family: "Pretendard", sans-serif;
  }
  .a11y {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  button {
    cursor: pointer;
    border: 0;
    background-color: transparent;

    &[disabled] {
      cursor: not-allowed;
    }
  }
  input,
  button,
  textarea {
    font-family: "Pretendard", sans-serif;
  }
  textarea {
    resize: none;
    outline: 0;

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray_50};
    }
    ::-webkit-scrollbar-track {
      margin: 8px;
    }
  }
  a {
    text-decoration: none;
  }
  table,
  tr,
  th,
  td {
    border-collapse: collapse;
  }
  dialog {
    border: none;
  }

  input {
    outline: 0;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  }
  [href]:focus-visible,
  [tabindex]:focus-visible,
  input:focus-visible,
  button:focus-visible {
    outline-color: ${theme.color.primary_40};
  }
  .ReactQueryDevtools {
    height: 0;
    & div {
      color: white !important;
    }
  }
`;

export default globalStyles;
