import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { LabelContentTable, ModalPagination } from "@/components";
import { findLookupTableLabel, formatWeight, formatQuantity } from "@/utils";
import { CARGO_TYPE, PACKAGING_TYPE } from "@/assets";

import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationCargoDetails.styled";

interface QuotationCargoDetailsProps {
  data: GetQuotationDetailServerModel<CompanyType>;
}

const QuotationCargoDetails = ({ data }: QuotationCargoDetailsProps) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const handleMovePage = (pageNumber: number) => setCurrentPage(pageNumber);

  const cargo = data.cargoes[currentPage - 1];

  const cargoTypeLabel =
    findLookupTableLabel(CARGO_TYPE, cargo.cargoType) ?? "";
  const packagingTypeLabel =
    findLookupTableLabel(PACKAGING_TYPE, cargo.packagingType) ?? "";

  return (
    <>
      <LabelContentTable variant="underline" css={S.labelContentTable}>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Type">
            {cargoTypeLabel ? t(cargoTypeLabel) : cargo.cargoType}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Packaging type">
            {packagingTypeLabel ? t(packagingTypeLabel) : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Cargo">
            {`${cargo.width}m x ${cargo.height}m x ${
              cargo.length
            }m / ${formatWeight(cargo.weight)} / ${formatQuantity(
              cargo.cargoCnt,
            )}`}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Hazard goods">
            {cargo.hazardGoods ? t("Yes") : t("No")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Stackable">
            {cargo.doubleLoading ? t("Yes") : t("No")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <ModalPagination
        css={S.pagination}
        currentPage={currentPage}
        pageInfo={{
          currentPage,
          dataPerPage: 10,
          startRow: 1,
          totalData: data.cargoes.length,
          totalPages: data.cargoes.length,
        }}
        movePage={handleMovePage}
      />
    </>
  );
};

export default QuotationCargoDetails;
