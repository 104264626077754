import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Card, ErrMsg, FormFileInput, LabelContent } from "@/components";
import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG, convertMB, FILE_DESC } from "@/constants";
import type { Languages } from "@/types";

import { CARRIER_VALID_MSG } from "constants/index";
import type { TruckForm } from "types";
import { TRUCK_FORM_INIT_STATE } from "pages/property/truck/create/hooks/useTruckCreateForm";
import * as S from "./TruckCreateAttachments.styled";

const TruckCreateAttachments = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const {
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    resetField,
    register,
  } = useFormContext<TruckForm>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name } = e.target;
    const id = name.split(".").at(-1);
    const key = name as keyof typeof TRUCK_FORM_INIT_STATE;

    switch (id) {
      case "registration":
      case "truckPhoto": {
        if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
          addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
          setError(key, { type: "capacityOver" });
          setValue(key, null);
        } else {
          clearErrors(key);
        }
        break;
      }
    }
  };

  const handleDelFile = (name: string) => (): void => {
    if (name === "registration") {
      setError(name, { message: CARRIER_VALID_MSG.REQUIRED });
    }

    resetField(name as keyof TruckForm);
  };

  return (
    <Card.Content heading="Attachments">
      <LabelContent
        label="Vehicle registration certificate"
        direction="vertical"
        isRequired
      >
        <FormFileInput // TODO: api 수정 이후 file 타입 일괄 수정 필요
          placeholder="Vehicle registration certificate"
          hasErr={!!errors.registration}
          file={watch("registration")}
          fileKey="registration"
          handleDeleteFile={handleDelFile}
          handleInputChange={handleFileChange}
          register={register}
        />
        {errors.registration?.message && (
          <ErrMsg>{errors.registration?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Truck photo" direction="vertical">
        <FormFileInput // TODO: api 수정 이후 file 타입 일괄 수정 필요
          placeholder="Truck photo"
          hasErr={!!errors.truckPhoto}
          file={watch("truckPhoto")}
          fileKey="truckPhoto"
          handleDeleteFile={handleDelFile}
          handleInputChange={handleFileChange}
          register={register}
        />
        <S.Desc>- {t(FILE_DESC)}</S.Desc>
      </LabelContent>
    </Card.Content>
  );
};

export default TruckCreateAttachments;
