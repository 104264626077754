import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useScript from "react-script-hook";
import i18n from "i18next";

import { clientLanguage } from "@/stores";

import * as S from "./PrivateLayout.styled";

interface PrivateLayoutProps {
  header: React.ReactNode;
  navbar: React.ReactNode;
  footer: React.ReactNode;
}

const PrivateLayout = ({ header, navbar, footer }: PrivateLayoutProps) => {
  const language = useRecoilValue(clientLanguage);
  const [loading] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLEMAP_KEY}&libraries=places&language=${language}`,
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <>
      {header}
      {navbar}
      {!loading && (
        <S.PrivateMain>
          <Outlet />
        </S.PrivateMain>
      )}
      {footer}
    </>
  );
};

export default PrivateLayout;
