import React, { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NoResult, QueryPagination } from "@/components";
import { useAlignCenter } from "@/hooks";
import { formatICTDateTime, formatPhoneNum } from "@/utils";

import {
  BtnFoldInfo,
  TruckStatusInfo,
  DriverInfo,
  TruckAllocationInfo,
} from "components";
import { useMonitoringTruckMarker } from "hooks";
import { useGetMonitoringTruckDetail } from "services";
import { foldCardAnimate } from "utils";
import type { GetMonitoringTrucksClientModel } from "types";
import { useTruckMarkerEventListener, useTruckSelect } from "../../hooks";
import * as S from "./MonitoringShippingList.styled";

interface TruckMonitoringShippingListProps {
  trucks: GetMonitoringTrucksClientModel;
}

const TruckMonitoringShippingList = ({
  trucks,
}: TruckMonitoringShippingListProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const truckRef = useRef<{ [key: string]: HTMLLIElement | null }>({});

  const truckId = searchParams.get("truckId");

  const { data: truckDetail } = useGetMonitoringTruckDetail(truckId!, {
    suspense: false,
    enabled: !!truckId,
  });

  const { truckMarkers } = useMonitoringTruckMarker(trucks.trucks);

  const monitoringTrucks = trucks.trucks.map((truck, i) => ({
    ...truck,
    ...truckMarkers[i],
  }));

  const { handleTruckCardClick, handleTruckMarkerClick } = useTruckSelect(
    monitoringTrucks,
    truckRef,
  );

  useTruckMarkerEventListener(handleTruckMarkerClick, monitoringTrucks);
  useAlignCenter(trucks.coords);

  return (
    <>
      {trucks.trucks.length ? (
        <>
          <S.MonitoringShippingListWrapper>
            {trucks.trucks.map((truck) => {
              const isFold = truck.truckId !== truckId;

              return (
                <li
                  key={truck.truckId}
                  ref={(el) => (truckRef.current[truck.truckId] = el)}
                >
                  <BtnFoldInfo
                    isFold={isFold}
                    truck={truck}
                    status="SHIPPING"
                    handleFoldBtnClick={handleTruckCardClick(truck.truckId)}
                  />
                  {!isFold && truckDetail && (
                    <S.TruckDetailInfoWrapper
                      layout
                      initial="closed"
                      animate={isFold ? "closed" : "open"}
                      exit="closed"
                      variants={foldCardAnimate(
                        searchParams.get("type") === "card",
                      )}
                      transition={{ duration: 0.3 }}
                    >
                      <TruckStatusInfo
                        truckStatus="SHIPPING"
                        truckInfo={{
                          lat: truck.lat,
                          lng: truck.lng,
                          addr: truck.addr,
                          speed: truck.speed,
                          temperature: truck.temperature,
                        }}
                      />
                      <DriverInfo
                        driverInfo={{
                          title: "Driver",
                          name: truckDetail.driverInfo.name ?? "-",
                          phone: formatPhoneNum(truckDetail.driverInfo.phone),
                          addr: truckDetail.driverInfo.started
                            ? `${t("Starts")} ${formatICTDateTime(
                                truckDetail.driverInfo.started,
                              )}`
                            : "-",
                        }}
                      />
                      <TruckAllocationInfo
                        trucksAllocations={truckDetail.allocations}
                      />
                    </S.TruckDetailInfoWrapper>
                  )}
                </li>
              );
            })}
          </S.MonitoringShippingListWrapper>
          <QueryPagination css={S.pagination} pageInfo={trucks.pageInfo} />
        </>
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default TruckMonitoringShippingList;
