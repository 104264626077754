import React, { useEffect } from "react";
import i18n from "i18next";

import { LoginForm } from "@/components";

import { usePostLogin } from "services";

const Login = () => {
  const { mutate: loginMutate } = usePostLogin();

  useEffect(() => {
    i18n.changeLanguage("vi");
  }, []);

  return <LoginForm loginMutate={loginMutate} />;
};

export default Login;
