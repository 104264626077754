import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  height: 44px;

  & > input[aria-expanded="true"] ~ ul {
    position: absolute;
    top: 44px;
    display: block;
  }
`;

export const input = css`
  height: inherit;
`;

export const DropDownWrapper = styled.ul`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    position: absolute;
    top: 44px;
    width: 100%;
    max-height: 200px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_dropdown};
    overflow: auto;
    z-index: 10;
  `}
`;

export const DropDownItem = styled.li`
  ${({ theme }) => css`
    & > button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      padding: 8px 12px;
      color: ${theme.color.gray_80};

      :hover {
        color: ${theme.color.primary_40};
        font-weight: 600;
      }
    }
  `}
`;
