/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash-es";

import { CheckboxBtn, NoResult, Portal } from "@/components";
import { useDomReady } from "@/hooks";
import type { Languages, TableColumn, TableColumns } from "@/types";

import useTableScrollTop from "../hooks/useTableScrollTop";
import * as S from "./CheckTable.styled";

interface CheckTableProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  isStickyThead?: boolean;
  columnList: TableColumns<string>;
  isAllChecked: boolean;
  handleAllCheck?: () => void;
}

interface RowProps {
  className?: string;
  children: React.ReactNode;
  handleMouseOver?: React.MouseEventHandler<HTMLElement>;
  handleMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

interface SelectRowProps extends RowProps {
  id: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  selectFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface SelectRowMovePageProps extends RowProps {
  id: string;
  path: string;
  state?: Record<string, string | null>;
}

interface TdProps {
  className?: string;
  children: React.ReactNode;
}

interface CheckTdProps {
  className?: string;
  children: React.ReactNode;
}

const CheckTable = ({
  className,
  children,
  title,
  isStickyThead = false,
  columnList,
  isAllChecked,
  handleAllCheck,
}: CheckTableProps) => {
  const { t } = useTranslation();
  const { tableRef } = useTableScrollTop();

  const hasDepth = columnList.length !== columnList.flat(2).length;

  return (
    <>
      <S.Table
        className={className}
        ref={tableRef}
        isStickyThead={isStickyThead}
      >
        <caption className="a11y">{title}</caption>
        <thead>
          <S.Trow>
            <S.CheckTh scope="col" rowCnt={hasDepth ? 3 : 1}>
              <CheckboxBtn
                css={S.checkBtn}
                isChecked={isAllChecked}
                shape="rectangle"
                handleCheck={handleAllCheck}
              />
            </S.CheckTh>
            {columnList.map((columns, i) => {
              if (columns.length === 1) {
                const column = columns[0] as TableColumn<string>;

                return (
                  <S.Th key={column.key} rowCnt={hasDepth ? 3 : 1} scope="col">
                    {t(column.label as Languages)}
                  </S.Th>
                );
              }

              return columns.map((column) => {
                if (Array.isArray(column)) {
                  return column.map((c) => (
                    <S.Th key={c.key} scope="col" hasMultiCol>
                      {t(c.label as Languages)}
                    </S.Th>
                  ));
                } else {
                  return (
                    <S.Th
                      key={column.key}
                      scope="col"
                      hasMultiCol
                      isLast={columnList.length - 1 === i}
                      rowCnt={1}
                      columnCnt={(columns as TableColumn<string>[][])[1].length}
                    >
                      {t(column.label as Languages)}
                    </S.Th>
                  );
                }
              });
            })}
          </S.Trow>
        </thead>
        <S.Tbody>
          {isEmpty(children) ? <CheckTable.NoData /> : children}
        </S.Tbody>
      </S.Table>
    </>
  );
};

CheckTable.Row = function Row({
  className,
  children,
  handleMouseOver,
  handleMouseLeave,
}: RowProps) {
  return (
    <S.Row
      className={className}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </S.Row>
  );
};

CheckTable.SelectRow = function SelectRow({
  className,
  children,
  id,
  isSelected = false,
  isDisabled,
  selectFn: selectCb,
}: SelectRowProps) {
  const domReady = useDomReady();

  const rowId = `table-row-${id}`;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isDisabled) return;

    selectCb(e);
  };

  return (
    <>
      <S.SelectRow className={className} id={rowId} isSelected={isSelected}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          <S.RowBtn type="button" disabled={isDisabled} onClick={handleClick}>
            <span className="a11y">select row</span>
          </S.RowBtn>
        </td>
      </Portal>
    </>
  );
};

CheckTable.SelectRowMovePage = function SelectRowMovePage({
  className,
  children,
  id,
  path,
  state,
}: SelectRowMovePageProps) {
  const domReady = useDomReady();

  const rowId = `table-row-${id}`;

  return (
    <>
      <S.SelectRow className={className} id={rowId}>
        {children}
      </S.SelectRow>
      <Portal container={`#${rowId}`} mounted={domReady}>
        <td>
          {/* // TODO: 상태값 추가 되면 삭제하기 */}
          <Link css={S.rowlink} to={path} state={state}>
            <span className="a11y">select row</span>
          </Link>
        </td>
      </Portal>
    </>
  );
};

CheckTable.Td = function Td({ className, children }: TdProps) {
  return <S.Td className={className}>{children}</S.Td>;
};

CheckTable.CheckTd = function CheckTd({ className, children }: CheckTdProps) {
  return <S.CheckTd className={className}>{children}</S.CheckTd>;
};

interface NodataProps {
  className?: string;
  children?: React.ReactNode;
}

CheckTable.NoData = function NoData({ className, children }: NodataProps) {
  return (
    <S.NoData className={className}>
      <td>
        <NoResult />
        {children}
      </td>
    </S.NoData>
  );
};

export default CheckTable;
