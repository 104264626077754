import React from "react";

import { ConfirmModal } from "@/components";
import { useModal } from "@/hooks";

import * as S from "./DelFavoriteOrderModal.styled";

interface DelFavoriteOrderModalProps {
  favoriteId: string;
  handleDelFavoriteOrder: (favoriteId: string) => void;
}

const DelFavoriteOrderModal = React.forwardRef(
  (
    { favoriteId, handleDelFavoriteOrder }: DelFavoriteOrderModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { handleModalClose } = useModal();

    const handleDelete = () => {
      handleDelFavoriteOrder(favoriteId);
      handleModalClose();
    };

    return (
      <ConfirmModal
        css={S.confirmModal}
        ref={ref}
        title="Delete favorite"
        desc={`Are you sure you want to delete the favorite?\nYou can’t undo this action.`}
        closeBtnLabel="Cancel"
        negBtnLabel="Delete"
        callbackFn={handleDelete}
      />
    );
  },
);

DelFavoriteOrderModal.displayName = "DelFavoriteOrderModal";

export default DelFavoriteOrderModal;
