import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const detailModal = css`
  & > div:first-of-type {
    margin-bottom: 20px;
  }
`;

export const CalendarInputValue = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const CalendarInputPlaceholder = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_40};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const DateTimeLabel = styled.label`
  ${({ theme }) => css`
    ${theme.font.medium_13};
    position: relative;
    color: ${theme.color.gray_70};
  `}
`;

export const DateInputContent = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 35px 1fr;
    align-items: center;
    margin-bottom: 20px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 4px;
    }

    & > span {
      margin: auto 8px;
      color: ${theme.color.black};
      text-align: center;
    }
  `}
`;

interface DateBtnProps {
  isFocus: boolean;
  hasError: boolean;
}

export const DateBtn = styled.button<DateBtnProps>`
  ${({ theme, isFocus, hasError }) => css`
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid
      ${isFocus
        ? theme.color.primary_40
        : hasError
        ? theme.color.red_20
        : theme.color.gray_35};
    padding: 8px 11px;
  `}
`;

export const CalendarFlexWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 5px;
    padding: 20px;
  `}
`;

export const CanlendarWrapper = styled.div`
  height: 300px;
  border-radius: 5px;
`;

export const Division = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 320px;
    margin: 0 28px;
    border-right: 1px solid ${theme.color.gray_20};
  `}
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-bottom: 20px;
`;

const monthBtnMixin = (theme: Theme) => css`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  & > svg path {
    fill: ${theme.color.gray_60};
  }
`;

export const PrevMonthBtn = styled.button`
  ${({ theme }) => css`
    ${monthBtnMixin(theme)};
    left: 0;
  `}
`;

export const NextMonthBtn = styled.button`
  ${({ theme }) => css`
    ${monthBtnMixin(theme)};
    right: 0;
  `}
`;

export const MonthYear = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    display: flex;
    column-gap: 4px;
    color: ${theme.color.black};
  `}
`;

export const MonthYearContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      ${theme.font.medium_15};
    }
  `}
`;

export const WeekRow = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 36px);
    margin-bottom: 12px;

    > li {
      ${theme.font.regular_12};
      color: ${theme.color.gray_70};
      text-align: center;
    }
  `}
`;

export const DateRow = styled.ul`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 36px);
    row-gap: 4px;
    margin-bottom: 10px;
    color: ${theme.color.gray_70};
  `}
`;

export const MoveBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const MoveBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg {
      fill: ${theme.color.gray_60};
    }

    :hover {
      border-radius: 50%;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const DateValueWrapper = styled.div`
  flex: 1;
  text-align: start;
`;

export const timeInput = css`
  width: 65px;
  height: 38px;
  border: 0;
  padding: 8px 0 8px 11px;

  &:focus {
    border: 0;
  }
`;

export const Bar = styled.span`
  ${({ theme }) => css`
    width: 1px;
    height: 20px;
    background-color: ${theme.color.gray_30};
  `}
`;

export const errMsg = (theme: Theme) => css`
  width: fit-content;
  /* NOTE: !important 미설정 시 margin / color가 적용되지 않아 추가 */
  margin: 4px 0 0 !important;
  color: ${theme.color.red_20} !important;
`;
