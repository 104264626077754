import { css, Theme } from "@emotion/react";

import { ORDER_COMPLETED_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${ORDER_COMPLETED_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;
