import { QuestionIcon } from "@/assets";

export const CARGO_TOOLTIP = {
  title: "Cargo size",
  contents: "You can enter up to two decimal point the cargo size.",
  tooltipBtnContent: QuestionIcon,
} as const;

export const LOADING_STATUS_TOOLTIP = {
  title: "LTL/FTL",
  contents:
    "- LTL: Using the transport truck with other customers, it is cheaper than FTL.(*may not be available depending on the region)\n- FTL: Using the transport truck alone.",
  tooltipBtnContent: QuestionIcon,
} as const;

export const CONTAINER_TOOLTIP = {
  id: "container",
  title: "Container size",
  contents:
    "You can enter up to two decimal point the container size.\nPlease enter a valid container size.",
} as const;

export const PROFILE_TOOLTIP = {
  title: "Profile picture",
  contents: "Profile picture are recommended in a 1:1 ratio.",
  tooltipBtnContent: QuestionIcon,
} as const;
