import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DialogBtn, StatusLabel, InternalLink, MoreDialog } from "@/components";
import { formatICTDateTime, formatOrderNum } from "@/utils";
import { PATH, TimeIcon, TruckIcon, OrderIcon } from "@/assets";

import type { AllocationStatus } from "@/types";

import { ClickCard } from "components";
import type { AllocationDriver } from "types";
import * as S from "./OngoingCard.styled";

interface OngoingCardProps {
  data: AllocationDriver & { allocationId?: string };
  isClickAble?: boolean;
  category?: AllocationStatus;
}

const OngoingCard = ({
  data,
  isClickAble = false,
  category,
}: OngoingCardProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  return (
    <ClickCard
      isClickAble={isClickAble}
      isSelected={
        data.allocationId === (searchParams.get("allocationId") as string)
      }
      // TODO: 로직 수정하기
      path={`/allocation?tab=ongoing&allocationId=${
        data.allocationId
      }&driverId=${data.driverId}&category=${category}&page=${
        searchParams.get("page") || "1"
      }${
        searchParams.get("search")
          ? `&search=${searchParams.get("search")}`
          : ""
      }`}
    >
      <ClickCard.Header>
        <h3>{data.driverName}</h3>
        {data.status && <StatusLabel status={data.status} />}
      </ClickCard.Header>
      <ClickCard.Body>
        <ClickCard.BodyDetail>
          <TruckIcon css={S.icon} />
          <span>{`${t(data.truckType) ?? "-"} (${
            data.registNumber ?? "-"
          })`}</span>
        </ClickCard.BodyDetail>
        <ClickCard.BodyDetail>
          <OrderIcon css={S.icon} />
          {isClickAble ? (
            <span>{formatOrderNum(data.orders[0].orderNumber)}</span>
          ) : (
            <InternalLink
              css={S.orderLink}
              variant="ghostNoUnderlineGray"
              to={`${PATH.ORDER_MANAGEMENT}/${data.orders[0].orderId}`}
            >
              {formatOrderNum(data.orders[0].orderNumber)}
            </InternalLink>
          )}
          {data.orders.length > 1 ? (
            isClickAble ? (
              <S.MoreOrderCnt>+{data.orders.length - 1}</S.MoreOrderCnt>
            ) : (
              <DialogBtn
                css={S.moreBtn}
                popup={() => <MoreDialog orders={data.orders} />}
              >
                +{data.orders.length - 1}
              </DialogBtn>
            )
          ) : null}
        </ClickCard.BodyDetail>
        <ClickCard.BodyDetail>
          <TimeIcon css={S.icon} />
          <time>
            ({t("Requested")}) {formatICTDateTime(data.requested)}
          </time>
        </ClickCard.BodyDetail>
      </ClickCard.Body>
    </ClickCard>
  );
};

export default OngoingCard;
