import type { PickDropType } from "@/types";

import type { AllocationRoute, SelectedAllocationDelivery } from "types";

export const makeDeliveryToAllocation = (
  route: SelectedAllocationDelivery,
  type: PickDropType,
): AllocationRoute => {
  switch (type) {
    case "PICK":
      return {
        orderId: route.orderId,
        loadingStatus: route.loadingStatus,
        memo: route.memo,
        idx: route.idx,
        cargoes: route.cargoes,
        city: route.puCity,
        name: route.puName,
        addr: route.puAddr,
        addrDetail: route.puAddrDetail,
        pic: route.puPic,
        phone: route.puPhone,
        lat: route.puLat,
        lng: route.puLng,
        eta: route.puEta,
        etd: route.puEtd,
        id: `${route.orderId} PICK`,
        type: "PICK" as PickDropType,
      };

    case "DROP":
      return {
        orderId: route.orderId,
        loadingStatus: route.loadingStatus,
        memo: route.memo,
        idx: route.idx,
        cargoes: route.cargoes,
        city: route.doCity,
        name: route.doName,
        addr: route.doAddr,
        addrDetail: route.doAddrDetail,
        pic: route.doPic,
        phone: route.doPhone,
        lat: route.doLat,
        lng: route.doLng,
        eta: route.doEta,
        etd: route.doEtd,
        id: `${route.orderId} DROP`,
        type: "DROP" as PickDropType,
      };
  }
};
