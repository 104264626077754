import { css, Theme } from "@emotion/react";

export const modal = css`
  width: 424px;
`;

export const labelContent = (theme: Theme) => css`
  margin-bottom: 24px;

  && > :nth-of-type(1) > label {
    margin-bottom: 2px;
    color: ${theme.color.gray_60};
  }
`;
export const errorMsg = css`
  margin-top: 4px;
`;
