import { useSearchParams } from "react-router-dom";

import type { Languages, SearchLabelsType } from "@/types";

interface Label {
  searchs: string[];
  queryKey: string;
  options?: { key: string; label: Languages }[];
  link?: string;
}

type Labels = Record<string, Label>;

const useFilterSearchLabel = (searchLabels: SearchLabelsType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const labels = searchLabels.reduce<Labels>(
    (acc, { label, queryKey, options, link }) => {
      acc[label] = {
        queryKey,
        searchs: searchParams.getAll(queryKey),
        ...(options && { options }),
        ...(link && { link }),
      };
      return acc;
    },
    {},
  );

  const hasLabel = Object.values(labels).some(
    ({ searchs }) => searchs.length !== 0,
  );

  const handleDeleteSearchLabel =
    (queryKey: string, search: string, link?: string) => () => {
      const searchs = searchParams.getAll(queryKey);

      searchParams.delete(queryKey);
      link && searchParams.delete(link);

      const updatedSearchs = searchs.filter((value) => value !== search);
      updatedSearchs.forEach((search) => searchParams.append(queryKey, search));

      searchParams.get("page")
        ? searchParams.set("page", "1")
        : searchParams.append("page", "1");

      setSearchParams(searchParams);
    };

  const handleResetSearchLabel = () => {
    const tabQuery = searchParams.get("tab");

    setSearchParams({ ...(tabQuery && { tab: tabQuery }) });
  };

  return {
    labels,
    hasLabel,
    handleDeleteSearchLabel,
    handleResetSearchLabel,
  };
};

export default useFilterSearchLabel;
