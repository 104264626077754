import type { DropdownOptionType } from "@/types";

export const selectDropdownForm =
  (
    dropdowns: readonly DropdownOptionType[],
    onChange: (...event: any[]) => void, // NOTE: react hook form의 Controller onChange 타입이 ...event: any[] 타입입니다.
  ) =>
  (selectDropdownKey: string): void => {
    const selectIndex = dropdowns.findIndex(
      ({ key }) => key === selectDropdownKey,
    );

    onChange(dropdowns[selectIndex]);
  };
