import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetQuotationCanceled } from "services";
import { CompanyType, GetQuotationCanceledQueryModel } from "types";
import QuotationCanceledSection from "./section/QuotationCanceledSection";
import * as S from "./QuotationCanceled.styled";

interface QuotationCanceledProps {
  clientType: CompanyType;
}

const QuotationCanceled = ({ clientType }: QuotationCanceledProps) => {
  const { filters } = useFilterParams<GetQuotationCanceledQueryModel["query"]>([
    "page",
  ]);

  const { data, dataUpdatedAt, refetch } = useGetQuotationCanceled<
    typeof clientType
  >({ query: filters });

  return (
    <>
      {data && (
        <>
          <QuotationCanceledSection
            data={data}
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default QuotationCanceled;
