import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { mixin_limit_one_line } from "@/styles";
import type { PickDropType } from "@/types";

interface OrderCardProps {
  isMute?: boolean;
  dropArea?: boolean;
  isLifted?: boolean;
  needDragDrop?: boolean;
}

export const orderCard =
  ({ isMute, dropArea, isLifted, needDragDrop }: OrderCardProps) =>
  (theme: Theme) =>
    css`
      position: relative;
      display: grid;
      border: ${isLifted && `1px solid ${theme.color.primary_40}`};
      border-radius: 5px;
      box-shadow: ${dropArea ? `0 0 0 3px rgba(68, 147, 249, 0.65)` : ""};
      background-color: ${isLifted && theme.color.primary_10};
      opacity: ${isLifted ? 0.2 : 1};
      cursor: ${needDragDrop && "grab"};
      z-index: ${dropArea && 10};
      & * {
        pointer-events: ${isMute && "none"};
      }
    `;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const pointIcon = (type: PickDropType) => (theme: Theme) =>
  css`
    margin-right: 5px;

    path {
      fill: ${type === "PICK" ? theme.color.primary_40 : theme.color.red_20};
    }
  `;

export const orderTitle = css`
  ${mixin_limit_one_line};
  max-width: 200px;
  margin: 0;
  margin-right: 4px;
`;

export const OrderIdx = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    margin-right: 8px;
    color: ${theme.color.gray_60};
  `}
`;

// TODO: puName이 길어질 경우 짤릴 수 있어 수정 필요
export const phoneTooltip = (theme: Theme) => css`
  > dl {
    width: 220px;

    > dd {
      display: flex;
      align-items: center;
      column-gap: 8px;

      > svg {
        width: 16px;
        height: 16px;

        path {
          fill: ${theme.color.gray_70};
        }
      }
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${theme.color.gray_35};
    border-radius: 2px;
    padding: 0;

    & > svg {
      width: 14px;
      height: 14px;

      > path {
        fill: ${theme.color.gray_60};
      }
    }

    :hover {
      background-color: ${theme.color.gray_10};
    }
  }
`;

export const Address = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    max-width: 307px;
    border-bottom: 1px solid ${theme.color.gray_20};
    padding-bottom: 12px;
    color: ${theme.color.gray_80};
    word-break: break-all;
  `}
`;

export const MsgWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 32px;
    min-height: 60px;
    margin-top: 12px;
    border-radius: 2px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const Content = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: flex;
    align-items: center;
    padding: 10px 16px;
    white-space: pre-wrap;
    word-break: break-all;
    text-overflow: ellipsis;
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-left: 1px solid ${theme.color.gray_40};

    & > button {
      padding: 9px 10px;
    }

    > svg > path {
      fill: ${theme.color.gray_60};
    }
  `}
`;

export const WriteMsgForm = styled.form`
  position: relative;
  margin-top: 12px;
`;

export const textarea = (theme: Theme) => css`
  ${theme.font.regular_14};
  width: 100%;
  min-height: 40px;
  max-height: 310px;
  border: 1px solid ${theme.color.gray_30};
  border-radius: 2px;
  padding: 10px calc(24px + 20px) 10px 12px;
  outline-color: ${theme.color.primary_40};
  overflow: hidden;

  ::placeholder {
    color: ${theme.color.gray_50};
  }

  :focus {
    border: 1px solid ${theme.color.primary_40};
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 9px;
  right: 8px;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const MessageTitle = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    & > span {
      color: ${theme.color.gray_70};
    }
  `}
`;

export const ContentLength = styled.span<{ isEnter: boolean }>`
  ${({ theme, isEnter }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};

    & > span {
      color: ${isEnter ? theme.color.primary_40 : theme.color.gray_60};
    }
  `}
`;

export const SubmitWriteMsgBtn = styled.button<{ isMsg: boolean }>`
  ${({ theme, isMsg }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: ${isMsg ? theme.color.primary_40 : theme.color.gray_20};
    color: ${isMsg ? theme.color.white : theme.color.gray_50};

    & > svg {
      width: 18px;
      height: 18px;
      transform: rotate(90deg);

      path {
        fill: ${isMsg ? theme.color.white : theme.color.gray_50};
      }
    }
  `}
`;

export const createMsgBtn = css`
  width: fit-content;
  margin-top: 12px;
  text-align: left;
`;

export const orderInfoRow = css`
  border-bottom: 0;

  & > div {
    > span,
    div {
      padding-bottom: 0 !important;
    }
  }
`;

export const orderInfoEtdRow = (isNeedMemo: boolean) => (theme: Theme) =>
  css`
    border-bottom: ${isNeedMemo ? `1px solid ${theme.color.gray_30}` : 0};

    & > div {
      span,
      div {
        padding-bottom: ${!isNeedMemo && "0"} !important;
      }
    }
  `;

export const trashIcon = (theme: Theme) => css`
  & > g > path {
    fill: ${theme.color.gray_60};
  }
`;

export const pencilIcon = (theme: Theme) => css`
  & > path {
    fill: ${theme.color.gray_60};
  }
`;
