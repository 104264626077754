import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { PROPERTY_TRUCK_TABLE_CELL_SIZES } from "assets";

export const table = css`
  tr {
    grid-template-columns: ${PROPERTY_TRUCK_TABLE_CELL_SIZES};
  }
`;

export const body = (theme: Theme) => css`
  height: ${theme.size.TABLE_BODY_HEIGHT};
`;

export const Truck = styled.div<{ hasCnt?: boolean }>`
  ${({ hasCnt }) => css`
    display: grid;
    grid-template-columns: ${hasCnt ? "24px 1fr 24px" : "1fr 24px"};
    align-items: center;
    column-gap: 8px;
    height: 40px;
  `}
`;

export const Name = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Count = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const gpsIcon = (hasGps: boolean) => (theme: Theme) =>
  css`
    & > path {
      fill: ${hasGps ? theme.color.green_20 : theme.color.gray_40};
    }
  `;
