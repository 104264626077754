import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
`;

export const DropDownWrapper = styled.ul<{ isExpanded: boolean }>`
  ${({ isExpanded, theme }) => css`
    position: absolute;
    display: ${isExpanded ? "block" : "none"};
    top: 44px;
    width: 100%;
    max-height: 200px;
    margin-top: 4px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 10px;
    background-color: ${theme.color.white};
    box-shadow: 0 1px 3px 0 ${theme.color.gray_50};
    overflow: auto;
    z-index: 10;
  `}
`;

export const DropDownItem = styled.li`
  ${({ theme }) => css`
    & > button {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 36px;
      padding: 0 12px;

      > span {
        ${theme.font.regular_13};
        color: ${theme.color.gray_70};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      :hover {
        color: ${theme.color.primary_40};
        font-weight: 600;
      }
    }
  `}
`;
