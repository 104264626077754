import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";

import { useUpdateFavoriteOrderName } from "services";

const schema = yup.object({
  favoriteName: yup.string().required(COMMON_VALID_MSG.REQUIRED).max(30),
});

const useUpdateFavoriteName = (
  initName: string,
  favoriteId: string,
  isDialogOpen: boolean,
  handleCloseDialog: () => void,
) => {
  const {
    formState: { errors },
    clearErrors,
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      favoriteName: initName,
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const { mutate: updateFavoriteOrderMutate, isLoading } =
    useUpdateFavoriteOrderName();

  const handleCloseModal = () => {
    clearErrors();
    handleCloseDialog();
  };

  const handleUpdateName = handleSubmit((data) => {
    const req = {
      favoriteId,
      body: { favoriteName: data.favoriteName },
    };

    updateFavoriteOrderMutate(req, {
      onSuccess: () => {
        handleCloseDialog();
      },
      onError: () => {
        // TODO: API error에 따라 설정해주기
        addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
      },
    });
  });

  useEffect(() => {
    if (isDialogOpen) {
      setValue("favoriteName", initName);
    }
    return () => {
      clearErrors();
    };
  }, [isDialogOpen]);

  return {
    isLoading,
    errors,
    watch,
    register,
    handleCloseModal,
    handleUpdateName,
  };
};

export default useUpdateFavoriteName;
