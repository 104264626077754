import { css } from "@emotion/react";

import { theme } from "../themes";

const mixinBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 5px;

  &[disabled] {
    color: ${theme.color.gray_50};
    background-color: ${theme.color.gray_20};
  }
`;

export const mixinBtn_primary = css`
  ${mixinBtn};
  color: ${theme.color.white};
  background-color: ${theme.color.primary_40};

  &:not(:disabled):hover {
    background-color: ${theme.color.primary_50};
  }
`;

export const mixinBtn_secondary = css`
  ${mixinBtn};
  border: 1px solid ${theme.color.gray_30};
  color: ${theme.color.gray_60};
  background-color: ${theme.color.white};

  &:not(:disabled):hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const mixinBtn_16 = css`
  ${theme.font.semibold_12};
  height: 16px;
  border: 1px solid ${theme.color.gray_30};
  border-radius: 5px;
  padding: 0 4px;
  color: ${theme.color.gray_50};

  &:not(:disabled):hover {
    background-color: ${theme.color.gray_10};
  }

  &[disabled] {
    opacity: 50%;
  }
`;

export const mixinBtn_36 = css`
  ${theme.font.semibold_14};
  height: 36px;
  padding: 0 14px;
`;

export const mixinBtn_40 = css`
  ${theme.font.semibold_14};
  height: 40px;
  padding: 0 15px;
`;

export const mixinBtn_rectangle = css`
  height: 38px;
  border: 1px solid ${theme.color.gray_30};
  padding: 9px 13px;

  & > span {
    ${theme.font.medium_14};
    color: ${theme.color.gray_60};
  }

  &:not(:disabled):hover {
    background-color: ${theme.color.gray_10};
  }

  &[disabled] {
    opacity: 50%;
  }
`;

export const mixinBtn_ghost_b = css`
  ${theme.font.regular_14};
  color: ${theme.color.primary_40};

  &:not(:disabled):hover {
    color: ${theme.color.primary_50};
  }

  &:disabled {
    color: ${theme.color.gray_60};
  }
`;

export const mixinBtn_ghost_g = css`
  ${theme.font.regular_14};
  color: ${theme.color.gray_60};

  &:not(:disabled):hover {
    color: ${theme.color.gray_80};
  }
`;

export const mixinBtn_icon = css`
  border-radius: 5px;
  padding: 4px;

  &:not(:disabled):hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const mixinBtn_icon_text_ghost_g = css`
  span {
    ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  }
  &:not(:disabled):hover path {
    fill: ${theme.color.gray_80};
  }
  &:not(:disabled):hover span {
    color: ${theme.color.gray_80};
  }
`;

export const mixinBtn_icon_text_ghost_b = css`
  span {
    ${theme.font.regular_14};
    color: ${theme.color.primary_40};
  }
  &:not(:disabled):hover path {
    fill: ${theme.color.primary_50};
  }
  &:not(:disabled):hover span {
    color: ${theme.color.primary_50};
  }
`;
