import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > p {
      ${theme.font.regular_14};
      color: ${theme.color.gray_50};
    }
  `}
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    svg path {
      fill: ${theme.color.gray_40};
    }
  `}
`;
