import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import type { UseFormRegisterReturn } from "react-hook-form";

import type { RadioType } from "@/types";
import * as S from "./FormRadioBtn.styled";

interface RadioBtnProps {
  className?: string;
  isDisabled?: boolean;
  radioList: Readonly<RadioType[]>;
  register: UseFormRegisterReturn<string>;
}

const FormRadioBtn = ({
  className,
  isDisabled,
  radioList,
  register,
}: RadioBtnProps) => {
  const uuid = useId();
  const { t } = useTranslation();

  return (
    <S.RadioWrapper className={className}>
      {radioList.map((item) => (
        <S.Label key={item.key} disabled={isDisabled}>
          <input
            type="radio"
            id={item.key + uuid}
            disabled={isDisabled}
            value={item.key}
            {...register}
          />
          <S.RadioBtn htmlFor={item.key + uuid} />
          <span>{t(item.label)}</span>
        </S.Label>
      ))}
    </S.RadioWrapper>
  );
};

export default FormRadioBtn;
