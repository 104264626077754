/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React, { useId } from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegisterReturn } from "react-hook-form";

import type { Languages } from "@/types";

import * as S from "./CheckboxBtn.styled";

interface CheckboxBtnProps {
  className?: string;
  disabled?: boolean;
  isChecked?: boolean;
  register?: UseFormRegisterReturn<string>;
  label?: Languages;
  shape: "circle" | "rectangle";
  handleCheck?: () => void;
}

const CheckboxBtn = ({
  className,
  disabled,
  isChecked = false,
  register,
  label,
  shape,
  handleCheck,
}: CheckboxBtnProps) => {
  const uuid = useId();
  const { t } = useTranslation();

  return (
    <S.Wrapper className={className}>
      <S.Input
        disabled={disabled}
        checked={isChecked}
        type="checkbox"
        readOnly
        id={uuid}
        onChange={handleCheck}
        {...register}
      />
      <S.CheckboxClickAbleArea
        className="label"
        htmlFor={uuid}
        disabled={disabled}
      >
        <S.CheckBox shape={shape} className="box" tabIndex={0} />
      </S.CheckboxClickAbleArea>
      {label && <S.Label htmlFor={uuid}>{t(label)}</S.Label>}
    </S.Wrapper>
  );
};

export default CheckboxBtn;
