import { useState, useRef } from "react";

import type { AutoCompleteDropdown } from "types";

const useAutoComplete = (
  searchValue: string,
  selectDropdown: (selectedDropdown: AutoCompleteDropdown) => void,
  dropdowns: AutoCompleteDropdown[],
) => {
  const listRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (): void => {
    setIsFocused(false);

    const equalData = dropdowns.filter(
      (item) => item.name === (searchValue ?? ""),
    );

    if (equalData.length) {
      selectDropdown(equalData[0]);
    } else {
      selectDropdown({ name: searchValue ?? "", id: "" });
    }
  };

  const handleSelectOption =
    (selectedDropdown: AutoCompleteDropdown) => (): void => {
      setIsFocused(false);
      selectDropdown(selectedDropdown);
    };

  return {
    isFocused,
    listRef,
    handleSelectOption,
    handleFocus,
    handleBlur,
  };
};

export default useAutoComplete;
