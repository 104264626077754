import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  cancelOrderManagementAPI,
  getOrderManagementAllocationAPI,
  getOrderManagementCanceledAPI,
  getOrderManagementCompletedAPI,
  getOrderManagementDetailAPI,
  getOrderManagementDetailTrackingHistoryAPI,
  getOrderManagementShippingAPI,
  updateOrderManagementDetailAPI,
} from "apis";
import type {
  CompanyType,
  GetOrderAllocationQueryModel,
  GetOrderManagementCanceledQueryModel,
  GetOrderManagementCompletedQueryModel,
  GetOrderManagementShippingQueryModel,
  GetOrderManagementDetailServerModel,
  UpdateOrderManagementDetailQueryModel,
} from "types";

const orderKeys = {
  all: ["Order"] as const,
  lists: () => [...orderKeys.all, "Orders"] as const,
  list: <T>(type: string, filters: T) =>
    [...orderKeys.lists(), { type, filters }] as const,
  details: () => [...orderKeys.all, "OrderDetail"] as const,
  detail: (id: string) => [...orderKeys.details(), id] as const,
  trackingHistories: () =>
    [...orderKeys.all, "OrderDetailTrackingHistory"] as const,
  trackingHistory: (orderId: string) =>
    [...orderKeys.trackingHistories(), orderId] as const,
};

export const useGetOrderManagementAllocation = <T extends CompanyType>(
  filters: GetOrderAllocationQueryModel<T>,
) => {
  return useQuery({
    queryKey: orderKeys.list("allocation", filters),
    queryFn: () => getOrderManagementAllocationAPI<T>(filters),
    suspense: false,
  });
};

export const useGetOrderManagementShipping = (
  filters: GetOrderManagementShippingQueryModel,
) => {
  return useQuery({
    queryKey: orderKeys.list("shipping", filters),
    queryFn: () => getOrderManagementShippingAPI(filters),
  });
};

export const useGetOrderManagementCompleted = (
  filters: GetOrderManagementCompletedQueryModel,
) => {
  return useQuery({
    queryKey: orderKeys.list("completed", filters),
    queryFn: () => getOrderManagementCompletedAPI(filters),
  });
};

export const useGetOrderManagementCanceled = (
  filters: GetOrderManagementCanceledQueryModel,
) => {
  return useQuery({
    queryKey: orderKeys.list("canceled", filters),
    queryFn: () => getOrderManagementCanceledAPI(filters),
  });
};

interface UseGetOrderDetailProps {
  suspense: boolean;
  orderId: string;
}

export const useGetOrderManagementDetail = ({
  suspense,
  orderId,
}: UseGetOrderDetailProps): UseQueryResult<GetOrderManagementDetailServerModel> => {
  return useQuery({
    queryKey: orderKeys.detail(orderId),
    queryFn: () => getOrderManagementDetailAPI(orderId),
    suspense,
  });
};

export const useUpdateOrderManagementDetail = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateOrderManagementDetailQueryModel
  >({
    mutationFn: (req) => updateOrderManagementDetailAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(orderKeys.detail(req.orderNumber));
    },
  });
};

export const useCancelOrderManagement = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError<typeof COMMON_ERROR_CODE>, string>({
    mutationFn: (orderNumber) => cancelOrderManagementAPI(orderNumber),
    onSuccess: (_, orderNumber) => {
      queryClient.invalidateQueries(orderKeys.detail(orderNumber));
    },
  });
};

export const useOrderDetailTrackingHistory = (orderId: string) => {
  return useQuery({
    queryKey: orderKeys.trackingHistory(orderId),
    queryFn: () => getOrderManagementDetailTrackingHistoryAPI({ orderId }),
  });
};
