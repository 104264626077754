import React from "react";
import { useTranslation } from "react-i18next";
import type { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { DetailLayout, TableOfContent, PreviousPageLink } from "@/components";
import { PATH } from "@/assets";
import type { SettlementStatus } from "@/types";

import * as S from "./SettlementDetailLayout.styled";

interface SettlementDetailLayoutProps {
  status: SettlementStatus;
  content: {
    heading: { headingLevel: "h2"; text: string };
    content: EmotionJSX.Element;
  }[];
}

const SettlementDetailLayout = ({
  status,
  content,
}: SettlementDetailLayoutProps) => {
  const { t } = useTranslation();

  return (
    <DetailLayout css={S.detailLayout(status)}>
      <TableOfContent
        css={S.tableOfContent}
        pageName="Settlement details"
        contents={content}
      />
      <PreviousPageLink
        css={S.backLink}
        variant="outlineMedium"
        to={PATH.SETTLEMENT}
      >
        {t("Back to list")}
      </PreviousPageLink>
    </DetailLayout>
  );
};

export default SettlementDetailLayout;
