import React from "react";

import {
  QuotationCanceled,
  QuotationCompleted,
  QuotationLayout,
  QuotationOngoing,
  QuotationPending,
} from "components";

const ForwarderCarrierQuotation = () => {
  const render = {
    pending: <QuotationPending />,
    ongoing: <QuotationOngoing clientType="forwardercarrier" />,
    completed: <QuotationCompleted clientType="forwardercarrier" />,
    canceled: <QuotationCanceled clientType="forwardercarrier" />,
  } as const;

  return <QuotationLayout render={render} />;
};

export default ForwarderCarrierQuotation;
