export const DRIVER_DELIVERY_STATUS = {
  COMPLETED: "Completed",
  REQUESTED: "Requested",
  ALLOCATED: "Allocated",
  TO_PICKUP: "Shipping",
  TO_DROPOFF: "Shipping",
  REJECTED: "Rejected",
  EMPTY_TRUCK: "Empty truck",
} as const;

export const DELIVERY_LOG_LABELS = {
  doAtd: "Drop-off ATD",
  doAta: "Drop-off ATA",
  puAtd: "Pick-up ATD",
  puAta: "Pick-up ATA",
  shipmentsStart: "Shipments start",
} as const;
