import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable } from "@/components";
import {
  createAttachFiles,
  findLookupTableLabel,
  makeOrderFileName,
} from "@/utils";
import { LOADING_METHOD_TYPE, LOADING_STATUS_RADIOS } from "@/assets";
import type { Languages } from "@/types";

import { useDownloadFile } from "services";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementTruckingMethods.styled";

interface OrderManagementTruckingMethodsProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementTruckingMethods = ({
  data,
}: OrderManagementTruckingMethodsProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  const loadingStatusLabel =
    findLookupTableLabel(
      LOADING_STATUS_RADIOS,
      data.truckingMethods.loadingStatus,
    ) ?? "-";

  const loadingMethodLabel =
    findLookupTableLabel(
      LOADING_METHOD_TYPE,
      data.truckingMethods.loadingMethod,
    ) ?? data.truckingMethods.loadingMethod;

  const { file1, file2 } = data.truckingMethods;

  const files = createAttachFiles([file1, file2]);

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading status">
          {t(loadingStatusLabel as Languages)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading/unloading method">
          {t(loadingMethodLabel as Languages)}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Attached file">
          <S.DownloadBtnWrapper>
            {files.length ? (
              <>
                {files.map((fileKey, idx) => (
                  <DownloadBtn
                    key={fileKey}
                    downloadFileNamePrefix="OrderAttachedFile"
                    fileName={makeOrderFileName(fileKey, idx)}
                    fileKey={fileKey}
                    isLoading={isLoading}
                    downloadFileMutate={downloadFileMutate}
                  />
                ))}
              </>
            ) : (
              "-"
            )}
          </S.DownloadBtnWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Memo">
          {data.truckingMethods.orderMemo ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default OrderManagementTruckingMethods;
