import React from "react";

import { Table } from "@/components";
import { filterTableColumns } from "@/utils";

import { formatTrucksTableData, formatTruckNumTypeOption } from "utils";
import { TRUCKLIST_TABLE_COLUMNS } from "assets";
import type { GetOrderManagementDetailServerModel } from "types";
import * as S from "./OrderManagementTruckList.styled";

interface OrderManagementTruckListProps {
  data: GetOrderManagementDetailServerModel;
}

const OrderManagementTruckList = ({ data }: OrderManagementTruckListProps) => {
  const filteredColumns = filterTableColumns(TRUCKLIST_TABLE_COLUMNS);

  const tableTruckTypeOptions = formatTruckNumTypeOption(data.trucks);

  return (
    <Table
      css={S.table(tableTruckTypeOptions.length)}
      title="Truck list"
      rowCount={tableTruckTypeOptions.length}
    >
      <Table.Head columnList={TRUCKLIST_TABLE_COLUMNS} />
      <Table.Body css={S.tbody(tableTruckTypeOptions.length > 0)}>
        {tableTruckTypeOptions.length ? (
          tableTruckTypeOptions.map((data, i) => (
            <Table.Row key={i}>
              {filteredColumns.map(({ key }) => (
                <Table.Cell key={key}>
                  {formatTrucksTableData(key, data)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))
        ) : (
          <Table.NoData />
        )}
      </Table.Body>
    </Table>
  );
};

export default OrderManagementTruckList;
