import React from "react";

import { ConfirmModal } from "@/components";

const DeleteAccountModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    return (
      <ConfirmModal
        ref={ref}
        title="Delete account"
        desc="Deleting your account will remove all of your information from our database. This cannot be undone."
        closeBtnLabel="Cancel"
        negBtnLabel="Delete"
        callbackFn={() => console.log("Click Delete Button")} //TODO: 계정 삭제 API 연동하기
      />
    );
  },
);

DeleteAccountModal.displayName = "DeleteAccountModal";

export default DeleteAccountModal;
