import React, { useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { NoResult, QueryPagination } from "@/components";
import { useAlignCenter } from "@/hooks";

import { useMonitoringTruckMarker } from "hooks";
import { foldCardAnimate } from "utils";
import type { GetMonitoringTrucksClientModel } from "types";
import { BtnFoldInfo, TruckStatusInfo } from "../../card";
import { useTruckMarkerEventListener, useTruckSelect } from "../../hooks";
import * as S from "./MonitoringWaitingList.styled";

interface MonitoringWaitingListProps {
  trucks: GetMonitoringTrucksClientModel;
}

const MonitoringWaitingList = ({ trucks }: MonitoringWaitingListProps) => {
  const [searchParams] = useSearchParams();

  const truckRef = useRef<{ [key: string]: HTMLLIElement | null }>({});

  const { truckMarkers } = useMonitoringTruckMarker(trucks.trucks);

  const monitoringTrucks = trucks.trucks.map((truck, i) => ({
    ...truck,
    ...truckMarkers[i],
  }));

  const { handleTruckCardClick, handleTruckMarkerClick } = useTruckSelect(
    monitoringTrucks,
    truckRef,
  );
  useTruckMarkerEventListener(handleTruckMarkerClick, monitoringTrucks);
  useAlignCenter(trucks.coords);

  return (
    <>
      {trucks.trucks.length ? (
        <S.MonitoringWaitingListWrapper>
          {trucks.trucks.map((truck) => {
            const isFold = truck.truckId !== searchParams.get("truckId");

            return (
              <li
                key={truck.truckId}
                ref={(el) => (truckRef.current[truck.truckId] = el)}
              >
                <BtnFoldInfo
                  isFold={isFold}
                  truck={truck}
                  status="WAITING"
                  handleFoldBtnClick={handleTruckCardClick(truck.truckId)}
                />
                {!isFold && (
                  <S.TruckDetailInfoWrapper
                    layout
                    initial="closed"
                    animate={isFold ? "closed" : "open"}
                    exit="closed"
                    variants={foldCardAnimate(
                      searchParams.get("type") === "card",
                    )}
                    transition={{ duration: 0.3 }}
                  >
                    <TruckStatusInfo
                      truckStatus="WAITING"
                      truckInfo={{
                        lat: truck.lat,
                        lng: truck.lng,
                        addr: truck.addr,
                        speed: truck.speed,
                        temperature: truck.temperature,
                      }}
                    />
                  </S.TruckDetailInfoWrapper>
                )}
              </li>
            );
          })}
        </S.MonitoringWaitingListWrapper>
      ) : (
        <NoResult />
      )}
      <QueryPagination css={S.pagination} pageInfo={trucks.pageInfo} />
    </>
  );
};

export default MonitoringWaitingList;
