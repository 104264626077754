import React from "react";

import { QueryPagination, SummaryStatistics, TableLayout } from "@/components";
import { useFilterParams } from "@/hooks";
import { calculateDropDecimal, filterOrderNum, formatPrice } from "@/utils";

import { SettlementFilter, SettlementSection } from "components";
import { useGetLinkPlayer, useGetSettlements } from "services";
import type { GetSettlementQueryModel } from "types";
import * as S from "./Settlement.styled";

interface Filter
  extends Omit<
    GetSettlementQueryModel<"shipper">["query"],
    "startDate" | "endDate"
  > {
  date?: string;
}

const requiredFilterKeys = ["startDate", "endDate"] as const;

const ShipperSettlement = () => {
  const { filters } = useFilterParams<Filter>([
    "page",
    "status",
    "orderNumber",
    "date",
    "companyId",
  ]);

  const query = {
    ...filters,
    ...(filters.orderNumber && {
      orderNumber: filterOrderNum(filters.orderNumber),
    }),
  } as any; // TODO: 테스트 후 수정 필요

  const {
    data: settlements,
    dataUpdatedAt,
    refetch,
  } = useGetSettlements<"shipper">(
    { query },
    { enabled: requiredFilterKeys.every((key) => key in query) },
  );
  const { data: carrierCollection } = useGetLinkPlayer({
    query: { type: "carrier" },
  });
  const { data: forwarderCollection } = useGetLinkPlayer({
    query: { type: "forwarder" },
  });

  if (!carrierCollection || !forwarderCollection) return null;

  return (
    <TableLayout h2="Settlement">
      <SettlementFilter
        companyCollection={[...carrierCollection, ...forwarderCollection]}
      />
      <SummaryStatistics
        css={S.simpleStatistics}
        totalOrderCount={settlements ? settlements.pageInfo.totalData : null}
        totalPrice={
          settlements
            ? formatPrice(calculateDropDecimal(settlements.totalPrice))
            : null
        }
      />
      <SettlementSection
        hasRequiredFilter={requiredFilterKeys.every((key) => key in query)}
        data={settlements}
        dataUpdatedAt={dataUpdatedAt}
        refetch={refetch}
      />
      <QueryPagination css={S.pagination} pageInfo={settlements?.pageInfo} />
    </TableLayout>
  );
};

export default ShipperSettlement;
