import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  getCompanyInfoAPI,
  getCompanyPlanAPI,
  putCompanyInfoAPI,
  putCompanyLicenseAPI,
} from "apis";
import type {
  CompanyType,
  PutLicenseQueryModel,
  UpdateCompanyInfoQueryModel,
} from "types";

const companyInfoKeys = ["companyInfo"];

export const useGetCompanyInfo = (suspense: boolean) => {
  return useQuery({
    queryKey: companyInfoKeys,
    queryFn: () => getCompanyInfoAPI(),
    suspense,
  });
};

export const useUpdateCompanyInfo = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateCompanyInfoQueryModel
  >({
    mutationFn: (req) => putCompanyInfoAPI(req),
  });
};

export const useGetCompanyPlanInfo = () => {
  return useQuery({
    queryKey: ["companyPlan"],
    queryFn: () => getCompanyPlanAPI(),
  });
};

export function useRegisterCompanyLicense(
  clientType: "forwarder" | "shipper",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PutLicenseQueryModel<"forwarder" | "shipper">
>;
export function useRegisterCompanyLicense(
  clientType: "carrier" | "forwardercarrier",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PutLicenseQueryModel<"carrier" | "forwardercarrier">
>;
export function useRegisterCompanyLicense(clientType: CompanyType) {
  const queryClient = useQueryClient();

  switch (clientType) {
    case "carrier":
    case "forwardercarrier":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PutLicenseQueryModel<typeof clientType>
      >({
        mutationFn: (req: PutLicenseQueryModel<typeof clientType>) =>
          putCompanyLicenseAPI(req),
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: companyInfoKeys,
          });
        },
      });
    case "forwarder":
    case "shipper":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PutLicenseQueryModel<typeof clientType>
      >({
        mutationFn: (req: PutLicenseQueryModel<typeof clientType>) =>
          putCompanyLicenseAPI(req),
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: companyInfoKeys,
          });
        },
      });
  }
}
