import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  cancelQuotationAPI,
  getQuotationsOngoingAPI,
  getQuotationsCanceledAPI,
  getQuotationsCompletedAPI,
  getQuotationsPendingAPI,
  patchSendQuotationRejectAPI,
  patchRequestQuotationRejectAPI,
  patchQuotationRequoteAPI,
  getQuotationDetailAPI,
  postSelectQuotationAPI,
  postQuotationRequestAPI,
  postQuotationSendAPI,
} from "apis";
import type {
  CompanyType,
  GetQuotationCanceledQueryModel,
  GetQuotationCompletedQueryModel,
  GetQuotationOngoingQueryModel,
  GetQuotationPendingQueryModel,
  PostQuotationRequestQueryModel,
  PostSelectQuotationQueryModel,
  PostSendQuotationQueryModel,
} from "types";

export const quotationKeys = {
  all: ["quotation"] as const,
  lists: () => [...quotationKeys.all, "list"] as const,
  list: <T>(type: string, filters: T) =>
    [...quotationKeys.lists(), { type, filters }] as const,
  details: () => [...quotationKeys.all, "detail"] as const,
  detail: (id: string) => [...quotationKeys.details(), id] as const,
};

export const useGetQuotationPending = (req: GetQuotationPendingQueryModel) => {
  return useQuery({
    queryKey: quotationKeys.list("pending", req),
    queryFn: () => getQuotationsPendingAPI(req),
  });
};

export const useGetQuotationOngoing = <T extends CompanyType>(
  req: GetQuotationOngoingQueryModel<T>,
) => {
  return useQuery({
    queryKey: quotationKeys.list("ongoing", req),
    queryFn: () => getQuotationsOngoingAPI<T>(req),
  });
};

export const useGetQuotationCompleted = <T extends CompanyType>(
  req: GetQuotationCompletedQueryModel,
) => {
  return useQuery({
    queryKey: quotationKeys.list("completed", req),
    queryFn: () => getQuotationsCompletedAPI<T>(req),
  });
};

export const useGetQuotationCanceled = <T extends CompanyType>(
  req: GetQuotationCanceledQueryModel,
) => {
  return useQuery({
    queryKey: quotationKeys.list("canceled", req),
    queryFn: () => getQuotationsCanceledAPI<T>(req),
  });
};

interface UseGetQuotationDetail {
  suspense: boolean;
  quotationId: string;
  enabled?: boolean;
  offerId?: string;
}

export const useGetQuotationDetail = <T extends CompanyType>({
  suspense,
  quotationId,
  enabled,
  offerId,
}: UseGetQuotationDetail) => {
  return useQuery({
    queryKey: quotationKeys.detail(offerId || quotationId),
    queryFn: () => getQuotationDetailAPI<T>(offerId || quotationId),
    suspense,
    enabled,
  });
};

export const usePatchSendQuotationReject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => patchSendQuotationRejectAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};

export const usePatchRequestQuotationReject = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => patchRequestQuotationRejectAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};

export const useCancelQuotation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (offerId: string) => cancelQuotationAPI(offerId),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
      queryClient.invalidateQueries(quotationKeys.lists());
    },
  });
};

export const usePatchQuotationRequote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => patchQuotationRequoteAPI(id),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};

export function usePostSelectQuotation(
  clientType: "forwarder",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostSelectQuotationQueryModel<"forwarder">
>;
export function usePostSelectQuotation(
  clientType: "forwardercarrier",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostSelectQuotationQueryModel<"forwardercarrier">
>;
export function usePostSelectQuotation(
  clientType: "shipper",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostSelectQuotationQueryModel<"shipper">
>;
export function usePostSelectQuotation(
  clientType: "shipper" | "forwardercarrier" | "forwarder",
) {
  const queryClient = useQueryClient();

  switch (clientType) {
    case "shipper":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PostSelectQuotationQueryModel<typeof clientType>
      >({
        mutationFn: (req) => postSelectQuotationAPI(req, clientType),
        onSuccess: () => {
          queryClient.invalidateQueries(quotationKeys.details());
        },
      });

    case "forwarder":
    case "forwardercarrier":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PostSelectQuotationQueryModel<typeof clientType>
      >({
        mutationFn: (req) => postSelectQuotationAPI(req, clientType),
        onSuccess: () => {
          queryClient.invalidateQueries(quotationKeys.details());
        },
      });
  }
}

export function usePostQuotationRequest(
  clientType: "shipper",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostQuotationRequestQueryModel<"shipper">
>;
export function usePostQuotationRequest(
  clientType: "forwarder",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostQuotationRequestQueryModel<"forwarder">
>;
export function usePostQuotationRequest(
  clientType: "forwardercarrier",
): UseMutationResult<
  unknown,
  APIError<typeof COMMON_ERROR_CODE>,
  PostQuotationRequestQueryModel<"forwardercarrier">
>;
export function usePostQuotationRequest(
  clientType: "shipper" | "forwarder" | "forwardercarrier",
) {
  const queryClient = useQueryClient();

  switch (clientType) {
    case "shipper":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PostQuotationRequestQueryModel<typeof clientType>
      >({
        mutationFn: (req) => postQuotationRequestAPI(req, clientType),
        onSuccess: () => queryClient.invalidateQueries(quotationKeys.details()),
      });
    case "forwarder":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PostQuotationRequestQueryModel<typeof clientType>
      >({
        mutationFn: (req) => postQuotationRequestAPI(req, clientType),
        onSuccess: () => queryClient.invalidateQueries(quotationKeys.details()),
      });
    case "forwardercarrier":
      return useMutation<
        unknown,
        APIError<typeof COMMON_ERROR_CODE>,
        PostQuotationRequestQueryModel<typeof clientType>
      >({
        mutationFn: (req) => postQuotationRequestAPI(req, clientType),
        onSuccess: () => queryClient.invalidateQueries(quotationKeys.details()),
      });
  }
}

export const usePostQuotationSend = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: PostSendQuotationQueryModel) => postQuotationSendAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries(quotationKeys.details());
    },
  });
};
