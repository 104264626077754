import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadBtn, LabelContentTable } from "@/components";
import {
  createAttachFiles,
  findLookupTableLabel,
  makeOrderFileName,
} from "@/utils";
import { LOADING_METHOD_TYPE, LOADING_STATUS_RADIOS } from "@/assets";

import { useDownloadFile } from "services";
import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationTruckingMethods.styled";

interface QuotationTruckingMethodsProps {
  data: GetQuotationDetailServerModel<CompanyType>;
}

const QuotationTruckingMethods = ({ data }: QuotationTruckingMethodsProps) => {
  const { t } = useTranslation();

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  const loadingStatusLabel =
    findLookupTableLabel(
      LOADING_STATUS_RADIOS,
      data.truckingMethods.loadingStatus,
    ) ?? "";

  const loadingMethodLabel =
    findLookupTableLabel(
      LOADING_METHOD_TYPE,
      data.truckingMethods.loadingMethod,
    ) ?? "";

  const { file1, file2 } = data.truckingMethods;
  const files = createAttachFiles([file1, file2]);

  return (
    <LabelContentTable variant="underline">
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading status">
          {loadingStatusLabel ? t(loadingStatusLabel) : "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Loading/unloading method">
          {loadingMethodLabel
            ? t(loadingMethodLabel)
            : data.truckingMethods.loadingMethod}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Attached file">
          <S.FileWrapper>
            {files.length ? (
              <>
                {files.map((fileKey, idx) => {
                  if (!fileKey) return null;

                  return (
                    <DownloadBtn
                      key={fileKey}
                      downloadFileNamePrefix="OrderAttachedFile"
                      fileName={makeOrderFileName(fileKey, idx)}
                      fileKey={fileKey}
                      isLoading={isLoading}
                      downloadFileMutate={downloadFileMutate}
                    />
                  );
                })}
              </>
            ) : (
              "-"
            )}
          </S.FileWrapper>
        </LabelContentTable.Content>
      </LabelContentTable.Row>
      <LabelContentTable.Row>
        <LabelContentTable.Content label="Memo">
          {data.truckingMethods.orderMemo ?? "-"}
        </LabelContentTable.Content>
      </LabelContentTable.Row>
    </LabelContentTable>
  );
};

export default QuotationTruckingMethods;
