import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  Dropdown,
  ErrMsg,
  FormRadioBtn,
  Input,
  LabelContent,
  UnitSuffixInput,
} from "@/components";
import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";
import {
  comma,
  deleteComma,
  numericOnly,
  formatTwoDecimalDigit,
  formatterDecimal,
} from "@/utils";
import {
  HAZARD_GOODS_RADIOS,
  STACKABLE_GOODS_RADIOS,
  CARGO_TYPE,
  PACKAGING_TYPE,
  CARGO_TOOLTIP,
  PlusCircleIcon16,
  DeleteIcon20,
} from "@/assets";
import type { Languages } from "@/types";

import type { OrderForm } from "types";
import * as S from "./OrderCreationCargo.styled";

const OrderCreationCargo = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();

  const {
    control,
    setValue,
    formState: { errors },
    resetField,
    watch,
    register,
  } = useFormContext<OrderForm>();
  const { fields, append, remove } = useFieldArray({ control, name: "cargo" });
  const controlledFields = fields.map((field, i) => ({
    ...field,
    ...watch("cargo")[i],
  }));

  const handleCargoAdd = (): void => {
    append(
      {
        cargoType: { key: "", label: "" },
        cargoOtherType: "",
        packagingType: { key: "", label: "" },
        size: { length: "", depth: "", height: "" },
        weight: "",
        quantity: "",
        hazardGoods: "no" as const,
        stackable: "no" as const,
      },
      { shouldFocus: false },
    );
  };

  const handleCargoDelete = (index: number) => (): void => {
    fields.length === 1
      ? addToast(COMMON_TOAST_MSG.WARNING.CARGO_TYPE_DELETE_FAIL)
      : remove(index);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValue(name as any, value ? `${+formatterDecimal(value)}` : value);
  };

  return (
    <Card>
      <Card.Content css={S.cardContent} heading="Cargo" headingLevel="h3">
        {controlledFields.map((cargo, i) => {
          const cargoTypeErrorMsg = errors?.cargo?.[i]?.cargoType?.message;
          const cargoOtherTypeErrorMsg =
            errors?.cargo?.[i]?.cargoOtherType?.message;
          const cargoSizeErrorMsg = errors.cargo?.[i]?.size?.length?.message;
          const cargoDepthErrorMsg = errors.cargo?.[i]?.size?.depth?.message;
          const cargoHeightErrorMsg = errors.cargo?.[i]?.size?.height?.message;

          return (
            <S.CargoWrapper key={cargo.id}>
              <Button
                css={S.deleteBtn}
                variant="ghostRed"
                icon={<DeleteIcon20 />}
                handleClickBtn={handleCargoDelete(i)}
              />
              <S.DropdownWrapper>
                <LabelContent
                  css={S.content}
                  label="Type"
                  direction="vertical"
                  isRequired
                >
                  <Controller
                    control={control}
                    name={`cargo.${i}.cargoType`}
                    render={({ field: { value, onChange } }) => {
                      const handleSelect = (cargoTypeKey: string) => {
                        const selectedCargoType = CARGO_TYPE.findIndex(
                          ({ key }) => key === cargoTypeKey,
                        );

                        onChange(CARGO_TYPE[selectedCargoType]);
                        resetField(`cargo.${i}.cargoOtherType`);
                      };

                      return (
                        <Dropdown
                          css={S.dropdown}
                          hasError={!!cargoTypeErrorMsg}
                          options={CARGO_TYPE}
                          placeholder="Select the cargo type"
                          selectedOption={value}
                          handleSelect={handleSelect}
                        />
                      );
                    }}
                  />
                  {cargo.cargoType.key === "others" && (
                    <Input
                      id="cargoOtherType"
                      css={S.cargoTypeInput}
                      maxLength={100}
                      placeholder={"Please enter the cargo type" as Languages}
                      hasError={!!errors.cargo?.[i]?.cargoOtherType?.message}
                      register={register(`cargo.${i}.cargoOtherType`)}
                    />
                  )}
                  {(!!cargoTypeErrorMsg || !!cargoOtherTypeErrorMsg) && (
                    <ErrMsg>
                      {(cargoTypeErrorMsg as Languages) ||
                        (cargoOtherTypeErrorMsg as Languages)}
                    </ErrMsg>
                  )}
                </LabelContent>
                <LabelContent
                  css={S.packagingTypeContent}
                  label="Packaging type"
                  direction="vertical"
                  isRequired
                >
                  <Controller
                    control={control}
                    name={`cargo.${i}.packagingType`}
                    render={({ field: { value, onChange } }) => {
                      const handleSelect = (cargoTypeKey: string) => {
                        const selectedPackagingType = PACKAGING_TYPE.findIndex(
                          ({ key }) => key === cargoTypeKey,
                        );

                        onChange(PACKAGING_TYPE[selectedPackagingType]);
                      };

                      return (
                        <Dropdown
                          css={S.dropdown}
                          hasError={!!errors.cargo?.[i]?.packagingType?.message}
                          options={PACKAGING_TYPE}
                          placeholder="Select the packaging type"
                          selectedOption={value}
                          handleSelect={handleSelect}
                        />
                      );
                    }}
                  />
                  {!!errors.cargo?.[i]?.packagingType?.message && (
                    <ErrMsg>
                      {errors.cargo?.[i]?.packagingType?.message as Languages}
                    </ErrMsg>
                  )}
                </LabelContent>
              </S.DropdownWrapper>
              <LabelContent
                css={S.content}
                isRequired
                direction="vertical"
                label="Size"
                tooltip={CARGO_TOOLTIP}
              >
                <S.SizeInputWrapper>
                  <UnitSuffixInput
                    id="length"
                    value={cargo.size.length}
                    err={errors.cargo?.[i]?.size?.length?.message}
                    maxLength={5}
                    placeholder="Length"
                    unit="m"
                    register={register(`cargo.${i}.size.length`, {
                      setValueAs: (currentValue: string) =>
                        formatTwoDecimalDigit(cargo.size.length, currentValue),
                      onBlur: handleInputBlur,
                    })}
                  />
                  <UnitSuffixInput
                    id="depth"
                    value={cargo.size.depth}
                    err={errors.cargo?.[i]?.size?.depth?.message}
                    maxLength={5}
                    placeholder="Depth"
                    unit="m"
                    register={register(`cargo.${i}.size.depth`, {
                      setValueAs: (currentValue: string) =>
                        formatTwoDecimalDigit(cargo.size.depth, currentValue),
                      onBlur: handleInputBlur,
                    })}
                  />
                  <UnitSuffixInput
                    id="height"
                    value={cargo.size.height}
                    err={errors.cargo?.[i]?.size?.height?.message}
                    maxLength={5}
                    placeholder="Height"
                    unit="m"
                    register={register(`cargo.${i}.size.height`, {
                      setValueAs: (currentValue: string) =>
                        formatTwoDecimalDigit(cargo.size.height, currentValue),
                      onBlur: handleInputBlur,
                    })}
                  />
                  <S.SizeInfo>
                    {`- ${t(
                      "Maximum: Length 13.56 m, Depth 2.35 m, Height 2.7 m",
                    )}`}
                  </S.SizeInfo>
                  {errors?.cargo?.[i]?.size &&
                    !!Object.keys(errors?.cargo?.[i]?.size ?? {}).length && (
                      <ErrMsg css={S.sizeErrorMsg}>
                        {(cargoSizeErrorMsg as Languages) ||
                          (cargoDepthErrorMsg as Languages) ||
                          (cargoHeightErrorMsg as Languages)}
                      </ErrMsg>
                    )}
                </S.SizeInputWrapper>
              </LabelContent>
              <S.CargoWeightQuantityWrapper>
                <LabelContent label="Weight" direction="vertical" isRequired>
                  <div>
                    <UnitSuffixInput
                      id="weight"
                      maxLength={7}
                      value={cargo.weight}
                      err={errors.cargo?.[i]?.weight?.message}
                      placeholder="Only numbers"
                      unit="kg"
                      register={register(`cargo.${i}.weight`, {
                        setValueAs: (value: string) =>
                          comma(deleteComma(numericOnly(value))),
                      })}
                    />
                    <S.WeightInfo>{`- ${t(
                      "Maximum: 100,000 kg",
                    )}`}</S.WeightInfo>
                    {!!errors.cargo?.[i]?.weight?.message && (
                      <ErrMsg css={S.errorMsg}>
                        {errors.cargo?.[i]?.weight?.message as Languages}
                      </ErrMsg>
                    )}
                  </div>
                </LabelContent>
                <LabelContent label="Quantity" direction="vertical" isRequired>
                  <div>
                    <Input
                      id="quantity"
                      maxLength={13}
                      hasError={!!errors.cargo?.[i]?.quantity?.message}
                      value={cargo.quantity}
                      placeholder="Only numbers"
                      register={register(`cargo.${i}.quantity`, {
                        setValueAs: (value: string) =>
                          comma(deleteComma(numericOnly(value))),
                      })}
                    />
                    {!!errors.cargo?.[i]?.quantity?.message && (
                      <ErrMsg css={S.errorMsg}>
                        {errors.cargo?.[i]?.quantity?.message as Languages}
                      </ErrMsg>
                    )}
                  </div>
                </LabelContent>
              </S.CargoWeightQuantityWrapper>
              <S.CargoStatusWrapper>
                <LabelContent label="Stackable" isRequired direction="vertical">
                  <FormRadioBtn
                    radioList={STACKABLE_GOODS_RADIOS}
                    register={register(`cargo.${i}.stackable`)}
                  />
                </LabelContent>
                <LabelContent
                  label="Hazard goods"
                  isRequired
                  direction="vertical"
                >
                  <FormRadioBtn
                    radioList={HAZARD_GOODS_RADIOS}
                    register={register(`cargo.${i}.hazardGoods`)}
                  />
                </LabelContent>
              </S.CargoStatusWrapper>
            </S.CargoWrapper>
          );
        })}
        <Button
          css={S.addCargoBtn}
          variant="tertiaryIconLarge"
          label="Add the cargo"
          icon={<PlusCircleIcon16 />}
          handleClickBtn={handleCargoAdd}
        />
      </Card.Content>
    </Card>
  );
};

export default OrderCreationCargo;
