import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    width: 564px;
    min-height: calc(100vh - ${theme.size.HEADER_HEIGHT});
    margin: 0 auto;
    padding-top: 33px;
    padding-bottom: 40px;
  `}
`;

export const CardContentWrapper = styled.div`
  & > div {
    display: flex;
    column-gap: 10px;
    margin-bottom: 4px;
  }
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const PasswordShowBtn = styled.button<{ isShowPassword: boolean }>`
  ${({ theme, isShowPassword }) => css`
    position: absolute;
    top: 32px;
    right: 12px;
    height: 20px;
    padding: 0;

    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        fill: ${isShowPassword ? theme.color.primary_40 : theme.color.gray_50};
      }
    }

    &:not(:disabled):hover {
      background-color: inherit;
    }
  `}
`;

export const labelContent = css`
  position: relative;
`;

export const AgreementProperty = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 16px 20px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const checkbtn = (theme: Theme) => css`
  ${theme.font.regular_14};
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  color: ${theme.color.gray_70};

  & > label:first-of-type {
    width: 16px;
  }

  & > label:last-of-type {
    &::after {
      content: "*";
      margin-left: 2px;
      color: ${theme.color.red_20};
    }
  }
`;

export const errMsg = (theme: Theme) => css`
  display: flex;
  ${theme.font.regular_13};
  color: ${theme.color.red_20};
`;

export const pageBackHeader = (theme: Theme) => css`
  border: 0;
  padding: 0;
  padding-bottom: 12px;
  background-color: inherit;

  span {
    ${theme.font.bold_18};
  }
`;

export const card = css`
  width: 564px;
  row-gap: 32px;

  & > div:not(:last-of-type) > div:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;
