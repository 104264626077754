import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  width: 100%;
`;

export const LabelContentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    width: 100%;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;

export const labelContent = (theme: Theme) => css`
  color: ${theme.color.gray_70};
`;
