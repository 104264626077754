import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const cardContent = css`
  & > div:first-of-type {
    margin-bottom: 16px;
  }
`;

export const TruckWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: 16px;
    border: 1px solid ${theme.color.gray_20};
    border-radius: 8px;
    padding: 24px 16px;
    background-color: ${theme.color.gray_00};
  `}
`;

export const deleteBtn = (theme: Theme) => css`
  position: absolute;
  top: 12px;
  right: 12px;

  svg path {
    margin: 0;
    fill: ${theme.color.gray_60};
  }
`;

export const DropdownWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 16px;
`;

export const TruckOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  align-items: flex-start;
`;

export const dropdown = (theme: Theme) => css`
  background-color: ${theme.color.white};
`;

export const addTruckBtn = css`
  width: 100%;
`;

export const TruckNumInputWrapper = styled.div<{ isDisabled: boolean }>`
  ${({ theme, isDisabled }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 1fr 38px 38px;
    align-items: center;
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.color.gray_35};
    padding-left: 12px;
    color: ${isDisabled && theme.color.gray_50};
    background-color: ${isDisabled ? theme.color.gray_10 : theme.color.white};

    & > button {
      width: 100%;
      height: 100%;
      border-left: 1px solid ${theme.color.gray_35};
      background-color: ${theme.color.gray_10};

      & > svg {
        width: 20px;
        height: 20px;
        margin: 0 auto;

        path {
          fill: ${isDisabled ? theme.color.gray_40 : theme.color.gray_80};
        }
      }
    }
  `}
`;

export const button = (theme: Theme) => css`
  &:disabled {
    opacity: 1;

    & > svg > path {
      fill: ${theme.color.gray_40};
    }
  }
`;
