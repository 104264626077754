import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const AllocationContent = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const link = (theme: Theme) => css`
  ${theme.font.semibold_12};
  height: 20px;
  border: 1px solid ${theme.color.gray_35};
  padding: 2px 4px;
  color: ${theme.color.gray_60};
`;
