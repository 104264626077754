import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentTable = css`
  margin-bottom: 12px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
`;
