import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import { Button } from "@/components";

import type { TruckForm } from "types";
import * as S from "./TruckCreateBtns.styled";

interface TruckCreateBtnsProps {
  isCreateTruckLoading: boolean;
  createTruck: (data: TruckForm) => void;
}

const TruckCreateBtns = ({
  isCreateTruckLoading,
  createTruck,
}: TruckCreateBtnsProps) => {
  const navigate = useNavigate();

  const {
    formState: { errors },
    handleSubmit,
  } = useFormContext<TruckForm>();

  const handleClickCancel = (): void => {
    navigate(-1);
  };

  return (
    <S.BtnWrapper>
      <Button
        variant="outlineMedium"
        label="Cancel"
        handleClickBtn={handleClickCancel}
      />
      <Button
        isLoading={isCreateTruckLoading}
        isDisabled={Object.keys(errors).length > 0}
        variant="primaryMedium"
        label="Add"
        handleClickBtn={handleSubmit(createTruck)}
      />
    </S.BtnWrapper>
  );
};

export default TruckCreateBtns;
