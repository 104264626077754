import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError, LatLng, OrsRes } from "@/types";

import {
  getDriversForAllocationEditAPI,
  getAllocationDriverDetailAPI,
  getAllocationDriversAPI,
  getAllocationTruckAllAPI,
  getAllocationDetailAPI,
  postAllocationAPI,
  putAllocationAPI,
  getAllocationDeliveriesAPI,
  postAllocationOrsAPI,
  postAllocationOptimizingAPI,
} from "apis";
import type {
  CreateAllocationQueryModel,
  GetAllocationDriversQueryModel,
  UpdateAllocationQueryModel,
  GetDriversForAllocationEditQueryModel,
  GetAllocationTruckAllQueryModel,
  GetAllocationDriverDetailServerModel,
  GetAllocationDetailServerModel,
  GetDeliverysQueryModel,
  PostAllocationOptimizingQueryModel,
  PostAllocationOptimizingServerModel,
  GetAllocationDeliveryServerModel,
  AllocationRoute,
} from "types";

const allocationDriverKeys = {
  all: ["allocationDriver"] as const,
  lists: () => [...allocationDriverKeys.all, "list"] as const,
  list: (filters: GetAllocationDriversQueryModel) =>
    [...allocationDriverKeys.lists(), { filters }] as const,
  drivers: () => [...allocationDriverKeys.all, "driver"] as const,
  driver: (query: GetDriversForAllocationEditQueryModel) =>
    [...allocationDriverKeys.drivers(), { query }] as const,
  trucks: () => [...allocationDriverKeys.all, "truck"] as const,
  truck: (query: GetAllocationTruckAllQueryModel) =>
    [...allocationDriverKeys.trucks(), { query }] as const,
  details: () => [...allocationDriverKeys.all, "detail"] as const,
  detail: (id: string) => [...allocationDriverKeys.details(), id] as const,
  deliveries: () => [...allocationDriverKeys.all, "delivery"] as const,
  delivery: (req: GetDeliverysQueryModel) =>
    [...allocationDriverKeys.deliveries(), req] as const,
};

export const useGetAllocationDrivers = (req: GetAllocationDriversQueryModel) =>
  useQuery({
    queryKey: allocationDriverKeys.list(req),
    queryFn: () => getAllocationDriversAPI(req),
  });

export const useGetDriversForAllocationEdit = (
  query: GetDriversForAllocationEditQueryModel,
) =>
  useQuery({
    queryKey: allocationDriverKeys.driver(query),
    queryFn: () => getDriversForAllocationEditAPI(query),
  });

export const useGetAllocationTruckAll = (
  query: GetAllocationTruckAllQueryModel,
) => {
  return useQuery({
    queryKey: allocationDriverKeys.truck(query),
    queryFn: () => getAllocationTruckAllAPI(query),
  });
};

export const useGetAllocationDriverDetail = (
  id: string,
  options?: UseQueryOptions<
    GetAllocationDriverDetailServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    GetAllocationDriverDetailServerModel,
    ReturnType<typeof allocationDriverKeys.detail>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: allocationDriverKeys.detail(id),
    queryFn: () => getAllocationDriverDetailAPI(id),
  });
};

export const useGetAllocationDetail = (
  driverId: string,
  options?: UseQueryOptions<
    GetAllocationDetailServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    GetAllocationDetailServerModel,
    ReturnType<typeof allocationDriverKeys.detail>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: allocationDriverKeys.detail(driverId),
    queryFn: () => getAllocationDetailAPI(driverId),
    select: (res) => ({
      ...res,
      allocationInfo: res.allocationInfo.map((allocation) => ({
        ...allocation,
        id: allocation.deliveryId + allocation.type,
      })),
      enabled: false,
    }),
  });
};

export const useCreateAllocation = () => {
  return useMutation<
    string,
    APIError<typeof COMMON_ERROR_CODE>,
    CreateAllocationQueryModel
  >({
    mutationFn: (req) => postAllocationAPI(req),
  });
};

export const useEditAllocation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateAllocationQueryModel
  >({
    mutationFn: (req) => putAllocationAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries({
        queryKey: allocationDriverKeys.detail(req.allocationId),
      });
    },
  });
};

export const useGetDeliveriesAllocation = (
  req: GetDeliverysQueryModel,
  options?: UseQueryOptions<
    GetAllocationDeliveryServerModel,
    APIError<typeof COMMON_ERROR_CODE>,
    GetAllocationDeliveryServerModel,
    ReturnType<typeof allocationDriverKeys.delivery>
  >,
) => {
  return useQuery({
    ...options,
    queryKey: allocationDriverKeys.delivery(req),
    queryFn: () => getAllocationDeliveriesAPI(req),
  });
};

export const useGetAllocationDriverDetailAllocation = (driverId: string) => {
  return useQuery({
    queryKey: allocationDriverKeys.detail(driverId),
    queryFn: () => getAllocationDriverDetailAPI(driverId),
  });
};

export const usePostAllocationOrs = () =>
  useMutation<
    OrsRes,
    { response: { data: { error: { code: number } } } },
    AllocationRoute[]
  >({
    mutationFn: (latLngs: LatLng[]) => postAllocationOrsAPI(latLngs),
  });

export const usePostAllocationOptimizing = (
  req: PostAllocationOptimizingQueryModel,
  onSuccess: (res: PostAllocationOptimizingServerModel) => void,
) =>
  useQuery({
    queryKey: [
      "allocations",
      req.body.list.map((delivery) => delivery.orderId),
    ],
    queryFn: () => postAllocationOptimizingAPI(req),
    onSuccess,
    enabled: false,
  });
