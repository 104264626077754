import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, LabelContentTable } from "@/components";
import { getMeterSize } from "@/utils";
import type { Languages } from "@/types";

import type { TruckSpecificationInfoForm } from "types";
import * as S from "./SpecificationField.styled";

interface SpecificationFieldProps {
  handleClickEdit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SpecificationField = ({ handleClickEdit }: SpecificationFieldProps) => {
  const { t } = useTranslation();

  const { watch } = useFormContext<TruckSpecificationInfoForm>();

  return (
    <>
      <LabelContentTable variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Truck type">
            {/* TODO: DropdownOptionType의 label type을 Languages로 변경 후 수정 필요 */}
            {t(watch("truckType")?.label as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Truck option">
            {/* TODO: DropdownOptionType의 label type을 Languages로 변경 후 수정 필요 */}
            {t(watch("truckOption")?.label as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Container size">
            {watch("size.depth") && watch("size.length") && watch("size.height")
              ? getMeterSize(
                  watch("size.length") ?? 0,
                  watch("size.depth") ?? 0,
                  watch("size.height") ?? 0,
                )
              : "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Brand">
            {watch("brand") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Model">
            {watch("model") || "-"}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="Year">
            {watch("year")?.key}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content label="VIN">
            {watch("vin")}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      <S.BtnWrapper>
        <Button
          variant="outlineMedium"
          label="Edit"
          handleClickBtn={handleClickEdit}
        />
      </S.BtnWrapper>
    </>
  );
};

export default SpecificationField;
