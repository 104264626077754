import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ConfirmModal } from "@/components";
import { useModal, useToast } from "@/hooks";
import { COMMON_ERROR_CODE, COMMON_TOAST_MSG } from "@/constants";
import { PATH } from "@/assets";

import { useDeletePropertyTruck } from "services";
import { CARRIER_TOAST_MSG } from "constants/index";

const DelTruckModal = React.forwardRef(
  (_, ref: React.ForwardedRef<HTMLDialogElement>) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { addToast } = useToast();
    const { handleModalClose } = useModal();

    const { mutate: deletePropertyTruck } = useDeletePropertyTruck();

    const handleDeletePropertyTruck = () => {
      deletePropertyTruck(id!, {
        onSuccess: () => {
          addToast(CARRIER_TOAST_MSG.SUCCESS.TRUCK_DELETE_DONE);
          handleModalClose();
          navigate(`${PATH.PROPERTY}?tab=truck`);
        },
        onError: (err) => {
          if (err.response?.data.response === COMMON_ERROR_CODE.DRIVING_TRUCK) {
            addToast(CARRIER_TOAST_MSG.WARNING.PROCESS_FAIL);
          } else {
            addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
          }
          handleModalClose();
        },
      });
    };

    return (
      <ConfirmModal
        ref={ref}
        title="Delete the truck"
        desc="Are you sure you want to delete the truck?"
        closeBtnLabel="Cancel"
        negBtnLabel="Confirm"
        callbackFn={handleDeletePropertyTruck}
      />
    );
  },
);

DelTruckModal.displayName = "DelTruckModal";

export default DelTruckModal;
