import React from "react";

import useDialog from "./hooks/useDialog";
import * as S from "./DialogBtn.styled";

interface DialogBtnProps {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  popup: ({
    dialogRef,
    isDialogOpen,
    handleDialogClose,
  }: {
    dialogRef?: React.RefObject<HTMLDialogElement>;
    isDialogOpen?: boolean;
    handleDialogClose: () => void;
  }) => React.ReactNode;
}

const DialogBtn = ({
  children,
  className,
  disabled,
  popup,
}: DialogBtnProps) => {
  const { isDialogOpen, dialogRef, handleToggleDialog, handleDialogClose } =
    useDialog();

  return (
    <S.Wrapper className={className}>
      <S.Button
        type="button"
        aria-haspopup="dialog"
        aria-pressed={isDialogOpen}
        disabled={disabled}
        onClick={handleToggleDialog}
      >
        {children}
      </S.Button>
      <S.Dialog
        ref={dialogRef}
        open={isDialogOpen}
        aria-current={isDialogOpen}
        aria-hidden={!isDialogOpen}
        aria-modal="true"
      >
        {popup({ dialogRef, isDialogOpen, handleDialogClose })}
      </S.Dialog>
    </S.Wrapper>
  );
};

export default DialogBtn;
