import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useCustomParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [id, setId] = useState(searchParams.get("id"));

  const handleUpdateQuery = (
    addQueries: { [key: string]: string },
    deleteQueries?: string[],
  ) => {
    let newParams = { ...Object.fromEntries(searchParams.entries()) };

    newParams = addQuery(newParams, addQueries);
    newParams = deleteQueries
      ? deleteQuery(newParams, deleteQueries)
      : newParams;

    setSearchParams(newParams);
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      setId(searchParams.get("id"));
    }
  }, [searchParams]);

  return { id, handleUpdateQuery };
};

export default useCustomParams;

const addQuery = (
  params: { [key: string]: string },
  addQueries: { [key: string]: string },
) => {
  for (const [key, value] of Object.entries(addQueries)) {
    params[key] = value;
  }

  return params;
};

const deleteQuery = (
  params: { [key: string]: string },
  deleteQueries: string[],
) => {
  for (const key of deleteQueries) {
    delete params[key];
  }

  return params;
};
