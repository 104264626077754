import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const card = css`
  row-gap: 0px;
  margin-bottom: 32px;

  & > div {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 12px;
    }
  }
`;

export const offerPriceLabelContentTable = (theme: Theme) => css`
  margin-bottom: 12px;

  & > div > div:first-of-type {
    border-top: 1px solid ${theme.color.gray_35};
  }
`;

export const Address = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 4px;

    & > div:not(:first-of-type) {
      color: ${theme.color.gray_70};
    }
  `}
`;

export const TypeWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 4px;

    & > div:last-of-type {
      color: ${theme.color.gray_70};
    }
  `}
`;

export const Type = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const tooltip = (theme: Theme) => css`
  border: 1px solid ${theme.color.gray_35};
  border-radius: 2px;
  padding: 1px 3px;

  & > button {
    color: ${theme.color.gray_60};
  }

  & > dl {
    top: 32px;
  }
`;

export const Heading = styled.h3`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
    margin-bottom: 12px;
  `}
`;

export const unitSuffixInput = css`
  width: 100%;
`;

export const offerPriceBtn = (isFoldOfferPrice: boolean) => css`
  width: fit-content;

  & > svg {
    rotate: ${isFoldOfferPrice ? "180deg" : "0deg"};
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

export const Total = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    padding-bottom: 12px;
    color: ${theme.color.black};
  `}
`;

export const SubtotalWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 8px;
    border-top: 1px solid ${theme.color.gray_30};
    padding-top: 12px;
  `}
`;

export const SubtotalItem = styled.div`
  display: flex;
`;

export const SubtotalLabel = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    width: 120px;
    color: ${theme.color.gray_70};
  `}
`;

export const SubtotalContent = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;

export const content = css`
  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const labelContentMemo = css`
  ${content}

  textarea {
    height: 120px;
  }
`;
