import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const card = css`
  width: 564px;

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

export const labelContent = css`
  margin-top: 16px;
`;

export const FileLimitInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 12px;
`;
