import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { mixin_scrollbar } from "@/styles";

export const Body = styled.ul`
  ${({ theme }) => css`
    ${mixin_scrollbar};

    max-height: 354px;
    border: 1px solid ${theme.color.gray_35};
    overflow-x: hidden;

    & > :not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray_30};
    }
  `}
`;

export const Quotation = styled.li`
  display: flex;
  width: 544px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    align-items: center;
    width: 210px;
    padding: 0 16px;
    color: ${theme.color.gray_70};
    background-color: ${theme.color.gray_10};
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  width: 334px;
  padding: 12px 16px;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.color.gray_70};
  `}
`;
