import type { Dispatch, SetStateAction } from "react";

import { useToast } from "@/hooks";
import { COMMON_TOAST_MSG } from "@/constants";

import { usePostAllocationOptimizing } from "services";
import { makeDeliveryToAllocation } from "utils";
import type {
  AllocationRoute,
  PostAllocationOptimizingQueryModel,
  SelectedAllocationDelivery,
} from "types";

const useDispatchOptimizing = (
  deliveries: SelectedAllocationDelivery[],
  handleAllocationWithSmartDispatchAdd: (
    allocations: AllocationRoute[],
  ) => void,
  setDeliveries: Dispatch<SetStateAction<SelectedAllocationDelivery[]>>,
) => {
  const req: PostAllocationOptimizingQueryModel = {
    body: {
      list: deliveries.map((delivery) => ({
        orderId: delivery.orderId,
        puName: delivery.puName,
        puLat: +delivery.puLat,
        puLng: +delivery.puLng,
        puEta: delivery.puEta.replace("Z", ""),
        doName: delivery.doName,
        doLat: +delivery.doLat,
        doLng: +delivery.doLng,
        doEta: delivery.doEta.replace("Z", ""),
        price: delivery.price,
        vat: delivery.vat,
      })),
    },
  };

  const { addToast } = useToast();
  const { refetch, isFetching: isSmartDispatchFetching } =
    usePostAllocationOptimizing(req, (res) => {
      if (!res.allocations) {
        handleAllocationWithSmartDispatchAdd([]);
        setDeliveries(deliveries);
        addToast(COMMON_TOAST_MSG.WARNING.RESULT_NOT_FOUND);
        return;
      }

      // NOTE: 왼쪽 Allocations 산입 부분 24/01/29
      const smartDispatchedAllocations = res.allocations.map((allocation) => {
        const selectedDelivery = deliveries.find(
          (delivery) => delivery.orderId === allocation.orderId,
        );

        return makeDeliveryToAllocation(selectedDelivery!, allocation.type);
      });

      handleAllocationWithSmartDispatchAdd(smartDispatchedAllocations);

      // NOTE: 오른쪽 Deliveries selected 값 변경 부분 24/01/29
      const selectedOrderIds = [
        ...new Set(res.allocations.map((item) => item.orderId)),
      ];
      const copiedDeliveries = [...deliveries];
      copiedDeliveries.forEach((delivery) => {
        if (selectedOrderIds.includes(delivery.orderId)) {
          delivery.selected = true;
        }
      });
      setDeliveries(copiedDeliveries);

      addToast(COMMON_TOAST_MSG.SUCCESS.SMART_DISPATCH_DONE);
    });

  return { refetch, isSmartDispatchFetching };
};

export default useDispatchOptimizing;
