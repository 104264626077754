import React from "react";
import { useTranslation } from "react-i18next";

import {
  InternalLink,
  QueryPagination,
  SettingLayout,
  Table,
} from "@/components";
import {
  filterTableColumns,
  findLookupTableLabel,
  formatICTDateTime,
  formatPrice,
  upperCaseToupperFirst,
} from "@/utils";
import { PATH, PAYMENT_METHOD_PLAN_RADIOS } from "@/assets";
import type { Languages } from "@/types";

import { useGetCompanyPlanInfo } from "services";
import { COMPANY_PLAN_TABLE_COLUMNS, SETTING_MENU } from "assets";
import type { GetCompanyPlansServerModel } from "types";
import * as S from "./CompanyPlan.styled";

const CompanyPlan = () => {
  const { t } = useTranslation();

  const { isLoading, data } = useGetCompanyPlanInfo();
  const filteredColumns = filterTableColumns(COMPANY_PLAN_TABLE_COLUMNS);

  const formatTableData = (
    data: GetCompanyPlansServerModel["plans"][number],
    key: keyof GetCompanyPlansServerModel["plans"][number],
  ) => {
    switch (key) {
      case "type":
      case "status":
        return t(upperCaseToupperFirst(data[key]) as Languages);

      case "startDate":
      case "endDate":
        return data[key] ? formatICTDateTime(data[key], "DD/MM/YYYY") : "-";

      case "total":
        // TODO: Total 추후에 반영해주신다고 하셔서 임의로 없을 경우에 대한 처리
        return data[key] ? formatPrice(data[key]) : "0 ₫";

      case "pay": {
        const payMethodLabel =
          findLookupTableLabel(PAYMENT_METHOD_PLAN_RADIOS, data[key]) ?? "";

        return payMethodLabel ? t(payMethodLabel) : "-";
      }

      default:
        return data[key];
    }
  };

  return (
    <SettingLayout settingMenu={SETTING_MENU}>
      <section>
        <h3 className="a11y">Payment history</h3>
        <S.Title>{t("Payment history")}</S.Title>
        <Table
          css={S.table}
          isLoading={isLoading}
          title="Payment"
          rowCount={data?.plans.length ?? 0}
        >
          <Table.Head columnList={COMPANY_PLAN_TABLE_COLUMNS} />
          <Table.Body css={S.body}>
            {data?.plans.length === 0 ? (
              <Table.NoData />
            ) : (
              data?.plans.map((plan) => (
                <Table.Row key={plan.planId}>
                  {filteredColumns.map(({ key }) => (
                    <Table.Cell key={key}>
                      {formatTableData(plan, key)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
        <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
        <InternalLink
          css={S.closeBtn}
          variant="outlineMedium"
          to={PATH.SETTINGS_COMPANY}
        >
          {t("Back to list")}
        </InternalLink>
      </section>
    </SettingLayout>
  );
};

export default CompanyPlan;
