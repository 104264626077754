import React from "react";
import { useTranslation } from "react-i18next";
import { upperFirst } from "lodash";

import { useAuth } from "@/contexts";
import {
  Button,
  DownloadBtn,
  ErrMsg,
  FormFileInput,
  FormRadioBtn,
  LabelContentTable,
  Textarea,
  UnitSuffixInput,
} from "@/components";
import { useModal, useToggle } from "@/hooks";
import {
  calculateVatAmount,
  calculateTotalAmount,
  findLookupTableLabel,
  formatPrice,
  makeOrderFileName,
  createAttachFiles,
  comma,
  deleteComma,
  numericOnly,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS, TAX_RADIOS } from "@/assets";
import type { Languages } from "@/types";

import { SettlementDetailRequestConfirmModal } from "components";
import { useDownloadFile } from "services";
import type { CompanyType, GetSettlementDetailServerModel } from "types";
import useSettlementDetailPaymentForm from "./hooks/useSettlementDetailPaymentForm";
import * as S from "./SettlementDetailPayment.styled";

interface SettlementPaymentProps {
  data: GetSettlementDetailServerModel<CompanyType>;
}

const paymentUtil = (
  data: GetSettlementDetailServerModel<CompanyType>,
): {
  paymentMethodLabel: (
    paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"],
  ) => Languages;
  vat: string;
  totalPrice: string;
  hasUploadFile: boolean;
} => {
  const paymentMethodLabel = (
    paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"],
  ) =>
    findLookupTableLabel(PAYMENT_METHOD_RADIOS, paymentMethod) ??
    "Account transfer";

  const vat = `(${data.payment.vat}%) ${formatPrice(
    calculateVatAmount(`${data.payment.price}`, `${data.payment.vat}`),
  )}`;

  const totalPrice = formatPrice(
    calculateTotalAmount(`${data.payment.price}`, `${data.payment.vat}`),
  );

  const hasUploadFile = Object.values(data.payment.attachment ?? {}).some(
    Boolean,
  );

  return { paymentMethodLabel, vat, totalPrice, hasUploadFile };
};

const SettlementDetailPayment = ({ data }: SettlementPaymentProps) => {
  const { t } = useTranslation();

  const {
    auth: { clientType },
  } = useAuth();

  const [isEdit, handleIsEdit] = useToggle();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const {
    formMethod,
    handleInputChange,
    handleDeleteFile,
    handleFormSuccess,
    handleFormFail,
  } = useSettlementDetailPaymentForm(data);
  const { paymentMethodLabel, vat, totalPrice, hasUploadFile } =
    paymentUtil(data);

  const { mutate: downloadFileMutate, isLoading } = useDownloadFile();

  const isShowButton =
    clientType === "carrier" ||
    (clientType === "forwarder" && data.type === "SHIPPER_TO_FORWARDER") ||
    (clientType === "forwardercarrier" &&
      (data.type === "SHIPPER_TO_FORWARDER" ||
        (data.type === "FORWARDER_TO_CARRIER" &&
          "isSelfTransport" in data.defaultInfo &&
          data.defaultInfo.isSelfTransport)));

  const handleCancel = (): void => {
    formMethod.reset();
    handleIsEdit();
  };

  const handleConfirm = (): void => {
    formMethod.handleSubmit(handleFormSuccess, handleFormFail)();

    handleModalClose();
    isEdit && handleIsEdit();
  };

  return (
    <>
      <LabelContentTable css={S.labelContentTable} variant="underline">
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Status">
            {t(upperFirst(data.status.toLocaleLowerCase()) as Languages)}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content
            labelWidth={200}
            label="Payment method"
            isRequired={isEdit}
          >
            {isEdit ? (
              <FormRadioBtn
                radioList={PAYMENT_METHOD_RADIOS}
                register={formMethod.register("paymentMethod")}
              />
            ) : (
              t(paymentMethodLabel(data.payment.paymentMethod))
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        {isEdit && (
          <LabelContentTable.Row>
            <LabelContentTable.Content
              css={S.content}
              labelWidth={200}
              label="Subtotal"
              isRequired
            >
              <UnitSuffixInput
                css={S.suffixInput}
                id="subTotalPrice"
                placeholder="Enter a price (only numbers)"
                value={formMethod.watch("price")}
                err={!!formMethod.formState.errors.price?.message}
                unit="₫"
                maxLength={20} // TODO: 임의로 작성
                register={formMethod.register("price", {
                  setValueAs: (value: string) =>
                    comma(deleteComma(numericOnly(value))),
                })}
              />
              {formMethod.formState.errors.price?.message && (
                <ErrMsg>
                  {formMethod.formState.errors.price.message as Languages}
                </ErrMsg>
              )}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        {isEdit && (
          <LabelContentTable.Row>
            <LabelContentTable.Content labelWidth={200} label="Tax rate">
              <FormRadioBtn
                radioList={TAX_RADIOS}
                register={formMethod.register("vat")}
              />
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        )}
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Total">
            <S.PriceWrapper>
              <S.Total>
                {isEdit
                  ? formatPrice(
                      calculateTotalAmount(
                        formMethod.watch("price"),
                        formMethod.watch("vat"),
                      ),
                    )
                  : totalPrice}
              </S.Total>
              <S.SubtotalWrapper>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                  <S.SubtotalContent>
                    {formatPrice(
                      isEdit
                        ? +deleteComma(formMethod.watch("price"))
                        : data.payment.price,
                    )}
                  </S.SubtotalContent>
                </S.SubtotalItem>
                <S.SubtotalItem>
                  <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                  <S.SubtotalContent>
                    {isEdit
                      ? `(${formMethod.watch("vat")}%) ${formatPrice(
                          calculateVatAmount(
                            formMethod.watch("price"),
                            formMethod.watch("vat"),
                          ),
                        )}`
                      : vat}
                  </S.SubtotalContent>
                </S.SubtotalItem>
              </S.SubtotalWrapper>
            </S.PriceWrapper>
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row>
          <LabelContentTable.Content labelWidth={200} label="Attached file">
            {isEdit ? (
              <S.FormFileInputWrapper>
                <FormFileInput
                  placeholder="Upload file"
                  file={formMethod.watch("attachment.attachment1")}
                  fileKey="attachment.attachment1"
                  hasErr={!!formMethod.formState.errors.attachment?.attachment1}
                  register={formMethod.register}
                  handleInputChange={handleInputChange}
                  handleDeleteFile={handleDeleteFile}
                />
                <FormFileInput
                  placeholder="Upload file"
                  file={formMethod.watch("attachment.attachment2")}
                  fileKey="attachment.attachment2"
                  hasErr={!!formMethod.formState.errors.attachment?.attachment2}
                  register={formMethod.register}
                  handleInputChange={handleInputChange}
                  handleDeleteFile={handleDeleteFile}
                />
                <S.FileLimit>
                  - {t("File format: jpg, jpeg, png or pdf (Limit 5MB)")}
                </S.FileLimit>
              </S.FormFileInputWrapper>
            ) : hasUploadFile && data.payment.attachment ? (
              <S.FileWrapper>
                {createAttachFiles([
                  data.payment.attachment.attachment1 || "",
                  data.payment.attachment.attachment2 || "",
                ]).map(
                  (fileKey, i) =>
                    fileKey && (
                      <DownloadBtn
                        key={fileKey}
                        downloadFileNamePrefix="SettlementAttachedFile"
                        fileName={makeOrderFileName(fileKey, i)}
                        fileKey={fileKey}
                        isLoading={isLoading}
                        downloadFileMutate={downloadFileMutate}
                      />
                    ),
                )}
              </S.FileWrapper>
            ) : (
              "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
        <LabelContentTable.Row css={S.memo}>
          <LabelContentTable.Content labelWidth={200} label="Memo">
            {isEdit ? (
              <>
                <Textarea
                  id="memo"
                  placeholder="Please enter information about the amount"
                  maxLength={150}
                  hasError={!!formMethod.formState.errors.memo?.message}
                  value={formMethod.watch("memo") || ""}
                  register={formMethod.register("memo")}
                />
                {formMethod.formState.errors.memo?.message && (
                  <ErrMsg>
                    {formMethod.formState.errors.memo?.message as Languages}
                  </ErrMsg>
                )}
              </>
            ) : (
              data.memo || "-"
            )}
          </LabelContentTable.Content>
        </LabelContentTable.Row>
      </LabelContentTable>
      {isShowButton && (
        <S.ButtonWrapper>
          <Button
            variant="outlineMedium"
            label={isEdit ? "Cancel" : "Edit"}
            handleClickBtn={handleCancel}
          />
          {(data.status === "WAITING" ||
            (data.status === "COMPLETED" && isEdit)) && (
            <Button
              variant="primaryMedium"
              label="Confirm"
              handleClickBtn={handleModalOpen(
                <SettlementDetailRequestConfirmModal
                  ref={modalRef}
                  handleRequestConfirm={handleConfirm}
                />,
              )}
            />
          )}
        </S.ButtonWrapper>
      )}
    </>
  );
};

export default SettlementDetailPayment;
