import React from "react";
import { useTranslation } from "react-i18next";

import * as S from "./OrderCreationLayout.styled";

interface OrderCreationLayoutProps {
  favoriteButton: React.ReactNode;
  LeftContent: React.ReactNode;
  RightContent: React.ReactNode;
}

const OrderCreationLayout = ({
  favoriteButton,
  LeftContent,
  RightContent,
}: OrderCreationLayoutProps) => {
  const { t } = useTranslation();

  return (
    <S.Root>
      <S.Header>
        <S.Title>{t("Order creation")}</S.Title>
        {favoriteButton}
      </S.Header>
      <S.Container>
        <S.LeftContent>{LeftContent}</S.LeftContent>
        <S.RightContent>{RightContent}</S.RightContent>
      </S.Container>
    </S.Root>
  );
};

export default OrderCreationLayout;
