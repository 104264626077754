import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Button, Dropdown, ErrMsg, LabelContent } from "@/components";
import { DeleteIcon20, MinusIcon, PlusCircleIcon16, PlusIcon } from "@/assets";
import type { Languages } from "@/types";

import { useTruck } from "hooks";
import type { OrderManagementForm } from "types";
import * as S from "./OrderManagementTruckForm.styled";

interface OrderManagementTruckFormProps {
  isEdit: boolean;
}

const OrderManagementTruckForm = ({
  isEdit,
}: OrderManagementTruckFormProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<OrderManagementForm>();
  const {
    fields: truckFields,
    remove,
    append,
  } = useFieldArray({ control, name: "truck" });
  const truckInfos = truckFields.map((truckField, i) => ({
    ...truckField,
    ...watch("truck")[i],
  }));

  const {
    dropdownTruckTypes,
    dropdownTruckOptions,
    handleTruckAdd,
    handleTruckDelete,
    handleTruckTypeSelect,
    handleTruckOptionSelect,
    handleTruckNum,
  } = useTruck();

  return (
    <S.TruckFormContent>
      {truckInfos.map((truck, i) => (
        <S.TruckWrapper key={truck.id}>
          {isEdit && (
            <Button
              css={S.deleteBtn}
              variant="ghostRed"
              icon={<DeleteIcon20 />}
              handleClickBtn={handleTruckDelete({
                trucks: truckInfos,
                remove,
                index: i,
              })}
            />
          )}
          <S.DropdownWrapper>
            <LabelContent label="Truck type" direction="vertical" isRequired>
              <Controller
                control={control}
                name="truck"
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    css={S.dropdown}
                    disabled={!isEdit}
                    placeholder="Select the truck type"
                    hasError={!!errors.truck?.[i]?.truckType?.message}
                    options={dropdownTruckTypes}
                    selectedOption={truck.truckType}
                    handleSelect={handleTruckTypeSelect({
                      selectIndex: i,
                      trucks: value,
                      onChange,
                    })}
                  />
                )}
              />
              {!!errors.truck?.[i]?.truckType?.message && (
                <ErrMsg>
                  {errors.truck?.[i]?.truckType?.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
            <S.TruckOptionsWrapper>
              <LabelContent
                label="Number of trucks"
                direction="vertical"
                isRequired
              >
                <S.TruckNumInputWrapper
                  isDisabled={!isEdit || !truckInfos[i].truckType.key}
                >
                  {watch("truck")[i].truckNum}
                  <Controller
                    control={control}
                    name="truck"
                    render={({ field: { value, onChange } }) => (
                      <Button
                        css={S.button}
                        variant="icon"
                        icon={<MinusIcon />}
                        isDisabled={
                          !isEdit ||
                          !truck.truckType.key ||
                          truck.truckNum === 1
                        }
                        handleClickBtn={handleTruckNum({
                          selectIndex: i,
                          trucks: value,
                          num: -1,
                          onChange,
                        })}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="truck"
                    render={({ field: { value, onChange } }) => (
                      <Button
                        css={S.button}
                        variant="icon"
                        icon={<PlusIcon />}
                        isDisabled={!isEdit || !truck.truckType.key}
                        handleClickBtn={handleTruckNum({
                          selectIndex: i,
                          trucks: value,
                          num: +1,
                          onChange,
                        })}
                      />
                    )}
                  />
                </S.TruckNumInputWrapper>
              </LabelContent>
              <LabelContent
                label="Truck option"
                direction="vertical"
                isRequired
              >
                <Controller
                  control={control}
                  name="truck"
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      css={S.dropdown}
                      disabled={!isEdit || !truckInfos[i].truckType.key}
                      hasError={
                        !!watch("truck")[i].truckType.key &&
                        !!errors?.truck?.[i]?.truckOption?.message
                      }
                      options={dropdownTruckOptions(+truck.truckType.key)}
                      placeholder="Select the truck option"
                      selectedOption={truck.truckOption}
                      handleSelect={handleTruckOptionSelect({
                        selectIndex: i,
                        trucks: value,
                        onChange,
                      })}
                    />
                  )}
                />

                {!!watch("truck")[i].truckType.key &&
                  !!errors.truck?.[i]?.truckOption?.message && (
                    <ErrMsg>
                      {errors.truck?.[i]?.truckOption?.message as Languages}
                    </ErrMsg>
                  )}
              </LabelContent>
            </S.TruckOptionsWrapper>
          </S.DropdownWrapper>
        </S.TruckWrapper>
      ))}
      {isEdit && (
        <Button
          css={S.addTruckBtn}
          variant="tertiaryIconLarge"
          label="Add the truck"
          icon={<PlusCircleIcon16 />}
          handleClickBtn={handleTruckAdd(append)}
        />
      )}
    </S.TruckFormContent>
  );
};

export default OrderManagementTruckForm;
