// TODO: any 타입 수정하기
export const filterDuplicateImg = (data: any[], key: string) => {
  const imgList: (string | number | null)[] = data.reduce((acc, cur) => {
    const imgIdx = acc.indexOf(cur[key]);
    const imgValue = imgIdx !== -1 ? imgIdx : cur[key];

    return [...acc, imgValue];
  }, []);

  return imgList;
};

export const getFilteredDriverProfiles = (
  profileUrls: (string | number)[],
  i: number,
) => {
  if (typeof profileUrls[i] === "number") return profileUrls[+profileUrls[i]];

  return profileUrls[i];
};
