import React from "react";

import { Loading, NoResult, QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";
import { formatSearchData } from "@/utils";

import { OngoingCard } from "components";
import { useGetOngoingDrivers } from "services";
import { ALLOCATION_QUERY_PARAMS } from "assets";
import type { GetOngoingDriverQueryModel } from "types";
import * as S from "./AllocationOngoingList.styled";

const AllocationOngoingList = () => {
  const { filters } = useFilterParams<
    GetOngoingDriverQueryModel["query"] & { search?: string }
  >(
    [
      ALLOCATION_QUERY_PARAMS.SEARCH,
      ALLOCATION_QUERY_PARAMS.PAGE,
      ALLOCATION_QUERY_PARAMS.CATEGORY,
    ],
    { [ALLOCATION_QUERY_PARAMS.CATEGORY]: "ALL" },
  );

  const query = {
    ...filters,
    ...(filters.search && {
      q: formatSearchData(filters.search),
    }),
  };
  delete query["search"];

  const req = { query };

  const { data, isLoading } = useGetOngoingDrivers(req);

  isLoading && <Loading />;

  return (
    <>
      {data?.allocations.length ? (
        <S.Ul>
          {data.allocations.map((allocation) => (
            <OngoingCard
              key={allocation.allocationId}
              data={allocation}
              isClickAble
              category={filters.category}
            />
          ))}
        </S.Ul>
      ) : (
        <NoResult />
      )}
      <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
    </>
  );
};

export default AllocationOngoingList;
