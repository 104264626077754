import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, InternalLink } from "@/components";
import { useModal } from "@/hooks";

import CancelAllocationModal from "./cancelAllocation/CancelAllocationModal";
import * as S from "./MenuDialog.styled";

interface MenuDialogProps {
  handleDialogClose: () => void;
}

const MenuDialog = ({ handleDialogClose }: MenuDialogProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const allocationId = searchParams.get("allocationId");
  const driverId = searchParams.get("driverId");

  const { handleModalOpen, modalRef } = useModal();

  return (
    <S.Root>
      <ul>
        <li>
          <Button
            css={S.button}
            variant="outlineMedium"
            label="Cancel allocation"
            handleClickBtn={handleModalOpen(
              <CancelAllocationModal ref={modalRef} />,
              handleDialogClose,
            )}
          />
        </li>
        <li>
          <InternalLink
            css={S.button}
            variant="outlineMedium"
            to={`/allocation/edit?allocationId=${allocationId}&driverId=${driverId}`}
          >
            {t("Edit allocation")}
          </InternalLink>
        </li>
      </ul>
    </S.Root>
  );
};

export default MenuDialog;
