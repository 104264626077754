import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AddrAutoComplete,
  Button,
  Card,
  ErrMsg,
  Input,
  LabelContent,
  PageBackHeader,
} from "@/components";
import { comma } from "@/utils";
import type { Languages } from "@/types";

import { CARRIER_VALID_MSG } from "constants/index";
import useEditGarage from "./hooks/useEditGarage";
import * as S from "./GarageEdit.styled";

const GarageEdit = () => {
  const navigate = useNavigate();

  const {
    isUpdateGarageLoading,
    errors,
    register,
    watch,
    handleSelectAddr,
    handleSubmitGarage,
  } = useEditGarage();

  const handleClickCancel = () => {
    navigate(-1);
  };

  return (
    <S.Root>
      <form>
        <h3 className="a11y">Edit the garage</h3>
        <PageBackHeader
          css={S.pageBackHeader}
          title="Edit the garage"
          path="/property?tab=garage"
        />
        <Card css={S.card}>
          <Card.Content heading="Default information">
            <LabelContent label="Garage name" isRequired direction="vertical">
              <Input
                placeholder="Garage name"
                value={watch("garageName")}
                hasError={!!errors.garageName?.message}
                maxLength={50}
                register={register("garageName", {
                  required: CARRIER_VALID_MSG.REQUIRED,
                  maxLength: 50,
                })}
              />
              {errors.garageName?.message && (
                <ErrMsg>{errors.garageName.message as Languages}</ErrMsg>
              )}
            </LabelContent>
            <LabelContent
              label="Garage address"
              direction="vertical"
              isRequired
            >
              <AddrAutoComplete
                err={errors.garageAddr?.address?.message}
                type="garage"
                value={watch("garageAddr")}
                placeholder="Address"
                handleSelect={handleSelectAddr}
              />
              <Input
                maxLength={100}
                placeholder="Detail address (Optional)"
                value={watch("garageAddr.addressDetail")}
                register={register("garageAddr.addressDetail")}
              />
              {errors.garageAddr?.address?.message && (
                <ErrMsg>
                  {errors.garageAddr.address.message as Languages}
                </ErrMsg>
              )}
            </LabelContent>
            <LabelContent label="Capacity" isRequired direction="vertical">
              <Input
                placeholder="Only numbers"
                hasError={!!errors.capacity?.message}
                value={watch("capacity")}
                maxLength={999}
                register={register("capacity", {
                  required: CARRIER_VALID_MSG.REQUIRED,
                  setValueAs: (value) => comma(value),
                  maxLength: 999,
                })}
              />
              {errors.capacity?.message && (
                <ErrMsg>{errors.capacity.message as Languages}</ErrMsg>
              )}
            </LabelContent>
          </Card.Content>
        </Card>
        <S.BtnWrapper>
          <Button
            variant="outlineMedium"
            label="Cancel"
            handleClickBtn={handleClickCancel}
          />
          <Button
            isLoading={isUpdateGarageLoading}
            isDisabled={Object.keys(errors).length > 0}
            variant="primaryMedium"
            label="Update"
            handleClickBtn={handleSubmitGarage}
          />
        </S.BtnWrapper>
      </form>
    </S.Root>
  );
};

export default GarageEdit;
