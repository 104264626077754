import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const MoreDialog = styled.div`
  ${({ theme }) => css`
    width: max-content;

    > ul > li {
      :not(:last-of-type) {
        border-bottom: 1px solid ${theme.color.gray_35};
      }

      > a {
        ${theme.font.regular_14};
        border: 0;
        padding: 12px 16px;
      }
    }
  `}
`;

export const goToOrderDetail = (theme: Theme) => css`
  column-gap: 10px;
  border: 0;
  padding: 0;

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 0;

    > g > path {
      fill: ${theme.color.gray_50};
    }
  }
`;
