import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { isEqual } from "lodash-es";

import {
  Button,
  Card,
  ErrMsg,
  ExternalLink,
  FormRadioBtn,
  LabelContentTable,
  Textarea,
  Tooltip,
  UnitSuffixInput,
} from "@/components";
import {
  calculateTotalAmount,
  calculateVatAmount,
  comma,
  deleteComma,
  findLookupTableLabel,
  formatOrderNum,
  formatPrice,
  formatQuantity,
  formatWeight,
  numericOnly,
  formatICTDateTime,
} from "@/utils";
import {
  DownArrowIcon16,
  CARGO_TYPE,
  PATH,
  TAX_RADIOS,
  PAYMENT_METHOD_RADIOS,
} from "@/assets";
import type { Languages } from "@/types";

import { SendQuotationTruckForm } from "components";
import { QUOTATION_SEND_FORM } from "assets";
import type { GetQuotationPendingClientModel, QuotationSendForm } from "types";
import * as S from "./SendQuotationOrderInfo.styled";

interface SendQuotationOrderInfoProps {
  data: GetQuotationPendingClientModel["pending"][number];
  idx: number;
}

const SendQuotationOrderInfo = ({ data, idx }: SendQuotationOrderInfoProps) => {
  const { t } = useTranslation();
  const [isFoldOfferPrice, setFoldOfferPrice] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    register,
    getValues,
  } = useFormContext<QuotationSendForm>();

  const { fields: orderFields } = useFieldArray({ control, name: "orders" });
  const orderInfos = orderFields.map((orderField, i) => ({
    ...orderField,
    ...watch("orders")[i],
  }));

  const payMethodLabel = findLookupTableLabel(PAYMENT_METHOD_RADIOS, data.pay);
  const firstCargo = data.cargoes[0];
  const cargoesLabel =
    findLookupTableLabel(CARGO_TYPE, firstCargo.cargoType) ?? "";

  const handleFoldOfferPrice = () => setFoldOfferPrice(!isFoldOfferPrice);

  useEffect(() => {
    setFoldOfferPrice(
      watch("isCheckSelfTransport") &&
        isEqual(getValues(), QUOTATION_SEND_FORM),
    );
  }, [watch("isCheckSelfTransport")]);

  return (
    <Card css={S.card}>
      <Card.Content
        heading="Order information"
        addHeadingElement={
          <ExternalLink
            href={`${PATH.QUOTATION}/${data.offerId}`}
            variant="ghostPrimary"
          >
            {formatOrderNum(data.orderNumber)}
          </ExternalLink>
        }
      >
        <LabelContentTable variant="underline">
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Company">
              {data.shipperName}
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Pick-up">
              <S.Address>
                <div>{data.puAddr}</div>
                {data.puAddrDetail && <div>{data.puAddrDetail}</div>}
                <div>
                  ({t("ETA")}) {formatICTDateTime(data.puEta)}
                </div>
              </S.Address>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Drop-off">
              <S.Address>
                <div>{data.doAddr}</div>
                {data.doAddrDetail && <div>{data.doAddrDetail}</div>}
                <div>
                  ({t("ETA")}) {formatICTDateTime(data.doEta)}
                </div>
              </S.Address>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Type">
              <S.TypeWrapper>
                <S.Type>
                  {t(cargoesLabel)}
                  {data.cargoes.length > 1 && (
                    <Tooltip
                      css={S.tooltip}
                      tooltipBtnContent={`+${data.cargoes.length}`}
                      position="BOTTOM_RIGHT"
                    >
                      {data.cargoes.map((cargo) => {
                        const cargoesLabel =
                          findLookupTableLabel(CARGO_TYPE, cargo.cargoType) ??
                          "";

                        return `${t(cargoesLabel)} \n`;
                      })}
                    </Tooltip>
                  )}
                </S.Type>
                <div>
                  {`${firstCargo.width}m x ${firstCargo.height}m x ${
                    firstCargo.length
                  }m / ${formatWeight(firstCargo.weight)} / ${formatQuantity(
                    firstCargo.cargoCnt,
                  )}`}
                </div>
              </S.TypeWrapper>
            </LabelContentTable.Content>
          </LabelContentTable.Row>
          <LabelContentTable.Row>
            <LabelContentTable.Content label="Received price">
              {formatPrice(
                calculateTotalAmount(`${data.price}`, `${data.vat}`),
              )}{" "}
              ({t("VAT Included")})
            </LabelContentTable.Content>
          </LabelContentTable.Row>
        </LabelContentTable>
      </Card.Content>
      {watch("isCheckSelfTransport") && (
        <Card.Content heading="Trucks">
          <SendQuotationTruckForm idx={idx} />
        </Card.Content>
      )}
      {isFoldOfferPrice && (
        <>
          <S.Heading>{t("Offer price")}</S.Heading>
          <LabelContentTable
            css={S.offerPriceLabelContentTable}
            variant="underline"
          >
            <LabelContentTable.Row>
              <LabelContentTable.Content label="Payment method">
                {payMethodLabel ? t(payMethodLabel) : "-"}
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LabelContentTable.Row>
              <LabelContentTable.Content css={S.content} label="Subtotal">
                <UnitSuffixInput
                  css={S.unitSuffixInput}
                  id="subTotal"
                  disabled={watch("isCheckSelfTransport")}
                  placeholder="Enter a price (only numbers)"
                  value={orderInfos[idx].subtotal}
                  unit="₫"
                  err={errors?.orders && errors?.orders[idx]?.subtotal}
                  maxLength={13}
                  register={register(`orders.${idx}.subtotal`, {
                    setValueAs: (value: string) =>
                      comma(deleteComma(numericOnly(value))),
                  })}
                />
                {errors?.orders && errors?.orders[idx]?.subtotal && (
                  <ErrMsg>
                    {errors?.orders[idx]?.subtotal?.message as Languages}
                  </ErrMsg>
                )}
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LabelContentTable.Row>
              <LabelContentTable.Content label="Tax rate">
                <FormRadioBtn
                  isDisabled={watch("isCheckSelfTransport")}
                  radioList={TAX_RADIOS}
                  register={register(`orders.${idx}.tax`)}
                />
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            <LabelContentTable.Row>
              <LabelContentTable.Content label="Total">
                <S.PriceWrapper>
                  <S.Total>
                    {formatPrice(
                      calculateTotalAmount(
                        watch("orders")[idx].subtotal ?? "0",
                        watch("orders")[idx].tax,
                      ),
                    )}
                  </S.Total>
                  <S.SubtotalWrapper>
                    <S.SubtotalItem>
                      <S.SubtotalLabel>{t("Subtotal")}</S.SubtotalLabel>
                      <S.SubtotalContent>
                        {formatPrice(
                          +deleteComma(watch("orders")[idx].subtotal),
                        )}
                      </S.SubtotalContent>
                    </S.SubtotalItem>
                    <S.SubtotalItem>
                      <S.SubtotalLabel>{t("Tax")}</S.SubtotalLabel>
                      <S.SubtotalContent>{`(${
                        watch("orders")[idx].tax
                      }%) ${formatPrice(
                        calculateVatAmount(
                          watch("orders")[idx].subtotal,
                          watch("orders")[idx].tax,
                        ),
                      )}`}</S.SubtotalContent>
                    </S.SubtotalItem>
                  </S.SubtotalWrapper>
                </S.PriceWrapper>
              </LabelContentTable.Content>
            </LabelContentTable.Row>
            {watch("isCheckSelfTransport") && (
              <LabelContentTable.Row>
                <LabelContentTable.Content
                  css={S.labelContentMemo}
                  label="Memo"
                >
                  <Textarea
                    id="memo"
                    placeholder="Write a comment about the order"
                    value={watch("orders")[idx].memo ?? ""}
                    hasError={!!errors.orders?.[idx]?.memo?.message}
                    maxLength={150}
                    register={register(`orders.${idx}.memo`)}
                  />
                  {errors.orders?.[idx]?.memo?.message && (
                    <ErrMsg>
                      {errors.orders?.[idx]?.memo?.message as Languages}
                    </ErrMsg>
                  )}
                </LabelContentTable.Content>
              </LabelContentTable.Row>
            )}
          </LabelContentTable>
        </>
      )}
      <Button
        css={S.offerPriceBtn(isFoldOfferPrice)}
        type="button"
        variant="outlineMedium"
        label="Offer price"
        icon={<DownArrowIcon16 />}
        handleClickBtn={handleFoldOfferPrice}
      />
    </Card>
  );
};

export default SendQuotationOrderInfo;
