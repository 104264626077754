import { pick, includes } from "lodash-es";

import type { StringKeyOf } from "@/types";

import { formatPeriodICTToUtc } from "./formatter";

export const getFilterParams = <T>(
  searchParams: URL["searchParams"],
  params: StringKeyOf<T>[],
  init?: Partial<T>,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    if (!includes(params, query)) return acc;

    const queryValue = searchParams.getAll(query);
    const value = queryValue.join(",");

    if (query === "date") {
      const [startDate, endDate] = formatPeriodICTToUtc(value);

      return { ...acc, startDate, endDate };
    }

    return {
      ...acc,
      [query]: value,
    };
  }, getDefaultQuery(init) as unknown as T);
};

export const getFilterParams2 = <T extends string>(
  searchParams: URL["searchParams"],
  queryKeys: T[],
) => {
  const obj = { page: "1", pageSize: "20" } as Record<
    T | "startDate" | "endDate" | "page" | "pageSize",
    string
  >;

  for (const queryKey of queryKeys) {
    const queryValue = searchParams.getAll(queryKey);
    const value = queryValue.join(",");
    if (!value) continue;

    if (queryKey === "date") {
      const [startDate, endDate] = formatPeriodICTToUtc(value);
      obj.startDate = startDate;
      obj.endDate = endDate;
    } else {
      obj[queryKey] = value;
    }
  }

  return obj;
};

const getDefaultQuery = <T>(init?: T) => ({
  page: "1",
  pageSize: "20",
  ...(init ?? {}),
});

export const getAllQuery = (searchParams: URL["searchParams"]) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce(
    (acc, query) => ({
      ...acc,
      [query]: searchParams.getAll(query),
    }),
    {} as { [key: string]: string[] },
  );
};

export const pickQuery = <T extends object>(query: T, pickList: string[]) =>
  pick(query, pickList);

export const hasRequiredFilterParam = (
  filterParams: Record<string, string>,
  requiredKeys: string[],
) => requiredKeys.some((requiredKey) => filterParams[requiredKey]);
