import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { auth } from "@/contexts";
import { useModal, useToast } from "@/hooks";
import { comma, deleteComma, findLookupTableLabel } from "@/utils";
import { PATH, PAYMENT_METHOD_RADIOS } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG } from "@/constants";
import type { DropdownOptionType, Languages } from "@/types";

import { SendQuotationCheckModal } from "components";
import { QUOTATION_SEND_FORM } from "assets";
import type {
  GetQuotationPendingClientModel,
  PostQuotationRequestQueryModel,
  SendQuotationRequestCommonBody,
  QuotationSendForm,
} from "types";

const ORDER_SCHEMA = {
  subtotal: yup
    .string()
    .optional()
    .test(
      "maxTotal",
      COMMON_VALID_MSG.INVALID_PRICE,
      (value) => +deleteComma(value) < 2147483647,
    ),
  tax: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  orderId: yup.string().required(COMMON_VALID_MSG.REQUIRED),
  orderNumber: yup.string().required(COMMON_VALID_MSG.REQUIRED),
};

const FORM_SCHEMA = yup.object({
  companyIds: yup
    .array()
    .required()
    .when("isCheckSelfTransport", {
      is: (isCheckSelfTransport: boolean) => !!isCheckSelfTransport,
      then: () => yup.array().optional(),
      otherwise: () =>
        yup
          .array()
          .required(COMMON_VALID_MSG.REQUIRED)
          .test(
            "required",
            COMMON_VALID_MSG.REQUIRED,
            (value) => !!value.length,
          ),
    }),
  isCheckSelfTransport: yup.boolean().required(),
  orders: yup
    .array()
    .required()
    .when("isCheckSelfTransport", {
      is: (isCheckSelfTransport: boolean) => !!isCheckSelfTransport,
      then: () =>
        yup
          .array()
          .required()
          .of(
            yup.object({
              ...ORDER_SCHEMA,
              memo: yup
                .string()
                .optional()
                .test(
                  "maxTotal",
                  COMMON_VALID_MSG.INVALID_MEMO_150,
                  (value) => (value?.length ?? 0) <= 150,
                ),
              truck: yup.array().of(
                yup.object({
                  truckType: yup
                    .mixed<DropdownOptionType>()
                    .required(COMMON_VALID_MSG.REQUIRED)
                    .test({
                      name: "required",
                      test: ({ key, label }) => !!(key && label),
                      message: COMMON_VALID_MSG.REQUIRED,
                    }),
                  truckOption: yup
                    .mixed<DropdownOptionType>()
                    .required(COMMON_VALID_MSG.REQUIRED)
                    .test({
                      name: "required",
                      test: ({ key, label }) => !!(key && label),
                      message: COMMON_VALID_MSG.REQUIRED,
                    }),
                  truckNum: yup.number().required(COMMON_VALID_MSG.REQUIRED),
                }),
              ),
            }),
          ),
      otherwise: () =>
        yup.array().of(
          yup.object({
            ...ORDER_SCHEMA,
            memo: yup.string().optional(),
            truck: yup.array().of(
              yup.object({
                truckType: yup.mixed<DropdownOptionType>().optional(),
                truckOption: yup.mixed<DropdownOptionType>().optional(),
                truckNum: yup.number().optional(),
              }),
            ),
          }),
        ),
    }),
});

const useQuotationSendForm = (locationState: {
  selectedOrders: GetQuotationPendingClientModel["pending"];
}) => {
  const navigate = useNavigate();

  const formMethod = useForm<QuotationSendForm>({
    defaultValues: QUOTATION_SEND_FORM,
    mode: "onTouched",
    resolver: yupResolver(FORM_SCHEMA),
  });

  const { addToast } = useToast();
  const { modalRef, handleModalClose, handleModalOpen } = useModal();

  function makeCompanyRequestMutateFormBody(
    clientType: "forwarder",
    data: QuotationSendForm,
  ): PostQuotationRequestQueryModel<"forwarder">;
  function makeCompanyRequestMutateFormBody(
    clientType: "forwardercarrier",
    data: QuotationSendForm,
  ): PostQuotationRequestQueryModel<"forwardercarrier">;
  function makeCompanyRequestMutateFormBody(
    clientType: "forwarder" | "forwardercarrier",
    data: QuotationSendForm,
  ): PostQuotationRequestQueryModel<"forwarder" | "forwardercarrier"> {
    const commonBody: SendQuotationRequestCommonBody<
      "forwarder" | "forwardercarrier"
    > = {
      orders: data.orders.map((order) => ({
        orderId: order.orderId,
        price: +deleteComma(order.subtotal) ?? 0,
        vat: +order.tax,
        ...(order.memo && { remarks: order.memo }),
      })),
    };

    switch (clientType) {
      case "forwarder": {
        return {
          body: {
            ...commonBody,
            truckingCompanyId: data.companyIds.map(({ id }) => id),
          },
        };
      }
      case "forwardercarrier": {
        return {
          body: {
            ...commonBody,
            truckingCompanyId: data.isCheckSelfTransport
              ? [auth.companyId!]
              : data.companyIds.map(({ id }) => id),
            isSelfTransport: data.isCheckSelfTransport,
            ...(data.isCheckSelfTransport && {
              orders: data.orders.map((order, i) => ({
                ...commonBody.orders[i],
                ...(data.isCheckSelfTransport && {
                  trucks: order.truck.reduce(
                    (acc: Record<string, number>, item) => {
                      acc[`${item.truckOption.key}`] = item.truckNum;
                      return acc;
                    },
                    {},
                  ),
                }),
              })),
            }),
          },
        };
      }
    }
  }

  const selectMutateOption = {
    onSuccess: async () => {
      await formMethod.reset(makeInitData(locationState.selectedOrders));
      await handleModalClose();
      await navigate(`${PATH.QUOTATION}?tab=ongoing`);
      await addToast(COMMON_TOAST_MSG.SUCCESS.SEND_QUOTAIUON_DONE);
    },
    onError: () => {
      addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
    },
  };

  const handleSendModalOpen = (send: () => void) => (): void => {
    handleModalOpen(
      <SendQuotationCheckModal
        ref={modalRef}
        truckingCompany={auth.companyName!}
        watch={formMethod.watch}
        handleSend={send}
      />,
    )();
  };

  const makeInitData = (data?: GetQuotationPendingClientModel["pending"]) => {
    if (!data) return;

    return {
      ...QUOTATION_SEND_FORM,
      isCheckSelfTransport: formMethod.watch("isCheckSelfTransport"),
      orders: locationState.selectedOrders.map((order) => ({
        orderId: order.orderId,
        orderNumber: order.orderNumber,
        subtotal: formMethod.watch("isCheckSelfTransport")
          ? comma(`${order.price}`)
          : "",
        tax: `${order.vat}`,
        memo: "",
        pay:
          findLookupTableLabel(PAYMENT_METHOD_RADIOS, order.pay) ??
          "Account transfer",
        truck: [
          {
            truckType: { key: "", label: "" as Languages },
            truckOption: { key: "", label: "" as Languages },
            truckNum: 1,
          },
        ],
      })),
    };
  };

  useEffect(() => {
    formMethod.reset(makeInitData(locationState.selectedOrders));
  }, [locationState, formMethod.watch("isCheckSelfTransport")]);

  return {
    formMethod,
    makeInitData,
    makeCompanyRequestMutateFormBody,
    selectMutateOption,
    handleSendModalOpen,
  };
};

export default useQuotationSendForm;
