import { useState, useEffect, useRef } from "react";
import {
  AutocompletePrediction,
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { limitGoogleAddressLength } from "@/utils";
import type { AddressSearchType } from "@/types";

const useAddrAutoComplete = (
  initState: AddressSearchType,
  selectFn: (value: AddressSearchType) => void,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const [addrSuggestions, setAddrSuggestions] = useState<
    AutocompletePrediction[]
  >([]);

  const handleAddrFocus = () => {
    const initLocationInfo = {
      address: "",
      buildingName: "",
      city: "",
      placeId: "",
      coord: {
        lat: 0,
        lng: 0,
      },
    };

    setInputValue("");
    selectFn(initLocationInfo);
  };

  const handleAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 500) return;

    setInputValue(e.target.value);
  };

  const updateAddressInfo = (locationInfo: AddressSearchType) => {
    setInputValue(locationInfo.address);
    setAddrSuggestions([]);
    selectFn(locationInfo);
  };

  const handleAddrSelect = (info: AutocompletePrediction) => async () => {
    if (!info) return;

    const description = info.description;
    const res = await geocodeByAddress(description);
    const latLng = await getLatLng(res[0]);
    const { terms, place_id: placeId } = info;
    const buildingName = terms[0].value;
    const city = terms[terms.length - 2].value;

    const address = limitGoogleAddressLength(description);

    const AddressSearchType = {
      address,
      buildingName,
      city,
      placeId,
      coord: {
        lng: latLng.lng,
        lat: latLng.lat,
      },
    };

    setInputValue(address);
    setAddrSuggestions([]);
    selectFn(AddressSearchType);
  };

  const handleModalAddrSelect = (addrInfo: AddressSearchType) => {
    updateAddressInfo(addrInfo);
  };

  const getAutocompleteAddrInfo = () => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      { input: inputValue },
      (predictions) => {
        setAddrSuggestions(predictions || []);
      },
    );
  };

  const handleAddrKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getAutocompleteAddrInfo();
    }
  };

  useEffect(() => {
    setInputValue(initState.address);
  }, [initState]);

  return {
    inputRef,
    addrSuggestions,
    inputValue,
    getAutocompleteAddrInfo,
    handleAddrFocus,
    handleAddrChange,
    handleAddrKeyPress,
    handleAddrSelect,
    handleModalAddrSelect,
  };
};

export default useAddrAutoComplete;
