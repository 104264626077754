import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixin_li, mixin_limit_one_line, mixin_ul } from "@/styles";

export const Root = styled.li<{
  isClickAble: boolean;
  isSelected: boolean;
}>`
  ${({ theme, isSelected, isClickAble }) => css`
    display: block;
    width: 100%;
    border-bottom: 1px solid ${theme.color.gray_20};
    padding: 16px 20px;
    background-color: ${isSelected && theme.color.gray_00};

    cursor: ${isClickAble ? "pointer" : "unset"};
    :hover {
      background-color: ${isClickAble && theme.color.gray_00};
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;

    & > h3 {
      ${mixin_limit_one_line};
      ${theme.font.medium_15};
      padding-right: 10px;
      color: ${theme.color.black};
    }
  `}
`;

export const Ul = styled.ul`
  ${mixin_ul};
`;

export const Li = styled.li`
  ${mixin_li};
  column-gap: 8px;
`;
