import React from "react";

import { Button, QuerySearchInput, Tab } from "@/components";
import { ResetIcon } from "@/assets";

import {
  Foldable1DepthLayout,
  TruckMonitoringShippingList,
  MonitoringWaitingList,
} from "components";
import { useGetMonitoringTrucks } from "services";
import { MONITORING_QUERY_PARAMS, MONITORING_TABS } from "assets";
import { useMonitoring } from "./hooks";
import * as S from "./Monitoring.styled";

const ForwarderCarrierMonitoring = () => {
  const { status, heading, deleteSearchParams, makeMonitoringFilters } =
    useMonitoring("forwardercarrier");

  const { data, refetch } = useGetMonitoringTrucks({
    query: {
      ...makeMonitoringFilters("forwardercarrier").restFilters,
      status,
      registNumber: makeMonitoringFilters("forwardercarrier").search,
    },
  });

  if (!data) return null;

  const render = {
    shipping: <TruckMonitoringShippingList trucks={data} />,
    waiting: <MonitoringWaitingList trucks={data} />,
  };

  const resetClick = (): void => {
    refetch();
    deleteSearchParams();
  };

  return (
    <Foldable1DepthLayout heading={heading}>
      <QuerySearchInput
        css={S.querySearchInput}
        placeholder="Search by plate number"
        searchQuery={MONITORING_QUERY_PARAMS.SEARCH}
        deleteQueries={[
          MONITORING_QUERY_PARAMS.PAGE,
          MONITORING_QUERY_PARAMS.TRUCK_ID,
          MONITORING_QUERY_PARAMS.TYPE,
        ]}
      />
      <Tab css={S.tab} tabs={MONITORING_TABS}>
        <S.TabListWrapper>
          <Tab.TabList />
          <Button
            css={S.resetBtn}
            variant="text"
            icon={<ResetIcon />}
            label="Reset"
            handleClickBtn={resetClick}
          />
        </S.TabListWrapper>
        <Tab.TabPanel css={S.tabPanel} render={render} />
      </Tab>
    </Foldable1DepthLayout>
  );
};

export default ForwarderCarrierMonitoring;
