import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { ErrMsg } from "@/components";
import { DeleteIcon16, PlusCircleIcon16 } from "@/assets";
import type { Languages } from "@/types";

import { AutoComplete } from "components";
import type { AutoCompleteDropdown } from "types";
import * as S from "./AutoCompletes.styled";

interface AutoCompletesProps {
  placeholder: Languages;
  id: string;
  dropdowns: AutoCompleteDropdown[];
  fields: AutoCompleteDropdown[];
  handleDropdownSelect: (
    i: number,
  ) => (selectedDropdown: AutoCompleteDropdown) => void;
  handleDropdownSlotDelete: (idx: number) => () => void;
  handleDropdownSlotAdd: () => void;
}

const AutoCompletes = ({
  placeholder,
  id,
  dropdowns,
  fields,
  handleDropdownSelect,
  handleDropdownSlotDelete,
  handleDropdownSlotAdd,
}: AutoCompletesProps) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<{ autoCompletes: AutoCompleteDropdown[] }>();

  const hasEnteredIds = (dropdown: AutoCompleteDropdown): boolean =>
    watch("autoCompletes")
      .map(({ id }) => id)
      .includes(dropdown.id);

  const isSameIdAsEntered = (
    dropdown: AutoCompleteDropdown,
    i: number,
  ): boolean => watch(`autoCompletes.${i}`).id === dropdown.id;

  return (
    <S.AutoCompleteWrapper>
      {fields.map((field, i) => {
        const filteredDropdowns = dropdowns.filter(
          (dropdown) =>
            !hasEnteredIds(dropdown) || isSameIdAsEntered(dropdown, i),
        );

        return (
          <Fragment key={i}>
            <S.AutoCompleteItem>
              <AutoComplete
                placeholder={placeholder}
                id={id}
                value={field.name}
                hasError={!!errors.autoCompletes?.[i]?.message}
                dropdowns={filteredDropdowns}
                selectDropdown={handleDropdownSelect(i)}
                register={register(`autoCompletes.${i}.name`)}
              />
              {fields.length === 1 || fields.length - 1 === i ? (
                <S.Btn
                  type="button"
                  aria-label={`add ${id} slot`}
                  onClick={handleDropdownSlotAdd}
                >
                  <PlusCircleIcon16 css={S.plusCircleIcon} />
                </S.Btn>
              ) : (
                <S.Btn
                  type="button"
                  aria-label={`delete ${id} slot`}
                  onClick={handleDropdownSlotDelete(i)}
                >
                  <DeleteIcon16 css={S.deleteIcon} />
                </S.Btn>
              )}
            </S.AutoCompleteItem>
            {errors.autoCompletes && errors.autoCompletes[i]?.message && (
              <ErrMsg>{errors.autoCompletes[i]?.message as Languages}</ErrMsg>
            )}
          </Fragment>
        );
      })}
    </S.AutoCompleteWrapper>
  );
};

export default AutoCompletes;
