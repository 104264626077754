import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const modal = css`
  padding: 24px;
  width: 408px;
`;

export const labelContent = css`
  margin-bottom: 24px;
`;

export const IconWrapper = styled.div`
  position: relative;
`;

export const PasswordShowButton = styled.button<{ isPasswordShow: boolean }>`
  ${({ theme, isPasswordShow }) => css`
    position: absolute;
    bottom: 10px;
    right: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: 18px;
    height: 18px;
    background-color: inherit;

    & > svg > path {
      fill: ${isPasswordShow ? theme.color.primary_40 : theme.color.gray_50};
    }
  `}
`;
