import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetQuotationPending } from "services";
import type { GetQuotationPendingQueryModel } from "types";
import QuotationPendingSection from "./section/QuotationPendingSection";
import * as S from "./QuotationPending.styled";

const QuotationPending = () => {
  const { filters: pendingFilters } = useFilterParams<
    GetQuotationPendingQueryModel["query"]
  >(["page"]);

  const { data, dataUpdatedAt, refetch } = useGetQuotationPending({
    query: pendingFilters,
  });

  return (
    <>
      {data && (
        <>
          <QuotationPendingSection
            data={data}
            dataUpdatedAt={dataUpdatedAt}
            refetch={refetch}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default QuotationPending;
