import React from "react";

import { QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetQuotationOngoing } from "services";
import type { CompanyType, GetQuotationOngoingQueryModel } from "types";
import QuotationOngoingSection from "./section/QuotationOngoingSection";
import QuotationOngoingFilter from "./filter/QuotationOngoingFilter";
import * as S from "./QuotationOngoing.styled";

interface QuotationOngoingProps {
  clientType: CompanyType;
}

const QuotationOngoing = ({ clientType }: QuotationOngoingProps) => {
  const { filters } = useFilterParams<
    GetQuotationOngoingQueryModel<typeof clientType>["query"]
  >(["page", "status"]);

  const { data, dataUpdatedAt, refetch } = useGetQuotationOngoing<
    typeof clientType
  >({ query: filters });

  return (
    <>
      <QuotationOngoingFilter />
      {data && (
        <>
          <QuotationOngoingSection
            data={data}
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
          />
          <QueryPagination css={S.pagination} pageInfo={data.pageInfo} />
        </>
      )}
    </>
  );
};

export default QuotationOngoing;
