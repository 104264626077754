import type { RadioType } from "@/types";

export const RADIO = {
  ACCOUNT_TRANSPER: { key: "ACCOUNT_TRANSFER", label: "Account transfer" },
  ALLOCATED: { key: "ALLOCATED", label: "Allocated" },
  ALL: { key: "all", label: "All" },
  ANSWERED: { key: "ANSWERED", label: "Answered" },
  AVAILABLE: { key: "AVAILABLE", label: "Available" },
  COD: { key: "COD", label: "CoD" },
  COMPLETED: { key: "COMPLETED", label: "Completed" },
  CREDIT_CARD: { key: "CREDIT_CARD", label: "Credit card" },
  DELIVERED: { key: "DELIVERED", label: "Delivered" },
  EIGHT_PERCENT: { key: "8", label: "8%" },
  FTL: { key: "FTL", label: "FTL" },
  FORWARDING: { key: "FORWARDING", label: "Forwarding" },
  LTL: { key: "LTL", label: "LTL" },
  NO: { key: "no", label: "No" },
  ONGOING: { key: "ONGOING", label: "Ongoing" },
  REJECTED: { key: "REJECTED", label: "Rejected" },
  REQUESTED: { key: "REQUESTED", label: "Requested" },
  REQUESTED_ONGOING: { key: "ONGOING", label: "Requested" },
  TEN_PERCENT: { key: "10", label: "10%" },
  UNAVAILABLE: { key: "UNAVAILABLE", label: "Unavailable" },
  WAITING: { key: "WAITING", label: "Waiting" },
  YES: { key: "yes", label: "Yes" },
} as const;

export const PAYMENT_METHOD_RADIOS: RadioType[] = [
  RADIO.ACCOUNT_TRANSPER,
  RADIO.COD,
];

export const PAYMENT_METHOD_PLAN_RADIOS: RadioType[] = [
  RADIO.ACCOUNT_TRANSPER,
  RADIO.CREDIT_CARD,
];

export const TAX_RADIOS: RadioType[] = [RADIO.EIGHT_PERCENT, RADIO.TEN_PERCENT];

export const HAZARD_GOODS_RADIOS: RadioType[] = [RADIO.YES, RADIO.NO];

export const STACKABLE_GOODS_RADIOS: RadioType[] = [RADIO.YES, RADIO.NO];

export const LOADING_STATUS_RADIOS: RadioType[] = [RADIO.LTL, RADIO.FTL];

export const AVAILABLE_RADIOS: RadioType[] = [
  RADIO.AVAILABLE,
  RADIO.UNAVAILABLE,
];

export const TRUCK_FILTER_RADIOS: RadioType[] = [
  RADIO.ALL,
  ...AVAILABLE_RADIOS,
];
