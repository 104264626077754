import { lowerCase, upperCase } from "lodash-es";
import { useSearchParams } from "react-router-dom";

import { useFilterParams } from "@/hooks";

import { MONITORING_QUERY_PARAMS, MONITORING_TABS } from "assets";
import type {
  GetMonitoringTrucksQueryModel,
  GetOrderManagementShippingQueryModel,
  TruckStatus,
} from "types";

export const useMonitoring = (
  clientType: "shipper" | "forwardercarrier" | "carrier",
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const status =
    searchParams.get(MONITORING_QUERY_PARAMS.TAB) ===
    lowerCase(MONITORING_TABS[1].key)
      ? (upperCase(MONITORING_TABS[1].key) as TruckStatus)
      : (upperCase(MONITORING_TABS[0].key) as TruckStatus);

  const heading =
    MONITORING_TABS.find(
      ({ key }) => key === searchParams.get(MONITORING_QUERY_PARAMS.TAB),
    )?.label ?? MONITORING_TABS[0].label;

  const deleteSearchParams = (): void => {
    searchParams.delete(
      clientType === "shipper"
        ? MONITORING_QUERY_PARAMS.ORDER_ID
        : MONITORING_QUERY_PARAMS.TRUCK_ID,
    );
    searchParams.delete(MONITORING_QUERY_PARAMS.TYPE);
    searchParams.delete(MONITORING_QUERY_PARAMS.SEARCH);
    setSearchParams(searchParams);
  };

  function makeMonitoringFilters(clientType: "shipper"): {
    restFilters: GetOrderManagementShippingQueryModel["query"];
  } & {
    search?: string;
  };
  function makeMonitoringFilters(clientType: "forwardercarrier" | "carrier"): {
    restFilters: GetMonitoringTrucksQueryModel["query"];
  } & {
    search?: string;
  };
  function makeMonitoringFilters(
    clientType: "shipper" | "forwardercarrier" | "carrier",
  ):
    | ({ restFilters: GetOrderManagementShippingQueryModel["query"] } & {
        search?: string;
      })
    | ({ restFilters: GetMonitoringTrucksQueryModel["query"] } & {
        search?: string;
      }) {
    switch (clientType) {
      case "shipper": {
        const { filters } = useFilterParams<
          GetOrderManagementShippingQueryModel["query"] & { search?: string }
        >(["orderNumber", "search"]);

        const { search, ...restFilters } = filters;

        return { search, restFilters };
      }

      case "forwardercarrier":
      case "carrier": {
        const { filters } = useFilterParams<
          GetMonitoringTrucksQueryModel["query"] & { search?: string }
        >(["status", "registNumber", "search", "page"]);

        const { search, ...restFilters } = filters;

        return { search, restFilters };
      }
    }
  }

  return {
    status,
    heading,
    deleteSearchParams,
    makeMonitoringFilters,
  };
};

export default useMonitoring;
