import { useMutation, useQuery } from "@tanstack/react-query";

import { COMMON_ERROR_CODE } from "@/constants";
import type { APIError } from "@/types";

import {
  changeAccountPasswordAPI,
  getAccountInfoAPI,
  putAccountInfoAPI,
} from "apis";
import type {
  UpdateAccountInfoQueryModel,
  ChangeAccountPasswordQueryModel,
} from "types";

export const useGetAccountInfo = () => {
  return useQuery({
    queryKey: ["accountInfo"],
    queryFn: () => getAccountInfoAPI(),
  });
};

export const useUpdateAccountInfo = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    UpdateAccountInfoQueryModel
  >({
    mutationFn: (req) => putAccountInfoAPI(req),
  });
};

export const useChangeAccountPassword = () => {
  return useMutation<
    unknown,
    APIError<typeof COMMON_ERROR_CODE>,
    ChangeAccountPasswordQueryModel
  >({
    mutationFn: (req) => changeAccountPasswordAPI(req),
  });
};
