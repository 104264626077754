import React, { Fragment, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InternalLink, TableOfContent } from "@/components";
import { hasNotCanceledDriverDeliveryStatus } from "@/utils";
import { MapIcon, PATH } from "@/assets";
import type { Languages } from "@/types";

import {
  OrderManagementAllocationInfo,
  OrderManagementCargoDetails,
  OrderManagementDefaultInfo,
  OrderManagementDetailLayout,
  OrderManagementDropOffInfo,
  OrderManagementPayment,
  OrderManagementPickUpInfo,
  OrderManagementTruckingMethods,
  OrderManagementTruckList,
} from "components";
import { useGetOrderManagementDetail } from "services";
import * as S from "./OrderManagementDetail.styled";

const ShipperOrderManagementDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetOrderManagementDetail({
    suspense: false,
    orderId: id!,
  });

  if (!data) return null; // TODO: 현재는 스켈레톤이 없어서 null을 반환하여 화면 렌더링을 깔끔하게 함 -> 스켈레톤이 생기면 삭제 필요 why? 이게 있으면 스켈레톤이 동작을 안 함

  const status = data.status;
  const isShowCargoTrackingLink = !!status && status === "COMPLETED";
  const isHideOrderCancelBtn =
    !data.isSelfOrder ||
    status === "CANCELED" ||
    status === "COMPLETED" ||
    hasNotCanceledDriverDeliveryStatus(data.allocationInfo.drivers); // TODO: 서버에서 driverStatus를 반환하는지 확인 필요

  const contents = [
    {
      text: "Default information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDefaultInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: `Truck list`,
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementTruckList data={data} />
        </Suspense>
      ),
    },
    {
      text: "Allocation information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementAllocationInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Pick-up information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPickUpInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Drop-off information",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementDropOffInfo data={data} />
        </Suspense>
      ),
    },
    {
      text: "Cargo details",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementCargoDetails data={data} />
        </Suspense>
      ),
    },
    {
      text: "Trucking methods",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementTruckingMethods data={data} />
        </Suspense>
      ),
    },
    {
      text: "Payment",
      content: (
        <Suspense fallback={<Fragment />}>
          <OrderManagementPayment data={data} />
        </Suspense>
      ),
    },
  ];

  return (
    <OrderManagementDetailLayout
      tableOfContent={
        <TableOfContent
          css={S.tableOfContent}
          pageName="Order details"
          contents={contents.map(({ text, content }) => ({
            heading: { headingLevel: "h2", text },
            content,
          }))}
          stickyBottom={
            isShowCargoTrackingLink ? (
              <InternalLink
                css={S.cargoTracking}
                variant="ghostNoUnderlineGray"
                to={`${PATH.ORDER_MANAGEMENT_MAP}?orderId=${id}`}
              >
                <MapIcon />
                {/* TODO: 번역 추가되면 as Languages 삭제할 것 */}
                {t("Tracking history" as Languages)}
              </InternalLink>
            ) : null
          }
        />
      }
      isHideOrderCancelBtn={isHideOrderCancelBtn}
      data={data}
    />
  );
};

export default ShipperOrderManagementDetail;
