import React from "react";
import { useTranslation } from "react-i18next";

import type { Languages } from "@/types";

import * as S from "./ErrMsg.styled";

interface ErrMsgProps {
  children: Languages;
  className?: string;
}

const ErrMsg = ({ children, className }: ErrMsgProps) => {
  const { t } = useTranslation();

  return <S.ErrMsg className={className}>{t(children)}</S.ErrMsg>;
};

export default ErrMsg;
