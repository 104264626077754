import { useState, useEffect, useMemo } from "react";

import { createPageList } from "@/utils";

const usePagination = (
  currentPage: number,
  maxPageCount: number,
  totalPages: number,
) => {
  const [pageNumList, setPageNumList] = useState<number[]>([]);

  const isDisabledPrev = useMemo(() => {
    return currentPage - maxPageCount <= 0;
  }, [currentPage]);

  const isDisabledNext = useMemo(() => {
    /** NOTE: edge case: maxPageCount === totalPages 일때
     *  ex) maxPageCount = 10, totalPages = 10
     */
    if (maxPageCount === totalPages) return true;

    return (
      Math.floor((currentPage + maxPageCount - 1) / maxPageCount) >
      Math.floor(totalPages / maxPageCount)
    );
  }, [currentPage, maxPageCount, totalPages]);

  useEffect(() => {
    const newPageNumList = createPageList(
      currentPage,
      maxPageCount,
      totalPages,
    );
    setPageNumList(newPageNumList);
  }, [currentPage, maxPageCount, totalPages]);

  return {
    isDisabledPrev,
    isDisabledNext,
    pageNumList,
  };
};

export default usePagination;
