import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const detailTable = (theme: Theme) => css`
  & > span {
    ${theme.font.bold_18};
    display: block;
    margin-bottom: 20px;
  }

  & > div {
    width: 1294px;
  }
`;

export const description = css`
  & > div {
    align-items: flex-start;
    min-height: 148px;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const internalLink = css`
  margin: 33px 0 0 auto;
  width: fit-content;
`;
