import React from "react";

import { StatusLabel } from "@/components";
import { TimeIcon, PersonIcon, PhoneIcon } from "@/assets";
import { formatICTDateTime, formatOrderNum, formatPhoneNum } from "@/utils";

import { ClickCard } from "components";
import type { OrderCard } from "types";
import * as S from "./MonitoringOrderCard.styled";

interface MonitoringOrderCardProps {
  orderCard: OrderCard;
}

const MonitoringOrderCard = ({ orderCard }: MonitoringOrderCardProps) => {
  return (
    <ClickCard css={S.orderCard}>
      <ClickCard.Header>
        <h3>{formatOrderNum(orderCard.orderNumber)}</h3>
        <StatusLabel status={orderCard.status} />
      </ClickCard.Header>
      <ClickCard.Body>
        <ClickCard.BodyDetail>
          <PersonIcon css={S.svg} />
          <S.Ellipsis>{orderCard.name || "-"}</S.Ellipsis>
        </ClickCard.BodyDetail>
        <ClickCard.BodyDetail>
          <PhoneIcon css={S.grayIcon} />
          <span>{orderCard.phone ? formatPhoneNum(orderCard.phone) : "-"}</span>
        </ClickCard.BodyDetail>
        <ClickCard.BodyDetail>
          <TimeIcon css={S.svg} />
          <time>(ETA) {formatICTDateTime(orderCard.puEta)}</time>
        </ClickCard.BodyDetail>
      </ClickCard.Body>
    </ClickCard>
  );
};

export default MonitoringOrderCard;
