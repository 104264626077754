import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const TextareaContent = styled.textarea<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    ${theme.font.regular_14};
    width: 100%;
    height: 90px;
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_35};
    border-radius: 2px;
    padding: 12px 67px 12px 12px;
    background-color: ${theme.color.white};

    &[aria-invalid="true"] {
      border-color: ${theme.color.red_20};
    }

    &:focus {
      border-color: ${theme.color.primary_40};
    }

    &:disabled {
      background-color: ${theme.color.gray_10};
    }

    &::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const TextareaLength = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: ${theme.color.gray_50};
  `}
`;
