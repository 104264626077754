import React from "react";

import { auth } from "@/contexts";
import { Filter } from "@/components";

import { ORDER_ALLOCATION_FILTER_STATUS_RADIOS } from "assets";

const OrderAllocationFilter = () => {
  const searchLabels = [
    ...(auth.clientType === "carrier" || auth.clientType === "forwardercarrier"
      ? [
          {
            queryKey: "status",
            label: "Status",
            options: ORDER_ALLOCATION_FILTER_STATUS_RADIOS,
          },
        ]
      : []),
    { queryKey: "orderNumber", label: "OrderNumber" },
  ];

  return (
    <Filter searchLabels={searchLabels}>
      {(auth.clientType === "carrier" ||
        auth.clientType === "forwardercarrier") && (
        <Filter.Row>
          <Filter.Condtion conditionName="Status">
            <Filter.Radio
              queryKey="status"
              radios={ORDER_ALLOCATION_FILTER_STATUS_RADIOS}
            />
          </Filter.Condtion>
        </Filter.Row>
      )}
      <Filter.Row>
        <Filter.Condtion conditionName="Search">
          <Filter.Input
            maxLength={17}
            queryKey="orderNumber"
            placeholder="Search by order number"
          />
        </Filter.Condtion>
      </Filter.Row>
    </Filter>
  );
};

export default OrderAllocationFilter;
