import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 16px;
`;

export const PriceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Hr = styled.hr`
  ${({ theme }) => css`
    position: absolute;
    top: -16px;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: ${theme.color.gray_30};
  `}
`;

export const labelContent = (theme: Theme) => css`
  label {
    ${theme.font.regular_13};
  }
`;

export const Price = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-left: auto;
    color: ${theme.color.gray_70};
  `}
`;

export const Total = styled.span`
  ${({ theme }) => css`
    ${theme.font.medium_15};
    margin-left: auto;
    color: ${theme.color.gray_80};
  `}
`;
