export const CELL = {
  ADDRESS_DOADDR: { key: "doAddr", label: "Address" },
  ADDRESS_PUADDR: { key: "puAddr", label: "Address" },
  ADDRESS_DOADDRESS: { key: "doAddress", label: "Address" },
  ADDRESS_PUADDRESS: { key: "puAddress", label: "Address" },
  ATA_DOATA: { key: "doAta", label: "ATA" },
  ATA_PUATA: { key: "puAta", label: "ATA" },
  ATD_DOATD: { key: "doAtd", label: "ATD" },
  ATD_PUATD: { key: "puAtd", label: "ATD" },
  CAPACITY: { key: "capacity", label: "Capacity" },
  CATEGORY: { key: "category", label: "Category" },
  CATEGORY_TYPE: { key: "type", label: "Category" },
  COMPANY_CLIENT: { key: "client", label: "Company" },
  COMPANY_NAME: { key: "name", label: "Company" },
  COMPANY_SHIPPERNAME: { key: "shipperName", label: "Company" },
  COMPANY_COMPANYNAME: { key: "companyName", label: "Company" },
  COMPLETED: { key: "completed", label: "Completed" },
  COMPLETEDORDERS_PROGRESSED: { key: "progressed", label: "Completed orders" },
  CONFIRMATION: { key: "confirmation", label: "Confirmation" },
  CONSTENTS_TITLE: { key: "title", label: "Contents" },
  CREATEDDATE_CREATED: { key: "created", label: "Created date" },
  CREATEDON_CREATED: { key: "created", label: "Created on" },
  DRIVERNAME_PROFILE: { key: "profile", label: "Driver name" },
  DROPOFF_DOADDRESS: { key: "doAddress", label: "Drop-off" },
  DROPOFF_DOADDR: { key: "doAddr", label: "Drop-off" },
  DROPOFFETA_DOETA: { key: "doEta", label: "Drop-off ETA" },
  DRIVINGTRUCK_REGISTNUMBER: { key: "registNumber", label: "Driving truck" },
  DRIVINGDRVIER_DRIVERNAME: { key: "driverName", label: "Driving driver" },
  EMAIL: { key: "email", label: "E-mail" },
  EMPTY: { key: "", label: "" },
  EMPTY_UTILS: { key: "utils", label: "" },
  ENDDATE: { key: "endDate", label: "End date" },
  ETD_DOETD: { key: "doEtd", label: "ETD" },
  ETD_PUETD: { key: "puEtd", label: "ETD" },
  ETA_DOETA: { key: "doEta", label: "ETA" },
  ETA_PUETA: { key: "puEta", label: "ETA" },
  GARAGEADDRESS_ADDRESS: { key: "address", label: "Garage address" },
  GARAGENAME_NAME: { key: "name", label: "Garage name" },
  GARAGEUSAGE_USAGE: { key: "usage", label: "Garage usage" },
  GRADE: { key: "grade", label: "Grade" },
  INQUIRYDATE_CREATED: { key: "created", label: "Inquiry date" },
  INQUIRYDATE_WRITTEN: { key: "written", label: "Inquiry date" },
  LASTSHIPPINGON: { key: "lastShippingOn", label: "Last shipping on" },
  LF_LOADINGSTATUS: { key: "loadingStatus", label: "L/F" },
  LINKING_DRIVERS: { key: "drivers", label: "Linking" },
  LINKING_LINKEDDRIVER: { key: "linkedDriver", label: "Linking" },
  LINKING_LINKEDREGISTNUMBER: { key: "linkedRegistNumber", label: "Linking" },
  LOGINID_EMAIL: { key: "email", label: "Login ID" },
  MEMBERNAME_NAME: { key: "name", label: "Member name" },
  NUMBEROFTRUCKS_TRUCKNUM: { key: "truckNum", label: "Number of trucks" },
  ORDER_NUMBER: { key: "orderNumber", label: "Order number" },
  PARKEDGARAGE: { key: "parkedGarage", label: "Parked garage" },
  PAYMENTMETHOD_PAY: { key: "pay", label: "Payment method" },
  PHONENUMBER_PHONE: { key: "phone", label: "Phone number" },
  PICKUP_PUADDRESS: { key: "puAddress", label: "Pick-up" },
  PICKUP_PUADDR: { key: "puAddr", label: "Pick-up" },
  PICKUPETA_PUETA: { key: "puEta", label: "Pick-up ETA" },
  PLANE_TYPE: { key: "type", label: "Plan" },
  PLATENUMBER: { key: "plateNumber", label: "Plate number" },
  PLATENUMBER_REGISTNUMBER: { key: "registNumber", label: "Plate number" },
  PRICE: { key: "price", label: "Price" },
  STARTDATE: { key: "startDate", label: "Start date" },
  STATUS: { key: "status", label: "Status" },
  SUBJECT_TITLE: { key: "title", label: "Subject" },
  SUBJECTS_TITLE: { key: "title", label: "Subjects" },
  TEL_PHONE: { key: "phone", label: "Tel." },
  TITLE: { key: "title", label: "Title" },
  TOTAL: { key: "total", label: "Total" },
  TOTAL_PRICE: { key: "price", label: "Total" },
  TRUCKINGCOMPANY_NAME: { key: "name", label: "Trucking company" },
  TRUCKINGCOMPANY_CARRIERNAME: {
    key: "carrierName",
    label: "Trucking Company",
  },
  TRUCKINGCOMPANY: { key: "truckingCompany", label: "Trucking company" },
  TRUCKS: { key: "trucks", label: "Trucks" },
  TRUCKOPTION: { key: "truckOption", label: "Truck option" },
  TRUCKTYPE: { key: "truckType", label: "Truck type" },
  YEAR: { key: "year", label: "Year" },
  GPS: { key: "gpsAvailable", label: "GPS" },
} as const;

export const TRUCKING_COMPANY_COLUMNS = [
  [CELL.TRUCKINGCOMPANY_NAME],
  [CELL.TEL_PHONE],
  [CELL.EMAIL],
] as const;

export const COMPANY_COLUMNS = [
  [CELL.COMPANY_NAME],
  [CELL.TEL_PHONE],
  [CELL.EMAIL],
] as const;

export const NOTICE_TABLE_COLUMNS = [
  [CELL.CREATEDON_CREATED],
  [CELL.CONSTENTS_TITLE],
] as const;

export const MEMBER_TABLE_COLUMNS = [
  [CELL.MEMBERNAME_NAME],
  [CELL.PHONENUMBER_PHONE],
  [CELL.LOGINID_EMAIL],
  [CELL.GRADE],
  [CELL.EMPTY_UTILS],
] as const;

export const SUPPORT_TABLE_COLUMNS = [
  [CELL.CATEGORY_TYPE],
  [CELL.INQUIRYDATE_CREATED],
  [CELL.SUBJECT_TITLE],
  [CELL.STATUS],
] as const;
