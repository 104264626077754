import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 32px;
    border: 1px solid ${theme.color.gray_35};
    padding: 32px;
    background-color: ${theme.color.white};
  `}
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const checkbox = (theme: Theme) => css`
  ${theme.font.regular_14};
  justify-content: flex-start;
  column-gap: 8px;

  & > label {
    color: ${theme.color.gray_70};

    &:first-of-type {
      width: fit-content;
    }
  }
`;

export const selectCompanyBtn = (hasError: boolean) => (theme: Theme) =>
  css`
    border: 1px solid ${hasError ? theme.color.red_20 : theme.color.gray_35};

    &:disabled {
      opacity: inherit;
      border: 1px solid ${theme.color.gray_35};
      background-color: ${theme.color.gray_10};
      cursor: not-allowed;
    }
  `;
