import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Button, Card, Dropdown, ErrMsg, LabelContent } from "@/components";
import { DeleteIcon20, MinusIcon, PlusCircleIcon16, PlusIcon } from "@/assets";
import type { Languages } from "@/types";

import { useTruck } from "hooks";
import type { OrderForm } from "types";
import * as S from "./OrderCreationTruck.styled";

const OrderCreationTruck = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<OrderForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "truck",
  });
  const controlledFields = fields.map((field, i) => ({
    ...field,
    ...watch("truck")[i],
  }));

  const {
    dropdownTruckTypes,
    dropdownTruckOptions,
    handleTruckAdd,
    handleTruckDelete,
    handleTruckTypeSelect,
    handleTruckOptionSelect,
    handleTruckNum,
  } = useTruck();

  return (
    <Card>
      <Card.Content css={S.cardContent} heading="Trucks" headingLevel="h3">
        {controlledFields.map((truck, i) => {
          const truckTypeErrorMsg = errors?.truck?.[i]?.truckType?.message;
          const truckOptionErrorMsg = errors?.truck?.[i]?.truckOption?.message;

          return (
            <S.TruckWrapper key={truck.id}>
              <Button
                css={S.deleteBtn}
                variant="ghostRed"
                icon={<DeleteIcon20 />}
                handleClickBtn={handleTruckDelete({
                  trucks: controlledFields,
                  remove,
                  index: i,
                })}
              />
              <S.DropdownWrapper>
                <LabelContent
                  label="Truck type"
                  direction="vertical"
                  isRequired
                >
                  <Controller
                    control={control}
                    name="truck"
                    render={({ field: { value, onChange } }) => (
                      <Dropdown
                        css={S.dropdown}
                        placeholder="Select the truck type"
                        hasError={!!truckTypeErrorMsg}
                        options={dropdownTruckTypes}
                        selectedOption={truck.truckType}
                        handleSelect={handleTruckTypeSelect({
                          selectIndex: i,
                          trucks: value,
                          onChange,
                        })}
                      />
                    )}
                  />
                  {!!truckTypeErrorMsg && (
                    <ErrMsg>{truckTypeErrorMsg as Languages}</ErrMsg>
                  )}
                </LabelContent>
                <S.TruckOptionsWrapper>
                  <LabelContent
                    label="Number of trucks"
                    direction="vertical"
                    isRequired
                  >
                    <S.TruckNumInputWrapper isDisabled={!truck.truckType.key}>
                      {truck.truckNum}
                      <Controller
                        control={control}
                        name="truck"
                        render={({ field: { value, onChange } }) => (
                          <Button
                            css={S.button}
                            variant="icon"
                            icon={<MinusIcon />}
                            isDisabled={
                              !truck.truckType.key || truck.truckNum === 1
                            }
                            handleClickBtn={handleTruckNum({
                              selectIndex: i,
                              trucks: value,
                              num: -1,
                              onChange,
                            })}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="truck"
                        render={({ field: { value, onChange } }) => (
                          <Button
                            css={S.button}
                            variant="icon"
                            icon={<PlusIcon />}
                            isDisabled={!truck.truckType.key}
                            handleClickBtn={handleTruckNum({
                              selectIndex: i,
                              trucks: value,
                              num: +1,
                              onChange,
                            })}
                          />
                        )}
                      />
                    </S.TruckNumInputWrapper>
                  </LabelContent>
                  <LabelContent
                    label="Truck option"
                    direction="vertical"
                    isRequired
                  >
                    <Controller
                      control={control}
                      name="truck"
                      render={({ field: { value, onChange } }) => (
                        <Dropdown
                          css={S.dropdown}
                          disabled={!truck.truckType.key}
                          hasError={!!truckOptionErrorMsg}
                          options={dropdownTruckOptions(+truck.truckType.key)}
                          placeholder="Select the truck option"
                          selectedOption={truck.truckOption}
                          handleSelect={handleTruckOptionSelect({
                            selectIndex: i,
                            trucks: value,
                            onChange,
                          })}
                        />
                      )}
                    />
                    {!!truckOptionErrorMsg && (
                      <ErrMsg>{truckOptionErrorMsg as Languages}</ErrMsg>
                    )}
                  </LabelContent>
                </S.TruckOptionsWrapper>
              </S.DropdownWrapper>
            </S.TruckWrapper>
          );
        })}
        <Button
          css={S.addTruckBtn}
          variant="tertiaryIconLarge"
          label="Add the truck"
          icon={<PlusCircleIcon16 />}
          handleClickBtn={handleTruckAdd(append)}
        />
      </Card.Content>
    </Card>
  );
};

export default OrderCreationTruck;
