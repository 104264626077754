import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isEqual } from "lodash-es";

import { usePrompt, useToast } from "@/hooks";
import { checkOrderNum, deleteHyphen } from "@/utils";
import { PATH } from "@/assets";
import { COMMON_TOAST_MSG, COMMON_VALID_MSG, convertMB } from "@/constants";
import type { DropdownOptionType } from "@/types";

import { useCreateInquiry } from "services";
import { INQUIRY_CATEGORY_OPTIONS } from "assets";
import { CARRIER_VALID_MSG, CARRIER_TOAST_MSG } from "constants/index";
import type { InquiryForm } from "types";

const SUPPORT_FORM_INIT_STATE = {
  type: { key: "SERVICE", label: "Service" },
  title: "",
  content: "",
  orderNumber: "",
  file1: null,
  file2: null,
  file3: null,
} as const;

const schema = yup.object({
  type: yup.mixed<DropdownOptionType>().defined(),
  title: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test("title", COMMON_VALID_MSG.REQUIRED, (value) => value.trim() !== "")
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_SUBJECT_1000,
      (value) => value.length <= 50,
    ),
  content: yup
    .string()
    .required(COMMON_VALID_MSG.REQUIRED)
    .test("content", COMMON_VALID_MSG.REQUIRED, (value) => value.trim() !== "")
    .test(
      "maxLength",
      COMMON_VALID_MSG.INVALID_DESCRIPTION_1000,
      (value) => value.length <= 1000,
    ),
  orderNumber: yup
    .string()
    .defined()
    .when("type", {
      is: (value: (typeof INQUIRY_CATEGORY_OPTIONS)[number]) =>
        value.key === "ORDER",
      then: () =>
        yup
          .string()
          .required(COMMON_VALID_MSG.REQUIRED)
          .test("orderNumber", CARRIER_VALID_MSG.ORDER_NUMBER, (value) =>
            checkOrderNum(value),
          ),
      otherwise: () => yup.string().notRequired(),
    }),
  file1: yup.mixed<FileList>().required().nullable(),
  file2: yup.mixed<FileList>().required().nullable(),
  file3: yup.mixed<FileList>().required().nullable(),
});

const useCreateSupport = () => {
  const navigate = useNavigate();

  const formMethod = useForm<InquiryForm>({
    defaultValues: SUPPORT_FORM_INIT_STATE,
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const {
    mutate: createInquiryMutate,
    isLoading,
    isSuccess,
  } = useCreateInquiry();

  const { addToast } = useToast();
  usePrompt(!isEqual(formMethod.getValues(), SUPPORT_FORM_INIT_STATE));

  const writeInquiry = formMethod.handleSubmit((data) => {
    const req = {
      body: {
        type: data.type.key,
        title: data.title.trim(),
        content: data.content.trim(),
        ...(data.type.key === "ORDER" && {
          orderNumber: deleteHyphen(data.orderNumber),
        }),
        ...(data?.file1 && {
          file1: data?.file1?.[0],
        }),
        ...(data?.file2 && {
          file2: data?.file2?.[0],
        }),
        ...(data?.file3 && {
          file3: data?.file3?.[0],
        }),
      },
    };

    createInquiryMutate(req, {
      onSuccess: () => {
        formMethod.reset();
        addToast(CARRIER_TOAST_MSG.SUCCESS.INQURIY_ADD_DONE);
      },
      onError: (err) => {
        if (err.response?.data.response === "INVALID_ORDERNUMBER") {
          addToast(COMMON_TOAST_MSG.WARNING.INVALID_ORDERNUMBER);
        } else {
          addToast(COMMON_TOAST_MSG.WARNING.FAIL_TO_CALL_API);
        }
      },
    });
  });

  const handleCancel = () => navigate(PATH.SETTINGS_SUPPORT);

  const handleFileAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const id = name.split(".").at(-1);
    const key = name as keyof typeof SUPPORT_FORM_INIT_STATE;

    switch (id) {
      case "file1":
      case "file2":
      case "file3": {
        if ((e.target.files?.[0].size ?? 0) > convertMB(5)) {
          addToast(COMMON_TOAST_MSG.WARNING.EXCEED_FILE_SIZE);
          formMethod.setError(key, {
            type: "capacityOver",
          });
          formMethod.setValue(id, null);
        } else {
          formMethod.clearErrors(id);
        }
        break;
      }
    }
  };

  const handleFileDelete = (fileKey: string) => () => {
    if (fileKey === "file1" || fileKey === "file2" || fileKey === "file3") {
      formMethod.setValue(fileKey, null);
      formMethod.clearErrors(fileKey);
    }
  };

  // NOTE: usePrompt로 인해 페이지 이동 시 경고창이 뜨는 현상 방지
  useEffect(() => {
    if (!isEqual(formMethod.getValues(), SUPPORT_FORM_INIT_STATE)) return;
    if (!isSuccess) return;

    setTimeout(() => {
      navigate(PATH.SETTINGS_SUPPORT);
    }, 0);
  }, [isSuccess, formMethod.getValues()]);

  return {
    formMethod,
    isLoading,
    handleSubmit: writeInquiry,
    handleCancel,
    handleFileAdd,
    handleFileDelete,
  };
};

export default useCreateSupport;
