import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const content = css`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

export const dateInput = css`
  width: 240px;

  & > input {
    height: 100%;
  }
`;

export const DatesWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const DateExplanation = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    display: inline-block;
    color: ${theme.color.gray_60};
  `}
`;

export const labelContent = css`
  position: relative;

  & > button {
    position: absolute;
    top: 42px;
  }

  & > ul {
    width: 100%;
  }
`;
