/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";

import { InternalLink, Profile } from "@/components";
import { formatPhoneNum } from "@/utils";

import * as S from "./ProfileInfo.styled";

interface ProfileProps {
  profileUrl: string;
  allocationPath?: string;
  name: string;
  phone: string;
  hasInternalLink?: boolean;
}

const ProfileInfo = ({
  profileUrl,
  allocationPath,
  name,
  phone,
  hasInternalLink = true,
}: ProfileProps) => {
  return (
    <S.Profile>
      <Profile css={S.profileImg} src={profileUrl} />
      <S.InfoContent>
        {allocationPath && hasInternalLink ? (
          <InternalLink css={S.name} variant="ghostGray" to={allocationPath}>
            {name}
          </InternalLink>
        ) : (
          <S.Name>{name}</S.Name>
        )}
        <S.Phone>{formatPhoneNum(phone)}</S.Phone>
      </S.InfoContent>
    </S.Profile>
  );
};

export default ProfileInfo;
