/** @jsxImportSource @emotion/react */ // FIXME: 안 붙여도 되도록 수정 필요
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components";
import { useModal } from "@/hooks";
import type {
  Languages,
  ModalPosLabel,
  ModalNegLabel,
  ModalCloseLabel,
} from "@/types";

import BaseModal from "../baseModal/BaseModal";
import * as S from "./ConfirmModal.styled";

interface Common {
  className?: string;
  type?: "submit" | "button";
  isLoading?: boolean;
  isDisabled?: boolean;
  title: Languages;
  desc: Languages;
  // closeBtnLabel?: ModalCloseLabel; // TODO: 번역 추가되면 타입 수정하기
  closeBtnLabel?: Languages;
  callbackFn?: () => void;
}

interface Pos extends Common {
  // posBtnLabel: ModalPosLabel; TODO: 번역 추가되면 타입 수정하기
  posBtnLabel: Languages;
  negBtnLabel?: never;
}

interface Neg extends Common {
  posBtnLabel?: never;
  // negBtnLabel: ModalNegLabel; TODO: 번역 추가되면 타입 수정하기
  negBtnLabel: Languages;
}

type DetailModalProps = Pos | Neg;

const ConfirmModal = React.forwardRef(
  (
    {
      className,
      type = "button",
      isLoading,
      isDisabled,
      title,
      desc,
      posBtnLabel,
      negBtnLabel,
      closeBtnLabel,
      callbackFn,
    }: DetailModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();
    const { handleModalClose } = useModal();

    return (
      <BaseModal css={S.baseModal} className={className} ref={ref}>
        <S.Title>{t(title)}</S.Title>
        <S.Desc>{t(desc)}</S.Desc>
        <S.Footer>
          {closeBtnLabel && (
            <Button
              variant="outlineMedium"
              label={closeBtnLabel}
              handleClickBtn={handleModalClose}
            />
          )}
          <Button
            isLoading={isLoading}
            isDisabled={isDisabled}
            type={type}
            variant={posBtnLabel ? "primaryMedium" : "errorMedium"}
            label={(posBtnLabel ? posBtnLabel : negBtnLabel)!}
            handleClickBtn={callbackFn}
          />
        </S.Footer>
      </BaseModal>
    );
  },
);

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
