import React from "react";
import { useTranslation } from "react-i18next";

import { Badge, LabelContentTable, Tooltip } from "@/components";
import {
  addrFormatter,
  formatICTDateTime,
  formatQuantity,
  formatWeight,
  findLookupTableLabel,
} from "@/utils";
import { CARGO_TYPE, LOADING_STATUS_RADIOS } from "@/assets";
import type { Languages, Location } from "@/types";

import * as S from "./OrderInfo.styled";

interface OrderInfoProps {
  children?: React.ReactNode;
}

const OrderInfo = ({ children }: OrderInfoProps) => {
  return <LabelContentTable variant="underline">{children}</LabelContentTable>;
};

export default OrderInfo;

interface Cargo {
  cargoType: (typeof CARGO_TYPE)[number]["key"];
  weight: number;
  cargoCnt: number;
}

interface OrderCargoInfoProps {
  className?: string;
  cargoes: Cargo[];
}

const AdditionalCargo = ({ cargoes }: OrderCargoInfoProps) => {
  const { t } = useTranslation();

  return (
    <S.AdditionalCargoWrapper>
      {cargoes.map((cargo, i) => {
        const cargoTypeLabel =
          findLookupTableLabel(CARGO_TYPE, cargo.cargoType) ?? cargo.cargoType;

        const cargoItemInfo = [
          t(cargoTypeLabel as Languages),
          formatWeight(cargo.weight),
          formatQuantity(cargo.cargoCnt),
        ].join(" / ");

        return <span key={i}>{cargoItemInfo}</span>;
      })}
    </S.AdditionalCargoWrapper>
  );
};

interface OrderCargoProps {
  className?: string;
  loadingStatus: (typeof LOADING_STATUS_RADIOS)[number]["key"];
  cargoes: Cargo[];
}

OrderInfo.Cargo = function OrderCargoInfo({
  className,
  loadingStatus,
  cargoes,
}: OrderCargoProps) {
  const { t } = useTranslation();

  const additionalCargoInfo = cargoes.slice(1);
  const firstCargoTypeLabel =
    findLookupTableLabel(CARGO_TYPE, cargoes[0].cargoType) ??
    cargoes[0].cargoType;
  const loadingStatusLabel = findLookupTableLabel(
    LOADING_STATUS_RADIOS,
    loadingStatus,
  );

  return (
    <LabelContentTable.Row className={className}>
      <LabelContentTable.Content css={S.labelContentTableRow} label="Cargo">
        <S.CargoWrapper>
          <Badge variant="primary" content={loadingStatusLabel!} />
          <S.CargoContents>
            <span>{`${t(firstCargoTypeLabel as Languages)} / `}</span>
            <span>{`${formatWeight(cargoes[0].weight)} / `}</span>
            <span>{formatQuantity(cargoes[0].cargoCnt)}</span>
            {!!additionalCargoInfo.length && (
              <Tooltip
                css={S.moreCargoTooltip}
                tooltipBtnContent={`+${additionalCargoInfo.length}`}
                position="BOTTOM_RIGHT"
              >
                <AdditionalCargo cargoes={additionalCargoInfo} />
              </Tooltip>
            )}
          </S.CargoContents>
        </S.CargoWrapper>
      </LabelContentTable.Content>
    </LabelContentTable.Row>
  );
};

interface OrderAddressInfoProps {
  className?: string;
  addr: Location["addr"];
  addrDetail?: Location["addrDetail"];
  label: "Pick-up" | "Drop-off";
  eta: string;
  etd: string | null;
}

OrderInfo.Address = function OrderAddressInfo({
  className,
  addr,
  addrDetail,
  label,
  eta,
  etd,
}: OrderAddressInfoProps) {
  const { t } = useTranslation();

  return (
    <LabelContentTable.Row className={className}>
      <LabelContentTable.Content css={S.labelContentTableRow} label={label}>
        <S.AddressWrapper>
          <S.AddressContent>{addrFormatter(addr, addrDetail)}</S.AddressContent>
          <div>
            <S.AddressTimeLabel>{t("ETA")}</S.AddressTimeLabel>
            <S.AddressContent>{formatICTDateTime(eta)}</S.AddressContent>
          </div>
          <div>
            <S.AddressTimeLabel>{t("ETD")}</S.AddressTimeLabel>
            <S.AddressContent>
              {etd ? formatICTDateTime(etd) : "-"}
            </S.AddressContent>
          </div>
        </S.AddressWrapper>
      </LabelContentTable.Content>
    </LabelContentTable.Row>
  );
};

interface OrderTimeInfoProps {
  className?: string;
  label: "ETA" | "ETD";
  time: string | null;
}

OrderInfo.Time = function OrderTimeInfo({
  className,
  label,
  time,
}: OrderTimeInfoProps) {
  return (
    <LabelContentTable.Row className={className}>
      <LabelContentTable.Content css={S.labelContentTableRow} label={label}>
        <S.AddressContent>
          {time ? formatICTDateTime(time) : "-"}
        </S.AddressContent>
      </LabelContentTable.Content>
    </LabelContentTable.Row>
  );
};
