import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const LabelContentWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    margin-bottom: 16px;
    color: ${theme.color.red_20};

    & > div {
      display: flex;
      column-gap: 10px;
    }
  `}
`;

export const labelContentTable = css`
  width: 764px;
`;

export const row = css`
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const labelContent = css`
  width: 100%;
  margin-bottom: 4px;
`;

export const content = css`
  & > div:not(:first-of-type) {
    flex-direction: column;
    align-items: flex-start;

    & > textarea {
      width: 100%;
      height: 120px;
    }
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 12px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 12px 0 0;
`;

export const DriverLicenseWrapper = styled.div`
  width: 100%;
`;

export const Desc = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;
