import { Theme, css } from "@emotion/react";

import { COMPANY_SELECT_TABLE_CELL_SIZES } from "@/assets";

export const detailModal = css`
  width: 686px;
  height: 622px;
`;

export const searchInput = css`
  width: 300px;
  margin-bottom: 12px;
`;

export const table = (theme: Theme) => css`
  th {
    color: ${theme.color.gray_70};
  }

  tbody {
    display: block;
    width: 620px;
    height: calc(420px - 32px); // NOTE: tbody - thead
    overflow-x: hidden;
  }

  tr {
    grid-template-columns: ${COMPANY_SELECT_TABLE_CELL_SIZES};
  }
`;
