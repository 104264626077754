export const TAB = {
  ALLOCATE: { key: "allocate", label: "Allocate" },
  ALLOCATION: { key: "allocation", label: "Allocation" },
  CANCELED: { key: "canceled", label: "Canceled" },
  CARRIER: { key: "carrier", label: "Carrier" },
  COMPLETED: { key: "completed", label: "Completed" },
  DRIVERLIST_DRIVER: { key: "driver", label: "Driver list" },
  GARAGELIST_GARAGE: { key: "garage", label: "Garage list" },
  MANAGELINK_LINK: { key: "link", label: "Manage link" },
  ONGOING: { key: "ongoing", label: "Ongoing" },
  PENDING: { key: "pending", label: "Pending" },
  SHIPPING: { key: "shipping", label: "Shipping" },
  SHIPPER: { key: "shipper", label: "Shipper" },
  TRUCKLIST_TRUCK: { key: "truck", label: "Truck list" },
  WAITING: { key: "waiting", label: "Waiting" },
} as const;
