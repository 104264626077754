import styled from "@emotion/styled";
import { Theme, css } from "@emotion/react";

export const icon = (theme: Theme) => css`
  > path,
  > g > path {
    fill: ${theme.color.gray_40};
  }
`;

export const orderLink = (theme: Theme) => css`
  color: ${theme.color.gray_80};
`;

const moreCnt = (theme: Theme) => css`
  ${theme.font.semibold_12};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.color.gray_35};
  border-radius: 2px;
  padding: 1px 5px;
  color: ${theme.color.gray_70};
`;

export const moreBtn = (theme: Theme) => css`
  & > button {
    ${moreCnt(theme)}
  }

  & > dialog {
    left: -50px;
  }

  :hover {
    background-color: ${theme.color.gray_10};
  }
`;

export const MoreOrderCnt = styled.span`
  ${({ theme }) => css`
    && {
      ${moreCnt(theme)}
    }
  `}
`;
