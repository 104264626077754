import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const labelContentSize = css`
  display: flex;
  flex-direction: column;
`;

export const SizeInputWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
    align-items: center;
    width: 100%;

    & > div:not(:last-of-type) {
      &::after {
        ${theme.font.medium_14};
        position: absolute;
        right: -16px;
        content: "x";
        color: ${theme.color.black};
      }
    }
  `}
`;

export const SizeInfo = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    grid-column: span 3;
    margin-top: 4px;
    color: ${theme.color.gray_70};
  `}
`;

export const dropdown = css`
  width: 214px;
`;
