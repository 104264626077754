import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.section`
  ${({ theme }) => css`
    min-width: ${theme.size.TABLE_SECTION_MIN_WIDTH};
    width: ${`calc(100vw - ${theme.size.NAV_WIDTH})`};
    max-width: ${theme.size.TABLE_SECTION_MAX_WIDTH};
    height: max-content;
    margin: 0 auto;
    padding: 32px 40px;
    background-color: ${theme.color.gray_00};
  `}
`;
