import React from "react";

import { SettingDetailLayout } from "@/components";

import { CompanyLicenseRegistrationHeader } from "components";

interface CompanyLicenseRegistrationLayoutProps {
  licenseRegistrationForm: React.ReactNode;
}

const CompanyLicenseRegistrationLayout = ({
  licenseRegistrationForm,
}: CompanyLicenseRegistrationLayoutProps) => {
  return (
    <SettingDetailLayout>
      <CompanyLicenseRegistrationHeader />
      {licenseRegistrationForm}
    </SettingDetailLayout>
  );
};

export default CompanyLicenseRegistrationLayout;
