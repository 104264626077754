import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const customTabList = (theme: Theme) => css`
  background-color: ${theme.color.gray_00};
`;
