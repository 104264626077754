import { css } from "@emotion/react";

export const font = {
  regular_12: css`
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(16 / 12);
  `,
  regular_13: css`
    font-size: 1.3rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(18 / 13);
  `,
  regular_14: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(20 / 14);
  `,
  regular_14_underline: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(20 / 14);
    text-decoration: underline;
  `,
  regular_14_lineThrough: css`
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(20 / 14);
    text-decoration: line-through;
  `,
  regular_15: css`
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(22 / 15);
  `,
  regular_16: css`
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "pretendard", sans-serif;
    line-height: calc(24 / 16);
  `,
  medium_13: css`
    font-size: 1.3rem;
    font-weight: 500;
    font-family: "pretendard", sans-serif;
    line-height: calc(18 / 13);
  `,
  medium_14: css`
    font-size: 1.4rem;
    font-weight: 500;
    font-family: "pretendard", sans-serif;
    line-height: calc(20 / 14);
  `,
  medium_15: css`
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "pretendard", sans-serif;
    line-height: calc(22 / 15);
  `,
  medium_15_underline: css`
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "pretendard", sans-serif;
    line-height: calc(22 / 15);
    text-decoration: underline;
  `,
  semibold_12: css`
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "pretendard", sans-serif;
    line-height: calc(16 / 12);
  `,
  semibold_13: css`
    font-size: 1.3rem;
    font-weight: 600;
    font-family: "pretendard", sans-serif;
    line-height: calc(18 / 13);
  `,
  semibold_14: css`
    font-size: 1.4rem;
    font-weight: 600;
    font-family: "pretendard", sans-serif;
    line-height: calc(20 / 14);
  `,
  semibold_16: css`
    font-size: 1.6rem;
    font-weight: 600;
    font-family: "pretendard", sans-serif;
    line-height: calc(24 / 16);
  `,
  semibold_32: css`
    font-size: 3.2rem;
    font-weight: 600;
    font-family: "pretendard", sans-serif;
    line-height: calc(44 / 32);
  `,
  bold_16: css`
    font-size: 1.6rem;
    font-weight: 700;
    font-family: "pretendard", sans-serif;
    line-height: calc(24 / 16);
  `,
  bold_18: css`
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "pretendard", sans-serif;
    line-height: calc(26 / 18);
  `,
  bold_20: css`
    font-size: 2rem;
    font-weight: 700;
    font-family: "pretendard", sans-serif;
    line-height: calc(28 / 20);
  `,
  bold_40: css`
    font-size: 4rem;
    font-weight: 700;
    font-family: "pretendard", sans-serif;
    line-height: calc(48 / 40);
  `,
} as const;

export type FontType = typeof font;
