import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContentTable = css`
  width: 764px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LinkContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  & > div {
    width: 220px;
  }
`;

export const profile = css`
  min-width: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const DriverInfoWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `}
`;

export const DriverPhone = styled.span`
  ${({ theme }) => css`
    ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  height: 100%;
  margin: 12px 0 0;
`;

export const moreDialogBtn = (theme: Theme) => css`
  & > button {
    width: 20px;
    height: 20px;

    svg path {
      fill: ${theme.color.gray_50};
    }

    &:hover,
    &[aria-pressed="true"] {
      opacity: 100%;
    }
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ProfileWrapper = styled.div`
  display: flex;
`;

export const button = (theme: Theme) => css`
  color: ${theme.color.primary_40};

  & > svg > path {
    fill: ${theme.color.primary_40};
  }

  &:not(:disabled):hover {
    background-color: inherit;
  }
`;
