import React from "react";
import { useTranslation } from "react-i18next";

import { DetailModal, LabelContent, Table } from "@/components";
import {
  calculateTotalAmount,
  calculateVatAmount,
  deleteComma,
  filterTableColumns,
  findLookupTableLabel,
  formatPrice,
} from "@/utils";
import { PAYMENT_METHOD_RADIOS } from "@/assets";
import type { Languages } from "@/types";

import { formatTrucksTableData, formatTruckNumTypeOption } from "utils";
import { TRUCKLIST_TABLE_COLUMNS } from "assets";
import type { CompanyType, GetQuotationDetailServerModel } from "types";
import * as S from "./QuotationDetailsModal.styled";

interface QuotationDetailsModalProps {
  quotationHistory: GetQuotationDetailServerModel<CompanyType>["history"][number];
  companyInfo: Record<string, string | number>;
  paymentMethod: (typeof PAYMENT_METHOD_RADIOS)[number]["key"];
  trucks: GetQuotationDetailServerModel<"forwardercarrier">["history"][number]["trucks"];
}

const QuotationDetailsModal = React.forwardRef(
  (
    {
      quotationHistory,
      companyInfo,
      paymentMethod,
      trucks,
    }: QuotationDetailsModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { t } = useTranslation();

    if (!quotationHistory) return null;

    const payMethodLabel = findLookupTableLabel(
      PAYMENT_METHOD_RADIOS,
      paymentMethod,
    );

    const totalPrice = calculateTotalAmount(
      `${quotationHistory.price ?? 0}`,
      `${quotationHistory.vat}`,
    );

    const vatAmount = calculateVatAmount(
      `${quotationHistory.price ?? 0}`,
      `${quotationHistory.vat}`,
    );

    const filteredColumns = filterTableColumns(TRUCKLIST_TABLE_COLUMNS);

    const tableTruckNumTypeOption = formatTruckNumTypeOption(trucks ?? {});

    return (
      <DetailModal css={S.detailModal} ref={ref} title="Quotation details">
        <LabelContent
          css={S.labelContent}
          label="Trucking company"
          direction="horizontal"
          columnWidth={200}
        >
          <span> {companyInfo ? companyInfo.truckingCompany : "-"}</span>
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Payment method"
          direction="horizontal"
          columnWidth={200}
        >
          <S.PaymentMethodText>
            {payMethodLabel ? t(payMethodLabel as Languages) : "-"}
          </S.PaymentMethodText>
        </LabelContent>
        <LabelContent
          css={S.verticalLabelContent}
          label="Truck list"
          direction="vertical"
        >
          <Table
            css={S.table(tableTruckNumTypeOption.length)}
            title="Truck list"
            rowCount={tableTruckNumTypeOption.length}
          >
            <Table.Head columnList={TRUCKLIST_TABLE_COLUMNS} />
            <Table.Body css={S.tbody(tableTruckNumTypeOption.length > 0)}>
              {tableTruckNumTypeOption.length ? (
                tableTruckNumTypeOption.map((data, i) => (
                  <Table.Row key={i}>
                    {filteredColumns.map(({ key }) => (
                      <Table.Cell key={key}>
                        {formatTrucksTableData(key, data)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <Table.NoData />
              )}
            </Table.Body>
          </Table>
        </LabelContent>
        <LabelContent
          css={S.multiInfoLabelContent}
          label="Total"
          direction="horizontal"
          columnWidth={200}
        >
          <div>
            <S.TotalPriceText>{formatPrice(totalPrice)}</S.TotalPriceText>
            <S.PriceInfo>
              <LabelContent
                label="Subtotal"
                direction="horizontal"
                columnWidth={80}
              >
                <S.PriceText>
                  {formatPrice(+deleteComma(`${quotationHistory.price ?? 0}`))}
                </S.PriceText>
              </LabelContent>
              <LabelContent label="Tax" direction="horizontal" columnWidth={80}>
                <S.PriceText>{`(${quotationHistory.vat}%) ${formatPrice(
                  vatAmount,
                )}`}</S.PriceText>
              </LabelContent>
            </S.PriceInfo>
          </div>
        </LabelContent>
        <LabelContent
          css={S.labelContent}
          label="Memo"
          direction="horizontal"
          columnWidth={200}
        >
          <div>{quotationHistory.remarks ?? "-"}</div>
        </LabelContent>
        <S.Hr />
      </DetailModal>
    );
  },
);

QuotationDetailsModal.displayName = "QuotationDetailsModal";

export default QuotationDetailsModal;
