import { css, Theme } from "@emotion/react";

export const detailModal = (theme: Theme) => css`
  min-width: 400px;
  padding: 24px;

  div {
    &:first-of-type {
      margin-bottom: 4px;

      & > button {
        display: none;
      }
    }

    &:nth-of-type(2) {
      ${theme.font.regular_14};
      color: ${theme.color.gray_60};
    }

    &:last-of-type {
      button:last-of-type {
        ${theme.button.errorMedium}
    }
  }
`;
