import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Root = styled.div`
  padding: 20px;
`;
export const Title = styled.h4`
  ${({ theme }) => css`
    ${theme.font.semibold_16};
  `}
`;

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  row-gap: 12px;
`;
