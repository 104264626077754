import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PrivateMain = styled.main`
  ${({ theme }) => css`
    position: relative;
    width: ${`calc(100% - ${theme.size.NAV_WIDTH})`};
    min-width: max-content;
    height: max-content;
    min-height: ${`calc(100% - ${theme.size.HEADER_HEIGHT})`};
    margin: ${`${theme.size.HEADER_HEIGHT} 0 0 ${theme.size.NAV_WIDTH} `};
    background-color: ${theme.color.gray_00};
  `}
`;
