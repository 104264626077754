import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { auth } from "@/contexts";
import {
  Button,
  Card,
  Dropdown,
  ErrMsg,
  Input,
  LabelContent,
} from "@/components";
import { useModal, useToast } from "@/hooks";
import { formatPlateNumber } from "@/utils";
import { COMMON_TOAST_MSG } from "@/constants";
import type { Languages } from "@/types";

import { AutoCompletes, PlateNumberModal } from "components";
import {
  useGetPropertyLinkDriver,
  useGetTruckGarage,
  useGetGpsTrucks,
} from "services";
import { CARRIER_VALID_MSG } from "constants/index";
import { getTruckGarageData, selectDropdownForm } from "utils";
import type { TruckForm, AutoCompleteDropdown } from "types";
import * as S from "./TruckCreateDefaultInfo.styled";

const TruckCreateDefaultInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  // TODO: driverName 필터링을 프론트에서 하고 있기 때문에 QueryModel 수정 필요
  const { data: linkDrivers } = useGetPropertyLinkDriver({ query: {} });
  const { data: truckGarages } = useGetTruckGarage();
  const { data: gpsTrucks } = useGetGpsTrucks(!!auth.gpsType);

  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const {
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    register,
  } = useFormContext<TruckForm>();
  const { fields, update, append, remove } = useFieldArray({
    control,
    name: "autoCompletes",
  });

  const formatLinkDrivers = linkDrivers?.reduce(
    (acc: AutoCompleteDropdown[], current) => {
      if (current.truckId === id || !current.truckId) {
        acc.push({ name: current.name, id: current.driverId });
      }

      return acc;
    },
    [],
  );

  const controlledFields = fields.map((field, i) => ({
    ...field,
    ...watch("autoCompletes")[i],
  }));

  const handleSelectLink =
    (idx: number) =>
    (linkDriver: AutoCompleteDropdown): void => {
      update(idx, linkDriver);

      if (!watch("autoCompletes")[idx].id && linkDriver.name) {
        setError(`autoCompletes.${idx}`, {
          message: CARRIER_VALID_MSG.DRIVER_NAME,
        });
        return;
      }

      if (watch("autoCompletes")[idx].id || !linkDriver.name) {
        clearErrors(`autoCompletes.${idx}`);
        return;
      }
    };

  const handleAddLink = (): void => {
    append({ name: "", id: "" }, { shouldFocus: false });
  };

  const handleDelLink = (i: number) => (): void => {
    remove(i);
  };

  const handlePlateListOpen = () => {
    auth.gpsType && gpsTrucks?.plateList.length
      ? handleModalOpen(
          <Controller
            name="plateNumber"
            control={control}
            render={({ field: { onChange } }) => {
              const handleCancelClick = (): void => {
                handleModalClose();
              };

              const handleRowClick = (plate: string) => (): void => {
                onChange(plate);
                handleModalClose();
              };

              return (
                <PlateNumberModal
                  ref={modalRef}
                  plateNumbers={gpsTrucks.plateList}
                  type="select"
                  handleCancelClick={handleCancelClick}
                  handleRowClick={handleRowClick}
                />
              );
            }}
          />,
        )()
      : addToast(COMMON_TOAST_MSG.WARNING.GPS_NOT_CONNECTED);
  };

  if (!truckGarages) return null;

  return (
    <Card.Content heading="Default information">
      <LabelContent
        css={S.plateNumberWrapper}
        label="Plate number"
        direction="vertical"
        isRequired
      >
        <Input
          maxLength={11}
          placeholder="Plate number"
          hasError={!!errors.plateNumber?.message}
          value={watch("plateNumber")}
          register={register("plateNumber", { setValueAs: formatPlateNumber })}
        />
        <S.PlateList>
          {`- ${t("Do you want to check plate number list?")}`}{" "}
          <Button
            css={S.viewDetailsButton}
            variant="text"
            label={"View details" as Languages}
            handleClickBtn={handlePlateListOpen}
          />
        </S.PlateList>
        {errors.plateNumber?.message && (
          <ErrMsg>{errors.plateNumber?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Parked garage" direction="vertical" isRequired>
        <Controller
          name="parkedGarage"
          control={control}
          render={({ field: { value, onChange } }) => {
            const dropdownTruckGarages = getTruckGarageData(truckGarages);

            return (
              <Dropdown
                disabled={!dropdownTruckGarages?.length}
                hasError={!!errors.parkedGarage?.message}
                placeholder="Select the garage"
                options={dropdownTruckGarages}
                selectedOption={value}
                handleSelect={selectDropdownForm(
                  dropdownTruckGarages,
                  onChange,
                )}
              />
            );
          }}
        />
        {errors.parkedGarage?.message && (
          <ErrMsg>{errors.parkedGarage?.message as Languages}</ErrMsg>
        )}
      </LabelContent>
      <LabelContent label="Linking" direction="vertical">
        {formatLinkDrivers && (
          <AutoCompletes
            placeholder="Enter the driver's name"
            id="link"
            dropdowns={formatLinkDrivers}
            fields={controlledFields}
            handleDropdownSelect={handleSelectLink}
            handleDropdownSlotDelete={handleDelLink}
            handleDropdownSlotAdd={handleAddLink}
          />
        )}
      </LabelContent>
    </Card.Content>
  );
};

export default TruckCreateDefaultInfo;
