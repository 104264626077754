import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { mixinBtn_40 } from "@/styles";

export const loading = css`
  height: 100vh;
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg path {
      fill: ${theme.color.black};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    ${theme.font.bold_18};
    color: ${theme.color.black};
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;

  & > button {
    ${mixinBtn_40};
  }
`;
