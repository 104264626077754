import React from "react";

import { NoResult, QueryPagination } from "@/components";
import { useFilterParams } from "@/hooks";

import { useGetAllocationDrivers } from "services";
import type { GetAllocationDriversQueryModel } from "types";
import SelectedAllocationCard from "../../selectedCard/SelectedAllocationCard";
import * as S from "./AllocationAllocateList.styled";

const AllocationAllocateList = () => {
  const { filters } = useFilterParams<
    GetAllocationDriversQueryModel["query"] & { search?: string }
  >(["page", "driverName", "search"]);

  const { search, ...restFilters } = filters;

  const { data, refetch } = useGetAllocationDrivers({
    query: { ...restFilters, driverName: search! },
  });

  return (
    <>
      {data && data.drivers.length > 0 ? (
        <S.Ul>
          {data.drivers.map((driver) => (
            <SelectedAllocationCard
              key={driver.driverId}
              selectedAllocationDriver={driver}
              isClickAble
              refetch={refetch}
            />
          ))}
        </S.Ul>
      ) : (
        <NoResult />
      )}
      <QueryPagination css={S.pagination} pageInfo={data?.pageInfo} />
    </>
  );
};

export default AllocationAllocateList;
