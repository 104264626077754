import React from "react";
import { useTranslation } from "react-i18next";

import { upperCaseToupperFirst } from "@/utils";
import type { Languages, Status } from "@/types";

import * as S from "./StatusLabel.styled";

interface StatusLabelProps {
  className?: string;
  status: Status;
}

const StatusLabel = ({ className, status }: StatusLabelProps) => {
  const { t } = useTranslation();

  const formatStatus = upperCaseToupperFirst(status) as Languages;

  switch (status) {
    case "PENDING":
    case "REQUESTED":
      return (
        <S.OrangeLabel className={className}>{t(formatStatus)}</S.OrangeLabel>
      );

    case "FORWARDING":
    case "SHIPPING":
    case "ONGOING":
      return <S.BlueLabel className={className}>{t(formatStatus)}</S.BlueLabel>;

    case "WAITING":
    case "COMPLETED":
    case "AVAILABLE":
    case "ALLOCATED":
    case "DELIVERED":
    case "ANSWERED":
      return <S.GrayLabel className={className}>{t(formatStatus)}</S.GrayLabel>;

    case "UNAVAILABLE":
    case "REJECTED":
    case "CANCELED":
    case "EMPTY_TRUCK":
      return <S.RedLabel className={className}>{t(formatStatus)}</S.RedLabel>;

    case "REJECTED_BY_SENDER":
      return <S.RedLabel className={className}>{t("Rejected")}</S.RedLabel>;

    case "REJECTED_BY_RECEIVER":
      return <S.RedLabel className={className}>{t("Canceled")}</S.RedLabel>;

    case "ACCEPTED":
      return <S.BlueLabel className={className}>{t("Selected")}</S.BlueLabel>;

    default:
      return <div>-</div>;
  }
};

export default StatusLabel;
