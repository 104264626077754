import { ax } from "apis/axios";
import { getS3PresignedUrlAPI } from "apis";
import { addPrefixToUrlIfNeeded } from "utils";
import {
  GetLinkManageTruckQueryModel,
  ConnectLinkQueryModel,
  GetLinkManageTruckServerModel,
} from "types";

export const getLinkManageTrucksAPI = async (
  req: GetLinkManageTruckQueryModel,
) => {
  const { data } = await ax.get<GetLinkManageTruckServerModel>(
    "/property/link",
    { params: req.query },
  );

  const linkManageTrucks = await Promise.all(
    data.links.map(async (link) => ({
      ...link,
      drivers: await Promise.all(
        link.drivers.map(async (driver, i) => ({
          ...driver,
          profile:
            i === 0 && driver.profile
              ? await getS3PresignedUrlAPI(
                  addPrefixToUrlIfNeeded(driver.profile, "profile"),
                )
              : driver.profile,
        })),
      ),
    })),
  );

  return { ...data, links: linkManageTrucks };
};

export const connectLinkAPI = (req: ConnectLinkQueryModel) =>
  ax.post(`/property/link/${req.truckId}`, req.body);
